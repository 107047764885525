import {
  Box,
  Divider,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  IconButton,
  Slide,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { retrievePublicCollectionInfo } from "graphql/queries";
import AggdataDisplayControl, {
  FILTER_TYPE,
  SORT_TYPE,
  VIEW_TYPE,
} from "components/UI/Controls/AggdataDisplayControl";
import {
  getAggdataSortType,
  getAggdataViewType,
  getAggdataFilterType,
  isUserLoggedIn as checkIsUserLoggedIn,
} from "localstorage/localstorage";
import { useTheme } from "@mui/material/styles";
import AggDataItemsContainer, {
  RequestTypes,
} from "components/AggDataItems/AggDataItemsContainer";
import { useHistory, useParams } from "react-router-dom";
import { DEBUG, MAX_SHARED_COLLECTIONS_COUNT_FREE_USER } from "configuration";
import Skeleton from "@mui/material/Skeleton";
import NotFoundIllustration from "illustrations/undraw_page_not_found.svg";
import { useTranslation } from "react-i18next";
import {
  LOGIN,
  MAINBOARD,
  PUBLIC_COLLECTION_BASE_PATH,
  PUBLIC_COLLECTION_ROUTE_SUBPATH_ID,
} from "navigation/Constants";
import CurrentUserAvatarMenu from "components/layout/Header/CurrentUserAvatarMenu";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { UserObj } from "models/user";
import { fetchUserData } from "api/authApi";
import { loginUser, logoutUser } from "store/actions/authActions";
import SearchIcon from "@mui/icons-material/Search";
import {
  canSavePublicCollectionGql,
  savePublicAggdataGql,
  savePublicCollectionGql,
} from "graphql/mutations";
import CommentsDrawer from "containers/Comments/CommentsDrawer";
import ImageViewer from "components/ImageViewer/ImageViewer";
import {
  hideLoginModal,
  setCurrentCollection,
  setDisplayLoginModal,
  setPathAfterLogin,
  setPublicCollectionOpened,
  showSnackbar,
} from "store/actions/view";
import AggdataCommentsContainer from "containers/Comments/AggdataCommentsContainer";
import SavePublicCollectionConfirmationDialog from "./SavePublicCollectionConfirmationDialog";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";
import { Helmet } from "react-helmet";
import MaxDataUsageLimitReachedDialog from "components/UI/Modals/MaxDataUsageLimitReachedDialog";
import NoteEditor from "containers/AggData/NoteEditor/NoteEditor";
import { AggDataObj, AggDataTagObj } from "models/aggdata";
import NoteImagesViewer from "components/ImageViewer/NoteImagesViewer";
import AudioPlayer from "components/Player/AudioPlayer";
import SelectCollectionContainer from "containers/SelectCollectionContainer/SelectCollectionContainer";
import { CollectionObj } from "models/collection";
import LoginModal from "components/UI/Modals/LoginModal";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import SimpleTagsFilter from "components/UI/Filters/SimpleTagsFilter";
import NestedCollectionsContainer from "../NestedCollectionsContainer";

const PublicCollection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openCommentsDrawer, setOpenCommentsDrawer] = React.useState(false);
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const displayNoteimageViewer = useSelector(
    (state: RootState) => state.view.displayNoteImageViewer
  );
  const displayLoginModal = useSelector(
    (state: RootState) => state.view.displayLoginModal
  );
  const savePublicAggdata = useSelector(
    (state: RootState) => state.view.savePublicAggdataClicked
  );
  const snackBar = useSelector((state: RootState) => state.view.snackBarState);

  const [
    displaySelectCollectionModal,
    setDisplaySelectCollectionModal,
  ] = React.useState(false);
  const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = React.useState<
    string | undefined | null
  >(undefined);
  const [
    displayUserAlreadyOwnsCollection,
    setDisplayUserAlreadyOwnsCollection,
  ] = React.useState(false);
  const [
    displaySaveCollectionConfirmation,
    setDisplaySaveCollectionConfirmation,
  ] = React.useState(false);
  const displayImageViewer = useSelector(
    (state: RootState) => state.view.displayImageViewer
  );
  const displayCommentsView = useSelector(
    (state: RootState) => state.view.displayCommentsViewState
  );
  const aggDataClicked = useSelector(
    (state: RootState) => state.view.aggdataClicked
  );
  const [selectedFilterTags, setSelectedFilterTags] = useState<AggDataTagObj[]>(
    []
  );
  const [
    fetchPublicCollectionInfo,
    fetchPublicCollectionInfoResult,
  ] = useLazyQuery(retrievePublicCollectionInfo, {
    context: {
      clientName: "public",
    },
  });

  const handleSnackBarAction = useCallback(
    (event: React.SyntheticEvent | React.MouseEvent) => {
      if (snackBar.onAction) {
        snackBar.onAction();
      }
      handleSnackbarClose(event);
    },
    [snackBar]
  );

  const handleSnackbarClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    console.log("handle close for reason =" + reason);

    if (reason === "clickaway") {
      return;
    }
    dispatch(
      showSnackbar({ message: "", action: "", onAction: null, loading: false })
    );
  };

  React.useEffect(() => {
    dispatch(setPublicCollectionOpened(true));
    return () => {
      dispatch(setPublicCollectionOpened(false));
    };
  }, []);

  React.useEffect(() => {
    setDisplaySelectCollectionModal(Boolean(savePublicAggdata));
  }, [savePublicAggdata]);

  const [
    checkCanSavePublicCollection,
    checkCanSavePublicCollectionResult,
  ] = useMutation(canSavePublicCollectionGql);

  const [
    savePublicAggdataMutation,
    savePublicAggdataMutationResult,
  ] = useMutation(savePublicAggdataGql);

  const [displayViewType, setDispayViewType] = React.useState<VIEW_TYPE>(
    getAggdataViewType(VIEW_TYPE.FEED)
  );
  const [displaySortType, setDisplaySortType] = React.useState<SORT_TYPE>(
    getAggdataSortType()
  );
  const [dataTypeFilter, setDataTypeFilter] = React.useState<FILTER_TYPE>(
    getAggdataFilterType()
  );

  const editableAggdataNote = useSelector(
    (state: RootState) => state.aggdataReducer.editableAggdataNote
  );

  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );

  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  React.useEffect(() => {
    if (
      fetchPublicCollectionInfoResult.data &&
      fetchPublicCollectionInfoResult.data.publicCollectionData &&
      fetchPublicCollectionInfoResult.data.publicCollectionData.collection
    ) {
      dispatch(
        setCurrentCollection(
          fetchPublicCollectionInfoResult.data.publicCollectionData.collection
        )
      );
    }
    return () => {
      dispatch(setCurrentCollection(null));
    };
  }, [fetchPublicCollectionInfoResult.data]);

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }

  React.useEffect(() => {
    if (!currentUser && isUserLoggedIn) {
      fetchUserData()
        .then((user: UserObj) => {
          dispatch(loginUser(user));
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    }
  }, [currentUser]);

  React.useEffect(() => {
    const publicCollectionDataId = params.id;
    if (DEBUG) {
      console.log("publicCollectionDataId =" + publicCollectionDataId);
    }
    fetchPublicCollectionInfo({
      variables: { token: publicCollectionDataId },
    }).then((result) => {
      if (DEBUG)
        console.log(
          "Successfully fetched the public collection info %o",
          result
        );
    });
  }, [params]);

  const handleSaveToMyMemento = React.useCallback(() => {
    if (
      isUserLoggedIn &&
      fetchPublicCollectionInfoResult.data &&
      fetchPublicCollectionInfoResult.data.publicCollectionData &&
      fetchPublicCollectionInfoResult.data.publicCollectionData.collection
    ) {
      checkCanSavePublicCollection({
        variables: {
          collectionId:
            fetchPublicCollectionInfoResult.data.publicCollectionData.collection
              .id,
        },
      })
        .then((result) => {
          if (DEBUG) {
            console.log("Got the result %o", result);
          }
          if (
            result.data &&
            result.data.canSavePublicCollection &&
            // !result.data.canSavePublicCollection.isOwner &&
            result.data.canSavePublicCollection.result == 0
          ) {
            setDisplaySaveCollectionConfirmation(true);
          } else if (result.data.canSavePublicCollection.isOwner) {
            setDisplayUserAlreadyOwnsCollection(true);
          } else if (result.data.canSavePublicCollection.result == 1) {
            //Max number of collections reached, prompt to upgrade
            setOpenUpgradePlanDialog(
              t("max_free_tier_collections_reached", {
                max_shared_collections_count: MAX_SHARED_COLLECTIONS_COUNT_FREE_USER,
              })
            );
          } else if (result.data.canSavePublicCollection.result == 2) {
            //Max data usage reached, prompt to upgrade
            setOpenUpgradePlanDialog(
              t("max_data_usage_reached_dialog_description")
            );
          }
          //setDisplaySaveCollectionConfirmation(true);
        })
        .catch((err) => {
          console.log("Caught exception %o", err);
          if (!checkIsUserLoggedIn() || err?.message?.includes("403")) {
            dispatch(logoutUser());
            history.push(LOGIN);
          }
        });
    } else {
      dispatch(
        setPathAfterLogin(PUBLIC_COLLECTION_BASE_PATH + "/" + params.id)
      );
      history.push(LOGIN);
    }
  }, [
    isUserLoggedIn,
    history,
    params,
    setDisplaySaveCollectionConfirmation,
    fetchPublicCollectionInfoResult.data,
  ]);

  // if (fetchPublicCollectionInfoResult.loading) return <LoadingView />;
  if (
    fetchPublicCollectionInfoResult.data &&
    fetchPublicCollectionInfoResult.data.publicCollectionData
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Helmet>
          <title>
            🌐{" "}
            {
              fetchPublicCollectionInfoResult.data.publicCollectionData
                .collection.name
            }{" "}
            | Memento
          </title>
        </Helmet>
        <Box
          sx={{
            display: "flex",
            width: "80%",
            height: "58px",
            marginTop: "8px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={handleSaveToMyMemento}
            variant="contained"
            sx={{
              fontSize: "14px",
              marginRight: "24px",
            }}
          >
            {t("save_to_my_memento_title")}
          </Button>

          {isUserLoggedIn && <CurrentUserAvatarMenu />}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "70%",
            flexDirection: "column",
            alignItems: "center",
            "@media (max-width: 780px)": {
              width: "80%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              marginTop: "48px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              "@media (max-width: 780px)": {
                flexDirection: "column",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: "3rem",
                width: "100%",
                textAlign: "start",
                fontFamily: "Trebuchet MS, sans-serif",
                "@media (max-width: 780px)": {
                  marginBottom: "16px",
                },
              }}
            >
              {
                fetchPublicCollectionInfoResult.data.publicCollectionData
                  .collection.name
              }
            </Typography>
            <Box
              sx={{
                "@media (max-width: 780px)": {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "end",
                },
              }}
            >
              <AggdataDisplayControl
                onSortTypeChanged={(sortType: SORT_TYPE) => {
                  setDisplaySortType(sortType);
                }}
                onViewTypeChanged={(viewType: VIEW_TYPE) => {
                  setDispayViewType(viewType);
                }}
                onFilterTypeChanged={(filterType: FILTER_TYPE) => {
                  setDataTypeFilter(filterType);
                }}
                onCommentClicked={() => {
                  setOpenCommentsDrawer(true);
                }}
                displaySearch
                displayComments
                disableVisibilityOption
                selectedTags={selectedFilterTags}
                onSelectedTagsChanged={(tags: AggDataTagObj[]) => {
                  setSelectedFilterTags(tags);
                }}
                collectionId={
                  fetchPublicCollectionInfoResult.data
                    ? fetchPublicCollectionInfoResult.data.publicCollectionData
                        .collection.id
                    : undefined
                }
                collectionName={
                  fetchPublicCollectionInfoResult.data
                    ? fetchPublicCollectionInfoResult.data.publicCollectionData
                        .collection.name
                    : undefined
                }
                isCollectionPublic
              />
            </Box>
          </Box>
          <Divider
            sx={{
              width: "100%",
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "24px",
              width: "100%",
            }}
          >
            {fetchPublicCollectionInfoResult.data.publicCollectionData &&
              fetchPublicCollectionInfoResult.data.publicCollectionData
                .collection && (
                <NestedCollectionsContainer
                  parentCollectionId={
                    fetchPublicCollectionInfoResult.data.publicCollectionData
                      .collection.id
                  }
                  publicCollections
                />
              )}
          </Box>
          <Box
            sx={{
              marginTop: "12px",
              width: "120%",
            }}
          >
            {selectedFilterTags.length > 0 && (
              <SimpleTagsFilter
                allSelectedText={t("filter_all_collections_selected_text")}
                inputHint={t("filter_by_tags_title")}
                onSelectionChanged={(values) => {
                  // console.log("YIT simple filter tag selection changes " + JSON.stringify(values))
                  setSelectedFilterTags(
                    values.map((value) => {
                      return { name: value };
                    })
                  );
                }}
                selectedValues={selectedFilterTags.map(
                  (selectedTag) => selectedTag.name
                )}
                collectionId={
                  fetchPublicCollectionInfoResult.data
                    ? fetchPublicCollectionInfoResult.data.publicCollectionData
                        .collection.id
                    : undefined
                }
                isCollectionPublic
              />
            )}
            <AggDataItemsContainer
              displayViewType={displayViewType}
              dataTypeFilter={dataTypeFilter}
              displaySortType={displaySortType}
              collectionId={
                fetchPublicCollectionInfoResult.data.publicCollectionData
                  .collection.id
              }
              requestType={RequestTypes.PUBLIC_COLLECTION}
              filterTags={selectedFilterTags}
            />
          </Box>
        </Box>
        {fetchPublicCollectionInfoResult.data &&
          fetchPublicCollectionInfoResult.data.publicCollectionData
            .collection && (
            <CommentsDrawer
              collection={
                fetchPublicCollectionInfoResult.data.publicCollectionData
                  .collection
              }
              open={openCommentsDrawer}
              onClose={() => {
                setOpenCommentsDrawer(false);
              }}
            />
          )}

        {displaySelectCollectionModal && (
          <SelectCollectionContainer
            open={displaySelectCollectionModal}
            title={t("select_collection_container_title")}
            handleCollectionSelected={(
              collectionObj: CollectionObj,
              aggdataObj: AggDataObj
            ) => {
              //handleMoveItemsFromCollectionToCollection(collectionObj);
              dispatch(
                showSnackbar({
                  message: t("save_ongoing"),
                  action: "",
                  onAction: null,
                  loading: true,
                })
              );
              savePublicAggdataMutation({
                variables: {
                  collectionId: collectionObj.id,
                  dataId: savePublicAggdata.aggDataId,
                },
              })
                .then((result) => {
                  if (result.data.savePublicAggdata.result == 0) {
                    dispatch(
                      showSnackbar({
                        message: t(
                          "item_added_to_collection_confirmation_msg_singular",
                          {
                            collection_name: collectionObj.name,
                          }
                        ),
                        action: "",
                        onAction: null,
                        loading: false,
                      })
                    );
                  }
                })
                .catch((e) => {
                  if (DEBUG) console.log("Caught an error", e);
                  dispatch(
                    showSnackbar({
                      message: t("save_public_aggdata_failed", {
                        collection_name: collectionObj.name,
                      }),
                      action: "",
                      onAction: null,
                      loading: false,
                    })
                  );
                });
              setDisplaySelectCollectionModal(false);
            }}
            handleModalClose={() => {
              setDisplaySelectCollectionModal(false);
            }}
          ></SelectCollectionContainer>
        )}
        {displayImageViewer && (
          <ImageViewer
            aggDataArray={displayImageViewer.aggDataArray}
            startAggData={displayImageViewer.currentAggData}
            title={displayImageViewer.title}
            inPublicMode
          />
        )}
        {aggDataClicked && aggDataClicked.aggData && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              display: "sticky",
              marginRight: "16px",
              marginBottom: "16px",
            }}
          >
            <AudioPlayer aggdataObj={aggDataClicked.aggData}></AudioPlayer>
          </Box>
        )}
        {displayNoteimageViewer && (
          <NoteImagesViewer
            noteImagesArray={displayNoteimageViewer.noteImagesArray}
            startNoteImage={displayNoteimageViewer.currentNoteImage}
            title={displayNoteimageViewer.title}
            aggData={displayNoteimageViewer.aggData}
            inPublicMode
          />
        )}
        {displayCommentsView && (
          <AggdataCommentsContainer
            aggData={displayCommentsView.aggData}
            inPublicMode
          />
        )}
        {displaySaveCollectionConfirmation && (
          <SavePublicCollectionConfirmationDialog
            collectionName={
              fetchPublicCollectionInfoResult.data.publicCollectionData
                .collection.name
            }
            publicCollectionId={params.id}
            collectionNameExistsAlready={
              checkCanSavePublicCollectionResult.data?.canSavePublicCollection
                .collectionNameExists
            }
            open={displaySaveCollectionConfirmation}
            handleModalClose={() => {
              setDisplaySaveCollectionConfirmation(false);
            }}
          />
        )}
        {displayUserAlreadyOwnsCollection && (
          <SimpleConfirmationDialog
            open={displayUserAlreadyOwnsCollection}
            title={t("save_public_collection_dialog_title", {
              collection_name:
                fetchPublicCollectionInfoResult.data.publicCollectionData
                  .collection.name,
            })}
            description={t("public_collection_already_owned_msg")}
            handleOk={() => {
              setDisplayUserAlreadyOwnsCollection(false);
            }}
            handleModalClose={() => {
              setDisplayUserAlreadyOwnsCollection(false);
            }}
            hideCancel
          />
        )}
        {openUpgradePlanDialog && (
          <MaxDataUsageLimitReachedDialog
            open={Boolean(openUpgradePlanDialog)}
            description={openUpgradePlanDialog}
            handleModalClose={() => {
              setOpenUpgradePlanDialog(null);
            }}
          />
        )}
        {editableAggdataNote && (
          <NoteEditor
            aggData={editableAggdataNote}
            reminder={editableAggdataNote.reminders}
            onPinUnpinAction={() => {}}
            onContentChanged={(aggData: AggDataObj) => {}}
            isPublic
          />
        )}

        <Snackbar
          // className={classes.root}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          style={{ width: "800px", maxWidth: "800px" }}
          open={snackBar.message.length > 0}
          autoHideDuration={snackBar.loading ? 600000 : 6000} //when it's a loading snackbar, we leave it there until the action confirmation arrives to update it.
          onClose={handleSnackbarClose}
          TransitionComponent={TransitionUp}
          message={
            <h1 style={{ width: "100%", fontWeight: 50, fontSize: "1.7rem" }}>
              {snackBar.message}
            </h1>
          }
          action={
            <>
              {snackBar.loading ? (
                <CircularProgress
                  style={{ margin: "8px 0px 8px 130px", padding: "2px" }}
                />
              ) : (
                <Button
                  style={{
                    margin: "8px 0px 8px 130px",
                    fontSize: "1.4rem",
                  }}
                  color="primary"
                  size="large"
                  onClick={handleSnackBarAction}
                >
                  {snackBar.action}
                </Button>
              )}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
        {displayLoginModal && (
          <LoginModal
            description={displayLoginModal.message}
            handleModalClose={() => {
              dispatch(hideLoginModal());
            }}
            open={Boolean(displayLoginModal)}
          />
        )}
      </Box>
    );
  } else if (fetchPublicCollectionInfoResult.error) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {" "}
        <img
          style={{ marginTop: "60px", height: "300px", cursor: "initial" }}
          src={NotFoundIllustration}
        />
        <Typography
          style={{
            marginLeft: "12px",
            color: "black",
            marginTop: "36px",
            textAlign: "center",
          }}
          variant="h4"
        >
          {t("public_collection_not_found_message")}
        </Typography>
        <Button
          variant="contained"
          sx={{
            fontSize: "1.4rem",
            width: "fit-content",
            marginTop: "12px",
          }}
          onClick={() => {
            history.push(MAINBOARD);
          }}
        >
          {t("back_to_home_action")}
        </Button>
      </Box>
    );
  } else {
    return <LoadingView />;
  }
};

const LoadingView = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "80%",
            height: "58px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Skeleton variant="text" height={80} sx={{ width: "20%" }} />{" "}
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            width: "70%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              marginTop: "48px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Skeleton variant="text" height={80} sx={{ width: "30%" }} />
          </Box>
          <Divider
            sx={{
              width: "100%",
            }}
          />
          <Box
            sx={{
              height: "100%",
              marginTop: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress></CircularProgress>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(PublicCollection);



/**
 * This method is used to convert certain HTML tags, to the ones supported by the html parsing lib used (htmltodraft and drafttohtml)
 * The library doesn't seem to support the <b> and <i> tags, but supports <strong> and <em> instead, that's why they are being replaced,
 * to have the expected rendering of the text.
 * 
 * @param {} rawHtml 
 * @returns 
 */
export function convertHtmlTagsForDisplay(rawHtml){
    rawHtml = rawHtml.replace('<b>', '<strong>')
    rawHtml = rawHtml.replace('</b>', '</strong>')
    rawHtml = rawHtml.replace('<i>', '<em>')
    rawHtml = rawHtml.replace('</i>', '</em>')
    return rawHtml
}


function convertRgbStringToHex(rgbString){
    var a = rgbString.split("(")[1].split(")")[0];
    a = a.split(",");
    var b = a.map(function(x){             //For each array element
        x = parseInt(x).toString(16);      //Convert to a base16 string
        return (x.length==1) ? "0"+x : x;  //Add zero if we get only one character
    })
    b = "#"+b.join("");
    //console.log("regex hex val " + b)
    return b
}

/**
 * Used to convert all rgb values from a raw html text to hex. This is done to keep compatibility with android devices, that don't support rgb values format.
 * For ex, sequences like : 
 *  <span style="color: rgb(32,151,241);background-color: rgb(238,154,154);">
 * shall be converted to :
 *  <span style="color: #2097f1;background-color: #ee9a9a;">
 * 
 * This method replaces all rgb(x,x,x) patterns by their hex equivalent.
 *  
 * @param {*} rawHtml 
 * @returns 
 */

export function convertHtmlRgbValuesToHex(rawHtml){
    var result = (' ' + rawHtml).slice(1)
    const regex = new RegExp('rgb[\(]([0-9]{1,3},[0-9]{1,3},[0-9]{1,3})[\)];', 'g')
    var matches
    while ((matches = regex.exec(rawHtml)) !== null) {
      const hexColor = convertRgbStringToHex(matches[0].replace(";", ""))
      //console.log("will replace " + matches[0] + " by " + hexColor)
      result = result.replace(matches[0].toString(), hexColor + ";")
    }
    return result

}

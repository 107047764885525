import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import NoSearchDataFoundIllustration from "illustrations/undraw_no_data_qbuo.svg";

const SearchResultsEmpty = () => {
  const { t } = useTranslation();

  return (
    <>
      <img
        style={{ marginTop: "120px", height: "300px", width: "600px",  cursor:"initial" }}
        src={NoSearchDataFoundIllustration}
      />
      <Typography
        style={{ marginLeft: "12px", color: "black", marginTop: "12px" }}
        variant="h4"
      >
        {t("no_search_results_found_msg")}
      </Typography>
    </>
  );
};

export default React.memo(SearchResultsEmpty);

import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { RootState } from "store/reducers";
import {
  retrieveChildCollectionsForParentGql,
  retrievePublicChildCollectionsForParentGql,
} from "graphql/queries";
import { useQuery } from "@apollo/client";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { CollectionObj } from "models/collection";
import CollectionLayout from "./CollectionLayout";
import { getCollectionRoute } from "navigation/Constants";
import { useHistory } from "react-router-dom";
import { VIEW_TYPE } from "components/UI/Controls/CollectionsFilterControl";

interface NestedCollectionsContainerProps {
  parentCollectionId: string;
  publicCollections?: boolean;
}

const NestedCollectionsContainer = ({
  parentCollectionId,
  publicCollections = false,
}: NestedCollectionsContainerProps) => {
  const history = useHistory();
  const [collections, setCollections] = React.useState([]);
  const { loading, error, data } = useQuery(
    publicCollections
      ? retrievePublicChildCollectionsForParentGql
      : retrieveChildCollectionsForParentGql,
    {
      variables: { parentId: parentCollectionId },
      fetchPolicy: "network-only",
      context: publicCollections
        ? {
            clientName: "public",
          }
        : undefined,
    }
  );

  React.useEffect(() => {
    if (data) {
      if (data.childCollections) {
        setCollections(data.childCollections);
      } else if (data.publicChildCollections) {
        setCollections(data.publicChildCollections);
      }
    }
  }, [data]);

  const handleCollectionClicked = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      collection: CollectionObj
    ) => {
      console.log("Collection " + JSON.stringify(collection.name) + " clicked")
      const event = e.target as HTMLElement;
      console.log("tagname " + event.tagName);
      if (event.tagName == "svg" || event.tagName == "BUTTON") {
        //ignoe the click when it comes from the more button on the card
        return;
      }
      if (publicCollections) {
        history.push("/pcollection/" + collection.publicToken);
      } else {
        if (collection.isArchived) {
          history.push("/board/archives/collection/" + collection.id);
        } else {
          history.push(getCollectionRoute(collection.id));
        }
      }
    },
    [publicCollections]
  );
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {" "}
      <Grid
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
        container
        spacing={2}
      >
        {collections.map((collection: CollectionObj) => {
          return (
            <Grid item xs={4} sx={{ padding: "4px" }}>
              <Card
                sx={{
                  width: "auto",
                  border: "1px solid yellow yellow",
                  background: "#ebeae8",
                  borderRadius: "16px",
                }}
              >
                <CollectionLayout
                  onClick={(e, collectionClicked) => handleCollectionClicked(e, collectionClicked)}
                  key={collection.id!!}
                  collection={collection}
                  listLayout={true}
                  displayPublicCollection={publicCollections}
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default React.memo(NestedCollectionsContainer);

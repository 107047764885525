import { AggDataObj, AggDataTagObj, CommentObj } from "models/aggdata";
import { CollectionObj } from "models/collection";
import { ApolloClient, useMutation } from "@apollo/client";
import {
  retrieveCommentsForAggdataIdGql,
  retrieveAggdataForCollectionGql,
  retrieveAllCollectionsGql,
  retrieveAllDataGql,
  retrieveCollectionGqlCache,
  retrieveAggDataGql,
  retrieveNoteGql,
  retrieveAllArchivedDataGql,
  retrieveAllArchivedCollectionsGql,
  retrieveAllAggDataTagsGql,
  retrieveTopLevelCollectionsGql,
  retrieveAllTopLevelArchivedCollectionsGql,
} from "./queries";
import { DEBUG } from "configuration";
import { PublicProfile } from "models/user";

export function updateCache(
  client: ApolloClient<any>,
  query: any,
  aggData: AggDataObj
) {
  client.writeQuery({
    query: query,
    data: { allAggdata: aggData },
  });
}

export function updatePublicProfileCache(
  client: ApolloClient<any>,
  query: any,
  publicProfile: PublicProfile
) {
  client.writeQuery({
    query: query,
    data: { publicProfile: publicProfile },
  });
}

export function addAggDataTagInCache(cache: any, tagName: String) {
  const existingTags: any = cache.readQuery({
    query: retrieveAllAggDataTagsGql,
  });
  let tagAlreadyExists = existingTags.allTags.some(
    (tag: any) => tag.name == tagName
  );

  if (!tagAlreadyExists) {
    if (DEBUG) {
      console.log("addAggDataTagInCache: TAG does not exist already, will add it");
    }
    cache.writeQuery({
      query: retrieveAllAggDataTagsGql,
      data: { allTags: [{ name: tagName }, ...existingTags.allTags] },
    });
  } else {
    if (DEBUG) {
      console.log("addAggDataTagInCache: TAG already exists in cache");
    }
  }
}

export function addAggDataInCache(cache: any, aggData: AggDataObj) {
  const existingData: any = cache.readQuery({
    query: retrieveAllDataGql,
  });
  if (existingData) {
    cache.writeQuery({
      query: retrieveAllDataGql,
      data: { allAggdata: [aggData, ...existingData.allAggdata] },
    });
  }
}

export function deleteCommentsCacheForAggdata(cache: any, dataId: string) {
  cache.evict({
    id: "ROOT_QUERY",
    fieldName: "aggdataCommentsPaged",
    args: { dataId: dataId },
  });
}

export function deleteCommentsNotificationsCacheForCollection(
  cache: any,
  collectionId: string
) {
  cache.evict({
    id: "ROOT_QUERY",
    fieldName: "commentsForCollectionPaged",
    args: { collectionId: collectionId },
  });
}

export function updateCommentCacheUponDelete(
  cache: any,
  page: number,
  pageSize: number,
  dataId: string,
  newPage: CommentObj[]
) {
  const existingData: any = cache.readQuery({
    query: retrieveCommentsForAggdataIdGql,
    variables: { id: dataId },
  });
  if (existingData) {
    const newCache = [
      ...[...existingData.aggdataCommentsPaged].slice(0, (page - 1) * pageSize),
      ...newPage,
    ];
    deleteCommentsCacheForAggdata(cache, dataId);
    cache.writeQuery({
      query: retrieveCommentsForAggdataIdGql,
      variables: { id: dataId },
      data: { aggdataCommentsPaged: newCache },
    });
  }
}

export function addAggdataCommentInCache(
  cache: any,
  comment: CommentObj,
  dataId: string,
  cachePageSize: number
) {
  if (DEBUG) {
    console.log(
      "addAggdataCommentInCache comment is %o and data id" + dataId,
      comment
    );
  }
  const existingData: any = cache.readQuery({
    query: retrieveCommentsForAggdataIdGql,
    variables: { id: dataId, page: 1, pageSize: 1 },
  });
  if (DEBUG) {
    console.log("addAggdataCommentInCache existing data is %o", existingData);
  }
  if (existingData) {
    var newCache = [...existingData.aggdataCommentsPaged];
    if (existingData.aggdataCommentsPaged.length > 0) {
      //We remove the last item in this case to make sure the pages are consistent on the fetch of the next page
      if (existingData.aggdataCommentsPaged.length % cachePageSize == 0) {
        newCache = [...existingData.aggdataCommentsPaged].pop();
      }
    }
    deleteCommentsCacheForAggdata(cache, dataId);
    cache.writeQuery({
      query: retrieveCommentsForAggdataIdGql,
      variables: { id: dataId },
      data: { aggdataCommentsPaged: [comment, ...newCache] },
    });
  }
}

export function addCollectionInCache(cache: any, collectionObj: CollectionObj) {
  const existingCollections: any = cache.readQuery({
    query: retrieveAllCollectionsGql,
  });
  if (existingCollections) {
    cache.writeQuery({
      query: retrieveAllCollectionsGql,
      data: {
        collections: [collectionObj, ...existingCollections.collections],
      },
    });
  }
}

export function addCollectionInArchivedCache(
  cache: any,
  collectionObj: CollectionObj
) {
  const existingCollections: any = cache.readQuery({
    query: retrieveAllArchivedCollectionsGql,
  });
  if (existingCollections) {
    cache.writeQuery({
      query: retrieveAllArchivedCollectionsGql,
      data: {
        archivedCollections: [
          collectionObj,
          ...existingCollections.archivedCollections,
        ],
      },
    });
  }
}
export function addCollectionInTopLevelArchivedCache(
  cache: any,
  collectionObj: CollectionObj
) {
  const existingCollections: any = cache.readQuery({
    query: retrieveAllTopLevelArchivedCollectionsGql,
  });
  if (existingCollections) {
    cache.writeQuery({
      query: retrieveAllTopLevelArchivedCollectionsGql,
      data: {
        archivedTopLevelCollections: [
          collectionObj,
          ...existingCollections.archivedTopLevelCollections,
        ],
      },
    });
  }
}


export function deleteCollectionFromTopLevelArchivedCache(
  cache: any,
  collection: CollectionObj
) {
  console.log(
    "deleteCollectionFromTopLevelArchivedCache: for collection " + JSON.stringify(collection)
  );
  //This also removes any dangling references to the collection object just removed,
  //aka all aggdata that are pointing to this collection
  // const normalizedId = cache.identify({ ...collection, __typename: 'CollectionType' });
  // cache.evict({ id: normalizedId });
  // cache.gc();

  const existingCollections: any = cache.readQuery({
    query: retrieveAllTopLevelArchivedCollectionsGql,
  });
  if (existingCollections && existingCollections.archivedTopLevelCollections) {
    const newCollectionsList = existingCollections.archivedTopLevelCollections.filter(
      (coll: CollectionObj) => coll.id != collection.id
    );
    cache.writeQuery({
      query: retrieveAllTopLevelArchivedCollectionsGql,
      data: { archivedTopLevelCollections: newCollectionsList },
    });
  }
}


export function getAggDataFrommAllCacheForCollection(
  cache: any,
  collectionId: string
) {
  const existingData: any = cache.readQuery({
    query: retrieveAllDataGql,
  });
  return existingData.allAggdata.filter(
    (aggData: AggDataObj) =>
      aggData.collection && aggData.collection.id == collectionId
  );
}

//For a given collection Id, will move the data for a collection for non archived data cache to archived data cache.
//This will not delete aggdata from source cache.
export function moveAggDataToArchivedForCollectionId(
  cache: any,
  collectionId: string
) {
  const existingData: any = cache.readQuery({
    query: retrieveAllDataGql,
  });
  console.log("moveAggDataToArchivedForCollectionId: %o", existingData);
  if (existingData && existingData.allAggdata) {
    const aggDataListFromAllCache = existingData.allAggdata.filter(
      (aggData: AggDataObj) =>
        aggData.collection && aggData.collection.id == collectionId
    );
    addAggDataArrayInArchivedDataCache(cache, aggDataListFromAllCache);
    const aggDataNotFromCollection = existingData.allAggdata.filter(
      (aggData: AggDataObj) =>
        !aggData.collection || aggData.collection.id != collectionId
    );
    cache.writeQuery({
      query: retrieveAllDataGql,
      data: { allAggdata: aggDataNotFromCollection },
    });
  }
}

//For a given collection Id, will move the data for a collection for non archived data cache to archived data cache.
//This will not delete aggdata from source cache.
export function moveAggDataToUnArchivedForCollectionId(
  cache: any,
  collectionId: string
) {
  const existingData: any = cache.readQuery({
    query: retrieveAllArchivedDataGql,
  });
  console.log("moveAggDataToUnArchivedForCollectionId: %o", existingData);
  if (existingData && existingData.allArchivedAggdata) {
    const aggDataListFromAllCache = existingData.allArchivedAggdata.filter(
      (aggData: AggDataObj) =>
        aggData.collection && aggData.collection.id == collectionId
    );
    addAggDataArrayInCache(cache, aggDataListFromAllCache);
    const aggDataNotFromCollection = existingData.allArchivedAggdata.filter(
      (aggData: AggDataObj) =>
        !aggData.collection || aggData.collection.id != collectionId
    );
    cache.writeQuery({
      query: retrieveAllArchivedDataGql,
      data: { allArchivedAggdata: aggDataNotFromCollection },
    });
  }
}

export function addAggDataArrayInCache(cache: any, aggDataArray: AggDataObj[]) {
  const existingData: any = cache.readQuery({
    query: retrieveAllDataGql,
  });
  console.log("Will add aggdata to cache " + JSON.stringify(existingData));
  if (existingData) {
    cache.writeQuery({
      query: retrieveAllDataGql,
      data: { allAggdata: [...aggDataArray, ...existingData.allAggdata] },
    });
  }
}

export function addAggDataArrayInArchivedDataCache(
  cache: any,
  aggDataArray: AggDataObj[]
) {
  const existingData: any = cache.readQuery({
    query: retrieveAllArchivedDataGql,
  });
  if (existingData) {
    //If there is a cache
    cache.writeQuery({
      query: retrieveAllArchivedDataGql,
      data: {
        allArchivedAggdata: [
          ...aggDataArray,
          ...existingData.allArchivedAggdata,
        ],
      },
    });
  }
}

export function addAggDataInArchivedDataCache(cache: any, aggData: AggDataObj) {
  const existingData: any = cache.readQuery({
    query: retrieveAllArchivedDataGql,
  });
  if (existingData) {
    //If there is a cache
    cache.writeQuery({
      query: retrieveAllArchivedDataGql,
      data: {
        allArchivedAggdata: [aggData, ...existingData.allArchivedAggdata],
      },
    });
  }
}

export function addAggDataListInCollectionSpecificCache(
  cache: any,
  aggDataList: AggDataObj[],
  collectionId: string
) {
  if (DEBUG)
    console.log(
      "addAggDataListInCollectionSpecificCache: aggdata " +
        JSON.stringify(aggDataList) +
        " in cache for collectoin " +
        collectionId
    );
  const cachedData = cache.readQuery({
    query: retrieveAggdataForCollectionGql,
    variables: { collectionId: collectionId },
  });
  if (cachedData) {
    //If there is a cache
    cache.writeQuery({
      query: retrieveAggdataForCollectionGql,
      variables: { collectionId: collectionId },
      data: {
        aggdataForCollection: [
          ...aggDataList,
          ...cachedData.aggdataForCollection,
        ],
      },
    });
  }
}

export function addAggDataInCollectionSpecificCache(
  cache: any,
  aggData: AggDataObj,
  collectionId: string
) {
  if (DEBUG)
    console.log(
      "addAggDataInCollectionSpecificCache: aggdata " +
        JSON.stringify(aggData) +
        " in cache for collectoin " +
        collectionId
    );
  const cachedData = cache.readQuery({
    query: retrieveAggdataForCollectionGql,
    variables: { collectionId: collectionId },
  });
  if (cachedData) {
    //If there is a cache
    cache.writeQuery({
      query: retrieveAggdataForCollectionGql,
      variables: { collectionId: collectionId },
      data: {
        aggdataForCollection: [aggData, ...cachedData.aggdataForCollection],
      },
    });
  }
}

//Shared collections have their own cache for aggdata, as aggdata is only fetched when a shared collection is opened,
//as opposed to not shared collection, for which aggdata is retrieved when the memento website is loaded.
export function deleteSharedCollectionSpecificCache(
  cahce: any,
  collectionId: string
) {
  cahce.evict({
    id: "ROOT_QUERY",
    fieldName: "aggdataForCollection",
    args: { collectionId: collectionId },
  });
}

export function deleteCollectionFromCache(cache: any, collectionId: string) {
  if (DEBUG)
    console.log(
      "deleteCollectionFromCache: for collection " +
        JSON.stringify(collectionId) +
        " from cache %o",
      cache
    );
  const existingCollections: any = cache.readQuery({
    query: retrieveAllCollectionsGql,
  });
  if (DEBUG)
    console.log(
      "deleteCollectionFromCache: found cache of  " +
        JSON.stringify(existingCollections)
    );
  if (existingCollections && existingCollections.collections) {
    const newCollectionsList = existingCollections.collections.filter(
      (coll: CollectionObj) => coll.id !== collectionId
    );
    if (DEBUG)
      console.log(
        "deleteCollectionFromCache: puhsing to cache list of " +
          newCollectionsList.length
      );
    cache.writeQuery({
      query: retrieveAllCollectionsGql,
      data: { collections: newCollectionsList },
    });
  }
}
export function deleteCollectionFromTopLevelCollectionsCache(cache: any, collectionId: string) {
  if (DEBUG)
    console.log(
      "deleteCollectionFromCache: for collection " +
        JSON.stringify(collectionId) +
        " from cache %o",
      cache
    );
  const existingCollections: any = cache.readQuery({
    query: retrieveTopLevelCollectionsGql,
  });
  if (DEBUG)
    console.log(
      "deleteCollectionFromCache: found cache of  " +
        JSON.stringify(existingCollections)
    );
  if (existingCollections && existingCollections.topLevelCollections) {
    const newCollectionsList = existingCollections.topLevelCollections.filter(
      (coll: CollectionObj) => coll.id !== collectionId
    );
    if (DEBUG)
      console.log(
        "deleteCollectionFromCache: puhsing to cache list of " +
          newCollectionsList.length
      );
    cache.writeQuery({
      query: retrieveTopLevelCollectionsGql,
      data: { topLevelCollections: newCollectionsList },
    });
  }
}

export function deleteCollectionFromArchivedCache(
  cache: any,
  collection: CollectionObj
) {
  console.log(
    "deleteCollectionFromCache: for collection " + JSON.stringify(collection)
  );
  //This also removes any dangling references to the collection object just removed,
  //aka all aggdata that are pointing to this collection
  // const normalizedId = cache.identify({ ...collection, __typename: 'CollectionType' });
  // cache.evict({ id: normalizedId });
  // cache.gc();

  const existingCollections: any = cache.readQuery({
    query: retrieveAllArchivedCollectionsGql,
  });
  if (existingCollections && existingCollections.archivedCollections) {
    const newCollectionsList = existingCollections.archivedCollections.filter(
      (coll: CollectionObj) => coll.id != collection.id
    );
    cache.writeQuery({
      query: retrieveAllArchivedCollectionsGql,
      data: { archivedCollections: newCollectionsList },
    });
  }
}
export function deleteAggdataFromCollectionSpecificCache(
  cache: any,
  aggDataIdsArray: string[],
  collectionId: string
) {
  console.log(
    "deleteAggdataFromCollectionSpecificCache: for collection " +
      JSON.stringify(collectionId)
  );
  //This also removes any dangling references to the collection object just removed,
  //aka all aggdata that are pointing to this collection
  // const normalizedId = cache.identify({ ...collection, __typename: 'CollectionType' });
  // cache.evict({ id: normalizedId });
  // cache.gc();

  const existingAggdataForCollection: any = cache.readQuery({
    query: retrieveAggdataForCollectionGql,
    variables: { collectionId: collectionId },
  });
  if (
    existingAggdataForCollection &&
    existingAggdataForCollection.aggdataForCollection
  ) {
    const newAggdataList = existingAggdataForCollection.aggdataForCollection.filter(
      (aggData: AggDataObj) => !aggDataIdsArray.includes(aggData.id)
    );
    cache.writeQuery({
      query: retrieveAggdataForCollectionGql,
      variables: { collectionId: collectionId },
      data: { aggdataForCollection: newAggdataList },
    });
  }
}

export function deleteAggDataFromCache(cache: any, aggData: AggDataObj) {
  if (DEBUG) {
    console.log(
      "Will delete aggdata " + JSON.stringify(aggData) + "from cache"
    );
  }
  const normalizedId = cache.identify({
    ...aggData,
    __typename: "AggDataType",
  });
  cache.evict({ id: normalizedId });
  cache.gc();
}

export function deleteAggDataFromAllDataCache(cache: any, aggData: AggDataObj) {
  const existingData: any = cache.readQuery({
    query: retrieveAllDataGql,
  });
  if (existingData) {
    const newAggData = existingData.allAggdata.filter(
      (data: AggDataObj) => data.id !== aggData.id
    );
    cache.writeQuery({
      query: retrieveAllDataGql,
      data: { allAggdata: newAggData },
    });
  }
}

export function deleteAggDataFromArchivedDataCache(
  cache: any,
  aggData: AggDataObj
) {
  const existingData: any = cache.readQuery({
    query: retrieveAllArchivedDataGql,
  });
  if (existingData) {
    const newAggData = existingData.allArchivedAggdata.filter(
      (data: AggDataObj) => data.id !== aggData.id
    );
    cache.writeQuery({
      query: retrieveAllArchivedDataGql,
      data: { allArchivedAggdata: newAggData },
    });
  }
  // const normalizedId = cache.identify({ ...aggData, __typename: 'AggDataType' });
  // cache.evict({ id: normalizedId });
  // cache.gc();
}

export function updateAggDataInCache(cache: any, aggData: AggDataObj) {
  if (DEBUG) {
    console.log("updateAggDataInCache for aggdata %o", aggData);
  }
  if (aggData.id) {
    cache.writeQuery({
      query: retrieveAggDataGql,
      data: { allAggdata: aggData },
      variables: { id: aggData.id },
    });
  }
}

export function updateAggDataArrayInCache(
  cache: any,
  aggDataArray: AggDataObj[]
) {
  if (aggDataArray && aggDataArray.length > 0) {
    aggDataArray.map((aggData: AggDataObj) => {
      //console.log("Mouad update in cache " + JSON.stringify(aggData))
      updateAggDataInCache(cache, aggData);
    });
  }
}

export function updateNoteInCache(cache: any, noteObj: any) {
  // const existingData: any = cache.readQuery({
  //     query: retrieveAllDataGql,
  // });
  // console.log('update aggdata in cache for aggdata ' + JSON.stringify(aggData))
  // cache.writeQuery({
  //     query: retrieveAllDataGql,
  //     data: { allAggdata: [...existingData.allAggdata, aggData] },
  // });
  cache.writeQuery({
    query: retrieveNoteGql,
    data: { note: noteObj },
    variables: { id: noteObj.id },
  });
}
// }
// export function updateAggDataInCache(cache: any, aggData: AggDataObj) {
//     console.log('update aggdata in cache for aggdata ' + JSON.stringify(aggData))
//     cache.writeQuery({
//         query: retrieveAggDataGql,
//         data: { allAggData: aggData },
//         variables: { id: aggData.id },
//         refetchQueries: retrieveAggDataGql,
//     });
// }

export function updateAggDataInCacheWithClient(
  client: ApolloClient<any>,
  aggData: AggDataObj
) {
  client.writeQuery({
    query: retrieveAggDataGql,
    data: { allAggData: aggData },
    variables: { id: aggData.id },
  });
}

export function updateCollectionsCache(
  cache: any,
  query: any,
  collection: CollectionObj
) {
  if (DEBUG)
    console.log(
      "updateCollectionsCache: query " +
        query +
        " collection " +
        JSON.stringify(collection)
    );
  const existingData: any = cache.readQuery({
    query: retrieveAllCollectionsGql,
  });
  if (existingData) {
    cache.writeQuery({
      query: query,
      data: { collections: [collection, ...existingData.collections] },
    });
  }
}


export function updateTopLevelCollectionsCache(
  cache: any,
  query: any,
  collection: CollectionObj
) {
  if (DEBUG)
    console.log(
      "updateCollectionsCache: query " +
        query +
        " collection " +
        JSON.stringify(collection)
    );
  const existingData: any = cache.readQuery({
    query: retrieveTopLevelCollectionsGql,
  });
  if (existingData) {
    cache.writeQuery({
      query: query,
      data: { topLevelCollections: [collection, ...existingData.topLevelCollections] },
    });
  }
}

export function updateCollectionInCache(cache: any, collection: CollectionObj) {
  console.log(
    "update collection in cache for collection " + JSON.stringify(collection)
  );
  cache.writeQuery({
    query: retrieveCollectionGqlCache,
    data: { collections: collection },
    variables: { id: collection.id },
  });
}

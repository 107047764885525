import React, { useState, useCallback } from "react";
import Avatar from "@mui/material/Avatar";
import { stringToColour } from "shared/colors";
import { SharedUserAccess, UserObj } from "models/user";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import IconButton from "@mui/material/IconButton";
import SharedUserAvatar from "components/UI/Controls/SharedUserAvatar";
import { useTranslation } from "react-i18next";

const MAX_AVATARS_DISPLAYED = 3;
enum Size {
  NORMAL = "normal",
  SMALL = "small",
}
interface SharedUsersAvatarsProps {
  sharedUsersList: SharedUserAccess[];
  hideConfigureAccess?: boolean;
  size?: Size;
  onManageAccessClicked?: () => void;
}

const getToolTipContent = (user: UserObj) => {
  return user.firstName + " " + user.lastName + " (" + user.username + ")";
};

const SharedUsersAvatars = ({
  sharedUsersList,
  hideConfigureAccess,
  size,
  onManageAccessClicked,
}: SharedUsersAvatarsProps) => {
  const [showOverFlowAvatarTooltip, setShowOverFlowAvatarTooltip] =
    React.useState(false);
  const [showManageTooltip, setShowManageTooltip] = React.useState(false);

  const { t } = useTranslation();
  return (
    <div key="avatars-list" style={{ display: "flex", flexDirection: "row" }}>
      {sharedUsersList.map((sharedUser: SharedUserAccess, index: number) => {
        if (index + 1 > MAX_AVATARS_DISPLAYED) {
          return null;
        }
        return (
          <SharedUserAvatar
            key={index}
            user={sharedUser.user}
            applyLeftMargin={index > 0}
            size={size}
          />
        );
      })}
      {sharedUsersList.length > MAX_AVATARS_DISPLAYED && (
        <Tooltip
          key="overflow-avatar-tooltip"
          open={showOverFlowAvatarTooltip}
          arrow
          title={styledTooltipTitle(
            sharedUsersList
              .slice(MAX_AVATARS_DISPLAYED, sharedUsersList.length)
              .map((sharedUser) => {
                return (
                  <div key={sharedUser.user.id}>
                    {getToolTipContent(sharedUser.user)}
                    <br />
                  </div>
                );
              })
          )}
        >
          <Avatar
            //onClick={handleClick}
            key="overflow-avatar"
            onMouseEnter={() => {
              setShowOverFlowAvatarTooltip(true);
            }}
            onMouseLeave={() => {
              setShowOverFlowAvatarTooltip(false);
            }}
            color="red"
            style={{
              border: "thick double #fff",
              cursor: "pointer",
              fontSize: size == Size.SMALL ? "1.1rem" : "1.7rem",
              width: size == Size.SMALL ? "30px" : "45px",
              height: size == Size.SMALL ? "30px" : "45px",
              cursor: "pointer",
              marginLeft: size == Size.SMALL ? "-12px" : "-12px",
            }}
          >
            +{sharedUsersList.length - MAX_AVATARS_DISPLAYED}
          </Avatar>
        </Tooltip>
      )}
      {!hideConfigureAccess && (
        <Tooltip
          key="manage-access"
          open={showManageTooltip}
          arrow
          title={styledTooltipTitle(t("manage_user_access_title"))}
        >
          <IconButton
            key="manage-access-tooltip"
            onMouseEnter={() => {
              setShowManageTooltip(true);
            }}
            onMouseLeave={() => {
              setShowManageTooltip(false);
            }}
            onClick={onManageAccessClicked}
          >
            <ManageAccountsIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default React.memo(SharedUsersAvatars);

import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { addNewCollectionGql, updateCollectionGql } from "graphql/mutations";
import { isCollectionShared, CollectionObj } from "models/collection";
import { useMutation, useApolloClient } from "@apollo/client";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import {
  updateCollectionsCache,
  updateCollectionInCache,
  updateTopLevelCollectionsCache,
} from "graphql/helpers";
import FormHelperText from "@mui/material/FormHelperText";
import { COLLECTION_NAME_EXISTS_ALREADY } from "graphql/errors";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import { setCollectionsMaxLastUsedScore } from "store/actions/collectionsActions";
import { useQuery } from "@apollo/client";
import {
  retrieveAllCollectionsGql,
  retrieveChildCollectionsForParentGql,
  retrieveTopLevelCollectionsGql,
} from "graphql/queries";
import { MAX_SHARED_COLLECTIONS_COUNT_FREE_USER } from "configuration";
import MaxDataUsageLimitReachedDialog from "components/UI/Modals/MaxDataUsageLimitReachedDialog";
import { isUserOnFreePlan } from "models/user";
import { RootState } from "store/reducers";

interface AddNewCollectionProps {
  open: boolean;
  handleModalClose: (open: boolean) => void;
  isOpenedInRenameMode?: boolean;
  collectionIdToRename?: string;
  collectionNameToRename?: string;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    form: {
      margin: theme.spacing(1),
      marginTop: "56px",
      fontSize: "8rem",
    },
    inputLabel: {
      width: "100%",
      fontSize: "1.9rem",
      padding: "4px",
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
    addButton: {
      marginEnd: "12px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: "24px",
    },
  })
);

export default function AddNewCollectionModal(props: AddNewCollectionProps) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState<string>(
    props.isOpenedInRenameMode ? props.collectionNameToRename : ""
  );
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const client = useApolloClient();
  const [
    displayMaxSharedCollectionsReached,
    setDisplayMaxSharedCollectionsReached,
  ] = React.useState(false);
  const [addNewCollection, { loading, data, error }] = useMutation(
    addNewCollectionGql,
    {
      update(cache, { data: { createCollection } }) {
        // if (createCollection) {
        //   updateCollectionsCache(
        //     cache,
        //     retrieveAllCollectionsGql,
        //     createCollection.collection
        //   );
        // }
      },
    }
  );

  const allCollectionsQueryResult = useQuery(retrieveAllCollectionsGql);

  const [updateCollection, updateCollectionResult] = useMutation(
    updateCollectionGql,
    {
      update(cache, { data: { updateCollection } }) {
        if (updateCollection) {
          updateCollectionInCache(cache, updateCollection.collection);
        }
      },
    }
  );
  const dispatch = useDispatch();

  const maxLastUsedScore: number = useSelector(
    (state: RootState) => state.collectionsReducer.maxLastUsedScore
  );

  const { t } = useTranslation();
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const textInput = e.target.value;
    setInputValue(textInput);
  };

  const isNumberOfCollectionsGreaterThan = (
    collections: CollectionObj[],
    max: number
  ) => {
    var sharedCollectionsCount = 0;
    for (var i = 0; i < collections.length; i++) {
      if (!isCollectionShared(collections[i])) {
        ++sharedCollectionsCount;
      }
      if (sharedCollectionsCount >= max) {
        return true;
      }
    }
    return false;
  };

  const handleClick = () => {
    if (
      allCollectionsQueryResult.data.collections &&
      isUserOnFreePlan(user) &&
      isNumberOfCollectionsGreaterThan(
        allCollectionsQueryResult.data.collections,
        MAX_SHARED_COLLECTIONS_COUNT_FREE_USER
      )
    ) {
      setDisplayMaxSharedCollectionsReached(true);
      return;
    }

    setIsButtonLoading(true);
    if (props.isOpenedInRenameMode) {
      console.log(
        "input value " +
          inputValue +
          " collection " +
          props.collectionNameToRename
      );
      if (inputValue == props.collectionNameToRename) {
        close();
        return;
      }
      const collection: CollectionObj = {
        id: props.collectionIdToRename,
        name: inputValue.trim(),
      };
      updateCollection({
        variables: {
          input: collection,
        },
      })
        .then((data) => {
          close();
        })
        .catch((e) => {
          setIsButtonLoading(false);
        });
    } else {
      const collection: CollectionObj = {
        name: inputValue.trim(),
        lastUsedScore: maxLastUsedScore + 1,
        parentCollection: currentlyOpenedCollection
          ? currentlyOpenedCollection.id
          : undefined,
      };
      addNewCollection({
        variables: {
          input: collection,
        },
        refetchQueries: currentlyOpenedCollection
          ? [
              {
                query: retrieveChildCollectionsForParentGql,
                variables: {
                  parentId: currentlyOpenedCollection.id,
                },
              },
            ]
          : undefined,
      })
        .then((data) => {
          dispatch(setCollectionsMaxLastUsedScore(maxLastUsedScore + 1));
          //if (data.data.createCollection) {
          if (data.data.createCollection) {
            if (!currentlyOpenedCollection) {
              updateTopLevelCollectionsCache(
                client.cache,
                retrieveTopLevelCollectionsGql,
                data.data.createCollection.collection
              );
            }
            close();
          }
          // }
        })
        .catch((e) => {
          setIsButtonLoading(false);
        });
    }
  };

  function close() {
    setInputValue("");
    setIsButtonLoading(false);
    props.handleModalClose(false);
  }

  const getErrorMessage = useCallback(() => {
    var responseErrorMsg;
    console.log(
      "Update collection error" + JSON.stringify(updateCollectionResult.error)
    );
    if (!error && !updateCollectionResult) {
      return "";
    }
    if (error) {
      responseErrorMsg = error.message;
    } else if (updateCollectionResult) {
      responseErrorMsg = updateCollectionResult?.error?.message;
    } else responseErrorMsg = "";

    try {
      switch (JSON.parse(responseErrorMsg!!).error_code) {
        case COLLECTION_NAME_EXISTS_ALREADY:
          return t("failed_to_create_collection_name_exists_already");
        default:
          return t("failed_to_create_collection");
      }
    } catch (e) {
      return t("failed_to_create_collection");
    }
  }, [error, updateCollectionResult]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={() => props.handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <Title>
              {props.isOpenedInRenameMode
                ? t("rename_collection_dialog_title", {
                    collection_name: props.collectionNameToRename,
                  })
                : t("create_collection_dialog_title")}
            </Title>
            <TextField
              label="Collection Name"
              variant="standard"
              className={classes.inputLabel}
              value={inputValue}
              onChange={handleChange}
              color="primary"
              inputProps={{ style: { fontSize: "large" } }}
              InputLabelProps={{ style: { fontSize: "medium" } }}
              focused
            />
            {(updateCollectionResult.error || error) && (
              <FormHelperText
                id="component-error-text"
                style={{ color: "red", fontSize: "small" }}
              >
                {getErrorMessage()}
              </FormHelperText>
            )}
            <div className={classes.buttonContainer}>
              <LoadingButton
                disabled={!(inputValue.length > 0)}
                loading={isButtonLoading}
                className={classes.addButton}
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                <Typography variant="h6">
                  {props.isOpenedInRenameMode
                    ? t("rename_title")
                    : t("create_title")}
                </Typography>
              </LoadingButton>
            </div>
            <MaxDataUsageLimitReachedDialog
              open={displayMaxSharedCollectionsReached}
              description={t("max_free_tier_collections_reached", {
                max_shared_collections_count: MAX_SHARED_COLLECTIONS_COUNT_FREE_USER,
              })}
              handleModalClose={() => {
                setDisplayMaxSharedCollectionsReached(false);
              }}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}



import axios, { AxiosResponse } from "axios";
import { SERVER_URL, DEBUG } from "configuration";

const AUTH_URL_API_SUBPATH = "/integrations/pinterest-auth-url"
const AUTH_USER_API_SUBPATH = "/integrations/auth-pinterest"
const DISCONNECT_PINTEREST_API_SUBPATH = "/integrations/pinterest-disconnect"
const FETCH_PINTEREST_INFORMATION_API_SUBPATH = "/integrations/pinterest-info"
const SET_PINTEREST_COLLECTION_API_SUBPATH = "/integrations/pinterest-set-collection"


export interface CollectionInfo {
    name: string;
    id: string ;
    isArchived: boolean;
    previewUrls: string[] | null;
}
export interface PinterestIntegrationInformation {
    isConnected: boolean;
    userName: string | null;
    userAvatar: string | null;
    collectionInfo: CollectionInfo | null;
}


export interface AuthenticateUserResponse {
    resultCode: number;
    integrationInfo: PinterestIntegrationInformation | null;
}

export interface SetCollectionResponse {
    resultCode: number;
    collectionInfo: CollectionInfo | null;
}


export const fetchIntegrationInformation = async (): Promise<PinterestIntegrationInformation> => {
    try {
        let res = await axios.get(SERVER_URL + FETCH_PINTEREST_INFORMATION_API_SUBPATH,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("fetchIntegrationInformation: result" + JSON.stringify(res));
        }

        return {
            isConnected: res.data.connected,
                userName: res.data.information?.user_name,
                userAvatar: res.data.information?.user_avatar,
                collectionInfo: res.data.information?.collection_info ? {
                    name : res.data.information?.collection_info?.name,
                    id : res.data.information?.collection_info?.id,
                    previewUrls : res.data.information?.collection_info?.preview_urls,
                    isArchived : res.data.information?.collection_info?.is_archived
                } : null
        }
    } catch (e) {
        throw (e)
    }
}


export const disconnectCurrentIntegration = async (): Promise<boolean> => {
    try {
        let res = await axios.get(SERVER_URL + DISCONNECT_PINTEREST_API_SUBPATH,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("disconnectCurrentIntegration: result" + JSON.stringify(res));
        }

        return res.status == 200
    } catch (e) {
        throw (e)
    }
}

export const fetchPinterestAuthUrl = async (): Promise<string> => {
    try {
        let res = await axios.post(SERVER_URL + AUTH_URL_API_SUBPATH,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("fetchAuthUrl: result" + JSON.stringify(res));
        }
        return res.data.url

    } catch (e) {
        throw (e)
    }
}

export const authenticateUser = async (code: string): Promise<AuthenticateUserResponse> => {
    try {
        let res = await axios.post(SERVER_URL + AUTH_USER_API_SUBPATH,
            {
                code: code
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("authenticateUser: result" + JSON.stringify(res));
        }
        return {
            resultCode: res.data.result,
            integrationInfo: {
                isConnected: res.data.connected,
                userName: res.data.information?.user_name,
                userAvatar: res.data.information?.user_avatar,
                collectionInfo: res.data.information?.collection_info ? {
                    name : res.data.information?.collection_info?.name,
                    id : res.data.information?.collection_info?.id,
                    previewUrls : res.data.information?.collection_info?.preview_urls,
                    isArchived : res.data.information?.collection_info?.is_archived
                } : null
            }
        };
    } catch (e) {
        throw (e)
    }
}

export const setDestinationCollection = async (collectionId: string): Promise<SetCollectionResponse> => {
    try {
        let res = await axios.post(SERVER_URL + SET_PINTEREST_COLLECTION_API_SUBPATH,
            {
                id: collectionId
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("setDestinationCollection: result" + JSON.stringify(res));
        }
        return {
            resultCode: res.status,
            collectionInfo: res.data.collection_info ?{
                name : res.data.collection_info?.name,
                id : res.data?.collection_info?.id,
                previewUrls : res.data?.collection_info?.preview_urls,
                isArchived : res.data?.collection_info?.is_archived
            } : null
        };
    } catch (e) {
        throw (e)
    }
}
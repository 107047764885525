import { Box, Typography } from "@mui/material";
import { getNoteImageForId } from "configuration";
import { AggDataObj, NoteImage } from "models/aggdata";
import React from "react";
import { useDispatch } from "react-redux";
import { displayNoteImagesViewer } from "store/actions/view";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

interface NoteEditorImagesPreviewerProps {
  noteImages: NoteImage[];
  aggData: AggDataObj;
}

const NoteEditorImagesPreviewer = ({
  noteImages,
  aggData,
}: NoteEditorImagesPreviewerProps) => {
  const imageHeight = "48px";
  const hintTextSize = "2rem";

  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        paddingRight: "5%",
        paddingLeft: "5%"
      }}
    >
      <ImageList variant="masonry" cols={4} gap={8}>
        {noteImages.map((noteImage) => (
          <ImageListItem key={noteImage.id}>
            <img
              src={`${getNoteImageForId(
                noteImage.id
              )}?w=248&fit=crop&auto=format`}
              srcSet={`${getNoteImageForId(
                noteImage.id
              )}?w=248&fit=crop&auto=format&dpr=2 2x`}
              loading="lazy"
              style={{
                borderRadius: "4px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                console.log("Clicked on note iamges");
                dispatch(
                  displayNoteImagesViewer(
                    noteImages,
                    noteImage,
                    aggData,
                    "Note Images"
                  )
                );
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      {/*    {noteImages.map((id, index) => {
        if (index > 2) {
          return null;
        }
        if (index == 2) {
          return (
            <Box
              style={{
                position: "relative",
                flex: 1,
                height: imageHeight,
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                }}
                src={getNoteImageForId(id.id)}
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "100%",
                  height: "100%",
                  color: "white",
                  backgroundColor: "#57575780",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "2rem",
                    textAlign: "center",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  {"+" + (noteImages.length-2)}
                </Typography>
              </Box>
            </Box>
          );
        }
        return (
          <img
            style={{
              objectFit: "cover",
              flex: 1,
            }}
            src={getNoteImageForId(id.id)}
            height={imageHeight}
          />
        );
      })} */}
    </Box>
  );
};

export default React.memo(NoteEditorImagesPreviewer);

import React from "react";
import styled from "styled-components";
import { IconButton, Link as TextLink, Typography } from "@mui/material";
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "navigation/Constants";
import { useTranslation } from "react-i18next";
import { colorMemento } from "shared/colors";
import { capitalizeFirstLetter } from "shared/utility";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { SUPPORT_CONTACT_EMAIL } from "configuration";
import { Tooltip } from "@mui/material";
import { styledTooltipTitle } from "shared/styles";

const MainContainer = styled.div`
  padding: 4em;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

function Footer() {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <TextLink
        style={{
          paddingRight: "12px",
          fontSize: "1.8rem",
          color: "black",
          cursor: "pointer",
        }}
        onClick={() => {
          window.open(PRIVACY_POLICY, "_blank");
        }}
      >
        {capitalizeFirstLetter(t("privacy_policy"))}
      </TextLink>
      <Typography variant="h4">•</Typography>

      <TextLink
        style={{
          paddingLeft: "12px",
          paddingRight: "12px",
          fontSize: "1.8rem",
          color: "black",
          cursor: "pointer",
        }}
        onClick={() => {
          window.open(TERMS_CONDITIONS, "_blank");
        }}
      >
        {capitalizeFirstLetter(t("terms_and_conditions"))}
      </TextLink>
      <Typography variant="h4">•</Typography>

      <Tooltip
        title={styledTooltipTitle(
          t("reach_us_at_title") + SUPPORT_CONTACT_EMAIL
        )}
      >
        <TextLink
          style={{
            paddingLeft: "12px",
            fontSize: "1.8rem",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open("mailto:" + SUPPORT_CONTACT_EMAIL, "_blank");
          }}
        >
          {capitalizeFirstLetter(t("contact_us_text"))}
        </TextLink>
      </Tooltip>

      <div style={{ paddingLeft: "5%" }}>
        <IconButton
          onClick={() => {
            window.open("https://www.facebook.com/mementoappcollect", "_blank");
          }}
        >
          <FacebookIcon fontSize="large" />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open("https://www.instagram.com/app_memento/", "_blank");
          }}
        >
          <InstagramIcon fontSize="large" />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open("https://twitter.com/app_memento", "_blank");
          }}
        >
          <TwitterIcon fontSize="large" />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open("https://www.youtube.com/@mementoapp3859", "_blank");
          }}
        >
          <YouTubeIcon fontSize="large" />
        </IconButton>
      </div>
    </MainContainer>
  );
}

export default Footer;

import styled, { keyframes } from "styled-components";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { AggDataObj } from "models/aggdata";
import {
  AggDataTitle,
  AggDataContent,
  AggDataImageContent,
  AggDataFileImgPreviewContent,
  AggDataFilePagesInfoContainer,
  AggDataSubTitle,
} from "containers/AggData/AggDataElements";
import { getLinkPreviewInformation, DataType } from "models/aggdata";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { highlightText } from "shared/utility";
import { Box, IconButton, Typography } from "@mui/material";
import LazyLoad from "react-lazyload";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { colorMementoBeige, colorGreyMemento } from "shared/colors";
import { NoteTask } from "shared/types";
import { parseNoteTasksAsSortedList } from "shared/dataUtils";
import NoteTasksList from "containers/AggData/NoteEditor/NoteTasksList";
import parse from "html-react-parser";
import { getImageForDataId, getFilePathForDataId } from "configuration";
import { setEditableAggdataNote } from "store/actions/aggdataActions";
import { getNoteColorFromColorIndex } from "shared/colors";
import AudioPlayer from "components/Player/AudioPlayer";
import PlayCircleFilledWhiteRoundedIcon from "@mui/icons-material/PlayCircleFilledWhiteRounded";
import NoteImagePreview, {
  PreviewType,
} from "containers/AggData/NoteEditor/NoteImagePreview";

interface AggdataCommentViewProps {
  aggData: AggDataObj;
}

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  overflow-x: hidden;
  cursor: pointer;
`;

const MainAggdataContainer = styled("div")<{
  bgColor?: string;
}>`
  display: flex;
  width: 90%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  border-radius: 8px;
  border: 1px solid #fdc84b;
  background: ${(props) => (props.bgColor ? props.bgColor : "#ffffffff")};
`;

const HeaderContainer = styled.div`
  display: flex;
  height: 20%;
  width: 90%;
  flex-direction: row;
  overflow-x: hidden;
`;

const AggdataCommentView = ({ aggData }: AggdataCommentViewProps) => {
  const [noteTasks, setNoteTasks] = useState<NoteTask[]>(
    parseNoteTasksAsSortedList(aggData?.note?.noteTasks)
  );

  const dispatch = useDispatch();
  const [videoClicked, setVideoClicked] = useState(false);
  const videoRef = React.useRef<any>();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      const event = e.target as HTMLElement;
      // console.log(
      //   "handleClick " + event.tagName + "the type" + event.getAttribute("type")
      // );
      if (aggData.dataType == DataType.WEB_URL) {
        const url = aggData.url;
        if (url) {
          window.open(url, "_blank");
        }
      } else if (aggData.dataType == DataType.IMAGE_TYPE) {
      } else if (aggData.dataType == DataType.FILE_TYPE) {
        window.open(getFilePathForDataId(aggData.id), "_blank");
      } else if (aggData.dataType == DataType.NOTE_TYPE) {
        //dispatch(getEditableData(aggData));
        //setBeingEditedNoteAggDataId && setBeingEditedNoteAggDataId(editedAggData.id)
        //setIsEditing(true);
        dispatch(setEditableAggdataNote(aggData));
      }
    },
    [dispatch, aggData]
  );

  React.useEffect(() => {
    if (videoClicked && videoRef.current) {
      if (
        aggData.dataType == DataType.VIDEO_TYPE &&
        videoRef &&
        videoRef.current
      ) {
        videoRef.current.src = getFilePathForDataId(aggData.id);
        videoRef.current.load();
      }
    }
  }, [videoClicked]);

  const getAggdataView = () => {
    if (aggData.dataType == DataType.WEB_URL) {
      const previewInformation = getLinkPreviewInformation(aggData);
      var image = (
        <InsertLinkIcon
          style={{
            width: "100%",
            height: "70px",
            background: "#faf6e9",
            borderRadius: "12px",
            marginTop: "8px",
            marginBottom: "8px",
          }}
        />
      );
      var title = "";
      var description = "";
      var hostUrl = "";
      if (previewInformation) {
        title = previewInformation.title!!;
        description = previewInformation.description!!;
        hostUrl = previewInformation.hostUrl;
        if (previewInformation.imgUrl === "None") {
          image = (
            <InsertLinkIcon
              style={{
                width: "100%",
                height: "70px",
                background: "#faf6e9",
                borderRadius: "12px",
                marginTop: "8px",
                marginBottom: "8px",
              }}
            />
          );
        } else {
          image = (
            <img
              src={previewInformation.imgUrl}
              style={{
                paddingTop: "12px",
                paddingBottom: "8px",
                width: "auto",
                padding: "12px",
                height: "auto",
                borderRadius: "24px",
                maxHeight: "350px",
                objectFit: "cover",
              }}
              loading="lazy"
            />
          );
        }
      } else {
        description = aggData.url;
      }
      return (
        <Box
          sx={{
            display: "flex",
            width:"100%",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <MainAggdataContainer
            style={{
              height: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AggDataTitle>{highlightText(title, "")}</AggDataTitle>
            {image}
            <AggDataContent>
              {highlightText(description === "None" ? "" : description, "")}
            </AggDataContent>

            <Typography
              variant="h6"
              color="gray"
              sx={{ fontWeight: "400", padding: "0px 12px 8px 12px" }}
            >
              {hostUrl}
            </Typography>
          </MainAggdataContainer>
        </Box>
      );
    } else if (aggData.dataType === DataType.IMAGE_TYPE) {
      return (
        <MainAggdataContainer
          style={{
            height: "100%",
            border: "none",
          }}
        >
          <AggDataImageContent>
            <img
              src={getImageForDataId(aggData.id)}
              style={{
                width: "auto",
                objectFit: "contain",
                overflow: "hidden",
                borderRadius: "16px",
                maxHeight: "100%",
                //display: isImgLoading ? "none" : "block",
              }}
              //   onLoadStart={() => {
              //     setIsImgLoading(true);
              //   }}
              //   onLoad={() => {
              //     setIsImgLoading(false);
              //   }}
            />
          </AggDataImageContent>
        </MainAggdataContainer>
      );
    } else if (aggData.dataType === DataType.AUDIO_TYPE) {
      return (
        <MainAggdataContainer
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            border: "none",
          }}
        >
          <AudioPlayer
            aggdataObj={aggData}
            autoPlay={false}
            floatingMode={false}
          />
        </MainAggdataContainer>
      );
    } else if (aggData.dataType === DataType.VIDEO_TYPE) {
      return (
        <MainAggdataContainer
          style={{
            height: "100%",
            border: "none",
          }}
        >
          {videoClicked && (
            <video
              ref={videoRef}
              poster={getImageForDataId(aggData.id)}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "auto",
                objectFit: "contain",
                overflow: "hidden",
                borderRadius: "16px",
                maxHeight: "100%",
              }}
              controls
              autoPlay
            >
              <source src={getFilePathForDataId(aggData.id)} />
            </video>
          )}
          {!videoClicked && (
            <AggDataImageContent>
              <img
                src={getImageForDataId(aggData.id)}
                style={{
                  /* height: "auto",
                maxHeight: "15Opx",
                objectFit: "contain",
                overflow: "hidden",
                overflow: "hidden", */

                  width: "auto",
                  objectFit: "contain",
                  overflow: "hidden",
                  borderRadius: "16px",
                  maxHeight: "100%",

                  //display: isImgLoading ? "none" : "block",
                }}
                //   onLoadStart={() => {
                //     setIsImgLoading(true);
                //   }}
                //   onLoad={() => {
                //     setIsImgLoading(false);
                //   }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                }}
                onClick={() => {
                  setVideoClicked(true);
                }}
              >
                <PlayCircleFilledWhiteRoundedIcon
                  sx={{
                    color: "white",
                    width: "48px",
                    height: "48px",
                  }}
                ></PlayCircleFilledWhiteRoundedIcon>
              </IconButton>
            </AggDataImageContent>
          )}
        </MainAggdataContainer>
      );
    } else if (aggData.dataType === DataType.FILE_TYPE) {
      return (
        <MainAggdataContainer>
          <AggDataFileImgPreviewContent>
            <div
              style={{
                backgroundColor: colorMementoBeige,
                width: "100%",
                height: "12px",
              }}
            ></div>
            <img
              src={getImageForDataId(aggData.id)}
              style={{
                width: "70%",
              }}
              loading="lazy"
            />
          </AggDataFileImgPreviewContent>
          <AggDataTitle>
            {highlightText(aggData.file?.fileName!!, "")}
          </AggDataTitle>
          <AggDataFilePagesInfoContainer>
            <AggDataSubTitle> {aggData?.file?.pagesCount}</AggDataSubTitle>
            <MenuBookIcon style={{ color: colorGreyMemento }} />
          </AggDataFilePagesInfoContainer>
        </MainAggdataContainer>
      );
    } else {
      return (
        <MainAggdataContainer
          bgColor={getNoteColorFromColorIndex(aggData?.note?.noteColor)}
        >
          {aggData?.note?.noteimageSet &&
            aggData?.note?.noteimageSet.length > 0 && (
              <NoteImagePreview
                noteImageIds={aggData?.note?.noteimageSet}
                previewType={PreviewType.FEED}
                aggData={aggData}
              />
            )}
          <AggDataTitle>
            {highlightText(aggData?.note?.noteTitle!!, "")}
          </AggDataTitle>
          {/* {isChecked ? (
               <TodoList todoContent={todos} />
             ) : (
               <AggDataContent>{highlightText(content, searchQuery)}</AggDataContent>
             )} */}
          <AggDataContent>
            {parse(
              aggData?.note?.noteContent
                ? aggData?.note?.noteContent
                : "<br/><br/>"
            )}

            {/* {highlightText(aggData?.note?.noteContent!!, "")} */}
          </AggDataContent>

          {noteTasks.length > 0 && (
            <>
              <NoteTasksList readOnly={true} noteTasksArray={noteTasks} />
              <div style={{ height: "8px" }}></div>
            </>
          )}
        </MainAggdataContainer>
      );
    }
  };
  return (
    <MainContainer onClick={handleClick}>{getAggdataView()}</MainContainer>
  );
};

export default React.memo(AggdataCommentView);


import { UserAuthState } from "store/actions/authActions"
import { LoginUser, LogoutUser, SetUserSubscriptionInfo, UserAuthActionTypes } from '../actions/authActions';
import { updateObject } from 'shared/utility';
import { isUserLoggedIn, setUserIsloggedIn, setUserIsloggedOut } from '../../localstorage/localstorage';
import { CHROME_EXTENSION_ID } from "configuration";
import * as actionTypes from 'store/actions/authActions';
import { MESSAGE_TYPE_LOGOUT } from 'shared/extension';

function notifyLogoutToExtension() {
  if (window && window.chrome && window.chrome.runtime) {
    window.chrome.runtime.sendMessage(CHROME_EXTENSION_ID, {
      type: MESSAGE_TYPE_LOGOUT,
    });
  }
}

const loginUser = (state: UserAuthState, action: LoginUser) => {
  const newUserState = {
    ...state,
    user: action.payload,
    isUserLoggedIn: true,
  };
  setUserIsloggedIn();
  return updateObject(state, newUserState)
}

const setUserSubscriptionInformation = (state: UserAuthState, action: SetUserSubscriptionInfo) => {
  const newUserState = {
    ...state,
    userSubscriptionInfo: action.payload,
  };
  return updateObject(state, newUserState)
}

const logoutUser = (state: UserAuthState, action: LogoutUser) => {
  const newUserState = {
    ...state,
    user: null,
    isUserLoggedIn: false
  };
  setUserIsloggedOut();
  notifyLogoutToExtension();
  return updateObject(state, newUserState)
}

const initialState: UserAuthState = {
  user: null,
  userSubscriptionInfo: null,
  isUserLoggedIn: isUserLoggedIn(),
};

// REDUCER
const reducer = (state = initialState, action: UserAuthActionTypes) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return loginUser(state, action);
    case actionTypes.LOGOUT_USER:
      return logoutUser(state, action)
    case actionTypes.SET_USER_SUBSCRIPTION_INFO:
      return setUserSubscriptionInformation(state, action)
    default:
      return state;
  }
};

export default reducer;


import React from "react";
import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { UserObj } from "models/user";
import { RootState } from "store/reducers";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { logout, LogoutApiResult, LogoutStatusCodes } from "api/authApi";
import { useDispatch } from "react-redux";
import { logoutUser as logoutReducerAction } from "store/actions/authActions";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material/styles";
import BoltIcon from "@mui/icons-material/Bolt";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { PRICING, SETTINGS } from "navigation/Constants";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import DataUsageProgressBar from "components/DataUsageProgress/DataUsageProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    width: "100%",
    minWidth: 480,
    boxShadow: "0 1px 5px 0 rgba(60, 64, 67, 0.302)",
    zIndex: 1005,
  },

  progressbar: {
    width: "100%",
  },
}));

function ListItemLink(props: any) {
  return <ListItem button component="a" {...props} />;
}

interface PorpertyInterface {
  closeMenu: () => void;
}

export default function FloatingUserMenu(props: PorpertyInterface) {
  const classes = useStyles();
  const userObj: UserObj = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const handleLogout = useCallback(() => {
    logout().then((result: LogoutApiResult) => {
      if (result.statusCode == LogoutStatusCodes.SUCCESS) {
        dispatch(logoutReducerAction());
      }
    });
  }, []);

  const getSubscriptionPlanTitle = useCallback(() => {
    if (userObj.subscriptionPlan.id == 0) {
      return t("tier_free_title");
    } else if (userObj.subscriptionPlan.id == 1) {
      return t("tier_mementee_title");
    } else {
      return "";
    }
  }, [userObj]);

  const handleUpgradeButtonClicked = useCallback(() => {
    history.push(PRICING);
  }, [history]);

  const handleGoToSettings = useCallback(() => {
    history.push(SETTINGS);
  }, [history]);

  //console.log("Usermomo %o" , userObj)
  return (
    <ClickAwayListener onClickAway={props.closeMenu}>
      <Paper className={classes.root}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem>
            <ListItemText
              secondary={getSubscriptionPlanTitle()}
              secondaryTypographyProps={{
                fontSize: "1.2rem",
              }}
              primary={userObj.firstName + " " + userObj.lastName}
              primaryTypographyProps={{
                fontSize: "2.2rem",
              }}
            />
            {userObj && userObj.subscriptionPlan.id == 0 && (
              <div style={{ textAlign: "center" }}>
                <IconButton
                  onClick={handleUpgradeButtonClicked}
                  aria-label="delete"
                > 
                  <BoltIcon
                    sx={{
                      height: "3rem",
                      width: "3rem",
                      color: theme.palette.primary.main,
                    }}
                  ></BoltIcon>
                </IconButton>
              </div>
            )}
          </ListItem>
          <ListItem>
            <DataUsageProgressBar />
          </ListItem>
        </List>
        <Divider />
        <List component="nav" aria-label="secondary mailbox folders">
          <ListItemLink onClick={handleGoToSettings}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={t("settings_action_title")}
              primaryTypographyProps={{
                fontSize: "1.4rem",
              }}
            />
          </ListItemLink>
          <ListItemLink onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary={t("logout_action_title")}
              primaryTypographyProps={{
                fontSize: "1.4rem",
              }}
            />
          </ListItemLink>
        </List>
      </Paper>
    </ClickAwayListener>
  );
}

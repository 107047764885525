import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import { colorMemento } from "shared/colors";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import { RootState } from "store/reducers";
import { UserObj } from "models/user";
import { makeStyles } from "@mui/styles";
import Popper from "@mui/material/Popper";
import FloatingUserMenu from "components/layout/FloatingUserMenu/FloatingUserMenu";
import { useTranslation } from "react-i18next";
import { setSearchInput } from "store/actions/view";
import FullLogoIcon from "icons/memento_full.svg";
import { isUserLoggedIn } from "../../localstorage/localstorage";
import { getUserAvatarForEmail, getUserAvatarForId } from "configuration";

const LogoContainer = styled.img`
  width: 10%;
  margin-left: 10%;
  @media (max-width: 780px) {
    width: 30%;
  }
`;

const HeaderContainer = styled.header`
  width: 100%;
  height: 10vh;
  padding-top: 16px;
  //border-bottom: 0.5px solid rgba(194, 194, 194, 0.05);
  //box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.302);
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;

  // &:hover {
  //   background-color: ${colorMemento}};
  // }
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 5,
  },
  searchField: {
    "& .MuiInputBase-input": {
      fontSize: "2rem",
      padding: "12px 12px",
    },

    "& .MuiInputBase-root": {
      borderRadius: "8px",
      background: "#e8e8e8",
    },

    "&:hover .MuiInputBase-root": {
      background: "white",
    },
    "&:Mui-focused .MuiInputBase-root": {
      background: "white",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    width: "45%",
    fontSize: "5rem",
  },
}));

interface SimpleHeaderProps {
  makeStatic: boolean;
}

const SimpleHeader = ({  makeStatic }: SimpleHeaderProps) => {
  const theme = useTheme();
  const userObj: UserObj = useSelector((state: RootState) => state.auth.user);
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const { t } = useTranslation();
  const [searchHint, setSearchHint] = React.useState(
    t("search_searchfield_hint")
  );
  const searchContext = useSelector((state) => state.view.searchContext);
  const dispatch = useDispatch();
  const userInitials =
    userObj?.firstName.charAt(0) + userObj?.lastName.charAt(0);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showToolTip, setShowTooltip] = React.useState(false);

  const handleClick = (event: any) => {
    setShowTooltip(false);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const tootltipText: string = t("header_avatar_tooltip");

  const avatarFilter = showToolTip ? "brightness(.9)" : "";

  const handleSearchInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const textInput = e.target.value;
    dispatch(setSearchInput(textInput));
  };
  return (
    <>
      <HeaderContainer
        style={{
          position: makeStatic ? "static" : "sticky",
        }}
      >
        <LogoContainer src={FullLogoIcon} alt="Logo" />

        {isUserLoggedIn && (
          <Tooltip
            open={showToolTip}
            arrow
            title={
              <h1
                style={{
                  fontWeight: 100,
                  fontSize: "1.4rem",
                  padding: "0.6rem",
                }}
              >
                {tootltipText}
              </h1>
            }
          >
            <Avatar
              onClick={handleClick}
              onMouseEnter={() => setShowTooltip(!open && true)}
              onMouseLeave={() => setShowTooltip(false)}
              color="primary"
              src={getUserAvatarForId(userObj?.id)}
              style={{
                marginRight: "10%",
                border: "thick double #fff",
                fontSize: "1.7rem",
                width: "45px",
                height: "45px",
                cursor: "pointer",
                filter: avatarFilter,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
              }}
            >
              {userInitials ? userInitials : ".."}
            </Avatar>
          </Tooltip>
        )}
        {userObj && (
          <Popper
            id={id}
            open={open}
            placement={"bottom-end"}
            anchorEl={anchorEl}
            className={classes.popper}
          >
            <FloatingUserMenu closeMenu={() => setAnchorEl(null)} />
          </Popper>
        )}
      </HeaderContainer>
    </>
  );
};

export default SimpleHeader;

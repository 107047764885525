import { getImageForDataId } from 'configuration';
import { UserObj, SharedUserAccess } from './user';


export interface CollectionObj {
    id?: string | null,
    name?: string | null,
    itemsCount?: number | null,
    previewUrls?: string[] | null,
    isArchived?: boolean | null ,
    isPublic?: boolean,
    createdAt?: string | null,
    pinScore: number ,
    lastActive: string | null,
    lastUsedScore: number,
    owner: UserObj,
    sharedUsers: SharedUserAccess[] | null,
  } 


export const isCollectionShared= (collection: CollectionObj | null | undefined) : boolean => {
  return Boolean(collection) && Boolean(collection?.sharedUsers) && collection?.sharedUsers?.length>0
}

/**
 * The backend provides for collectiotns an array for list URLS, which may contain either
 *    - The full URL to the image, that can be accessed directly
 *    - The data ID, under the format aggData:<ID>, which should be processed 
 * @param preview_urls 
 */
// export function buildPreviewUrlsArray(preview_urls: string[]){
//   preview_urls.forEach((item, index) => {
//     if (item.startsWith('aggData')){
//       const dataId = item.split(':')[1];
//       item = getImageForDataId(dataId);
//     }
//   })
// }
import React from "react";
import styled from "styled-components";
import SideNav from "components/layout/SideNav/SideNav";
import Header from "components/layout/Header/Header";
import MultiSelectionToolbar from "components/layout/MultiSelectionToolbar/MultiSelectionToolbar";
import { RootState } from "store/reducers/index";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "api/authApi";
import { loginUser } from "store/actions/authActions";
import {
  closedAttachDataToCollectionDialog,
  displayMaxDataUsageReachedDialog,
} from "store/actions/view";
import { useCallback, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { showSnackbar } from "store/actions/view";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";
import { logoutUser } from "store/actions/authActions";
import { useParams } from "react-router-dom";
import Home from "pages/Home";
import CollectionInvitation from "pages/CollectionInvitation";
import CollectionAggDataContainer from "components/CollectionItems/CollectionAggDataContainer";
import {
  COLLECTIONS_ROUTE_SUBPATH,
  ALL_ITEMS_ROUTE_SUBPATH,
  ALL_PENDING_ITEMS_ROUTE_SUBPATH,
  ARCHIVES_ROUTE_SUBPATH,
  REMINDERS_ROUTE_SUBPATH,
  TAG_ITEMS_ROUTE_SUBPATH,
} from "navigation/Constants";
import {
  retrieveAllDataGql,
  NOT_ALLOWED_READ_ONLY_ERROR,
} from "graphql/queries";
import ImageViewer from "components/ImageViewer/ImageViewer";
import ArchivedItemsContainer from "pages/ArchivedItemsContainer";
import RemindersItemsContainer from "pages/Reminders/RemindersItemsContainer";
import NoteEditor from "containers/AggData/NoteEditor/NoteEditor";
import {
  AggDataObj,
  DataType,
  Note,
  getLinkPreviewInformation,
} from "models/aggdata";
import SelectCollectionContainer from "containers/SelectCollectionContainer/SelectCollectionContainer";
import { CollectionObj } from "models/collection";
import { useTranslation } from "react-i18next";
import {
  updateAggDataGql,
  attachMultipleAggDataToCollectionGql,
  updateMultipleAggDataGql,
} from "graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { retrieveCollectionGql } from "graphql/queries";
import {
  updateAggDataInCache,
  updateAggDataArrayInCache,
  deleteSharedCollectionSpecificCache,
} from "graphql/helpers";
import { closeReminderDatePicker, addFiles } from "../../store/actions/view";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import ReminderDatePicker from "components/UI/ReminderDatePicker/ReminderDatePicker";
import { Reminder } from "models/aggdata";
import {
  setMaxSortOrderScore,
  setAggDataWithReminders,
  setMultiSelectedData,
} from "store/actions/aggdataActions";
import { SideNavEntries } from "components/layout/SideNav/SideNav";
import RemindersWorker from "workers/NextReminderWorker.worker";
import { COMPUTE_NEXT_REMINDER } from "workers/WorkersMessageRequests";
import { getImageForDataId, DEBUG } from "configuration";
import { currentTimestamp } from "shared/dataUtils";
import parse from "html-react-parser";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";
import MaxDataUsageLimitReachedDialog from "components/UI/Modals/MaxDataUsageLimitReachedDialog";
import {
  shouldDisplayNotificationPermissionRationale,
  setNotificatioPermissionnDisplayed,
} from "localstorage/localstorage";
import FilesDropArea from "components/UI/DragAndDrop/FilesDropArea";
import {
  retrieveCollectionsForIds,
  retrieveAggdataForCollectionGql,
} from "graphql/queries";
import { Helmet } from "react-helmet";
import { useApolloClient } from "@apollo/client";
import useNetworkStatus from "hooks/useNetworkStatus";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import AggdataCommentsContainer from "containers/Comments/AggdataCommentsContainer";
import CollectionsContainer from "components/CollectionItems/CollectionsContainer";
import NoteImagesViewer from "components/ImageViewer/NoteImagesViewer";
import AudioPlayer from "components/Player/AudioPlayer";
import { Box } from "@mui/material";
import TagsAggdataItemsContainer from "components/AggDataItems/TagsAggdataItemsContainer";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 90%;
  flex-direction: column;
  overflow-x: hidden;
`;

const DragFocusOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: transparent;
  z-index: 40000000000;
`;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "800px",
    "& > * + *": {
      marginTop: theme.spacing(4),
    },
    minWidth: "800px",
    fontSize: "2rem",
  },

  datePickerContainer: {
    "& .MuiButtonBase-root": {
      fontSize: "2rem",
      htmlFontSize: "2rem",
      color: "red",
    },

    "& .MuiPickersDay-root": {
      fontSize: "40px",
    },
  },
  datePicker: {
    color: "red",
    "& .MuiButtonBase-root": {
      fontSize: "2rem",
      htmlFontSize: "2rem",
      color: "red",
    },
    "& .MuiCalendarPicker-root": {
      button: {
        fontSize: "40px",
      },
      "& .MuiButtonBase-root": {
        fontSize: "40px",
        color: "red",
      },
      "& .MuiPickersDay-root": {
        fontSize: "40px",
      },
      "& .MuiPickersDay-dayWithMargin": {
        fontSize: "40px",
      },
    },
  },
}));

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

function MainBoard() {
  const { isOnline } = useNetworkStatus();

  const [
    openNotificationPermission,
    setOpenNotificationPermission,
  ] = React.useState(false);
  const [
    openMaxUsageReachedDialog,
    setOpenMaxUsageReachedDialog,
  ] = React.useState(true);
  const [sideNavSelectedEntry, setSideNavSelectedEntry] = React.useState(
    SideNavEntries.HOME_KEY
  );
  const { loading, error, data } = useQuery(retrieveAllDataGql, {
    // pollInterval: 500,
  });

  const client = useApolloClient();
  const userLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const aggDataClicked = useSelector(
    (state: RootState) => state.view.aggdataClicked
  );
  const openAttachDataToCollectionDialog = useSelector(
    (state: RootState) => state.view.displayAttachDataToCollectionDialog
  );
  const displayMaxDataUsageReached = useSelector(
    (state: RootState) => state.view.displayMaxDataUsageReachedDialog
  );
  //console.log("got displayMaxDataUsageReached" + displayMaxDataUsageReached);
  const openReminderDatePicker = useSelector(
    (state: RootState) => state.view.displayReminderDatePicker
  );
  const snackBar = useSelector((state: RootState) => state.view.snackBarState);
  const multiSelectedAggData = useSelector(
    (state: RootState) => state.aggdataReducer.multiSelectedAggData
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const displayImageViewer = useSelector(
    (state: RootState) => state.view.displayImageViewer
  );
  const displayNoteimageViewer = useSelector(
    (state: RootState) => state.view.displayNoteImageViewer
  );
  const displayCommentsView = useSelector(
    (state: RootState) => state.view.displayCommentsViewState
  );

  //console.log("comments %o", displayCommentsView)
  const nextReminderTask = React.useRef();
  const nextReminderWorker = React.useRef();
  const editableAggdataNote = useSelector(
    (state: RootState) => state.aggdataReducer.editableAggdataNote
  );
  const maxSortOrderScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxSortOrderScore
  );

  const [dragOverOngoing, setDragOverOngoing] = React.useState(false);

  const [updateAggData, updateAggDataResult] = useMutation(updateAggDataGql);
  const [updateMultipleAggData, updateMultipleAggDataResult] = useMutation(
    updateMultipleAggDataGql
  );
  const [
    attachMultipleAggDataToCollection,
    attachMultipleAggDataToCollectionResult,
  ] = useMutation(attachMultipleAggDataToCollectionGql);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rootRef = React.useRef(null);
  const dragOverlayRef = React.useRef(null);

  React.useEffect(() => {
    console.log("aggdataclicked " + JSON.stringify(aggDataClicked));
  }, [aggDataClicked]);

  React.useEffect(() => {
    //example of how a notification can be sent
    // var options = {
    //   body: "This is the body of the Notification",
    //   icon: "https://images.pexels.com/photos/853168/pexels-photo-853168.jpeg?    auto=compress&cs=tinysrgb&dpr=1&w=500",
    //   dir: "ltr"
    // };
    // var notification = new Notification("Notification Demo", options);
    return () => {
      //we terminate the worker
      if (nextReminderWorker && nextReminderWorker.current) {
        nextReminderWorker.current.terminate();
      }
    };
  }, []);

  React.useEffect(() => {
    if (error) {
      dispatch(setAggDataWithReminders([], false, true));
    }
  }, [error]);

  React.useEffect(() => {
    if (data && data.allAggdata && data.allAggdata.length > 0) {
      //We terminate previous worker, just in case ..

      nextReminderWorker.current = new RemindersWorker();
      nextReminderWorker.current.addEventListener("message", (event: any) => {
        // if (DEBUG) {
        //   console.log(
        //     "Work Got back message " + JSON.stringify(event.data.payload)
        //   );
        // }
        const aggDataWithReminders = event.data.payload.aggDataWithReminders;
        dispatch(setAggDataWithReminders(aggDataWithReminders, false, false));
        //At this stage if browser notifications are not supported/Or permission is not granted we just return.
        if (!("Notification" in window)) {
          if (DEBUG) {
            console.log("This browser does not support desktop notification");
          }
          return;
        } else if (Notification.permission != "granted") {
          // If it's okay let's create a notification
          if (DEBUG) {
            console.log("Notification permission has not been granted");
          }
          const notificationRequest = Notification.requestPermission();
          notificationRequest.then((result) => {
            if (result != "granted") {
              if (shouldDisplayNotificationPermissionRationale()) {
                setOpenNotificationPermission(true);
              }
            }
          });
          return;
        }

        const aggDataWithNextReminder = event.data.payload.aggData;
        if (aggDataWithNextReminder) {
          const nextReminderTriggerTime =
            event.data.payload.reminderTriggerTime;
          //If there was a previous task we clear it before scheduling the next one
          if (nextReminderTask && nextReminderTask.current) {
            clearTimeout(nextReminderTask.current);
          }

          const delayBeforeNextReminder =
            nextReminderTriggerTime - currentTimestamp();
          if (DEBUG) {
            //console.log("Will schedule next reminder nextReminderTriggerTime= "+ nextReminderTriggerTime+ "currentTime=" + currentTimestamp() )
            console.log(
              "Will schedule next reminder for data " +
                JSON.stringify(aggDataWithNextReminder) +
                " in " +
                delayBeforeNextReminder +
                " secs"
            );
          }
          //2147483647 is the max value that settimeout can take, otherwise it fires immediately
          //If next reminder is in more than 24 days (equiv to 2147483647ms) we just don't schedule it, assuming
          //it's too soon to schedule it.
          if (delayBeforeNextReminder * 1000 < 2147483647) {
            nextReminderTask.current = setTimeout(() => {
              buildAggDataReminderNotification(aggDataWithNextReminder);
              nextReminderWorker.current.postMessage({
                request: COMPUTE_NEXT_REMINDER,
                payload: data.allAggdata,
              });
            }, delayBeforeNextReminder * 1000);
          }
        }
      });
      nextReminderWorker.current.postMessage({
        request: COMPUTE_NEXT_REMINDER,
        payload: data.allAggdata,
      });
    } else if (
      data &&
      data.allAggdata &&
      data.allAggdata.length == 0 &&
      !loading &&
      !error
    ) {
      dispatch(setAggDataWithReminders([], false, false));
    }
  }, [data]);

  const buildAggDataReminderNotification = useCallback(
    (aggData: AggDataObj) => {
      var options = null;
      var notificationTitle = "";
      if (aggData.dataType == DataType.IMAGE_TYPE) {
        notificationTitle = t("reminder_notification_title_image");
        options = {
          body: t("reminder_notification_description_image"),
          icon: getImageForDataId(aggData.id),
        };
      } else if (aggData.dataType == DataType.FILE_TYPE) {
        notificationTitle = t("reminder_notification_title_file");
        options = {
          body: t("reminder_notification_description_file"),
          icon: getImageForDataId(aggData.id),
        };
      } else if (aggData.dataType == DataType.NOTE_TYPE) {
        const note = aggData.note;
        notificationTitle = note?.noteTitle
          ? "⏱️" + note.noteTitle
          : t("reminder_notification_title_note");
        const notePreview = buildNoteReminderNotificationPreview(note);
        console.log("schedule note preview " + notePreview);
        options = {
          body: notePreview,
        };
      } else if (aggData.dataType == DataType.WEB_URL) {
        notificationTitle = t("reminder_notification_title_link");
        const filePreviewInfo = getLinkPreviewInformation(aggData);
        options = {
          body: filePreviewInfo?.title,
          icon: filePreviewInfo?.imgUrl,
        };
      }
      if (DEBUG) {
        console.log("Display notification");
      }
      const notification = new Notification(notificationTitle, options);
      notification.onclick = () => {
        //event.preventDefault(); // empêcher le navigateur de passer le focus sur l'onglet de la navigation
        //openMementoWindowIfNotAlreadyOpened();
        //window.open(window.location.origin,'mywindow').focus()
        if (aggData.dataType == DataType.WEB_URL) {
          const filePreviewInfo = getLinkPreviewInformation(aggData);
          window.open(filePreviewInfo?.hostUrl, "__blank");
        } else {
          window.focus();
        }
        notification.close();
      };
    },
    []
  );

  const buildNoteReminderNotificationPreview = useCallback((note: Note) => {
    if (!note) {
      return "";
    }
    if (!note.noteContent && !note.noteTasks) {
      return "";
    }
    if (!note.noteTasks || JSON.parse(note.noteTasks).length < 1) {
      if (note.noteContent) return parse(note.noteContent).substring(0, 150);
    }
    const noteTasksCount = JSON.parse(note.noteTasks).length;
    if (noteTasksCount > 0) {
      if (noteTasksCount == 1) {
        return t("reminder_notification_description_note_tasks_singular");
      } else {
        return t("reminder_notification_description_note_tasks_plural", {
          note_tasks_count: noteTasksCount,
        });
      }
    }
    if (note.noteContent) {
      var substring = note.noteContent.substring(0, 1000);
      console.log("schedule substringed " + substring);
      var plainTextNoteContent = substring.replace(/<[^>]+>/g, "");
      return plainTextNoteContent;
    }
  }, []);

  const [content, setContent] = React.useState();
  const classes = useStyles();
  const params: any = useParams();

  const globalTheme = useTheme();

  const theme = createTheme({
    ...globalTheme,
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "1.8rem",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "1.4rem",
          },
        },
      },
      ClockPicker: {
        styleOverrides: {
          fontSize: "2rem",
        },
      },
    },
  });

  useEffect(() => {
    intializeUserData();
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    console.log("handle close for reason =" + reason);

    if (reason === "clickaway") {
      return;
    }
    dispatch(
      showSnackbar({ message: "", action: "", onAction: null, loading: false })
    );
  };

  const intializeUserData = useCallback(() => {
    if (userLoggedIn) {
      console.log("User is logged in");
      if (!user) {
        console.log("User data is not available");
        fetchUserData()
          .then((result) => {
            console.log("fetch result " + JSON.stringify(result));
            dispatch(loginUser(result));
          })
          .catch((error) => {
            if (error?.message?.includes("403")) {
              dispatch(logoutUser());
            }
          });
      }
    } else {
      console.log("User is not logged in going to path ");
    }
  }, [user]);

  const handleSnackBarAction = useCallback(
    (event: React.SyntheticEvent | React.MouseEvent) => {
      if (snackBar.onAction) {
        snackBar.onAction();
      }
      handleClose(event);
    },
    [snackBar]
  );

  //console.log("Berkok params are " + JSON.stringify(params));

  useEffect(() => {
    //console.log("MAinboard useeffect " + JSON.stringify(params));
    setSideNavSelectedEntry(SideNavEntries.HOME_KEY);
    if (params.subpathbis) {
      if (params.subpath == "archives") {
        if (params.subpathbis == "collection" && params.id) {
          setSideNavSelectedEntry(SideNavEntries.ARCHIVES_KEY);
          setContent(
            <CollectionAggDataContainer
              collectionId={params.id}
              isCollectionArchived
            />
          );
        } else {
          setSideNavSelectedEntry(SideNavEntries.ARCHIVES_KEY);
          setContent(<ArchivedItemsContainer />);
        }
      } else if (params.subpath == "collection") {
        //console.log("Momo render MainBoard coll id " + params.id);
        setContent(
          <CollectionAggDataContainer collectionId={params.subpathbis} />
        );
      } else if (params.subpath == TAG_ITEMS_ROUTE_SUBPATH) {
        //console.log("Momo render MainBoard coll id " + params.id);
        setContent(
          <TagsAggdataItemsContainer tagName={params.subpathbis} />
        );
      } else if (params.subpath == "invite") {
        console.log("Got the invite params " + JSON.stringify(params));
        if (params.subpathbis == "c") {
          setContent(
            <CollectionInvitation
              collectionId={params.id}
              inviteToken={params.token}
            ></CollectionInvitation>
          );
        }
      }
    } else if (params.subpath) {
      switch (params.subpath) {
        case COLLECTIONS_ROUTE_SUBPATH:
          setContent(<CollectionsContainer />);
          break;
        case ALL_ITEMS_ROUTE_SUBPATH:
          setContent(<Home tabName="all" />);
          break;
        case ALL_PENDING_ITEMS_ROUTE_SUBPATH:
          setContent(<Home tabName="pending" />);
          break;
        case ARCHIVES_ROUTE_SUBPATH:
          setSideNavSelectedEntry(SideNavEntries.ARCHIVES_KEY);
          setContent(<ArchivedItemsContainer />);
          break;
        case REMINDERS_ROUTE_SUBPATH:
          setSideNavSelectedEntry(SideNavEntries.REMINDERS_KEY);
          setContent(<RemindersItemsContainer />);
          break;
      }
    } else {
      setContent(<Home />);
    }
  }, [params, setSideNavSelectedEntry]);

  const addAggDataArrayToCollection = React.useCallback(
    (collection: CollectionObj, aggdataArray: AggDataObj[]) => {
      dispatch(closedAttachDataToCollectionDialog());
      if (DEBUG) {
        console.log(
          "addAggDataArrayToCollection: add data array " +
            JSON.stringify(aggdataArray) +
            " to collection " +
            collection.name
        );
      }
      //Verify in case we want to attach a single data, if it is  already attached to the destination collection. If yes, just notify, don't do anything
      if (
        aggdataArray &&
        aggdataArray.length == 1 &&
        aggdataArray[0].collection?.id == collection.id
      ) {
        dispatch(
          showSnackbar({
            message: t("item_already_in_collection", {
              collection_name: collection.name,
            }),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        dispatch(setMultiSelectedData([]));
        return;
      }

      const sourceCollectionsIds: string[] = [];

      const dataIdsArray = aggdataArray.map((aggData: AggDataObj) => {
        if (aggData.collection) {
          sourceCollectionsIds.push(aggData.collection.id);
        }
        return aggData.id;
      });

      //In this case, we check if the destination collection is shared or not, and if yes we see if there is an existing cache.
      // If there is an existing cache, we remove it to force refetching data if ever that collection is opened, to make sure
      //data displayed is fresh and relevant
      if (collection.sharedUsers && collection.sharedUsers.length > 0) {
        const cachedData = client.readQuery({
          query: retrieveAggdataForCollectionGql,
          variables: { collectionId: collection.id },
        });
        if (cachedData) {
          if (DEBUG)
            console.log(
              "DEBUG cache found for shared collection %o",
              cachedData
            );
          if (DEBUG)
            console.log(
              "DEBUG will evict the cache found to force the data to be refetched from server if ever the user opens the shared collection"
            );
          deleteSharedCollectionSpecificCache(client.cache, collection.id);
        }
      }

      attachMultipleAggDataToCollection({
        variables: {
          input: dataIdsArray,
          collectionId: collection.id,
          sortOrderScore: maxSortOrderScore + 1,
        },
        // When an agg data is moved from one collection to the other, the source is
        //the collection where it was, and the destination the new collection to be moved to.
        //we refetch the one or two collections impacted by this update. The source and destination.
        refetchQueries: [
          {
            query:
              sourceCollectionsIds.length > 0
                ? retrieveCollectionsForIds
                : undefined, // if no collection, avoid trying a useless query
            variables: { input: sourceCollectionsIds },
          },
          {
            query: retrieveCollectionGql,
            variables: {
              id: collection.id,
            },
          },
          // {
          //    query: (collection.sharedUsers && collection.sharedUsers.length > 0) ? retrieveAggdataForCollectionGql : undefined,
          //    variables: { collectionId: collection.id }
          // },
        ],
        update(cache, { data: { attachMultipleAggdataToCollection } }) {
          // console.log(
          //   "Mouad  " + JSON.stringify(attachMultipleAggdataToCollection)
          // );
          if (attachMultipleAggdataToCollection) {
            updateAggDataArrayInCache(
              cache,
              attachMultipleAggdataToCollection.aggdatas
            );
          }
        },
      })
        .then((data: any) => {
          if (
            data?.data.attachMultipleAggdataToCollection.error ==
            NOT_ALLOWED_READ_ONLY_ERROR
          ) {
            dispatch(
              showSnackbar({
                message: t("observer_only_error_message", {
                  collection_name: collection.name,
                }),
                action: "",
                onAction: null,
                loading: false,
              })
            );
            return;
          }
          dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
          dispatch(
            showSnackbar({
              message: t(
                aggdataArray.length > 1
                  ? "item_added_to_collection_confirmation_msg_plural"
                  : "item_added_to_collection_confirmation_msg_singular",
                {
                  collection_name: collection.name,
                }
              ),
              action: "",
              onAction: null,
              loading: false,
            })
          );

          dispatch(setMultiSelectedData([]));
        })
        .catch((e) => {
          dispatch(
            showSnackbar({
              message: t("item_added_to_collection_failed_msg"),
              action: "",
              onAction: null,
              loading: false,
            })
          );

          dispatch(setMultiSelectedData([]));
        });
    },
    [maxSortOrderScore]
  );

  //console.log("The MaxSortOrderScore  is" + maxSortOrderScore)
  const handleAddNewReminder = useCallback(
    (reminder: Reminder, aggDataArray: AggDataObj[]): void => {
      var aggDataArrayUpdateForCache: AggDataObj[] = [];
      var aggDataArrayUpdateForBackend: any[] = [];
      const beforeUpdateAggData = [...aggDataArray];

      aggDataArray.map((aggData: AggDataObj) => {
        // var aggDataUpdateForCache;
        // var aggDataUpdateForBackend;
        var updatedRemindersArray = [];
        if (aggData.reminders) {
          updatedRemindersArray = JSON.parse(aggData.reminders);
          if (updatedRemindersArray && updatedRemindersArray.length > 0) {
            updatedRemindersArray.push(reminder);
          } else {
            updatedRemindersArray = [reminder];
          }
        } else {
          updatedRemindersArray = [reminder];
        }
        const remindersAsJsonString = JSON.stringify(updatedRemindersArray);
        const aggDataUpdateForCache = {
          ...aggData,
          reminders: remindersAsJsonString,
          sortOrderScore: maxSortOrderScore + 1,
        };
        const aggDataUpdateForBackend = {
          id: aggData.id,
          reminders: remindersAsJsonString,
          sortOrderScore: maxSortOrderScore + 1,
        };
        aggDataArrayUpdateForCache.push(aggDataUpdateForCache);
        aggDataArrayUpdateForBackend.push(aggDataUpdateForBackend);
      });

      // if (aggData.reminders) {
      //   updatedRemindersArray = JSON.parse(aggData.reminders);
      //   if (updatedRemindersArray && updatedRemindersArray.length > 0) {
      //     updatedRemindersArray.push(reminder);
      //   } else {
      //     updatedRemindersArray = [reminder];
      //   }
      // } else {
      //   updatedRemindersArray = [reminder];
      // }

      // const remindersAsJsonString = JSON.stringify(updatedRemindersArray);

      // aggDataUpdateForCache = {
      //   ...aggData,
      //   reminders: remindersAsJsonString,
      //   sortOrderScore: maxSortOrderScore + 1,
      // };
      // aggDataUpdateForBackend = {
      //   id: aggData.id,
      //   reminders: remindersAsJsonString,
      //   sortOrderScore: maxSortOrderScore + 1,
      // };
      dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
      if (DEBUG)
        console.log(
          "will update for cache " + JSON.stringify(aggDataArrayUpdateForCache)
        );
      if (DEBUG)
        console.log(
          "will update for backend " +
            JSON.stringify(aggDataArrayUpdateForBackend)
        );

      updateAggDataArrayInCache(
        updateAggDataResult.client.cache,
        aggDataArrayUpdateForCache
      );
      updateMultipleAggData({
        variables: { input: aggDataArrayUpdateForBackend },
        refetchQueries: undefined,
      }).catch((e) => {
        //If update with the backend fails, we rollback the changes
        updateAggDataArrayInCache(
          updateAggDataResult.client.cache,
          beforeUpdateAggData
        );
        dispatch(
          showSnackbar({
            message: t("snackbar_failed_apply_changes_backend"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
    },
    [dispatch, updateAggData, maxSortOrderScore, updateAggDataResult]
  );

  //console.log("Editable aggdata note " + JSON.stringify(editableAggdataNote));
  React.useEffect(() => {
    if (dragOverlayRef.current) {
      dragOverlayRef.current.addEventListener("drop", handleDrop);
      dragOverlayRef.current.addEventListener("dragleave", handleDragLeave);
      dragOverlayRef.current.addEventListener("dragover", function (e) {
        e.preventDefault();
      });
    }
    rootRef.current.addEventListener("dragenter", handleDragEnter);
    rootRef.current.addEventListener("dragover", function (e) {
      e.preventDefault();
    });

    return () => {
      if (dragOverlayRef.current) {
        dragOverlayRef.current.removeEventListener("drop", handleDrop);
        dragOverlayRef.current.removeEventListener(
          "dragleave",
          handleDragLeave
        );
        dragOverlayRef.current.removeEventListener("dragover", function (e) {
          e.preventDefault();
        });
      }
      if (rootRef.current) {
        rootRef.current.removeEventListener("dragenter", handleDragEnter);
        rootRef.current.removeEventListener("dragover", function (e) {
          e.preventDefault();
        });
      }
    };
  }, [rootRef, dragOverlayRef, dragOverOngoing]);

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("Drag leave %o", e);
    setDragOverOngoing(false);
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("Drag enter %o", e);
    setDragOverOngoing(true);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverOngoing(false);
    dispatch(addFiles(e.dataTransfer.files));
  };

  //console.log("multiSelectedAggData is " + JSON.stringify(multiSelectedAggData) + " the lenght is " + multiSelectedAggData.length);
  return (
    <>
      <Helmet>
        <title>{t("home_title")} 🏠 | Memento</title>
      </Helmet>
      <SideNav selectedEntry={sideNavSelectedEntry} />
      {dragOverOngoing && (
        <>
          <FilesDropArea></FilesDropArea>
          <DragFocusOverlay ref={dragOverlayRef}></DragFocusOverlay>
        </>
      )}

      {multiSelectedAggData && multiSelectedAggData.length > 0 && (
        <MultiSelectionToolbar />
      )}

      <MainContainer ref={rootRef}>
        <Header />
        {content}
        <Snackbar
          className={classes.root}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          style={{ width: "800px", maxWidth: "800px" }}
          open={snackBar.message.length > 0}
          autoHideDuration={snackBar.loading ? 600000 : 6000} //when it's a loading snackbar, we leave it there until the action confirmation arrives to update it.
          onClose={handleClose}
          TransitionComponent={TransitionUp}
          message={
            <h1 style={{ width: "100%", fontWeight: 50, fontSize: "1.7rem" }}>
              {snackBar.message}
            </h1>
          }
          action={
            <React.Fragment>
              {snackBar.loading ? (
                <CircularProgress
                  style={{ margin: "8px 0px 8px 130px", padding: "2px" }}
                />
              ) : (
                <Button
                  style={{
                    margin: "8px 0px 8px 130px",
                    fontSize: "1.4rem",
                  }}
                  color="primary"
                  size="large"
                  onClick={handleSnackBarAction}
                >
                  {snackBar.action}
                </Button>
              )}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </MainContainer>
      {displayImageViewer && (
        <ImageViewer
          aggDataArray={displayImageViewer.aggDataArray}
          startAggData={displayImageViewer.currentAggData}
          title={displayImageViewer.title}
        />
      )}

      {displayNoteimageViewer && (
        <NoteImagesViewer
          noteImagesArray={displayNoteimageViewer.noteImagesArray}
          startNoteImage={displayNoteimageViewer.currentNoteImage}
          aggData={displayNoteimageViewer.aggData}
          title={displayNoteimageViewer.title}
        />
      )}

      {editableAggdataNote && (
        <NoteEditor
          aggData={editableAggdataNote}
          reminder={editableAggdataNote.reminders}
          onPinUnpinAction={() => {}}
          onContentChanged={(aggData: AggDataObj) => {
            // console.log(
            //   "onContentChanged " + JSON.stringify(aggData?.note.noteTasks)
            // );
            // setEditedAggData(aggData);
            // setNoteTasks(
            //   aggData?.note?.noteTasks
            //     ? JSON.parse(aggData?.note?.noteTasks as string).sort(
            //         (item1: NoteTask, item2: NoteTask) => {
            //           return item1.done === item2.done
            //             ? 0
            //             : item1.done
            //             ? 1
            //             : -1;
            //         }
            //       )
            //     : []
            // );
          }}
        ></NoteEditor>
      )}

      {openAttachDataToCollectionDialog.showAttachToCollectionDialog && (
        <SelectCollectionContainer
          open={openAttachDataToCollectionDialog.showAttachToCollectionDialog}
          title={t("select_collection_container_title")}
          aggDataObjArray={openAttachDataToCollectionDialog.aggdataToAttach}
          handleCollectionSelected={addAggDataArrayToCollection}
          handleModalClose={() => {
            //setDisplaySelectCollectionModal(false);
            dispatch(closedAttachDataToCollectionDialog());
          }}
        ></SelectCollectionContainer>
      )}
      {openReminderDatePicker.displayPicker && (
        <ReminderDatePicker
          aggDataArray={openReminderDatePicker.aggDataArray}
          open={openReminderDatePicker.displayPicker}
          onClose={() => {
            dispatch(closeReminderDatePicker());
          }}
          onReminderPicked={handleAddNewReminder}
        />
      )}
      <SimpleConfirmationDialog
        open={openNotificationPermission}
        title={t("notification_permission_rationale_title")}
        description={t("notification_permission_rationale_description")}
        hideCancel={true}
        handleOk={() => {
          setNotificatioPermissionnDisplayed();
          setOpenNotificationPermission(false);
        }}
        handleModalClose={(close: boolean) => {
          setNotificatioPermissionnDisplayed();
          setOpenNotificationPermission(false);
        }}
      ></SimpleConfirmationDialog>
      <MaxDataUsageLimitReachedDialog
        open={displayMaxDataUsageReached}
        handleModalClose={() => {
          dispatch(displayMaxDataUsageReachedDialog(false));
        }}
      />
      {aggDataClicked && aggDataClicked.aggData && (
        <Box sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          display: "sticky",
          marginRight: "16px",
          marginBottom: "16px"
        }}>
          <AudioPlayer aggdataObj={aggDataClicked.aggData}></AudioPlayer>
        </Box>
      )}
      {displayCommentsView && (
        <AggdataCommentsContainer aggData={displayCommentsView.aggData} />
      )}
      {!isOnline && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            ".MuiPaper-root": {
              width: "500px",
            },
          }}
          open={!isOnline}
          autoHideDuration={6000}
        >
          <Alert
            severity="warning"
            sx={{ fontSize: "1.8rem", padding: "16px" }}
          >
            <AlertTitle sx={{ width: "100%", fontSize: "1.8rem" }}>
              {t("you_are_offline_title")}
            </AlertTitle>
            {t("you_are_offline_description")}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default React.memo(MainBoard);

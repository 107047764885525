import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { useTheme } from "@mui/material/styles";
import { formatTimeStampForCyclePicker } from "shared/dataUtils";
import RepeatIcon from "@mui/icons-material/Repeat";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import {
  A_DAY_IN_MS,
  A_WEEK_IN_MS,
  A_MONTH_IN_MS,
  A_YEAR_IN_MS,
} from "shared/dataUtils";

interface ReminderCyclePickerProps {
  pickedTimestamp: number;
  onCyclePicked?: (cycleValue: number) => void;
  onClose?: () => void;
  onBackToDateTimePicker?: () => void;
}

const ReminderCyclePicker = ({
  pickedTimestamp,
  onCyclePicked,
  onBackToDateTimePicker,
  onClose,
}: ReminderCyclePickerProps) => {
  const globalTheme = useTheme();
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();

  const cyclesArray = [
    { label: t("cycle_once_title"), value: 0 },
    { label: t("cycle_everyday_title"), value: A_DAY_IN_MS },
    { label: t("cycle_everyweek_title"), value: A_WEEK_IN_MS },
    { label: t("cycle_everymonth_title"), value: A_MONTH_IN_MS },
    { label: t("cycle_everyyear_title"), value: A_YEAR_IN_MS },
  ];

  const DEFAULT_CYCLE_VALUE = cyclesArray[0];

  const [pickedValue, setPickedValue] = React.useState<any | undefined>(
    DEFAULT_CYCLE_VALUE
  );

  const handleOk = () => {
    setOpen(false);
    if (pickedValue) {
      onCyclePicked && onCyclePicked(pickedValue.value);
    }
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <div
          style={{
            backgroundColor: globalTheme.palette.primary.main,
            cursor: "pointer",
          }}
          onClick={onBackToDateTimePicker}
        >
          <AccessAlarmIcon
            style={{
              marginTop: "16px",
              marginLeft: "16px",
              width: "3.5rem",
              height: "3.5rem",
              color: globalTheme.palette.type == "dark" ? "black" : "white",
            }}
          ></AccessAlarmIcon>
          <DialogTitle
            sx={{ fontSize: "2rem", color: "white", cursor: "pointer" }}
          >
            {formatTimeStampForCyclePicker(pickedTimestamp)}
          </DialogTitle>
        </div>
        <RepeatIcon
          style={{
            marginTop: "16px",
            marginLeft: "16px",
            width: "3.5rem",
            height: "3.5rem",
            color: globalTheme.palette.type == "dark" ? "white" : "grey",
          }}
        />
        <DialogTitle>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={cyclesArray}
            sx={{ width: "25vw" }}
            defaultValue={DEFAULT_CYCLE_VALUE.label}
            ListboxProps={{
              style: {
                fontSize: "1.4rem",
              },
            }}
            onChange={(
              event: React.SyntheticEvent,
              value: any,
              reason: string,
              details?: string
            ) => {
              console.log(
                "arcim picked " +
                  JSON.stringify(value) +
                  " reason " +
                  reason +
                  " details " +
                  details
              );
              if (reason == "selectOption") {
                setPickedValue(value);
              } else if (reason == "clear") {
                setPickedValue(undefined);
              }
            }}
            clearIcon={<></>}
            renderInput={(params) => {
              console.log(" arcim %o", params);
              return (
                <TextField
                  {...params}
                  sx={{ fontSize: "2rem" }}
                  InputProps={{
                    ...params.InputProps,
                    //endAdornment: undefined,
                    style: { fontSize: "1.8rem" },
                  }} //we remove the clear button at the end
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    style: { fontSize: "1.6rem" },
                  }}
                  label={t("cycle_menu_title")}
                />
              );
            }}
          />
        </DialogTitle>
        <DialogActions>
          <Button sx={{ fontSize: "1.4rem" }} onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button sx={{ fontSize: "1.4rem" }} onClick={handleOk}>
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(ReminderCyclePicker);

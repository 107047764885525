import { Box, Menu, TextField, Typography } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";
import CheckIcon from "@mui/icons-material/Check";

const ColorPaletteContainer = styled("div")<{ isInputField?: boolean }>`
  max-width: 128px;
  display: block;
  z-index: 40000000;
  background-color: white;
  /* border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149); */
  background-color: white;

  ${({ isInputField }) =>
    isInputField &&
    css`
      bottom: 80px;
    `}
`;

const ColorPaletteBtn = styled("button")<{ color: string }>`
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1.5px solid transparent;
  border-radius: 50%;
  margin: 0.2rem;
  background: ${(props) => props.color};

  &:hover {
    border: 1.5px solid #5f6367;
  }

  &:first-child {
    border: 1.5px solid #e7eaed;
    &:hover {
      border: 1.5px solid #5f6367;
    }
  }
`;

interface PublicProfileColorPaletteProps {
  anchorEl: any;
  open: boolean;
  onClose: () => void;
  onColorSelected: (color: string) => void;
  selectedColor: string;
}

export const paletteColors = [
  { color: "#ffffff" },
  { color: "#e57373" },
  { color: "#81c784" },
  { color: "#64b5f6" },
  { color: "#dce775" },
  { color: "#ffb74d" },
  { color: "#4db6ac" },
  { color: "#ba68c8" },
  { color: "#fff176" },
];
export const paletteGradients = [
  {
    color:
      "linear-gradient( 109.6deg,  rgba(0,182,255,1) 11.2%, rgba(102,51,153,1) 91.1%)",
  },
  {
    color:
      "linear-gradient( 96.5deg,  rgba(39,103,187,1) 10.4%, rgba(16,72,144,1) 87.7% )",
  },
  {
    color:
      "linear-gradient( 64.3deg,  rgba(254,122,152,0.81) 17.7%, rgba(255,206,134,1) 64.7%, rgba(172,253,163,0.64) 112.1% )",
  },
  {
    color:
      "linear-gradient( 109.6deg,  rgba(196,248,180,1) 11.2%, rgba(160,250,254,1) 91.1% )",
  },
  {
    color:
      "linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% )",
  },
  {
    color:
      "linear-gradient( 109.6deg,  rgba(255,174,0,1) 11.2%, rgba(255,0,0,1) 100.2% );",
  },
  {
    color:
      "linear-gradient( 111.2deg,  rgba(232,5,5,1) 1.7%, rgba(245,49,191,1) 98.7% );",
  },
  {
    color:
      "linear-gradient( 109.6deg,  rgba(0,191,165,1) 11.2%, rgba(0,140,122,1) 100.2% );",
  },
  {
    color:
      "linear-gradient( 108.1deg,  rgba(167,220,225,1) 11.2%, rgba(217,239,242,1) 88.9% );",
  },
];

const PublicProfileColorPalette = ({
  anchorEl,
  open,
  onClose,
  onColorSelected,
  selectedColor,
}: PublicProfileColorPaletteProps) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box
        sx={{
          paddingRight: "8px",
          paddingLeft: "8px",
        }}
      >
        {paletteColors.map((noteColor) => (
          <ColorPaletteBtn
            key={noteColor.color}
            color={noteColor.color}
            onClick={(e) => onColorSelected(noteColor.color)}
          >
            <CheckIcon
              sx={{ opacity: selectedColor == noteColor.color ? 1 : 0 }}
            />
          </ColorPaletteBtn>
        ))}
      </Box>
      <Box
        sx={{
          paddingRight: "8px",
          paddingLeft: "8px",
        }}
      >
        {paletteGradients.map((noteColor) => (
          <ColorPaletteBtn
            key={noteColor.color}
            color={noteColor.color}
            onClick={(e) => onColorSelected(noteColor.color)}
          >
            <CheckIcon
              sx={{ opacity: selectedColor == noteColor.color ? 1 : 0 }}
            />
          </ColorPaletteBtn>
        ))}
      </Box>
      <Box
        sx={{
          paddingRight: "8px",
          paddingLeft: "8px",
          marginTop: "8px",
        }}
      >
        <Typography>HEX Code</Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          placeholder="#000000"
          sx={{
            "& .MuiFormHelperText-root": {
              fontSize: "1.2rem",
            },
          }}
          InputProps={{
            style: { fontSize: "1.2rem", width: "100%", padding: "8px" },
            /*                     disableUnderline: true,
             */
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onColorSelected(event.target.value);
          }}
        />
      </Box>
    </Menu>
  );
};

export default React.memo(PublicProfileColorPalette);

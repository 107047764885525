//export const SERVER_URL = "http://192.168.137.1:8000"//process.env.REACT_APP_SERVER_URL;
//export const SERVER_URL = "http://192.168.27.76:8000"//server address for mac
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const FRONT_URL = process.env.REACT_APP_PUBLIC_URL;
export const GOOGLE_LOGIN_KEY = process.env.REACT_APP_GOOGLE_LOGIN_KEY;
export const APP_PLAYSTORE_LINK =
  "https://play.google.com/store/apps/details?id=com.app.memento";
export const APP_PLAYSTORE_MOBILE_INTENT =
  "market://details?id=com.app.memento";
export const YOUTUBE_CHANNEL_URL = "https://www.youtube.com/@mementoapp3859";
export const CHROME_EXTENSION_STORE_URL =
  "https://chrome.google.com/webstore/detail/memento-collect/pmmdajcdfdfnofmagfpaeikgockmcnpp";
export const PASSWORD_MIN_LENGTH = 6;
export const CHROME_EXTENSION_ID = process.env.REACT_APP_CHROME_EXTENSION_ID;

export const DEBUG = process.env.REACT_APP_DEBUG === "true";
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_KEY;

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL =
  process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MESUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MESUREMENT_ID;

export const MAX_ALLOWED_BULK_FILES_IMPORT = 20; //We don't authorize adding more than 20 files at once.
export const SINGLE_DATA_MAX_ALLOWED_SIZE_FREE_TIER = 20 * 1024 * 1024;
export const SUPPORT_CONTACT_EMAIL = "support@mementocollect.com";

export const DEFAULT_CURRENCY = "USD";
export const MAX_SHARED_COLLECTIONS_COUNT_FREE_USER = 10;

export const COLLECTIONS_DEFAULT_VIEW_TYPE = "0";
export const SELECT_COLLECTIONS_DEFAULT_VIEW_TYPE = "0";
export const COLLECTIONS_DEFAULT_SORT_TYPE = "0";

export const AGGDATA_DEFAULT_VIEW_TYPE = "0"; 
export const AGGDATA_DEFAULT_SORT_TYPE = "0";
export const AGGDATA_DEFAULT_FILTER_TYPE = "0";

export function getPublicProfileUrl(username) {
  return FRONT_URL + "/u/" + username;
}

export function getCollectionPublicAccessUrlFromAccessToken(access_token) {
  return FRONT_URL + "/pcollection/" + access_token;
}

export function getImageForDataId(id) {
  return SERVER_URL + "/media/" + id;
}

export function getNoteImageForId(id) {
  return SERVER_URL + "/nmedia/" + id;
}

export function getUserAvatarForEmail(email) {
  return SERVER_URL + "/eav/" + email;
}

export function getUserAvatarForUsername(username) {
  return SERVER_URL + "/uav/" + username;
}

export function getUserAvatarForId(id) {
  return SERVER_URL + "/avatar/" + id;
}

export function getFilePathForDataId(id) {
  return SERVER_URL + "/file/" + id;
}

export function getDownloadLinkForDataId(id) {
  return SERVER_URL + "/download_media/" + id;
}

export function getDownloadLinkForNoteImageId(id) {
  return SERVER_URL + "/download_nmedia/" + id;
}

export function getNotionDatabaseUrl(notionDatabaseId) {
  return "https://www.notion.so/" + notionDatabaseId.replace(/-/gi, ""); //We remove the dashes from the uuid
}

export function getCollectionUrl(collectionId, isArchived) {
  if (isArchived) {
    return "/board/archives/collection/" + collectionId;
  } else {
    return "/board/collection/" + collectionId;
  }
}

export const SUBSCRIPTION_FREE_PLAN = {
  id: 0,
  name: "free",
  maxDataUsage: 1024 * 1024 * 100, // 100Mb,
  maxDataUsageAsString: "100Mb",
};

export const SUBSCRIPTION_MEMENTEE_PLAN = {
  id: 1,
  name: "Mementee",
  maxDataUsage: 1024 * 1024 * 1024 * 10, // 10Gb,
  maxDataUsageAsString: "10Gb",
};

export const SUBSCRIPTION_MEMENTEE_PLUS_PLAN = {
  id: 2,
  name: "Mementee +",
  maxDataUsage: 1024 * 1024 * 1024 * 20, // 20Gb,
  maxDataUsageAsString: "20Gb",
};

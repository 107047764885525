import React, { useState, useCallback, useRef } from "react";
import styled, { css } from "styled-components";

import deleteIcon from "icons/close.svg";
import dragIcon from "icons/drag-indicator.svg";
import Tool from "containers/Toolbar/Tool/Tool";
import { TodoItemID, Todo } from "shared/types";
import { makeStyles } from "@mui/styles";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip from "@mui/material/Tooltip";
import PanTool from "@mui/icons-material/PanTool";
import { useTranslation } from "react-i18next";
import uniqid from "uniqid";

export const Wrapper = styled("li")<{ isEditable: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 30px;
  padding: ${(props) => (props.isEditable ? "0 2.5rem" : "0 1.1rem")};
  margin: 1px 0;
  ${({ isEditable }) =>
    isEditable &&
    css`
    &:focus-within {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    `}
`;


const useStyles = makeStyles((theme) => ({
  checkBoxText: {
    width: "100%",
    "& .MuiInputBase-input": {
      fontSize: "1.8rem",
      paddingTop: "1px",
      paddingBottom: "1px",
      lineHeight: "1.9rem", 
      
    },
  },
  doneCheckboxText: {
    width: "100%",
    "& .MuiInputBase-input": {
      fontSize: "1.8rem",
      textDecoration: "line-through",
      paddingTop: "1px",
      paddingBottom: "1px",
      lineHeight: "1.9rem",
     
    },
  },
}));

interface NoteTaskItemProp {
  todo: Todo;
  readOnly?: boolean;
  inputFocus?: boolean;
  onDelete?: (todo: Todo) => void;
  onCheck?: (todo: Todo) => void;
  onTaskContentChanged?: () => void;
  onAddNewTask?: (currentNotTask: Todo) => void;
}

interface isHover {
  hoverID: string | number;
  onHover: boolean;
}

const NoteTaskItem = ({
  todo,
  onCheck,
  onDelete,
  onTaskContentChanged,
  onAddNewTask,
  inputFocus,
  readOnly = false,
}: NoteTaskItemProp) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState<isHover>({
    hoverID: "",
    onHover: false,
  });
  const uniqueId = useRef(uniqid());
  const [hover, setHover] = useState(false);
  const { hoverID, onHover } = isHover;
  const textFieldRef = React.useRef();
  const taskLastContent = React.useRef(todo.task);

  const handleOnMouseOver = (id: TodoItemID) => {
    setHover(true);
  };

  const handleOnMouseLeave = (id: TodoItemID) => {
    setHover(false);
  };

  const handleDeleteTodo = () => {
    onDelete && onDelete(todo);
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key == "Enter") {
        e.preventDefault();
        if (onAddNewTask) onAddNewTask(todo);
      }
    },
    [onAddNewTask]
  );

  const handleBlur = useCallback(
    (taskContent: string) => {
      if (taskContent == "") {
        handleDeleteTodo();
        return;
      }
      if (taskLastContent.current != taskContent) {
        todo.task = taskContent;
        onTaskContentChanged && onTaskContentChanged();
        taskLastContent.current = taskContent;
      }
      setHover(false);
    },
    [onTaskContentChanged, setHover, taskLastContent, handleDeleteTodo]
  );

  const labelId = `checkbox-list-label-${todo.id}`;

  React.useEffect(() => {
    if (textFieldRef && todo.hasFocus){
      textFieldRef.current.focus();
    }
    todo.hasFocus = false;
    delete todo.hasFocus; //to void sending this to the backend while performing an update
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <ListItem
        key={uniqueId}
        disablePadding
        onMouseOver={() => handleOnMouseOver(1)}
        onMouseLeave={() => handleOnMouseLeave(2)}
      >
        <div
          key={uniqueId}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingLeft: "8px",
            paddingRight: "8px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Checkbox
            edge="start"
            tabIndex={-1}
            disableRipple
            defaultChecked={todo.done}
            onChange={() => {
              onCheck(todo);
            }}
            inputProps={{ "aria-labelledby": labelId }}
          />
          <TextField
            ref={textFieldRef}
            id="standard-basic"
            variant="standard"
            sx={{ fontSize: "1.2rem" }}
            className={
              todo.done ? classes.doneCheckboxText : classes.checkBoxText
            }

             //This is a workaround for a bug when the focus changes when you first press enter on Add New Item and the focus changes, when textfield is set to multiline
              //the cursor jumps to the beginning of the text after the first character, which is very annoying.
              //In this case we delay setting the textfield to multiline
            multiline={todo.task.length > 2} 
            autoFocus={inputFocus}
            onBlur={(e) => {
              handleBlur(e.target.value);
            }}
            onKeyPress={(e) => {
              handleKeyPress(e);
            }}
            defaultValue={todo.task}
            InputProps={{
              disableUnderline: true,
              readOnly: readOnly,
              autoComplete: "off",
            }}
          />
          {hover && !readOnly && !todo.done && (
            <Tooltip title={t("action_dnd")}>
              <IconButton onClick={handleDeleteTodo}>
                <PanTool />
              </IconButton>
            </Tooltip>
          )}
          {hover && !readOnly && (
            <Tooltip title={t("action_delete")}>
              <IconButton onClick={handleDeleteTodo}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {/* </ListItemButton> */}
      </ListItem>
    </div>
  );
};

export default React.memo(NoteTaskItem);

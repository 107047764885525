import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import DataUsageProgressBar from "components/DataUsageProgress/DataUsageProgressBar";
import {
  getUsageInHumanReadableFormat,
  getStartOfNextMonth,
  getDateFromIso8601,
  getDateAndTimeFromIso8601,
  getMonthFromMonthNumber,
  containsOnlyLettersNumbersUnderscore,
  isUsernameValid,
} from "shared/dataUtils";
import { RootState } from "store/reducers/index";
import { UserObj } from "models/user";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import Link from "@mui/material/Link";
import DeleteAccountConfirmationDialog from "components/UI/Modals/DeleteAccountConfirmationDialog";
import Avatar from "@mui/material/Avatar";
import { getUserAvatarForEmail, getUserAvatarForId } from "configuration";
import { stringToColour } from "shared/colors";
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { styledTooltipTitle } from "shared/styles";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { useMutation } from "@apollo/client";
import { updateUsernameGql } from "graphql/mutations";
import { loginUser } from "store/actions/authActions";
import UploadProfileImage from "containers/User/UploadProfileImage";

const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;

  flex-direction: column;
  padding-left: 48px;
  padding-right: 48px;
  @media (max-width: 780px) {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  subscriptionPlanContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  userFullNameContainer: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  userFullNameWithAvatarContainerContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  dataUsageContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  avatarNotHovered: {
    transform: "scale(1)",
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: 200,
    }),
    [theme.breakpoints.down("mobile")]: {
      width: "10px",
      height: "10px",
    },
  },
  boxSectionContiner: {
    display: "flex",
    flexDirection: "column",

    textAlign: "start",
    width: "50%",
  },

  deleteAccountContainer: {
    padding: 0,
    display: "flex",
    marginTop: "36px",
    fontSize: "1.6rem",
    flexDirection: "row",
    width: "60%",
    cursor: "pointer",
    "& .MuiLink-root": {
      color: "#123456",
    },
  },
}));

const General = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user: UserObj = useSelector((state: RootState) => state.auth.user);
  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = React.useState(
    false
  );
  const [userAvatarUrl, setUserAvatarUrl] = React.useState("");

  const [editProfilePictureSrc, setEditProfilePicture] = React.useState<
    string | null
  >(null);

  function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setEditProfilePicture(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  React.useEffect(() => {
    if (user) {
      setUserAvatarUrl(getUserAvatarForId(user.id));
    }
  }, [user]);

  const editUsernameTextFieldRef = React.useRef(null);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const getSubscriptionPlanTitle = useCallback(() => {
    if (user.subscriptionPlan.id == 0) {
      return t("tier_free_title");
    } else if (user.subscriptionPlan.id == 1) {
      return t("tier_mementee_title");
    } else if (user.subscriptionPlan.id == 2) {
      return t("tier_mementee_plus_title");
    } else {
      return "";
    }
  }, [user]);

  const getUserInitials = React.useCallback(() => {
    if (user.userInitials) {
      var userInitials = user.userInitials;
    } else if (user?.firstName && user?.lastName) {
      var userInitials = user?.firstName.charAt(0) + user?.lastName.charAt(0);
      if (userInitials.length == 0) {
        userInitials = user?.username.substring(0, 2);
      }
    } else {
      userInitials = user?.username.substring(0, 2);
    }
    userInitials = userInitials.toUpperCase();
    return userInitials;
  }, [user]);

  const avatarColor = stringToColour(user?.username);

  if (!user) {
    return (
      <>
        <MenuContainer>
          <Skeleton height="36px" width="60%" />
          <Skeleton height="36px" width="20%" />
          <Box className={classes.subscriptionPlanContainer}>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="80%" />
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
            </Box>
          </Box>
          <Box className={classes.dataUsageContainer}>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
              <Skeleton height="36px" width="60%" />
              <Skeleton height="36px" width="40%" />
            </Box>
          </Box>
          <Box className={classes.subscriptionPlanContainer}>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
            </Box>
          </Box>
        </MenuContainer>
      </>
    );
  } else {
    return (
      <MenuContainer>
        <Box className={classes.userFullNameWithAvatarContainerContainer}>
          <Box className={classes.userFullNameContainer}>
            <Typography variant="h3">
              {user.firstName + " " + user.lastName}{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <EditableUserName username={user.username} />
            </Box>
            <Typography variant="h4" color="text.secondary">
              {user.email}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Avatar
              //onClick={handleClick}
              className={classes.avatarNotHovered}
              color="red"
              src={userAvatarUrl}
              style={{
                border: "thick double #fff",
                fontSize: "3rem",
                width: "96px",
                height: "96px",
                cursor: "pointer",
                //filter: avatarFilter,
                backgroundColor: avatarColor?.backgroundColor,
                color: avatarColor?.textColor,
                // backgroundColor: theme.palette.primary.main,
              }}
            >
              {getUserInitials()}
            </Avatar>
            <input
              ref={hiddenFileInput}
              accept="image/*,application/pdf,audio/*,video/*"
              onChange={onFileInputChange}
              type="file"
              style={{ display: "none" }}
            />
            <Button
              onClick={() => {
                //console.log("image click %o", hiddenFileInput)
                hiddenFileInput?.current?.click();
              }}
              sx={{
                color: "grey"
              }}
            >
              {t("edit_action")}
            </Button>
          </Box>
        </Box>
        <Box className={classes.subscriptionPlanContainer}>
          <Box className={classes.boxSectionContiner}>
            <Typography
              style={{ marginTop: "16px" }}
              variant="h4"
              color="text.secondary"
            >
              {t("settings_subscription_plan_title")}
            </Typography>
          </Box>
          <Box className={classes.boxSectionContiner}>
            <Typography
              style={{ marginTop: "16px" }}
              component="h4"
              variant="h4"
              color="text.primary"
            >
              {getSubscriptionPlanTitle()}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.dataUsageContainer}>
          <Box className={classes.boxSectionContiner}>
            <Typography variant="h4" color="text.secondary">
              {t("settings_data_usage_title", {
                data_usage_month: getMonthFromMonthNumber(user.dataUsageMonth),
              })}
            </Typography>
          </Box>
          <Box className={classes.boxSectionContiner}>
            <Typography variant="h4" color="text.primary">
              {t("settings_data_uasge_info_message", {
                data_usage: getUsageInHumanReadableFormat(
                  user.monthlyDataUsage
                ),
              })}
            </Typography>
            <DataUsageProgressBar hideTitle />
            <Typography
              style={{ marginTop: "8px" }}
              variant="h5"
              color="text.primary"
            >
              {t("settings_data_reinit_message", {
                init_date: getStartOfNextMonth(),
              })}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.subscriptionPlanContainer}>
          <Box className={classes.boxSectionContiner}>
            <Typography
              style={{ marginTop: "16px" }}
              variant="h4"
              color="text.secondary"
            >
              {t("settings_member_last_login_title")}
            </Typography>
          </Box>
          <Box className={classes.boxSectionContiner}>
            <Typography
              style={{ marginTop: "16px" }}
              component="h4"
              variant="h4"
              color="text.primary"
            >
              {getDateAndTimeFromIso8601(user.lastLogin)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.subscriptionPlanContainer}>
          <Box className={classes.boxSectionContiner}>
            <Typography
              style={{ marginTop: "16px" }}
              variant="h4"
              color="text.secondary"
            >
              {t("settings_member_date_title")}
            </Typography>
          </Box>
          <Box className={classes.boxSectionContiner}>
            <Typography
              style={{ marginTop: "16px" }}
              component="h4"
              variant="h4"
              color="text.primary"
            >
              {getDateFromIso8601(user.dateJoined)}
            </Typography>
          </Box>
        </Box>
        <Link
          style={{ color: "#123456", marginTop: "48px" }}
          className={classes.deleteAccountContainer}
          onClick={() => {
            setOpenDeleteAccountDialog(true);
          }}
        >
          {t("delete_my_account_title")}
        </Link>
        {openDeleteAccountDialog && (
          <DeleteAccountConfirmationDialog
            open={openDeleteAccountDialog}
            handleModalClose={(close: boolean) => {
              setOpenDeleteAccountDialog(false);
            }}
          ></DeleteAccountConfirmationDialog>
        )}
        <UploadProfileImage
          open={Boolean(editProfilePictureSrc)}
          handleModalClose={(imgChanged) => {
            setEditProfilePicture(null);
            if (imgChanged) {
              setUserAvatarUrl(getUserAvatarForId(user.id) + "?" + Date.now());
            }
          }}
          src={editProfilePictureSrc}
        />
      </MenuContainer>
    );
  }
};

interface EditableUserNameProps {
  username: string;
}

const EditableUserName = ({ username }: EditableUserNameProps) => {
  const [editUserNameMode, setEditUserNameMode] = React.useState(false);
  const [usernameInputValue, setUsernameInputValue] = React.useState(username);
  const [isUserNameValid, setIsUserNameValid] = React.useState(true);
  const { t } = useTranslation();
  const [userNameInvalidError, setUserNameInvalidError] = React.useState(
    t("username_validation_help_message")
  );
  const [updateUsername, updateUsernameResult] = useMutation(updateUsernameGql);
  const dispatch = useDispatch();
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  const handleSaveNewUsername = React.useCallback(() => {
    if (!isUserNameValid) {
      return "";
    }
    if (usernameInputValue == username) {
      setEditUserNameMode(false);
    }
    updateUsername({
      variables: {
        newUsername: usernameInputValue,
      },
    })
      .then((result) => {
        if (
          result.data &&
          result.data.updateUsername &&
          result.data.updateUsername.result
        ) {
          const newUser = { ...currentUser, username: usernameInputValue };
          dispatch(loginUser(newUser));
          setEditUserNameMode(false);
        } else {
          setIsUserNameValid(false);
          setUserNameInvalidError(t("username_already_taken_error"));
        }
      })
      .catch((e) => {
        setIsUserNameValid(false);
        setUserNameInvalidError(t("network_error_msg"));
      });
  }, [isUserNameValid, usernameInputValue, setIsUserNameValid]);

  React.useEffect(() => {
    const isUserNameInvalid = !isUsernameValid(usernameInputValue);
    if (isUserNameInvalid) {
      setUserNameInvalidError(t("username_validation_help_message"));
    }
    setIsUserNameValid(!isUserNameInvalid);
  }, [usernameInputValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      {!editUserNameMode && (
        <>
          <Typography
            variant="h4"
            color="text.secondary"
            sx={{
              fontStyle: "italic",
            }}
          >
            {username}
          </Typography>
          <Tooltip title={styledTooltipTitle(t("edit_user_name_title"))}>
            <IconButton
              onClick={() => {
                setUsernameInputValue(username);
                setEditUserNameMode(true);
              }}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {editUserNameMode && (
        <>
          <TextField
            fullWidth
            error={!isUserNameValid}
            helperText={
              isUserNameValid
                ? undefined
                : t("username_validation_help_message")
            }
            variant="standard"
            value={usernameInputValue}
            sx={{
              "& .MuiFormHelperText-root": {
                fontSize: "1.2rem",
              },
            }}
            InputProps={{
              style: { fontSize: "2.2rem", width: "100%" },
              readOnly: updateUsernameResult.loading,
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUsernameInputValue(event.target.value);
            }}
          />

          {!updateUsernameResult.loading && (
            <Tooltip title={styledTooltipTitle(t("save_action"))}>
              <IconButton
                onClick={() => {
                  handleSaveNewUsername();
                }}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          )}
          {updateUsernameResult.loading && (
            <CircularProgress
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          )}
          <Tooltip title={styledTooltipTitle(t("edit_user_name_title"))}>
            <IconButton
              onClick={() => {
                setEditUserNameMode(false);
              }}
            >
              <HighlightOffOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default React.memo(General);

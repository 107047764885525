import {
  COLLECTIONS_DEFAULT_VIEW_TYPE,
  COLLECTIONS_DEFAULT_SORT_TYPE,
  AGGDATA_DEFAULT_VIEW_TYPE,
  AGGDATA_DEFAULT_SORT_TYPE,
  AGGDATA_DEFAULT_FILTER_TYPE,
} from "configuration";

const USER_LOGGED_IN_KEY = "user_logged_in_key";
const USER_LOGGED_IN = "1";
const USER_LOGGED_OUT = "0";

const LOCAL_CURRENCY = "user_currency_key";
const WEB_CLIENT_UNIQUE_ID = "web_client_unique_id";
const DISPLAY_NOTIFICATIONS_PERMISSION =
  "display_notifciation_permission_rationale";
const CONFIDENTIALITY_FILTER_KEY = "confidentiality_filter_key";
const PIN_SIDE_NAVIGATION_BAR = "pin_side_navigation_bar_key";
const COLLECTION_FILTER_OPTION = "collection_filter_option_key";
const COLLECTIONS_VIEW_TYPE = "collections_view_type";
const SELECT_COLLECTIONS_VIEW_TYPE = "select_collections_view_type";
const COLLECTIONS_SORT_TYPE = "collections_sort_type";
const AGGDATA_VIEW_TYPE = "aggdata_view_type";
const AGGDATA_SORT_TYPE = "aggdata_sort_type";
const AGGDATA_FILTER_TYPE = "aggdata_filter_type";

enum USER_LOGGED_STATUS {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
}

export function setWebClientUniqueId(uuid: string) {
  localStorage.setItem(WEB_CLIENT_UNIQUE_ID, uuid);
}

export function setNotificatioPermissionnDisplayed() {
  localStorage.setItem(DISPLAY_NOTIFICATIONS_PERMISSION, "1");
}

export function shouldDisplayNotificationPermissionRationale(): boolean {
  return localStorage.getItem(DISPLAY_NOTIFICATIONS_PERMISSION) != "1";
}

export function getWebClientUniqueId() {
  return localStorage.getItem(WEB_CLIENT_UNIQUE_ID);
}

export function setUserCurrency(localCurrency: string) {
  localStorage.setItem(LOCAL_CURRENCY, localCurrency);
}

export function getUserCurrency(): string | null {
  return localStorage.getItem(LOCAL_CURRENCY);
}

export function setUserIsloggedIn() {
  localStorage.setItem(USER_LOGGED_IN_KEY, USER_LOGGED_IN);
}

export function setUserIsloggedOut() {
  localStorage.setItem(USER_LOGGED_IN_KEY, USER_LOGGED_OUT);
}

export function isUserLoggedIn(): boolean {
  return localStorage.getItem(USER_LOGGED_IN_KEY) === USER_LOGGED_IN;
}

export function saveConfidentialityFilterConfiguration(show: boolean) {
  localStorage.setItem(CONFIDENTIALITY_FILTER_KEY, show ? "1" : "0");
}

export function shouldShowConfidentialityFilter(): boolean {
  return localStorage.getItem(CONFIDENTIALITY_FILTER_KEY) == "1";
}

export function savePinSideNavigationBar(pin: boolean) {
  localStorage.setItem(PIN_SIDE_NAVIGATION_BAR, pin ? "1" : "0");
}

export function shouldPinSideNavigationBar(): boolean {
  const valueFromPersistence = Boolean(
    localStorage.getItem(PIN_SIDE_NAVIGATION_BAR)
  );
  if (!valueFromPersistence) {
    //By default we want to pin the side navigation bar
    return true;
  }
  return localStorage.getItem(PIN_SIDE_NAVIGATION_BAR) == "1";
}

export function saveCollectionsViewType(viewType: string) {
  localStorage.setItem(COLLECTIONS_VIEW_TYPE, viewType);
}

export function getCollectionsViewType(): string | null {
  const valueFromPersistence = Boolean(
    localStorage.getItem(COLLECTIONS_VIEW_TYPE)
  );
  if (!valueFromPersistence) {
    return COLLECTIONS_DEFAULT_VIEW_TYPE;
  }
  return localStorage.getItem(COLLECTIONS_VIEW_TYPE);
}

export function savSelectCollectionViewType(gridDisplay: boolean) {
  var viewType;
  if (gridDisplay) {
    viewType = "1";
  } else {
    viewType = "0";
  }
  localStorage.setItem(SELECT_COLLECTIONS_VIEW_TYPE, viewType);
}

export function getSelectCollectionViewType(): boolean {
  const valueFromPersistence = Boolean(
    localStorage.getItem(SELECT_COLLECTIONS_VIEW_TYPE)
  );
  if (!valueFromPersistence) {
    return false; // by default the view type to display will be linear
  }
  return localStorage.getItem(SELECT_COLLECTIONS_VIEW_TYPE) == "1";
}

export function saveCollectionsSortType(viewType: string) {
  localStorage.setItem(COLLECTIONS_SORT_TYPE, viewType);
}

export function getCollectionsSortType(): string {
  const valueFromPersistence = Boolean(
    localStorage.getItem(COLLECTIONS_SORT_TYPE)
  );
  if (!valueFromPersistence) {
    //By default we want to pin the side navigation bar
    return COLLECTIONS_DEFAULT_SORT_TYPE;
  }
  return localStorage.getItem(COLLECTIONS_SORT_TYPE);
}

export function saveAggdataViewType(viewType: string) {
  localStorage.setItem(AGGDATA_VIEW_TYPE, viewType);
}

export function getAggdataViewType(
  defaultViewType: string = AGGDATA_DEFAULT_VIEW_TYPE
): string {
  const valueFromPersistence = Boolean(localStorage.getItem(AGGDATA_VIEW_TYPE));
  if (!valueFromPersistence) {
    //By default we want to pin the side navigation bar
    return defaultViewType;
  }
  return localStorage.getItem(AGGDATA_VIEW_TYPE);
}

export function saveAggdataSortType(viewType: string) {
  localStorage.setItem(AGGDATA_SORT_TYPE, viewType);
}

export function getAggdataSortType(): string {
  const valueFromPersistence = Boolean(localStorage.getItem(AGGDATA_SORT_TYPE));
  if (!valueFromPersistence) {
    //By default we want to pin the side navigation bar
    return AGGDATA_DEFAULT_SORT_TYPE;
  }
  return localStorage.getItem(AGGDATA_SORT_TYPE);
}

export function saveAggdataFilterType(viewType: string) {
  localStorage.setItem(AGGDATA_FILTER_TYPE, viewType);
}

export function getAggdataFilterType(): string {
  const valueFromPersistence = Boolean(
    localStorage.getItem(AGGDATA_FILTER_TYPE)
  );
  if (!valueFromPersistence) {
    //By default we want to pin the side navigation bar
    return AGGDATA_DEFAULT_FILTER_TYPE;
  }
  return localStorage.getItem(AGGDATA_FILTER_TYPE);
}

//value can be either "personal" or "shared"
export function saveCollectionFilterOption(filterOption: string) {
  localStorage.setItem(COLLECTION_FILTER_OPTION, filterOption);
}
//value can be either "personal" or "shared"
export function getCollectionFilterType() {
  return localStorage.getItem(COLLECTION_FILTER_OPTION)
    ? localStorage.getItem(COLLECTION_FILTER_OPTION)
    : "personal";
}

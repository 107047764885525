
import React from "react";
import { useSelector } from "react-redux";
import { AggDataObj } from "models/aggdata";
import { CollectionObj } from "models/collection";
import { RootState } from "store/reducers";
import { useTranslation } from "react-i18next";
import SharedUserAvatar, {Size} from "components/UI/Controls/SharedUserAvatar";
import { stringToColour } from "shared/colors";
import { useHistory } from "react-router-dom";
import { UserObj } from "models/user";


interface UserAvatarWithTooltipProps {
    aggData: AggDataObj;
    topRight?: boolean;//Position by default the avatar in the top right
    size: Size;
  }

const UserAvatarWithTooltip = ({ aggData, size ="small", topRight = true }: UserAvatarWithTooltipProps) => {
    const history = useHistory();
    const currentlyOpenedCollection: CollectionObj = useSelector(
      (state: RootState) => state.view.currentlyOpenedCollection
    );
    const { t } = useTranslation();
    const currentUser: UserObj = useSelector(
      (state: RootState) => state.auth.user
    );
    var avatarColour = null;
    if (aggData.collection) {
      avatarColour = stringToColour(aggData.collection?.name);
    }
    return (
      <>
        <div
          style={{
            marginTop: "4px",
            position: topRight? "absolute" : "inherit",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            top: "4px",
            right: "4px",
            marginBottom: "4px",
            marginLeft: "4px",
          }}
        >
          {/* We display the avatar when aggdata is shared and id displayed from its collection, or when the data is not owner by the user for example from reminders UI */}
          
            <SharedUserAvatar
              user={aggData.owner}
              size={size}
            ></SharedUserAvatar>
          
  
          {/* display the collection badge when the item is not displayed from a collection */}
         
        </div>
      </>
    );
  };
  
  export default React.memo(UserAvatarWithTooltip);
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CollectionObj } from "models/collection";
import WrapTextIcon from "@mui/icons-material/WrapText";
import Box from "@mui/material/Box";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { colorMemento } from "shared/colors";

interface DeleteCollectionConfirmationDialogProps {
  open: boolean;
  collectionObj: CollectionObj;
  handleOk: () => void;
  handleModalClose: (close: boolean) => void;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    addButton: {
      marginEnd: "12px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: "24px",
    },
    hintTextAreaContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      marginTop: "12px",
    },
    hintText: {
      textAlign: "center",
    },
  })
);

export default function DeleteCollectionConfirmationDialog({
  open,
  collectionObj,
  handleOk,
  handleModalClose,
}: DeleteCollectionConfirmationDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Title>{t("delete_collection_dialog_title")}</Title>
            <Typography variant="h5">
              {collectionObj.itemsCount > 0
                ? t("delete_collection_dialog_description", {
                    collection_name: collectionObj.name,
                  })
                : t("delete_collection_dialog_description_empty", {
                  collection_name: collectionObj.name,
                })}
            </Typography>
            {collectionObj.itemsCount > 0 && (
              <Box className={classes.hintTextAreaContainer}>
                <LightbulbOutlinedIcon
                  style={{ width: "3rem", height: "3rem", color: colorMemento }}
                />

                <Typography
                  className={classes.hintText}
                  style={{ marginLeft: "8px", height: "100%" }}
                  variant="h5"
                >
                  {t("delete_collection_dialog_hint")}{" "}
                  <WrapTextIcon style={{ width: "2rem", height: "2rem" }} />
                </Typography>
              </Box>
            )}
            <div className={classes.buttonContainer}>
              <Button
                className={classes.addButton}
                color="primary"
                onClick={() => handleModalClose(false)}
              >
                <Typography variant="h6">{t("cancel")}</Typography>
              </Button>
              <Button
                className={classes.addButton}
                color="primary"
                onClick={handleOk}
              >
                <Typography variant="h6">{t("ok")}</Typography>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

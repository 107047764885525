import React from "react";
import AllItemsEmptyIllustration from "illustrations/undraw_all_items_empty.svg";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const AllItemsEmpty = () => {
  const { t } = useTranslation();

  return (
    <>
      <img
        style={{ marginTop: "60px", height: "300px",  cursor:"initial"}}
        src={AllItemsEmptyIllustration}
      />
      <Typography
        style={{ marginLeft: "12px", color: "black", marginTop: "36px" }}
        variant="h4"
      >
        {t("all_items_empty_list_msg")}
      </Typography>
    </>
  );
};

export default React.memo(AllItemsEmpty);

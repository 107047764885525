import axios, { AxiosResponse } from "axios";
import { DEBUG } from "configuration";



export interface CurrencyResult {
    result: string, //when successful will be 0, otherwise > 0
}

export const fetchUserCurrency = async (): Promise<CurrencyResult> => {
    try {
        let res = await axios.get("https://ipapi.co/currency/",{
           
        })
        if (DEBUG) {
            console.log("getLocalCurrency " + JSON.stringify(res));
        }
        console.log("getLocalCurrency " + JSON.stringify(res));
        return { result: res.data }

    } catch (e) {
        throw (e)
    }
}
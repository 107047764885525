import React from "react";
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/index";
import {
  LOGIN,
  MAINBOARD,
  ROOT,
  isPathAllowedForMobileDevice,
  shouldRedirectToPlaystore,
} from "navigation/Constants";
import { setPathAfterLogin } from "store/actions/view";
import { useDispatch } from "react-redux";
import { DEBUG } from "configuration";
import { isMobile } from "react-device-detect";
import { APP_PLAYSTORE_MOBILE_INTENT } from "configuration";

var userAgent = navigator.userAgent || navigator.vendor || window.opera;


interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const userLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const dispatch = useDispatch();

  if (DEBUG) {
    console.log(
      "Routing going to " +
        JSON.stringify(rest) +
        "for component " +
        JSON.stringify(Component)
    );
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        console.log(
          "params Render location " +
            JSON.stringify(location) +
            " is user logged in " +
            userLoggedIn +
            "isprivate " +
            isPrivate
        );

        if (isMobile && !isPathAllowedForMobileDevice(location.pathname)) {
          if (/android/i.test(userAgent) && shouldRedirectToPlaystore(location.pathname)) { //if we are on android, we redirect to the play store for some cases
            window.location.replace(APP_PLAYSTORE_MOBILE_INTENT);
          }
          return (
            <Redirect
              to={{
                pathname: ROOT,
                state: location,
              }}
            />
          );
        }
        if (isMobile && location.pathname === ROOT) {
          return <Component />;
        }
        if (userLoggedIn && !isPrivate) {
          if (location.pathname === ROOT || location.pathname === LOGIN) {
            return (
              <Redirect
                to={{
                  pathname: MAINBOARD,
                  state: location,
                }}
              />
            );
          }
          return <Component />;
        }
        if (isPrivate === userLoggedIn) {
          return <Component />;
        } else if (isPrivate && !userLoggedIn) {
          dispatch(setPathAfterLogin(location.pathname));
          if (DEBUG) {
            console.log(
              "Redirect to login, and to " + location.pathname + " afterwards"
            );
          }
          return (
            <Redirect
              to={{ pathname: isPrivate ? LOGIN : MAINBOARD, state: location }}
            />
          );
        }
        // return isPrivate === userLoggedIn ? (
        //   <Component />
        // ) : (
        //   <Redirect
        //     to={{ pathname: isPrivate ? LOGIN : MAINBOARD, state: location }}
        //   />
        // );
      }}
    />
  );
};

export default Route;

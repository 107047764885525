import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AggDataItemsLayout from "components/AggDataItems/AggDataItemsLayout/AggDataItemsLayout";
import AggData from "containers/AggData/AggData";
import { AggDataObj, DataType } from "models/aggdata";
import { openAttachDataToCollectionDialog } from "store/actions/view";
import ConfidentialityFilterControl from "components/UI/Controls/ConfidentialityFilterControl";

interface AggDataRemindersContainerProps {
  date: Date;
  aggDataWithReminders: AggDataObj[];
  onImageAggDataClicked: (aggData: AggDataObj) => void;
}

const AggDataRemindersContainer = ({
  aggDataWithReminders,
  date,
  onImageAggDataClicked,
}: AggDataRemindersContainerProps) => {
  const dispatch = useDispatch();
  const [displayedData, setDisplayedData] = useState([]);

  const currentOpenedNoteAggdataId = React.useRef("");

  const handleAddToCollection = React.useCallback(
    (aggData: AggDataObj) => {
      dispatch(openAttachDataToCollectionDialog(aggData));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (aggDataWithReminders) {
      const renderedData = aggDataWithReminders.map((aggData: AggDataObj) => (
        <AggData
          key={aggData.id}
          aggDataObj={aggData}
          setBeingEditedNoteAggDataId={(aggdataId: string) => {
            currentOpenedNoteAggdataId.current = aggdataId;
          }}
          isEditingProp={currentOpenedNoteAggdataId.current == aggData.id}
          onAddToCollection={() => handleAddToCollection(aggData)}
          onImageAggDataClicked={
            aggData.dataType == DataType.IMAGE_TYPE
              ? onImageAggDataClicked
              : null
          }
        />
      ));
      setDisplayedData(renderedData);
    }
  }, [aggDataWithReminders]);

  return (
    <>
      {displayedData && displayedData.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            paddingRight: "8%",
          }}
        >
          <ConfidentialityFilterControl />
        </div>
      )}

      <AggDataItemsLayout
        notes={displayedData}
        itemsPerRow={4}
      ></AggDataItemsLayout>
    </>
  );
};

export default React.memo(AggDataRemindersContainer);

import { combineReducers } from 'redux';
import view from './view';
import notes from './notes';
import auth from './authReducer';
import aggdataReducer from './aggdataReducer';
import collectionsReducer from './collectionsReducer';

const rootReducer = combineReducers({
  auth,
  aggdataReducer,
  collectionsReducer,
  notes,
  view,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { AggDataObj, AggDataTagObj, DataType } from "models/aggdata";
import React, { useCallback, useRef } from "react";
import { DEBUG } from "configuration";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  addTagToAggdataGql,
  removeTagFromAggdataGql,
  updateAggDataGql,
} from "graphql/mutations";
import { addAggDataTagInCache, updateAggDataInCache } from "graphql/helpers";
import { stringToColour } from "shared/colors";
import { retrieveAllAggDataTagsGql } from "graphql/queries";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/actions/view";
import { useHistory } from "react-router-dom";
import { getTagRoute } from "navigation/Constants";

interface AggDataTagsContainerProps {
  aggdata: AggDataObj;
  readOnly?: boolean;
}
const DUMMY_INITIAL_EMPTY_OPTIONS = [{ name: "", isLoading: true }];

const AggDataTagsContainer = ({
  aggdata,
  readOnly = false,
}: AggDataTagsContainerProps) => {
  const [retrieveAllAggDataTags, retrieveAllAggDataTagsResult] = useLazyQuery(
    retrieveAllAggDataTagsGql
  );
  const history = useHistory();

  const [updateAggData, updateAggDataResult] = useMutation(updateAggDataGql);
  const [addTagToAggdata, addTagToAggdataResult] = useMutation(
    addTagToAggdataGql
  );
  const [removeTagFromAggdata, removeTagToAggdataResult] = useMutation(
    removeTagFromAggdataGql
  );

  const { t } = useTranslation();
  const [tags, setTags] = React.useState(aggdata.tags ? aggdata.tags : []);
  const [openAutoComplete, setOpenAutoComplete] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    setTags(aggdata.tags ? aggdata.tags : []);
  }, [aggdata]);

  const getAutoCompleteOptions = React.useCallback(() => {
    if (
      retrieveAllAggDataTagsResult &&
      retrieveAllAggDataTagsResult.data &&
      retrieveAllAggDataTagsResult.data.allTags
    ) {
      if (aggdata?.tags && aggdata.tags.length) {
        return retrieveAllAggDataTagsResult.data.allTags
          .filter((tag: AggDataTagObj) => {
            for (var i = 0; i < aggdata.tags.length; i++) {
              if (aggdata.tags[i].name == tag.name) {
                return false;
              }
            }
            return true;
          })
          .sort((tag1: AggDataTagObj, tag2: AggDataTagObj) =>
            tag1.name.localeCompare(tag2.name)
          );
      } else {
        if (retrieveAllAggDataTagsResult.data.allTags.length > 1) {
          return [
            ...retrieveAllAggDataTagsResult.data.allTags,
          ].sort((tag1: AggDataTagObj, tag2: AggDataTagObj) =>
            tag1.name.localeCompare(tag2.name)
          );
        } else {
          return retrieveAllAggDataTagsResult.data.allTags;
        }
      }
    } else {
      return DUMMY_INITIAL_EMPTY_OPTIONS;
    }
  }, [retrieveAllAggDataTagsResult.data, aggdata.tags]);

  const handleAddTagToAggdata = React.useCallback(
    (name: string) => {
      name = name.trim();
      if (DEBUG) {
        console.log("Adding tag + " + name + " to aggdata " + aggdata.id);
      }
      addTagToAggdata({
        variables: {
          dataId: aggdata.id,
          tagName: name,
        },
      })
        .then((result) => {
          if (result.data.addTagToData.result == 0) {
            if (DEBUG) {
              console.log("Adding tag to aggdata success");
            }
            addAggDataTagInCache(addTagToAggdataResult.client.cache, name);
            let newTagsList = [
              ...(aggdata.tags ? aggdata.tags : []),
              { name: name },
            ];
            updateAggDataInCache(addTagToAggdataResult.client.cache, {
              ...aggdata,
              tags: newTagsList,
            });
          }
        })
        .catch((e) => {
          if (DEBUG) {
            console.log("failed to add tag to aggdata " + e);
          }
          dispatch(
            showSnackbar({
              message: t("failed_to_add_tag_msg"),
              action: "",
              onAction: null,
            })
          );
        });
    },
    [aggdata, addTagToAggdataResult]
  );

  const handleRemoveTagFromAggdata = React.useCallback(
    (name: string) => {
      if (DEBUG) {
        console.log("Adding tag + " + name + " to aggdata " + aggdata.id);
      }
      removeTagFromAggdata({
        variables: {
          dataId: aggdata.id,
          tagName: name,
        },
      })
        .then((result) => {
          if (result.data.removeTagFromData.result == 0) {
            if (DEBUG) {
              console.log("Adding tag to aggdata success");
            }
            if (aggdata.tags && aggdata.tags.length > 0) {
              let newTagsList = aggdata.tags.filter((tag: AggDataTagObj) => {
                return tag.name != name;
              });
              updateAggDataInCache(addTagToAggdataResult.client.cache, {
                ...aggdata,
                tags: newTagsList,
              });
            }
          }
        })
        .catch((e) => {
          if (DEBUG) {
            console.log("failed to add tag to aggdata " + e);
          }

          dispatch(
            showSnackbar({
              message: t("failed_to_remove_tag_msg"),
              action: "",
              onAction: null,
            })
          );
        });
    },
    [aggdata]
  );

  if (readOnly && (!aggdata.tags || aggdata.tags.length == 0)) {
    return <></>;
  }

  return (
    <>
      <Autocomplete
        freeSolo
        open={openAutoComplete}
        multiple
        readOnly={readOnly}
        forcePopupIcon={false}
        id="multiple-limit-tags"
        options={getAutoCompleteOptions()}
        getOptionLabel={(option) => option.name}
        onOpen={() => {
          retrieveAllAggDataTags();
          if (getAutoCompleteOptions().length == 0) {
            setOpenAutoComplete(false);
            return;
          }
          setOpenAutoComplete(true);
        }}
        onClose={() => {
          setOpenAutoComplete(false);
        }}
        renderOption={(
          props: object,
          option: Value,
          state: object,
          ownerState: object
        ) => {
          if (option.isLoading) {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size="2rem"></CircularProgress>
              </Box>
            );
          }
          return (
            <MenuItem
              key={option.name}
              sx={{
                fontSize: "1.4rem",
                cursor: "pointer",
              }}
              onClick={(e: any) => {
                //We make sure we don't add a new chip/tag if it was already there

                let tagAlreadyExists = tags.some(
                  (e: any) => e.name == option.name
                );
                if (!tagAlreadyExists) {
                  setTags([...tags, { name: option.name }]);
                  handleAddTagToAggdata(option.name);
                }
                setOpenAutoComplete(false);
              }}
            >
              {option.name}
            </MenuItem>
          );
        }}
        //renderOption={props: object, option: Value, state: object, ownerState: object}
        //defaultValue={}
        clearIcon={null}
        value={tags}
        onChange={(
          event: React.SyntheticEvent,
          value: Value | Array,
          reason: string,
          details?: string
        ) => {
          /* let addedTags = value.filter((e: any) => {
                    //  return lastValue.current.indexOf(e)
                    for (var i = 0; i < tags.length; i++) {
                      if (tags[i].name == e.name) {
                        return false;
                      }
                    }
                    setTags([...tags, e]);
                    handleAddTagToAggdata(e.name);
                    return true;
                  });
                  console.log("on change ADDED" + JSON.stringify(addedTags));

                  let removedTags = tags.filter((e: any) => {
                    //  return lastValue.current.indexOf(e)
                    for (var i = 0; i < value.length; i++) {
                      if (value.name == e.name) {
                        handleRemoveTagFromAggdata(e.name);
                        return true;
                      }
                    }
                    return false;
                  }); */
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={readOnly ? undefined : t("add_tags_input_hint")}
            disabled={readOnly}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            sx={{
              "& .MuiInput-root": {
                fontSize: "1.4rem",
              },
            }}
            onKeyDown={(event: any) => {
              if (event.key === "Backspace") {
                event.stopPropagation();
              }
              if (event.key === "Enter") {
                console.log("Got the event for value " + event.target.value);
                let newTagName = event.target.value;
                //We make sure we don't add a new chip/tag if it was already there

                let tagAlreadyExists = tags.some(
                  (e: any) => e.name == newTagName
                );
                if (!tagAlreadyExists) {
                  setTags([...tags, { name: newTagName }]);
                  handleAddTagToAggdata(newTagName);
                } else {
                  console.log("clearing the text input");
                  event.target.value = "";
                }
              }
            }}
          />
        )}
        renderTags={(value: readonly AggDataTagObj[], getTagProps) =>
          value.map((item: AggDataTagObj, index) => (
            <Chip
              size="small"
              variant="filled"
              label={"🏷️ " + item.name}
              onDelete={
                readOnly
                  ? undefined
                  : //this is what allows to display the clear icon on the chip. If no function is provided, then the chip is not displayed.
                    () => {
                      let newTagsList = tags.filter(
                        (tag) => item.name != tag.name
                      );
                      handleRemoveTagFromAggdata(item.name);
                      setTags(newTagsList);
                    }
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(getTagRoute(item.name))
                // onClick(reminder);
              }}
              sx={{
                fontSize: "1.3rem",
                cursor: "pointer",
                margin: "2px",
                backgroundColor: stringToColour(item.name)?.backgroundColor,
                color: stringToColour(item.name)?.textColor,
              }}
              // {...getTagProps({ index })}
            />
          ))
        }
      />
    </>
  );
};

export default React.memo(AggDataTagsContainer);

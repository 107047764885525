import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import SimpleHeader from "components/Header/SimpleHeader";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PricingCards from "components/Pricing/PricingCards";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from "shared/utility";
import ComparisonTable from "components/Pricing/ComparisonTable";
import { Button } from "@mui/material";
import styled from "styled-components";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import { YOUTUBE_CHANNEL_URL, SUPPORT_CONTACT_EMAIL } from "configuration";

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 48px;
  padding: 16px;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

function PricingContent() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background:
            "linear-gradient(90deg, rgba(255, 241, 206, 1) 0%, rgba(255, 253, 250, 1) 100%)",
        }}
      >
        <Helmet>
          <title>
            {capitalizeFirstLetter(t("pricing_main_title"))} | Memento
          </title>
        </Helmet>
        {/* Hero unit */}
        <SimpleHeader makeStatic={true} />
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ width: "100%", pt: 2, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
            }}
          >
            {t("pricing_main_title")}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              textAlign: "center",
              fontWeight: 300,
            }}
          >
            {t("landing_pricing_subtitle")}
          </Typography>
        </Container>
        <PricingCards />
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
        >
          {" "}
        </Typography>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{
            marginTop: "48px",
            marginBottom: "36px",
            fontFamily: "Trebuchet MS, sans-serif",
          }}
        >
          {t("pricing_compare_plans_title")}
        </Typography>
        <ComparisonTable />

        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{
            width: "80%",
            marginTop: "48px",
            marginBottom: "36px",
            fontFamily: "Trebuchet MS, sans-serif",
            "@media (max-width: 780px)": {
              width: "100%",
              padding: "16px",
            },
          }}
        >
          {t("pricing_page_questions_title")}
        </Typography>
        <ButtonContainer>
          <Button
            variant="outlined"
            onClick={() => {
              window.open(YOUTUBE_CHANNEL_URL, "_blank");
            }}
            sx={{
              fontSize: "2rem",
              marginRight: "16px",
              textColor: "black",
              "@media (max-width: 780px)": {
                marginBottom: "16px",
                marginRight: "0px",
              },
            }}
          >
            {" "}
            <YouTubeIcon
              size="large"
              sx={{
                color: "red",
                marginRight: "12px",
                width: "24px",
                height: "24px",
              }}
            />
            {t("visit_channel_title")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              window.open("mailto:" + SUPPORT_CONTACT_EMAIL, "_blank");
            }}
            sx={{
              fontSize: "2rem",
            }}
          >
            <EmailIcon
              size="large"
              sx={{
                color: "black",
                marginRight: "12px",
                width: "24px",
                height: "24px",
              }}
            />
            {t("contact_us_text")}
          </Button>
        </ButtonContainer>
      </div>
    </>
  );
}

export default function Pricing() {
  return <PricingContent />;
}

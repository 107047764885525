import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { RootState } from "store/reducers/index";
import { UserObj } from "models/user";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import {
  getSubscriptionInformation,
  cancelSubscription,
  SubscriptionInformationResult,
} from "api/stripeApi";
import { SubscriptionInformation, PaymentType } from "models/user";
import { setUserSubscriptionInformation } from "store/actions/authActions";
import { loginUser } from "store/actions/authActions";
import { useDispatch } from "react-redux";
import { getDateFromTimestamp, isFromPast } from "shared/dataUtils";
import VisaIcon from "icons/icons8-visa.svg";
import MastercardIcon from "icons/icons8-mastercard.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import BoltIcon from "@mui/icons-material/Bolt";
import { useHistory } from "react-router-dom";
import { PAYMENT } from "navigation/Constants";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchUserData } from "api/authApi";
import PaymentDialog from "pages/Payments/PaymentDialog";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { logoutUser } from "store/actions/authActions";

const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;

  flex-direction: column;
  padding-left: 48px;
  padding-right: 48px;
  @media (max-width: 780px) {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 16px;
    width: 100%;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media (max-width: 780px) {
    width: 100%;
    flex-direction: column;
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  subscriptionPlanContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "70%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  dataUsageContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("mobile")]: {
      flexDirection: "column",
    },
  },
  boxSectionContiner: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    width: "50%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  accordionDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingEnd: "16px",
  },
  upgradeButtonContainer: {
    display: "flex",
    marginTop: "48px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
  },
  subscriptionCancellationContainer: {
    display: "flex",
    marginTop: "48px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
  },

  cancelSubscriptionAction: {
    padding: 0,
    display: "flex",
    marginTop: "36px",
    fontSize: "1.6rem",
    flexDirection: "row",
    width: "60%",
    cursor: "pointer",
    "& .MuiLink-root": {
      color: "#123456",
    },
  },
}));

const Subscription = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openCancelConfDialog, setOpenCancelConfDialog] = React.useState(false);
  const [openEditPaymentDialog, setOpenEditPaymentDialog] =
    React.useState(false);
  const [subscriptionCancellationOngoing, setSubscriptionCancellationOngoing] =
    React.useState(false);
  const user: UserObj = useSelector((state: RootState) => state.auth.user);
  const history = useHistory();
  const userSubscriptionInfo: SubscriptionInformation = useSelector(
    (state: RootState) => state.auth.userSubscriptionInfo
  );
  const [userSubInfoIsLoading, setUserSubInforIsLoading] = React.useState(
    !userSubscriptionInfo
  );
  const [error, setError] = React.useState(false);

  console.log("userSubscriptionInfo " + JSON.stringify(userSubscriptionInfo));
  const getSubscriptionPlanTitle = useCallback(() => {
    if (user.subscriptionPlan.id == 0) {
      return t("tier_free_title");
    } else if (user.subscriptionPlan.id == 1) {
      return t("tier_mementee_title");
    } else {
      return "";
    }
  }, [user]);

  const handleUpgradeButtonClick = useCallback(() => {
    history.push(PAYMENT);
  }, [history]);

  React.useEffect(() => {
    if (!user){
      return;
    }
    //If the user doesn't have a valid subscription plan, then there is no subscription information to retrieve.
    if (user.subscriptionPlan.id == 0) {
      setUserSubInforIsLoading(false);
      return;
    }
    if (userSubscriptionInfo) {
      return; //no need to retrieve user sub info.
    }
    setUserSubInforIsLoading(true);
    getSubscriptionInformation()
      .then((data: SubscriptionInformationResult) => {
     //   console.log("subscriptions " + JSON.stringify(data));
        if (data.result == "0") {
         // console.log("subscriptions view" + JSON.stringify(data));
          dispatch(
            setUserSubscriptionInformation(data.subscriptionInformation)
          );
        }
        setUserSubInforIsLoading(false);
      })
      .catch((e) => {
        setUserSubInforIsLoading(false);
      });
  }, [user]);

  const getcardIconForType = useCallback((cardType: string) => {
    var cardIcon = null;
    if (cardType == "visa") {
      cardIcon = VisaIcon;
    } else if (cardType == "mastercard") {
      cardIcon = MastercardIcon;
    } else {
      return <></>;
    }
    return (
      <img
        style={{ width: "28px", height: "28px", marginRight: "16px" }}
        src={cardIcon}
      ></img>
    );
  }, []);

  const handleCardChangeSuccess = useCallback(() => {
    //setUserSubInforIsLoading(true);
    getSubscriptionInformation()
      .then((data: SubscriptionInformationResult) => {
        console.log("subscriptions " + JSON.stringify(data));
        if (data.result == "0") {
          console.log("subscriptions view" + JSON.stringify(data));
          dispatch(
            setUserSubscriptionInformation(data.subscriptionInformation)
          );
        }
        setUserSubInforIsLoading(false);
        setError(false);
      })
      .catch((e) => {
        setUserSubInforIsLoading(false);
        setError(true);
      });
  }, [setUserSubInforIsLoading]);

  if (userSubInfoIsLoading) {
    return (
      <>
        <MenuContainer>
          <Skeleton height="36px" width="60%" />
          <Skeleton height="36px" width="20%" />
          <Box className={classes.subscriptionPlanContainer}>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="80%" />
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
            </Box>
          </Box>
          <Box className={classes.dataUsageContainer}>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
              <Skeleton height="36px" width="60%" />
              <Skeleton height="36px" width="40%" />
            </Box>
          </Box>
          <Box className={classes.subscriptionPlanContainer}>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Skeleton height="36px" width="40%" />
            </Box>
          </Box>
        </MenuContainer>
      </>
    );
  } else {
    var header = null;
    if (!user) {
      header = (
        <>
          <Skeleton height="36px" width="60%" />
          <Skeleton height="36px" width="20%" />
        </>
      );
    } else {
      header = (
        <>
          <Typography variant="h3">
            {user.firstName + " " + user.lastName}{" "}
          </Typography>
          <Typography variant="h4" color="text.secondary">
            {user.email}{" "}
          </Typography>
        </>
      );
    }
    if (subscriptionCancellationOngoing) {
      return (
        <Box className={classes.subscriptionCancellationContainer}>
          <Typography
            style={{ marginTop: "16px", marginBottom: "16px" }}
            variant="h4"
            color="text.secondary"
          >
            {t("subscription_cancellation_ongoing_msg")}
          </Typography>
          <CircularProgress color="primary" />
        </Box>
      );
    }

    if (error) {
      return (
        <MenuContainer>
          {header}
          <BodyContainer>
            <Alert
              severity="error"
              style={{ fontSize: "1.8rem", marginTop: "18px", width: "100%" }}
            >
              {t("failed_to_retrieve_subscription_information")}
            </Alert>
          </BodyContainer>
        </MenuContainer>
      );
    }

    if (!userSubscriptionInfo) {
      return (
        <MenuContainer>
          {header}
          <BodyContainer>
            <Alert
              severity="info"
              style={{ fontSize: "1.8rem", marginTop: "18px", width: "100%" }}
            >
              {t("subscription_no_active_subscription")}
            </Alert>
            <Box className={classes.upgradeButtonContainer}>
              <Button
                onClick={handleUpgradeButtonClick}
                variant="contained"
                style={{ width: "100%", fontSize: "2rem" }}
                startIcon={<BoltIcon />}
              >
                {t("title_upgrade")}
              </Button>
            </Box>
          </BodyContainer>
        </MenuContainer>
      );
    } else if (
      userSubscriptionInfo.paymentType == PaymentType.GOOGLE_PLAY_BILLING &&
      userSubscriptionInfo.isCancelled
    ) {
      return (
        <MenuContainer>
          {header}

          {!isFromPast(userSubscriptionInfo.nextPaymentDate *1000) && (
            <Alert
              severity="info"
              style={{ fontSize: "1.8rem", marginTop: "18px", width: "100%" }}
            >
              {t("subscription_cancelled_expires_on_msg", {
                date: getDateFromTimestamp(
                  userSubscriptionInfo.nextPaymentDate
                ),
              })}
            </Alert>
          )}
          <Box className={classes.subscriptionPlanContainer}>
            <Box className={classes.boxSectionContiner}>
              <Typography
                style={{ marginTop: "16px" }}
                variant="h4"
                color="text.secondary"
              >
                {t("subscription_start_title")}
              </Typography>
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Typography
                style={{ marginTop: "16px" }}
                component="h4"
                variant="h4"
                color="text.primary"
              >
                {getDateFromTimestamp(userSubscriptionInfo.subscriptionStart)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.subscriptionPlanContainer}>
            <Box className={classes.boxSectionContiner}>
              <Typography variant="h4" color="text.secondary">
                {t("payment_method")}
              </Typography>
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Typography variant="h4" color="text.primary">
                <Link
                  style={{
                    color: "#123456",
                    marginTop: "48px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/account/subscriptions",
                      "_blank",
                      "noreferrer"
                    );
                  }}
                >
                  Google Play Billing
                </Link>
              </Typography>
            </Box>
          </Box>
        </MenuContainer>
      );
    } else
      return (
        <MenuContainer>
          {header}
          <Box className={classes.subscriptionPlanContainer}>
            <Box className={classes.boxSectionContiner}>
              <Typography
                style={{ marginTop: "16px" }}
                variant="h4"
                color="text.secondary"
              >
                {t("subscription_start_title")}
              </Typography>
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Typography
                style={{ marginTop: "16px" }}
                component="h4"
                variant="h4"
                color="text.primary"
              >
                {getDateFromTimestamp(userSubscriptionInfo.subscriptionStart)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.subscriptionPlanContainer}>
            <Box className={classes.boxSectionContiner}>
              <Typography variant="h4" color="text.secondary">
                {t("subscription_last_successful_payment")}
              </Typography>
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Typography variant="h4" color="text.primary">
                {getDateFromTimestamp(userSubscriptionInfo.lastPaymentDate)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.subscriptionPlanContainer}>
            <Box className={classes.boxSectionContiner}>
              <Typography variant="h4" color="text.secondary">
                {t("subscription_next_payment")}
              </Typography>
            </Box>
            <Box className={classes.boxSectionContiner}>
              <Typography variant="h4" color="text.primary">
                {getDateFromTimestamp(userSubscriptionInfo.nextPaymentDate)}
              </Typography>
            </Box>
          </Box>
          {userSubscriptionInfo.paymentType ==
            PaymentType.GOOGLE_PLAY_BILLING && (
            <Box className={classes.subscriptionPlanContainer}>
              <Box className={classes.boxSectionContiner}>
                <Typography variant="h4" color="text.secondary">
                  {t("payment_method")}
                </Typography>
              </Box>
              <Box className={classes.boxSectionContiner}>
                <Typography variant="h4" color="text.primary">
                  <Link
                    style={{
                      color: "#123456",
                      marginTop: "48px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/account/subscriptions",
                        "_blank",
                        "noreferrer"
                      );
                    }}
                  >
                    Google Play Billing
                  </Link>
                </Typography>
              </Box>
            </Box>
          )}
          {userSubscriptionInfo.paymentType == PaymentType.CARD_STRIPE && (
            <Box className={classes.subscriptionPlanContainer}>
              <Box className={classes.boxSectionContiner}>
                <Typography
                  style={{ marginTop: "16px" }}
                  variant="h4"
                  color="text.secondary"
                >
                  {t("subscription_payment_card_title")}
                </Typography>
              </Box>
              <Box className={classes.boxSectionContiner}>
                {/* <Typography
              style={{ marginTop: "16px" }}
              component="h4"
              variant="h4"
              color="text.primary"
            >
              **** **** **** {userSubscriptionInfo.paymentInformation.cardLast4Digits}
            </Typography> */}

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {" "}
                    {getcardIconForType(
                      userSubscriptionInfo.paymentInformation?.cardBrand
                    )}
                    <Typography
                      component="h4"
                      variant="h4"
                      color="text.primary"
                    >
                      •••• •••• ••••{" "}
                      {userSubscriptionInfo.paymentInformation?.cardLast4Digits}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={classes.accordionDetails}>
                      <Box className={classes.accordionDetailsContainer}>
                        <Typography variant="h5" color="text.secondary">
                          {t("card_expires_title")}
                        </Typography>
                        <Typography variant="h5" color="text.primary">
                          {userSubscriptionInfo.paymentInformation
                            ?.cardExpiryMonth +
                            "/" +
                            userSubscriptionInfo.paymentInformation
                              ?.cardExpiryYear}
                        </Typography>
                      </Box>
                      <Box className={classes.accordionDetailsContainer}>
                        <Typography variant="h5" color="text.secondary">
                          {t("card_type_title")}
                        </Typography>
                        <Typography variant="h5" color="text.primary">
                          {userSubscriptionInfo.paymentInformation?.cardBrand}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Tooltip
                title={
                  <Typography variant="h5">{t("edit_card_title")}</Typography>
                }
              >
                <IconButton
                  onClick={() => {
                    setOpenEditPaymentDialog(true);
                  }}
                  style={{ marginLeft: "12px", width: "56px", height: "56px" }}
                >
                  <EditIcon style={{ width: "24px", height: "24px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          <Link
            style={{ color: "#123456", marginTop: "48px" }}
            className={classes.cancelSubscriptionAction}
            onClick={() => {
              setOpenCancelConfDialog(true);
            }}
          >
            {t("subscription_cancellation_title")}
          </Link>
          <SimpleConfirmationDialog
            open={openCancelConfDialog}
            title={t("subscription_cancellation_title_msg")}
            description={t("subscription_cancellation_confirmation_msg")}
            handleOk={() => {
              setSubscriptionCancellationOngoing(true);
              setOpenCancelConfDialog(false);
              cancelSubscription()
                .then(() => {
                  dispatch(setUserSubscriptionInformation(null));
                  fetchUserData()
                    .then((user: UserObj) => {
                      dispatch(loginUser(user));
                      setSubscriptionCancellationOngoing(false);
                    })
                    .catch((err) => {
                      console.log(
                        "Caught an error while fetch user data" + err
                      );
                      if (err?.message?.includes("403")) {
                        dispatch(logoutUser());
                      }
                    });
                })
                .catch((e) => {});
            }}
            handleModalClose={(close: boolean) => {
              setOpenCancelConfDialog(false);
            }}
          ></SimpleConfirmationDialog>
          {openEditPaymentDialog && (
            <PaymentDialog
              open={openEditPaymentDialog}
              onCardChangeSuccess={handleCardChangeSuccess}
              handleOk={() => {}}
              handleModalClose={() => {
                setOpenEditPaymentDialog(false);
              }}
            />
          )}
        </MenuContainer>
      );
  }
};

export default React.memo(Subscription);

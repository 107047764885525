import React, { useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useTranslation } from "react-i18next";
import { colorGreyMemento } from "shared/colors";

interface PinnedHeaderProps {
  itemsCount?: number;
}

const PinnedHeader = ({ itemsCount }: PinnedHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        paddingLeft: "32px",
      }}
    >
      <PushPinIcon
        htmlColor={colorGreyMemento}
        style={{ height: "16px", width: "16px" }}
      />
      <Typography
        sx={{
          fontWeight: "550",
          color: colorGreyMemento,
          marginLeft: "4px",
          fontSize: "1.6rem"
        }}
        variant="h4"
      >
        {t("header_pinned_title")}
      </Typography>
      <Typography
        sx={{
          fontWeight: "550",
          color: colorGreyMemento,
          marginLeft: "4px",
          fontSize: "1.2rem"
        }}
        variant="h5"
      >
        {"(" + itemsCount + ")"}
      </Typography>
    </Box>
  );
};

export default React.memo(PinnedHeader);

import React from "react";
import PendingItemsEmptyIllustration from "illustrations/undraw_pending_items_empty.svg";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Link as TextLink } from "@mui/material";
import { colorMemento } from "shared/colors";

const PendingDataEmpty = () => {
  const { t } = useTranslation();

  return (
    <>
      <img
        style={{ marginTop: "60px", height: "300px", cursor: "initial" }}
        src={PendingItemsEmptyIllustration}
      />
      <Typography
        style={{ marginLeft: "12px", color: "black", marginTop: "12px" }}
        variant="h4"
      >
        {t("no_pending_items_title")}
      </Typography>

      <Typography
        style={{ marginLeft: "12px", marginTop: "12px" }}
        variant="h5"
      >
        {t("no_pending_items_subtitle")}
      </Typography>
      <Typography
        style={{ marginLeft: "12px", color: "grey", marginTop: "12px" }}
        variant="h5"
      >
        {t("no_pending_items_hint")} {" "}
        <TextLink
          style={{ color: colorMemento, cursor: "pointer" }}
          onClick={() => {
            window.open("https://chrome.google.com/webstore/detail/memento-collect/pmmdajcdfdfnofmagfpaeikgockmcnpp", "_blank");
          }}
        >
          {t("browser_extension")}
        </TextLink>
      </Typography>
    </>
  );
};

export default React.memo(PendingDataEmpty);

import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import styled from "styled-components";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  retrieveAllCollectionsGql,
  retrieveAllArchivedCollectionsGql,
  retrieveCollectionGql,
  retrieveAllTopLevelArchivedCollectionsGql,
} from "graphql/queries";
import { CollectionObj, isCollectionShared } from "models/collection";
import {
  setSearchContext,
  setCurrentCollection,
  setCurrentUserOpenedCollectionRight,
} from "store/actions/view";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { RequestTypes } from "components/AggDataItems/AggDataItemsContainer";
import AggDataItemsContainer from "components/AggDataItems/AggDataItemsContainer";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useHistory } from "react-router-dom";
import ImagePreview from "components/CollectionItems/ImagePreview";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useQuery } from "@apollo/client";
import {
  ARCHIVED_COLLECTION_BASE_PATH,
  COLLECTION_BASE_PATH,
} from "navigation/Constants";
import BackgroundWorker from "workers/NextReminderWorker.worker";
import {
  SORT_COLLECTIONS,
  SORT_COLLECTIONS_REPLY,
  BUILD_SHARED_USERS_LIST,
  BUILD_SHARED_USERS_LIST_REPLY,
} from "workers/WorkersMessageRequests";
import { DEBUG } from "configuration";
import { Helmet } from "react-helmet";
import SharedUsersAvatars from "components/UI/Controls/SharedUsersAvatars";
import ManageUserAccessModal from "components/UI/Modals/ManageUserAccessModal";
import { UserAccessRight, UserObj, SharedUserAccess } from "models/user";
import { colorMemento, colorMementoBeige } from "shared/colors";
import { styledTooltipTitle } from "shared/styles";
import Tooltip from "@mui/material/Tooltip";
import { useApolloClient } from "@apollo/client";
import { setSharedUsersList } from "store/actions/collectionsActions";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsDrawer from "containers/Comments/CommentsDrawer";
import AggdataDisplayControl, {
  FILTER_TYPE,
  SORT_TYPE,
  VIEW_TYPE,
} from "components/UI/Controls/AggdataDisplayControl";
import { Box } from "@mui/material";
import {
  getAggdataFilterType,
  getAggdataSortType,
  getAggdataViewType,
} from "localstorage/localstorage";
import PublishCollectionFloatingMenu from "./public/PublishCollectionFloatingMenu";
import PublicIcon from "@mui/icons-material/Public";
import { AggDataTagObj } from "models/aggdata";
import SimpleTagsFilter from "components/UI/Filters/SimpleTagsFilter";
import NestedCollectionsContainer from "./NestedCollectionsContainer";

interface CollectionAggDataContainerProps {
  collectionId: string;
  isCollectionArchived?: boolean;
}

const ControlsContainer = styled.div`
  padding-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 40px;
  margin-top: 36px;
  margin-bottom: 12px;
  width: 100%;
  align-items: center;
`;

const ContentContainer = styled.div`
  overflow-x: hidden;
  height: 100%;
  width: 100%;
`;

const SelectItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    width: "48px",
    height: "48px",
  },
  select: {
    marginLeft: "2%",
    width: "100%",
  },
  inputLabel: {
    width: "100%",
    fontSize: "1.4rem",
    padding: "4px",
    marginBottom: "4px",
    "& .MuiInput-input": {
      fontSize: "large",
    },
  },
}));

function CollectionAggDataContainer({
  collectionId,
  isCollectionArchived = false,
}: CollectionAggDataContainerProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, error, data } = useQuery(
    isCollectionArchived
      ? retrieveAllTopLevelArchivedCollectionsGql
      : retrieveAllCollectionsGql
  );

  const [selectedFilterTags, setSelectedFilterTags] = useState<AggDataTagObj[]>(
    []
  );

  const [displaySortType, setDisplaySortType] = React.useState<SORT_TYPE>(
    getAggdataSortType()
  );

  //When we come here, we want to reset the scroll to be at the top of the page in all cases.
  //Without this, when the user for example clicks on collection badge on data at the bottom of all items, then the scroll position
  //will be applied on this component
  //React.useEffect(() => window.scrollTo(0, 0), []);

  // const [retrieveAggDataForCollection, retrieveAggDataForCollectionResult] =
  //   useMutation(retrieveAggdataForCollectionGql, {
  //     variables: {
  //       collectionId: collectionId,
  //     },

  //     update(cache, { data }) {
  //       //console.log("Will update "+ JSON.stringify(data.aggdataForCollection.aggDataForCollection))
  //       if (data.aggdataForCollection.aggDataForCollection) {
  //         console.log(
  //           "Will update " +
  //             JSON.stringify(data.aggdataForCollection.aggDataForCollection)
  //         );
  //         addAggDataArrayInCache(
  //           cache,
  //           data.aggdataForCollection.aggDataForCollection
  //         );
  //       }
  //     },
  //   });
  React.useEffect(() => {
    // setTimeout(() => {
    //   retrieveAggDataForCollection().then((data) => {});
    // }, 10000)
    //retrieveAggDataForCollection().then((data) => {});
  }, []);

  // React.useEffect(() => {
  //   if (retrieveAggDataForCollectionResult.data && retrieveAggDataForCollectionResult.data.aggdataForCollection){
  //     console.log("Will write to the cache " + JSON.stringify(retrieveAggDataForCollectionResult.data.aggdataForCollection))
  //     addAggDataArrayInCache(client.cache, retrieveAggDataForCollectionResult.data.aggdataForCollection)
  //   }
  // }, [retrieveAggDataForCollectionResult.data])

  const userObj: UserObj = useSelector((state: RootState) => state.auth.user);

  const retrieveCollectionQuery = useQuery(retrieveCollectionGql, {
    variables: { id: collectionId },
  });

  const displayedCollections = !data
    ? null
    : data.collections
    ? data.collections
    : data.archivedTopLevelCollections;

  const [collectionsToDisplay, setCollectionsToDisplay] = React.useState(
    displayedCollections
  );

  const [openCommentsDrawer, setOpenCommentsDrawer] = React.useState(false);

  const [
    showSharedUserRightBadge,
    setShowSharedUserRightBadge,
  ] = React.useState(false);
  const [showCommentsTooltip, setShowCommentsTooltip] = React.useState(false);
  const [openSelect, setOpenSelect] = React.useState(false);
  const [searchFocused, setSearchFocused] = React.useState(false);

  const [displayPublishMenu, setDisplayPublishMenu] = React.useState<any>(null); //we store here the anchor element

  const [selectedCollectionId, setSelectedCollectionId] = React.useState(
    collectionId
  );

  const [
    selectedCollection,
    setSelectedCollection,
  ] = React.useState<CollectionObj | null>();

  React.useEffect(() => {
    if (displayedCollections) {
      setSelectedCollection(
        displayedCollections.find(
          (collection: CollectionObj) => collection.id == collectionId
        )
      );
    }
  }, [data, collectionId]);

  const currentlyOpenedCollectionCurrentUserRight: number = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollectionCurrentUserRight
  );

  const backgroundWorkerRef = React.useRef<Worker>();
  const backgroundWorkerRequestToken = React.useRef<Worker>(0);
  const sortedCollectionsRef = React.useRef<CollectionObj[]>([]);
  const backgroundWorkerBuildSharedUsersRequestToken = React.useRef<Worker>(0);
  const [
    displayManageUsersAccessModal,
    setDisplayManageUsersAccessModal,
  ] = React.useState(false);

  const toggleCommentsDrawer = React.useCallback(() => {
    setOpenCommentsDrawer(!openCommentsDrawer);
  }, [openCommentsDrawer]);

  // const updateSerachContext = useCallback(() => {
  //   var serachContext = "";
  //   if (retrieveCollectionQuery.data.collections) {
  //     serachContext = t("search_searchfield_hint_with_context", {
  //       search_context: retrieveCollectionQuery.data.collections.name,
  //     });
  //   }
  //   dispatch(setSearchContext(serachContext));
  // }, [dispatch, retrieveCollectionQuery.data]);

  const theme = useTheme();
  const [dataTypeFilter, setDataTypeFilter] = React.useState<FILTER_TYPE>(
    getAggdataFilterType()
  );

  const [displayViewType, setDispayViewType] = React.useState<VIEW_TYPE>(
    getAggdataViewType()
  );

  React.useEffect(() => {
    if (backgroundWorkerRef && backgroundWorkerRef.current) {
      backgroundWorkerRef.current.terminate();
    }
    backgroundWorkerRef.current = new BackgroundWorker();
    backgroundWorkerRef.current.addEventListener("message", (event: any) => {
      if (DEBUG) {
        console.log(
          "CollectionAggDataContainer got back message %o",
          event.data
        );
      }
      if (event.data.reply == SORT_COLLECTIONS_REPLY) {
        const requestToken = event.data.payload.token;
        if (backgroundWorkerRequestToken.current != requestToken) {
          if (DEBUG) {
            console.log(
              "CollectionAggDataContainer: drop this reply from worker, request token doesn't match"
            );
          }
          return;
        }
        const sortedCollections = event.data.payload.collectionsSorted;
        sortedCollectionsRef.current = sortedCollections;
        setCollectionsToDisplay(sortedCollections);
      }
      if (event.data.reply == BUILD_SHARED_USERS_LIST_REPLY) {
        const requestToken = event.data.payload.token;
        if (
          backgroundWorkerBuildSharedUsersRequestToken.current != requestToken
        ) {
          if (DEBUG) {
            console.log(
              "CollectionAggDataContainer: drop this reply from worker, request token doesn't match"
            );
          }
          return;
        }
        const allSharedUsersList = event.data.payload.sharedUsersList;
        /*  console.log(
          "List of sharedUsers is " + JSON.stringify(allSharedUsersList)
        ); */
        dispatch(setSharedUsersList(allSharedUsersList));
      }
    });
    return () => {
      //thread cleanup
      if (backgroundWorkerRef && backgroundWorkerRef.current) {
        backgroundWorkerRef.current.terminate();
      }
    };
  }, []);

  React.useEffect(() => {
    const displayedCollections = !data
      ? null
      : data.collections
      ? data.collections
      : data.archivedTopLevelCollections;
    if (!displayedCollections || displayedCollections.length == 0) {
      if (DEBUG) {
        console.log("No collections to filter!");
      }
      return;
    }

    backgroundWorkerRef.current.postMessage({
      request: SORT_COLLECTIONS,
      payload: {
        token: ++backgroundWorkerRequestToken.current,
        collections: displayedCollections,
      },
    });

    if (!displayedCollections[0].isArchived) {
      //We compute shared users list only for non archived collections...
      backgroundWorkerRef.current.postMessage({
        request: BUILD_SHARED_USERS_LIST,
        payload: {
          token: ++backgroundWorkerBuildSharedUsersRequestToken.current,
          collections: displayedCollections,
        },
      });
    }
  }, [data]);

  const handleChange = (event: SelectChangeEvent<CollectionObj>) => {
    let value = event.target.value;
    if (!value) {
      event.stopPropagation();
      return true;
    }
    console.log("handle change " + JSON.stringify(value));

    /* const {
      target: { value },
    } = event; */
    setSelectedCollection(value);
    setSelectedCollectionId(value.id);
    setCollectionsToDisplay(sortedCollectionsRef.current);
    if (isCollectionArchived) {
      history.replace(ARCHIVED_COLLECTION_BASE_PATH + value.id);
    } else {
      history.replace(COLLECTION_BASE_PATH + value.id);
    }
  };

  React.useEffect(() => {
    if (!userObj) {
      //wait for the user information to be retrieved
      return;
    }
    if (retrieveCollectionQuery.data) {
      dispatch(setSearchContext(retrieveCollectionQuery.data.collection.name)); // we dispatch this so that the search hint indicates that we are trying to search in the currently opened collection.
      dispatch(setCurrentCollection(retrieveCollectionQuery.data.collection)); // we dispatch this so that all newly added items added to the currently opened collection

      //We check for the right the user has in this collection if it's a shared one, and dispatch it to hide some UI components eventually if user is only an observer
      if (
        !retrieveCollectionQuery.data.collection.sharedUsers ||
        retrieveCollectionQuery.data.collection.sharedUsers.length == 0
      ) {
        dispatch(
          setCurrentUserOpenedCollectionRight(
            UserAccessRight.OWNER_ACCESS_RIGHT
          )
        );
      }
      const currentUserAR = retrieveCollectionQuery.data.collection.sharedUsers.find(
        (userAccessRight: SharedUserAccess) => {
          console.log("user email is " + userObj.email);
          if (userAccessRight.user.id == userObj.id) {
            return userAccessRight;
          }
        }
      );
      if (currentUserAR) {
        dispatch(setCurrentUserOpenedCollectionRight(currentUserAR?.right));
      }
    }
    return () => {
      dispatch(setSearchContext(""));
      dispatch(setCurrentCollection(null));
      dispatch(
        setCurrentUserOpenedCollectionRight(UserAccessRight.OWNER_ACCESS_RIGHT)
      );
    };
  }, [retrieveCollectionQuery.data, userObj]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleBackPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSearchInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const searchInput = e.target.value;
      if (!searchInput || searchInput.length == 0) {
        setCollectionsToDisplay(sortedCollectionsRef.current);
        return;
      }
      const rgxp = new RegExp(searchInput, "gi");
      const searchResult = sortedCollectionsRef.current.filter(
        (collection: CollectionObj) => {
          return collection.name.match(rgxp);
        }
      );
      setCollectionsToDisplay(searchResult);
    },
    [sortedCollectionsRef, setCollectionsToDisplay]
  );

  const getSharedUserBadgeContent = React.useCallback(() => {
    if (
      currentlyOpenedCollectionCurrentUserRight ==
      UserAccessRight.OWNER_ACCESS_RIGHT
    ) {
      return t("owner_title");
    } else if (
      currentlyOpenedCollectionCurrentUserRight ==
      UserAccessRight.MEMBER_ACCESS_RIGHT
    ) {
      return t("shared_user_member");
    } else if (
      currentlyOpenedCollectionCurrentUserRight ==
      UserAccessRight.READER_ACCESS_RIGHT
    ) {
      return t("shared_user_observer");
    }
  }, [currentlyOpenedCollectionCurrentUserRight]);

  const getSharedUserBadgeTooltipContent = React.useCallback(() => {
    if (
      currentlyOpenedCollectionCurrentUserRight ==
      UserAccessRight.OWNER_ACCESS_RIGHT
    ) {
      return t("owner_badge_tooltip_content");
    } else if (
      currentlyOpenedCollectionCurrentUserRight ==
      UserAccessRight.MEMBER_ACCESS_RIGHT
    ) {
      return t("member_badge_tooltip_content");
    } else if (
      currentlyOpenedCollectionCurrentUserRight ==
      UserAccessRight.READER_ACCESS_RIGHT
    ) {
      return t("observer_badge_tooltip_content");
    }
  }, [currentlyOpenedCollectionCurrentUserRight]);

  const getSharedUserBadgeColor = React.useCallback(() => {
    if (
      currentlyOpenedCollectionCurrentUserRight ==
      UserAccessRight.OWNER_ACCESS_RIGHT
    ) {
      return colorMemento;
    } else if (
      currentlyOpenedCollectionCurrentUserRight ==
      UserAccessRight.MEMBER_ACCESS_RIGHT
    ) {
      return "#fee8b3";
    } else if (
      currentlyOpenedCollectionCurrentUserRight ==
      UserAccessRight.READER_ACCESS_RIGHT
    ) {
      return colorMementoBeige;
    }
  }, [currentlyOpenedCollectionCurrentUserRight]);

  return (
    <>
      <>
        {retrieveCollectionQuery.data &&
          retrieveCollectionQuery.data.collection.name && (
            <Helmet>
              <title>
                {retrieveCollectionQuery.data.collection.name} | Memento
              </title>
            </Helmet>
          )}

        {displayManageUsersAccessModal && selectedCollection && (
          <ManageUserAccessModal
            open={displayManageUsersAccessModal}
            handleModalClose={() => {
              setDisplayManageUsersAccessModal(false);
            }}
            collection={selectedCollection}
            sharedUserAccessList={
              selectedCollection.sharedUsers
                ? selectedCollection.sharedUsers
                : []
            }
          ></ManageUserAccessModal>
        )}

        <ControlsContainer>
          <IconButton
            className={classes.backButton}
            onClick={handleBackPressed}
          >
            <ArrowBackIcon
              style={{ width: "42px", height: "42px" }}
            ></ArrowBackIcon>
          </IconButton>
          <FormControl sx={{ m: 1, minWidth: "30%", mt: 3 }}>
            <Select
              displayEmpty
              className={classes.select}
              value={
                retrieveCollectionQuery.data
                  ? retrieveCollectionQuery.data.collection
                  : ""
              }
              open={openSelect}
              onOpen={() => {
                setOpenSelect(true);
              }}
              onClose={() => {
                if (!searchFocused) {
                  setOpenSelect(false);
                }
              }}
              onChange={handleChange}
              renderValue={(selected) => {
                if (selected) {
                  return (
                    <SelectItemContainer>
                      <ImagePreview
                        previewUrls={selected.previewUrls}
                        imgSpacing="0px"
                        width="25%"
                        height="48px"
                      />
                      <Typography style={{ paddingLeft: "8px" }} variant="h4">
                        {selected.name}
                      </Typography>

                      {selected &&
                        selected.itemsCount &&
                        selected.itemsCount > 0 && (
                          <Typography
                            style={{ marginLeft: "8px" }}
                            component="h4"
                            variant="h5"
                            color="text.secondary"
                          >
                            ({selected.itemsCount})
                          </Typography>
                        )}
                      {selected.isPublic && (
                        <PublicIcon
                          sx={{
                            marginLeft: "4px",
                          }}
                        />
                      )}
                    </SelectItemContainer>
                  );
                }
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {/* <MenuItem disabled value="">
                <Typography variant="h5">Collections</Typography>
              </MenuItem> */}

              <div
                /*This prevents selecting other menu items on keyboard press */
                onKeyDown={(e) => e.stopPropagation()}
                style={{
                  paddingRight: "8px",
                  paddingLeft: "8px",
                }}
              >
                <Input
                  id="outlined-adornment-amount"
                  placeholder={t("search_action")}
                  autoComplete="off"
                  className={classes.inputLabel}
                  //value={linkInputValue}
                  onFocus={() => {
                    setSearchFocused(true);
                  }}
                  onBlur={() => {
                    setSearchFocused(false);
                  }}
                  onChange={handleSearchInput}
                  inputProps={{
                    disableUnderline: true,
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon
                        style={{ width: "18px", height: "18px", opacity: 0.5 }}
                      />
                    </InputAdornment>
                  }
                />
              </div>
              {collectionsToDisplay &&
                collectionsToDisplay.map((collection: CollectionObj) => {
                  if (collection.id == selectedCollectionId) {
                    return null;
                  }
                  return (
                    <MenuItem key={collection.name} value={collection}>
                      <SelectItemContainer>
                        <ImagePreview
                          previewUrls={collection.previewUrls}
                          imgSpacing="0px"
                          width="48px"
                          height="48px"
                        />
                        <Typography style={{ paddingLeft: "8px" }} variant="h4">
                          {collection.name}
                        </Typography>
                        {collection && collection.itemsCount > 0 && (
                          <Typography
                            style={{ marginLeft: "8px" }}
                            component="h4"
                            variant="h5"
                            color="text.secondary"
                          >
                            ({collection.itemsCount})
                          </Typography>
                        )}
                        {collection && collection.isPublic && (
                          <PublicIcon
                            sx={{
                              marginLeft: "4px",
                            }}
                          />
                        )}
                      </SelectItemContainer>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              marginLeft: "16px",
              height: "100%",
              alignItems: "center",
            }}
          >
            {selectedCollection && selectedCollection.sharedUsers && (
              <SharedUsersAvatars
                sharedUsersList={selectedCollection.sharedUsers}
                onManageAccessClicked={() => {
                  setDisplayManageUsersAccessModal(true);
                }}
              />
            )}

            {displayPublishMenu && (
              <PublishCollectionFloatingMenu
                anchorEl={displayPublishMenu}
                onClose={() => {
                  setDisplayPublishMenu(null);
                }}
              />
            )}

            {(isCollectionShared(selectedCollection) ||
              selectedCollection?.isPublic) && (
              <>
                <Tooltip
                  open={showCommentsTooltip}
                  arrow
                  title={styledTooltipTitle(t("show_all_comments_action"))}
                >
                  <IconButton
                    onClick={toggleCommentsDrawer}
                    onMouseEnter={() => {
                      setShowCommentsTooltip(true);
                    }}
                    onMouseLeave={() => {
                      setShowCommentsTooltip(false);
                    }}
                  >
                    <CommentIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                {isCollectionShared(selectedCollection) && (
                  <Tooltip
                    open={showSharedUserRightBadge}
                    arrow
                    title={styledTooltipTitle(
                      getSharedUserBadgeTooltipContent()
                    )}
                  >
                    <Typography
                      variant="h5"
                      onMouseEnter={() => {
                        setShowSharedUserRightBadge(true);
                      }}
                      onMouseLeave={() => {
                        setShowSharedUserRightBadge(false);
                      }}
                      style={{
                        borderRadius: "4px",
                        backgroundColor: getSharedUserBadgeColor(),
                        fontSize: "1.2rem",
                        padding: "6px",
                        marginLeft: "24px",
                      }}
                    >
                      {getSharedUserBadgeContent()}
                    </Typography>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </ControlsContainer>

        <ContentContainer>
          <div style={{ height: "64px" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingLeft: "24px",
              paddingRight: "24px"
            }}
          >
            <NestedCollectionsContainer parentCollectionId={collectionId} />
          </Box>
          {selectedCollection && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  marginRight: "5%",
                }}
              >
                <AggdataDisplayControl
                  onSortTypeChanged={(sortType: SORT_TYPE) => {
                    setDisplaySortType(sortType);
                  }}
                  onViewTypeChanged={(viewType: VIEW_TYPE) => {
                    setDispayViewType(viewType);
                  }}
                  onFilterTypeChanged={(filterType: FILTER_TYPE) => {
                    setDataTypeFilter(filterType);
                  }}
                  displaySortByComments={isCollectionShared(selectedCollection)}
                  displaySortByPin={!isCollectionShared(selectedCollection)}
                  selectedTags={selectedFilterTags}
                  onSelectedTagsChanged={(tags: AggDataTagObj[]) => {
                    setSelectedFilterTags(tags);
                  }}
                  collectionId={collectionId}
                  collectionName={
                    retrieveCollectionQuery.data
                      ? retrieveCollectionQuery.data.collection.name
                      : undefined
                  }
                />
              </Box>

              {selectedFilterTags.length > 0 && (
                <SimpleTagsFilter
                  allSelectedText={t("filter_all_collections_selected_text")}
                  inputHint={t("filter_by_tags_title")}
                  onSelectionChanged={(values) => {
                    // console.log("YIT simple filter tag selection changes " + JSON.stringify(values))
                    setSelectedFilterTags(
                      values.map((value) => {
                        return { name: value };
                      })
                    );
                  }}
                  selectedValues={selectedFilterTags.map(
                    (selectedTag) => selectedTag.name
                  )}
                  collectionId={collectionId}
                />
              )}
              <AggDataItemsContainer
                dataTypeFilter={dataTypeFilter}
                displayViewType={displayViewType}
                displaySortType={displaySortType}
                requestType={
                  isCollectionArchived
                    ? RequestTypes.ARCHIVED_COLLECTION
                    : RequestTypes.COLLECTION
                }
                collectionId={collectionId}
                filterTags={selectedFilterTags}
                isCollectionShared={
                  selectedCollection.sharedUsers &&
                  selectedCollection.sharedUsers.length > 0
                }
              />
            </>
          )}
        </ContentContainer>
      </>
      {selectedCollection && (
        <CommentsDrawer
          collection={selectedCollection}
          open={openCommentsDrawer}
          onClose={() => {
            setOpenCommentsDrawer(false);
          }}
        />
      )}
    </>
  );
}

export default React.memo(CollectionAggDataContainer);

import React, { useState, useCallback, useEffect } from "react";

import { AggDataObj } from "models/aggdata";
import { formatIso8601, getInChronometerFormat } from "shared/dataUtils";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import UserAvatarWithTooltip from "components/UI/User/UserAvatarWithTooltip";
import CollectionBadge from "components/UI/CollectionBadge";
import { CollectionObj } from "models/collection";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

interface AggDataFeedItemHeaderProps {
  aggdata: AggDataObj;
}

const AggDataFeedItemHeader = ({ aggdata }: AggDataFeedItemHeaderProps) => {


  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box //name and created date container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <UserAvatarWithTooltip
          aggData={aggdata}
          topRight={false}
          size={"medium"}
        />
      </Box>
      <Box //name and created date container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginLeft: "12px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.3rem",
            fontWeight: 500,
            aligntText: "start",
          }}
        >
          {aggdata?.owner?.firstName + " " + aggdata?.owner?.lastName}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.3rem",
            aligntText: "start",
          }}
        >
          {formatIso8601(aggdata?.created)}
        </Typography>
      </Box>
      <Box
        sx={{
          marginLeft: "8px",
        }}
      >
        {aggdata.collection && !currentlyOpenedCollection &&  (
          <CollectionBadge collection={aggdata.collection} />
        )}
      </Box>
    </Box>
  );
};

export default React.memo(AggDataFeedItemHeader);

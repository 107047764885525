import { SUBSCRIPTION_MEMENTEE_PLAN, SUBSCRIPTION_MEMENTEE_PLUS_PLAN } from "configuration";


export function getPriceInLocalCurrency(currencyIso4217: string | undefined | null, planId: number) {
    switch (currencyIso4217) {
        case "EUR":
            return planId == SUBSCRIPTION_MEMENTEE_PLAN.id ?  "4.99" : "9.99";
        case "USD":
            return planId == SUBSCRIPTION_MEMENTEE_PLAN.id ?  "4.99" : "9.99";
        case "INR":
            return planId == SUBSCRIPTION_MEMENTEE_PLAN.id ?  "399" : "799";
        case "MAD":
            return planId == SUBSCRIPTION_MEMENTEE_PLAN.id ?  "49" : "99";
        case "CAD":
            return planId == SUBSCRIPTION_MEMENTEE_PLAN.id ?  "6.99" : "13.99";
        case "RUB":
            return planId == SUBSCRIPTION_MEMENTEE_PLAN.id ?  "399" : "799";
        default:
            return planId == SUBSCRIPTION_MEMENTEE_PLAN.id ? "4.99" : "9.99";
    }
}

export function getCurrencySymbol(currencyIso4217: string | undefined | null): String {
    switch (currencyIso4217) {
        case "EUR":
            return "€";
        case "USD":
            return "$";
        case "INR":
            return "₹";
        case "MAD":
            return "MAD";
        case "CAD":
            return "$ CA";
        case "RUB":
            return "₽";
        default:
            return "$"
    }
}
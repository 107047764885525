import React, { useCallback, useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import Logo from "icons/favicon.png";
import { Container } from "./styles";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { resetPassword } from "api/authApi";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";

import { useTranslation } from "react-i18next";

import { PASSWORD_MIN_LENGTH } from "configuration";
import { ResetPasswordApiResult, ResetPasswordStatusCodes } from "api/authApi";
import { useParams } from "react-router-dom";
import { LOGIN } from "navigation/Constants";
import { colorMementoBeige, colorMemento } from "shared/colors";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import LogoIcon from "icons/ic_memento_m_letter.svg";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from "shared/utility";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(
    90deg,
    rgba(255, 241, 206, 1) 0%,
    rgba(255, 253, 250, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  padding-top: 5%;
  overflow-y: hidden;
`;

interface RequestResetFormData {
  password: string;
  password_confirmation: string;
  uid: string;
  token: string;
}

interface SignUpResult {
  signupFailed: boolean;
  errorMsg: string;
}

const ResetPassword: React.FC = () => {
  const [requestResetSuccess, setRequestResetSuccess] = useState(false);
  const [signupStatus, setSignupStatus] = useState<SignUpResult>({
    signupFailed: false,
    errorMsg: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const params: any = useParams();

  const handleSubmit = useCallback(
    async (data: RequestResetFormData) => {
      try {
        setIsLoading(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string()
            .min(
              PASSWORD_MIN_LENGTH,
              t("password_too_short_error", {
                password_min_length: PASSWORD_MIN_LENGTH,
              })
            )
            .matches(/^(?=.*[0-9])/, t("reset_password_strength_error"))
            .required(t("password_required_error")),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref("password"), null],
            t("password_match_confirmation_error")
          ),
        });

        await schema.validate(data, { abortEarly: false });
        data = { ...data, uid: params.uid, token: params.token };
        resetPassword(data).then((result: ResetPasswordApiResult) => {
          switch (result.statusCode) {
            case ResetPasswordStatusCodes.FAILURE.valueOf():
              setRequestResetFailed(t("reset_password_failed_err_msg"));
              break;
            case ResetPasswordStatusCodes.SUCCESS.valueOf():
              setRequestResetSuccess(true);
              break;
          }
        });
      } catch (err) {
        setIsLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [history]
  );

  const setRequestResetFailed = useCallback(
    (errorMsgArg: string) => {
      setSignupStatus({ signupFailed: true, errorMsg: errorMsgArg });
      setIsLoading(false);
    },
    [signupStatus]
  );

  const handleLogin = useCallback(() => {
    history.push(LOGIN);
  }, []);

  if (requestResetSuccess) {
    return (
      <MainContainer>
        <Helmet>
          <title>
            {capitalizeFirstLetter(t("reset_password_title"))} | Memento
          </title>
        </Helmet>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            background: "#f8fbf8",
            borderRadius: "8px",
            minWidth: "30%",
            "@media (max-width: 780px)": {
              width: "80%",
            },
          }}
          elevation={1}
        >
          <Link to="/">
            <img
              id="logo"
              width="82px"
              height="82px"
              src={LogoIcon}
              alt="Memento logo"
            />
          </Link>
          <Typography
            sx={{
              marginBottom: "24px",
              fontWeight: "300",
              textAlign: "center",
            }}
            variant="h4"
          >
            {t("reset_password_success_msg")}
          </Typography>

          <Button
            onClick={handleLogin}
            id="reset-confirmation-login-btn"
            loading={false}
            type="submit"
          >
            {" "}
            {t("login_action")}
          </Button>
        </Paper>
      </MainContainer>
    );
  } else
    return (
      <MainContainer>
        <Helmet>
          <title>
            {capitalizeFirstLetter(t("reset_password_title"))} | Memento
          </title>
        </Helmet>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            background: "#f8fbf8",
            borderRadius: "8px",
            minWidth: "30%",
            "@media (max-width: 780px)": {
              width: "80%",
            },
          }}
          elevation={1}
        >
          {signupStatus.signupFailed && (
            <Alert
              style={{ width: "80%", fontSize: "1.4rem" }}
              id="error-alert"
              severity="error"
            >
              {signupStatus.errorMsg}
            </Alert>
          )}
          <Link to="/">
            <img
              id="logo"
              width="82px"
              height="82px"
              src={LogoIcon}
              alt="Memento logo"
            />
          </Link>
          <Typography sx={{ marginBottom: "24px" }} variant="h4">
            {t("enter_new_password_msg")}
          </Typography>
          <Form
            style={{ alignItems: "center", width: "80%" }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Input
              name="password"
              type="password"
              placeholder={t("password")}
            />
            <Input
              name="password_confirmation"
              type="password"
              placeholder={t("password_confirmation")}
            />
            <Button type="submit" loading={isLoading}>
              {" "}
              {t("reset_password_action")}
            </Button>
          </Form>
        </Paper>
      </MainContainer>
    );
};

export default ResetPassword;

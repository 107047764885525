import React, { Component } from "react";
import Header from "components/Landing/Header/Header";
import Footer from "components/Landing/Footer/Footer";
import Content from "pages/Landing/Content";
import styled from "styled-components";
import { landingBackgroundColor } from "shared/colors";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { BLOG_URL, LOGIN, PRICING, REGISTER } from "navigation/Constants";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import MobileHeader from "components/Landing/Header/MobileHeader";

const LandingContainer = styled.div`
  background: ${landingBackgroundColor};
  /**
   * 
  */
  background: linear-gradient(
    90deg,
    rgba(255, 241, 206, 1) 20%,
    rgba(255, 253, 250, 1) 100%
  );
  background: rgba(255, 241, 206, 1);

  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

const LandingMain: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //setAnchorEl(event.currentTarget);
  };
  const handlePricingClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open(PRICING, "_blank");
  };
  const handleBlogClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open(BLOG_URL, "_blank");
  };

  //console.log("en var " + process.env.REACT_APP_PUBLIC_URL);
  return (
    <LandingContainer>
      <Helmet>
        <title>{t("landing_main_title")}</title>
        <meta name="description" content={t("landing_main_description")} />

        {/* for twitter preview */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@app_memento" />
        <meta name="twitter:creator" content="@app_memento" />
        <meta name="twitter:title" content={t("landing_main_title")} />
        <meta
          name="twitter:description"
          content={t("landing_main_description")}
        />
        <meta
          name="twitter:image"
          content={
            "https://www.mementocollect.com" + "/mem_data_capture_withbg.jpg"
          }
        />

        {/* for facebook, linkedin, instagram preview */}
        <meta property="og:title" content={t("landing_main_title")} />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content={t("landing_main_description")}
        />
        <meta property="author" content="Memento" />
        <meta
          property="og:image"
          content={
            "https://www.mementocollect.com" + "/mem_data_capture_withbg.jpg"
          }
        />
      </Helmet>
      <Box
        sx={{
          paddingRight: "8%",
          paddingLeft: "8%",
        }}
      >
        {!isMobile && <Header />}
        {isMobile && <MobileHeader />}
      
      </Box>
      <Content />
      <Footer />
    </LandingContainer>
  );
};

export default LandingMain;

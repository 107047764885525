import AggDataLayout from "containers/AggData/AggDataLayout";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { RootState } from "store/reducers";
import { useDispatch, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { Input } from "containers/InputField/InputElements";
import { useMutation } from "@apollo/client";
import {
  addNewDataGql,
  updateAggDataGql,
  uploadNoteImagesGql,
} from "graphql/mutations";
import {
  retrieveAllDataGql,
  retrieveAggdataForIdFromCacheGql,
  retrieveCollectionGql,
} from "graphql/queries";
import {
  updateAggDataInCache,
  addAggDataInArchivedDataCache,
  deleteAggDataFromCache,
  deleteAggDataFromArchivedDataCache,
  addAggDataInCollectionSpecificCache,
} from "graphql/helpers";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  convertHtmlTagsForDisplay,
  convertHtmlRgbValuesToHex,
} from "utils/htmlutils";
import { shouldAggDataBeCreadted, AggDataObj, DataType } from "models/aggdata";
import { CollectionObj } from "models/collection";
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";
import { makeStyles } from "@mui/styles";
import { colorGreyMemento } from "shared/colors";
import NoteTasksList from "containers/AggData/NoteEditor/NoteTasksList";
import { NoteTask } from "shared/types";
import { parseNoteTasksAsSortedList } from "shared/dataUtils";
import NoteEditorToolbar from "containers/AggData/NoteEditor/NoteEditorToolbar";
import {
  setAllItemsMaxPinScore,
  setMaxSortOrderScore,
  setMaxSortOrderScoreCurrentCollection,
  setEditableAggdataNote,
} from "store/actions/aggdataActions";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  showSnackbar,
  openAttachDataToCollectionDialog,
  displayMaxDataUsageReachedDialog,
} from "store/actions/view";
import { addAggDataInCache } from "graphql/helpers";
import { isMaxDataUsageReached } from "models/user";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import BoltIcon from "@mui/icons-material/Bolt";
import { useHistory } from "react-router-dom";
import { PRICING } from "navigation/Constants";
import { getNoteColorFromColorIndex } from "shared/colors";
import useScrollBlock from "hooks/scrollHook";
import { UserAccessRight, UserObj, SharedUserAccess } from "models/user";
import { isAggdataShared } from "models/aggdata";
import { colorMementoBeige } from "shared/colors";
import { DEBUG } from "configuration";
import NoteEditorImagesPreviewer from "./NoteEditorImagesPreviewer";
import Compressor from "compressorjs";
import { Box, LinearProgress } from "@mui/material";

export const ToolbarContainer = styled.div`
  position: sticky;
  width: 100%;
  bottom: 0px;
  z-index: 900;
  flex: 0 0 auto;
`;

const popup = keyframes`
    from {
        opacity: 0;
        transform: scale(.4);
        transform: translate(-50%, -50%);
    } to {
        opacity: 1; 
        transform: scale(1);
        transform: translate(-50%, -50%);
    }
`;

export const NoteEditorContainer = styled("div")<{
  isFullScreen?: boolean;
  bgColor: string;
}>`
  position: fixed;
  border-radius: 8px;
  border: 1px solid rgba(66, 66, 66, 0.2);
  word-wrap: break-word;
  white-space: pre-wrap;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  transition: width 0.2s ease-in, height 0.2s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => props.bgColor};
  z-index: 800;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
  }
  &:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
      0 1px 3px 1px rgba(60, 64, 67, 0.149);
  }

  .public-DraftStyleDefault-block {
    margin: 0px; //this is to avoid the default extra space between lines
  }

  .rdw-editor-toolbar {
    background: ${(props) => props.bgColor};
    border: 0px;
  }
  .rdw-option-wrapper {
    background-color: ${(props) => props.bgColor};
    border: 0px;
  }
  .rdw-option-active {
    background: ${(props) => props.bgColor};
    border: 1px solid white;
  }

  .wrapper-editor-cls {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    resize: none;
    flex-grow: 1;
    flex: 1 1 auto;
    font-size: 1.7rem;
  }
  .editor-cls {
    height: 100%; /*Important to make the scrolling work!*/
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 24px;
    overflow: none;
    &::-webkit-scrollbar {
      width: 0.1em;
      height: 0.1em;
    }
  }

  .rdw-colorpicker-cube {
    border-radius: 6px;
  }

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42%;
  height: 80vh;
  z-index: 800;
  cursor: default;
  animation: ${popup} 0.3s ease-out;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149);

  @media (max-width: 1024px) {
    width: 55%;
  }
  @media (max-width: 768px) {
    width: 75%;
    height: 80vh;
  }
  @media (max-width: 576px) {
    width: 100%;
  }

  ${({ isFullScreen }) =>
    isFullScreen &&
    `
  height: 100vh; 
  width: 100vw;  
  margin-top: 0px;
  margin-bottom: 0px;

  .editor-cls {
    padding-right: 16px;
    padding-left: 16px;
  }
  `};
`;

export const OpaqueOverlay = styled.div`
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  z-index: 4;
`;

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    width: "50px",
    height: "50px",
    marginTop: "6px",
    right: 0,
    marginRight: "10px",
    verticalAlign: "middle",
    position: "absolute",
    cursor: "pointer",
    color: colorGreyMemento,
    "&:hover": {
      opacity: "0.87",
    },
  },
  readOnlyContainer: {
    marginTop: "6px",
    right: "50px",
    marginRight: "10px",
    verticalAlign: "middle",
    position: "absolute",
    cursor: "pointer",
    color: colorGreyMemento,
    "&:hover": {
      opacity: "0.87",
    },
  },
  checkBoxText: {
    "& .MuiTypography-root": {
      fontSize: "1.8rem",
    },
  },
}));

const expandIconStyle = {
  height: "24px",
  width: "24px",
  display: "flex",
  alignItems: "center",
};

type BlurEventType =
  | React.FocusEvent<HTMLInputElement>
  | React.FocusEvent<HTMLTextAreaElement>;

interface NoteEditorProps {
  aggData: AggDataObj | null | undefined;
  reminder?: string; //A note can be created with an initial reminder, if the note is created from the reminders view. This field should be json string equivalent to an array with a Reminder object
  onContentChanged?: (aggData: AggDataObj) => void;
  onPinUnpinAction: () => void;
  isPublic?: boolean;
  //createNewNote?: boolean;
}

const EMPTY_NOTE: AggDataObj = {
  note: { noteTitle: "", noteContent: "", noteTasks: "" },
};

enum SAVING_STATE {
  SAVED = 0,
  SAVE_ONGOING = 1,
  SAVE_FAILED = 2,
}

//Forward ref is used to forward the reference from the clickawaylistener to the child component
const NoteEditor = React.forwardRef((props: NoteEditorProps, ref) => {
  //const aggData = props.aggData!!;

  const currentUser = useSelector((state: RootState) => state.auth.user);
  //To be used to enforce note saving
  const [noteSavingState, setNoteSavingState] = useState(SAVING_STATE.SAVED);

  const [displayNoteImagesLoading, setDisplayNoteImagesLoading] = useState(
    false
  );

  const isEditorClosed = useRef(false);
  const { loading, error, data } = useQuery(retrieveAggdataForIdFromCacheGql, {
    variables: { id: props.aggData?.id },
    fetchPolicy: "cache-only",
  });
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );

  const [uploadNoteImages, uploadNoteImagesResult] = useMutation(
    uploadNoteImagesGql
  );

  //We compute the current user right for this aggdata
  const [currentUserRight, _] = React.useState(() => {
    if (!props.aggData) {
      return UserAccessRight.OWNER_ACCESS_RIGHT;
    }
    if (!isAggdataShared(props.aggData)) {
      return UserAccessRight.OWNER_ACCESS_RIGHT;
    } else if (!currentUser) {
      return UserAccessRight.READER_ACCESS_RIGHT;
    } else {
      const currentUserAccess = props.aggData?.collection.sharedUsers.find(
        (sharedUserAccess: SharedUserAccess) =>
          sharedUserAccess.user.id == currentUser.id
      );
      //console.log("current user right " + JSON.stringify(currentUserAccess));
      return currentUserAccess?.right;
    }
  });

  const history = useHistory();

  const [readOnlyMode, setReadOnlyMode] = useState(
    currentUserRight == UserAccessRight.READER_ACCESS_RIGHT
  );

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const maxSortOrderScoreForCurrentCollection: number = useSelector(
    (state: RootState) =>
      state.aggdataReducer.maxSortOrderScoreInCurrentCollection
  );

  const [addAggData, addAggdataResult] = useMutation(addNewDataGql, {
    //We refetch the collection in order to update the changes applied by backend, notably items count and preview urls.
    refetchQueries: [
      {
        query: currentlyOpenedCollection ? retrieveCollectionGql : undefined, // if no collection, avoid trying a useless query
        variables: {
          id: currentlyOpenedCollection ? currentlyOpenedCollection.id : null,
        },
      },
    ],
  });

  const aggData: AggDataObj = props.isPublic
    ? props.aggData
    : data && data.aggdata
    ? data.aggdata
    : EMPTY_NOTE;

  const classes = useStyles();
  const [blockScroll, allowScroll] = useScrollBlock();
  const dispatch = useDispatch();
  const [noteTasks, setNoteTasks] = useState<NoteTask[]>(
    aggData?.note.noteTasks
      ? parseNoteTasksAsSortedList(aggData?.note.noteTasks)
      : []
  ); //Sorted items allow to have a simple drag and drop implementation

  const [noteColorIndex, setNoteColorIdex] = useState(aggData?.note?.noteColor);
  const { t } = useTranslation();

  const [isFullScreen, setFullScreen] = useState(false);
  const displayableNoteContent = convertHtmlTagsForDisplay(
    aggData?.note?.noteContent ? aggData?.note?.noteContent : ""
  );
  const contentBlock = htmlToDraft(displayableNoteContent);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
        contentBlock.entityMap
      )
    )
  );

  const maxAllItemsPinScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxAllItemsPinScore
  );

  const maxSortOrderScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxSortOrderScore
  );

  const currentNoteContent = useRef(
    draftToHtml(convertToRaw(editorState.getCurrentContent()))
  );
  const lastUpdatedNoteTasks = useRef(JSON.stringify(noteTasks));
  const lastSavedTitle = React.useRef(aggData?.note?.noteTitle);
  const titleInputRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (currentUser) {
      if (isMaxDataUsageReached(currentUser)) {
        setReadOnlyMode(true);
      }
    }
  }, [currentUser]);
  /**
   * We register listener to listen to keyboard events.
   * For now we only list to escape that will:
   *  - Exit full screen mode if the note editor is fullscreen
   *  - [To do] Exit the note editor if the noteeditor is not in fullscreen
   */
  React.useEffect(() => {
    const scroll = window.scrollY;
    if (isFullScreen) blockScroll();
    document.addEventListener("keydown", handleKeyboardEvent);

    return () => {
      if (isFullScreen) allowScroll();
      document.removeEventListener("keydown", handleKeyboardEvent);
      window.scroll(0, scroll); //We restore the scroll position
    };
  }, [isFullScreen]);

  //This will save the note content
  const handleEditorBlurEvent = useCallback(
    (e: BlurEventType | null, editorState: EditorState) => {
      //console.log("handleEditorBlurEvent");
      const rawHtmlContent = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      if (currentNoteContent.current != rawHtmlContent) {
        const updateData = {
          id: aggData.id,
          note: { noteContent: convertHtmlRgbValuesToHex(rawHtmlContent) },
        };

        const updateAggdata = {
          ...aggData,
          note: { ...aggData.note, noteContent: updateData.note.noteContent },
        };
        if (DEBUG)
          console.log("handle update " + JSON.stringify(updateAggdata));
        updateAggDataInCache(updateAggDataResult.client.cache, updateAggdata);
        handleBackendUpdate(updateData);
        currentNoteContent.current = rawHtmlContent;
      }
    },
    [aggData]
  );

  const handleKeyboardEvent = useCallback(
    (e: any) => {
      e = e || window.event;
      switch (e.key) {
        case "Escape":
          if (isFullScreen) {
            setFullScreen(false);
          }
          handleEditorBlurEvent(null, editorState);
          break;
      }
    },
    [isFullScreen, handleEditorBlurEvent]
  );

  const [updateAggData, updateAggDataResult] = useMutation(updateAggDataGql, {
    //refetchQueries: [{ query: retrieveAllDataGql }],
  });

  React.useEffect(() => {
    //This is used to save the content of the note just before the tab gets either closed, or refreshed.
    window.onbeforeunload = function (e) {
      e.preventDefault();
      handleEditorBlurEvent(null, editorState);
      saveEditorTitleContent();
    };
  }, [editorState, handleEditorBlurEvent]);

  const saveEditorTitleContent = useCallback(() => {
    //console.log("save " + titleInputRef?.current)
    if (!titleInputRef?.current) {
      return;
    }
    const currentTitleInputValue = titleInputRef?.current.value;
    if (
      currentTitleInputValue &&
      currentTitleInputValue != lastSavedTitle?.current
    ) {
      const updateData = {
        id: aggData.id,
        note: { noteTitle: currentTitleInputValue },
      };
      updateAggDataInCache(updateAggDataResult.client.cache, updateData);
      handleBackendUpdate(updateData, () => {
        lastSavedTitle.current = currentTitleInputValue;
      });
    }
  }, [lastSavedTitle, titleInputRef, aggData, updateAggData]);

  const dataCreationOngoing = React.useRef(false);

  const handleBackendUpdate = useCallback(
    (aggdata: any, onUpdateOk?: () => void, onError?: () => void) => {
      console.log("handleBackendUpdate" + JSON.stringify(aggdata));
      if (aggdata.id) {
        updateAggData({
          variables: { input: aggdata },
          update(cache, { data: { updateAggdata } }) {
            onUpdateOk && onUpdateOk();
          },
        }).catch((e) => {
          onError && onError();
        });
      } else if (shouldAggDataBeCreadted(aggdata)) {
        if (dataCreationOngoing.current) {
          return;
        }
        //might need to be created
        aggdata.dataType = DataType.NOTE_TYPE;
        aggdata.collection = currentlyOpenedCollection?.id;

        if (props.reminder) {
          aggdata.reminders = props.reminder;
        }

        //When we are in collection UI, and it is shared, we apply collection sort order score.
        if (
          maxSortOrderScoreForCurrentCollection &&
          currentlyOpenedCollection &&
          currentlyOpenedCollection.sharedUsers.length > 0
        ) {
          aggdata.sortOrderScore = maxSortOrderScoreForCurrentCollection + 1;
          dispatch(
            setMaxSortOrderScoreCurrentCollection(
              maxSortOrderScoreForCurrentCollection + 1
            )
          );
        } else {
          aggdata.sortOrderScore = maxSortOrderScore + 1;
          dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
        }

        dataCreationOngoing.current = true;
        addAggData({
          variables: { input: aggdata },
          update(cache, { data: { createAggdata } }) {
            addAggDataInCache(cache, createAggdata.aggData);

            if (isAggdataShared(createAggdata.aggData)) {
              addAggDataInCollectionSpecificCache(
                cache,
                createAggdata.aggData,
                createAggdata.aggData.collection.id
              );
            }
            if (!isEditorClosed.current) {
              //If we already closed the editor, we prevent dispatching the note as being edited. Otherwise we do.
              dispatch(setEditableAggdataNote(createAggdata.aggData));
            }
          },
        }).finally(() => {
          dataCreationOngoing.current = false;
        });
      }
    },
    [
      updateAggData,
      addAggData,
      dispatch,
      isEditorClosed,
      props.reminder,
      maxSortOrderScore,
      maxSortOrderScoreForCurrentCollection,
      currentlyOpenedCollection,
    ]
  );

  const handleNoteTasksChanged = useCallback(
    (noteTasks: NoteTask[]) => {
      setNoteTasks(noteTasks);
      const noteTasksWithoutId = noteTasks.map((noteTask) => {
        return { ...noteTask, id: undefined };
      });
      const noteTasksAsJsonString = JSON.stringify(noteTasksWithoutId);
      console.log("changed " + noteTasksAsJsonString);
      if (lastUpdatedNoteTasks.current != noteTasksAsJsonString) {
        const updateData = {
          id: aggData.id,
          note: { noteTasks: noteTasksAsJsonString },
        };

        const updatedAggdata = {
          ...aggData,
          //id: aggData.id,
          //dataType: DataType.NOTE_TYPE,
          //allItemsPinScore: aggData.allItemsPinScore + 1,
          //dataContent: JSON.stringify(aggData.note),
          note: { ...aggData.note, noteTasks: noteTasksAsJsonString },
        };

        //delete updatedAggdata.note.__typename
        //console.log("Jeudi handlenotetaskschanged" + JSON.stringify(updatedAggdata))
        //console.log("changed 2 " + JSON.stringify(updateData));
        updateAggDataInCache(updateAggDataResult.client.cache, updatedAggdata);
        handleBackendUpdate(updateData, () => {
          lastUpdatedNoteTasks.current = noteTasksAsJsonString;
        });
      }
    },
    [setNoteTasks, handleBackendUpdate, aggData]
  );

  const handleToolbarAddCheckboxes = useCallback(() => {
    if (noteTasks.length == 0) {
      setNoteTasks([{ task: "", done: false, hasFocus: true }]);
    }
  }, [noteTasks, setNoteTasks]);

  const handlePinUnpinAction = useCallback((): void => {
    var aggDataUpdateForCache;
    var aggDataUpdateForBackend;
    const beforeUpdateAggData = { ...aggData };
    if (Number(aggData.allItemsPinScore) > 0) {
      // in this case item is pinned, we want to unpin it

      var sortOrderScoreToApply = 0;
      //When we are in collection UI, and it is shared, we apply collection sort order score.
      if (
        maxSortOrderScoreForCurrentCollection &&
        currentlyOpenedCollection &&
        currentlyOpenedCollection.sharedUsers.length > 0
      ) {
        sortOrderScoreToApply = maxSortOrderScoreForCurrentCollection + 1;
        dispatch(
          setMaxSortOrderScoreCurrentCollection(
            maxSortOrderScoreForCurrentCollection + 1
          )
        );
      } else {
        sortOrderScoreToApply = maxSortOrderScore + 1;
        dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
      }

      aggDataUpdateForCache = {
        ...aggData,
        id: aggData.id,
        allItemsPinScore: 0,
        sortOrderScore: sortOrderScoreToApply,
      };
      aggDataUpdateForBackend = {
        id: aggData.id,
        allItemsPinScore: 0,
        sortOrderScore: sortOrderScoreToApply,
      };
    } else {
      // in this case item is unpinned, we want to pin it
      aggDataUpdateForCache = {
        ...aggData,
        id: aggData.id,
        allItemsPinScore: maxAllItemsPinScore + 1,
      };
      aggDataUpdateForBackend = {
        id: aggData.id,
        allItemsPinScore: maxAllItemsPinScore + 1,
      };
      dispatch(setAllItemsMaxPinScore(maxAllItemsPinScore + 1));
    }

    updateAggDataInCache(
      updateAggDataResult.client.cache,
      aggDataUpdateForCache
    );

    handleBackendUpdate(aggDataUpdateForBackend, undefined, () => {
      updateAggDataInCache(
        updateAggDataResult.client.cache,
        beforeUpdateAggData
      );
      dispatch(
        showSnackbar({
          message: t("snackbar_failed_apply_changes_backend"),
          action: "",
          onAction: null,
          loading: false,
        })
      );
    });
  }, [
    aggData,
    updateAggData,
    maxSortOrderScoreForCurrentCollection,
    currentlyOpenedCollection,
    maxAllItemsPinScore,
    maxSortOrderScore,
  ]);

  //Understand handle click outside of note.
  const handleClickOutside = () => {
    //console.log("clickaway " + JSON.stringify(e))
    if (DEBUG) {
      console.log("handleClickOutside");
    }
    dispatch(setEditableAggdataNote(null));
    isEditorClosed.current = true;
  };

  const handleToolbarArchiveAction = useCallback(() => {
    const aggDataToUpdate: any = {
      id: aggData.id,
      isArchived: !aggData.isArchived,
      sortOrderScore: maxSortOrderScore + 1,
    };
    var refetchQueries: any = [];
    if (aggData.collection) {
      aggDataToUpdate.collection = "-1"; // means we want to detach the data from the collection
      refetchQueries = [
        {
          query: aggData.collection?.id ? retrieveCollectionGql : "", // if no collection, avoid trying a useless query
          variables: {
            id: aggData.collection?.id,
          },
        },
      ];
    }

    const modifiedDataForCache = {
      ...aggData,
      isArchived: !aggData.isArchived,
    };

    // console.log("handleToolbarArchiveAction of data " + JSON.stringify(modifiedDataForCache))

    if (aggData.isArchived) {
      //console.log("Addincache of data " + JSON.stringify(modifiedDataForCache));
      addAggDataInCache(updateAggDataResult.client.cache, modifiedDataForCache);
      deleteAggDataFromArchivedDataCache(
        updateAggDataResult.client.cache,
        modifiedDataForCache
      );
    } else {
      addAggDataInArchivedDataCache(
        updateAggDataResult.client.cache,
        modifiedDataForCache
      );
      deleteAggDataFromCache(
        updateAggDataResult.client.cache,
        modifiedDataForCache
      );
    }
    updateAggData({
      variables: { input: aggDataToUpdate },
      refetchQueries: refetchQueries,
      update(cache, { data: { updateAggdata } }) {},
    }).catch((e) => {
      if (aggData.isArchived) {
        addAggDataInArchivedDataCache(
          updateAggDataResult.client.cache,
          modifiedDataForCache
        );
        deleteAggDataFromCache(
          updateAggDataResult.client.cache,
          modifiedDataForCache
        );
      } else {
        addAggDataInCache(
          updateAggDataResult.client.cache,
          modifiedDataForCache
        );
        deleteAggDataFromArchivedDataCache(
          updateAggDataResult.client.cache,
          modifiedDataForCache
        );
      }
      dispatch(
        showSnackbar({
          message: t("snackbar_failed_apply_changes_backend"),
          action: "",
          onAction: null,
          loading: false,
        })
      );
    });
  }, [aggData, updateAggData]);

  const handleToolbarAddItemToCollectionAction = useCallback(() => {
    dispatch(openAttachDataToCollectionDialog(aggData));
  }, [aggData]);

  const dummyFunc = () => {};

  const handleColorSelected = React.useCallback(
    (selectedNoteColorIndex: number) => {
      const lastNoteColorIndex = noteColorIndex;
      setNoteColorIdex(selectedNoteColorIndex);
      var aggDataUpdateForBackend;
      aggDataUpdateForBackend = {
        id: aggData.id,
        note: {
          noteColor: selectedNoteColorIndex,
        },
      };

      updateAggData({
        variables: { input: aggDataUpdateForBackend },
        refetchQueries: undefined,
        update(cache, { data: { updateAggdata } }) {
          if (updateAggdata) {
            updateAggDataInCache(cache, updateAggdata.aggData);
          }
        },
      }).catch((e) => {
        setNoteColorIdex(lastNoteColorIndex);
        dispatch(
          showSnackbar({
            message: t("snackbar_failed_apply_changes_backend"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
    },
    []
  );

  const onFileInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const filesList = e.target.files;
      if (!filesList || !filesList[0]) return;
      const filesCount = filesList.length;
      const noteImagesInputArray: any = [];
      if (filesCount > 0) {
        setDisplayNoteImagesLoading(true);
      }
      for (let i = 0; i < filesCount; i++) {
        const fileObj = filesList[i];
        new Compressor(fileObj, {
          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            var noteImageInput = {
              image: compressedResult,
            };
            noteImagesInputArray.push(noteImageInput);
            if (noteImagesInputArray.length == filesCount) {
              uploadNoteImages({
                variables: {
                  aggdataId: aggData.id,
                  imagesList: noteImagesInputArray,
                },
              })
                .then((result) => {
                  // if (result.data.uploadNoteImages)
                  setDisplayNoteImagesLoading(false);
                })
                .catch((e) => {
                  setDisplayNoteImagesLoading(false);
                  dispatch(
                    showSnackbar({
                      message: t("network_error_msg"),
                      action: "",
                      onAction: null,
                      loading: false,
                    })
                  );
                });
            }
          },
        });
      }
    },
    [aggData]
  );

  const handleAddPicture = React.useCallback(() => {
    if (isMaxDataUsageReached(currentUser)) {
      dispatch(displayMaxDataUsageReachedDialog(true));
      return;
    }
    hiddenFileInput?.current?.click();
  }, [hiddenFileInput.current]);

  const getReadOnlyModeLabelComponent = React.useCallback(() => {
    return (
      <>
        {currentUserRight != UserAccessRight.READER_ACCESS_RIGHT ? (
          <>
            <Tooltip
              title={styledTooltipTitle(t("note_readonly_mode_hint"))}
              className={classes.readOnlyContainer}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
                onClick={() => {
                  history.push(PRICING);
                }}
              >
                <BoltIcon />
                <Typography sx={{ textAlign: "center" }}>Upgrade</Typography>
              </div>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip
              arrow
              title={styledTooltipTitle(
                t("observer_badge_tooltip_content_note")
              )}
            >
              <Typography
                variant="h5"
                style={{
                  borderRadius: "4px",
                  backgroundColor: colorMementoBeige,
                  fontSize: "1.2rem",
                  padding: "6px",
                  marginLeft: "24px",
                }}
              >
                {t("shared_user_observer")}
              </Typography>
            </Tooltip>
          </>
        )}
      </>
    );
  }, [currentUserRight]);

  return (
    <>
      <OpaqueOverlay onClick={handleClickOutside} />

      <NoteEditorContainer
        bgColor={getNoteColorFromColorIndex(noteColorIndex, false)}
        isFullScreen={isFullScreen}
        ref={ref}
      >
        {displayNoteImagesLoading && (
          <Box
            sx={{
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: 30,
              width: "100%",
              paddingRight: "4dp",
              paddingLeft: "4dp",
            }}
          >
            <LinearProgress />
          </Box>
        )}

        <Input
          name="noteTitle"
          placeholder="Title"
          autoComplete="off"
          isEditable
          readOnly={readOnlyMode}
          ref={titleInputRef}
          defaultValue={aggData?.note?.noteTitle!!}
          onBlur={saveEditorTitleContent}
        />
        {aggData.note?.noteimageSet && aggData.note.noteimageSet.length > 0 && (
          <NoteEditorImagesPreviewer
            noteImages={aggData.note?.noteimageSet}
            aggData={aggData}
          />
        )}

        <div style={{ height: "100%" }}>
          {noteTasks.length > 0 && (
            <NoteTasksList
              onNoteTasksChange={handleNoteTasksChanged}
              noteTasksArray={noteTasks}
              readOnly={readOnlyMode}
            />
          )}
          <Editor
            wrapperClassName="wrapper-editor-cls"
            editorClassName="editor-cls"
            editorState={editorState}
            readOnly={readOnlyMode}
            onEditorStateChange={setEditorState}
            onBlur={handleEditorBlurEvent}
            toolbar={{
              options: readOnlyMode ? [] : ["inline", "colorPicker", "history"],
              inline: {
                options: readOnlyMode
                  ? []
                  : ["bold", "italic", "underline", "strikethrough"],
              },
              colorPicker: {
                colors: [
                  "rgb(244, 66, 54)",
                  "rgb(238, 154, 154)",
                  "rgb(76, 176, 80)",
                  "rgb(165, 214, 167)",
                  "rgb(254, 193, 7)",
                  "rgb(255, 224, 131)",
                  "rgb(32, 151, 241)",
                  "rgb(144, 202, 248)",
                ],
              },
            }}
            toolbarCustomButtons={
              isFullScreen
                ? [
                    <>
                      {readOnlyMode && getReadOnlyModeLabelComponent()}
                      <CloseFullscreenRoundedIcon
                        className={classes.expandIcon}
                        htmlColor={colorGreyMemento}
                        style={{ ...expandIconStyle }}
                        onClick={() => {
                          setFullScreen(!isFullScreen);
                        }}
                      />
                    </>,
                  ]
                : [
                    <>
                      {readOnlyMode && getReadOnlyModeLabelComponent()}
                      <OpenInFullRoundedIcon
                        className={classes.expandIcon}
                        htmlColor={colorGreyMemento}
                        style={{ ...expandIconStyle }}
                        onClick={() => {
                          setFullScreen(!isFullScreen);
                        }}
                      />
                    </>,
                  ]
            }
          ></Editor>
          <ToolbarContainer>
            {!readOnlyMode && (
              <NoteEditorToolbar
                id="5"
                onHover={dummyFunc}
                selectedColor={aggData.note?.noteColor}
                setShowLabel={dummyFunc}
                onDelete={dummyFunc}
                onAddToCollection={handleToolbarAddItemToCollectionAction}
                isChecked={false}
                onClose={dummyFunc}
                isDataArchived={aggData.isArchived}
                aggDataObj={aggData}
                onArchive={handleToolbarArchiveAction}
                onPinUnpin={handlePinUnpinAction}
                onAddCheckboxes={handleToolbarAddCheckboxes}
                onColorSelected={handleColorSelected}
                onAddPicture={handleAddPicture}
              />
            )}
            {/* {showLabel && (
            <Label
              note={note}
              setShowLabel={setShowLabel}
              onExpand={setIsClickOutside}
              isEditableNote={clicked}
            />
          )} */}
          </ToolbarContainer>
          <input
            ref={hiddenFileInput}
            accept="image/*"
            onChange={onFileInputChange}
            type="file"
            multiple="multiple"
            style={{ display: "none" }}
          />
        </div>
      </NoteEditorContainer>
    </>
  );
});

export default React.memo(NoteEditor);

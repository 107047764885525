import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LoginIllustration from "illustrations/undraw_login_illustration.svg";
import { useHistory } from "react-router-dom";
import { LOGIN } from "navigation/Constants";

interface LoginModalProps {
  open: boolean;
  description: string;
  handleModalClose: (close: boolean) => void;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("mobile")]: {
        width: "80%",
      },
    },
    addButton: {
      marginEnd: "12px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "24px",
    },
  })
);

export default function LoginModal({
  open,
  description,
  handleModalClose,
}: LoginModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Title>{t("login_action")}</Title>
            <img
              src={LoginIllustration}
              style={{
                width: "70%",
                height: "auto",
              }}
            ></img>
            <Typography
              variant="h5"
              align="center"
              sx={{
                marginTop: "16px",
              }}
            >
              {description}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.addButton}
                color="primary"
                onClick={() => {
                  history.push(LOGIN);
                  handleModalClose(false);
                }}
              >
                <Typography variant="h6">{t("login_action")}</Typography>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

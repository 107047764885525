import {
  Box,
  Fade,
  ListItemButton,
  Modal,
  Typography,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import {
  retrieveTopLevelCollectionsGql,
  retrieveTopLevelCollectionsOwnedByMeGql,
} from "graphql/queries";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UserObj } from "models/user";
import { RootState } from "store/reducers";
import { colorMemento } from "shared/colors";
import { useHistory } from "react-router-dom";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { retrieveChildCollectionsForParentGql } from "graphql/queries";
import { CollectionObj } from "models/collection";
import ImagePreview from "components/CollectionItems/ImagePreview";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { moveCollectionToCollectionGql } from "graphql/mutations";
import { showSnackbar } from "store/actions/view";
import {
  deleteCollectionFromTopLevelCollectionsCache,
  updateTopLevelCollectionsCache,
} from "graphql/helpers";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import SelectCollectionFromHierarchyView from "./SelectCollectionFromHierarchyView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      position: "relative",
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      height: "80%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "16px",
      overflow: "hidden",
      "& .MuiInput-root": {
        height: "70%",
      },
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
    annotationTextField: {
      fontSize: "1.9rem",
    },

    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      padding: "24px",
    },
    applyButton: {
      marginEnd: "12px",
      marginStart: "12px",
      alignItems: "center",
    },
  })
);

interface MoveCollectionModalProps {
  open: boolean;
  collection: CollectionObj;
  handleModalClose: (open: boolean) => void;
}

const MoveCollectionModal = ({
  open,
  collection,
  handleModalClose,
}: MoveCollectionModalProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const { loading, error, data } = useQuery(
    retrieveTopLevelCollectionsOwnedByMeGql
  );
  const client = useApolloClient();

  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );

  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  const [
    moveCollectionToCollection,
    moveCollectionToCollectionResult,
  ] = useMutation(moveCollectionToCollectionGql);

  const [moveTopLevelSelected, setMoveTopLevelSelected] = React.useState(false);
  const [
    selectedCollection,
    setSelectedCollection,
  ] = React.useState<CollectionObj | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleMoveCollectionToCollection = useCallback(() => {
    if (!selectedCollection && !moveTopLevelSelected) {
      return;
    }
    moveCollectionToCollection({
      variables: {
        collectionId: collection.id,
        destinationCollectionId: moveTopLevelSelected
          ? null
          : selectedCollection.id,
      },
      refetchQueries: currentlyOpenedCollection
        ? [
            {
              query: retrieveChildCollectionsForParentGql,
              variables: {
                parentId: currentlyOpenedCollection.id,
              },
            },
          ]
        : undefined,
    })
      .then((result) => {
        if (
          result.data &&
          result.data.moveCollectionToCollection &&
          result.data.moveCollectionToCollection.result
        ) {
          if (moveTopLevelSelected) {
            dispatch(
              showSnackbar({
                message: t("collection_moved_top_level_success_msg"),
                action: "",
                onAction: null,
              })
            );
            updateTopLevelCollectionsCache(
              client.cache,
              retrieveTopLevelCollectionsGql,
              collection
            );
          } else if (selectedCollection) {
            dispatch(
              showSnackbar({
                message: t("collection_moved_success_msg", {
                  collection_name: collection.name,
                  destination_collection: selectedCollection.name,
                }),
                action: "",
                onAction: null,
              })
            );
            deleteCollectionFromTopLevelCollectionsCache(
              client.cache,
              collection.id
            );
          }
          handleModalClose(false);
        }
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("generic_failure_msg"),
            action: "",
            onAction: null,
          })
        );
      });
  }, [
    moveCollectionToCollection,
    currentlyOpenedCollection,
    collection,
    selectedCollection,
    moveTopLevelSelected,
  ]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        onClick={(e) => {
          e.stopPropagation(); //useful because for some reason when you click outside to close the modal the click still propagates to components underneath
        }}
      >
        <Fade in={open}>
          <Box
            className={classes.paper}
            sx={{
              display: "flex",
              flexDirection: "column",              
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              justifyContent: "spread-between",
              "&::-webkit-scrollbar": {
                width: 3,
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px #faf6e9`,
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#bdbdbd",
                borderRadius: "4px",
              },
              overflow: "auto",
              
              "@media (max-width: 780px)": {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                height: "100%",
              },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: 400,
                padding: "8px",
                marginTop: "16px",
                marginBottom: "16px",
                marginLeft: "32px",
                fontSize: "2rem",
              }}
            >
              {t("move_collection_title", {
                collection_name: collection.name,
              })}
            </Typography>
            <SelectCollectionFromHierarchyView
              collection={collection}
              handleClose={() => {
                handleModalClose(false);
              }}
              onCollectionSelected={(collection: CollectionObj | null) => {
                setSelectedCollection(collection);
              }}
              onTopLevelOptionSelected={(selected: boolean) => {
                setMoveTopLevelSelected(selected);
              }}
            />
            <div className={classes.buttonContainer}>
              <LoadingButton
                className={classes.applyButton}
                color="primary"
                variant="contained"
                loading={moveCollectionToCollectionResult.loading}
                disabled={!selectedCollection && !moveTopLevelSelected}
                onClick={() => {
                  handleMoveCollectionToCollection();
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "white",
                    paddingLeft: "4px",
                    paddingTop: "4px",
                  }}
                >
                  {t("move_action")}
                </Typography>
              </LoadingButton>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(MoveCollectionModal);

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { clearEditableData } from "store/actions/aggdataActions";
import CreateNewNotePage from "pages/CreateNewNotePage";
import MainRouterConfig from "navigation/MainRouterConfig";
import { Theme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { colorMemento } from "shared/colors";
import { scrollBarStyle } from "shared/styles";
import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useApolloClient } from "@apollo/client";
import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  ApolloProvider,
  ApolloLink,
  useQuery,
  gql,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { SERVER_URL } from "configuration";
import Cookies from "js-cookie";
import { resetStore } from "store/actions/view";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_MESUREMENT_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_API_KEY,
} from "configuration";

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MESUREMENT_ID,
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;

`;
//

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: 12,
    },
    "*::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      borderRadius: "8px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#bdbdbd",
      borderRadius: "8px",
    },
  },
  container: {
    ...scrollBarStyle,
  },
}));

export const OpaqueOverlay = styled.div`
  position: fixed;
  display: absolute;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  z-index: 799;
`;

const App = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
  const classes = useStyles();
  const prefersDarkMode = false; //useMediaQuery("(prefers-color-scheme: dark)");

  const uri = SERVER_URL + "/graphql";
  const uploadLink = createUploadLink({
    uri: uri,
    credentials: "include",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
      // Authorization:
      //   "Token 3d9e16e9bd49539aefb2648535e3529ce9920293b372c7c6d7d8a4d2e0a67dbc",
    },
  });
  const publicLink = createUploadLink({
    uri: SERVER_URL + "/pgraphql",
    credentials: "include",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
      // Authorization:
      //   "Token 3d9e16e9bd49539aefb2648535e3529ce9920293b372c7c6d7d8a4d2e0a67dbc",
    },
  });
  const client = new ApolloClient({
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === "public",
      publicLink, //if above
      uploadLink
    ),
    cache: new InMemoryCache({
      dataIdFromObject(object: any) {
        if (object.__typename === "UserType") {
          return object.id;
        }
        return defaultDataIdFromObject(object);
      },
      addTypename: true,
      typePolicies: {
        Query: {
          fields: {
            aggdataCommentsPaged: {
              //The keyArgs is what apollo cache relies on to create separate caches
              //Here since this is the result of a paginated query, we segregate the cache by dataId, and we don't care about the other keyargs (page and pagesize)
              keyArgs: ["dataId"],
              merge(existing = [], incoming) {
                // console.log("cachemerge: merging existing " + existing.length + " into incoming " + incoming.length )
                return [...existing, ...incoming];
              },
            },
            commentsForCollectionPaged: {
              //The keyArgs is what apollo cache relies on to create separate caches
              //Here since this is the result of a paginated query, we segregate the cache by dataId, and we don't care about the other keyargs (page and pagesize)
              keyArgs: ["collectionId"],
              merge(existing = [], incoming) {
                console.log(
                  "cachemerge: merging existing " +
                    existing.length +
                    " into incoming " +
                    incoming.length
                );
                // const incomingFormatted = incoming.map((commentNotification) => {
                //   return {
                //     user: commentNotification.user,
                //     content: commentNotification.content,
                //     created: commentNotification.created,
                //     dataId: commentNotification.aggdata.id,
                //     previewUrl: commentNotification.aggdata.previewUrl,
                //   };
                // });
                return [...existing, ...incoming];
              },
            },
            // allAggdata: {

            //   keyArgs: false,
            //   merge(existing, incoming, { args }) {
            //     const merged = existing ? existing.slice(0) : [];
            //     if (args) {
            //       // Assume an offset of 0 if args.offset omitted.
            //       const { offset = 0 } = args;
            //       for (let i = 0; i < incoming.length; ++i) {
            //         merged[offset + i] = incoming[i];
            //       }
            //     } else {
            //       // It's unusual (probably a mistake) for a paginated field not
            //       // to receive any arguments, so you might prefer to throw an
            //       // exception here, instead of recovering by appending incoming
            //       // onto the existing array.
            //       merged.push.apply(merged, incoming);
            //     }
            //     return merged;
            //   },

            // read(existing, { args: { offset, limit } }) {
            //   // Implement here
            //   return existing && existing.slice(offset, offset + limit);
            // }
            // },
            collection: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "CollectionType",
                  id: args.id,
                });
              },
            },
            // updateCollection: {
            //   //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
            //   read(_, { args, toReference }) {
            //     return toReference({
            //       __typename: "CollectionType",
            //       id: args.id,
            //     });
            //   },
            // },
        /*     updatePublicUserProfile: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "UserPublicProfileType",
                  id: args.id,
                });
              },
            }, */
           /*  publicProfile: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                console.log("args are " +JSON.stringify(args))
                return toReference({
                  __typename: "UserPublicProfileType",
                });
              },
            }, */
            aggdata: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "AggDataType",
                  id: args.id,
                });
              },
            },
            createAggdata: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              merge(existing = [], incoming) {
                console.log(
                  "Will merge " +
                    JSON.stringify(incoming) +
                    " into " +
                    JSON.stringify(existing)
                );
                return [...existing, ...incoming];
              },
            },
            aggdatas: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "AggDataType",
                  id: args.id,
                });
              },
            },
            updateAggdata: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "AggDataType",
                  id: args.id,
                });
              },
            },
            deleteAggdata: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "AggDataType",
                  id: args.id,
                });
              },
            },
            // createMultipleAggdata: {
            //   //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
            //   read(_, { args, toReference }) {
            //     return toReference({
            //       __typename: "AggDataType",
            //       id: args.id,
            //     });
            //   },
            //  },
            // createAggdata: {
            //   //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
            //   read(_, { args, toReference }) {
            //     return toReference({
            //       __typename: "AggDataType",
            //       id: args.id,
            //     });
            //   },
            // // },
            // aggDatas: {
            //   //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
            //   read(_, { args, toReference }) {
            //     return toReference({
            //       __typename: "AggDataType",
            //       id: args.id,
            //     });
            //   },
            // },
            // aggDataForCollection: {
            //   //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
            //   read(_, { args, toReference }) {
            //     return toReference({
            //       __typename: "AggDataType",
            //       id: args.id,
            //     });
            //   },
            // },
            note: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "NoteType",
                  id: args.id,
                });
              },
            },
            owner: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "UserType",
                  id: args.id,
                });
              },
            },
            user: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "UserType",
                  id: args.id,
                });
              },
            },
            sharedUser: {
              //this is a cache redirection. More info https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "UserSharedItemAccessType",
                  id: args.id,
                });
              },
            },
          },
        },
      },
    }),
  });

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#E33E7F",
      },
      primary: {
        main: colorMemento,
      },
      type: prefersDarkMode ? "dark" : "light",
    },
    components: {
      MuiChip: {
        defaultProps: {
          sx: {
            fontSize: "1.2rem",
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0, // removes the `xs` breakpoint
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        mobile: 780, // Adding a new breakpoint for mobile devices
      },
    },
    // typography: {
    //   // In Chinese and Japanese the characters are usually larger,
    //   // so a smaller fontsize may be appropriate.
    //   fontSize: '3rem',
    // },

    // button: {
    //   fontSize: '4rem',
    // },
    // MuiButton: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       // Some CSS
    //       fontSize: '4rem',
    //     },
    //   },
    // },
  });

  /**
   * Following a login we clear the data cache, otherwise it might be read at next login by some other account.
   */
  useEffect(() => {
    if (!isUserLoggedIn) {
      client.clearStore();
      dispatch(resetStore());
    } else {
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    }
  }, [isUserLoggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <AppContainer className={classes.container}>
          <MainContainer>
            <MainRouterConfig />
          </MainContainer>
        </AppContainer>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Button from "@mui/material/Button";
import ImagePreview from "components/CollectionItems/ImagePreview";
import {
  queryCollectionInviteInformationGql,
  acceptCollectionInviteGql,
} from "graphql/mutations";
import Skeleton from "@mui/material/Skeleton";
import { getCollectionRoute } from "navigation/Constants";
import InvitationLinkExpiredIllustration from "illustrations/undraw_page_not_found.svg";
import {
  setHideAddNewItemFabButton,
  setHideMainSearchBar,
} from "store/actions/view";
import CircularProgress from "@mui/material/CircularProgress";

const MainContainer = styled.div`
  display: flex;
  margin-top: 56px;
  width: 100%;
  height: 60vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const SpaceBetweenButtons = styled.div`
  display: flex;
  width: 5%;
  justify-content: center;
`;

interface CollectionInvitationProps {
  collectionId: string;
  inviteToken: string;
}

const CollectionInvitation = ({
  collectionId,
  inviteToken,
}: CollectionInvitationProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [queryInviteInformation, queryInviteInformationResult] = useMutation(
    queryCollectionInviteInformationGql,
    {
      variables: {
        collectionUuid: collectionId,
        accessToken: inviteToken,
      },
    }
  );
  const [acceptCollectionInvite, acceptCollectionInviteResult] = useMutation(
    acceptCollectionInviteGql,
    {
      variables: {
        collectionUuid: collectionId,
        accessToken: inviteToken,
      },
    }
  );

  React.useEffect(() => {
    dispatch(setHideAddNewItemFabButton(true));
    dispatch(setHideMainSearchBar(true));
    return () => {
      dispatch(setHideAddNewItemFabButton(false));
      dispatch(setHideMainSearchBar(false));
    };
  }, []);

  React.useEffect(() => {
    queryInviteInformation()
      .then((data) => {
        if (data.data.querySharedCollectionInfo.alreadyAccepted) {
          history.replace(getCollectionRoute(collectionId));
        }
      })
      .catch((e) => {
        //handled in queryInviteInformationResult.error
      });
  }, []);

  const handleCancelButtonClicked = React.useCallback(() => {
    history.push("/");
  }, []);

  const handleAcceptInvite = React.useCallback(() => {
    acceptCollectionInvite().then((data) => {
      history.replace(getCollectionRoute(collectionId));
    }).catch((e) => {

    });
  }, []);

  if (queryInviteInformationResult.error) {
    return (
      <MainContainer>
        <img
          style={{ marginTop: "60px", height: "300px", cursor: "initial" }}
          src={InvitationLinkExpiredIllustration}
        />
        <Typography
          style={{ marginLeft: "12px", color: "black", marginTop: "36px" }}
          variant="h4"
        >
          {t("collection_invitation_link_expired_msg")}
        </Typography>
      </MainContainer>
    );
  }

  if (
    queryInviteInformationResult.loading ||
    !queryInviteInformationResult.data
  ) {
    return <LoadingView />;
  }
  console.log("Graphql query result %o", queryInviteInformationResult);

  return (
    <MainContainer>
      <Typography variant="h4" sx={{ fontSize: "3rem", marginBottom: "3%" }}>
        {
          queryInviteInformationResult.data.querySharedCollectionInfo
            .inviterFirstName
        }{" "}
        <span style={{ color: "gray" }}>
          {" "}
          (
          {
            queryInviteInformationResult.data.querySharedCollectionInfo
              .inviterEmail
          }
          )
        </span>{" "}
        {t('collection_invitation_messsage')}
        <b>
          {
            queryInviteInformationResult.data.querySharedCollectionInfo
              .collectionName
          }
        </b>
      </Typography>
      <ImagePreview
        previewUrls={
          queryInviteInformationResult.data.querySharedCollectionInfo
            .previewUrls
        }
        height="248px"
        width="248px"
        imgSpacing="0px"
      ></ImagePreview>
      <ButtonsContainer>
        <Button
          onClick={handleCancelButtonClicked}
          sx={{ width: "15%", fontSize: "2rem" }}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <SpaceBetweenButtons></SpaceBetweenButtons>
        <Button
          onClick={handleAcceptInvite}
          sx={{ width: "15%", fontSize: "2rem" }}
          variant="contained"
        >
          {acceptCollectionInviteResult.loading ? (
            <CircularProgress sx={{ color: "white" }} />
          ) : (
            <>{t("accept_action")}</>
          )}
        </Button>
      </ButtonsContainer>
    </MainContainer>
  );
};

const LoadingView = () => {
  return (
    <MainContainer>
      <Typography variant="h4" sx={{ fontSize: "3rem", marginBottom: "3%" }}>
        <Skeleton variant="text" height={60} width={600} />
      </Typography>
      <Skeleton variant="rectangular" height={300} width={300} />
      <ButtonsContainer>
        <Skeleton variant="text" height={40} width={200} />
        <SpaceBetweenButtons></SpaceBetweenButtons>
        <Skeleton variant="text" height={40} width={200} />
      </ButtonsContainer>
    </MainContainer>
  );
};

export default React.memo(CollectionInvitation);

import React, { useCallback } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import SpeedDial, { SpeedDialProps } from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { FabProps } from "@mui/material/Fab";
import AddIcon from "@material-ui/icons/Add"; //For some weird reason transitions only work on icon from @material-ui and don't from @mui...
import CreateIcon from "@mui/icons-material/Create";
import ImageIcon from "@mui/icons-material/Image";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import GestureIcon from "@mui/icons-material/Gesture";
import { setEditableAggdataNote } from "store/actions/aggdataActions";
import {
  showSnackbar,
  addFiles as addFilesAction,
  displayMaxDataUsageReachedDialog,
} from "store/actions/view";
import { useTranslation } from "react-i18next";
import {
  addNewDataWithImageGql,
  addMultipleNewDataWithImageGql,
  uploadFileGql,
} from "graphql/mutations";
import { useMutation } from "@apollo/client";
import Compressor from "compressorjs";
import AddNewLinkModal from "components/UI/Modals/AddNewLinkModal";
import AddNewCollectionModal from "components/UI/Modals/AddNewCollectionModal";
import {
  addAggDataArrayInCache,
  addAggDataListInCollectionSpecificCache,
} from "graphql/helpers";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import { CollectionObj } from "models/collection";
import {
  retrieveCollectionGql,
  MAX_DATA_USAGE_REACHED_ERROR,
} from "graphql/queries";
import { DataType, DataSubtype } from "models/aggdata";
import {
  setMaxSortOrderScore,
  setMaxSortOrderScoreCurrentCollection,
} from "store/actions/aggdataActions";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Fade from "@mui/material/Fade";
import { colorMementoBeige } from "shared/colors";
import {
  formatToContextualDate,
  getUsageInHumanReadableFormat,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import { DEBUG, SINGLE_DATA_MAX_ALLOWED_SIZE_FREE_TIER } from "configuration";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CircularProgress from "@mui/material/CircularProgress";
import { colorGreyMemento } from "shared/colors";
import { MAX_ALLOWED_BULK_FILES_IMPORT } from "configuration";
import { UserObj } from "models/user";
import { useHistory } from "react-router-dom";
import { PRICING } from "navigation/Constants";
import { isMaxDataUsageReached } from "models/user";
import { isAggdataShared, AggDataObj } from "models/aggdata";

const addIconDims = "40px";
const actionIconDims = "35px";

const ADD_LINK_ACTION = "add_link";
const ADD_IMAGE_ACTION = "add_image";
const DRAW_ACTION = "draw";
const NEW_NOTE_ACTION = "new_note";
const NEW_COLLECTION_ACTION = "new_collection";

export default function AddNewItemMenu() {
  const [rotation, setRotation] = React.useState("0deg");
  const [width, setWidth] = React.useState("40px");
  const [openLinkModal, setOpenLinkModal] = React.useState(false);
  const [openNewCollectionModal, setOpenNewCollectionModal] = React.useState(
    false
  );
  const { t } = useTranslation();
  const history = useHistory();

  const [direction, setDirection] = React.useState<SpeedDialProps["direction"]>(
    "right"
  );
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const dispatch = useDispatch();
  const isCollectionsUiDisplayed: boolean = useSelector(
    (state: RootState) => state.view.isCollectionUIDisplayed
  );
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );
  const displayTimePickerState = useSelector(
    (state: RootState) => state.view.displayTimepickerOnAddingNewItems
  );

  const addFilesFromDragAndDrop = useSelector(
    (state: RootState) => state.view.addFilesFromDragAndDrop
  );

  const maxSortOrderScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxSortOrderScore
  );

  const maxSortOrderScoreForCurrentCollection: number = useSelector(
    (state: RootState) =>
      state.aggdataReducer.maxSortOrderScoreInCurrentCollection
  );

  const timePickerHoveredRef = React.useRef(false);
  const isTimePickerOpenedRef = React.useRef(false);
  const [fileUploadIsLoading, setFileUploadIsLoading] = React.useState(false);
  const userObj: UserObj = useSelector((state: RootState) => state.auth.user);

  const reminderToAddRef = React.useRef();

  const [addMultipleAggDataWithFiles, { loading, data }] = useMutation(
    addMultipleNewDataWithImageGql,
    {
      update(cache, { data: { createMultipleAggdata } }) {
        // if (createMultipleAggdata.aggDatas && createMultipleAggdata.aggDatas.length > 0){
        //     const aggData = createMultipleAggdata.aggDatas[0]
        //     if (aggData.collection.sharedUsers.length > 0 && currentlyOpenedCollection.id == aggData.collection.id){
        //       //We assume here that the user is still on the same collection UI they added items to, so add the items to the current shared collection cache

        //     }
        // }
        const sharedAggDataList: AggDataObj[] = [];
        if (createMultipleAggdata.aggDatas) {
          createMultipleAggdata.aggDatas.map((aggdata: AggDataObj) => {
            if (isAggdataShared(aggdata)) {
              sharedAggDataList.push(aggdata);
            }
          });
          //Normally all aggdata here are added to the same collection .. so we read the collection id on the first item
          if (sharedAggDataList.length > 0) {
            addAggDataListInCollectionSpecificCache(
              cache,
              sharedAggDataList,
              sharedAggDataList[0].collection.id
            );
          }
        }
        addAggDataArrayInCache(cache, createMultipleAggdata.aggDatas);
      },
      //We refetch the collection in order to update the changes applied by backend, notably items count and preview urls.
      refetchQueries: [
        {
          query: currentlyOpenedCollection ? retrieveCollectionGql : "", // if no collection, avoid trying a useless query
          variables: {
            id: currentlyOpenedCollection ? currentlyOpenedCollection.id : null,
          },
        },
      ],
    }
  );

  const getTimePickerInitialValue = React.useCallback(() => {
    const newDate = new Date(displayTimePickerState.selectedDayDate);
    newDate.setHours(10, 0, 0);
    return newDate;
  }, [displayTimePickerState.selectedDayDate]);

  const [timePickerValue, setTimePickerValue] = React.useState(
    getTimePickerInitialValue()
  );

  const firstRender = React.useRef(true);

  //When new files are being drag and dropped on Memento, we add them to the backend
  React.useEffect(() => {
    //When this components is first rendered, we clear all pending add files queries coming from other components if any.
    if (firstRender.current) {
      dispatch(addFilesAction(null));
      firstRender.current = false;
    } else if (addFilesFromDragAndDrop) {
      if (DEBUG) {
        console.log("Add list of files %o", addFilesFromDragAndDrop);
      }
      addFiles(addFilesFromDragAndDrop);
    }
    return () => {
      dispatch(addFilesAction(null));
    };
  }, [addFilesFromDragAndDrop]);

  React.useEffect(() => {
    setTimePickerValue(getTimePickerInitialValue());
  }, [getTimePickerInitialValue]);

  const useStyles = useCallback(
    makeStyles((theme: Theme) =>
      createStyles({
        root: {
          //      transform: 'translateZ(0px)',
          flexGrow: 1,
          zIndex: 1000,
        },
        fab: {
          width: "300px",
          height: "300px",
          padding: 0,
          [theme.breakpoints.down("sm")]: {
            width: "60px",
            height: "60px",
          },
        },
        icons: {
          width: addIconDims,
          height: addIconDims,
          //transform: "rotate(90)",
          transform: `rotate(${rotation})`,
          transition: theme.transitions.create(["transform"], {
            easing: theme.transitions.easing.sharp,
          }),
          [theme.breakpoints.down("sm")]: {
            width: "30px",
            height: "30px",
          },
        },
        speedDial: {
          "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
            flexDirection: "column",
          },
          "& .MuiSpeedDial-actions": {
            // marginTop: '60px',
            top: "12px",
            paddingTop: "60px",
            position: "fixed",
          },
          "& .MuiSpeedDialAction-fab": {
            //Speed dial actions fabs styling
            height: "60px",
            width: "60px",
          },
          [theme.breakpoints.down("sm")]: {},
        },
        speedDialAction: {
          width: "60px",
          height: "60px",
          [theme.breakpoints.down("sm")]: {
            width: "30px",
            height: "30px",
          },

          "& .MuiFab-root": {
            height: "70px",
            width: "70px",
          },
        },
        speedDialActionIcon: {
          "& .MuiSvgIcon-root": {
            width: "30px",
            height: "30px",
            [theme.breakpoints.down("sm")]: {
              width: "20px",
              height: "20px",
            },
          },
        },
        modal: {
          borderRadius: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        paper: {
          borderRadius: "12px",
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },
        margin: {
          margin: theme.spacing(1),
          width: "400px",
          fontSize: "8rem",
        },
        inputLabel: {
          fontSize: "1.9rem",
          padding: "4px",
        },
        title: {
          fontSize: "2.4rem",
        },
      })
    ),
    [rotation, width]
  );
  const classes = useStyles();

  const handleClose = () => {
    if (displayTimePickerState.displayTimePicker) {
      setTimeout(() => {
        //This is a "hack", to be able to display timepicker when the cursor move away from dialactions to the timepicker menu div
        if (!timePickerHoveredRef.current && !isTimePickerOpenedRef.current) {
          setOpen(false);
          setRotation("0deg");
        }
      }, 200);
    } else {
      setOpen(false);
      setRotation("0deg");
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setRotation("45deg");
  };

  const fabProps: Partial<FabProps> = {
    className: classes.fab,
  };

  const actions = [
    {
      icon: (
        <CreateIcon fontSize="large" className={classes.speedDialActionIcon} />
      ),
      name: t("action_new_note_title"),
      action: NEW_NOTE_ACTION,
    },
    {
      icon: (
        <InsertLinkIcon
          fontSize="large"
          className={classes.speedDialActionIcon}
        />
      ),
      name: t("action_add_link_title"),
      action: ADD_LINK_ACTION,
    },
    //For now not supported
    // {
    //   icon: <GestureIcon className={classes.speedDialActionIcon} />,
    //   name: t("action_draw_title"),
    //   action: DRAW_ACTION,
    // },
    {
      icon: (
        <FileUploadIcon
          fontSize="large"
          className={classes.speedDialActionIcon}
        />
      ),
      name: t("action_import_title"),
      action: ADD_IMAGE_ACTION,
    },
    {
      icon: (
        <DashboardCustomizeIcon
          fontSize="large"
          className={classes.speedDialActionIcon}
        />
      ),
      name: t("action_add_new_collection"),
      action: NEW_COLLECTION_ACTION,
    },
  ];

  const collectionsActions = [
    {
      icon: (
        <DashboardCustomizeIcon
          fontSize="large"
          className={classes.speedDialActionIcon}
        />
      ),
      name: t("action_add_new_collection"),
      action: NEW_COLLECTION_ACTION,
    },
  ];

  const handleAddNewItem = (action: string): void => {
    //If max usage is reached we display the dialog to incite the user to upgrade their plan.
    if (isMaxDataUsageReached(userObj)) {
      dispatch(displayMaxDataUsageReachedDialog(true));
      return;
    }
    switch (action) {
      case NEW_NOTE_ACTION:
        //dispatch(getEditableData({}));
        if (displayTimePickerState.displayTimePicker) {
          const reminder = [
            { cycle: 0, triggerTime: timePickerValue.getTime() / 1000 },
          ];
          const reminderAsString = JSON.stringify(reminder);
          dispatch(setEditableAggdataNote({ reminders: reminderAsString }));
        } else {
          dispatch(setEditableAggdataNote({}));
        }

        break;
      case ADD_IMAGE_ACTION:
        hiddenFileInput?.current?.click();
        break;
      case ADD_LINK_ACTION:
        if (displayTimePickerState.displayTimePicker) {
          const reminder = [
            { cycle: 0, triggerTime: timePickerValue.getTime() / 1000 },
          ];
          reminderToAddRef.current = JSON.stringify(reminder);
        }
        setOpenLinkModal(true);
        break;
      case NEW_COLLECTION_ACTION:
        setOpenNewCollectionModal(true);
        break;
      default:
        if (DEBUG) {
          console.log("not supported");
        }
    }
    handleClose();
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  //const compressedFilesArray = React.useRef<[]>([])

  const addFiles = React.useCallback(
    (files: FileList | null) => {
      if (DEBUG) console.log("Start adding files ");
      const aggDataInputArray = [];
      if (!files || !files[0]) return;
      const filesCount =
        files.length > MAX_ALLOWED_BULK_FILES_IMPORT
          ? MAX_ALLOWED_BULK_FILES_IMPORT
          : files.length;
      if (filesCount == 0) {
        return;
      }

      var maxSortOrderScoreToApply = maxSortOrderScore;
      if (
        maxSortOrderScoreForCurrentCollection &&
        currentlyOpenedCollection &&
        currentlyOpenedCollection.sharedUsers.length > 0
      ) {
        maxSortOrderScoreToApply = maxSortOrderScoreForCurrentCollection;
      }

      var unsupportedFileFormatProvided = false;
      var singleDataMaxSizeDetected = false;
      const imagesFilesArray = [];

      setFileUploadIsLoading(true);
      for (let i = 0; i < filesCount; i++) {
        const fileObj = files[i];
        if (DEBUG) {
          console.log(
            "Adding   new file " +
              JSON.stringify(fileObj.type) +
              " size " +
              JSON.stringify(fileObj.size)
          );
        }

        if (
          fileObj.size > SINGLE_DATA_MAX_ALLOWED_SIZE_FREE_TIER &&
          userObj &&
          userObj.subscriptionPlan.id == 0
        ) {
          singleDataMaxSizeDetected = true;
          continue;
        }
        if (
          fileObj.type.startsWith("image/jpeg") ||
          fileObj.type.startsWith("image/jpg") ||
          fileObj.type.startsWith("image/png")
        ) {
          imagesFilesArray.push(fileObj);
        } else if (fileObj.type.startsWith("audio/")) {
          setFileUploadIsLoading(true);
          var aggDataInput = {
            dataType: DataType.AUDIO_TYPE,
            file: {
              file: fileObj,
              fileName: fileObj.name,
            },
            sortOrderScore: maxSortOrderScoreToApply + 1,
            collection: currentlyOpenedCollection?.id,
          };
          if (displayTimePickerState.displayTimePicker) {
            const reminder = [
              { cycle: 0, triggerTime: timePickerValue.getTime() / 1000 },
            ];
            const reminderAsString = JSON.stringify(reminder);
            aggDataInput.reminders = reminderAsString;
          }
          if (DEBUG) console.log("pushing " + JSON.stringify(aggDataInput));
          aggDataInputArray.push(aggDataInput);
        } else if (fileObj.type.startsWith("video/")) {
          setFileUploadIsLoading(true);
          var aggDataInput = {
            dataType: DataType.VIDEO_TYPE,
            file: {
              file: fileObj,
              fileName: fileObj.name,
            },
            sortOrderScore: maxSortOrderScoreToApply + 1,
            collection: currentlyOpenedCollection?.id,
          };
          if (displayTimePickerState.displayTimePicker) {
            const reminder = [
              { cycle: 0, triggerTime: timePickerValue.getTime() / 1000 },
            ];
            const reminderAsString = JSON.stringify(reminder);
            aggDataInput.reminders = reminderAsString;
          }
          if (DEBUG) console.log("pushing " + JSON.stringify(aggDataInput));
          aggDataInputArray.push(aggDataInput);
        } else if (fileObj.type === "application/pdf") {
          setFileUploadIsLoading(true);
          var aggDataInput = {
            dataType: DataType.FILE_TYPE,
            dataSubtype: DataSubtype.PDF_FILE_SUBTYPE,
            file: {
              file: fileObj,
            },
            sortOrderScore: maxSortOrderScoreToApply + 1,
            collection: currentlyOpenedCollection?.id,
          };
          if (displayTimePickerState.displayTimePicker) {
            const reminder = [
              { cycle: 0, triggerTime: timePickerValue.getTime() / 1000 },
            ];
            const reminderAsString = JSON.stringify(reminder);
            aggDataInput.reminders = reminderAsString;
          }
          if (DEBUG) console.log("pushing " + JSON.stringify(aggDataInput));
          aggDataInputArray.push(aggDataInput);
        } else if (fileObj.type === "application/zip") {
          setFileUploadIsLoading(true);
          var aggDataInput = {
            dataType: DataType.FILE_TYPE,
            dataSubtype: DataSubtype.ZIP_FILE_SUBTYPE,
            file: {
              file: fileObj,
            },
            sortOrderScore: maxSortOrderScoreToApply + 1,
            collection: currentlyOpenedCollection?.id,
          };
          if (displayTimePickerState.displayTimePicker) {
            const reminder = [
              { cycle: 0, triggerTime: timePickerValue.getTime() / 1000 },
            ];
            const reminderAsString = JSON.stringify(reminder);
            aggDataInput.reminders = reminderAsString;
          }
          if (DEBUG) console.log("pushing " + JSON.stringify(aggDataInput));
          aggDataInputArray.push(aggDataInput);
        } else {
          unsupportedFileFormatProvided = true;
        }
      }

      dispatch(
        showSnackbar({
          message: t(
            filesCount == 1
              ? "file_uploading_ongoing_msg_singular"
              : "file_uploading_ongoing_msg_plural",
            {
              files_count: filesCount,
            }
          ),
          action: "",
          onAction: null,
          loading: false,
        })
      );

      // if (currentlyOpenedCollection && currentlyOpenedCollection.sharedUsers.length > 0){
      //   //When the collection is shared, we apply the max sort order score from the current collection  to put the item at the top of it.
      //   const maxSortOrderScoreAggData = data.allAggdata.reduce(
      //     (previous: AggDataObj, current: AggDataObj) =>
      //       Number(previous.sortOrderScore) > Number(current.sortOrderScore) ? previous : current
      //   );
      // }

      if (imagesFilesArray.length > 0) {
        imagesFilesArray.map((imageFile) => {
          new Compressor(imageFile, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
              // compressedResult has the compressed file.
              // Use the compressed file to upload the images to your server.
              var aggDataInput = {
                dataType: DataType.IMAGE_TYPE,
                dataSubtype: DataSubtype.IMAGE_SUBTYPE,
                image: compressedResult,
                sortOrderScore: maxSortOrderScoreToApply + 1,
                collection: currentlyOpenedCollection?.id,
              };
              if (displayTimePickerState.displayTimePicker) {
                const reminder = [
                  { cycle: 0, triggerTime: timePickerValue.getTime() / 1000 },
                ];
                const reminderAsString = JSON.stringify(reminder);
                aggDataInput.reminders = reminderAsString;
              }
              aggDataInputArray.push(aggDataInput);

              if (filesCount == aggDataInputArray.length) {
                addMultipleAggDataWithFiles({
                  variables: {
                    input: aggDataInputArray,
                  },
                })
                  .then((data: any) => {
                    if (
                      data.data.createMultipleAggdata.collection &&
                      data.data.createMultipleAggdata.collection.sharedUsers
                        .length > 0
                    ) {
                      dispatch(
                        setMaxSortOrderScoreCurrentCollection(
                          maxSortOrderScoreForCurrentCollection + 1
                        )
                      );
                    } else {
                      dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
                    }
                    setFileUploadIsLoading(false);
                    if (
                      data.data &&
                      data.data.createMultipleAggdata &&
                      data.data.createMultipleAggdata.error &&
                      data.data.createMultipleAggdata.error ==
                        MAX_DATA_USAGE_REACHED_ERROR
                    ) {
                      dispatch(displayMaxDataUsageReachedDialog(true));
                    }
                  })
                  .catch((e) => {
                    setFileUploadIsLoading(false);
                  });
              }
            },
          });
        });
      } else {
        addMultipleAggDataWithFiles({
          variables: {
            input: aggDataInputArray,
          },
        })
          .then((data: any) => {
            if (
              data.data.createMultipleAggdata.collection &&
              data.data.createMultipleAggdata.collection.sharedUsers.length > 0
            ) {
              dispatch(
                setMaxSortOrderScoreCurrentCollection(
                  maxSortOrderScoreForCurrentCollection + 1
                )
              );
            } else {
              dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
            }
            setFileUploadIsLoading(false);
            if (
              data.data &&
              data.data.createMultipleAggdata &&
              data.data.createMultipleAggdata.error &&
              data.data.createMultipleAggdata.error ==
                MAX_DATA_USAGE_REACHED_ERROR
            ) {
              dispatch(displayMaxDataUsageReachedDialog(true));
            }
          })
          .catch((e) => {
            setFileUploadIsLoading(false);
          });
      }

      if (files.length > MAX_ALLOWED_BULK_FILES_IMPORT) {
        dispatch(
          showSnackbar({
            message: t("warning_max_bulk_upload_files_exceeded", {
              max_allowed_files: MAX_ALLOWED_BULK_FILES_IMPORT,
            }),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      }

      if (singleDataMaxSizeDetected) {
        dispatch(
          showSnackbar({
            message: t("warning_max_single_file_size_reached_msg", {
              max_single_data_size: getSizeInHumeanReadableFormat(
                SINGLE_DATA_MAX_ALLOWED_SIZE_FREE_TIER
              ),
            }),
            action: t("title_upgrade"),
            onAction: () => {
              history.push(PRICING);
            },
            loading: false,
          })
        );
        return;
      }

      if (unsupportedFileFormatProvided) {
        dispatch(
          showSnackbar({
            message: t("warning_addded_file_format_not_supported"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      }
      // console.log("Add multiple " + JSON.stringify(aggDataInputArray))
    },
    [
      setFileUploadIsLoading,
      displayTimePickerState,
      timePickerValue,
      maxSortOrderScore,
      currentlyOpenedCollection,
      maxSortOrderScoreForCurrentCollection,
      addMultipleAggDataWithFiles,
    ]
  );

  function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    addFiles(e.target.files);
  }

  return (
    <div
      style={{
        position: "relative",
        alignItems: "center",
      }}
    >
      <input
        ref={hiddenFileInput}
        //accept="image/*,application/pdf,audio/*,video/*"
        onChange={onFileInputChange}
        type="file"
        multiple="multiple"
        style={{ display: "none" }}
      />
      {fileUploadIsLoading && (
        <CircularProgress
          size={68}
          sx={{
            color: colorGreyMemento,
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        ></CircularProgress>
      )}

      <SpeedDial
        ariaLabel="SpeedDial"
        className={classes.speedDial}
        hidden={hidden}
        icon={
          <AddIcon
            style={{ height: addIconDims, width: addIconDims }}
            className={classes.icons}
          />
        }
        onClose={() => handleClose()}
        onOpen={handleOpen}
        open={open}
        direction={direction}
        FabProps={fabProps}
      >
        {(isCollectionsUiDisplayed ? collectionsActions : actions).map(
          (action) => (
            <SpeedDialAction
              id="speed-dial-actions"
              key={action.name}
              icon={action.icon}
              tooltipTitle={
                <h1
                  style={{
                    fontWeight: 100,
                    fontSize: "1.4rem",
                    padding: "0.6rem",
                  }}
                >
                  {action.name}
                </h1>
              }
              tooltipPlacement="top"
              onClick={(e) => handleAddNewItem(action.action)}
              className={classes.speedDialAction}
            />
          )
        )}
      </SpeedDial>

      {displayTimePickerState.displayTimePicker && (
        <Fade in={open}>
          <Card
            onMouseEnter={() => {
              timePickerHoveredRef.current = true;
            }}
            onMouseLeave={() => {
              timePickerHoveredRef.current = false;
              handleClose();
            }}
            sx={{
              marginTop: "90px",
              right: -100,
              width: "248px",
              position: "absolute",
              padding: "12px",
              backgroundColor: colorMementoBeige,
            }}
          >
            <Typography variant="h6" color="text.secondary">
              {t("add_new_item_reminder_hint", {
                date: formatToContextualDate(
                  displayTimePickerState.selectedDayDate
                ),
              })}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopTimePicker
                value={timePickerValue}
                onOpen={() => {
                  isTimePickerOpenedRef.current = true;
                }}
                onClose={() => {
                  // setIsTimePickerOpened(false)
                  //handleClose()
                  isTimePickerOpenedRef.current = false;
                  handleClose();
                }}
                onChange={(newValue) => {
                  setTimePickerValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Card>
        </Fade>
      )}

      <AddNewLinkModal
        open={openLinkModal}
        reminder={
          displayTimePickerState.displayTimePicker
            ? reminderToAddRef.current
            : undefined
        }
        handleModalClose={setOpenLinkModal}
      />

      <AddNewCollectionModal
        open={openNewCollectionModal}
        handleModalClose={setOpenNewCollectionModal}
      />
    </div>
  );
}

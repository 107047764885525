import * as React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { getDateAndTimeFromIso8601 } from "shared/dataUtils";
import { colorMemento, colorHyperlink } from "shared/colors";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  fetchPinterestAuthUrl,
  authenticateUser,
  AuthenticateUserResponse,
  disconnectCurrentIntegration,
  PinterestIntegrationInformation,
  fetchIntegrationInformation,
  setDestinationCollection,
  SetCollectionResponse,
} from "api/pinterestIntegrationApi";
import { DEBUG } from "configuration";
import { PINTEREST_INTEGRATION_SETTINGS_WITH_SUBPATH } from "navigation/Constants";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CardActions } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";
import SelectCollectionContainer from "containers/SelectCollectionContainer/SelectCollectionContainer";
import { CollectionObj } from "models/collection";
import ImagePreview from "components/CollectionItems/ImagePreview";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getCollectionUrl } from "configuration";
import { isUserOnFreePlan } from "models/user";
import MaxDataUsageLimitReachedDialog from "components/UI/Modals/MaxDataUsageLimitReachedDialog";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    paddingLeft: "36px",
    flexDirection: "column",
    width: "50%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  boxSectionContiner: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      fontSize: "1.4rem",
    },
  },
  tableHeader: {
    "& .MuiTableCell-root": {
      fontSize: "1.4rem",
    },
  },
}));

interface SimpleConfirmationPayload {
  hideCancel: boolean;
  title: string;
  description: string;
  handleOk: () => void;
  handleCancel: () => void;
  handleClose: (close: boolean) => void;
}

export default function PinterestIntegration() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [externalPopup, setExternalPopup] = React.useState<any>(null);
  const history = useHistory();
  const user = useSelector((state: RootState) => state.auth.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [loadingPinterestInfo, setLoadingPinterestInfo] = React.useState(true);
  const [isCollectionSettingOngoing, setIsCollectionSettingOngoing] =
    React.useState(false);

  const [pinterestIntegrationInfo, setPinterestIntegrationInfo] =
    React.useState<PinterestIntegrationInformation | null>(null);

  const [displaySimpleConfirmation, setDisplaySimpleConfirmation] =
    React.useState<SimpleConfirmationPayload | null>(null);

  const [openSelectCollectionContainer, setOpenSelectCollectionContainer] =
    React.useState(false);
  const [displayUpgradePlanDialog, setDisplayUpgradePlanDialog] =
    React.useState(false);
  //in some cases like on mobile, the redirection is not done on a popup, the browser opens a new tab for the  auth callback URL.
  //We built a component that gets the code from there and redirect to here.
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");

  React.useEffect(() => {
    if (code && user && code.length > 0) {
      handleAuthenticateUser(code);
    }
  }, [code, user]);

  React.useEffect(() => {
    fetchIntegrationInformation()
      .then((info: PinterestIntegrationInformation) => {
        setLoadingPinterestInfo(false);
        //setFetchNotionIntegrationInfoFailed(false);
        setPinterestIntegrationInfo(info);
      })
      .catch((e) => {
        console.log("fetchIntegrationInformation: caught exception %o", e);
        setLoadingPinterestInfo(false);
        //setFetchNotionIntegrationInfoFailed(true);
      });
  }, []);

  const handleEditClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopupMenu = () => {
    setAnchorEl(null);
  };

  const handleIntegrateButtonClick = React.useCallback(() => {
    if (isUserOnFreePlan(user)) {
      setDisplayUpgradePlanDialog(true);
      return;
    }
    fetchPinterestAuthUrl()
      .then((url: string) => {
        var popupWidth = 500;
        var popupHeight = 600;
        var left = window.screenX + (window.outerWidth - popupWidth) / 2;
        var top = window.screenY + (window.outerHeight - popupHeight) / 2.5;
        const title = `Auth`;
        const popup = window.open(
          url,
          title,
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
        );
        setExternalPopup(popup);
      })
      .catch((e) => {
        if (DEBUG) {
          console.log("Caucght an error while fetching auth url");
        }
      });
  }, [setExternalPopup, externalPopup, user]);

  //We monitor on the external popup that we got a response code
  React.useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("code");
        if (code) {
          externalPopup.close();
          if (DEBUG) {
            console.log(`The popup URL has URL code param = ${code}`);
          }
          handleAuthenticateUser(code);
        }
      } catch (e) {
        console.log("Caught an exception! %o", e);
      }
    }, 500);
  }, [externalPopup]);

  const handlePickCollectionClick = React.useCallback(() => {
    if (isUserOnFreePlan(user)) {
      setDisplayUpgradePlanDialog(true);
      return;
    }
    setOpenSelectCollectionContainer(true);
  }, [setOpenSelectCollectionContainer, user]);

  const handleCollectionSelected = React.useCallback(
    (collection: CollectionObj) => {
      if (!collection || !collection.id) {
        return;
      }
      setOpenSelectCollectionContainer(false);
      setIsCollectionSettingOngoing(true);
      setDestinationCollection(collection.id)
        .then((response: SetCollectionResponse) => {
          if (response.resultCode == 200) {
            setPinterestIntegrationInfo({
              isConnected: pinterestIntegrationInfo?.isConnected,
              userName: pinterestIntegrationInfo?.userName,
              userAvatar: pinterestIntegrationInfo?.userAvatar,
              collectionInfo: response.collectionInfo,
            });
          } else {
            console.log(
              "Failed to set destination collection " + JSON.stringify(response)
            );
          }
        setIsCollectionSettingOngoing(false);

        })
        .catch((e) => {
          setIsCollectionSettingOngoing(false);
          console.log("Caught an error ", e);
        });
    },
    [
      pinterestIntegrationInfo,
      setPinterestIntegrationInfo,
      setIsCollectionSettingOngoing,
    ]
  );

  const handleAuthenticateUser = React.useCallback(
    (code: string) => {
      setLoadingPinterestInfo(true);
      authenticateUser(code)
        .then((authUserResponse: AuthenticateUserResponse) => {
          if (DEBUG) {
            console.log("handleAuthenticateUser: auth ok!");
          }
          setLoadingPinterestInfo(false);
          if (authUserResponse.resultCode == 1) {
          } else if (authUserResponse.resultCode == 0) {
            setPinterestIntegrationInfo(authUserResponse.integrationInfo);
          }
          if (code) {
            //we redirect excluding the search params to avoid unexpected behavior in case the user refreshes
            history.replace(PINTEREST_INTEGRATION_SETTINGS_WITH_SUBPATH);
          }
        })
        .catch((e) => {
          if (DEBUG) {
            console.log("handleAuthenticateUser: failed %o", e);
          }
        });
    },
    [code]
  );

  const getIntegrationStatusComponents = React.useCallback(() => {
    console.log("getIntegrationStatusComponents : integ info is %o", pinterestIntegrationInfo)
    if (loadingPinterestInfo) {
      return (
        <Card sx={{ display: "flex", marginTop: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <CardContent sx={{ flex: "1 0 auto", width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Skeleton height="36px" width="20%" />

                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "20%" }}
                >
                  <Skeleton height="36px" width="80%" />
                  <Skeleton height="36px" width="24px" />
                </Box>
              </Box>

              {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                <Skeleton height="36px" width="20%" />
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "20%" }}
                >
                  <Skeleton height="36px" width="100%" />
                </Box>
              </Box> */}
            </CardContent>
          </Box>
        </Card>
      );
    } else if (pinterestIntegrationInfo) {
      if (pinterestIntegrationInfo.isConnected) {
        return (
          <Card sx={{ display: "flex", marginTop: "16px" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <CardContent sx={{ flex: "1 0 auto", width: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography component="div" variant="h5">
                    {t("notion_connected_to_title")}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{
                        marginRight: "8px",
                      }}
                    >
                      {pinterestIntegrationInfo.userName}
                    </Typography>
                    <img
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "10%",
                      }}
                      src={pinterestIntegrationInfo.userAvatar}
                    />
                  </Box>
                </Box>
                {isCollectionSettingOngoing && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      marginTop: "16px",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton height="36px" width="20%" />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "20%",
                      }}
                    >
                      <Skeleton height="36px" width="80%" />
                      <Skeleton height="36px" width="24px" />
                    </Box>
                  </Box>
                )}
                {!pinterestIntegrationInfo.collectionInfo &&
                  !isCollectionSettingOngoing && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        marginTop: "16px",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{ textAlign: "center", alignItems: "center" }}
                      >
                        {t("pinterest_pick_collection_message")}
                      </Typography>

                      <Button
                        onClick={handlePickCollectionClick}
                        variant="outlined"
                        sx={{
                          width: "30%",
                          fontSize: "1.6rem",
                          marginTop: "8px",
                        }}
                      >
                        {t("pick_action")}
                      </Button>
                    </Box>
                  )}
                {pinterestIntegrationInfo.collectionInfo &&
                  !isCollectionSettingOngoing && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        marginTop: "16px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{ textAlign: "center", alignItems: "center" }}
                      >
                        {t("synchronize_to")}
                      </Typography>

                      <Box
                        onClick={()=>{
                          window.open(
                            getCollectionUrl(
                              pinterestIntegrationInfo.collectionInfo.id,
                              pinterestIntegrationInfo.collectionInfo
                                .isArchived
                            ),
                            "_blank"
                          );
                        }}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          cursor: "pointer"
                        }}
                      >
                        <Typography
                          component="div"
                          variant="h5"
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            marginRight: "4px",
                          }}
                        >
                          {pinterestIntegrationInfo.collectionInfo.name}
                        </Typography>
                        <ImagePreview
                          previewUrls={
                            pinterestIntegrationInfo.collectionInfo.previewUrls
                          }
                          height="48px"
                          width="48px"
                        />
                      </Box>
                    </Box>
                  )}
              </CardContent>
              <CardActions
                sx={{ width: "100%", display: "flex", justifyContent: "end" }}
              >
                <Tooltip
                  title={
                    <Typography variant="h5">{t("edit_action")}</Typography>
                  }
                >
                  <IconButton onClick={handleEditClick}>
                    <EditIcon
                      sx={{
                        width: "1.6rem",
                        height: "1.6rem",
                        // color: colorMemento,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <Typography variant="h5">
                      {t("disconnect_action")}
                    </Typography>
                  }
                >
                  <IconButton onClick={handleDisconnect}>
                    <HighlightOffIcon
                      sx={{
                        width: "1.6rem",
                        height: "1.6rem",
                        // color: colorMemento,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Box>
          </Card>
        );
      } else {
        return (
          <Card sx={{ display: "flex", marginTop: "16px" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <CardContent
                sx={{
                  flex: "1 0 auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ padding: "12px", fontSize: "1.6rem" }}
                >
                  {t("no_pinterest_account_connected_msg")}
                </Typography>
                <Button
                  onClick={handleIntegrateButtonClick}
                  variant="contained"
                  style={{ width: "60%", fontSize: "1.6rem" }}
                >
                  {t("connect_action")}
                </Button>
              </CardContent>
            </Box>
          </Card>
        );
      }
    } else {
      return (
        <Card sx={{ display: "flex", marginTop: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <CardContent
              sx={{
                flex: "1 0 auto",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" sx={{ padding: "12px" }}>
                {t("failed_fetch_notion_integration_information_msg")}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      );
    }
  }, [
    loadingPinterestInfo,
    pinterestIntegrationInfo,
    pinterestIntegrationInfo?.collectionInfo,
    isCollectionSettingOngoing,
  ]);

  const handleDisconnect = React.useCallback(() => {
    setDisplaySimpleConfirmation({
      title: t("diconnect_pinterest_account_confirmation_dialog_tilte"),
      description: t(
        "diconnect_pinterest_account_confirmation_dialog_description"
      ),
      hideCancel: false,
      handleOk: () => {
        setDisplaySimpleConfirmation(null);
        setLoadingPinterestInfo(true);
        disconnectCurrentIntegration()
          .then((result: boolean) => {
            setLoadingPinterestInfo(false);
            if (result) {
              setPinterestIntegrationInfo({
                isConnected: false,
                userName: null,
                userAvatar: null,
                collectionInfo: null,
              });
            } else {
              if (DEBUG) {
                console.log("Failed to disconnect from pinterest!");
              }
            }
          })
          .catch((e) => {
            setLoadingPinterestInfo(false);
          });
      },
      handleCancel: () => {
        setDisplaySimpleConfirmation(null);
      },
      handleClose: (close: boolean) => {
        setDisplaySimpleConfirmation(null);
      },
    });
  }, [
    setDisplaySimpleConfirmation,
    setLoadingPinterestInfo,
    setPinterestIntegrationInfo,
  ]);

  return (
    <>
      <Helmet>
        <title>Connect your Pinterest | Memento</title>
      </Helmet>
      <Box className={classes.root}>
        <Box className={classes.boxSectionContiner}>
          <Typography
            style={{ marginTop: "16px" }}
            variant="h3"
            color="text.primary"
          >
            Pinterest
          </Typography>
          {!pinterestIntegrationInfo ||
            (!pinterestIntegrationInfo.isConnected && (
              <Typography
                variant="h5"
                color="text.secondary"
                sx={{ padding: "12px", fontSize: "1.4rem", width: "100%" }}
              >
                {t("pinterest_account_integration_subtitle")}
              </Typography>
            ))}
        </Box>

        {getIntegrationStatusComponents()}
        {displaySimpleConfirmation && (
          <SimpleConfirmationDialog
            open={Boolean(displaySimpleConfirmation)}
            title={displaySimpleConfirmation.title}
            description={displaySimpleConfirmation.description}
            hideCancel={displaySimpleConfirmation.hideCancel}
            handleOk={displaySimpleConfirmation.handleOk}
            handleModalClose={displaySimpleConfirmation.handleClose}
          />
        )}
        {openSelectCollectionContainer && (
          <SelectCollectionContainer
            open={openSelectCollectionContainer}
            title={t("pick_action")}
            aggDataObjArray={[]}
            handleCollectionSelected={handleCollectionSelected}
            handleModalClose={() => {
              setOpenSelectCollectionContainer(false);
            }}
          ></SelectCollectionContainer>
        )}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={closePopupMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            key="connected-account"
            onClick={handleIntegrateButtonClick}
          >
            <Typography sx={{fontSize: "1.2rem"}}>{t('connected_account_title')}</Typography>
          </MenuItem>
          <MenuItem key="connected-coll" onClick={handlePickCollectionClick}>
            <Typography sx={{fontSize: "1.2rem"}}>{t('destination_collection_pinterest_title')}</Typography>
          </MenuItem>
        </Menu>

        {displayUpgradePlanDialog && (
          <MaxDataUsageLimitReachedDialog
            open={displayUpgradePlanDialog}
            description={t("upgrade_plan_to_integrate_pinterest_msg")}
            handleModalClose={() => {
              setDisplayUpgradePlanDialog(false);
            }}
          />
        )}
      </Box>
    </>
  );
}

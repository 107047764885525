import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback, useEffect } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { retrieveAllCollectionsGql } from "graphql/queries";
import Grid from "@mui/material/Grid";
import { CollectionObj } from "models/collection";
import Box from "@mui/material/Box";
import SelectCollectionItem from "containers/SelectCollectionContainer/SelectCollectionItem";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import IconButton from "@mui/material/IconButton";
import { colorGreyMemento } from "shared/colors";
import AddNewCollectionModal from "components/UI/Modals/AddNewCollectionModal";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Grow from "@mui/material/Grow";
import CloseIcon from "@mui/icons-material/Close";
import { scrollBarStyle } from "shared/styles";
import { AggDataObj } from "models/aggdata";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { UserObj } from "models/user";
import { RootState } from "store/reducers";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import SelectCollectionFromHierarchyView from "components/CollectionItems/SelectCollectionFromHierarchyView";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  getSelectCollectionViewType,
  savSelectCollectionViewType,
} from "localstorage/localstorage";

interface SelectCollectionContainerProps {
  open: boolean;
  title: string;
  token?: string;
  collection: CollectionObj;
  handleCollectionSelected: (
    collectionObj: CollectionObj,
    aggDataObjArray?: AggDataObj[] | null,
    token?: string | undefined
  ) => void;
  handleModalClose: (close: boolean) => void;
  aggDataObjArray: AggDataObj[];
  filterToOwnedByCurrentUser?: boolean;
  filterPublicCollections?: boolean;
  hideCreate?: boolean;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

export const AddNewCollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  margin-bottom: 16px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const iconStyles = {
  width: "18px",
  height: "18px",
  color: "gray",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      width: "100%",
      borderRadius: "25px",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    createCollection: {
      paddingTop: "8px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "40%",
      height: "70%",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      "@media (max-width: 780px)": {
        width: "100%",
        height: "100%",
      },
    },
    paper: {
      width: "100%",
      height: "100%",
      marginTop: "4px",
      overflowY: "auto",
      ...scrollBarStyle,
      "@media (max-width: 780px)": {
        height: "100%",
      },
    },
    addButton: {
      marginEnd: "12px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingBottom: "16px",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      paddingTop: "12px",
      paddingLeft: "48px",
      paddingRight: "48px",
      "@media (max-width: 780px)": {
        paddingLeft: "12px",
        paddingRight: "12px",
      },
    },
    searchInput: {
      paddingRight: "24px",

      "& .MuiInputBase-input": {
        fontSize: "2rem",
        padding: "12px 12px",
      },

      "& .MuiInputBase-root": {
        borderRadius: "8px",
        background: "#e8e8e8",
      },

      "&:hover .MuiInputBase-root": {
        background: "white",
      },
      "&:Mui-focused .MuiInputBase-root": {
        background: "white",
      },

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          boxShadow: "0 1px 1px 0 rgba(60, 64, 67, 0.602)",
          borderWidth: "0px",
        },
        "&:hover fieldset": {
          boxShadow: "0 1px 4px 0 rgba(60, 64, 67, 0.602)",
          borderWidth: "0px",
        },
        "&.Mui-focused fieldset": {
          boxShadow: "0 1px 4px 0 rgba(60, 64, 67, 0.602)",
          borderWidth: "0px",
        },
      },
    },
    searchIconButton: {},
    collapse: {
      width: "90%",
      "& .MuiCollapse-root .MuiCollapse-horizontal .MuiCollapse-entered .MuiCollapse-wrapperInner": {
        width: "90%",
      },
    },
    applyButton: {
      marginEnd: "12px",
      marginStart: "12px",
      alignItems: "center",
    },
  })
);

export default function SelectCollectionContainer({
  open,
  title,
  token,
  collection,
  handleCollectionSelected,
  handleModalClose,
  aggDataObjArray,
  filterToOwnedByCurrentUser = false,
  filterPublicCollections = false,
  hideCreate = false,
}: SelectCollectionContainerProps) {
  const [openNewCollectionModal, setOpenNewCollectionModal] = React.useState(
    false
  );
  const [
    selectedCollection,
    setSelectedCollection,
  ] = React.useState<CollectionObj | null>(null);
  const [displayGridMode, setDisplayGridMode] = React.useState(
    getSelectCollectionViewType()
  );
  const [showSearchInput, setShowSearchInput] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [displayedCollections, setDisplayedCollections] = React.useState<
    CollectionObj[]
  >([]);
  const { loading, error, data } = useQuery(retrieveAllCollectionsGql);
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  ); //current user
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    refreshData(searchInput);
  }, [data]);

  const handleAddNewCollection = useCallback(() => {
    setOpenNewCollectionModal(true);
  }, [openNewCollectionModal, setOpenNewCollectionModal]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSearchInput(value);
    refreshData(value);
  };

  const refreshData = (searchInput: string) => {
    if (!data || !data.collections) {
      return;
    }
    var collectionsArray = data.collections;
    if (filterToOwnedByCurrentUser && filterPublicCollections) {
      collectionsArray = collectionsArray.filter(
        (collection: CollectionObj) =>
          collection.owner.username == currentUser.username &&
          !collection.isPublic
      );
    }
    if (searchInput && searchInput.length > 0) {
      const rgxp = new RegExp(searchInput, "gi");
      setDisplayedCollections(
        collectionsArray.filter((collection: CollectionObj) => {
          return collection?.name?.match(rgxp);
        })
        // data.collections.filter((collection: CollectionObj) =>
        //   collection?.name?.toLowerCase().includes(searchInput.toLowerCase())
        // )
      );
    } else if (!searchInput.length) {
      setDisplayedCollections(collectionsArray);
    }
  };

  const closeSearch = useCallback(() => {
    setShowSearchInput(false);
    setSearchInput("");
    refreshData("");
  }, []);

  const toggleDisplayLayout = useCallback(() => {
    setDisplayGridMode(!displayGridMode);
    savSelectCollectionViewType(!displayGridMode);
  }, [setDisplayGridMode, displayGridMode]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              {!showSearchInput && isMobile && (
                <IconButton
                  onClick={() => {
                    handleModalClose(false);
                  }}
                >
                  <CloseIcon
                    style={{
                      padding: "2px",
                      width: "24px",
                      height: "24px",
                    }}
                  ></CloseIcon>
                </IconButton>
              )}
              <Grow
                style={{
                  transformOrigin: "0 0 0",
                  display: showSearchInput ? "block" : "none",
                }}
                in={showSearchInput}
              >
                <TextField
                  id="standard-basic"
                  className={classes.searchInput}
                  fullWidth
                  variant="standard"
                  placeholder={t("search_collection_hint")}
                  onChange={handleSearchInputChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        onClick={() => {
                          closeSearch();
                        }}
                      >
                        <CloseIcon
                          style={{
                            padding: "2px",
                            width: "24px",
                            height: "24px",
                          }}
                        ></CloseIcon>
                      </IconButton>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grow>
              <Grow
                style={{
                  transformOrigin: "0 0 0",
                  display: !showSearchInput ? "block" : "none",
                }}
                in={!showSearchInput}
              >
                {/*  <Typography className={classes.createCollection} variant="h5">
                  {title ? title : t("select_collection")}
                </Typography> */}

                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 400,
                    marginTop: "16px",
                    fontSize: "2rem",
                  }}
                >
                  {title ? title : t("select_collection")}
                </Typography>
              </Grow>

              <IconButton
                className={classes.searchIconButton}
                onClick={() => {
                  setShowSearchInput(true);
                }}
              >
                <SearchIcon
                  style={{
                    width: "24px",
                    height: "24px",
                    display: !showSearchInput ? "block" : "none",
                  }}
                ></SearchIcon>
              </IconButton>
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                width: "100%",
                paddingRight: "32px",
                marginBottom: "8px",
              }}
            >
              {displayGridMode ? (
                <IconButton onClick={toggleDisplayLayout}>
                  <TableRowsRoundedIcon sx={iconStyles} />
                </IconButton>
              ) : (
                <IconButton onClick={toggleDisplayLayout}>
                  <GridViewRoundedIcon sx={iconStyles} />
                </IconButton>
              )}
            </Box>
            <div className={classes.paper}>
              {displayGridMode ? (
                <Box
                  sx={{
                    paddingLeft: theme.spacing(8),
                    paddingRight: theme.spacing(8),
                    flexGrow: 1,
                    "@media (max-width: 780px)": {
                      paddingLeft: theme.spacing(1),
                      paddingRight: theme.spacing(1),
                      height: "100%",
                    },
                  }}
                >
                  <Grid container spacing={{ xs: 2, md: 4 }}>
                    {!isMobile && !hideCreate && (
                      <Grid item xs={4} sm={3} md={3} key="-1">
                        <AddNewCollectionContainer
                          onClick={handleAddNewCollection}
                        >
                          <AddCircleOutlinedIcon
                            style={{
                              color: colorGreyMemento,
                              height: "72px",
                              width: "72px",
                            }}
                          />
                          <Typography
                            className={classes.createCollection}
                            variant="h5"
                          >
                            {t("create_title")}
                          </Typography>
                        </AddNewCollectionContainer>
                      </Grid>
                    )}

                    {displayedCollections &&
                      displayedCollections?.map((collection: CollectionObj) => (
                        <Grid item xs={4} sm={3} md={3} key={collection.id}>
                          <SelectCollectionItem
                            key={collection.id!!}
                            collection={collection}
                            onCollectionSelected={() => {
                              handleCollectionSelected(
                                collection,
                                aggDataObjArray,
                                token
                              );
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    overflowY: "auto",
                    ...scrollBarStyle,
                    "@media (max-width: 780px)": {
                      paddingLeft: theme.spacing(1),
                      paddingRight: theme.spacing(1),
                      height: "100%",
                    },
                  }}
                >
                  <SelectCollectionFromHierarchyView
                    onCollectionSelected={(
                      collection: CollectionObj | null
                    ) => {
                      setSelectedCollection(collection);
                    }}
                    onTopLevelOptionSelected={(selected: boolean) => {}}
                    searchInput={searchInput}
                    handleClose={() => handleModalClose(false)}
                  />

                  <div className={classes.buttonContainer}>
                    <LoadingButton
                      className={classes.applyButton}
                      color="primary"
                      variant="contained"
                      disabled={!selectedCollection}
                      onClick={() => {
                        handleCollectionSelected(
                          selectedCollection,
                          aggDataObjArray,
                          token
                        );
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "white",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                        }}
                      >
                        {t("move_action")}
                      </Typography>
                    </LoadingButton>
                  </div>
                </Box>
              )}
            </div>

            <AddNewCollectionModal
              open={openNewCollectionModal}
              handleModalClose={setOpenNewCollectionModal}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { colorMementoBeige, colorMemento, stringToColour } from "shared/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import { useQuery } from "@apollo/client";
import {
  retrieveAllAggDataTagsGql,
  retrieveTagsForCollectionGql,
  retrieveTagsForPublicCollectionGql,
} from "graphql/queries";
import { AggDataTagObj } from "models/aggdata";

interface SimpleTagsFilterProps {
  selectedValues?: string[];
  allSelectedText: string; //placeholder text displayed when all items are selected
  inputHint: string; //placeholder text displayed when all items are selected
  onSelectionChanged: (selectedValues: string[]) => void;
  collectionId?: string;
  isCollectionPublic?: boolean;
  excludedTag?: string;
}

const SimpleTagsFilter = ({
  allSelectedText,
  inputHint,
  onSelectionChanged,
  selectedValues,
  collectionId,
  isCollectionPublic,
  excludedTag,
}: SimpleTagsFilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [values, setValues] = React.useState<string[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const [unSelectedItems, setUnSelectedItems] = React.useState(values);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getTagsGqlQuery = React.useCallback(() => {
    if (isCollectionPublic) {
      return retrieveTagsForPublicCollectionGql;
    } else if (collectionId) {
      return retrieveTagsForCollectionGql;
    } else {
      return retrieveAllAggDataTagsGql;
    }
  }, []);

  const { loading, data, error } = useQuery(getTagsGqlQuery(), {
    variables: collectionId
      ? {
          collectionId: collectionId,
        }
      : undefined,
    context: {
      clientName: isCollectionPublic ? "public" : "",
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (
      data &&
      (data.tagsForCollection ||
        data.allTags ||
        data.aggdataTagsForPublicCollection)
    ) {
      setValues(
        (isCollectionPublic
          ? data.aggdataTagsForPublicCollection
          : collectionId
          ? data.tagsForCollection
          : data.allTags
        ).map((tag: AggDataTagObj) => tag.name)
      );
    }
  }, [data]);

  /**
   * When the values provided as an input changes, we need to make sure that the selected items are still within the range of values provided
   * If not, then we simply remove the selected items, if the values doesn't exist anymore in values input, and notify about selection change.
   */
  React.useEffect(() => {
    setUnSelectedItems(
      values.filter((value) => !selectedItems.includes(value))
    );
    const selectedItemCountPreFilter = selectedItems.length;
    const newSelectedItemsArray = selectedItems.filter((selectedItem) =>
      values.includes(selectedItem)
    );
    setSelectedItems(newSelectedItemsArray);
    //  console.log("length before filter " + selectedItemCountPreFilter + " and after filter " + selectedItems.length)
    if (selectedItemCountPreFilter != newSelectedItemsArray.length) {
      onSelectionChanged(newSelectedItemsArray);
    }
  }, [values, onSelectionChanged]);

  React.useEffect(() => {
    if (selectedValues) {
      setSelectedItems(selectedValues);
      setUnSelectedItems(
        values.filter((value) => !selectedValues.includes(value))
      );
    }
  }, [selectedValues]);

  const handleDeleteSelectedItem = React.useCallback(
    (itemToDelete: string) => {
      const mutableArray = [...selectedItems];
      const indexOfItemToDelete = mutableArray.indexOf(itemToDelete);
      if (indexOfItemToDelete > -1) {
        mutableArray.splice(indexOfItemToDelete, 1);
        onSelectionChanged(mutableArray);
        setSelectedItems(mutableArray);
      }
      setUnSelectedItems([...unSelectedItems, itemToDelete]);
      setAnchorEl(null);
    },
    [selectedItems, unSelectedItems, setUnSelectedItems, setAnchorEl]
  );

  const handleMenuItemClicked = React.useCallback(
    (clickedItem: string) => {
      const mutableArray = [...unSelectedItems];
      const indexOfItemToDelete = mutableArray.indexOf(clickedItem);
      if (indexOfItemToDelete > -1) {
        mutableArray.splice(indexOfItemToDelete, 1);
        setUnSelectedItems(mutableArray);
      }
      const selectedItemsResult = [...selectedItems, clickedItem];
      setSelectedItems(selectedItemsResult);
      onSelectionChanged(selectedItemsResult);
      setAnchorEl(null);
    },
    [selectedItems, unSelectedItems, setUnSelectedItems, setAnchorEl]
  );

  return (
    <div>
      <Chip
        style={{
          borderStyle: "dashed",
        }}
        label={inputHint}
        variant="outlined"
        onClick={handleClick}
      />
      <Menu
        style={{
          width: "100%",
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {unSelectedItems.map((option, index) => {
          if (option == excludedTag) {
            return <></>;
          }
          return (
            <MenuItem
              sx={{
                fontSize: "1.2rem",
              }}
              key={option + index}
              onClick={(event) => {
                handleMenuItemClicked(option);
              }}
            >
              {option}
            </MenuItem>
          );
        })}
        {unSelectedItems.length == 0 && (
          <Typography
            sx={{
              paddingRight: "16px",
              paddingLeft: "16px",
            }}
          >
            <i>{allSelectedText}</i>
          </Typography>
        )}
      </Menu>
      {selectedItems.map((item, index) => {
        if (item == excludedTag) {
          return <></>;
        }
        let color = stringToColour(item);

        return (
          <Chip
            key={item + index}
            style={{
              borderColor: colorMemento,
              marginRight: "4px",
              marginLeft: "4px",
              backgroundColor: color?.backgroundColor,
              color: color?.textColor,
            }}
            sx={{
              fontSize: "1.2rem",
            }}
            label={item}
            color="primary"
            deleteIcon={<CancelIcon></CancelIcon>}
            onDelete={() => handleDeleteSelectedItem(item)}
            onClick={() => {}}
          />
        );
      })}
    </div>
  );
};

export default React.memo(SimpleTagsFilter);

import React, { useState } from "react";

import Toolbar from "containers/Toolbar/Toolbar";
import {
  AggDataContainer,
  ToolbarContainer,
  Container,
} from "containers/AggData/AggDataElements";
import { useClickOutside } from "hooks/useClickOutside";
import { Note, Dispatcher } from "shared/types";
import { AggDataObj, DataType } from "models/aggdata";
import {
  appendMultiSelectedData,
  removeMultiSelectedData,
} from "store/actions/aggdataActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CollectionObj } from "models/collection";
import { UserObj } from "models/user";

interface NoteLayoutProp {
  aggData: AggDataObj | undefined;
  clicked: number;
  currentUserRight: number,
  isHovering: boolean;
  displayFromCollection?: boolean; //Whether the item is displayed from a collection being opened UI or not
  bgColor?: string;
  onDelete: () => void;
  onAddToCollection: () => void;
  onAddReminder?: () => void;
  onRemoveFromSharedCollection?: () => void;
  onColorSelected?: (colorIndex: number) => void;
  onArchive: () => void;
  onPinUnpin: () => void;
  onEdit: () => void;
  onAnnotate: () => void;
  setIsHovering: Dispatcher<boolean>;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onComment?: () => void;
  onSave?: () => void;
  disableToolbar?: boolean;
  children: React.ReactNode;
}

const AggDataLayout = ({
  aggData,
  currentUserRight,
  clicked,
  bgColor = "#ffffff",
  onClick,
  onDelete,
  onEdit,
  onAnnotate,
  onAddToCollection,
  onAddReminder,
  onRemoveFromSharedCollection,
  onColorSelected,
  setIsHovering,
  isHovering,
  displayFromCollection = false,
  onClose,
  children,
  onArchive,
  onPinUnpin,
  onComment,
  onSave,
  disableToolbar = false
}: NoteLayoutProp) => {
  const id = aggData?.id;
  const [showLabel, setShowLabel] = useState(false);
  const [isSelected, setSelected] = React.useState(false);
  const [displayVisibilityHint, setDisplayVisibilityHint] =
    React.useState(false);

  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  const dispatch = useDispatch();

  const multiSelectedAggData = useSelector(
    (state: RootState) => state.aggdataReducer.multiSelectedAggData
  );

  const showConfidentialityFilterGlobal = useSelector(
    (state: RootState) => state.view.showConfidentialityFilter
  );

  const [shoConfidentialityFilter, setShowConfidentialityFilter] =
    React.useState(showConfidentialityFilterGlobal);

  const currentlyOpenedCollectionCurrentUserRight: number = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollectionCurrentUserRight
  );
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );

  const handleSelectionButtonClicked = React.useCallback(() => {
    if (isSelected) {
      dispatch(removeMultiSelectedData(aggData));
    } else {
      dispatch(appendMultiSelectedData(aggData));
    }
    setSelected(!isSelected);
  }, [isSelected, aggData]);

  const handleItemClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      //If selection ongoing, click on item should select/unselect
      if (multiSelectedAggData && multiSelectedAggData.length > 0) {
        handleSelectionButtonClicked();
        e.preventDefault();
      } else {
        onClick(e);
      }
    },
    [multiSelectedAggData, handleSelectionButtonClicked, onClick]
  );

  React.useEffect(() => {
    if (!multiSelectedAggData || multiSelectedAggData.length == 0) {
      setSelected(false);
    }
  }, [multiSelectedAggData, setSelected]);

  React.useEffect(() => {
    setShowConfidentialityFilter(showConfidentialityFilterGlobal);
  }, [showConfidentialityFilterGlobal]);



  return (
    <AggDataContainer
      bgColor={bgColor}
      clicked={clicked}
      isSelected={isSelected}
      //onClick= {() => {setIsHovering(false)}}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {shoConfidentialityFilter && (
        <div
          onMouseEnter={() => {
            setDisplayVisibilityHint(true);
          }}
          onMouseLeave={() => {
            setDisplayVisibilityHint(false);
          }}
          onClick={() => {
            setShowConfidentialityFilter(false);
            setDisplayVisibilityHint(false);
          }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backdropFilter: "blur(20px)",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
          }}
        >
          {displayVisibilityHint && (
            <VisibilityIcon sx={{ width: "48px", height: "48px" }} />
          )}
        </div>
      )}
      <Container>
        {/* <Tool notePin id={id!!} isPinned={false} title="Pin Note" /> */}
        <div
          style={{
            height: "100%",
            width: "100%",
            paddingBottom:
              aggData?.dataType == DataType.NOTE_TYPE && disableToolbar ? "12px" : "0px",
          }}
          onClick={handleItemClick}
        >
          {children}
        </div>
      </Container>
      {/* We don't want to display the toolbar for items when
           - the user is supposed to be only an observer
           - the user is not the owner of the item, and it is opened from somewhere else than the collection itself
        */}
        

      {!disableToolbar && (
          <ToolbarContainer>
            {/* {labels.length > 0 && <NoteLabel id={id} labels={labels} />} */}
            <Toolbar
              id={id!!}
              selectedColor={aggData?.note?.noteColor}
              isAggDataSharedNDisplayedFromCollection={
                //actually it is more is a shared aggdata displayed from a shared collection context, because in this case, regardless of being owner of the data or not, we don't allow directly deleting it or archiving it.
                currentlyOpenedCollection &&
                currentlyOpenedCollection.sharedUsers &&
                currentlyOpenedCollection.sharedUsers.length > 0 &&
                aggData?.collection &&
                aggData?.sharedUsers &&
                aggData?.sharedUsers.length > 0
              }
              showColorPalette={aggData?.dataType == DataType.NOTE_TYPE}
              onHover={isHovering}
              transparentBg={aggData?.dataType == DataType.NOTE_TYPE} //For notes no need to put a white color filter
              displayedFromCollection={displayFromCollection}
              setShowLabel={setShowLabel}
              onDelete={onDelete}
              onAddToCollection={onAddToCollection}
              isChecked={false}
              onClose={onClose}
              onComment={onComment}
              onSave={onSave}
              onEdit={onEdit}
              onAnnotate={onAnnotate}
              isDataArchived={aggData?.isArchived!!}
              aggDataObj={aggData!!}
              onArchive={onArchive}
              onPinUnpin={onPinUnpin}
              onAddReminder={onAddReminder}
              onColorSelected={onColorSelected}
              onSelectionClicked={handleSelectionButtonClicked}
              onRemoveFromSharedCollection={onRemoveFromSharedCollection}
              currentUserRight={currentUserRight}
            />
          </ToolbarContainer>
        )}
    </AggDataContainer>
  );
};

export default AggDataLayout;

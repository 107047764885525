import React, { useState, useCallback } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import { useSelector, useDispatch } from "react-redux";
import { showConfidentialityFilter } from "store/actions/view";
import { saveConfidentialityFilterConfiguration } from "localstorage/localstorage";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import { useTranslation } from "react-i18next";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { RootState } from "store/reducers";

interface SharedUsersFilterControlProps {
  handleOptionChanged: (displaySharedItems: boolean) => void;
}

const SharedUsersFilterControl = ({
  handleOptionChanged,
}: SharedUsersFilterControlProps) => {

  const [selected, setSelected] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="top"
      title={styledTooltipTitle(
        !selected
          ? t("display_only_reminders_on_own_items_hint")
          : t("display_only_reminders_on_shared_items_hint")
      )}
    >
      <ToggleButton
        value="check"
        selected={selected}
        onChange={() => {
          //saveConfidentialityFilterConfiguration(!selected);
          setSelected(!selected);
          handleOptionChanged(!selected);
          //dispatch(showConfidentialityFilter(!selected));
        }}
      >
        {!selected ? <PersonOutlineIcon /> : <PeopleOutlineIcon />}
      </ToggleButton>
    </Tooltip>
  );
};

export default React.memo(SharedUsersFilterControl);

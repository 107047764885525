import { AggDataObj } from 'models/aggdata';

// Action types
export const INIT_DATA = 'INIT_DATA';
export const ADD_DATA = 'ADD_DATA';
export const SET_AGG_DATA_WITH_REMINDERS = 'SET_AGG_DATA_WITH_REMINDERS';
export const GET_EDITABLE_DATA = 'GET_EDITABLE_DATA';
export const SET_EDITABLE_DATA = 'SET_EDITABLE_DATA';
export const CLEAR_EDITABLE_DATA = 'CLEAR_EDITABLE_DATA';
export const SET_MAX_ALL_ITEMS_PIN_SCORE = 'SET_MAX_ALL_ITEMS_PIN_SCORE';
export const SET_MAX_SORT_ORDER_SCORE = 'SET_MAX_SORT_ORDER_SCORE';
export const APPEND_DATA_TO_MULTI_SELECTION = 'APPEND_DATA_TO_MULTI_SELECTION';
export const SET_MULTI_SELECTED_DATA = 'SET_MULTI_SELECTED_DATA';
export const REMOVE_MULTI_SELECTED_DATA = 'REMOVE_MULTI_SELECTED_DATA';
export const SET_MAX_SORT_ORDER_SCORE_CURRENT_COLLECTION = 'SET_MAX_SORT_ORDER_SCORE_CURRENT_COLLECTION';


interface AggDataWithRemindersState {
  aggDataWithReminders: AggDataObj[];
  loading: boolean;
  error: boolean;
}

// Types
export interface AggDataState {
  aggData: AggDataObj[];
  aggDataWithRemindersState: AggDataWithRemindersState;
  editableData: null | AggDataObj;
  editableAggdataNote: null | AggDataObj;
  maxAllItemsPinScore: number;
  maxSortOrderScore: number;
  maxSortOrderScoreInCurrentCollection: number;
  multiSelectedAggData: AggDataObj[];
}

export interface InitData {
  type: typeof INIT_DATA;
  payload: AggDataObj[];
}

export interface AddData {
  type: typeof ADD_DATA;
  payload: AggDataObj;
}

export interface SetAggDataWithReminders {
  type: typeof SET_AGG_DATA_WITH_REMINDERS;
  payload: AggDataWithRemindersState;
}

export interface GetEditableData {
  type: typeof GET_EDITABLE_DATA;
  payload: AggDataObj;
}

export interface SetEditableAggdataNote {
  type: typeof SET_EDITABLE_DATA;
  payload: AggDataObj;
}

export interface ClearEditableData {
  type: typeof CLEAR_EDITABLE_DATA;
}


export interface SetMaxAllItemsPinScore {
  type: typeof SET_MAX_ALL_ITEMS_PIN_SCORE;
  payload: number;
}

export interface SetMaxAllItemsPinScore {
  type: typeof SET_MAX_ALL_ITEMS_PIN_SCORE;
  payload: number;
}

export interface SetMaxSortOrderScore {
  type: typeof SET_MAX_SORT_ORDER_SCORE;
  payload: number;
}

export interface SetMaxSortOrderScoreCurrentCollection {
  type: typeof SET_MAX_SORT_ORDER_SCORE_CURRENT_COLLECTION;
  payload: number;
}

export interface AppendMultiSelectedData {
  type: typeof APPEND_DATA_TO_MULTI_SELECTION;
  payload: AggDataObj;
}

export interface SetMultiSelectedData {
  type: typeof SET_MULTI_SELECTED_DATA;
  payload: AggDataObj[];
}

export interface RemoveMultiSelectedData {
  type: typeof REMOVE_MULTI_SELECTED_DATA;
  payload: AggDataObj;
}


export type AggDataActionTypes =
  | InitData
  | AddData
  | GetEditableData
  | SetMaxSortOrderScore
  | SetMaxSortOrderScoreCurrentCollection
  | SetMaxAllItemsPinScore
  | SetEditableAggdataNote
  | SetAggDataWithReminders
  | AppendMultiSelectedData
  | SetMultiSelectedData
  | RemoveMultiSelectedData
  | ClearEditableData;


// Action Creators
export const initAggData = (aggData: AggDataObj[]): InitData => {
  return {
    type: INIT_DATA,
    payload: aggData,
  };
};


export const addAggData = (aggData: AggDataObj): AddData => {
  return {
    type: ADD_DATA,
    payload: aggData,
  };
};

export const setAggDataWithReminders = (aggDataWithReminders: AggDataObj[], loading: boolean, error: boolean): SetAggDataWithReminders => {
  return {
    type: SET_AGG_DATA_WITH_REMINDERS,
    payload: {
      aggDataWithReminders: aggDataWithReminders,
      loading: loading,
      error: error
    }
  };
};

export const getEditableData = (note: AggDataObj): GetEditableData => {
  return {
    type: GET_EDITABLE_DATA,
    payload: note,
  };
};

export const setEditableAggdataNote = (aggdataNote: null | AggDataObj): SetEditableAggdataNote => {
  return {
    type: SET_EDITABLE_DATA,
    payload: aggdataNote,
  };
};

export const clearEditableData = (): ClearEditableData => {
  return {
    type: CLEAR_EDITABLE_DATA,
  };
};

// Action Creators
export const setAllItemsMaxPinScore = (allItemsMaxPinScore: number): SetMaxAllItemsPinScore => {
  return {
    type: SET_MAX_ALL_ITEMS_PIN_SCORE,
    payload: allItemsMaxPinScore,
  };
};

// Action Creators
export const setMaxSortOrderScore = (maxSortOrderScore: number): SetMaxSortOrderScore => {
  return {
    type: SET_MAX_SORT_ORDER_SCORE,
    payload: maxSortOrderScore,
  };
};

// Action Creators
export const setMaxSortOrderScoreCurrentCollection = (maxSortOrderScore: number): SetMaxSortOrderScoreCurrentCollection => {
  return {
    type: SET_MAX_SORT_ORDER_SCORE_CURRENT_COLLECTION,
    payload: maxSortOrderScore,
  };
};


// Action Creators
export const setMultiSelectedData = (aggDataArray: AggDataObj[]): SetMultiSelectedData => {
  return {
    type: SET_MULTI_SELECTED_DATA,
    payload: aggDataArray,
  };
};


// Action Creators
export const appendMultiSelectedData = (aggData: AggDataObj): AppendMultiSelectedData => {
  return {
    type: APPEND_DATA_TO_MULTI_SELECTION,
    payload: aggData,
  };
};
// Action Creators
export const removeMultiSelectedData = (aggData: AggDataObj): RemoveMultiSelectedData => {
  return {
    type: REMOVE_MULTI_SELECTED_DATA,
    payload: aggData,
  };
};


import styled, { keyframes, css } from "styled-components";
import { colorMemento, colorMementoBeige } from "shared/colors";

const popup = keyframes`
    from {
        opacity: 0;
        transform: scale(.4);
        transform: translate(-50%, -50%);
    } to {
        opacity: 1;
        transform: scale(1);
        transform: translate(-50%, -50%);
    }
`;
const popupScale = keyframes`
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(0.2);
    }
    40% {
      transform: scale(0.4);
    }
    60% {
      transform: scale(0.6);
    }
    80% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1) ;
    }
`;

export const AggDataImgOpenedContainer = styled.div`
  position: fixed;
  transform: translate(-50%, -50%);
  width: 42%;
  height: 60vh;
  max-height: 90vh;
  z-index: 800;
  cursor: default;
  overflow: hidden;
  animation: ${popup} 0.3s ease-out;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149);

  @media (max-width: 1024px) {
    width: 55%;
  }
  @media (max-width: 768px) {
    width: 75%;
    height: 80vh;
  }
  @media (max-width: 576px) {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
      0 1px 3px 1px rgba(60, 64, 67, 0.149);
  }
`;

export const AggDataContainer = styled("div")<{
  clicked: number;
  bgColor: string;
  isSelected: boolean;
}>`
  position: relative;
  width: 100%;
  border-radius: 8px;
  animation: ${popupScale} 0.1s ease-in-out;
  border: ${(props) =>
    props.isSelected ? "4px solid #FDC84B" : "2px solid rgba(66, 66, 66, 0.2)"};
  word-wrap: break-word;
  white-space: pre-wrap;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  background: ${(props) => props.bgColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
      0 1px 3px 1px rgba(60, 64, 67, 0.149);
  }

  ${({ clicked }) =>
    clicked &&
    css`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 42%;
      height: 60vh;
      max-height: 90vh;
      z-index: 800;
      cursor: default;
      animation: ${popup} 0.3s ease-out;
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
        0 1px 3px 1px rgba(60, 64, 67, 0.149);

      @media (max-width: 1024px) {
        width: 55%;
      }
      @media (max-width: 768px) {
        width: 75%;
        height: 80vh;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
    `}
`;

export const AggDataTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  width: 100%;
  padding: 0px 12px 0 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const AggDataContent = styled.div`
  width: 100%;
  font-size: 1.5rem;
  padding: 0px 12px 0 12px;
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  line-height: 2rem; /*Very important to have these two bottom lines ... spent some time to figure out that they are required to make line clamp work on firefox*/
  max-height: 10rem; /*For line calmp to work needs to be line-height*line-clamp value*/
`;

export const AggDataImageContent = styled.div`
  position: relative;

  width: auto;
  height: 100%;
  overflow: hidden;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
 // background-color: ${colorMementoBeige};
`;

export const AggDataFileImgPreviewContent = styled.div`
  width: auto;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colorMementoBeige};
`;

export const AggDataFilePagesInfoContainer = styled.div`
  width: 100%;
  padding-right: 12px;
  padding-bottom: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

export const AggDataSubTitle = styled.div`
  height: 100%;
  text-align: center;
  padding-right: 2px;
  font-size: 1 rem;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

export const ToolbarContainer = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 0px 0px 4px 4px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
`;

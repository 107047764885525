import axios, { AxiosResponse } from "axios";
import { SERVER_URL, DEBUG } from "configuration";
import { SubscriptionInformation } from "models/user"

const URL_PREVIEW_SUBPATH = "/urlpreview";


export interface UrlPreviewRequestResult {
    title: string,
    previewUrl: string, 
}

export const fetchPreview = async (url: string): Promise<UrlPreviewRequestResult> => {
    try {
        let res = await axios.post(SERVER_URL + URL_PREVIEW_SUBPATH,
            {
                url: url
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("getConnectedDevices result " + JSON.stringify(res));
        }
        return res.data

    } catch (e) {
        throw (e)
    }
}
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import NotionIcon from "icons/notion_icon.svg";
import PinterestIcon from "icons/pinterest_icon.png";
import { MAX_SHARED_COLLECTIONS_COUNT_FREE_USER } from "configuration";

function createData(
  name: string,
  calories: string,
  fat: string,
  carbs: string
) {
  return { name, calories, fat, carbs };
}

const ComparisonTable = () => {
  const { t } = useTranslation();

  const rows = [
    createData(
      t("compare_pricing_monthly_uploads_title"),
      "100Mb",
      "10Gb",
      t("unlimited_title")
    ),
    createData(
      t("compare_pricing_max_file_upload_title"),
      "20Mb",
      t("unlimited_title"),
      t("unlimited_title")
    ),
    createData(
      t("compare_pricing_sync_devices_title"),
      t("compare_pricing_sync_devices_option_1"),
      t("unlimited_title"),
      t("unlimited_title")
    ),
    createData(
      t("compare_pricing_tags_title"),
      t("unlimited_title"),
      t("unlimited_title"),
      t("unlimited_title")
    ),
    createData(
      t("compare_pricing_number_collections_title"),
      ""+MAX_SHARED_COLLECTIONS_COUNT_FREE_USER,
      t("unlimited_title"),
      t("unlimited_title")
    ),
    
    createData(t("compare_pricing_web_mobile_access_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_capture_organize_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_reminders_title"), "✔️", "✔️", "✔️"),
    createData(
      t("compare_pricing_speech_to_text_notes_title"),
      "✔️",
      "✔️",
      "✔️"
    ),
    createData(t("compare_pricing_privacy_filter_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_chrome_extension_title"), "✔️", "✔️", "✔️"),
    createData(
      t("compare_pricing_recap_notifications_title"),
      "✔️",
      "✔️",
      "✔️"
    ),
    createData(t("compare_pricing_global_search_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_shared_collections_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_send_email_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_public_collections_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_public_profile_title"), "✔️", "✔️", "✔️"),
    createData(
      t("compare_pricing_import_pins_pinterest"),
      "",
      "✔️",
      "✔️"
    ),
    createData(
      t("compare_pricing_connect_collection_to_notion"),
      "",
      "✔️",
      "✔️"
    ),
    createData(
      t("compare_pricing_highlights_extractions_title"),
      "",
      "✔️",
      "✔️"
    ),
    createData(t("compare_pricing_readonly_users_title"), "", "✔️", "✔️"),
    createData(t("compare_pricing_no_ads_title"), "", "✔️", "✔️"),
    createData(t("compare_pricing_fingerprint_lock_title"), "", "✔️", "✔️"),
    createData(t("compare_pricing_export_collection_title"), "", "", "✔️"),
    createData(
      t("compare_pricing_quick_access_notification_title"),
      "",
      "",
      "✔️"
    ),
    createData(t("compare_pricing_auto_collection_organizing_title"), "", "", "✔️"),
    createData(t("compare_pricing_auto_tag_organizing_title"), "", "", "✔️"),
    createData(t("compare_pricing_virtual_assistant_title"), "", "", "✔️"),
    createData(t("compare_pricing_smart_note_editor_title"), "", "", "✔️"),
    createData(t("compare_pricing_new_feature_access_title"), "", "", "✔️"),
  ];

  const getColumnTitleComponent = React.useCallback((title: string) => {
    return (
      <Typography
        variant="h5"
        align="center"
        color="text.primary"
        gutterBottom
        sx={{
          fontFamily: "Trebuchet MS, sans-serif",
        }}
      >
        {title}
      </Typography>
    );
  }, []);

  const getRowText = React.useCallback((text: string) => {
    return (
      <Typography
        variant="h5"
        color="text.primary"
        gutterBottom
        sx={{
          fontFamily: "Trebuchet MS, sans-serif",
          paddingLeft: "10%",
          alignText: "left",
        }}
      >
        {text}
      </Typography>
    );
  }, []);

  const getCellContentText = React.useCallback((text: string) => {
    return (
      <Typography
        variant="h5"
        align="center"
        color="text.primary"
        gutterBottom
        sx={{
          fontFamily: "Trebuchet MS, sans-serif",
        }}
      >
        {text}
      </Typography>
    );
  }, []);

  return (
    <>
      {" "}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            width: "60%",
            display: "flex",
            backgroundColor: "transparent",
            marginBottom: "48px",
            "@media (max-width: 780px)": {
              width: "100%",
            },
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">
                  {getColumnTitleComponent(t("tier_free_title"))}
                </TableCell>
                <TableCell align="right">
                  {getColumnTitleComponent(t("tier_mementee_title"))}
                </TableCell>
                <TableCell align="right">
                  {getColumnTitleComponent(t("tier_mementee_plus_title"))}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="left"
                    sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                  >
                    {getRowText(row.name)}
                    {t("compare_pricing_connect_collection_to_notion") ==
                      row.name && (
                      <img
                        src={NotionIcon}
                        width={"28px"}
                        height="28px"
                        style={{
                          paddingLeft: "8px",
                        }}
                      />
                    )} 
                    {t("compare_pricing_import_pins_pinterest") ==
                      row.name && (
                      <img
                        src={PinterestIcon}
                        width="20px"
                        height="20px"
                        style={{
                          marginLeft: "4px"
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {getCellContentText(row.calories)}
                  </TableCell>
                  <TableCell align="right">
                    {getCellContentText(row.fat)}
                  </TableCell>
                  <TableCell align="right">
                    {getCellContentText(row.carbs)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ComparisonTable;

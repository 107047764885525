import { Box, Menu, TextField, Typography } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";
import CheckIcon from "@mui/icons-material/Check";

const ColorPaletteContainer = styled("div")<{ isInputField?: boolean }>`
  max-width: 128px;
  display: block;
  z-index: 40000000;
  background-color: white;
  /* border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149); */
  background-color: white;

  ${({ isInputField }) =>
    isInputField &&
    css`
      bottom: 80px;
    `}
`;

const ColorPaletteBtn = styled("button")<{ color: string }>`
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1.5px solid transparent;
  border-radius: 50%;
  margin: 0.2rem;
  background: ${(props) => props.color};

  &:hover {
    border: 1.5px solid #5f6367;
  }

  &:first-child {
    border: 1.5px solid #e7eaed;
    &:hover {
      border: 1.5px solid #5f6367;
    }
  }
`;

interface PublicButtonColorPickerProps {
  anchorEl: any;
  open: boolean;
  onClose: () => void;
  onColorSelected: (color: string) => void;
  selectedColor: string;
}

export const paletteColors = [
  { color: "#ffffff" },
  { color: "#e57373" },
  { color: "#81c784" },
  { color: "#64b5f6" },
  { color: "#dce775" },
  { color: "#ffb74d" },
  { color: "#4db6ac" },
  { color: "#ba68c8" },
  { color: "#fff176" },
];

const PublicButtonColorPicker = ({
  anchorEl,
  open,
  onClose,
  onColorSelected,
  selectedColor,
}: PublicButtonColorPickerProps) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box
        sx={{
          paddingRight: "8px",
          paddingLeft: "8px",
        }}
      >
        {paletteColors.map((noteColor) => (
          <ColorPaletteBtn
            key={noteColor.color}
            color={noteColor.color}
            onClick={(e) => onColorSelected(noteColor.color)}
          >
            <CheckIcon
              sx={{ opacity: selectedColor == noteColor.color ? 1 : 0 }}
            />
          </ColorPaletteBtn>
        ))}
      </Box>
      <Box
        sx={{
          paddingRight: "8px",
          paddingLeft: "8px",
          marginTop: "8px"
        }}
      >
        <Typography>
            HEX Code
        </Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          placeholder="#000000"
          sx={{
            "& .MuiFormHelperText-root": {
              fontSize: "1.2rem",
            },
          }}
          InputProps={{
            style: { fontSize: "1.2rem", width: "100%" , padding: "8px"},
            /*                     disableUnderline: true,
             */
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onColorSelected(event.target.value);
          }}
        />
      </Box>
    </Menu>
  );
};

export default React.memo(PublicButtonColorPicker);

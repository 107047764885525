import axios, { AxiosResponse } from "axios";
import { SERVER_URL } from "configuration";
import { UserObj } from "models/user";
import { SUBSCRIPTION_FREE_PLAN, SUBSCRIPTION_MEMENTEE_PLAN, SUBSCRIPTION_MEMENTEE_PLUS_PLAN } from "configuration";
import { SubscriptionPlan } from 'models/user';
import { getDeviceName, getClientUniqueId } from "shared/dataUtils";
import { DEBUG } from 'configuration'

export interface DetelAccountApiResult {
    statusCode: number, //when successful will be 0, otherwise > 0. If 1, it's because of a wrong password.
    reason: string, //Shouldn't be used later to support different languages.
}

export interface LoginApiResult {
    statusCode: number, //when successful will be 0, otherwise > 0
    reason: string, //Shouldn't be used later to support different languages.
}

export enum LoginStatusCode {
    SUCCESS = 0,
    NO_ACCOUNT_FOUND_FOR_EMAIL = 1,
    WRONG_PASSWORD = 2
}

export enum LogoutStatusCodes {
    SUCCESS = 0,
    FAILURE = 1
}

export interface LogoutApiResult {
    statusCode: LogoutStatusCodes
}

export interface SignupApiResult {
    statusCode: SignupStatusCodes, //when successful will be 0, otherwise > 0
}


export enum SignupStatusCodes {
    SUCCESS = 0,
    USER_EXISTS_ALREADY = 1,
    UNKOWN = 2
}

export interface RequestResetPasswordApiResult {
    statusCode: RequestResetStatusCodes,
}

export enum RequestResetStatusCodes {
    SUCCESS = 0,
    USER_DOES_NOT_EXIST = 1,
    UNKOWN = 2
}

export enum ResetPasswordStatusCodes {
    SUCCESS = 0,
    FAILURE = 1
}

export interface ResetPasswordApiResult {
    statusCode: ResetPasswordStatusCodes,
}

export interface SignupArgs {
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    password_confirmation: string,
}

export interface ResetPasswordArgs {
    uid: string,
    token: string,
    password: string,
    password_confirmation: string,
}


const getUserSubscriptionPlan = (subscriptionPlanId: number): SubscriptionPlan => {
    if (subscriptionPlanId == SUBSCRIPTION_FREE_PLAN.id) {
        return SUBSCRIPTION_FREE_PLAN
    } else if (subscriptionPlanId == SUBSCRIPTION_MEMENTEE_PLAN.id) {
        return SUBSCRIPTION_MEMENTEE_PLAN
    } else if (subscriptionPlanId == SUBSCRIPTION_MEMENTEE_PLUS_PLAN.id){
        return SUBSCRIPTION_MEMENTEE_PLUS_PLAN
    }
    return SUBSCRIPTION_FREE_PLAN
}


export const fetchUserData = async (): Promise<UserObj> => {
    if (DEBUG) {
        console.log("Fetching user data start")
    }
    try {
        let res = await axios.get(SERVER_URL + "/api/auth/me", {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        })
        if (DEBUG) {
            console.log("Got user data is " + JSON.stringify(res));
        }
        return {
            id: res.data.id, email: res.data.email, firstName: res.data.first_name, lastName: res.data.last_name, username: res.data.username,
            monthlyDataUsage: res.data.monthly_data_usage, subscriptionPlan: getUserSubscriptionPlan(res.data.subscription_plan),
            dateJoined: res.data.date_joined,
            lastLogin: res.data.last_login,
            dataUsageMonth: res.data.data_usage_current_month,
        }
    } catch (e) {
        throw (e)
    }
};


export const deleteAccount = async (password: string): Promise<DetelAccountApiResult> => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };
    if (DEBUG) {
        console.log('Will delete user account')
    }
    // Request Body
    const body = JSON.stringify({ password });
    try {
        const res = await axios.post(SERVER_URL + '/api/auth/delete-account', body, config);
        if (DEBUG){
            console.log("deleteAccount: Got a response login " + JSON.stringify(res.data[0]))
        }
        return { statusCode: res.data.status_code, reason: res.data.reason }
    } catch (err) {
        console.log("deleteAccount: Caught an error while trying to delete account" + JSON.stringify(err))
        return { statusCode: 99, reason: "Login failed" };
    }
};


export const login = async (email: string, password: string): Promise<LoginApiResult> => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };
    if (DEBUG) {
        console.log('Starting reg login')
    }

    const device_name = getDeviceName()
    const device_id = getClientUniqueId()
    // Request Body
    const body = JSON.stringify({ email, password, device_name, device_id });
    try {
        const res = await axios.post(SERVER_URL + '/api/auth/login', body, config);
        console.log("Got a response login " + JSON.stringify(res.data[0]))
        return { statusCode: res.data.status_code, reason: res.data.reason }
    } catch (err) {
        console.log("Caught an error while login" + JSON.stringify(err))
        return { statusCode: 99, reason: "Login failed" };
    }
};


export const signUp = async (signupargs: SignupArgs): Promise<SignupApiResult> => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };
    // Request Body
    const body = JSON.stringify(signupargs);
    try {
        const res = await axios.post(SERVER_URL + '/api/auth/register', body, config);
        switch (res.data.status_code) {
            case SignupStatusCodes.SUCCESS.valueOf():
                return { statusCode: SignupStatusCodes.SUCCESS }
            case SignupStatusCodes.USER_EXISTS_ALREADY.valueOf():
                return { statusCode: SignupStatusCodes.USER_EXISTS_ALREADY }
            default:
                return { statusCode: SignupStatusCodes.UNKOWN }
        }
    } catch (err) {
        console.log("Caught an error while login" + JSON.stringify(err))
        return { statusCode: SignupStatusCodes.UNKOWN }
    }
};


export const requestResetPassword = async (email: string): Promise<RequestResetPasswordApiResult> => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };
    // Request Body
    const body = JSON.stringify({ email: email });
    console.log("request body " + body)
    try {
        const res = await axios.post(SERVER_URL + '/api/auth/request_reset_password', body, config);
        switch (res.data.status_code) {
            case RequestResetStatusCodes.SUCCESS.valueOf():
                return { statusCode: RequestResetStatusCodes.SUCCESS }
            case RequestResetStatusCodes.USER_DOES_NOT_EXIST.valueOf():
                return { statusCode: RequestResetStatusCodes.USER_DOES_NOT_EXIST }
            default:
                return { statusCode: RequestResetStatusCodes.UNKOWN }
        }
    } catch (err) {
        return { statusCode: RequestResetStatusCodes.UNKOWN }
    }
};

export const activateAccount = async (uid: string, token: string): Promise<number | null> => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };
    // Request Body
    const body = JSON.stringify({ uid: uid, token: token});
    console.log("request body " + body)
    try {
        const res = await axios.post(SERVER_URL + '/api/auth/activate', body, config);
        return res.status;
    } catch (err) {
        return null;
    }
};


export const resetPassword = async (args: ResetPasswordArgs): Promise<ResetPasswordApiResult> => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };
    // Request Body
    const body = JSON.stringify(args);
    try {
        const res = await axios.post(SERVER_URL + '/api/auth/reset_password', body, config);
        switch (res.data.status_code) {
            case ResetPasswordStatusCodes.SUCCESS.valueOf():
                return { statusCode: ResetPasswordStatusCodes.SUCCESS }
            default:
                return { statusCode: ResetPasswordStatusCodes.FAILURE }
        }
    } catch (err) {
        return { statusCode: ResetPasswordStatusCodes.FAILURE }
    }
};



export const handleGoogleLoginResponse = (response: any, createAccountIfNotExists: boolean): any => {
    console.log("google response : " + JSON.stringify(response));
    //console.log('google response full response : ' + JSON.stringify(response));
    try {
        let googlerep = googleLogin(response.tokenId, createAccountIfNotExists);
        console.log("handleGoogleLoginResponse : " + googlerep);
        return new Promise((resolve, reject) => {
            googlerep.then((result) => {
                resolve({ ...result, ...response.profileObj })
            })
        });
    } catch (e) {
        console.log('Caught exception ' + e)
    }
};

const googleLogin = async (tokenId: any, createAccountIfNotExists: boolean): Promise<AxiosResponse | null> => {
    try {
        const device_name = getDeviceName()
        const device_id = getClientUniqueId()
        let res: AxiosResponse = await axios.post(SERVER_URL + "/api/auth/google-login", {
            create: createAccountIfNotExists,
            id_token: tokenId,
            device_name: device_name,
            device_id: device_id,
            headers: { "Access-Control-Allow-Origin": "*" },
        }, {
            withCredentials: true
        });
        console.log(res);
        return res;
    } catch (e) {
        console.log("Caught " + e)
        return null;
    }
};


export const logout = async (): Promise<LogoutApiResult> => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };
    try {
        const res = await axios.post(SERVER_URL + '/api/auth/logout', {}, config);
        if (res.status === 200) {
            return { statusCode: LogoutStatusCodes.SUCCESS }
        } else {
            return { statusCode: LogoutStatusCodes.FAILURE }
        }
        console.log("Got a response " + JSON.stringify(res))
    } catch (err) {
        return { statusCode: LogoutStatusCodes.FAILURE }
    }
};


/**
 * Used to retrieve an auth token, that will allow the memento chrome extension to login with backend.
 * @returns 
 */
export const retrieveAuthToken = async (): Promise<string | null> => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true
    };
    try {
        const res = await axios.post(SERVER_URL + '/api/auth/get-token', {}, config);
        //console.log("Got token from server " + res.data.token)
        return res.data.token;
    } catch (err) {
        console.log("Caught an error while login" + JSON.stringify(err))
        return null;
    }
};


import React from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import Skeleton from "@mui/material/Skeleton";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: "flex",
      width: `calc(100% - 80px)`,
      padding: "0 40px",
    },
    col: {
      paddingLeft: "15px",
      backgroundClip: "padding-box",
      "& div": {
        marginBottom: "15px",
      },
    },
  })
);

const MainContainer = styled.div`
  margin: 117px 0 30px 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  @media (max-width: 576px) {
    margin: 80px 0 30px 0;
  }

  &:last-child {
    margin: 80px 0 30px 0;

    @media (max-width: 576px) {
      margin: 70px 0 30px 0;
    }
  }
`;

interface BreakPoints {
  default: number;
  1200: number;
  1024: number;
  768: number;
  576: number;
}

interface LoadingItemsProps {
  rowsCount?: number;
}

const LoadingItems = ({ rowsCount = 6 }: LoadingItemsProps) => {
  const BREAK_POINTS: BreakPoints = {
    default: rowsCount,
    1200: 4,
    1024: 3,
    768: 2,
    576: 1,
  };

  const classes = useStyles();
  return (
    <MainContainer>
      <Masonry
        breakpointCols={BREAK_POINTS}
        className={classes.grid}
        columnClassName={classes.col}
        style={{ paddingLeft: 80 }}
      >
        <div>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={40} />
        </div>
        <div>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={40} />
        </div>
        <div>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={40} />
        </div>
        <div>
          <Skeleton variant="rect" height={245} />
        </div>

        <div>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
        </div>
        <div>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={50} />
        </div>
        <div>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
        </div>
        <Skeleton variant="rectangular" height={150} />
        <div>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
        </div>
        <Skeleton variant="rectangular" height={150} />
        <Skeleton variant="rectangular" height={150} />
        <Skeleton variant="rectangular" height={150} />
        <Skeleton variant="rectangular" height={150} />
        <Skeleton variant="rectangular" height={150} />
        <Skeleton variant="rectangular" height={150} />
        <div>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Skeleton variant="text" height={30} />
        </div>
        <Skeleton variant="text" height={30} />
        <div style={{ marginTop: "12px" }}>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
        </div>
        <div style={{ marginTop: "12px" }}>
          <Skeleton variant="rectangular" height={138} />
          <Skeleton variant="text" height={30} />
        </div>
      </Masonry>
    </MainContainer>
  );
};

export default React.memo(LoadingItems);

import React, { useState, useCallback } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSelector, useDispatch } from "react-redux";
import { showConfidentialityFilter } from "store/actions/view";
import { saveConfidentialityFilterConfiguration } from "localstorage/localstorage";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import { useTranslation } from "react-i18next";
import { RootState } from "store/reducers";

const ConfidentialityFilterControl = () => {
  const showConfidentialityFilterState = useSelector(
    (state: RootState) => state.view.showConfidentialityFilter
  );
  const [selected, setSelected] = React.useState(
    showConfidentialityFilterState
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="top"
      title={styledTooltipTitle(
        !selected
          ? t("confidentiality_control_hide_hint")
          : t("confidentiality_control_show_hint")
      )}
    >
      <ToggleButton
        value="check"
        selected={selected}
        onChange={() => {
          saveConfidentialityFilterConfiguration(!selected);
          setSelected(!selected);
          dispatch(showConfidentialityFilter(!selected));
        }}
      >
        {!selected ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </ToggleButton>
    </Tooltip>
  );
};

export default React.memo(ConfidentialityFilterControl);

import { SUBSCRIPTION_FREE_PLAN, SUBSCRIPTION_MEMENTEE_PLAN, SUBSCRIPTION_MEMENTEE_PLUS_PLAN } from "configuration";


export enum UserAccessRight { //should be in sync with values from the backend
  READER_ACCESS_RIGHT = 0,
  MEMBER_ACCESS_RIGHT = 2,
  OWNER_ACCESS_RIGHT = 3,
}

export enum PaymentType { //should be in sync with values from the backend
  GOOGLE_PLAY_BILLING = 1,
  CARD_STRIPE = 2,
}

export interface SharedUserAccess {
  user: UserObj;
  right: number;
  userRegistered: boolean;
} 

export interface SharedUser {
  email: string;
  firstName: string;
  lastName: string;
} 

export interface ButtonItem {
  id: string,
  type: string, 
  url: string,
  title: string,
  imgUrl: string,
  background: string,
}

export interface PublicProfile {
  enabled: boolean;
  description: string;
  socialMediaUrls: string;
  presentationVideoUrl: string;
  background: string;
  uiItems: string;
} 

export interface UserObj {
    id: string,
    email: string,
    username: string,
    firstName: string,
    lastName: string,
    //avatarUrl: string,
    monthlyDataUsage: number,
    subscriptionPlan: SubscriptionPlan,
    dateJoined: string,
    lastLogin: string,
    userInitials: string,
    dataUsageMonth: number, // number from 1 to 12
}
  
export interface SubscriptionPlan {
  id : number,
  name: string,
  maxDataUsage: number,
  maxDataUsageAsString: string,
}

export interface SubscriptionInformation {
  subscriptionStart: number;//tmstamp
  lastPaymentDate: number;//tmstamp
  nextPaymentDate: number;//tmstamp
  isCancelled: boolean;
  paymentType: PaymentType;//can be Google play billing or card, values can be gpb
  paymentInformation: PaymentInformation | null;
}

export interface PaymentInformation {
  cardBrand: string;
  cardLast4Digits: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
}

export function isUserOnFreePlan(user: UserObj){
  return user.subscriptionPlan.id ==  SUBSCRIPTION_FREE_PLAN.id
}

export function isUserEntitledToAiAutoAggdataClassification(user: UserObj){
  return user.subscriptionPlan.id ==  SUBSCRIPTION_MEMENTEE_PLUS_PLAN.id
}

export function getRightAsString(right : number, translationHook : any){
  if (right == UserAccessRight.READER_ACCESS_RIGHT){
    return translationHook('shared_user_observer')
  } else if (right == UserAccessRight.MEMBER_ACCESS_RIGHT){
    return translationHook('shared_user_member')
  } else if (right == UserAccessRight.OWNER_ACCESS_RIGHT){
    return translationHook('owner_title')
  } else {
    return "NaN"
  }
} 


export function isMaxDataUsageReached(user: UserObj){
  const subscriptionId = user.subscriptionPlan.id
  if (subscriptionId == SUBSCRIPTION_FREE_PLAN.id){
    if (user.monthlyDataUsage > SUBSCRIPTION_FREE_PLAN.maxDataUsage){
      return true
    }
  } else if (subscriptionId == SUBSCRIPTION_MEMENTEE_PLAN.id){
    if (user.monthlyDataUsage > SUBSCRIPTION_MEMENTEE_PLAN.maxDataUsage){
      return true
    }
  }
  return false;
}
import React, { useCallback, useRef } from "react";

import Modal from "@mui/material/Modal";
import { Box, Fade, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { checkIfYoutubeVideoUrl, isUrlValid } from "shared/dataUtils";
import { updatePublicProfileGql } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/actions/view";
import YouTubeIcon from "@mui/icons-material/YouTube";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("mobile")]: {
        width: "90%",
      },
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "16px",
      flex: "1 1 0",
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
  })
);
interface ConfigureYoutubePresentationVideoModalProps {
  open: boolean;
  handleModalClose: (open: boolean) => void;
  presentationUrl: string;
}
const ConfigureYoutubePresentationVideoModal = ({
  open,
  handleModalClose,
  presentationUrl,
}: ConfigureYoutubePresentationVideoModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputFieldRef = React.useRef<HTMLInputElement | null>();
  const dispatch = useDispatch();
  const [updatePublicProfile, updatePublicProfileResult] = useMutation(
    updatePublicProfileGql
  );
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleSave = React.useCallback(() => {
    setErrorMessage(null)
    if (!inputFieldRef) {
      return;
    }
    if (inputFieldRef.current.value == presentationUrl) {
      handleModalClose(false);
      return;
    }
    const newUrl = inputFieldRef.current.value;

    if (!checkIfYoutubeVideoUrl(newUrl)) {
      setErrorMessage(t("not_youtube_video_url")); //FIX
      return;
    }
    if (inputFieldRef.current) {
      updatePublicProfile({
        variables: {
          input: {
            presentationVideoUrl: newUrl,
          },
        },
      })
        .then((result) => {
          handleModalClose(false);
        })
        .catch((e) => {
          dispatch(
            showSnackbar({
              message: t("generic_failure_msg"),
              action: "",
              onAction: null,
              loading: false,
            })
          );
        });
    }
  }, [inputFieldRef, inputFieldRef.current]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        onClick={(e) => {
          e.stopPropagation(); //useful because for some reason when you click outside to close the modal the click still propagates to components underneath
        }}
      >
        <Fade in={open}>
          <div
            className={classes.paper}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "1.6rem",
                marginBottom: "8px",
              }}
            >
              {t("add_youtube_presentation_video_modal_title")}
            </Typography>

            <TextField
              inputRef={inputFieldRef}
              variant="outlined"
              fullWidth
              className={classes.inputLabel}
              error={Boolean(errorMessage)}
              helperText={errorMessage}
              defaultValue={presentationUrl}
              onChange={(e) => {
                // setPreviewImageUrl(e.target.value);
                /*  let value = e.target.value.trim();
                if (!isUrlValid(value) && value.length > 0) {
                  setErrorMessage(t("url_not_valid_error"));
                } else {
                  setErrorMessage(null);
                }
                onInputChanged(e.target.value); */
              }}
              //defaultValue={aggdata.annotation}
              color="primary"
              inputProps={{
                style: {
                  fontSize: "large",
                  height: "100%",
                  lineHeight: "normal",
                },
              }}
              InputProps={{
                startAdornment: (
                  <Box sx={{ marginRight: "8px" }}>
                    <YouTubeIcon />
                  </Box>
                ),
              }}
              InputLabelProps={{
                style: { fontSize: "medium" },
              }}
            />
            <LoadingButton
              variant="contained"
              sx={{
                marginTop: "8px",
              }}
              loading={updatePublicProfileResult.loading}
              onClick={handleSave}
            >
              {t("save")}
            </LoadingButton>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(ConfigureYoutubePresentationVideoModal);

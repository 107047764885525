import React, { useCallback, useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import LogoIcon from "icons/ic_memento_m_letter.svg";
import EmailSentIllustration from "illustrations/undraw_mail_sent.svg";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { requestResetPassword } from "api/authApi";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";

import { useTranslation } from "react-i18next";
import {
  RequestResetPasswordApiResult,
  RequestResetStatusCodes,
} from "api/authApi";
import { useParams } from "react-router-dom";
import { colorMementoBeige, colorMemento } from "shared/colors";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from "shared/utility";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(
    90deg,
    rgba(255, 241, 206, 1) 0%,
    rgba(255, 253, 250, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  padding-top: 5%;
  overflow-y: hidden;
`;

interface RequestResetFormData {
  email: string;
}

interface SignUpResult {
  signupFailed: boolean;
  errorMsg: string;
}

const RequestResetPassword: React.FC = () => {
  const [requestResetSuccess, setRequestResetSuccess] = useState(false);
  const [signupStatus, setSignupStatus] = useState<SignUpResult>({
    signupFailed: false,
    errorMsg: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    console.log("Component just mounted !");
    return () => {
      console.log("Cleanup from login");
    };
  }, []); // <-- empty dependency array

  const handleSubmit = useCallback(
    async (data: RequestResetFormData) => {
      try {
        setIsLoading(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email(t("enter_valid_email_error"))
            .required(t("email_required_error")),
        });

        await schema.validate(data, { abortEarly: false });
        requestResetPassword(data.email).then(
          (result: RequestResetPasswordApiResult) => {
            switch (result.statusCode) {
              case RequestResetStatusCodes.USER_DOES_NOT_EXIST.valueOf():
                setRequestResetFailed(t("reset_password_email_does_not_exist"));
                break;
              case RequestResetStatusCodes.UNKOWN.valueOf():
                setRequestResetFailed(t("reset_password_email_failed_unknown"));
                break;
              case RequestResetStatusCodes.SUCCESS.valueOf():
                setRequestResetSuccess(true);
                break;
            }
          }
        );
      } catch (err) {
        setIsLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [history]
  );

  const setRequestResetFailed = useCallback(
    (errorMsgArg: string) => {
      setSignupStatus({ signupFailed: true, errorMsg: errorMsgArg });
      setIsLoading(false);
    },
    [signupStatus]
  );

  let params: any = useParams();

  if (requestResetSuccess) {
    return (
      <MainContainer>
        <Helmet>
          <title>
            {capitalizeFirstLetter(t("reset_password_title"))} | Memento
          </title>
        </Helmet>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            background: "#f8fbf8",
            borderRadius: "8px",
            minWidth: "30%",
            "@media (max-width: 780px)": {
              width: "80%",
            },
          }}
          elevation={1}
        >
          <Link to="/">
            <img
              id="logo"
              width="24px"
              height="24px"
              src={LogoIcon}
              alt="Memento logo"
            />
          </Link>
          <img
            id="logo"
            width="196px"
            height="196px"
            style={{ marginTop: "36px", marginBottom: "36px" }}
            src={EmailSentIllustration}
            alt="Memento logo"
          />
          <Typography
            sx={{
              marginBottom: "24px",
              fontWeight: "300",
              textAlign: "center",
            }}
            variant="h4"
          >
            {t("request_reset_password_success_msg")}
          </Typography>
        </Paper>
      </MainContainer>
    );
  } else
    return (
      <MainContainer>
        <Helmet>
          <title>
            {capitalizeFirstLetter(t("reset_password_title"))} | Memento
          </title>
        </Helmet>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            background: "#f8fbf8",
            borderRadius: "8px",
            minWidth: "30%",
            "@media (max-width: 780px)": {
              width: "80%",
            },
          }}
          elevation={1}
        >
          {signupStatus.signupFailed && (
            <Alert
              style={{ width: "80%", fontSize: "1.4rem" }}
              id="error-alert"
              severity="error"
            >
              {signupStatus.errorMsg}
            </Alert>
          )}
          <Link to="/">
            <img
              id="logo"
              width="82px"
              height="82px"
              src={LogoIcon}
              alt="Memento logo"
            />
          </Link>
          <Typography sx={{ marginBottom: "24px" }} variant="h4">
            {t("reset_password_title")}
          </Typography>
          <Form
            style={{ alignItems: "center", width: "80%" }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Input
              name="email"
              type="text"
              placeholder={t("reset_password_email_hint")}
            />
            <Typography
              sx={{ marginTop: "12px", fontWeight: "300", textAlign: "center" }}
              variant="h6"
              color="text.body"
            >
              {t("reset_password_helper_text")}
            </Typography>
            <Button type="submit" loading={isLoading}>
              {t("reset_password_action")}
            </Button>
          </Form>
        </Paper>
      </MainContainer>
    );
};

export default RequestResetPassword;

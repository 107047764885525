import { Box, Typography } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { colorMemento, colorMementoBeige } from "shared/colors";

interface ExpandableTextProps {
  text?: string | null | undefined;
}

const ExpandableText = ({ text }: ExpandableTextProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const { t } = useTranslation();
  const handleExpandClick = React.useCallback(
    (e: any) => {
      e.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  if (!text) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        paddingBottom: "8px",
        paddingRight: "4px",
      }}
    >
      <div
        style={{
          marginLeft: "8px",
          width: "2px",
          backgroundColor: colorMemento,
        }}
      />
      <Typography
        sx={{
          width: "100%",
          marginLeft: "8px",
          fontSize: "1.3rem",
        }}
      >
        {!isExpanded && (
          <>
            {text.slice(0, 300).trim()}
            {/* Display the first 100 characters, adjust as needed */}
            {text.length > 300 && (
              <span
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={handleExpandClick}
              >
                ...{t('expand_title')}
              </span>
            )}
          </>
        )}
        {isExpanded && (
          <>
            {text}
            <span
              style={{
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={handleExpandClick}
            >
             {" "+t('collapse_title')}
            </span>
          </>
        )}
      </Typography>
    </Box>
  );
};

export default React.memo(ExpandableText);

import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import UpgradeIllustration from "illustrations/upgrade_plan_illustration.svg";
import { useHistory } from "react-router-dom";
import { PRICING } from "navigation/Constants";

interface MaxDataUsageLimitReachedDialogProps {
  open: boolean;
  description?: string;
  handleModalClose: () => void;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("mobile")]: {
        width: "80%",
      },
    },
    addButton: {
      marginEnd: "12px",
      width:"40%"
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "24px",
    },
  })
);



export default function MaxDataUsageLimitReachedDialog({
  open,
  description,
  handleModalClose,
}: MaxDataUsageLimitReachedDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();


  const handleUpgradeButtonClicked= React.useCallback(() => {
    history.push(PRICING)
    handleModalClose()
  }, [handleModalClose])
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose()}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Title>{t('max_data_usage_reached_dialog_title')}</Title>
            <img
              style={{ maxHeight: "40vh", width: "100%" }}
              src={UpgradeIllustration} 
            />
            <Typography variant="h4" sx={{fontSize: "2rem", textAlign: "center", marginTop: "12px"}}>{description ? description : t('max_data_usage_reached_dialog_description')}</Typography>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.addButton}
                color="primary"
                variant="outlined"
                onClick={handleUpgradeButtonClicked}
              >
                <Typography sx={{fontWeight: "400"}} variant="h6">{t("explore_paid_plan_msg")}</Typography>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { PAYMENT } from "navigation/Constants";
import SimpleHeader from "components/Header/SimpleHeader";
import { fetchUserData } from "api/authApi";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/index";
import { UserObj } from "models/user";
import { useDispatch } from "react-redux";
import { loginUser } from "store/actions/authActions";
import { colorMemento } from "shared/colors";
import { useTranslation } from "react-i18next";
import { fetchUserCurrency } from "api/ipapi";
import { setUserCurrency, getUserCurrency } from "localstorage/localstorage";
import { getPriceInLocalCurrency, getCurrencySymbol } from "shared/currencies";
import { DEFAULT_CURRENCY } from "configuration";
import { logoutUser } from "store/actions/authActions";
import { MAX_SHARED_COLLECTIONS_COUNT_FREE_USER } from "configuration";
import {
  SUBSCRIPTION_MEMENTEE_PLAN,
  SUBSCRIPTION_MEMENTEE_PLUS_PLAN,
} from "configuration";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";
import NotionIcon from "icons/notion_icon.svg";
import PinterestIcon from "icons/pinterest_icon.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chooseButton: {
      fontSize: "1.5rem",
      fontWeight: "400",
      "& .MuiButtonBase-root": {
        fontSize: "1.5rem",
        fontWeight: "400",
      },
    },
  })
);

function PricingCards() {
  const theme = useTheme();
  const classes = useStyles();

  const { t } = useTranslation();
  const [localCurrency, setLocalCurrency] = React.useState(
    getUserCurrency() ? getUserCurrency() : DEFAULT_CURRENCY
  );
  const [
    displayMementeePlusOnlyMobileDialog,
    setDisplayMementeePlusOnlyMobileDialog,
  ] = React.useState(false);
  const dispatch = useDispatch();
  const user: UserObj = useSelector((state: RootState) => state.auth.user);
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );

  // "tier_mementee_description_1": "Synchronisez avec un nombre illimité d'appareils Android",
  // "tier_mementee_description_5": "Accès à l'application web et accès hors-ligne à l'application mobile",
  // "tier_mementee_description_2": "10Go de données par mois",
  // "tier_mementee_description_3": "Aucune pub",
  // "tier_mementee_description_4": "Accès à toutes les fonctionnalités de l'offre gratuite",
  const tiers = [
    {
      id: 0,
      title: t("tier_free_title"),
      price: "0",
      description: [
       // t("tier_free_description_1"),
        t("tier_free_description_5"),
        t("tier_free_description_2"),
        t("tier_free_description_6"),
        t("tier_free_description_10"),
        t("tier_free_description_8", {
          max_shared_collections_count: MAX_SHARED_COLLECTIONS_COUNT_FREE_USER,
        }),
        t("tier_free_description_9"),
      //  t("tier_free_description_3"),
      ],
      buttonText: t("tier_free_button_text"),
      buttonVariant: "outlined",
    },
    {
      id: 1,
      title: t("tier_mementee_title"),
      price: getPriceInLocalCurrency(
        localCurrency,
        SUBSCRIPTION_MEMENTEE_PLAN.id
      ),
      description: [
        t("tier_mementee_description_4"),
      //  t("tier_mementee_description_1"),
        t("tier_mementee_description_2"),
        t("tier_mementee_description_7"),
        t("tier_mementee_description_3"),
        t("tier_mementee_description_10"),
        t("tier_mementee_description_11"),
      ],
      buttonText: t("tier_mementee_button_text"),
      buttonVariant: "contained",
    },
    {
      id: 2,
      title: t("tier_mementee_plus_title"),
      price: getPriceInLocalCurrency(
        localCurrency,
        SUBSCRIPTION_MEMENTEE_PLUS_PLAN.id
      ),
      description: [
        t("tier_mementee_plus_description_1"),
        t("tier_mementee_plus_description_2"),
        t("tier_mementee_plus_description_6"),
        t("tier_mementee_plus_description_11"),
        t("tier_mementee_plus_description_3"),
        t("tier_mementee_plus_description_4"),
        t("tier_mementee_plus_description_5"),
        t("tier_mementee_plus_description_10"),
      ],
      buttonText: t("tier_mementee_button_text"),
      buttonVariant: "contained",
    },
  ];

  const history = useHistory();

  React.useEffect(() => {
    const userCurrencyFromLocal = getUserCurrency();
    if (!userCurrencyFromLocal) {
      fetchUserCurrency().then((data) => {
        setUserCurrency(data.result);
        setLocalCurrency(data.result);
      });
    }
  }, []);

  React.useEffect(() => {
    if (!user && isUserLoggedIn) {
      fetchUserData()
        .then((user: UserObj) => {
          dispatch(loginUser(user));
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    }
  }, [user]);

  const handleButtonClick = (tierId: number) => {
    if (tierId == SUBSCRIPTION_MEMENTEE_PLUS_PLAN.id) {
      setDisplayMementeePlusOnlyMobileDialog(true);
    } else {
      history.push(PAYMENT);
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          height: "100%",
          flexDirection: "column",
        }}
      >
        {/* End hero unit */}
        <Container maxWidth="lg" component="main">
          <Grid
            container
            spacing={6}
            alignItems="stretch"
            direction="row"
            justifyContent="center"
          >
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 4}
                md={4}
              >
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    background: "transparent",
                    border: "2px dotted",
                    borderColor: "black",
                  }}
                >
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{
                      fontSize: "2.6rem",
                      align: "center",
                      color: "grey",
                    }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                      fontSize: "1.7rem",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? "transparent" //#faf6e9
                          : theme.palette.primary.main,
                    }}
                  ></CardHeader>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "24px",
                      paddingTop: "24px",
                      alignItems: "end",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      sx={{
                        color: "black",
                      }}
                    >
                      {tier.price}
                      {getCurrencySymbol(localCurrency)}
                    </Typography>
                    <Typography sx={{}} variant="h6" color="text.secondary">
                      {t("month_title")}
                    </Typography>
                  </Box>
                  <CardActions
                    sx={{
                      width: "100%",
                      display: "flex",

                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!(isUserLoggedIn && tier.id == 0) && (
                      <Button
                        className={classes.chooseButton}
                        size="large"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "500",
                          width: "80%",
                          color:
                            tier.buttonVariant == "outlined"
                              ? "black"
                              : "black",
                        }}
                        onClick={() => handleButtonClick(tier.id)}
                        variant={"contained"}
                      >
                        {tier.buttonText}
                      </Button>
                    )}
                  </CardActions>
                  <CardContent>
                    <ul>
                      {tier.description.map((line) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "24px",
                          }}
                        >
                          <CheckIcon
                            style={{
                              width: "1.6rem",
                              height: "1.6rem",
                              color: "green",
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            variant="h5"
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              marginLeft: "6px",
                              fontSize: "1.6rem",
                              fontFamily: "Trebuchet MS, sans-serif",
                            }}
                            key={line}
                          >
                            {line}
                            {t("tier_mementee_description_10") == line && (
                              <img
                                src={NotionIcon}
                                width={"28px"}
                                height="28px"
                                style={{
                                  paddingLeft: "8px",
                                }}
                              />
                            )}
                            {t("tier_mementee_description_11") == line && (
                              <img
                               src={PinterestIcon}
                               width="20px"
                               height="20px"
                               style={{
                                 marginLeft: "4px"
                               }}
                             />
                            )}
                          </Typography>
                        </div>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            marginTop: "12px",
            fontStyle: "italic",
          }}
        >
          {t("plan_asterisk_only_for_mobile")}
        </Typography>
        <SimpleConfirmationDialog
          open={displayMementeePlusOnlyMobileDialog}
          title={t("subscribe_through_mobile_memplus_msg_title")}
          description={t("subscribe_through_mobile_memplus_msg_description")}
          hideCancel={true}
          handleOk={() => {
            setDisplayMementeePlusOnlyMobileDialog(false);
          }}
          handleModalClose={(close: boolean) => {
            setDisplayMementeePlusOnlyMobileDialog(false);
          }}
        ></SimpleConfirmationDialog>
      </div>
    </>
  );
}

export default React.memo(PricingCards);

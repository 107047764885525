import React, { useState, useCallback } from "react";
import styled from "styled-components";
import update from "immutability-helper";

import NoteTaskItem from "containers/AggData/NoteEditor/NoteTaskItem";
import Draggable from "components/Draggable/Draggable";
import { convertTodoToNote } from "shared/utility";
import { Dispatcher, TodoItemID, NoteTask } from "shared/types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { editorScrollBarStyle } from "shared/styles";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import InputAdornment from "@mui/material/InputAdornment";
import uniqid from "uniqid";
import { useTranslation } from "react-i18next";
import useEffect from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface NoteTasksListProp {
  noteTasksArray: NoteTask[];
  onNoteTasksChange?: (noteTask: NoteTask[]) => void;
  onTaskAdded?: (noteTask: NoteTask) => void;
  readOnly?: boolean;
}

const useStyles = makeStyles((theme) => ({
  checkBoxText: {
    "& .MuiTypography-root": {
      fontSize: "1.8rem",
    },
    "& .MuiInputBase-input": {
      fontSize: "1.8rem",
    },
  },
  doneCheckboxText: {
    "& .MuiTypography-root": {
      fontSize: "1.8rem",
      textDecoration: "line-through",
    },
    "& .MuiInputBase-input": {
      fontSize: "1.8rem",
      textDecoration: "line-through",
    },
  },
}));

const NoteTasksList = ({
  noteTasksArray,
  onNoteTasksChange,
  readOnly = false,
}: NoteTasksListProp) => {
  const [noteTasks, setNoteTasks] = useState<NoteTask[]>(noteTasksArray);
  const classes = useStyles();
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = React.useState(false);

  React.useEffect(() => {
    setNoteTasks(noteTasksArray);
  }, [noteTasksArray]);

  const todoNoteTasks: NoteTask[] =
    noteTasks && noteTasks.filter((noteTask) => !noteTask.done);
  //todoNoteTasks.push({ id: "-1" });
  //console.log("Todo tasks " + JSON.stringify(todoNoteTasks));
  const doneNoteTasks: NoteTask[] =
    noteTasks && noteTasks.filter((noteTask) => noteTask.done);
  //console.log("Done todo tasks " + JSON.stringify(doneNoteTasks));

  /**
   * Handles moving an item during drag and drop.
   */
  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number): void => {
      const dragNoteTask: NoteTask = noteTasks[dragIndex];
      if (dragNoteTask.done) {
        return;
      }
      const updatedArray = update(noteTasks, {
        $splice: [
          [dragIndex, 1], // Delete the item item at the drag start index
          [hoverIndex, 0, dragNoteTask], // Add the item where it's being dragged by the user.
        ],
      });
      setNoteTasks(updatedArray);
      //onNoteTasksChange && onNoteTasksChange(updatedArray);
    },
    [noteTasks, onNoteTasksChange, setNoteTasks]
  );

  const handleDrop = useCallback((): void => {
    onNoteTasksChange && onNoteTasksChange(noteTasks);
  }, [noteTasks, onNoteTasksChange, setNoteTasks]);

  const handleDelete = useCallback(
    (noteTask: NoteTask) => {
      console.log("delete " + JSON.stringify(noteTask));
      const mutableNoteTasks = [...noteTasks];
      const indexToDelete = mutableNoteTasks.indexOf(noteTask);
      if (indexToDelete > -1) {
        mutableNoteTasks.splice(indexToDelete, 1);
        setNoteTasks(mutableNoteTasks);
      } else {
        console.log("Note tasks, wrong index! item not found");
      }
      onNoteTasksChange && onNoteTasksChange(mutableNoteTasks);
    },
    [noteTasks, onNoteTasksChange]
  );

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  /**
   * Handles check/uncheck of a checkbox
   * When a checkbox is getting checked, it is placed at the bottom of the list.
   * When a checkbox is getting unchecked, it is placed at the top of the list.
   * Be aware that if this behavior is changed, it might impact drag and drop implementation. The current indexing
   * logic for dnd only works for lists order by boolean attribute "done".
   * @param value
   */
  const handleToggle = (value: NoteTask) => {
    value.done = !value.done;
    var updatedNoteTasksArray;
    if (value.done) {
      updatedNoteTasksArray = update(noteTasks, {
        $splice: [
          [noteTasks.indexOf(value), 1], // Delete
          [noteTasks.length - 1, 0, value], // Add
        ],
      });
      setNoteTasks(updatedNoteTasksArray);
    } else {
      updatedNoteTasksArray = update(noteTasks, {
        $splice: [
          [noteTasks.indexOf(value), 1], // Delete
          [0, 0, value], // Add
        ],
      });
      setNoteTasks(updatedNoteTasksArray);
    }
    onNoteTasksChange && onNoteTasksChange(updatedNoteTasksArray);
    forceUpdate();
  };

  const handleAddNoteTask = useCallback(
    (taskTitle: string, index?: number) => {
      const newTask = {
        task: taskTitle,
        done: false,
        hasFocus: true,
      };
      const newTaskList = [...noteTasks];
      if (index == undefined) {
        newTaskList.splice(0, 0, newTask);
      } else {
        newTaskList.splice(index, 0, newTask);
      }
      
      setNoteTasks(
        newTaskList.sort((item1: NoteTask, item2: NoteTask) => {
          return item1.done === item2.done ? 0 : item1.done ? 1 : -1;
        })
      );
    },
    [noteTasks, onNoteTasksChange, setNoteTasks]
  );

  const handleNoteTaskContentChanged = useCallback(() => {
    onNoteTasksChange && onNoteTasksChange(noteTasks);
  }, [noteTasks, onNoteTasksChange]);

  /**
   * Called when there is an Enter press key done on one of the note tasks.
   */
  const handleAddNewTask = useCallback(
    (noteTask?: NoteTask) => {
      if (noteTask) {
        const taskIndex = noteTasks.indexOf(noteTask);
        if (taskIndex > -1) {
          handleAddNoteTask("", taskIndex + 1);
        }
      }
    },
    [noteTasks]
  );

  return (
    <>
      <List
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        sx={{
          width: "100%",
          bgcolor: "transparent",
          maxHeight: readOnly ? "70vh" : "none",
          overflowY: readOnly ? "scroll" : "none",
          ...editorScrollBarStyle,
          "&::-webkit-scrollbar": {
            width: readOnly ? (isHovered ? 4 : 0) : 0,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px #faf6e9`,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#bdbdbd",
            borderRadius: "4px",
          },
          ".MuiAccordion-root:before": {
            //to remove weird line on top of accordiion when closed.
            background: "transparent",
            backgroundColor: "transparent",
            border: "none",
            elevation: 0,
          },
        }}
      >
        {todoNoteTasks.map((value, index) => {
          if (!value.id) {
            value.id = uniqid();
          }
          return (
            <Draggable
              handleMove={handleMove}
              handleDrop={handleDrop}
              index={index}
              id={value.id}
              key={value.id}
            >
              <NoteTaskItem
                todo={value}
                onCheck={handleToggle}
                onDelete={handleDelete}
                inputFocus={value.hasFocus}
                readOnly={readOnly}
                onTaskContentChanged={handleNoteTaskContentChanged}
                onAddNewTask={handleAddNewTask}
              />
            </Draggable>
          );
        })}

        {!readOnly && (
          <ListItem key="-1" disablePadding>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "8px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ fontSize: "1.2rem" }}
                placeholder={t("add_new_note_task")}
                value=""
                className={classes.checkBoxText}
                onChange={(e) => {
                  handleAddNoteTask(e.target.value);
                  e.preventDefault();
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddIcon sx={{ width: "24px", height: "24px" }} />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </div>
          </ListItem>
        )}
        {doneNoteTasks && doneNoteTasks.length > 0 && (
          <Accordion
            sx={{
              border: "none",
              boxShadow: "none",
              background: "transparent",
              backgroundColor: "transparent",
              ".MuiPaper-root": {
                background: "transparent",
                backgroundColor: "transparent",
                border: "none",
                elevation: 0,
              },

              ".MuiAccordion-root:before": {
                background: "transparent",
                border: "none",
                elevation: 0,
              },
            }}
            TransitionProps={{ unmountOnExit: true }} 
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
              sx={{
                borderWidth: 0,
                border: "none",
                background: "transparent",
                backgroundColor: "transparent",
              }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="h5"
                sx={{ fontStyle: "italic", opacity: 0.6 }}
              >
                {doneNoteTasks.length}{" "}
                {doneNoteTasks.length == 1
                  ? t("done_task_title_singular")
                  : t("done_task_title_plural")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {doneNoteTasks.map((value, index) => {
                  const key = uniqid();
                  return (
                   
                      <NoteTaskItem
                        key={key} 
                        todo={value}
                        onDelete={handleDelete}
                        onTaskContentChanged={handleNoteTaskContentChanged}
                        onCheck={handleToggle}
                        readOnly={readOnly}
                      />
                  );
                })}
            </AccordionDetails>
          </Accordion>
        )}
      </List>
    </>
  );
};

export default NoteTasksList;

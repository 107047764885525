import * as React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { getDateAndTimeFromIso8601 } from "shared/dataUtils";
import { colorMemento, colorHyperlink } from "shared/colors";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import {
  fetchAuthUrl,
  authenticateUser,
  fetchIntegrationInformation,
  fetchNotionCollections,
  disconnectCurrentIntegration,
  connectCollection,
  disConnectCollection,
  NotionIntegrationInformation,
  ConnectCollectionResult,
  AuthenticateUserResponse,
  NotionCollectionsInfo,
} from "api/notionIntegrationApi";
import { DEBUG, getNotionDatabaseUrl } from "configuration";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { CardActions } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SelectCollectionContainer from "containers/SelectCollectionContainer/SelectCollectionContainer";
import { CollectionObj } from "models/collection";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ImagePreview from "components/CollectionItems/ImagePreview";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { scrollBarStyle } from "shared/styles";
import { getCollectionUrl } from "configuration";
import MaxDataUsageLimitReachedDialog from "components/UI/Modals/MaxDataUsageLimitReachedDialog";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { isUserOnFreePlan } from "models/user";
import { useHistory } from "react-router-dom";
import { NOTION_INTEGRATION_SETTINGS_WITH_SUBPATH } from "navigation/Constants";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    paddingLeft: "36px",
    flexDirection: "column",
    width: "50%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  boxSectionContiner: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      fontSize: "1.4rem",
    },
  },
  tableHeader: {
    "& .MuiTableCell-root": {
      fontSize: "1.4rem",
    },
  },
}));

interface SimpleConfirmationPayload {
  hideCancel: boolean;
  title: string;
  description: string;
  handleOk: () => void;
  handleCancel: () => void;
  handleClose: (close: boolean) => void;
}

interface NotionCollectionsInfoUiItem {
  collectionInfo: NotionCollectionsInfo;
  isLoading: boolean;
}
//When the user adds a collection to sync with notion, we display a loading message while the operation is ongoing, it can take sometime
interface CollectionSyncLoadingHolder {
  id: string;
  name: string; //collection name
  previewUrls: string[] | null;
  error: boolean | undefined;
}

export default function Integrations() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [externalPopup, setExternalPopup] = React.useState<any>(null);
  const [displayUpgradePlanDialog, setDisplayUpgradePlanDialog] =
    React.useState(false);
  const [connectedCollections, setConnectedCollections] = React.useState<
    NotionCollectionsInfoUiItem[]
  >([]);
  const user = useSelector((state: RootState) => state.auth.user);

  const [collectionsSyncOngoing, setCollectionsSyncOngoing] = React.useState<
    CollectionSyncLoadingHolder[]
  >([]); //{ id: "lkjsfqd", name: "blabla", previewUrls: null }
  const [openSelectCollectionContainer, setOpenSelectCollectionContainer] =
    React.useState(false);
  const [loadingNotionInfo, setLoadingNotionInfo] = React.useState(true);
  const [
    fetchNotionIntegrationInfoFailed,
    setFetchNotionIntegrationInfoFailed,
  ] = React.useState(false);
  const [notionIntegrationInfo, setNotionIntegrationInfo] =
    React.useState<NotionIntegrationInformation | null>(null);
  const [displaySimpleConfirmation, setDisplaySimpleConfirmation] =
    React.useState<SimpleConfirmationPayload | null>(null);
  const history = useHistory();

  //in some cases like on mobile, the redirection is not done on a popup, the browser opens a new tab for the  auth callback URL.
  //We built a component that gets the code from there and redirect to here.
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");

  React.useEffect(() => {
    if (code && user && code.length > 0) {
      handleAuthenticateUser(code);
    }
  }, [code, user]);

  React.useEffect(() => {
    fetchIntegrationInformation()
      .then((info: NotionIntegrationInformation) => {
        setLoadingNotionInfo(false);
        setFetchNotionIntegrationInfoFailed(false);
        setNotionIntegrationInfo(info);
      })
      .catch((e) => {
        console.log("fetchIntegrationInformation: caught exception %o", e);
        setLoadingNotionInfo(false);
        setFetchNotionIntegrationInfoFailed(true);
      });
    fetchNotionCollections()
      .then((result: NotionCollectionsInfo[] | null) => {
        if (DEBUG) {
          console.log(
            "fetchNotionCollections Here is the result  " +
              JSON.stringify(result)
          );
        }

        if (result) {
          setConnectedCollections(
            result.map((collectionInfo: NotionCollectionsInfo) => {
              return {
                collectionInfo: collectionInfo,
                isLoading: false,
              };
            })
          );
        }
      })
      .catch((e) => {
        if (DEBUG) {
          console.log("fetchNotionCollections: Caught an error %o", e);
        }
      });
  }, []);

  const handleIntegrateButtonClick = React.useCallback(() => {
    if (isUserOnFreePlan(user)) {
      setDisplayUpgradePlanDialog(true);
      return;
    }
    fetchAuthUrl()
      .then((url: string) => {
        var popupWidth = 500;
        var popupHeight = 600;
        var left = window.screenX + (window.outerWidth - popupWidth) / 2;
        var top = window.screenY + (window.outerHeight - popupHeight) / 2.5;
        const title = `Auth`;
        const popup = window.open(
          url,
          title,
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
        );
        setExternalPopup(popup);
      })
      .catch((e) => {
        if (DEBUG) {
          console.log("Caucght an error while fetching auth url");
        }
      });
  }, [setExternalPopup, externalPopup, user]);

  const handleDisconnect = React.useCallback(() => {
    setDisplaySimpleConfirmation({
      title: t("diconnect_notion_account_confirmation_dialog_tilte"),
      description: t(
        "diconnect_notion_account_confirmation_dialog_description"
      ),
      hideCancel: false,
      handleOk: () => {
        setDisplaySimpleConfirmation(null);
        setLoadingNotionInfo(true);
        disconnectCurrentIntegration()
          .then((result: boolean) => {
            setLoadingNotionInfo(false);
            if (result) {
              setNotionIntegrationInfo({
                isConnected: false,
                workspaceIconUrl: null,
                workspaceName: null,
                rootPageName: null,
                rootPageUrl: null,
              });
              setConnectedCollections([]);
            } else {
              if (DEBUG) {
                console.log("Failed to disconnect from notion!");
              }
            }
          })
          .catch((e) => {
            setLoadingNotionInfo(false);
          });
      },
      handleCancel: () => {
        setDisplaySimpleConfirmation(null);
      },
      handleClose: (close: boolean) => {
        setDisplaySimpleConfirmation(null);
      },
    });
  }, [
    connectedCollections,
    setConnectedCollections,
    setDisplaySimpleConfirmation,
    setLoadingNotionInfo,
    setNotionIntegrationInfo,
  ]);

  const handleAuthenticateUser = React.useCallback(
    (code: string) => {
      setLoadingNotionInfo(true);
      authenticateUser(code)
        .then((authUserResponse: AuthenticateUserResponse) => {
          if (DEBUG) {
            console.log("handleAuthenticateUser: auth ok!");
          }
          setLoadingNotionInfo(false);
          if (authUserResponse.resultCode == 1) {
            setDisplaySimpleConfirmation({
              title: t("notion_access_to_page_required_dialog_title"),
              description: t(
                "notion_access_to_page_required_dialog_description"
              ),
              hideCancel: false,
              handleOk: () => {
                setDisplaySimpleConfirmation(null);
              },
              handleCancel: () => {
                setDisplaySimpleConfirmation(null);
              },
              handleClose: (close: boolean) => {
                setDisplaySimpleConfirmation(null);
              },
            });
          } else if (authUserResponse.resultCode == 0) {
            setNotionIntegrationInfo(authUserResponse.integrationInfo);
          }
          if (code) {
            //we redirect excluding the search params to avoid unexpected behavior in case the user refreshes
            history.replace(NOTION_INTEGRATION_SETTINGS_WITH_SUBPATH);
          }
        })
        .catch((e) => {
          if (DEBUG) {
            console.log("handleAuthenticateUser: failed %o", e);
          }
        });
    },
    [setLoadingNotionInfo, code]
  );

  //We monitor on the external popup that we got a response code
  React.useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("code");
        if (code) {
          externalPopup.close();
          if (DEBUG) {
            console.log(`The popup URL has URL code param = ${code}`);
          }
          handleAuthenticateUser(code);
          // YourApi.endpoint(code).then(() => {
          //   // change UI to show after the code was stored
          // })
          //   .catch(() => {
          //     // API error
          //   })
          //   .finally(() => {
          //     // clear timer at the end
          //     setExternalPopup(null);
          //     timer && clearInterval(timer);
          //   })
        }
      } catch (e) {
        console.log("Caught an exception! %o", e);
      }
    }, 500);
  }, [externalPopup]);

  const getIntegrationStatusComponents = React.useCallback(() => {
    if (loadingNotionInfo) {
      return (
        <Card sx={{ display: "flex", marginTop: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <CardContent sx={{ flex: "1 0 auto", width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Skeleton height="36px" width="20%" />

                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "20%" }}
                >
                  <Skeleton height="36px" width="80%" />
                  <Skeleton height="36px" width="24px" />
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                <Skeleton height="36px" width="20%" />
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "20%" }}
                >
                  <Skeleton height="36px" width="100%" />
                </Box>
              </Box>
            </CardContent>
          </Box>
        </Card>
      );
    } else if (notionIntegrationInfo) {
      if (notionIntegrationInfo.isConnected) {
        return (
          <Card sx={{ display: "flex", marginTop: "16px" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <CardContent sx={{ flex: "1 0 auto", width: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography component="div" variant="h5">
                    {t("notion_connected_to_title")}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{
                        marginRight: "8px",
                      }}
                    >
                      {notionIntegrationInfo.workspaceName}
                    </Typography>
                    <img
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "10%",
                      }}
                      src={notionIntegrationInfo.workspaceIconUrl}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "16px",
                  }}
                >
                  <Typography component="div" variant="h5">
                    {t("notion_access_to_page_title")}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{
                        marginRight: "8px",
                      }}
                    >
                      <Link
                        style={{
                          color: "black",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          window.open(
                            notionIntegrationInfo?.rootPageUrl,
                            "_blank"
                          );
                        }}
                      >
                        {notionIntegrationInfo.rootPageName}
                      </Link>
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{ width: "100%", display: "flex", justifyContent: "end" }}
                ></Box>
              </CardContent>
              <CardActions
                sx={{ width: "100%", display: "flex", justifyContent: "end" }}
              >
                <Tooltip
                  title={
                    <Typography variant="h5">{t("edit_action")}</Typography>
                  }
                >
                  <IconButton onClick={handleIntegrateButtonClick}>
                    <EditIcon
                      sx={{
                        width: "1.6rem",
                        height: "1.6rem",
                        // color: colorMemento,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <Typography variant="h5">
                      {t("disconnect_action")}
                    </Typography>
                  }
                >
                  <IconButton onClick={handleDisconnect}>
                    <HighlightOffIcon
                      sx={{
                        width: "1.6rem",
                        height: "1.6rem",
                        // color: colorMemento,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Box>
          </Card>
        );
      } else {
        return (
          <Card sx={{ display: "flex", marginTop: "16px" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <CardContent
                sx={{
                  flex: "1 0 auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ padding: "12px", fontSize: "1.6rem" }}
                >
                  {t("no_notion_account_connected_msg")}
                </Typography>
                <Button
                  onClick={handleIntegrateButtonClick}
                  variant="contained"
                  style={{ width: "60%", fontSize: "1.6rem" }}
                >
                  {t("connect_action")}
                </Button>
              </CardContent>
            </Box>
          </Card>
        );
      }
    } else {
      return (
        <Card sx={{ display: "flex", marginTop: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <CardContent
              sx={{
                flex: "1 0 auto",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" sx={{ padding: "12px" }}>
                {t("failed_fetch_notion_integration_information_msg")}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      );
    }
  }, [
    loadingNotionInfo,
    notionIntegrationInfo,
    fetchNotionIntegrationInfoFailed,
  ]);

  const handleAddCollectionClick = React.useCallback(() => {
    setOpenSelectCollectionContainer(true);
  }, [setOpenSelectCollectionContainer]);

  const handleDisconnectCollectionClick = React.useCallback(
    (notionCollectionInfo: NotionCollectionsInfoUiItem) => {
      setDisplaySimpleConfirmation({
        title: t("notion_disconnect_collection_dialog_title", {
          collection_name: notionCollectionInfo.collectionInfo.name,
        }),
        description: t("notion_disconnect_collection_dialog_description", {
          collection_name: notionCollectionInfo.collectionInfo.name,
        }),
        hideCancel: false,
        handleOk: () => {
          notionCollectionInfo.isLoading = true;
          setConnectedCollections([...connectedCollections]);
          setDisplaySimpleConfirmation(null);
          disConnectCollection(notionCollectionInfo.collectionInfo.id)
            .then((result: boolean) => {
              if (result) {
                if (DEBUG) {
                  console.log("disconnect collection OK");
                }
                const arrayClone = [...connectedCollections];
                const index = arrayClone.indexOf(notionCollectionInfo);
                if (index > -1) {
                  // only splice array when item is found
                  arrayClone.splice(index, 1); // 2nd parameter means remove one item only
                }
                setConnectedCollections(arrayClone);
              }
            })
            .catch((e) => {
              if (DEBUG) {
                console.log("Caught an exception %o", e);
              }
            });
        },
        handleCancel: () => {
          setDisplaySimpleConfirmation(null);
        },
        handleClose: (close: boolean) => {
          setDisplaySimpleConfirmation(null);
        },
      });
    },
    [connectedCollections, setConnectedCollections]
  );

  const doConnectCollection = React.useCallback(
    (collectionId: string, loadingHolderItem: CollectionSyncLoadingHolder) => {
      connectCollection(collectionId)
        .then((connectCollectionResult: ConnectCollectionResult) => {
          if (
            connectCollectionResult.result &&
            connectCollectionResult.collectionInfo
          ) {
            //We remove the loading UI place holder if there is one
            const arrayClone = [...collectionsSyncOngoing];
            const index = arrayClone.indexOf(loadingHolderItem);
            if (index > -1) {
              // only splice array when item is found
              arrayClone.splice(index, 1);
            }
            setCollectionsSyncOngoing(arrayClone);

            if (DEBUG) {
              console.log("connect collection OK");
            }
            setConnectedCollections([
              {
                collectionInfo: connectCollectionResult.collectionInfo,
                isLoading: false,
              },
              ...connectedCollections,
            ]);
          }
        })
        .catch((e) => {
          if (DEBUG) {
            console.log("doConnectCollection: caught an exception %o", e);
          }
          const index = collectionsSyncOngoing.indexOf(loadingHolderItem);
          loadingHolderItem.error = true;

          if (index > -1) {
            setCollectionsSyncOngoing([...collectionsSyncOngoing]);
          } else {
            setCollectionsSyncOngoing([
              loadingHolderItem,
              ...collectionsSyncOngoing,
            ]);
          }
        });
    },
    [connectedCollections, collectionsSyncOngoing, setCollectionsSyncOngoing]
  );

  const handleCollectionSelected = React.useCallback(
    (collection: CollectionObj) => {
      var alreadyConnected = false;
      connectedCollections.map((notionCollectionInfo) => {
        if (collection.id == notionCollectionInfo.collectionInfo.id) {
          alreadyConnected = true;
          return;
        }
      });
      setOpenSelectCollectionContainer(false);
      if (alreadyConnected) {
        return;
      }
      setDisplaySimpleConfirmation({
        title: t("notion_collection_selected_confirmation_dialog_title"),
        description: t(
          "notion_collection_selected_confirmation_dialog_description",
          { collection_name: collection.name }
        ),
        hideCancel: false,
        handleOk: () => {
          const loadingHolderItem = {
            id: collection.id,
            name: collection.name,
            previewUrls: collection.previewUrls,
            error: false,
          };
          setCollectionsSyncOngoing([
            loadingHolderItem,
            ...collectionsSyncOngoing,
          ]);
          setDisplaySimpleConfirmation(null);
          doConnectCollection(collection.id, loadingHolderItem);
        },
        handleCancel: () => {
          setDisplaySimpleConfirmation(null);
        },
        handleClose: (close: boolean) => {
          setDisplaySimpleConfirmation(null);
        },
      });
    },
    [connectedCollections, collectionsSyncOngoing, doConnectCollection]
  );

  return (
    <>
      <Helmet>
        <title>Connect your Notion | Memento</title>
      </Helmet>
      <Box className={classes.root}>
        <Box className={classes.boxSectionContiner}>
          <Typography
            style={{ marginTop: "16px" }}
            variant="h3"
            color="text.primary"
          >
            Notion
          </Typography>

          {!notionIntegrationInfo ||
            (!notionIntegrationInfo.isConnected && (
              <Typography
                variant="h5"
                color="text.secondary"
                sx={{ padding: "12px", fontSize: "1.4rem", width: "100%" }}
              >
                {t("notion_account_integration_subtitle")}
              </Typography>
            ))}
        </Box>
        {getIntegrationStatusComponents()}
        {notionIntegrationInfo && notionIntegrationInfo.isConnected && (
          <>
            <Typography
              style={{ marginTop: "16px" }}
              variant="h5"
              color="text.secondary"
            >
              {t("notion_connected_collections")}
            </Typography>
            {connectedCollections.length == 0 && (
              <Typography
                style={{ marginTop: "16px" }}
                variant="h5"
                color="text.secondary"
              >
                {t("notion_connected_collections_description")}
              </Typography>
            )}

            <Card sx={{ display: "flex", marginTop: "16px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleAddCollectionClick}
                  >
                    <AddCircleOutlineIcon sx={{ color: colorMemento }} />
                    <Typography
                      variant="h5"
                      sx={{ padding: "12px", color: colorMemento }}
                    >
                      {t("add_action")}
                    </Typography>
                  </Box>
                  {collectionsSyncOngoing.length > 0 && (
                    <List>
                      {collectionsSyncOngoing.map(
                        (
                          collectionSyncOngoingItem: CollectionSyncLoadingHolder
                        ) => {
                          return (
                            <ListItem
                              disablePadding
                              key={collectionSyncOngoingItem.id}
                            >
                              <ListItemIcon>
                                <ImagePreview
                                  previewUrls={
                                    collectionSyncOngoingItem.previewUrls
                                  }
                                  height="48px"
                                  width="48px"
                                />
                              </ListItemIcon>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <ListItemText
                                  primary={collectionSyncOngoingItem.name}
                                  secondary={
                                    collectionSyncOngoingItem.error
                                      ? t("notion_sending_items_failed_msg")
                                      : t("notion_sending_items_ongoing_msg")
                                  }
                                  primaryTypographyProps={{
                                    fontSize: "1.6rem",
                                  }}
                                  secondaryTypographyProps={{
                                    fontSize: "1.3rem",
                                  }}
                                />

                                {!collectionSyncOngoingItem.error && (
                                  <LinearProgress sx={{ width: "40%" }} />
                                )}
                              </Box>
                              {collectionSyncOngoingItem.error && (
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    collectionSyncOngoingItem.error = false;
                                    setCollectionsSyncOngoing([
                                      ...collectionsSyncOngoing,
                                    ]);
                                    doConnectCollection(
                                      collectionSyncOngoingItem.id,
                                      collectionSyncOngoingItem
                                    );
                                  }}
                                >
                                  {t("retry_action")}
                                </Button>
                              )}
                            </ListItem>
                          );
                        }
                      )}
                    </List>
                  )}

                  <List>
                    {connectedCollections.map(
                      (connectionCollection: NotionCollectionsInfoUiItem) => {
                        return (
                          <ListItem
                            disablePadding
                            key={connectionCollection.collectionInfo.id}
                          >
                            <ListItemIcon
                              onClick={() => {
                                window.open(
                                  getCollectionUrl(
                                    connectionCollection.collectionInfo.id,
                                    connectionCollection.collectionInfo
                                      .isArchived
                                  ),
                                  "_blank"
                                );
                              }}
                            >
                              <ImagePreview
                                previewUrls={
                                  connectionCollection.collectionInfo
                                    .previewUrls
                                }
                                height="48px"
                                width="48px"
                              />
                            </ListItemIcon>
                            <ListItemText
                              onClick={() => {
                                window.open(
                                  getCollectionUrl(
                                    connectionCollection.collectionInfo.id,
                                    connectionCollection.collectionInfo
                                      .isArchived
                                  ),
                                  "_blank"
                                );
                              }}
                              sx={{
                                cursor: "pointer",
                              }}
                              primary={connectionCollection.collectionInfo.name}
                              primaryTypographyProps={{ fontSize: "1.6rem" }}
                            />
                            {connectionCollection.isLoading && (
                              <CircularProgress size="1.9rem" />
                            )}
                            {!connectionCollection.isLoading && (
                              <>
                                <Tooltip
                                  title={
                                    <Typography variant="h5">
                                      {t("open_in_notion_action")}
                                    </Typography>
                                  }
                                >
                                  <IconButton
                                    onClick={() => {
                                      window.open(
                                        getNotionDatabaseUrl(
                                          connectionCollection.collectionInfo
                                            .notionDatabaseId
                                        ),
                                        "_blank"
                                      );
                                    }}
                                  >
                                    <OpenInNewIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    <Typography variant="h5">
                                      {t("disconnect_action")}
                                    </Typography>
                                  }
                                >
                                  <IconButton
                                    onClick={() => {
                                      handleDisconnectCollectionClick(
                                        connectionCollection
                                      );
                                    }}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </CardContent>
              </Box>
            </Card>
          </>
        )}

        {openSelectCollectionContainer && (
          <SelectCollectionContainer
            open={openSelectCollectionContainer}
            title={t("notion_select_collection_to_sync")}
            aggDataObjArray={[]}
            handleCollectionSelected={handleCollectionSelected}
            handleModalClose={() => {
              setOpenSelectCollectionContainer(false);
            }}
          ></SelectCollectionContainer>
        )}
        {displayUpgradePlanDialog && (
          <MaxDataUsageLimitReachedDialog
            open={displayUpgradePlanDialog}
            description={t("upgrade_plan_to_integrate_notion_msg")}
            handleModalClose={() => {
              setDisplayUpgradePlanDialog(false);
            }}
          />
        )}
        {displaySimpleConfirmation && (
          <SimpleConfirmationDialog
            open={Boolean(displaySimpleConfirmation)}
            title={displaySimpleConfirmation.title}
            description={displaySimpleConfirmation.description}
            hideCancel={displaySimpleConfirmation.hideCancel}
            handleOk={displaySimpleConfirmation.handleOk}
            handleModalClose={displaySimpleConfirmation.handleClose}
          />
        )}
      </Box>
    </>
  );
}

import styled from "styled-components";
import React from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { experimentalStyled as styledComponent } from "@mui/material/styles";
import ImagePreview from "components/CollectionItems/ImagePreview";
import { CollectionObj, isCollectionShared } from "models/collection";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AddNewCollectionModal from "components/UI/Modals/AddNewCollectionModal";
import SharedUsersAvatars from "components/UI/Controls/SharedUsersAvatars";

const Item = styledComponent(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  maxHeight: "140px",
  flexDirection: "column",
  border: "none",
  cursor: "pointer",
}));

const useStyles = makeStyles((theme: Theme) => ({
  menuItemIcon: {
    width: "56px",
    height: "56px",
  },
  menuItem: {
    height: "30px",
  },
}));

export interface CollectionLayoutProps {
  collection: CollectionObj;
  onCollectionSelected: (collection: CollectionObj) => void;
}

const CollectionItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-directoin: column;
  align-items: center;
  width: 100%;
`;

export const CollectionTitle = styled.div`
  display: flex;
  justify-content: start;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  padding: 0px 0px 0 12px;
  align-items: center;
`;

export const CollectionSubTitle = styled.div`
  display: flex;
  justify-content: start;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0px 0 0 12px;
`;

function CollectionLayout({
  collection,
  onCollectionSelected,
}: CollectionLayoutProps) {
  const [showMore, setShowMore] = React.useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    React.useState(false);
  const [openRenameCollectionModal, setOpenRenameCollectionModal] =
    React.useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleListSelected = () => {
    console.log("Handle list selected " + collection.name);
    onCollectionSelected(collection);
  };

  return (
    <Item sx={{ position: "relative" }} onClick={handleListSelected}>
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: "4px",
          right: "16px",
          flexDirection: "row",
        }}
      >
        {collection &&
          collection.sharedUsers &&
          collection.sharedUsers.length > 0 && (
            <SharedUsersAvatars
              hideConfigureAccess
              sharedUsersList={collection.sharedUsers}
              size="small"
            />
          )}
      </div>
      <ImagePreview
        previewUrls={collection.previewUrls}
        height="100px"
        imgSpacing="0px"
      />
      <CollectionItemFooter>
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          {" "}
          <CollectionTitle>
            {collection.name}
          </CollectionTitle>
          <CollectionSubTitle>
            ({collection.itemsCount ? collection.itemsCount : "0"})
          </CollectionSubTitle>
        </div>
      </CollectionItemFooter>
      <AddNewCollectionModal
        open={openRenameCollectionModal}
        handleModalClose={setOpenRenameCollectionModal}
        isOpenedInRenameMode
        collectionIdToRename={collection.id!!}
        collectionNameToRename={collection.name!!}
      />
    </Item>
  );
}

export default React.memo(CollectionLayout);

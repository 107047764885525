import styled, { keyframes } from "styled-components";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideCommentsViewAction } from "store/actions/view";
import { displayCommentsViewAction } from "store/actions/view";
import { AggDataObj, CommentObj, CommentNotificationObj } from "models/aggdata";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { getTimeElapsedsince } from "shared/dataUtils";
import { useTranslation } from "react-i18next";
import { colorMementoHovered } from "shared/colors";
import { retrieveAggdataForIdFromCacheGql } from "graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { DEBUG } from "configuration";
import SharedUserAvatar, {
  Size,
} from "components/UI/Controls/SharedUserAvatar";

interface CommentNotificationContainerProps {
  commentNotification: CommentNotificationObj;
}

const CommentNotificationContainer = ({
  commentNotification,
}: CommentNotificationContainerProps) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch();
  const [ getAggData,{ loading, error, data }] = useLazyQuery(retrieveAggdataForIdFromCacheGql, {
    variables: { id: commentNotification.dataId },
    fetchPolicy: "cache-only"
  });


  const handleItemClicked = React.useCallback(() => {
    getAggData().then((result) => {
        if(DEBUG){
            console.log("Got aggdata %o", result.data )
        }
        dispatch(displayCommentsViewAction(result.data.aggdata))

    }).catch(e => {
        if(DEBUG){
            console.log("handleItemClicked: failed to get aggdata")
        }
    })
  }, [getAggData])

  return (
    <Box
      onClick={handleItemClicked}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        marginBottom: "12px",
        paddingLeft: "16px",
        paddingRight: "16px",
        cursor: "pointer", 
        ":hover": {
            background: colorMementoHovered,
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          width: "100%",
          height: "100%", 
          alignItems: "top",
        }}
      >
        <Box //name and created date container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",  
            marginTop: "8px"
          }}
        >
          <SharedUserAvatar user={commentNotification.user} size={"small"} />
        </Box>
        <Box //name and created date container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginTop: "8px",
            marginLeft: "6px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.3rem",
                fontWeight: 500,
                aligntText: "start",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {commentNotification?.user?.firstName +
                " " +
                commentNotification?.user?.lastName}{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 200,
                aligntText: "start",
                marginLeft: "8px",
              }}
            >
              {getTimeElapsedsince(commentNotification.created)}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "1.3rem",
              aligntText: "start",
            }}
          >
            {commentNotification.content}
          </Typography>
        </Box>
      </Box>
      {commentNotification.previewUrl && (
        <Box>
          <img
            src={commentNotification.previewUrl}
            width="48px"
            height="48px"
            style={{
              borderRadius: "8px",
              objectFit: "cover"
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(CommentNotificationContainer);

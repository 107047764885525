import React, { useCallback } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme, Theme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UserObj } from "models/user";
import { RootState } from "store/reducers";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import IconButton from "@mui/material/IconButton";
import PushPinTwoToneIcon from "@mui/icons-material/PushPinTwoTone";
import {
  colorHyperlink,
  colorMemento,
  colorMementoBeige,
  stringToColour,
} from "shared/colors";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useHistory, useParams } from "react-router-dom";
import {
  MAINBOARD,
  ARCHIVES_FULL_ROUTE,
  REMINDERS_FULL_ROUTE,
  COLLECTIONS_FULL_ROUTE,
  COLLECTIONS_ROUTE_SUBPATH,
  ALL_ITEMS_ROUTE_SUBPATH,
  ALL_PENDING_ITEMS_ROUTE_SUBPATH,
  ARCHIVES_ROUTE_SUBPATH,
  REMINDERS_ROUTE_SUBPATH,
  TAG_ITEMS_ROUTE_SUBPATH,
  getTagRoute,
  PUBLIC_PROFILE_SETTINGS,
} from "navigation/Constants";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import BoltIcon from "@mui/icons-material/Bolt";
import { PRICING } from "navigation/Constants";
import {
  shouldPinSideNavigationBar,
  savePinSideNavigationBar,
} from "localstorage/localstorage";
import { size } from "polished";
import { CircularProgress, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  retrieveAllAggDataTagsGql,
  retrieveAllCollectionsGql,
  retrieveChildCollectionsForParentGql,
  retrieveTopLevelCollectionsGql,
} from "graphql/queries";
import { CollectionObj, isCollectionShared } from "models/collection";
import ImagePreview from "components/CollectionItems/ImagePreview";
import { getCollectionRoute } from "navigation/Constants";
import AddIcon from "@mui/icons-material/Add";
import AddNewCollectionModal from "components/UI/Modals/AddNewCollectionModal";
import LazyLoad, { forceCheck } from "react-lazyload";
import LabelIcon from "@mui/icons-material/Label";
import { AggDataTagObj } from "models/aggdata";
import Link from "@mui/material/Link";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
export const DRAWER_WIDTH = "280px";

const ITEM_MENU_BORDER_RADIUS = "6px";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: DRAWER_WIDTH,
      height: `50%`,
      flexShrink: 0,
      whiteSpace: "nowrap",
      zIndex: 0,
      "& .MuiListItem-root": {
        borderRadius: ITEM_MENU_BORDER_RADIUS,
        padding: "0px",
      },
      "& .MuiPaper-root": {
        backgroundColor: "#F8F8FA",
        "&::-webkit-scrollbar": {
          width: 3,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px #faf6e9`,
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#bdbdbd",
          borderRadius: "4px",
        },
      },
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
      //boxShadow: `7px 0px 1px -6px #dbdbdb`,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      "& .MuiPaper-root": {
        borderRight: "none",
      },
      "& .MuiList-root": {
        marginLeft: "24px",
        marginRight: "24px",
        marginTop: "24px",
      },
    },
    collectionsExpanded: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerClose: {
      width: "70px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
      "& .MuiPaper-root": {
        borderRight: "none",
        backgroundColor: "transparent",
      },
      "& .MuiListItemText-root": {
        display: "none",
      },
      "& .MuiListItemIcon-root": {
        minWidth: "24px",
      },
      "& #pin-sidebar-button": {
        // we only display the pin button when side nav is open.
        display: "none",
      },
      "& .MuiList-root": {
        marginLeft: "0px",
        marginTop: "24px",
        marginRight: "0px",
        paddingRight: "8px",
        paddingLeft: "8px",
      },
      "& .MuiListItemButton-root": {
        margin: "0px",
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    listDrawerOpen: {
      "& .MuiList-root": {
        marginLeft: "24px",
        marginRight: "24px",
        marginTop: "24px",
      },
      "& .MuiSvgIcon-root": {
        width: "2rem",
        height: "2rem",
      },
    },
    listDrawerClosed: {
      "& .MuiSvgIcon-root": {
        width: "2rem",
        height: "2rem",
      },
    },
    listItemText: {
      fontSize: "1.2rem",
      marginLeft: "0px",
    },
    listItemIcon: {},
    listItem: {},
    listItemSelected: {
      width: "100%",
      /*  "& .MuiTypography-root":{
         fontWeight: 600,
       }, */
      "& .MuiListItemButton-root": {
        borderRadius: ITEM_MENU_BORDER_RADIUS,
        backgroundColor: "#feeec9",
        "&:hover ": {
          backgroundColor: "#feeec9",
        },
      },
    },
    listItemUnSelected: {
      "& .MuiListItemButton-root": {
        borderRadius: ITEM_MENU_BORDER_RADIUS,
        backgroundColor: "transparent",
      },
    },
    pinButton: {
      color: colorMemento,
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      "& .MuiList-root": {
        marginLeft: "12px",
        marginRight: "12px",
      },
    },
    upgradeButton: {
      fontSize: "3rem",
      marginBottom: "16px",
      padding: "8px",
    },
  })
);

interface SideNavProp {
  selectedEntry: SideNavEntries;
}

interface SideMenuItems {
  icon: any;
  title: string;
  key: number;
}

const LogoContainer = styled.img`
  width: 50%;
  margin-left: 20%;
  margin-top: 18px;
`;

export enum SideNavEntries {
  HOME_KEY = 1,
  REMINDERS_KEY = 2,
  ARCHIVES_KEY = 3,
  COLLECTIONS_KEY = 4,
  TAGS_KEY = 5,
}

const SideNav = ({ selectedEntry }: SideNavProp) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedMenuItemKey, setSelectedMenuItemKey] = React.useState(
    selectedEntry
  );
  const params: any = useParams();

  const [collectionsExpanded, setCollectionsExpanded] = React.useState(false);
  const [tagsExpanded, setTagsExpanded] = React.useState(false);
  const [
    sharedCollectionsExpanded,
    setSharedCollectionsExpanded,
  ] = React.useState(true);
  const [
    privateCollectionsExpanded,
    setPrivateCollectionsExpanded,
  ] = React.useState(true);
  const [
    isCollectionsMenuHovered,
    setIsCollectionsMenuHovered,
  ] = React.useState(false);
  const [isPinned, setIsPinned] = React.useState(shouldPinSideNavigationBar());
  const [isDrawerOpen, setOpen] = React.useState(isPinned);
  const [selectedTagName, setSelectedTagName] = React.useState<string | null>(
    null
  );
  const history = useHistory();
  const userObj: UserObj = useSelector((state: RootState) => state.auth.user);
  const { loading, error, data } = useQuery(retrieveTopLevelCollectionsGql);
  const [retrieveTags, retrieveTagsResult] = useLazyQuery(
    retrieveAllAggDataTagsGql
  );
  const [openNewCollectionModal, setOpenNewCollectionModal] = React.useState(
    false
  );

  const [sharedCollections, setSharedCollections] = React.useState<
    CollectionObj[]
  >([]);
  const [privateCollections, setPrivatecollections] = React.useState<
    CollectionObj[]
  >([]);

  React.useEffect(() => {
    if (selectedMenuItemKey != SideNavEntries.TAGS_KEY) {
      setSelectedTagName(null);
    }
  }, [selectedMenuItemKey]);

  React.useEffect(() => {
    switch (params.subpath) {
      case COLLECTIONS_ROUTE_SUBPATH:
        setSelectedMenuItemKey(SideNavEntries.COLLECTIONS_KEY);
        break;
      case ALL_ITEMS_ROUTE_SUBPATH:
      case ALL_PENDING_ITEMS_ROUTE_SUBPATH:
        setSelectedMenuItemKey(SideNavEntries.HOME_KEY);
        break;
      case ARCHIVES_ROUTE_SUBPATH:
        setSelectedMenuItemKey(SideNavEntries.ARCHIVES_KEY);
        break;
      case REMINDERS_ROUTE_SUBPATH:
        setSelectedMenuItemKey(SideNavEntries.REMINDERS_KEY);
        break;
      case TAG_ITEMS_ROUTE_SUBPATH:
        setSelectedMenuItemKey(SideNavEntries.TAGS_KEY);
        if (params.subpathbis) {
          setSelectedTagName(params.subpathbis);
        }
        break;
    }
  }, [params]);

  React.useEffect(() => {
    if (data && data.topLevelCollections) {
      const sharedCollections: CollectionObj[] = [];
      const privateCollection: CollectionObj[] = [];
      data.topLevelCollections.map((collection: CollectionObj) => {
        if (isCollectionShared(collection)) {
          sharedCollections.push(collection);
        } else {
          privateCollection.push(collection);
        }
      });
      setSharedCollections(sharedCollections);
      setPrivatecollections(privateCollection);
    }
  }, [data]);

  React.useEffect(() => {
    if (tagsExpanded) {
      retrieveTags();
    }
  }, [tagsExpanded]);

  /* React.useEffect(() => {
    setSelectedMenuItemKey(selectedEntry);
  }, [selectedEntry]);
 */
  const sideMenuItems: SideMenuItems[] = [
    {
      key: SideNavEntries.HOME_KEY,
      title: t("home_title"),
      icon: <HomeRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />,
    },
    {
      key: SideNavEntries.REMINDERS_KEY,
      title: t("reminders_title"),
      icon: <AccessAlarmsIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />,
    },
    {
      key: SideNavEntries.COLLECTIONS_KEY,
      title: t("collections_title"),
      icon: (
        <FormatListBulletedRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
      ),
    },
    {
      key: SideNavEntries.TAGS_KEY,
      title: t("tags_title"),
      icon: <LabelIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />,
    },
    {
      key: SideNavEntries.ARCHIVES_KEY,
      title: t("archives_title"),
      icon: <ArchiveOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />,
    },
  ];

  const handleUpgradeButtonClick = useCallback(() => {
    history.push(PRICING);
  }, [history]);

  const onItemMenuCicked = useCallback(
    (key: number) => {
      setSelectedMenuItemKey(key);
      if (key == SideNavEntries.HOME_KEY) {
        history.push(MAINBOARD);
      } else if (key == SideNavEntries.ARCHIVES_KEY) {
        history.push(ARCHIVES_FULL_ROUTE);
      } else if (key == SideNavEntries.REMINDERS_KEY) {
        history.push(REMINDERS_FULL_ROUTE);
      } else if (key == SideNavEntries.COLLECTIONS_KEY) {
        history.push(COLLECTIONS_FULL_ROUTE);
      } else if (key == SideNavEntries.TAGS_KEY) {
        setTagsExpanded(!tagsExpanded);
      }
    },
    [setSelectedMenuItemKey, tagsExpanded]
  );

  const handleOnPinClicked = useCallback(() => {
    setIsPinned(!isPinned);
    savePinSideNavigationBar(!isPinned);
  }, [isPinned, setIsPinned]);

  return (
    <>
      <Drawer
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false || isPinned)}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isDrawerOpen,
            [classes.drawerClose]: !isDrawerOpen,
          }),
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/*  {isDrawerOpen ? (
            <LogoContainer src={FullLogoIcon} alt="Logo" />
          ) : (
            <LogoContainer
              style={{ maxWidth: "2.5rem" }}
              src={LogoIcon}
              alt="Logo"
            />
          )} */}

          {isDrawerOpen && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginLeft: "16px",
                  marginTop: "16px",
                  fontWeight: 450,
                }}
              >
                {userObj ? userObj.firstName + " " + userObj.lastName : ""}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  marginLeft: "16px",
                  fontWeight: 450,
                }}
                color="text.secondary"
              >
                {userObj ? userObj.username : ""}
              </Typography>
              {/*  <Typography
                variant="h5"
                sx={{
                  marginLeft: "16px",
                  marginTop: "4px",
                  fontWeight: 450,
                  fontSize: "1rem",
                  fontStyle: "italic",
                }}
                color="text.secondary"
              >
                Public profile is disabled
                <Link
                  style={{ color: colorMemento, cursor: "pointer" }}
                  onClick={() => {
                    history.push(PUBLIC_PROFILE_SETTINGS);
                  }}
                >
                  {" "} Configure
                </Link>
              </Typography> */}
            </Box>
          )}
          <IconButton
            style={{ marginRight: "8px", marginTop: "8px" }}
            id="pin-sidebar-button"
            aria-label="delete"
            onClick={handleOnPinClicked}
          >
            {isPinned ? (
              <PushPinIcon
                style={{
                  width: "1.8rem",
                  height: "1.8rem",
                }}
              />
            ) : (
              <PushPinTwoToneIcon
                style={{
                  width: "1.8rem",
                  height: "1.8rem",
                }}
              />
            )}
          </IconButton>
        </div>

        <Box className={classes.mainContainer}>
          <List
            className={clsx({
              [classes.listDrawerOpen]: isDrawerOpen,
              [classes.listDrawerClosed]: !isDrawerOpen,
            })}
          >
            {sideMenuItems.map((menuItem, index) => (
              <ListItem
                className={clsx(classes.listItem, {
                  [classes.listItemSelected]:
                    selectedMenuItemKey === menuItem.key,
                  [classes.listItemUnSelected]:
                    selectedMenuItemKey !== menuItem.key,
                })}
                sx={{
                  "& .MuiListItemButton-root": {
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    paddingRight: isDrawerOpen ? "16px" : "6px",
                    paddingLeft: isDrawerOpen ? "16px" : "6px",
                  },
                }}
                key={menuItem.title}
                onClick={() => onItemMenuCicked(menuItem.key)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <ListItemButton
                    onMouseEnter={() => {
                      setIsCollectionsMenuHovered(true);
                    }}
                    onMouseLeave={() => {
                      setIsCollectionsMenuHovered(false);
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      "& .MuiPaper-root": {
                        backgroundColor: "transparent",
                        border: "none",
                        padding: 0,
                        boxShadow: "none",
                        minHeight: "0px",
                      },
                    }}
                  >
                    <Box
                      id="selecteditemcontainer"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {menuItem.icon}
                      <ListItemText
                        primaryTypographyProps={{
                          style: { fontSize: "1.3rem" },
                        }}
                        className={classes.listItemText}
                        primary={menuItem.title}
                        sx={{
                          marginLeft: "8px",
                        }}
                      />
                      {menuItem.key == SideNavEntries.COLLECTIONS_KEY &&
                        isDrawerOpen && (
                          <>
                            {isCollectionsMenuHovered &&
                              menuItem.key ==
                                SideNavEntries.COLLECTIONS_KEY && (
                                <AddIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenNewCollectionModal(true);
                                  }}
                                />
                              )}
                            <ExpandMoreIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setCollectionsExpanded(!collectionsExpanded);
                              }}
                              sx={{
                                rotate: collectionsExpanded ? "0deg" : "180deg",
                              }}
                            />
                          </>
                        )}
                      {menuItem.key == SideNavEntries.TAGS_KEY && isDrawerOpen && (
                        <>
                          <ExpandMoreIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              setTagsExpanded(!tagsExpanded);
                            }}
                            sx={{
                              rotate: tagsExpanded ? "0deg" : "180deg",
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </ListItemButton>
                  {menuItem.key == SideNavEntries.TAGS_KEY &&
                    tagsExpanded &&
                    retrieveTagsResult.loading && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          size={"24px"}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Box>
                    )}
                  {menuItem.key == SideNavEntries.TAGS_KEY &&
                    tagsExpanded &&
                    retrieveTagsResult.data &&
                    retrieveTagsResult.data.allTags.length == 0 && (
                      <Typography
                        sx={{
                          marginLeft: "24px",
                        }}
                      >
                        {t("no_tags_title")}
                      </Typography>
                    )}
                  {menuItem.key == SideNavEntries.TAGS_KEY &&
                    tagsExpanded &&
                    isDrawerOpen &&
                    retrieveTagsResult.data &&
                    retrieveTagsResult.data.allTags.length > 0 &&
                    retrieveTagsResult.data.allTags.map(
                      (tag: AggDataTagObj, index) => {
                        let tagColor = stringToColour(tag.name);
                        return (
                          <Box
                            sx={{
                              "& .MuiListItemButton-root": {
                                borderRadius: "4px",
                                backgroundColor:
                                  selectedTagName == tag.name
                                    ? "#feeec9"
                                    : "transparent",
                              },
                            }}
                          >
                            <ListItemButton
                              onClick={(e) => {
                                e.stopPropagation();
                                history.push(getTagRoute(tag.name));
                                setSelectedTagName(tag.name);
                                setSelectedMenuItemKey(SideNavEntries.TAGS_KEY);
                              }}
                            >
                              <Box
                                sx={{
                                  paddingLeft: "8px",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <LabelIcon
                                  sx={{ color: tagColor?.backgroundColor }}
                                />
                                <Typography
                                  sx={{
                                    marginLeft: "6px",
                                  }}
                                >
                                  {tag.name}
                                </Typography>
                              </Box>
                            </ListItemButton>
                          </Box>
                        );
                      }
                    )}
                  {menuItem.key == SideNavEntries.COLLECTIONS_KEY &&
                    data &&
                    data.topLevelCollections &&
                    isDrawerOpen &&
                    collectionsExpanded && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          "& .MuiListItemButton-root": {
                            borderRadius: ITEM_MENU_BORDER_RADIUS,
                            backgroundColor: "transparent",
                            "&:hover ": {
                              backgroundColor: "grey",
                            },
                          },
                        }}
                      >
                        {sharedCollections.length > 0 && (
                          <ListItemText key={-2}>
                            <Typography
                              onClick={() => {
                                setSharedCollectionsExpanded(
                                  !sharedCollectionsExpanded
                                );
                              }}
                              sx={{
                                color: "gray",
                                marginLeft: "12px",
                                textTransform: "uppercase",
                                fontWeight: sharedCollectionsExpanded
                                  ? "none"
                                  : "bold",
                              }}
                            >
                              {t("shared_collections_title")}
                            </Typography>
                          </ListItemText>
                        )}
                        {sharedCollectionsExpanded &&
                          sharedCollections.map(
                            (collection: CollectionObj, index) => {
                              return (
                                <LazyLoad key={collection.id} offset={200} once>
                                  <CollectionItem
                                    key={index}
                                    collection={collection}
                                    onCollectionClicked={() => {
                                      setSelectedMenuItemKey(
                                        SideNavEntries.COLLECTIONS_KEY
                                      );
                                    }}
                                  />
                                </LazyLoad>
                              );
                            }
                          )}
                        {privateCollections.length > 0 && (
                          <ListItemText key={-1}>
                            <Typography
                              onClick={() => {
                                setPrivateCollectionsExpanded(
                                  !privateCollectionsExpanded
                                );
                              }}
                              sx={{
                                color: "gray",
                                marginLeft: "12px",
                                textTransform: "uppercase",
                                cusor: "pointer",
                                fontWeight: privateCollectionsExpanded
                                  ? "none"
                                  : "bold",
                              }}
                            >
                              {t("personal_collections_title")}
                            </Typography>
                          </ListItemText>
                        )}
                        {privateCollectionsExpanded &&
                          privateCollections.map(
                            (collection: CollectionObj, index) => {
                              return (
                                <LazyLoad key={collection.id} offset={200} once>
                                  <CollectionItem
                                    key={index}
                                    collection={collection}
                                    onCollectionClicked={() => {
                                      setSelectedMenuItemKey(
                                        SideNavEntries.COLLECTIONS_KEY
                                      );
                                    }}
                                  />
                                </LazyLoad>
                              );
                            }
                          )}
                      </Box>
                    )}
                </Box>
              </ListItem>
            ))}
          </List>
          {userObj && userObj.subscriptionPlan.id == 0 && isDrawerOpen && (
            <Button
              style={{
                marginBottom: "48px",
                marginRight: "12px",
                marginLeft: "12px",
                fontSize: "1.1rem",
              }}
              startIcon={<BoltIcon />}
              className={classes.upgradeButton}
              onClick={handleUpgradeButtonClick}
              variant="contained"
            >
              {t("title_upgrade")}
            </Button>
          )}
          {!isDrawerOpen && userObj && userObj.subscriptionPlan.id == 0 && (
            <BoltIcon
              style={{
                width: "3rem",
                height: "3rem",
                marginBottom: "48px",
                marginRight: "12px",
                marginLeft: "12px",
                color: colorMemento,
              }}
            />
          )}
          <AddNewCollectionModal
            open={openNewCollectionModal}
            handleModalClose={setOpenNewCollectionModal}
          />
        </Box>
      </Drawer>
    </>
  );
};

interface CollectionItemProps {
  collection: CollectionObj;
  onCollectionClicked: (collection: CollectionObj) => void;
}

const CollectionItem = ({
  collection,
  onCollectionClicked,
}: CollectionItemProps) => {

  const history = useHistory();
  const [openNesting, setOpenNesting] = React.useState(false);
  const [
    retrieveChildCollections,
    retrieveChildCollectionsResult,
  ] = useLazyQuery(retrieveChildCollectionsForParentGql, {
    variables: { parentId: collection.id },
  });

  const handleToggleNesting = React.useCallback(() => {
    if (!retrieveChildCollectionsResult.data) {
      retrieveChildCollections();
    }
    setOpenNesting(!openNesting);
  }, [openNesting, retrieveChildCollectionsResult]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ListItemButton
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={(e) => {
          e.stopPropagation();
          onCollectionClicked(collection);
          if (collection.isArchived) {
            history.push("/board/archives/collection/" + collection.id);
          } else {
            history.push(getCollectionRoute(collection.id));
          }
        }}
      >
        {/*  <IconButton
         
        > */}
        {openNesting ? (
          <KeyboardArrowDownIcon
            style={{
              width: "16px",
              height: "16px",
              visibility: collection.isNested ? "visible" : "hidden",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleToggleNesting();
            }}
          />
        ) : (
          <KeyboardArrowRightIcon
            style={{
              width: "16px",
              height: "16px",
              visibility: collection.isNested ? "visible" : "hidden",

            }}
            onClick={(e) => {
              e.stopPropagation();
              handleToggleNesting();
            }}
          />
        )}
        {/*         </IconButton>
         */}{" "}
        {/*  <ListItemIcon
        sx={{
          borderRadius: "4px",
        }}
      > */}
        {collection.previewUrls && collection.previewUrls.length > 0 && (
          <ImagePreview
            previewUrls={
              collection.previewUrls && collection.previewUrls.length > 0
                ? [collection.previewUrls[0]]
                : []
            }
            height="24px"
            width="24px"
            hideBorder
          />
        )}
        {/*       </ListItemIcon>
         */}{" "}
        <Typography
          sx={{
            marginLeft: "8px",
            color: "#282828",
            fontSize: "1.4rem",
            fontWeight: 450,
          }}
        >
          {collection.name}
        </Typography>
        {/*       <CollectionItem collection={collection} onCollectionClicked={() => {}} />
         */}
        <Typography
          sx={{
            fontSize: "0.8rem",
            color: "grey",
            marginLeft: "4px",
          }}
        >
          ({collection.itemsCount ? collection.itemsCount : 0})
        </Typography>
      </ListItemButton>
      {openNesting && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "12px",
          }}
        >
          {retrieveChildCollectionsResult.data &&
            !retrieveChildCollectionsResult.loading &&
            !retrieveChildCollectionsResult.error &&
            retrieveChildCollectionsResult.data.childCollections.map(
              (collection: CollectionObj) => {
                return (
                  <CollectionItem
                    collection={collection}
                    onCollectionClicked={onCollectionClicked}
                  />
                );
              }
            )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(SideNav);

import { Button, Fade, Modal, Theme } from "@mui/material";
import React, { useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { getUserAvatarForUsername } from "configuration";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useMutation } from "@apollo/client";
import { updateUserAvatarGql } from "graphql/mutations";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      height: "60%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C2C2C2",
        borderRadius: "8px",
      },
      [theme.breakpoints.down("mobile")]: {
        width: "90%",
        height: "auto",
      },
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "16px",
      flex: "1 1 0",
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
  })
);

interface UploadProfileImageProps {
  open: boolean;
  handleModalClose: (imgChanged: boolean) => void;
  src: string;
}

const UploadProfileImage = ({
  open,
  handleModalClose,
  src,
}: UploadProfileImageProps) => {
  const classes = useStyles();
  const [aspect, setAspect] = useState<number | undefined>(1);
  const [cropper, setCropper] = useState<any>();
  const [uploadAvatar, { loading, data, error }] = useMutation(
    updateUserAvatarGql
  );

  const handleSave = async () => {
    if (cropper) {
      const file = await fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], "newAvatar.png", { type: "image/png" });
        });
      if (file) {
        uploadAvatar({
          variables: {
            avatarFile: file,
          },
        }).then((result) => {
          if (result.data.updateUserAvatar.result == 0) {
            handleModalClose(true);
          }
        });
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => handleModalClose(false)}
      closeAfterTransition
      onClick={(e) => {
        e.stopPropagation(); //useful because for some reason when you click outside to close the modal the click still propagates to components underneath
      }}
    >
      <Fade in={open}>
        <div
          className={classes.paper}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Cropper
            src={src}
            style={{ height: "auto", maxHeight: "80%", width: "100%" }}
            initialAspectRatio={4 / 3}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            cropBoxResizable
            guides={true}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <LoadingButton
            sx={{
              marginTop: "40px",
            }}
            variant="contained"
            onClick={handleSave}
            loading={loading}
          >
            Upload
          </LoadingButton>
        </div>
      </Fade>
    </Modal>
  );
};

export default React.memo(UploadProfileImage);

import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Tool from "containers/Toolbar/Tool/Tool";
import ColorPalette from "components/ColorPalette/ColorPalette";
import { Dispatcher, ToggleTool } from "shared/types";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import PaletteIcon from "@mui/icons-material/Palette";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useTranslation } from "react-i18next";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AggDataObj, DataType } from "../../models/aggdata";
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CommentIcon from "@mui/icons-material/Comment";
import { IconButton, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { RootState } from "store/reducers/index";
import { UserAccessRight, UserObj } from "models/user";
import { CollectionObj } from "models/collection";
import NotesIcon from "@mui/icons-material/Notes";
import BookmarkIcon from "@mui/icons-material/Bookmark";

const ToolbarContainer = styled("div")<{
  isHover: boolean;
  transparentBg: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
  padding-left: 4px;
  opacity: ${(props) => (props.isHover ? 1 : 0)};
  background: ${(props) =>
    !props.transparentBg ? "rgba(255, 255, 255, 0.8)" : "transparent"};
  transition: opacity 0.3s ease-out;

  & .MuiButtonBase-root {
    font-size: 4rem;
  }
  @media (max-width: 1024px) {
    opacity: 1;
  }

  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

const CloseBtn = styled.button`
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 1.5rem;
  padding: 8px 24px;
  border-radius: 4px;
  letter-spacing: 0.4px;

  &:hover {
    opacity: 0.87;
    background: rgba(95, 99, 104, 0.157);
  }

  @media (max-width: 320px) {
    margin-left: auto;
    padding: 0;
  }
`;

const ToolContaienr = styled.div`
  padding-top: 8px;
  padding-bottom: 4px;
`;

interface ToolbarProp {
  id?: string;
  onHover: boolean;
  isAggDataSharedNDisplayedFromCollection?: boolean;
  transparentBg?: boolean;
  selectedColor?: number;
  displayedFromCollection?: boolean;
  showColorPalette?: boolean;
  onAddNote?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onToggle?: (noteProperty: ToggleTool) => void;
  onClick?: (color: string) => void;
  isInputField?: boolean;
  setShowLabel: Dispatcher<boolean>;
  onDelete: () => void;
  onAnnotate: () => void;
  isChecked?: boolean;
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAddToCollection: () => void;
  isDataArchived: boolean;
  aggDataObj?: AggDataObj;
  onArchive: () => void;
  onPinUnpin: () => void;
  onAddReminder?: () => void;
  onColorSelected?: (colorIndex: number) => void;
  onSelectionClicked?: () => void;
  onRemoveFromSharedCollection?: () => void;
  onComment?: () => void;
  onSave?: () => void;
  onEdit?: () => void;
  currentUserRight: number;
}

interface Icon {
  id: string;
  icon: any;
  title: string;
}

const useStyles = makeStyles((theme) => ({
  toolIcon: {
    borderRadius: "50%",
    width: "2rem",
    height: "2rem",
    backgroundSize: "50%",
    marginRight: "10px",
    "&:hover": {
      opacity: "0.87",
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const PALETTE_TOOL = "Color";
const ADD_TO_COLLECTION_TOOL = "Add to collection";
const ADD_REMINDER = "Add reminder";
const ARCHIVE_TOOL = "Archive";
const DELETE_TOOL = "Delete";
const PIN_UNPIN_TOOL = "Pin";
const REMOVE_FROM_SHARE_COLLECTION = "remove from shared collection";
const COMMENT_TOOL = "Comment";
const EDIT_TOOL = "Edit";
const ANNOTATE_TOOL = "Annotate";
const SAVE_TOOL = "Save"; //save from a public collection to own account

const Toolbar = ({
  id,
  onHover,
  isAggDataSharedNDisplayedFromCollection = false,
  transparentBg = false,
  displayedFromCollection = false,
  selectedColor,
  showColorPalette = true,
  onAddNote,
  onToggle,
  onClick,
  onEdit,
  onAnnotate,
  isInputField,
  setShowLabel,
  onDelete,
  isChecked,
  onClose,
  onAddToCollection,
  isDataArchived,
  aggDataObj,
  onArchive,
  onPinUnpin,
  onAddReminder,
  onColorSelected,
  onSelectionClicked,
  onRemoveFromSharedCollection,
  onComment,
  onSave,
  currentUserRight,
}: ToolbarProp) => {
  const [isHoverColorPalette, setIsHoverColorPalette] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [archiveClicked, setArchivedClicked] = useState(false);
  const [pinClicked, setPinClicked] = useState(false);
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );
  const currentlyOpenedCollectionCurrentUserRight: number = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollectionCurrentUserRight
  );
  const publicCollectionOpened: number = useSelector(
    (state: RootState) => state.view.publicCollectionOpened
  );
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const isDisplayedFromRemindersContainer = useSelector(
    (state: RootState) => state.view.displayTimepickerOnAddingNewItems
  ).displayTimePicker;
  const pinScore = !displayedFromCollection
    ? "allItemsPinScore"
    : "listItemsPinScore";

  const icons: Icon[] = [
    {
      id: EDIT_TOOL,
      title: t("toolbar_edit_tool_title"),
      icon: (
        <NotesIcon
          id={EDIT_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
    },
/*     {
      id: ANNOTATE_TOOL,
      title: t("toolbar_annotate_tool_title"),
      icon: (
        <NotesIcon
          id={ANNOTATE_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
    }, */
    {
      id: SAVE_TOOL,
      title: t("toolbar_bookmark_tool_title"),
      icon: (
        <BookmarkIcon
          id={SAVE_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
    },
    {
      id: PALETTE_TOOL,
      title: t("toolbar_palette_tool_title"),
      icon: (
        <PaletteIcon
          id={PALETTE_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
    },
    {
      id: COMMENT_TOOL,
      icon: (
        <CommentIcon
          id={COMMENT_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
      title: t("toolbar_comment_tool_title"),
    },
    {
      id: ADD_REMINDER,
      icon: (
        <AlarmAddIcon
          id={ADD_REMINDER}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
      title: t("toolbar_add_reminder_tool_title"),
    },
    {
      id: ADD_TO_COLLECTION_TOOL,
      icon: (
        <PlaylistAddIcon
          id={ADD_TO_COLLECTION_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
      title: t("toolbar_add_to_collection_tool_title"),
    },
    {
      id: PIN_UNPIN_TOOL,
      icon:
        Number(aggDataObj[pinScore]) > 0 ? (
          <PushPinOutlinedIcon
            id={PIN_UNPIN_TOOL}
            onClick={(e) => handleClick(e)}
            className={classes.toolIcon}
          />
        ) : (
          <PushPinIcon
            id={PIN_UNPIN_TOOL}
            onClick={(e) => handleClick(e)}
            className={classes.toolIcon}
          />
        ),
      title:
        Number(aggDataObj[pinScore]) > 0 ? t("action_unpin") : t("action_pin"),
    },
    {
      id: ARCHIVE_TOOL,
      icon: isDataArchived ? (
        <UnarchiveIcon
          id={ARCHIVE_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ) : (
        <ArchiveIcon
          id={ARCHIVE_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
      title: isDataArchived
        ? t("toolbar_unarchive_tool_title")
        : t("toolbar_archive_tool_title"),
    },
    {
      id: DELETE_TOOL,
      icon: (
        <DeleteIcon
          onClick={(e) => handleClick(e)}
          id={DELETE_TOOL}
          className={classes.toolIcon}
        />
      ),
      title: t("toolbar_delete_tool_title"),
    },
    ,
    {
      id: REMOVE_FROM_SHARE_COLLECTION,
      icon: (
        <RemoveCircleIcon
          onClick={(e) => handleClick(e)}
          id={REMOVE_FROM_SHARE_COLLECTION}
          className={classes.toolIcon}
        />
      ),
      title: t("remove_from_shared_collection_action"),
    },
  ];

  const handleShowColorPalette = useCallback((): void => {
    setIsHoverColorPalette(true);
  }, []);

  const handleHideColorPalette = useCallback((): void => {
    setIsHoverColorPalette(false);
  }, []);

  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const handleArchive = useCallback(() => {
    onArchive();
  }, [onArchive]);

  const handleComment = useCallback(() => {
    onComment();
  }, [onComment]);

  const handlePinUnpin = useCallback(() => {
    onPinUnpin();
  }, [onPinUnpin]);

  const handleClick = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.preventDefault();
      switch (e.currentTarget.id) {
        case EDIT_TOOL:
          onEdit && onEdit();
          break;
        case ANNOTATE_TOOL:
          onAnnotate && onAnnotate();
          break;
        case SAVE_TOOL:
          onSave && onSave();
          break;
        case DELETE_TOOL:
          setDeleteClicked(true);
          handleDelete();
          break;
        case ADD_TO_COLLECTION_TOOL:
          onAddToCollection();
          break;
        case ARCHIVE_TOOL:
          setArchivedClicked(true);
          handleArchive();
          break;
        case COMMENT_TOOL:
          handleComment();
          break;
        case PIN_UNPIN_TOOL:
          setPinClicked(true);
          handlePinUnpin();
          break;
        case PALETTE_TOOL:
          handleShowColorPalette(true);
          break;
        case ADD_REMINDER:
          onAddReminder && onAddReminder();
          break;
        case REMOVE_FROM_SHARE_COLLECTION:
          onRemoveFromSharedCollection && onRemoveFromSharedCollection();
          break;
        default:
          console.log("not supported!");
      }
    },
    [
      onAddToCollection,
      handleDelete,
      handleArchive,
      onRemoveFromSharedCollection,
      handleShowColorPalette,
    ]
  );
  return (
    <ToolbarContainer isHover={onHover} transparentBg={transparentBg}>
      <ToolContaienr>
        {icons.map((icon) => {
          if (
            currentUserRight == UserAccessRight.READER_ACCESS_RIGHT &&
            icon.id != COMMENT_TOOL  && icon.id != SAVE_TOOL 
          ) {
            return null;
          }
          if (
            !publicCollectionOpened &&
            icon.id == SAVE_TOOL
          ) {
            return null;
          }
          if (
            currentUser && currentUserRight != UserAccessRight.READER_ACCESS_RIGHT  &&
            icon.id == SAVE_TOOL
          ) {
            return null;
          }

          if ( 
            currentUserRight != UserAccessRight.OWNER_ACCESS_RIGHT &&
            icon.id == ANNOTATE_TOOL
          ) {
            return null;
          }
          if (
            isDataArchived &&
            (icon.id == ADD_TO_COLLECTION_TOOL ||
              icon.id == PIN_UNPIN_TOOL ||
              icon.id == ADD_REMINDER)
          ) {
            //when data is archived it can't be pinned/unpinned or adde to a collection
            return null;
          }
          
          //When aggdata is displayed from the reminders container we don't want to display pin/unpin tools
          if (isDisplayedFromRemindersContainer && icon.id == PIN_UNPIN_TOOL) {
            return null;
          }
          //From reminders, we only allow delete/archive to owner fo data.
          if (
            isDisplayedFromRemindersContainer &&
            currentUser.id != aggDataObj?.owner.id &&
            (icon.id == ARCHIVE_TOOL || icon.id == DELETE_TOOL)
          ) {
            return null;
          }

          //Only display when the aggdata is shared
          if (
            icon.id == REMOVE_FROM_SHARE_COLLECTION &&
            !isAggDataSharedNDisplayedFromCollection
          ) {
            return null;
          }
          //When aggdata is shared, we don't propose archive and delete options
          if (
            isAggDataSharedNDisplayedFromCollection &&
            (icon.id == DELETE_TOOL || icon.id == ARCHIVE_TOOL)
          ) {
            return null;
          }
          //if (isAggDataSharedNDisplayedFromCollection(aggDataObj)){
          if (!currentUser || currentUser.id != aggDataObj?.owner.id) {
            if (icon.id == ADD_TO_COLLECTION_TOOL) {
              return null;
            }
          }
          if (
            (!aggDataObj?.sharedUsers || aggDataObj?.sharedUsers.length == 0) &&
            !aggDataObj?.collection?.isPublic &&
            icon.id == COMMENT_TOOL
          ) {
            return null;
          }

          //}

          if (!showColorPalette && icon.id == PALETTE_TOOL) {
            return null;
          }
          if (
            (deleteClicked && icon.id == DELETE_TOOL) ||
            (pinClicked && icon.id == PIN_UNPIN_TOOL) ||
            (archiveClicked && icon.id == ARCHIVE_TOOL)
          ) {
            return (
              <CircularProgress
                key={icon.id}
                variant="indeterminate"
                disableShrink
                size="1.4rem"
                sx={{
                  marginRight: "10px",
                  color: "gray",
                }}
              ></CircularProgress>
            );
          }
          return (
            <Tool
              key={icon.title}
              id={id}
              title={icon.title}
              showPalette={
                icon.title === "Change Color"
                  ? handleShowColorPalette
                  : undefined
              }
              hidePalette={
                icon.title === "Change Color"
                  ? handleHideColorPalette
                  : undefined
              }
              onToggle={onToggle}
              setShowLabel={setShowLabel}
              isInputField={isInputField}
              isChecked={isChecked}
            >
              {/*  {icon.id == COMMENT_TOOL ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CommentIcon
                    id={icon.id}
                    onClick={(e) => handleClick(e)}
                    sx={{
                      borderRadius: "50%",
                      marginRight: "2px",
                    }}
                  />
                  <Typography>
                    {"(" + aggDataObj?.commentsCount + ")"}
                  </Typography>
                </div>
              ) : (
                <>{icon.icon}</>
              )} */}
              {icon.icon}
            </Tool>
          );
        })}
        {/* {!isInputField && (
          <Tool
            id={id}
            title="Delete Note"
            bgImage={TranshCanIcon}
            onDelete={onDelete}
          />
        )} */}
      </ToolContaienr>
      {isInputField && <CloseBtn onClick={onAddNote}>Close</CloseBtn>}
      {/* {isEditable && <CloseBtn onClick={onClose}>Close</CloseBtn>} */}
      {isHoverColorPalette && (
        <ColorPalette
          id={id}
          selectedColor={selectedColor}
          isInputField={isInputField}
          onUnHover={handleHideColorPalette}
          onHover={handleShowColorPalette}
          onColorSelected={onColorSelected ? onColorSelected : undefined}
        />
      )}

      {!isDisplayedFromRemindersContainer && currentUserRight > 0 && (
        <Tool key="Select" id="Select" title="Select">
          <IconButton onClick={onSelectionClicked}>
            <CheckCircleIcon sx={{ color: "black" }}></CheckCircleIcon>
          </IconButton>
        </Tool>
      )}
    </ToolbarContainer>
  );
};

export default React.memo(Toolbar);

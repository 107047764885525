import React, { useCallback } from "react";
import { colorGreyMemento, colorMemento } from "shared/colors";
import styled from "styled-components";
import LogoIcon from "icons/memento_full.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  BLOG_URL,
  LOGIN,
  PRICING,
  REGISTER,
  SETTINGS,
} from "navigation/Constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CustomButton } from "containers/InputField/InputElements";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { UserObj } from "models/user";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  APP_PLAYSTORE_LINK,
  CHROME_EXTENSION_STORE_URL,
  getUserAvatarForId,
} from "configuration";
import LanguageIcon from "@mui/icons-material/Language";
import ChromeExtensionLogo from "icons/chrome-ext.svg";
import AndroidIcon from "@mui/icons-material/Android";
import { margin } from "polished";
import { LogoutApiResult, LogoutStatusCodes, logout } from "api/authApi";
import { logoutUser as logoutReducerAction } from "store/actions/authActions";

const LogoContainer = styled.img`
  width: 10%;
  @media (max-width: 550px) {
    width: 30%;
    margin-right: 5%;
    margin-top: 5%;
  }
`;

const HeaderContainer = styled.header`
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  top: 0;
  left: 0;
  z-index: 100;
`;

const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  // setAnchorEl(event.currentTarget);
};
const handlePricingClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  window.open(PRICING, "_blank");
};
const handleBlogClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  window.open(BLOG_URL, "_blank");
};

const MobileHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [displayProducts, setDisplayProducts] = React.useState(false);
  const userObj: UserObj = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  const toggleDrawer = React.useCallback(() => {
    setOpenDrawer(!openDrawer);
  }, [openDrawer]);

  const handleGoToSettings = useCallback(() => {
    history.push(SETTINGS);
  }, [history]);

  const handleLogout = useCallback(() => {
    logout().then((result: LogoutApiResult) => {
      if (result.statusCode == LogoutStatusCodes.SUCCESS) {
        setOpenDrawer(false);
        dispatch(logoutReducerAction());
      }
    });
  }, []);

  const MenuItemStyle = {
    color: "black",
    fontSize: "1.3em",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
  };

  const MenuTextStyle = {
    fontSize: "1.6rem",
    color: "black",
    marginLeft: "8px",
  };
  const userInitials =
    userObj?.firstName.charAt(0) + userObj?.lastName.charAt(0);

  return (
    <>
      <HeaderContainer>
        <LogoContainer src={LogoIcon} alt="Logo" />
        <IconButton
          sx={{
            marginLeft: "64px",
          }}
          onClick={toggleDrawer}
        >
          <MenuIcon style={{ width: "32px", height: "32px" }} />
        </IconButton>
        <Drawer
          sx={{}}
          anchor={"right"}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
        >
          {/* {DrawerList} */}
          <Box
            sx={{
              width: "70vw",
              height: "100%",
              background: "rgba(255, 241, 206, 1)",
            }}
            role="presentation"
            onClick={() => {}}
          >
            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "16px",
              }}
            >
              {!userObj && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    width: "100%",
                  }}
                >
                  <IconButton
                    sx={{
                      marginLeft: "64px",
                    }}
                    onClick={toggleDrawer}
                  >
                    <CloseIcon style={{ width: "32px", height: "32px" }} />
                  </IconButton>
                </Box>
              )}
              {userObj && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "16px",
                  }}
                >
                  <Avatar
                    color="primary"
                    src={getUserAvatarForId(userObj?.id)}
                    style={{
                      marginRight: "8px",
                      border: "thick double #fff",
                      fontSize: "1.7rem",
                      width: "45px",
                      height: "45px",
                      cursor: "pointer",
                    }}
                    onClick={handleGoToSettings}
                  >
                    {userInitials ? userInitials : ".."}
                  </Avatar>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={handleGoToSettings}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 450,
                      }}
                    >
                      {userObj
                        ? userObj.firstName + " " + userObj.lastName
                        : ""}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 450,
                      }}
                      color="text.secondary"
                    >
                      {userObj ? userObj.username : ""}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{ position: "relative", right: "4px", top: "4px" }}
                    onClick={toggleDrawer}
                  >
                    <CloseIcon style={{ width: "32px", height: "32px" }} />
                  </IconButton>
                </Box>
              )}
              {userObj && (
                <Button
                  id="basic-button"
                  aria-haspopup="true"
                  onClick={handleGoToSettings}
                  style={{
                    color: "black",
                    fontSize: "1.6em",
                  }}
                >
                  {t("settings_action_title")}
                </Button>
              )}
              <Button
                id="basic-button"
                aria-haspopup="true"
                onClick={handleBlogClick}
                style={{
                  color: "black",
                  fontSize: "1.6em",
                }}
              >
                {t("blog_title")}
              </Button>
              <Button
                id="basic-button"
                aria-haspopup="true"
                onClick={handlePricingClick}
                style={{
                  color: "black",
                  fontSize: "1.6em",
                }}
              >
                {t("pricing_title")}
              </Button>

              <Button
                id="basic-button"
                aria-haspopup="true"
                onClick={() => {
                  setDisplayProducts(!displayProducts);
                }}
                style={{
                  color: "black",
                  fontSize: "1.6em",
                }}
              >
                {t("products_title")}
              </Button>

              {displayProducts && (
                <>
                  <Box
                    onClick={() => {
                      window.open(APP_PLAYSTORE_LINK, "_blank");
                    }}
                    sx={MenuItemStyle}
                  >
                    <AndroidIcon
                      fontSize="large"
                      style={{
                        color: "#59c639",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    <Typography sx={MenuTextStyle}>
                      {t("product_for_android")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      window.open(CHROME_EXTENSION_STORE_URL, "_blank");
                    }}
                    sx={MenuItemStyle}
                  >
                    <img
                      src={ChromeExtensionLogo}
                      style={{ width: "24px", height: "24px" }}
                    />
                    <Typography sx={MenuTextStyle}>
                      {t("product_for_chrome_ext")}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      window.open(LOGIN, "_blank");
                    }}
                    sx={MenuItemStyle}
                  >
                    <LanguageIcon
                      fontSize="large"
                      style={{
                        color: "lightblue",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    <Typography sx={MenuTextStyle}>
                      {t("product_for_web")}
                    </Typography>
                  </Box>
                </>
              )}
              {!userObj && (
                <Button
                  onClick={() => history.push(LOGIN)}
                  style={{
                    color: "black",
                    fontSize: "1.6em",
                    whiteSpace: "nowrap",
                  }}
                  size="large"
                >
                  {t("login_action")}
                </Button>
              )}
              {userObj && (
                <Button
                  onClick={handleLogout}
                  style={{
                    color: "black",
                    fontSize: "1.6em",
                    whiteSpace: "nowrap",
                  }}
                  size="large"
                >
                  {t("logout_action_title")}
                </Button>
              )}
              {!userObj && (
                <CustomButton
                  onClick={() => history.push(REGISTER)}
                  style={{
                    fontSize: "1.6em",
                    whiteSpace: "nowrap",
                    paddingRight: "12px",
                    paddingLeft: "12px",
                    marginLeft: "4px",
                  }}
                >
                  {t("signup_action")}
                </CustomButton>
              )}
            </List>
            {/*             <Divider />
             */}{" "}
          </Box>
        </Drawer>
      </HeaderContainer>
    </>
  );
};

export default React.memo(MobileHeader);

import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Alert,
  CircularProgress,
  Grow,
  TextField,
} from "@mui/material";
import { savePublicCollectionGql } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LOGIN } from "navigation/Constants";
import { useHistory } from "react-router-dom";

interface SavePublicCollectionConfirmationDialogProps {
  open: boolean;
  collectionNameExistsAlready: boolean;
  collectionName: string;
  publicCollectionId: string;
  handleModalClose: (close: boolean) => void;
}

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("mobile")]: {
        width: "80%",
      },
    },
    addButton: {
      marginEnd: "12px",
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "24px",
    },
  })
);

export default function SavePublicCollectionConfirmationDialog({
  open,
  collectionNameExistsAlready,
  collectionName,
  publicCollectionId,
  handleModalClose,
}: SavePublicCollectionConfirmationDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [textFieldValue, setTextFieldValue] = React.useState(collectionName);
  const [savePublicCollection, savePublicCollectionResult] = useMutation(
    savePublicCollectionGql
  );

  const history = useHistory();
  const handleSaveClicked = useCallback(() => {
    savePublicCollection({
      variables: {
        publicCollectionToken: publicCollectionId,
        newCollectionName: textFieldValue,
      },
    });
  }, [textFieldValue, publicCollectionId]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Title>
              {t("save_public_collection_dialog_title", {
                collection_name: collectionName,
              })}
            </Title>
            {savePublicCollectionResult.error && (
              <>
                <Typography
                  variant="h5"
                  sx={{
                    marginTop: "16px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {t("failed_to_save_public_collection")}
                </Typography>
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    onClick={() => {
                      handleModalClose(true);
                    }}
                  >
                    <Typography variant="h6">{t("retry_action")}</Typography>
                  </Button>
                </div>
              </>
            )}

            {savePublicCollectionResult.data &&
              !savePublicCollectionResult.loading &&
              savePublicCollectionResult.data.savePublicCollection.result ==
                0 && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...{ timeout: 600 }}
                  >
                    <CheckCircleIcon
                      color="primary"
                      sx={{
                        width: "96px",
                        height: "96px",
                        color: "#FDC84B",
                      }}
                    />
                  </Grow>

                  <Typography
                    variant="h5"
                    sx={{
                      marginTop: "16px",
                    }}
                  >
                    {t("public_collection_saved_confirmation_msg")}
                  </Typography>
                  <div className={classes.buttonContainer}>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      onClick={() => {
                        handleModalClose(true);
                        history.push(LOGIN);
                      }}
                    >
                      <Typography variant="h6">
                        {t("back_to_home_action")}
                      </Typography>
                    </Button>
                  </div>
                </div>
              )}
            {savePublicCollectionResult.loading && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
                <Typography
                  variant="h5"
                  sx={{
                    marginTop: "16px",
                  }}
                >
                  {t("copy_operation_ongoing")}
                </Typography>
              </div>
            )}
            {!savePublicCollectionResult.data &&
              !savePublicCollectionResult.loading && (
                <>
                  {collectionNameExistsAlready && (
                    <Alert
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "1.3rem",
                      }}
                      severity="warning"
                    >
                      {t("save_public_collection_name_already_exists", {
                        collection_name: collectionName,
                      })}
                    </Alert>
                  )}

                  <Typography
                    variant="h5"
                    sx={{
                      marginTop: "16px",
                    }}
                  >
                    {t("save_public_collection_dialog_description")}
                  </Typography>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "24px",
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      label={null}
                      variant="standard"
                      value={textFieldValue}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setTextFieldValue(event.target.value);
                      }}
                      sx={{
                        width: "60%",
                        fontSize: "1.8rem",
                        "& .MuiInput-root": {
                          fontSize: "1.9rem",
                        },
                      }}
                    />
                  </div>

                  <div className={classes.buttonContainer}>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      onClick={handleSaveClicked}
                    >
                      <Typography variant="h6">{t("save_action")}</Typography>
                    </Button>
                  </div>
                </>
              )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}

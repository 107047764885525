import { Box, Typography } from "@mui/material";
import { getNoteImageForId } from "configuration";
import { AggDataObj, NoteImage } from "models/aggdata";
import React from "react";
import { useDispatch } from "react-redux";
import { displayNoteImagesViewer } from "store/actions/view";

export enum PreviewType {
  GRID = 0,
  FEED = 1,
}

interface NoteImagePreviewProps {
  noteImageIds: NoteImage[];
  previewType: PreviewType;
  aggData: AggDataObj;
}

const NoteImagePreview = ({
  noteImageIds,
  aggData,
  previewType = PreviewType.GRID,
}: NoteImagePreviewProps) => {
  const imageHeight = previewType == PreviewType.GRID ? "70px" : "190px";
  const hintTextSize = previewType == PreviewType.GRID ? "2rem" : "3.5rem";

  const handleImageClicked = React.useCallback(
    (e, noteImageId) => {
      e.stopPropagation();
      dispatch(
        displayNoteImagesViewer(
          noteImageIds,
          noteImageId,
          aggData,
          aggData?.note?.noteTitle
        )
      );
    },
    [aggData, noteImageIds]
  );

  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {noteImageIds.map((id, index) => {
        if (index > 2) {
          return null;
        }
        if (index == 2) {
          return (
            <Box
              key={id.id}
              style={{
                position: "relative",
                flex: 1,
                height: imageHeight,
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  borderRadius: "4px",
                }}
                src={getNoteImageForId(id.id)}
                onClick={(e) => {
                  handleImageClicked(e, id);
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "100%",
                  height: "100%",
                  color: "white",
                  backgroundColor: "#57575780",
                }}
                onClick={(e) => {
                  handleImageClicked(e, id);
                }}
              >
                <Typography
                  sx={{
                    fontSize: hintTextSize,
                    textAlign: "center",
                    alignItems: "center",
                    color: "white",
                  }}
                  onClick={(e) => {
                    handleImageClicked(e, id);
                  }}
                >
                  {"+" + (noteImageIds.length - 2)}
                </Typography>
              </Box>
            </Box>
          );
        }
        return (
          <img
            key={id.id}
            style={{
              objectFit: "cover",
              flex: 1,
              borderRadius: "4px",
              maxWidth: "33%",
            }}
            src={getNoteImageForId(id.id)}
            height={imageHeight}
            onClick={(e) => {
              handleImageClicked(e, id);
            }}
          />
        );
      })}
    </Box>
  );
};

export default React.memo(NoteImagePreview);

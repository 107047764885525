import React, { useCallback, useRef } from "react";

import Modal from "@mui/material/Modal";
import {
  Avatar,
  Box,
  Fade,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  checkIfYoutubeVideoUrl,
  isUrlValid,
  isValidHttpUrl,
} from "shared/dataUtils";
import {
  addUiElement,
  addUiElementGql,
  updatePublicProfileGql,
  updateUiElementGql,
} from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/actions/view";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { styledTooltipTitle } from "shared/styles";
import PublicButtonColorPicker from "components/ColorPalette/PublicButtonColorPicker";
import TitleIcon from "@mui/icons-material/Title";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { fetchPreview } from "api/urlPreviewApi";
import { v4 as uuidv4 } from "uuid";
import { ButtonItem } from "models/user";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "60%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      alignItems: "end",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("mobile")]: {
        width: "90%",
      },
    },
    inputLabel: {
      fontSize: "1.9rem",
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "1.8rem",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
  })
);
interface AddPublicProfileButtonModalProps {
  open: boolean;
  handleModalClose: (open: boolean) => void;
  buttonItem?: ButtonItem | null; //We open in edit mode of button item is provided
}
const AddPublicProfileButtonModal = ({
  open,
  handleModalClose,
  buttonItem,
}: AddPublicProfileButtonModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputFieldRef = React.useRef<HTMLInputElement | null>();
  const dispatch = useDispatch();
  const [
    colorPaletteAnchorEl,
    setColorPaletteAnchorEl,
  ] = React.useState<HTMLInputElement | null>(null);
  const [inputTitle, setInputTitle] = React.useState(
    buttonItem ? buttonItem?.title : ""
  );
  const [inputLink, setInputLink] = React.useState(
    buttonItem ? buttonItem?.url : ""
  );
  const [buttonColor, setButtonColor] = React.useState(
    buttonItem ? buttonItem?.background : ""
  );
  const [buttonPreviewImageUrl, setButtonPreviewImageUrl] = React.useState<
    string | null
  >(buttonItem ? buttonItem?.imgUrl : null);
  const [addUiElement, addUiElementResult] = useMutation(addUiElementGql);
  const [updateUiElement, updateUiElementResult] = useMutation(
    updateUiElementGql
  );
  const [displayLinkError, setDisplayLinkError] = React.useState(false);
  const [linkHelperText, setLinkHelperText] = React.useState<string | null>(
    t("link_opened_when_button_clicked")
  );

  const fetchLinkPreviewInformation = React.useCallback(
    (url: string) => {
      fetchPreview(url).then((result) => {
        console.log("Got the result " + JSON.stringify(result));
        setButtonPreviewImageUrl(result.previewUrl);
        if (!inputTitle) {
          setInputTitle(result.title);
        }
      });
    },
    [inputTitle]
  );

  const handleSave = React.useCallback(() => {
    if (!isValidHttpUrl(inputLink)) {
      return;
    }
    const button = {
      id: buttonItem && buttonItem.id ? buttonItem.id : uuidv4(),
      type: "button",
      url: inputLink,
      title: inputTitle,
      imgUrl: buttonPreviewImageUrl,
      background: buttonColor,
    };
    if (buttonItem) {
      updateUiElement({
        variables: {
          element: JSON.stringify(button),
        },
      }).then((result) => {
        handleModalClose(false);
      });
    } else {
      addUiElement({
        variables: {
          element: JSON.stringify(button),
        },
      }).then((result) => {
        handleModalClose(false);
      });
    }
  }, [inputTitle, buttonItem, buttonPreviewImageUrl, buttonColor]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        onClick={(e) => {
          e.stopPropagation(); //useful because for some reason when you click outside to close the modal the click still propagates to components underneath
        }}
      >
        <Fade in={open}>
          <div
            className={classes.paper}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                "@media (max-width: 780px)": {
                  flexDirection: "column-reverse",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexBasis: "50%",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                  "@media (max-width: 780px)": {
                    paddingRight: "0px",
                    paddingBottom: "0px",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "1.6rem",
                    marginBottom: "8px",
                  }}
                >
                  {t("configuration")}
                </Typography>
                <TextField
                  inputRef={inputFieldRef}
                  variant="standard"
                  fullWidth
                  className={classes.inputLabel}
                  error={displayLinkError}
                  helperText={linkHelperText}
                  value={inputLink}
                  placeholder="https://www...."
                  onChange={(e) => {
                    let linkInput = e.target.value;
                    if (!isValidHttpUrl(linkInput)) {
                      setDisplayLinkError(true);
                      setLinkHelperText(t("url_not_valid_start_https_error"));
                    } else {
                      setDisplayLinkError(false);
                      setLinkHelperText(t("link_opened_when_button_clicked"));
                      fetchLinkPreviewInformation(linkInput);
                    }
                    setInputLink(e.target.value);
                  }}
                  sx={{
                    padding: "8px",
                    paddingLeft: "0px",
                  }}
                  //defaultValue={aggdata.annotation}
                  color="primary"
                  inputProps={{
                    style: {
                      height: "100%",
                      lineHeight: "normal",
                      fontSize: "1.8rem",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <Box sx={{ marginRight: "8px", marginLeft: "8px" }}>
                        <InsertLinkIcon
                          sx={{ width: "24px", height: "24px" }}
                        />
                      </Box>
                    ),
                  }}
                />

                <TextField
                  inputRef={inputFieldRef}
                  variant="standard"
                  fullWidth
                  placeholder={t("enter_button_title_placeholder")}
                  className={classes.inputLabel}
                  value={inputTitle}
                  onChange={(e) => {
                    setInputTitle(e.target.value);
                  }}
                  //defaultValue={aggdata.annotation}
                  color="primary"
                  inputProps={{
                    style: {
                      height: "100%",
                      lineHeight: "normal",
                      fontSize: "1.8rem",
                    },
                  }}
                  sx={{
                    padding: "8px",
                    paddingLeft: "0px",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <Box sx={{ marginRight: "8px", marginLeft: "8px" }}>
                        <TitleIcon sx={{ width: "24px", height: "24px" }} />
                      </Box>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexBasis: "50%",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "1.6rem",
                    marginBottom: "8px",
                  }}
                >
                  {t("button_preview")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "80px",
                    border: "1px solid black",
                    borderRadius: "8px",
                    cursor: "pointer",
                    background: buttonColor,
                    overflow: "none",
                    padding: "4px",
                    ":hover": {
                      boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5);",
                      "-webkit-transition": "background 1s" /* For Safari */,
                      transition: "background 1s",
                    },
                  }}
                  onClick={() => {
                    if (inputLink){
                      window.open(inputLink, "_blank");
                    }
                  }}
                >
                  {buttonPreviewImageUrl && (
                    <Avatar
                      //onClick={handleClick}
                      //className={classes.avatarNotHovered}
                      color="red"
                      src={buttonPreviewImageUrl}
                      style={{
                        border: "thick double #fff",
                        fontSize: "3rem",
                        width: "60px",
                        height: "60px",
                        cursor: "pointer",
                        marginLeft: "8px",
                        // backgroundColor: theme.palette.primary.main,
                      }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: "1.6rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      flex: "1 1 auto",
                      textAlign: "center",
                      alignContent: "center",
                      alignItems: "center",
                      height: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {inputTitle}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <Tooltip
                    title={styledTooltipTitle(
                      t("pick_public_profile_background")
                    )}
                  >
                    <IconButton
                      onClick={(e) => {
                        setColorPaletteAnchorEl(e.target);
                      }}
                    >
                      <ColorLensIcon
                        sx={{
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <PublicButtonColorPicker
              anchorEl={colorPaletteAnchorEl}
              open={Boolean(colorPaletteAnchorEl)}
              onClose={() => {
                setColorPaletteAnchorEl(null);
              }}
              onColorSelected={(color) => {
                setButtonColor(color);
              }}
              selectedColor={buttonColor}
            />
            <LoadingButton
              variant="contained"
              sx={{
                marginTop: "8px",
                width: "20%",
              }}
              loading={addUiElementResult.loading}
              onClick={handleSave}
            >
              {buttonItem?.id ? t("update_action") : t("add_action")}
            </LoadingButton>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(AddPublicProfileButtonModal);

import styled from 'styled-components';
import { shade } from 'polished';
import { colorMemento } from '../../shared/colors';

interface ContainerProps {
  loading: boolean;
}


export const Container = styled.button<ContainerProps>`
  background: ${colorMemento};
  height: 4rem;
  border-radius: 4px;
  border: "1px solid transparent";
  display: "inline-flex";
  padding: 2px;
  color: #fff;
  width: 100%;
  font-weight: 500;
  color: gray;
  font-size: 14px;
  margin-top: 1.6rem;
  transition: background-color 0.2s;
  font-family: Roboto, sans-serif;

  &:hover {
    background: ${shade(0.12, colorMemento)};
  }

  &:disabled {
    background: ${shade(0.12, '#ffba00')};
    cursor: progress;
  }
`;

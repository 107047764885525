import { ListAggDatasQuery } from "../API";
//import { GraphQLResult } from "@aws-amplify/api";
import { DELIMITER } from "shared/dataUtils";
import { CollectionObj } from "models/collection";
import { SharedUserAccess } from "models/user";
import { filterObject } from "utils/objectfilter";
import { UserObj } from "models/user";

export interface Reminder {
  triggerTime: number;
  cycle: number;
}

export interface Note {
  noteTitle?: string | null;
  noteContent?: string | null;
  noteColor?: number | null;
  noteTasks: string | null; //Should be in json format.
  laseEdited?: string;
  noteimageSet?: NoteImage[] | null;
}
export interface NoteImage {
  id: string;
}

interface File {
  pagesCount?: string | null;
  fileName: string | null;
  length: number;
}

export interface AggDataTagObj{
  name: string;
}

export interface AggDataObj {
  id?: string | null;
  collectionID?: string | null;
  dataContent?: string | null;
  annotation?: string | null;
  isResolved?: boolean | null;
  isArchived?: boolean | null;
  url?: string | null;
  sortOrderScore?: number | null;
  allItemsPinScore?: number | null;
  commentsCount?: number | null;
  listItemsPinScore?: number | null;
  dataType?: DataType;
  fileSize?: number | null;
  reminders: string | null;
  note?: Note | null;
  file?: File | null;
  created?: string;
  updatedAt?: string;
  collection?: CollectionObj;
  owner?: string | null;
  tags?: AggDataTagObj[];
  sharedUsers: SharedUserAccess[];
}

export interface CommentObj {
  user: UserObj;
  content: string;
  created: string;
  id: string;
}

export interface CommentNotificationObj {
  user: UserObj;
  content: string;
  created: string;
  // id: string;
  dataId: string;
  previewUrl: string; //preview image of the aggdata for the comment
}

/**
 * Used to set default values when using gql mutations with apollo.
 */
export const AGGDATA_DEFAULT = {
  isResolved: false,
  isArchived: false,
  //noteTask?: string | null,
  //noteTaskIsChecked?: boolean | null,
  //noteImages?: Array< string | null > | null,
};

interface PreviewInformation {
  title: string;
  description: string;
  imgUrl: string;
  hostUrl: string;
}

interface FilePreviewInformation {
  title: string;
  pagesCount: string;
}

export function getLinkPreviewInformation(
  aggData: AggDataObj
): PreviewInformation | null {
  const preview_data = aggData.dataContent?.split(DELIMITER);
  if (preview_data?.length != 4) {
    return null;
  }

  return {
    title: preview_data[0],
    description: preview_data[1],
    imgUrl: preview_data[2],
    hostUrl: preview_data[3].replace("https://", "").replace("http://", ""),
  };
}

export function buildLinkPreviewInformation(
  title: string,
  description: string,
  imgUrl: string,
  hostUrl: string
): string {
  return (
    title + DELIMITER + description + DELIMITER + imgUrl + DELIMITER + hostUrl
  );
}

export const isAggdataShared = (aggData: AggDataObj) => {
  return (
    aggData.collection &&
    aggData.collection.sharedUsers &&
    aggData.collection.sharedUsers.length > 0
  );
};

export function sanitizeForApiCall(aggDataObj: AggDataObj): AggDataObj {
  return filterObject(aggDataObj, "__typename");
}

export function constructLinkData(urlInput: string) {
  return {
    dataType: DataType.WEB_URL,
    url: urlInput,
  };
}

export function aggDataHasReminders(aggData: AggDataObj): Boolean {
  return (
    aggData != null &&
    aggData.reminders != null &&
    JSON.parse(aggData.reminders).length > 0
  );
}

export function shouldAggDataBeCreadted(aggData: AggDataObj) {
  console.log("shouldAggDataBeCreadted: " + JSON.stringify(aggData));
  return (
    !aggData.created &&
    ((aggData.note?.noteContent && aggData.note?.noteContent.length > 0) ||
      (aggData.note?.noteTitle && aggData.note?.noteTitle.length > 0) ||
      (aggData.note?.noteTasks && aggData.note?.noteTasks.length > 0))
  );
}

export enum DataType { //should be in sync with values from the backend
  IMAGE_TYPE = 0,
  NOTE_TYPE = 2,
  WEB_URL = 4,
  FILE_TYPE = 10,
  AUDIO_TYPE = 11,
  VIDEO_TYPE = 12,
}

export enum DataSubtype { //should be in sync with values from the backend
  IMAGE_SUBTYPE = 3, //in mobile we distinguish between drawing type, image from gallery, and screenshots. This value is for image from gallery
  PDF_FILE_SUBTYPE = 5,
  ZIP_FILE_SUBTYPE = 10,
}

import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { RootState } from "store/reducers";
import { retrievePublicCollectionsForUsernameGql } from "graphql/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { experimentalStyled as styledComponent } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { CollectionObj } from "models/collection";
import {
  updateCollectionContext,
  setSearchContext,
  showSnackbar,
  setPathAfterLogin,
} from "store/actions/view";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LazyLoad, { forceCheck } from "react-lazyload";
import ConfidentialityFilterControl from "components/UI/Controls/ConfidentialityFilterControl";
import CollectionsFilterControl, {
  SORT_TYPE,
  VIEW_TYPE,
} from "components/UI/Controls/CollectionsFilterControl";
import BackgroundWorker from "workers/NextReminderWorker.worker";

import {
  DEBUG,
  MAX_SHARED_COLLECTIONS_COUNT_FREE_USER,
  getCollectionPublicAccessUrlFromAccessToken,
} from "configuration";
import { setSharedUsersList } from "store/actions/collectionsActions";
import { Divider, IconButton, Tooltip, Typography } from "@mui/material";
import CollectionsFilter, {
  CollectionsFilterOptions,
} from "components/UI/Filters/CollectionsFilter";
import LoadingCollections from "components/layout/Loading/LoadingCollections";
import {
  getCollectionFilterType,
  getCollectionsSortType,
  getCollectionsViewType,
  saveCollectionFilterOption,
  isUserLoggedIn as checkIsUserLoggedIn,
} from "localstorage/localstorage";
import { UserObj } from "models/user";
import {
  LOGIN,
  PUBLIC_COLLECTION_BASE_PATH,
  PUBLIC_USER_PROFILE_BASE_PATH,
  getCollectionRoute,
} from "navigation/Constants";
import { Helmet } from "react-helmet";
import { ListItemButton } from "@mui/material";
import ImagePreview from "components/CollectionItems/ImagePreview";
import SelectCollectionContainer from "containers/SelectCollectionContainer/SelectCollectionContainer";
import PublishCollectionModal from "components/UI/Modals/PublishCollectionModal";
import { styledTooltipTitle } from "shared/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  canSavePublicCollectionGql,
  suspendPublicAccessGql,
} from "graphql/mutations";
import SaveIcon from "@mui/icons-material/Save";
import { logoutUser } from "store/actions/authActions";
import SavePublicCollectionConfirmationDialog from "./SavePublicCollectionConfirmationDialog";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";
import MaxDataUsageLimitReachedDialog from "components/UI/Modals/MaxDataUsageLimitReachedDialog";

const Item = styledComponent(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "column",
}));

const MainContainer = styled.div`
  display: flex;
  margin-top: 36px;
  flex-direction: column;

  margin-bottom: 12px;
  width: 100%;
  height: 100%;
`;

export const CollectionTitle = styled.div`
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

export const CollectionSubTitle = styled.div`
  display: flex;
  justify-content: start;
  font-size: 1.3rem;
  font-weight: 500;
  margin-right: 8px;
`;

const useStyles = makeStyles((theme: Theme) => ({
  itemsTypeStack: {
    marginRight: "135px",
  },
  tab: {
    "& .MuiButtonBase-root": {
      fontSize: "1.4rem",
    },
  },

  headerText: {},
}));

//const DEFAULT_VIEW_TYPE = VIEW_TYPE.GRID;
interface PublicCollectionsContainerProps {
  username: string;
  readonly: boolean;
}

const PublicCollectionsContainer = ({
  username,
  readonly,
}: PublicCollectionsContainerProps) => {
  const [renderedGridContent, setRenderedGridContent] = React.useState(<></>);
  const [
    openPublishCollectionModal,
    setOpenPublishCollectionModal,
  ] = React.useState<CollectionObj | null>(null);
  const [suspendPublicAccess, suspendPublicAccessResult] = useMutation(
    suspendPublicAccessGql
  );
  const { loading, error, data } = useQuery(
    retrievePublicCollectionsForUsernameGql,
    {
      variables: {
        username: username,
      },
      context: {
        clientName: "public",
      },
    }
  );

  const [emptyResult, setEmptyResult] = React.useState(Boolean(data)); //if data is already there, consider the initial state to be empty
  const { t } = useTranslation();

  const history = useHistory();
  const searchInput: string = useSelector(
    (state: RootState) => state.view.searchInput
  );

  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedViewType, setSelectedViewType] = React.useState<VIEW_TYPE>(
    getCollectionsViewType()
  );

  const [openSelectCollection, setOpenSelectCollection] = React.useState(false);
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const [
    displayUserAlreadyOwnsCollection,
    setDisplayUserAlreadyOwnsCollection,
  ] = React.useState(false);
  const [
    checkCanSavePublicCollection,
    checkCanSavePublicCollectionResult,
  ] = useMutation(canSavePublicCollectionGql);

  const [
    displaySaveCollectionConfirmation,
    setDisplaySaveCollectionConfirmation,
  ] = React.useState(false);

  const currentCollectionToSave = React.useRef<CollectionObj | null>();

  const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = React.useState<
    string | undefined | null
  >(undefined);

  const handleSuspendPublicAccess = React.useCallback(
    (collection: CollectionObj) => {
      suspendPublicAccess({
        variables: {
          collectionId: collection.id,
        },
        refetchQueries: [
          {
            query: retrievePublicCollectionsForUsernameGql,
            variables: {
              username: username,
            },
            context: {
              clientName: "public",
            },
          },
        ],
      })
        .then((e) => {})
        .catch((e) => {
          dispatch(
            showSnackbar({
              message: t("generic_failure_msg"),
              action: "",
              onAction: null,
              loading: false,
            })
          );
        });
    },
    []
  );

  React.useEffect(() => {
    let renderedResult = (
      <>
        {data &&
          data.publicCollectionsForUsername.map(
            (collection: CollectionObj, index: number) =>
              getCollectionGridItem(collection)
          )}
      </>
    );
    setRenderedGridContent(renderedResult);
    if (data && data.publicCollectionsForUsername) {
      setEmptyResult(data.publicCollectionsForUsername.length == 0);
    }
  }, [data, selectedViewType]);

  /*   React.useEffect(() => {
    if (backgroundWorkerRef && backgroundWorkerRef.current) {
      backgroundWorkerRef.current.terminate();
    }
    backgroundWorkerRef.current = new BackgroundWorker();
    backgroundWorkerRef.current.addEventListener("message", (event: any) => {
      if (DEBUG) {
        console.log("CollectionsContainer got back message %o", event.data);
      }
      if (event.data.reply == BUILD_SHARED_USERS_LIST_REPLY) {
        const requestToken = event.data.payload.token;
        if (backgroundWorkerRequestToken.current != requestToken) {
          if (DEBUG) {
            console.log(
              "CollectionsContainer: drop this reply from worker, request token doesn't match"
            );
          }
          return;
        }
        const allSharedUsersList = event.data.payload.sharedUsersList;
        dispatch(setSharedUsersList(allSharedUsersList));
      } else if (event.data.reply == FILTER_COLLECTIONS_REPLY) {
        const requestToken = event.data.payload.token;
        if (filterCollectionsWorkerRequestToken.current != requestToken) {
          if (DEBUG) {
            console.log(
              "CollectionsContainer: drop this reply from worker, request token doesn't match"
            );
          }
          return;
        }
        const personalCollectionsList =
          event.data.payload.personalCollectionsList;
        const sharedCollectionsList = event.data.payload.sharedCollectionsList;
        setPersonalCollectionsList(personalCollectionsList);
        setSharedCollectionsList(sharedCollectionsList);
      }
    });
    return () => {
      //thread cleanup
      if (backgroundWorkerRef && backgroundWorkerRef.current) {
        backgroundWorkerRef.current.terminate();
      }
    };
  }, []); */

  useEffect(() => {
    dispatch(updateCollectionContext(true));
    dispatch(setSearchContext(t("search_context_all_archived_collections")));
    return () => {
      dispatch(updateCollectionContext(false));
      dispatch(setSearchContext(""));
    };
  }, []);

  const handleSaveToMyMemento = React.useCallback(
    (collection: CollectionObj) => {
      currentCollectionToSave.current = collection;
      if (isUserLoggedIn) {
        checkCanSavePublicCollection({
          variables: {
            collectionId: collection.id,
          },
        })
          .then((result) => {
            if (DEBUG) {
              //  console.log("Got the result %o", result);
            }
            if (
              result.data &&
              result.data.canSavePublicCollection &&
              // !result.data.canSavePublicCollection.isOwner &&
              result.data.canSavePublicCollection.result == 0
            ) {
              setDisplaySaveCollectionConfirmation(true);
            } else if (result.data.canSavePublicCollection.isOwner) {
              setDisplayUserAlreadyOwnsCollection(true);
            } else if (result.data.canSavePublicCollection.result == 1) {
              //Max number of collections reached, prompt to upgrade
              setOpenUpgradePlanDialog(
                t("max_free_tier_collections_reached", {
                  max_shared_collections_count: MAX_SHARED_COLLECTIONS_COUNT_FREE_USER,
                })
              );
            } else if (result.data.canSavePublicCollection.result == 2) {
              //Max data usage reached, prompt to upgrade
              setOpenUpgradePlanDialog(
                t("max_data_usage_reached_dialog_description")
              );
            }
            //setDisplaySaveCollectionConfirmation(true);
          })
          .catch((err) => {
            console.log("Caught exception %o", err);
            if (!checkIsUserLoggedIn() || err?.message?.includes("403")) {
              dispatch(logoutUser());
              history.push(LOGIN);
            }
          });
      } else {
        dispatch(
          setPathAfterLogin(PUBLIC_USER_PROFILE_BASE_PATH + "/" + username)
        );
        history.push(LOGIN);
      }
    },
    [isUserLoggedIn, history, username, setDisplaySaveCollectionConfirmation]
  );

  const getCollectionGridItem = React.useCallback(
    (collection: CollectionObj) => {
      return (
        <CollectionItemLayout
          readonly={readonly}
          handleCollectionClicked={handleCollectionClicked}
          handleSuspendPublicAccess={handleSuspendPublicAccess}
          collection={collection}
          handleSaveCollection={handleSaveToMyMemento}
        />
      );
    },
    [selectedViewType]
  );

  useEffect(() => {
    forceCheck();
  }, [renderedGridContent]);

  /*  const applySearchInput = useCallback(
    (collectionsArray: CollectionObj[]) => {
      if (searchInput.length > 0) {
        const rgxp = new RegExp(searchInput, "gi");
        const searchCollections = collectionsArray.filter(
          (collection: CollectionObj) => collection.name?.match(rgxp)
        );
        return searchCollections;
      } else {
        return collectionsArray;
      }
    },
    [searchInput]
  ); */

  const handleCollectionClicked = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      collection: CollectionObj
    ) => {
      const event = e.target as HTMLElement;
      if (event.tagName == "svg" || event.tagName == "BUTTON") {
        //ignoe the click when it comes from the more button on the card
        return;
      }
      if (collection.publicToken) {
        window.open(
          getCollectionPublicAccessUrlFromAccessToken(collection.publicToken),
          "_blank"
        );
      }
      /*  if (displayArchivedCollections) {
        history.push("/board/archives/collection/" + collection.id);
      } else {
        history.push(getCollectionRoute(collection.id));
      } */
    },
    []
  );

  if (loading) {
    return (
      <Box
        sx={{
          marginLeft: theme.spacing(16),
          marginRight: theme.spacing(16),
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <LoadingCollections listView={selectedViewType == VIEW_TYPE.LIST} />
      </Box>
    );
  }

  if (emptyResult && !error && !loading) {
    //return <></>;
    {
      /* <MainContainer>
        <Box
          sx={{
            marginLeft: theme.spacing(16),
            marginRight: theme.spacing(16),
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CollectionsEmpty />
        </Box>
      </MainContainer> */
    }
  }

  return (
    <MainContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
        }}
      >
        {!emptyResult && (
          <Divider
            sx={{
              width: "100%",
              marginBottom: "16px",
            }}
          />
        )}
        <Grid
          container
          sx={{
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            {/* <CollectionsFilterControl
              initialViewType={getCollectionsViewType()}
              onSortTypeChanged={(sortType: SORT_TYPE) => {
               // setSortType(sortType);
              }}
              onViewTypeChanged={(viewType: VIEW_TYPE) => {
                setSelectedViewType(viewType);
              }}
              disableSortOptions
              disableVisibilityOption
              
            /> */}
          </div>
          {!readonly && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              key="add"
              style={{
                transition: theme.transitions.create("all", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              }}
            >
              <ListItemButton
                //onClick={onClick}
                sx={{
                  borderRadius: "16px",
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  textTransform: "uppercase",
                }}
                onClick={(e) => setOpenSelectCollection(true)}
              >
                {t("add_collection_action")}
              </ListItemButton>
            </Grid>
          )}

          {data && renderedGridContent}
        </Grid>
        {openSelectCollection && (
          <SelectCollectionContainer
            open={openSelectCollection}
            title={t("select_collection_container_title")}
            aggDataObjArray={[]}
            handleCollectionSelected={(collection: CollectionObj) => {
              setOpenPublishCollectionModal(collection);
            }}
            handleModalClose={() => {
              //setDisplaySelectCollectionModal(false);
              setOpenSelectCollection(false);
            }}
            filterToOwnedByCurrentUser
            filterPublicCollections
            hideCreate
          />
        )}
        {openPublishCollectionModal && (
          <PublishCollectionModal
            open={Boolean(openPublishCollectionModal)}
            collection={openPublishCollectionModal}
            handleModalClose={() => {
              setOpenPublishCollectionModal(null);
              setOpenSelectCollection(false);
            }}
          />
        )}
      </Box>
      {displaySaveCollectionConfirmation && (
        <SavePublicCollectionConfirmationDialog
          collectionName={currentCollectionToSave.current!!.name}
          publicCollectionId={currentCollectionToSave.current!!.publicToken}
          collectionNameExistsAlready={
            checkCanSavePublicCollectionResult.data?.canSavePublicCollection
              .collectionNameExists
          }
          open={displaySaveCollectionConfirmation}
          handleModalClose={() => {
            setDisplaySaveCollectionConfirmation(false);
          }}
        />
      )}
      {displayUserAlreadyOwnsCollection && (
        <SimpleConfirmationDialog
          open={displayUserAlreadyOwnsCollection}
          title={t("save_public_collection_dialog_title", {
            collection_name: currentCollectionToSave.current!!.name,
          })}
          description={t("public_collection_already_owned_msg")}
          handleOk={() => {
            setDisplayUserAlreadyOwnsCollection(false);
          }}
          handleModalClose={() => {
            setDisplayUserAlreadyOwnsCollection(false);
          }}
          hideCancel
        />
      )}
      {openUpgradePlanDialog && (
        <MaxDataUsageLimitReachedDialog
          open={Boolean(openUpgradePlanDialog)}
          description={openUpgradePlanDialog}
          handleModalClose={() => {
            setOpenUpgradePlanDialog(null);
          }}
        />
      )}
    </MainContainer>
  );
};

interface CollectionItemLayoutProps {
  collection: CollectionObj;
  handleCollectionClicked: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    collection: CollectionObj
  ) => void;
  handleSuspendPublicAccess: (collection: CollectionObj) => void;
  readonly: boolean;
  handleSaveCollection: (collection: CollectionObj) => void;
}

const CollectionItemLayout = ({
  collection,
  handleCollectionClicked,
  handleSuspendPublicAccess,
  handleSaveCollection,
  readonly,
}: CollectionItemLayoutProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [itemHovered, setItemHovered] = React.useState(false);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      key={collection.id}
      style={{
        transition: theme.transitions.create("all", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: "16px",
        marginLeft: "16px",
        padding: 0,
      }}
    >
      <LazyLoad key={collection.id} height={100} offset={600} once>
        {/* <CollectionLayout
        onClick={(e) => handleCollectionClicked(e, collection)}
        key={collection.id!!}
        collection={collection}
        listLayout={selectedViewType == VIEW_TYPE.LIST}
      /> */}

        <ListItemButton
          //onClick={onClick}
          sx={{
            borderRadius: "16px",
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
          onMouseEnter={() => {
            setItemHovered(true);
          }}
          onMouseLeave={() => {
            setItemHovered(false);
          }}
          onClick={(e) => handleCollectionClicked(e, collection)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div /* onClick={onClick} */>
              <ImagePreview
                previewUrls={collection.previewUrls}
                hideBorder={false}
                height="48px"
                width="48px"
                imgSpacing="0px"
              />
            </div>
            <div
              //onClick={onClick}
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                flexGrow: 1,
                marginLeft: "16px",
                alignItems: "center",
              }}
            >
              <CollectionTitle
                style={{
                  width: "auto",
                }}
              >
                {collection.name}
              </CollectionTitle>
              <CollectionSubTitle
                style={{
                  marginLeft: "8px",
                }}
              >
                {"(" +
                  (collection.itemsCount ? collection.itemsCount : "0") +
                  ")"}
              </CollectionSubTitle>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            {!readonly && (
              <Tooltip
                title={styledTooltipTitle(t("unpublish_collection_hint"))}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSuspendPublicAccess(collection);
                  }}
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            {readonly && itemHovered && (
              <Tooltip title={styledTooltipTitle(t("save_to_my_memento_hint"))}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSaveCollection(collection);
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </ListItemButton>
      </LazyLoad>
    </Grid>
  );
};

export default React.memo(PublicCollectionsContainer);

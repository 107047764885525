/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from "@apollo/client";

//Value of error returned from the backend when the user has consumed all his data usage and tries to add new data.
export const MAX_DATA_USAGE_REACHED_ERROR = 1;
export const NOT_ALLOWED_READ_ONLY_ERROR = 3;

export const retrieveTopLevelCollectionsOwnedByMeGql = gql`
  query {
    topLevelCollectionsOwnedByMe {
      id
      name
      isArchived
      itemsCount
      pinScore
      lastUsedScore
      previewUrls
      created
      lastActive
      isPublic
      isNested
      owner {
        id
        username
        email
      }
      sharedUsers {
        id
        right
        userRegistered
        created
        user {
          id
          username
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const retrieveTopLevelCollectionsGql = gql`
  query {
    topLevelCollections {
      id
      name
      isArchived
      itemsCount
      pinScore
      lastUsedScore
      previewUrls
      created
      lastActive
      isPublic
      isNested
      owner {
        id
        username
        email
      }
      sharedUsers {
        id
        right
        userRegistered
        created
        user {
          id
          username
          email
          firstName
          lastName
        }
      }
    }
  }
`;



export const retrievePublicChildCollectionsForParentGql = gql`
  query($parentId: ID) {
    publicChildCollections(parentId: $parentId) {
      id
      name
      isArchived
      itemsCount
      pinScore
      lastUsedScore
      previewUrls
      created
      lastActive
      isPublic
      isNested
      publicToken
      owner {
        id
        username
        email
      }
      sharedUsers {
        id
        right
        userRegistered
        created
        user {
          id
          username
          email
          firstName
          lastName
        }
      }
    }
  }
`;


export const retrieveChildCollectionsForParentGql = gql`
  query($parentId: ID) {
    childCollections(parentId: $parentId) {
      id
      name
      isArchived
      itemsCount
      pinScore
      lastUsedScore
      previewUrls
      created
      lastActive
      isPublic
      isNested
      owner {
        id
        username
        email
      }
      sharedUsers {
        id
        right
        userRegistered
        created
        user {
          id
          username
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const retrieveMyPublicProfileGql = gql`
  query {
    publicProfile {
      id
      description
      enabled
      socialMediaUrls
      presentationVideoUrl
      background
      uiItems
    }
  }
`;

export const retrievePublicUserProfileGql = gql`
  query($username: String) {
    publicProfile(username: $username) {
      id
      description
      enabled
      socialMediaUrls
      presentationVideoUrl
      background
      uiItems
    }
  }
`;
export const retrievePublicCollectionsForUsernameGql = gql`
  query($username: String) {
    publicCollectionsForUsername(username: $username) {
      id
      name
      publicToken
      itemsCount
      previewUrls
    }
  }
`;

export const retrieveAllAggDataTagsGql = gql`
  query {
    allTags {
      name
    }
  }
`;

export const retrieveTagsForPublicCollectionGql = gql`
  query($collectionId: String) {
    aggdataTagsForPublicCollection(collectionId: $collectionId) {
      name
    }
  }
`;

export const retrieveTagsForCollectionGql = gql`
  query($collectionId: ID) {
    tagsForCollection(collectionId: $collectionId) {
      name
    }
  }
`;

export const retrieveUserSettingsGql = gql`
  query {
    userSettings {
      aiClassificationEnabled
      aiTagsClassificationEnabled
    }
  }
`;

export const retrievePublicCollectionInfoFromCollectionIdGql = gql`
  query($collectionId: String) {
    publicCollectionData(collectionId: $collectionId) {
      accessToken
    }
  }
`;

export const retrievePublicCollectionInfo = gql`
  query($token: String) {
    publicCollectionData(token: $token) {
      collection {
        id
        name
        isPublic
        itemsCount
      }
    }
  }
`;

export const retrievePublicAggdataForCollection = gql`
  query($collectionId: String!) {
    aggdataForPublicCollection(collectionId: $collectionId) {
      id
      isArchived
      isResolved
      annotation
      url
      dataType
      dataContent
      sortOrderScore
      allItemsPinScore
      commentsCount
      listItemsPinScore

      created
      image
      reminders
      collection {
        id
        name
        isPublic
        lastActive
        isArchived
        itemsCount
        pinScore
        lastUsedScore
        previewUrls
        created
        owner {
          id
          username
        }
        sharedUsers {
          id
          right
          userRegistered
          created
          user {
            id
            email
            username
            firstName
            lastName
          }
        }
      }
      note {
        id
        noteColor
        noteTitle
        noteContent
        lastEdited
        noteTasks
        noteimageSet {
          id
        }
      }
      file {
        pagesCount
        fileName
        length
      }
      owner {
        id
        userInitials
        firstName
        lastName
      }
      tags {
        name
      }
      sharedUsers {
        id
        user {
          id
          username
          email
          userInitials
        }
      }
    }
  }
`;

export const retrieveCommentsNotificationsForCollectionGql = gql`
  query($collectionId: String!, $page: Int!, $pageSize: Int!) {
    commentsForCollectionPaged(
      page: $page
      pageSize: $pageSize
      collectionId: $collectionId
    ) {
      content
      user {
        id
        firstName
        lastName
        username
        email
        userInitials
      }
      aggdata {
        id
        previewUrl
      }
      created
    }
  }
`;

export const retrieveCommentsForAggdataIdGql = gql`
  query($id: String!, $page: Int!, $pageSize: Int!) {
    aggdataCommentsPaged(dataId: $id, page: $page, pageSize: $pageSize) {
      content
      user {
        id
        firstName
        lastName
        username
        email
        userInitials
      }
      created
      id
    }
  }
`;

//Query only works with the cache, and note with the backend.
//Works thanks to cache redirection specified in index.js
export const retrieveAggdataForIdFromCacheGql = gql`
  query($id: String!) {
    aggdata(id: $id) {
      id
      isArchived
      isResolved
      annotation
      url
      dataType
      dataContent
      sortOrderScore
      allItemsPinScore
      commentsCount
      listItemsPinScore

      created
      image
      reminders
      tags {
        name
      }
      collection {
        id
        name
        lastActive
        isPublic
        itemsCount
        previewUrls
      }
      owner {
        id
        username
        firstName
        lastName
        email
        userInitials
      }
      note {
        id
        noteColor
        noteTitle
        noteContent
        lastEdited
        noteTasks
        noteimageSet {
          id
        }
      }
      file {
        pagesCount
        fileName
        length
      }
    }
  }
`;

export const retrieveAllAggDataWithRermindersShared = gql`
  query {
    aggdatasSharedWithReminders {
      id
      isArchived
      isResolved
      annotation
      url
      dataType
      dataContent
      sortOrderScore
      allItemsPinScore
      commentsCount
      listItemsPinScore

      tags {
        name
      }
      created
      image
      reminders
      collection {
        id
        name
        lastActive
        isPublic
        itemsCount
        previewUrls
        sharedUsers {
          id
          right
          user {
            email
            id
            username
          }
        }
      }
      note {
        id
        noteColor
        noteTitle
        noteContent
        lastEdited
        noteTasks
        noteimageSet {
          id
        }
      }
      file {
        pagesCount
        fileName
        length
      }
      owner {
        firstName
        lastName
        email
        id
        username
        userInitials
      }
      sharedUsers {
        id
        user {
          email
          id
          username
          userInitials
        }
      }
    }
  }
`;

export const retrieveAllDataGql = gql`
  query {
    allAggdata {
      id
      isArchived
      isResolved
      annotation
      url
      dataType
      dataContent
      sortOrderScore
      allItemsPinScore
      commentsCount
      listItemsPinScore

      created
      image
      reminders
      tags {
        name
      }
      collection {
        id
        name
        lastActive
        isPublic
        isArchived
        itemsCount
        pinScore
        lastUsedScore
        previewUrls
        created
        owner {
          email
          id
          username
        }
        sharedUsers {
          id
          right
          userRegistered
          created
          user {
            email
            id
            username
            firstName
            lastName
          }
        }
      }
      note {
        id
        noteColor
        noteTitle
        noteContent
        lastEdited
        noteTasks
        noteimageSet {
          id
        }
      }
      file {
        pagesCount
        fileName
        length
      }
      owner {
        email
        id
        username
        userInitials
        firstName
        lastName
      }
      sharedUsers {
        id
        user {
          email
          id
          username
          userInitials
        }
      }
    }
  }
`;

export const retrieveAllArchivedDataGql = gql`
  query {
    allArchivedAggdata {
      id
      isArchived
      isResolved
      annotation
      url
      dataType
      dataContent
      sortOrderScore
      allItemsPinScore
      commentsCount
      listItemsPinScore

      created
      image
      reminders
      tags {
        name
      }
      collection {
        id
        name
        lastActive
        isPublic
        isArchived
        itemsCount
        previewUrls
      }
      owner {
        id
        username
        email
      }
      note {
        id
        noteColor
        noteTitle
        noteContent
        noteTasks
        lastEdited
        noteimageSet {
          id
        }
      }
      file {
        pagesCount
        fileName
        length
      }
    }
  }
`;

//user to query the cache only
export const retrieveAggDataGql = gql`
  query AggData($id: String!) {
    allAggdata(id: $id) {
      id
      isArchived
      isResolved
      annotation
      url
      dataType
      dataContent
      sortOrderScore
      allItemsPinScore
      commentsCount
      listItemsPinScore

      created
      image
      reminders
      tags {
        name
      }
      collection {
        id
        name
        lastActive
        isPublic
        itemsCount
        previewUrls
        sharedUsers {
          id
          right
          userRegistered
          user {
            id
            username
            email
            firstName
            lastName
          }
        }
      }
      note {
        id
        noteColor
        noteTitle
        noteContent
        lastEdited
        noteTasks
        noteimageSet {
          id
        }
      }
      file {
        pagesCount
        fileName
        length
      }
    }
  }
`;

export const retrieveNoteGql = gql`
  query Note($id: Int!) {
    note(id: $id) {
      id
      noteColor
      noteTitle
      noteContent
      lastEdited
      noteTasks
    }
  }
`;

export const retrieveAggdataForCollectionIds = gql`
  query($collectionId: String!) {
    aggdataForCollection(collectionId: $collectionId) {
      id
      collection {
        id
      }
    }
  }
`;

export const retrieveAggdataForCollectionGql = gql`
  query($collectionId: String!) {
    aggdataForCollection(collectionId: $collectionId) {
      id
      isArchived
      isResolved
      annotation
      url
      dataType
      dataContent
      sortOrderScore
      allItemsPinScore
      commentsCount
      listItemsPinScore

      created
      image
      reminders
      tags {
        name
      }
      collection {
        id
        name
        lastActive
        isPublic
        isArchived
        itemsCount
        pinScore
        lastUsedScore
        previewUrls
        created
        owner {
          id
          username
          email
        }
        sharedUsers {
          id
          right
          userRegistered
          created
          user {
            id
            username
            email
            firstName
            lastName
          }
        }
      }
      note {
        id
        noteColor
        noteTitle
        noteContent
        lastEdited
        noteTasks
        noteimageSet {
          id
        }
      }
      file {
        pagesCount
        fileName
        length
      }
      owner {
        firstName
        lastName
        id
        username
        email
      }
      sharedUsers {
        id
        user {
          id
          username
          email
          userInitials
        }
      }
    }
  }
`;

// export const retrieveAggdataForCollectionGql = gql`
//     mutation($collectionId: String!){
//       aggdataForCollection(collectionId :$collectionId){
//         aggDataForCollection{
//         id
//         isArchived
//         isResolved
//         url
//         dataType
//         dataContent
//         sortOrderScore
//         allItemsPinScore
//         listItemsPinScore
//
//         created
//         image
//         reminders
//         collection {
//           id
//           name
//           itemsCount
//           previewUrls
//         }
//         note{
//           id
//           noteColor
//           noteTitle
//           noteContent
//           lastEdited
//           noteTasks
//         }
//         file {
//           pagesCount
//           fileName
//         }
//         owner {
//           userInitials
//         }
//         sharedUsers {
//           user {
//             email
//             userInitials
//           }
//         }
//       }
//       }
//     }`
//   ;

export const retrieveAllCollectionsGql = gql`
  query {
    collections {
      id
      name
      isArchived
      itemsCount
      pinScore
      lastUsedScore
      previewUrls
      created
      lastActive
      isPublic
      isNested
      owner {
        id
        username
        email
      }
      sharedUsers {
        id
        right
        userRegistered
        created
        user {
          id
          username
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const retrieveCollectionGql = gql`
  query Collection($id: String!) {
    collection(id: $id) {
      id
      name
      isArchived
      itemsCount
      pinScore
      lastUsedScore
      previewUrls
      created
      lastActive
      isPublic
      owner {
        id
        username
        email
      }
      sharedUsers {
        id
        right
        userRegistered
        created
        user {
          id
          username
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const retrieveAllTopLevelArchivedCollectionsGql = gql`
  query {
    archivedTopLevelCollections {
      id
      name
      isArchived
      itemsCount
      pinScore
      lastUsedScore
      previewUrls
      created
      isNested
      owner {
        id
        username
        email
      }
    }
  }
`;

export const retrieveAllArchivedCollectionsGql = gql`
  query {
    archivedCollections {
      id
      name
      isArchived
      itemsCount
      pinScore
      lastUsedScore
      previewUrls
      created
      isNested
      owner {
        id
        username
        email
      }
    }
  }
`;

export const retrieveCollectionGqlCache = gql`
  query Collection($id: String!) {
    collections(id: $id) {
      id
      name
      isArchived
      itemsCount
      previewUrls
      created
      isPublic
    }
  }
`;

export const retrieveCollectionsForIds = gql`
  query($input: [String]) {
    collectionsForIds(ids: $input) {
      id
      itemsCount
      previewUrls
    }
  }
`;

import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import useScrollBlock from "hooks/scrollHook";
import { closeImageViewer, closeNoteImageViewer } from "store/actions/view";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AggDataObj, NoteImage } from "models/aggdata";
import {
  getImageForDataId,
  getDownloadLinkForDataId,
  getNoteImageForId,
  getDownloadLinkForNoteImageId,
} from "configuration";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import {
  deleteAggDataGql,
  deleteNoteImagesGql,
  updateAggDataGql,
} from "graphql/mutations";
import { retrieveCollectionGql } from "graphql/queries";
import { useMutation } from "@apollo/client";
import {
  deleteAggDataFromCache,
  addAggDataInArchivedDataCache,
  addAggDataInCache,
  deleteAggDataFromArchivedDataCache,
  updateAggDataInCache,
} from "graphql/helpers";
import { showSnackbar } from "store/actions/view";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import { openAttachDataToCollectionDialog } from "store/actions/view";
import { CollectionObj } from "models/collection";
import { RootState } from "store/reducers/index";

interface NoteImagesViewerProps {
  noteImagesArray: NoteImage[];
  startNoteImage: NoteImage;
  aggData: AggDataObj;
  title: string;
  inPublicMode?: boolean; //by default set to false. Set to true when this is opened from a public collection view
}

const ZOOM_MAX = 300;
const ZOOM_MIN = 100;
const ZOOM_STEP = 25;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    zIndex: 802,
    display: "flex",
    flexDirection: "column",
    flexFlow: "column",
    overflow: "hidden",
  },
  topToolbarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "70px",
    width: "100%",
    alignItems: "center",
  },
  backButton: {
    color: "white",
    width: "48px",
    height: "48px",

    "&.MuiIconButton-root": {
      marginTop: "12px",
      marginLeft: "8px",
    },

    "&:hover.MuiIconButton-root": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },
  arrowButtons: {
    color: "white",
    width: "48px",
    height: "48px",

    "&.MuiIconButton-root": {
      margin: "16px",
    },

    "&:hover.MuiIconButton-root": {
      backgroundColor: "rgba(253, 200, 75, 0.3)",
    },
  },
  toolButton: {
    color: "white",
    width: "32px",
    height: "32px",

    "&.MuiIconButton-root": {
      marginTop: "12px",
      marginLeft: "8px",
      marginRight: "18px",
    },

    "&:hover.MuiIconButton-root": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },

  mainContainer: {
    width: "100%",
    flex: 1.5,
    overflow: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainImage: {
    transition: theme.transitions.create(["height"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // "-ms-transform": "scale(1.5)", /* IE 9 */
    // "-webkit-transform": "scale(1.5)", /* Safari 3-8 */
    // transform: "scale(1.5)"
  },
}));

export const OpaqueOverlay = styled.div`
  position: fixed;
  display: absolute;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 801;
`;

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const NoteImagesViewer = ({
  noteImagesArray,
  startNoteImage,
  aggData,
  title,
  inPublicMode = false,
}: NoteImagesViewerProps) => {
  const classes = useStyles();
  const [blockScroll, allowScroll] = useScrollBlock();
  const forceUpdate = useForceUpdate();
  const [navIndex, setNavIndex] = useState(
    noteImagesArray.indexOf(startNoteImage) > 0
      ? noteImagesArray.indexOf(startNoteImage)
      : 0
  );
  const { t } = useTranslation();
  const [imageZoom, setImageZoom] = useState(100);
  const [displayedTitle, setDisplayedTitle] = useState("");
  const [updateAggData, updateAggDataResult] = useMutation(updateAggDataGql);
  const [deleteNoteImages, deleteNoteImagesResult] = useMutation(
    deleteNoteImagesGql
  );

  const currentlyOpenedCollectionCurrentUserRight: number = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollectionCurrentUserRight
  );
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );

  const dispatch = useDispatch();

  const handleBackPressed = useCallback(() => {
    dispatch(closeNoteImageViewer());
  }, []);

  const handleNextClicked = useCallback(() => {
    if (navIndex < noteImagesArray.length - 1) {
      setNavIndex(navIndex + 1);
    }
  }, [navIndex]);

  const handlePreviousClicked = useCallback(() => {
    if (navIndex > 0) {
      setNavIndex(navIndex - 1);
    }
  }, [navIndex]);

  const updateTitle = useCallback(() => {
    setDisplayedTitle(
      " (" + (navIndex + 1) + "/" + noteImagesArray.length + ")"
    );
  }, [noteImagesArray, navIndex, forceUpdate]);

  React.useEffect(() => {
    updateTitle();
  }, [updateTitle, forceUpdate]);

  const handleKeyboardEvent = useCallback(
    (e: any) => {
      e = e || window.event;
      switch (e.key) {
        case "ArrowLeft":
          handlePreviousClicked();
          break;
        case "ArrowRight":
          handleNextClicked();
          break;
        case "Escape":
          handleBackPressed();
          break;
      }
    },
    [handlePreviousClicked, handleNextClicked, handleBackPressed]
  );

  React.useEffect(() => {
    const scroll = window.scrollY;
    blockScroll();
    document.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      allowScroll();
      document.removeEventListener("keydown", handleKeyboardEvent);
      window.scroll(0, scroll); //we restore the scroll position.
    };
  }, [handleKeyboardEvent]);

  const handleClickOutside = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const event = e.target as HTMLElement;
      if (event.tagName == "DIV") {
        handleBackPressed();
      }
    },
    [handleBackPressed]
  );

  const zoomIn = useCallback(() => {
    if (imageZoom < ZOOM_MAX) {
      setImageZoom(imageZoom + ZOOM_STEP);
    }
  }, [imageZoom, setImageZoom]);

  const zoomOut = useCallback(() => {
    if (ZOOM_MIN < imageZoom) {
      setImageZoom(imageZoom - ZOOM_STEP);
    }
  }, [imageZoom, setImageZoom]);

  const handleDownloadClicked = useCallback(() => {
    window.open(
      getDownloadLinkForNoteImageId(noteImagesArray[navIndex].id),
      "_blank"
    );
  }, [noteImagesArray, navIndex]);

  const handleDelete = useCallback(async (): Promise<void> => {
    //Following a delete we update the navIndex to force a new render
    function updateNavIndex() {
      if (navIndex - 1 > 0) {
        setNavIndex(navIndex - 1);
      } else {
        if (noteImagesArray.length > 0) {
          setNavIndex(0);
          forceUpdate();
        } else {
          handleBackPressed();
        }
      }
    }
    const currentlyDisplayedNote = noteImagesArray[navIndex];
    /* if (aggData.collection) {
        deleteNoteImages({
        variables: { ids: [aggData.id]},
        refetchQueries: [
          {
            query: retrieveCollectionGql,
            variables: { id: aggData.collection.id },
          },
        ],
        update(cache, { data }) {
          deleteAggDataFromCache(cache, aggData);
        },
      }).then((data) => {
        noteImagesArray.splice(navIndex, 1);
        updateNavIndex();
      });
    } else {
      
    } */

    dispatch(
      showSnackbar({
        message: t("deletion_ongoing_msg"),
        action: "",
        onAction: null,
        loading: true,
      })
    );
    deleteNoteImages({
      variables: { ids: [currentlyDisplayedNote.id], aggdataId: aggData.id },
      update(cache, { data }) {
        const newNoteImagesArray = aggData.note.noteimageSet.filter(
          (noteImage) => currentlyDisplayedNote.id != noteImage.id
        );
        const newAggdata: AggDataObj = {
          ...aggData,
          note: {
            ...aggData.note,
            noteimageSet: newNoteImagesArray,
          },
        };
        updateAggDataInCache(cache, newAggdata);
      },
    })
      .then((result) => {
        if (result.data.deleteNoteImages.result) {
          dispatch(
            showSnackbar({
              message: t("snackbar_agg_data_deleted_msg"),
              action: "",
              onAction: null,
              loading: false,
            })
          );
        }
        noteImagesArray.splice(navIndex, 1);
        updateNavIndex();
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("deletion_failed"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [navIndex, noteImagesArray, deleteNoteImages, handleBackPressed]);

  return (
    <>
      <OpaqueOverlay />
      <div className={classes.root}>
        <div className={classes.topToolbarContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              className={classes.backButton}
              onClick={handleBackPressed}
            >
              <ArrowBackIcon
                style={{ width: "36px", height: "36px", color: "white" }}
              ></ArrowBackIcon>
            </IconButton>

            <Typography
              style={{ color: "white", marginLeft: "12px" }}
              variant="h4"
            >
              {title}
            </Typography>
            <Typography
              style={{ color: "white", marginLeft: "12px" }}
              variant="h5"
            >
              {displayedTitle}
            </Typography>
          </div>

          {/**  =========== ZOOM CONTAINER  ============ */}
          <div>
            <IconButton className={classes.toolButton} onClick={zoomOut}>
              <ZoomOutIcon
                style={{ width: "28px", height: "28px", color: "white" }}
              ></ZoomOutIcon>
            </IconButton>
            <IconButton className={classes.toolButton} onClick={zoomIn}>
              <ZoomInIcon
                style={{ width: "28px", height: "28px", color: "white" }}
              ></ZoomInIcon>
            </IconButton>
          </div>

          {/** =========== TOOLS ======== */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="bottom"
              title={styledTooltipTitle(t("action_download"))}
            >
              <IconButton
                className={classes.toolButton}
                onClick={handleDownloadClicked}
              >
                <DownloadIcon
                  style={{ width: "28px", height: "28px", color: "white" }}
                ></DownloadIcon>
              </IconButton>
            </Tooltip>
            {(!currentlyOpenedCollection ||
              currentlyOpenedCollectionCurrentUserRight > 0) &&
              !inPublicMode && (
                <Tooltip
                  placement="bottom"
                  title={styledTooltipTitle(t("action_delete"))}
                >
                  <IconButton
                    className={classes.toolButton}
                    onClick={handleDelete}
                  >
                    <DeleteOutlinedIcon
                      style={{ width: "28px", height: "28px", color: "white" }}
                    ></DeleteOutlinedIcon>
                  </IconButton>
                </Tooltip>
              )}
          </div>
        </div>
        <div className={classes.mainContainer} onClick={handleClickOutside}>
          <IconButton
            className={classes.arrowButtons}
            onClick={handlePreviousClicked}
          >
            {navIndex > 0 && (
              <ArrowBackIosNewIcon
                style={{ width: "42px", height: "42px", color: "white" }}
              ></ArrowBackIosNewIcon>
            )}
          </IconButton>

          <div
            style={{
              height: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={getNoteImageForId(noteImagesArray[navIndex].id)}
              className={classes.mainImage}
              style={{
                display: "flex",
                justifyContent: "center",
                height: imageZoom == ZOOM_MIN ? "auto" : imageZoom + "%",
                width: imageZoom == ZOOM_MIN ? "100%" : "auto",
                maxHeight: imageZoom == ZOOM_MIN ? "100%" : undefined,
              }}
              loading="lazy"
            />
          </div>

          <IconButton
            className={classes.arrowButtons}
            onClick={handleNextClicked}
          >
            {navIndex < noteImagesArray.length - 1 && (
              <ArrowForwardIosIcon
                style={{ width: "42px", height: "42px", color: "white" }}
              ></ArrowForwardIosIcon>
            )}
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default NoteImagesViewer;

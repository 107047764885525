import React, { useCallback, useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import { MAINBOARD } from "navigation/Constants";
import GoogleIcon from "icons/google_icon.svg";
import LogoIcon from "icons/ic_memento_m_letter.svg";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { login } from "api/authApi";
import { loginUser as loginReducerAction } from "store/actions/authActions";
import { useDispatch } from "react-redux";
import GoogleLogin from "react-google-login";
import Alert from "@mui/material/Alert";
import { handleGoogleLoginResponse } from "api/authApi";
import { UserObj } from "../../models/user";
import { fetchUserData, LoginApiResult } from "api/authApi";
import { REGISTER, REQUEST_RESET_PASSWORD } from "navigation/Constants";
import { PASSWORD_MIN_LENGTH, DEBUG, SERVER_URL, GOOGLE_LOGIN_KEY } from "configuration";
import { useTranslation } from "react-i18next";
import { LoginStatusCode } from "api/authApi";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/index";
import { setPathAfterLogin } from "store/actions/view";
import { useApolloClient } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import Cookies from "js-cookie";
import styled from "styled-components";
import { colorMementoBeige, colorMemento } from "shared/colors";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CreateAccountConfirmationDialog from "components/UI/Modals/CreateAccountConfirmationDialog";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from "shared/utility";

interface SignInFormData {
  email: string;
  password: string;
}

interface LoginResult {
  loginFailed: boolean;
  errorMsg: string;
}

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(
    90deg,
    rgba(255, 241, 206, 1) 0%,
    rgba(255, 253, 250, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  padding-top: 5%;
  overflow-y: hidden;
`;

const FooterContainer = styled.div`
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const INITIAL_LOGIN_STATUS = {
  loginFailed: false,
  errorMsg: "",
};
const Login: React.FC = () => {
  const [loginsStatus, setLoginStatus] =
    useState<LoginResult>(INITIAL_LOGIN_STATUS);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const afterLoginPath = useSelector(
    (state: RootState) => state.view.pathAfterLogin
  );

  const [openCreateAccount, setOpenCreateAccount] = React.useState(false);

  const userGoogleAccountFirstName = React.useRef();
  const userGoogleAccountEmail = React.useRef();
  const userGoogleAccessToken = React.useRef();

  const client = useApolloClient();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setIsLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email(t("enter_valid_email_error"))
            .required(t("email_required_error")),
          password: Yup.string()
            .min(
              PASSWORD_MIN_LENGTH,
              t("password_too_short_error", {
                password_min_length: PASSWORD_MIN_LENGTH,
              })
            )
            .required(t("password_required_error")),
        });

        await schema.validate(data, { abortEarly: false });
        login(data.email, data.password).then((result: LoginApiResult) => {
          console.log("login result : " + JSON.stringify(result));
          if (result.statusCode > 0) {
            setLoginFailed(getLoginError(result.statusCode));
          } else if (result.statusCode == 0) {
            setLoginSucceeded();
          }
          if (result == null) {
            console.log("login failed !");
          } else {
            console.log("login result " + JSON.stringify(result));
          }
        });
      } catch (err) {
        setIsLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        console.log("Error occured during login ! ");
      }
    },
    [setIsLoading, history]
  );

  const getLoginError = useCallback((statusCode: number) => {
    switch (statusCode) {
      case LoginStatusCode.NO_ACCOUNT_FOUND_FOR_EMAIL.valueOf():
        return t("login_no_account_exists_error");
      case LoginStatusCode.WRONG_PASSWORD.valueOf():
        return t("login_wrong_password_error");
      default:
        return t("login_unknown_error");
    }
  }, []);

  const onGoogleLoginSuccess = (accessToken: any, createAccountIfNotExists = false) => {
    setLoginStatus(INITIAL_LOGIN_STATUS);
    setIsLoading(true);
    setTimeout(() => {
      //this is just a hack.. to avoid error on backend token used too early
      const response = handleGoogleLoginResponse(accessToken, createAccountIfNotExists);
      response
        .then((result: any) => {
          console.log("got result momoxx %o", result)
          if (result.status === 200 && result.data.status_code == 0) {
            setLoginSucceeded();
          } else if (result.status == 200 && result.data.status_code == 5) {
            userGoogleAccountFirstName.current = result.data.first_name;
            userGoogleAccountEmail.current = result.data.email;
            userGoogleAccessToken.current = accessToken;
            setOpenCreateAccount(true);
          } else {
            setLoginFailed(t("google_login_failed_msg"));
          }
          if (DEBUG) {
            console.log("Got a result :" + JSON.stringify(result));
          }
          setIsLoading(false);
        })
        .catch((e: any) => {
          setIsLoading(false);
        });
    }, 4000);
  };

  const setLoginFailed = useCallback((errorMsgArg: string) => {
    setLoginStatus({ loginFailed: true, errorMsg: errorMsgArg });
    setIsLoading(false);
  }, []);

  const setLoginSucceeded = () => {
    fetchUserData()
      .then((user: UserObj) => {
        dispatchLogin(user);
        if (DEBUG) {
          console.log("will move to path " + afterLoginPath);
        }

        const uri = SERVER_URL + "/graphql";
        const uploadLink = createUploadLink({
          uri: uri,
          credentials: "include",
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
            // Authorization:
            //   "Token 3d9e16e9bd49539aefb2648535e3529ce9920293b372c7c6d7d8a4d2e0a67dbc",
          },
        });

        client.setLink(uploadLink);

        if (afterLoginPath && afterLoginPath.length > 0) {
          history.push(afterLoginPath);
          dispatch(setPathAfterLogin(null));
        } else {
          history.push(MAINBOARD);
        }
        if (DEBUG)
        console.log("User data is  " + JSON.stringify(user));
      })
      .catch((err) => {
        console.log("Caught an error while fetch user data" + err);
      });
  };

  const dispatchLogin = (user: UserObj) => {
    dispatch(loginReducerAction(user));
  };

  return (
    <MainContainer>
      <Helmet>
          <title>{capitalizeFirstLetter(t("login_action"))} | Memento</title>
        </Helmet>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "24px",
          background: "#f8fbf8",
          borderRadius: "8px",
          minWidth: "30%",
          "@media (max-width: 780px)": {
            minWidth: "95%",
          },
        }}
        elevation={1}
      >
        {loginsStatus.loginFailed && (
          <Alert
            style={{ width: "80%", fontSize: "1.4rem" }}
            id="error-alert"
            severity="error"
          >
            {" "}
            {loginsStatus.errorMsg}
          </Alert>
        )}
        <Link to="/">
          <img
            id="logo"
            width="82px"
            height="82px"
            src={LogoIcon}
            alt="Memento logo"
          />
        </Link>
        <Typography sx={{ marginBottom: "24px" }} variant="h4">
          {t("login_connect_title")}
        </Typography>
        <Form
          ref={formRef}
          style={{ alignItems: "center", width: "80%" }}
          onSubmit={handleSubmit}
        >
          <Input autoFocus name="email" type="text" placeholder="E-mail" />
          <Input name="password" type="password" placeholder="Password" />
          <Button type="submit" loading={isLoading}>
            {t("sign_in_title")}
          </Button>

          <Typography
            sx={{
              textAlign: "center",
              width: "100%",
              marginTop: "12px",
              marginBottom: "12px",
            }}
          >
            {t("or_text")}
          </Typography>
          <GoogleLogin
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                style={{
                  width: "100%",
                  backgroundColor: "rgb(255, 255, 255)",
                  display: "inline-flex",
                  alignItems: "center",
                  color: "rgba(0, 0, 0, 0.54)",
                  // boxShadow:
                  //   "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
                  padding: "0px",
                  borderRadius: "4px",
                  border: "1px solid transparent",
                  fontSize: "14px",
                  fontWeight: 500,
                  borderWidth: "1px",
                  borderColor: "gray",
                  fontFamily: "Roboto, sans-serif",
                }}
                type="button"
              >
                <div
                  style={{
                    marginRight: "10px",
                    background: "rgb(255, 255, 255)",
                    padding: "10px",
                    borderRadius: " 2px",
                  }}
                >
                  <img src={GoogleIcon} />
                </div>
                <span
                  style={{
                    width: "100%",
                    padding: "10px 10px 10px 0px",
                    fontWeight: 500,
                  }}
                >
                  {t("continue_with_google_title")}
                </span>
              </button>
            )}
            clientId={GOOGLE_LOGIN_KEY}
            buttonText={t("login_with_google_btn_txt")}
            onSuccess={onGoogleLoginSuccess}
          />
          <div
            style={{
              width: "100%",
              marginTop: "5%",
              marginBottom: "5%",
            }}
          >
            <Divider light variant="middle" />
          </div>
          <FooterContainer>
            <Link
              style={{
                color: "gray",
                fontWeight: "400",
                fontSize: "1.4rem",
                cursor: "pointer",
              }}
              id="forgot-password"
              to={REQUEST_RESET_PASSWORD}
            >
              {t("forgot_my_password_action")}
            </Link>
            <Typography
              sx={{
                color: colorMemento,
              }}
            >
              ●
            </Typography>
            <Link
              style={{
                color: "gray",
                fontWeight: "400",
                fontSize: "1.4rem",
                cursor: "pointer",
              }}
              id="signup"
              to={REGISTER}
            >
              {t("signup_action")}
            </Link>
          </FooterContainer>
        </Form>
      </Paper>
      <CreateAccountConfirmationDialog
        open={openCreateAccount}
        userEmail={userGoogleAccountEmail.current}
        userFirstName={userGoogleAccountFirstName.current}
        googleAccessToken={userGoogleAccessToken.current}
        handleModalClose={() => {
          setOpenCreateAccount(false)
          userGoogleAccountEmail.current = undefined
          userGoogleAccountFirstName.current = undefined
        }}
        handleCreateNewAccount={(googleAccessToken: string) => {
          setOpenCreateAccount(false)
          userGoogleAccountEmail.current = undefined
          userGoogleAccountFirstName.current = undefined
          userGoogleAccessToken.current = undefined
          onGoogleLoginSuccess(googleAccessToken, true);
        }}
      ></CreateAccountConfirmationDialog>
    </MainContainer>
  );
};

export default Login;

import React from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles, createStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Theme, useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: "flex",
      width: `calc(100% - 80px)`,
      padding: "0 40px",
    },
    col: {
      paddingLeft: "15px",
      backgroundClip: "padding-box",
      "& div": {
        marginBottom: "15px",
      },
    },
  })
);

const MainContainer = styled.div`
  margin: 117px 0 30px 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  @media (max-width: 576px) {
    margin: 80px 0 30px 0;
  }

  &:last-child {
    margin: 80px 0 30px 0;

    @media (max-width: 576px) {
      margin: 70px 0 30px 0;
    }
  }
`;

interface BreakPoints {
  default: number;
  1200: number;
  1024: number;
  768: number;
  576: number;
}

interface LoadingItemsProps {
  rowsCount?: number;
  listView?: boolean;
}

const LoadingCollections = ({
  rowsCount = 6,
  listView = false,
}: LoadingItemsProps) => {
  const BREAK_POINTS: BreakPoints = {
    default: rowsCount,
    1200: 4,
    1024: 3,
    768: 2,
    576: 1,
  };
  const dummy = [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17];
  const theme = useTheme();

  const classes = useStyles();
  return (
    <MainContainer>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {dummy.map((item) => {
          return (
            <Grid
              item
              xs={listView ? 12 : 2}
              sm={listView ? 12 : 3}
              md={listView ? 12 : 3}
              lg={listView ? 12 : 4}
              key={item}
              style={{
                transition: theme.transitions.create("all", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              }}
            >
              {!listView && (
                <>
                  <Skeleton variant="rectangular" height={178} />
                  <Skeleton variant="text" height={40} />
                </>
              )}
              {listView && (
                <div
                 style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start"
                 }}>
                  <Skeleton variant="rectangular" height={56} width={56}/>
                  <Skeleton variant="rectangular" height={56} width={818} sx={{
                    marginLeft: "12px",
                    width: "100%"
                  }} />
                </div>
              )}
            </Grid>
          );
        })}
      </Grid>
    </MainContainer>
  );
};

export default React.memo(LoadingCollections);

import React, { useState } from "react";
import styled from "styled-components";
import ChromeExtensionLogo from "icons/chrome-ext.svg";
import { colorMemento, colorGreyMemento } from "shared/colors";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import LogoIcon from "icons/memento_full.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LOGIN, PRICING, REGISTER, BLOG_URL } from "navigation/Constants";
import { isMobile } from "react-device-detect";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { landingBackgroundColor } from "shared/colors";
import { Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AndroidIcon from "@mui/icons-material/Android";
import LanguageIcon from "@mui/icons-material/Language";
import { APP_PLAYSTORE_LINK, CHROME_EXTENSION_STORE_URL } from "configuration";
import { CustomButton } from "containers/InputField/InputElements";

const HeaderContainer = styled.header`
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  top: 0;
  left: 0;
  z-index: 100;
  @media (max-width: 550px) {
    justify-content: end;
  }

  // &:hover {
  //   background-color: ${colorMemento}};
  // }
`;

const LoginRegisterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 2%;
`;

const HeaderOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2%;
`;

const LogoContainer = styled.img`
  width: 10%;
  @media (max-width: 550px) {
    width: 30%;
    margin-right: 5%;
    margin-top: 5%;
  }
`;

const MenuItemStyle = {
  color: colorGreyMemento,
  fontSize: "1.3em",
};

export const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    "& .MuiPaper-root": {
      backgroundColor: landingBackgroundColor,
    },
  },
}));

const Header = (/*{ userObj }: HeaderProp*/) => {
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [openNav, setOpenNav] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePricingClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open(PRICING, "_blank");
  };
  const handleBlogClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open(BLOG_URL, "_blank");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loginStyle = {
    color: "black",
    fontSize: "1.6em",
    whiteSpace: "nowrap",
  };

  return (
    <>
      <HeaderContainer>
        <LogoContainer src={LogoIcon} alt="Logo" />

        
          <LoginRegisterContainer>
            <HeaderOptionsContainer>
            <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleBlogClick}
                style={{
                  color: "black",
                  fontSize: "1.6em",
                }}
              >
                {t("blog_title")}
              </Button>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handlePricingClick}
                style={{
                  color: "black",
                  fontSize: "1.6em",
                }}
              >
                {t("pricing_title")}
              </Button>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                style={{
                  color: "black",
                  fontSize: "1.6em",
                }}
              >
                {t("products_title")}
              </Button>
              <Menu
                className={classes.menuPaper}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    window.open(APP_PLAYSTORE_LINK, "_blank");
                  }}
                  style={MenuItemStyle}
                >
                  <ListItemIcon>
                    <AndroidIcon
                      fontSize="large"
                      style={{
                        color: "#59c639",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography style={MenuItemStyle}>
                      {" "}
                      {t("product_for_android")}
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.open(CHROME_EXTENSION_STORE_URL, "_blank");
                  }}
                  style={MenuItemStyle}
                >
                  <ListItemIcon>
                    <img
                      src={ChromeExtensionLogo}
                      style={{ width: "24px", height: "24px" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography style={MenuItemStyle}>
                      {t("product_for_chrome_ext")}
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.open(LOGIN, "_blank");
                  }}
                  style={MenuItemStyle}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <LanguageIcon
                        fontSize="large"
                        style={{
                          color: "lightblue",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography style={MenuItemStyle}>
                      {t("product_for_web")}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </Menu>
            </HeaderOptionsContainer>
            {!isMobile && (<>
            <Button
              onClick={() => history.push(LOGIN)}
              style={loginStyle}
              size="large"
            >
              {t("login_action")}
            </Button>
            <CustomButton
              onClick={() => history.push(REGISTER)}
              style={{
                fontSize: "1.6em",
                whiteSpace: "nowrap",
                paddingRight: "12px",
                paddingLeft: "12px",
                marginLeft: "4px"
              }}
              variant="contained"
              disableElevation
            >
               {t("signup_action")}
            </CustomButton></>)}
          </LoginRegisterContainer>
        
      </HeaderContainer>
    </>
  );
};

export default Header;

import React, { useCallback } from "react";
import Modal from "@mui/material/Modal";
import { createStyles, makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { Theme } from "@mui/material/styles";
import styled from "styled-components";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { isEmailValid } from "shared/utility";
import FormHelperText from "@mui/material/FormHelperText";
import {
  shareCollectionWithUsersGql,
  generateCollectionShareLinkGql,
  queryCollectionShareLinkGql,
  suspendCollectionShareLinkGql,
  suspendPublicAccessGql,
  generatePublicAccessGql,
} from "graphql/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import {
  SharedUserAccess,
  UserAccessRight,
  getRightAsString,
} from "models/user";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { colorMemento } from "shared/colors";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { stringToColour } from "shared/colors";
import SharedUserAvatar from "components/UI/Controls/SharedUserAvatar";
import { useTranslation } from "react-i18next";
import { UserObj } from "models/user";
import { RootState } from "store/reducers";
import { useSelector, useDispatch } from "react-redux";
import {
  retrieveCollectionGql,
  retrieveAllCollectionsGql,
  retrievePublicCollectionInfoFromCollectionIdGql,
} from "graphql/queries";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import {
  deleteSharedCollectionSpecificCache,
  deleteCollectionFromCache,
  updateCollectionsCache,
  updateCollectionInCache,
} from "graphql/helpers";
import { useApolloClient } from "@apollo/client";
import {
  DEBUG,
  MAX_SHARED_COLLECTIONS_COUNT_FREE_USER,
  getCollectionPublicAccessUrlFromAccessToken,
} from "configuration";
import { isUserOnFreePlan } from "models/user";
import BoltIcon from "@mui/icons-material/Bolt";
import { useQuery } from "@apollo/client";
import { isCollectionShared, CollectionObj } from "models/collection";
import MaxDataUsageLimitReachedDialog from "components/UI/Modals/MaxDataUsageLimitReachedDialog";
import Box from "@mui/material/Box";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Tab, Tabs, Link as TextLink } from "@mui/material";
import useState from "react";
import Skeleton from "@mui/material/Skeleton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PublicIcon from "@mui/icons-material/Public";
import { borderColor } from "polished";

interface ManageUserAccessModalProps {
  open: boolean;
  collection: CollectionObj;
  sharedUserAccessList: SharedUserAccess[];
  handleModalClose: (open: boolean) => void;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

enum ManagerAccessTabs {
  INVITE = 0,
  PUBLISH = 1,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      height: "90vh",
      alignItems: "center",
      justifyContent: "center",
      "@media (max-width: 780px)": {
        width: "100%",
      },
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      maxHeight: "85%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      "@media (max-width: 780px)": {
        width: "100%",
      },
      "@media (max-width: 1480px)": {
        width: "80%",
      },
    },
    applyButton: {
      marginEnd: "12px",
      marginStart: "12px",
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingTop: "24px",
    },
  })
);

const DEFAULT_USER_ACCESS = {
  user: {
    email: "",
    firstName: "",
    lastName: "",
  },
  right: UserAccessRight.MEMBER_ACCESS_RIGHT,
  newlyAdded: true,
};
const ManageUserAccessModal = ({
  open,
  collection,
  handleModalClose,
}: ManageUserAccessModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const client = useApolloClient();
  const userObj: UserObj = useSelector((state: RootState) => state.auth.user); //current user
  const sharedUsersNetworkList = useSelector(
    (state: RootState) => state.collectionsReducer.sharedUsers
  ); //List of all users that the current user has at least a collection shared with them
  //console.log("list of suggestions " + JSON.stringify(sharedUsersNetworkList));

  const getSharedUsersList = React.useCallback(() => {
      return collection.sharedUsers ? collection.sharedUsers : []
  }, [collection]);


  const [usersAccessList, setUsersAccessList] = React.useState(
    [...getSharedUsersList()].sort(
      (sharedUserAccess1, sharedUserAccess2) =>
        sharedUserAccess2.right - sharedUserAccess1.right
    )
  );

  const [removedUsersList, setRemovedUsersList] = React.useState<string>([]);
  const history = useHistory();
  const { loading, error, data } = useQuery(retrieveAllCollectionsGql);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [
    displayMaxSharedCollectionsReached,
    setDisplayMaxSharedCollectionsReached,
  ] = React.useState(false);
  const [addButtonEnabled, setAddButtonEnabled] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [userSelfDeletedAccess, setUserSelfDeletedAccess] = React.useState(
    false
  );
  const [
    displayRemovedUserWarnings,
    setDisplayRemovedUserWarning,
  ] = React.useState(false);
  const [currentUserAccessRight] = React.useState(() => {
    const currentUserAR = getSharedUsersList().find((userAccessRight) => {
      if (userAccessRight.user.id == userObj.id) {
        return userAccessRight;
      }
    });
    if (currentUserAR) {
      return currentUserAR?.right;
    } else if (getSharedUsersList().length == 0) {
      return UserAccessRight.OWNER_ACCESS_RIGHT;
    }
    return 0;
  });

  const [newUserAccessInput, setNewUserAccessInput] = React.useState(
    DEFAULT_USER_ACCESS
  );

  const [applyButtonEnabled, setApplyButtonEnabled] = React.useState(false);

  const [
    shareCollectionWithUsers,
    shareCollectionWithUsersResult,
  ] = useMutation(shareCollectionWithUsersGql, {
    refetchQueries: [
      {
        query: retrieveCollectionGql,
        variables: {
          id: collection.id,
        },
      },
    ],
    // update(cache, { data: { updateCollection } }) {
    //   if (updateCollection) {
    //     updateCollectionInCache(cache, updateCollection.collection);
    //   }
    // },
  });

  const handleApplyNewUserAccess = React.useCallback(() => {
    setErrorMessage(null);
    setButtonLoading(true);

    const payload = usersAccessList.map((userAccess) => {
      if (DEBUG) {
        console.log("Users with access are " + JSON.stringify(usersAccessList));
      }
      return { email: userAccess.user.email, right: userAccess.right };
    });

    shareCollectionWithUsers({
      variables: {
        collectionId: collection.id,
        sharedUsers: payload,
        removedUsers: removedUsersList,
      },
    })
      .then((data) => {
        console.log("share collection result " + JSON.stringify(data));
        handleModalClose(false);
        if (userSelfDeletedAccess) {
          deleteSharedCollectionSpecificCache(client.cache, collection.id);
          deleteCollectionFromCache(client.cache, collection.id);
          history.goBack();
        }
      })
      .catch((e) => {
        setButtonLoading(false);
        setErrorMessage("Failed to apply changes");
      });
  }, [usersAccessList, userObj, userSelfDeletedAccess, removedUsersList]);

  const handleAddButtonClick = React.useCallback(() => {
    var userAlreadyAdded = false;

    const isNumberOfSharedCollectionsGreaterThan = (
      collections: CollectionObj[],
      max: number
    ) => {
      var sharedCollectionsCount = 0;
      for (var i = 0; i < collections.length; i++) {
        if (isCollectionShared(collections[i])) {
          ++sharedCollectionsCount;
        }
        if (sharedCollectionsCount >= max) {
          return true;
        }
      }
      return false;
    };

    /* if (isUserOnFreePlan(userObj)) {
      if (!data || !data.collections) {
        return;
      }
      //If the user has already more than MAX_SHARED_COLLECTIONS_COUNT_FREE_USER shared collections, and this collection was not shared before and they are trying to share it, we stop them
      if (
        isNumberOfSharedCollectionsGreaterThan(
          data.collections,
          MAX_SHARED_COLLECTIONS_COUNT_FREE_USER
        ) &&
        (!usersAccessList || usersAccessList.length == 0)
      ) {
        setDisplayMaxSharedCollectionsReached(true);
        return;
      }
    } */
    usersAccessList.map((userAccess) => {
      if (userAccess.user.email == newUserAccessInput.user.email) {
        userAlreadyAdded = true;
      }
    });
    if (userAlreadyAdded) {
      setErrorMessage(t("User already added"));
      return;
    }

    if (usersAccessList.length > 0) {
      setUsersAccessList([
        usersAccessList[0],
        newUserAccessInput,
        ...[...usersAccessList].slice(1, usersAccessList.length),
      ]);
    } else {
      setUsersAccessList([newUserAccessInput]);
    }
    //usersAccessList.splice()
    setApplyButtonEnabled(true);
    if (newUserAccessInput.user.email == userObj.email) {
      //User might put back their email address if they deleted it.
      setUserSelfDeletedAccess(false);
    }

    const index = removedUsersList.indexOf(newUserAccessInput.user.email);
    if (index > -1) {
      if (DEBUG)
        console.log(
          "Trying a previously removed user from the access list, will remove it from remove users list"
        );
      const clone = [...removedUsersList];
      clone.splice(index, 1);
      setRemovedUsersList(clone);
    }

    setInputValue("");
  }, [
    newUserAccessInput,
    userObj,
    setUsersAccessList,
    setApplyButtonEnabled,
    setInputValue,
    removedUsersList,
    data,
  ]);

  const handleUserAccessRightChanged = React.useCallback(
    (userAccess: SharedUserAccess, newAccessRight: number) => {
      setApplyButtonEnabled(true);
      const newAccessRightList = usersAccessList.map(
        (userAccessMapValue: SharedUserAccess) => {
          if (userAccessMapValue.user.email == userAccess.user.email) {
            return { ...userAccess, right: newAccessRight };
          } else {
            return userAccessMapValue;
          }
        }
      );
      setUsersAccessList(newAccessRightList);
    },
    [usersAccessList, setUsersAccessList]
  );

  const handleDeleteAccess = React.useCallback(
    (userAccess: SharedUserAccess) => {
      if (userObj.email == userAccess.user.email) {
        setUserSelfDeletedAccess(true);
      } else {
        setDisplayRemovedUserWarning(true);
      }

      const index = usersAccessList.indexOf(userAccess);
      console.log("index is " + index);
      const clone = [...usersAccessList];
      clone.splice(index, 1);
      setUsersAccessList(clone);
      setApplyButtonEnabled(true);
      const foundRemovedUserAccessInInitialList = getSharedUsersList().find(
        (sharedUserAccess) => {
          return sharedUserAccess.user.email == userAccess.user.email;
        }
      );
      if (!foundRemovedUserAccessInInitialList) {
        if (DEBUG) {
          console.log(
            "User was not event there in the actual access list for the collection, no need to add it to removed list"
          );
        }
        return;
      } else {
        setRemovedUsersList([...removedUsersList, userAccess.user.email]);
      }
    },
    [
      userObj,
      usersAccessList,
      setUsersAccessList,
      setApplyButtonEnabled,
      removedUsersList,
      getSharedUsersList,
    ]
  );

  const [currentTab, setCurrentTab] = React.useState(ManagerAccessTabs.INVITE);
  const handleTabChange = useCallback(
    (event: any, selectedTab: any) => {
      setCurrentTab(selectedTab);
    },
    [setCurrentTab]
  );

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <Title>
            <ManageAccountsOutlinedIcon
              fontSize="large"
              sx={{ marginRight: "4px" }}
            />{" "}
            {t("manage_access_collection_title", {
              collection_name: collection.name,
            })}
          </Title>

          <Tabs
            value={currentTab}
            aria-label="basic tabs example"
            onChange={handleTabChange}
            centered
          >
            <Tab
              value={ManagerAccessTabs.INVITE}
              label={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PeopleOutlineIcon />
                  <Typography
                    sx={{
                      marginLeft: "8px",
                      fontSize: "1.2rem",
                    }}
                  >
                    {t("invite_action")}
                  </Typography>
                </div>
              }
              {...a11yProps(0)}
            />
            <Tab
              value={ManagerAccessTabs.PUBLISH}
              disabled={
                currentUserAccessRight != UserAccessRight.OWNER_ACCESS_RIGHT
              }
              label={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PublicIcon />
                  <Typography
                    sx={{
                      marginLeft: "8px",
                      fontSize: "1.2rem",
                    }}
                  >
                    {t("publish_action")}
                  </Typography>
                </div>
              }
              {...a11yProps(1)}
            />
          </Tabs>

          {currentTab == ManagerAccessTabs.PUBLISH && (
            <PublishView
              collection={collection}
            />
          )}

          {currentTab == ManagerAccessTabs.INVITE && (
            <>
              {currentUserAccessRight > 0 && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Autocomplete
                    id="tags-standard"
                    inputValue={inputValue}
                    sx={{
                      width: "80%",
                      fontSize: "1.6rem",
                      ".MuiInputLabel-root": {
                        fontSize: "1.6rem",
                      },
                      input: {
                        fontSize: "1.6rem",
                      },
                    }}
                    freeSolo
                    options={sharedUsersNetworkList.map(
                      (sharedUser) => sharedUser
                    )}
                    getOptionLabel={(sharedUser) => sharedUser.email}
                    renderOption={(props, sharedUsersOption) => {
                      if (sharedUsersOption.email == userObj.email) {
                        return null;
                      }
                      return (
                        <ListItem {...props}>
                          <SharedUserAvatar user={sharedUsersOption} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "12px",
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{ color: "black", fontWeight: 430 }}
                            >
                              {sharedUsersOption.firstName +
                                " " +
                                sharedUsersOption.lastName}
                            </Typography>
                            <Typography variant="h6">
                              {sharedUsersOption.email}
                            </Typography>
                          </div>
                        </ListItem>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ fontSize: "2rem" }}
                        variant="standard"
                        label={undefined}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                        placeholder="Enter email address"
                      />
                    )}
                    onInputChange={(
                      event: React.SyntheticEvent,
                      email: string,
                      reason: string
                    ) => {
                      setInputValue(email);
                      //console.log("input reason " + reason);
                      const emailValidBool = isEmailValid(email);
                      setAddButtonEnabled(emailValidBool);
                      if (emailValidBool) {
                        const result = sharedUsersNetworkList.find(
                          (sharedUser) => sharedUser.email == email
                        );
                        const newlyAdded = !(reason == "reset");
                        setNewUserAccessInput({
                          ...newUserAccessInput,
                          user: {
                            ...newUserAccessInput.user,
                            email: email.replace(/\s/g, ""),
                            firstName: result ? result.firstName : "",
                            lastName: result ? result.lastName : "",
                          },
                          newlyAdded: newlyAdded,
                          userRegistered: !newlyAdded,
                        });
                      }
                    }}
                    onChange={(
                      event: React.SyntheticEvent,
                      values: any,
                      reason: string,
                      details?: string
                    ) => {
                      console.log(
                        "auto complete before filtering" +
                          JSON.stringify(values)
                      );
                    }}
                  />
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingRight: "2%",
                      paddingLeft: "2%",
                      paddingTop: "1.5%",
                    }}
                  >
                    <RightsControl
                      userAccess={newUserAccessInput}
                      onUserRightsAccessChanged={(sharedUserAccess, right) => {
                        setNewUserAccessInput({
                          ...newUserAccessInput,
                          right: right,
                        });
                      }}
                      width="100%"
                      currentUserAccessRight={currentUserAccessRight}
                    ></RightsControl>
                  </div>
                  <Button
                    disabled={!addButtonEnabled}
                    variant="contained"
                    onClick={handleAddButtonClick}
                  >
                    {t("add_action")}
                  </Button>
                </div>
              )}
              {errorMessage && (
                <FormHelperText
                  id="component-error-text"
                  style={{ color: "red", fontSize: "small" }}
                >
                  {errorMessage}
                </FormHelperText>
              )}
              {currentUserAccessRight > 0 && (
                <GenerateShareLinkCard
                  collectionId={collection.id}
                ></GenerateShareLinkCard>
              )}
              <List
                sx={{
                  height: "40%",
                  width: "100%",
                  bgcolor: "background.paper",
                  overflow: "auto",
                  paddingRight: "16px",
                  "&::-webkit-scrollbar": {
                    width: 5,
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#C2C2C2",
                    borderRadius: "8px",
                  },
                }}
              >
                {usersAccessList.map((userAccess) => {
                  return (
                    <ListItem
                      key={userAccess.user.email}
                      style={{ width: "100%" }}
                      disableGutters
                    >
                      <SharedUserAvatar user={userAccess.user} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "12px",
                        }}
                      >
                        {userAccess?.newlyAdded ? (
                          <>
                            <Typography
                              variant="h5"
                              sx={{ color: "black", fontWeight: 430 }}
                            >
                              {userAccess.user.email}
                            </Typography>
                            <Typography variant="h6">
                              {t(
                                "manage_access_send_invite_user_not_exists_hint"
                              )}
                            </Typography>
                          </>
                        ) : (
                          <>
                            {userAccess.userRegistered ? (
                              <>
                                <Typography
                                  variant="h5"
                                  sx={{ color: "black", fontWeight: 430 }}
                                >
                                  {userAccess.user.firstName +
                                    " " +
                                    userAccess.user.lastName}
                                </Typography>
                                <Typography variant="h6">
                                  {userAccess.user.email}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography
                                  variant="h5"
                                  sx={{ color: "black", fontWeight: 430 }}
                                >
                                  {userAccess.user.email}
                                </Typography>
                                <Typography variant="h6">
                                  {t("not_account_invitation_sent_hint")}
                                </Typography>
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: "1 1 auto",
                          justifyContent: "end",
                        }}
                      >
                        {userAccess.right !=
                          UserAccessRight.OWNER_ACCESS_RIGHT && (
                          <RightsControl
                            userAccess={userAccess}
                            onUserRightsAccessChanged={
                              handleUserAccessRightChanged
                            }
                            currentUserAccessRight={currentUserAccessRight}
                          ></RightsControl>
                        )}

                        {userAccess.right !=
                          UserAccessRight.OWNER_ACCESS_RIGHT &&
                          (currentUserAccessRight > 0 ||
                            userAccess.user.email == userObj.email) && (
                            <IconButton
                              onClick={() => {
                                handleDeleteAccess(userAccess);
                              }}
                            >
                              <ClearIcon fontSize="medium" />
                            </IconButton>
                          )}
                      </div>

                      {userAccess.right ==
                        UserAccessRight.OWNER_ACCESS_RIGHT && (
                        <Typography
                          variant="h5"
                          style={{
                            borderRadius: "4px",
                            backgroundColor: colorMemento,
                            padding: "4px",
                            position: "absolute",
                            right: "0px",
                          }}
                        >
                          {t("owner_title")}
                        </Typography>
                      )}
                    </ListItem>
                  );
                })}
              </List>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                }}
              >
                {userSelfDeletedAccess && (
                  <Alert
                    style={{ width: "100%", fontSize: "1.4rem" }}
                    id="error-alert"
                    severity="error"
                  >
                    {t("user_access_self_removal_warning")}
                  </Alert>
                )}

                {displayRemovedUserWarnings && (
                  <Alert
                    style={{
                      width: "100%",
                      fontSize: "1.4rem",
                      marginTop: "8px",
                    }}
                    id="error-alert"
                    severity="warning"
                  >
                    {t("remove_user_access_warning_msg")}
                  </Alert>
                )}
              </div>
              <div className={classes.buttonContainer}>
                <LoadingButton
                  className={classes.applyButton}
                  color="primary"
                  variant="contained"
                  loading={buttonLoading}
                  disabled={!applyButtonEnabled}
                  onClick={handleApplyNewUserAccess}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      paddingLeft: "4px",
                      paddingTop: "4px",
                    }}
                  >
                    {t("apply_action")}
                  </Typography>
                </LoadingButton>
              </div>
            </>
          )}
          <MaxDataUsageLimitReachedDialog
            open={displayMaxSharedCollectionsReached}
            description={t("max_free_tier_shared_collections_reached", {
              max_shared_collections_count: MAX_SHARED_COLLECTIONS_COUNT_FREE_USER,
            })}
            handleModalClose={() => {
              setDisplayMaxSharedCollectionsReached(false);
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ManageUserAccessModal);

interface PublishViewProps {
  collection: CollectionObj;
}
enum VIEW_STATES {
  IDLE = 0,
  LOADING_PUBLIC_URL = 1,
  LOADING_SUSPEND_URL = 2,
  LOADING_GENERATE_URL = 3,
  //PUBLIC_URL_SUSPENDED = 4,
  PUBLIC_URL_RETRIEVED = 5,
  ERROR_SUSPEND = 6,
  ERROR_RETRIEVE = 7,
  ERROR_GENERATE = 8,
}

const PublishView = ({ collection }: PublishViewProps) => {
  const client = useApolloClient();
  const [suspendPublicAccess, suspendPublicAccessResult] = useMutation(
    suspendPublicAccessGql,
    {
      variables: {
        collectionId: collection.id,
      },
    }
  );
  const [generatePublicAccess, generatePublicAccessResult] = useMutation(
    generatePublicAccessGql,
    {
      variables: {
        collectionId: collection.id,
      },
    }
  );
  const [displayUrlCopiedTooltip, setDisplayUrlCopiedTooltip] = React.useState(
    false
  );
  const [
    fetchPublicCollectionData,
    fetchPublicCollectionDataResult,
  ] = useLazyQuery(retrievePublicCollectionInfoFromCollectionIdGql, {
    variables: { collectionId: collection.id },
    fetchPolicy: "network-only",
  });
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [viewState, setViewState] = React.useState<VIEW_STATES>(
    VIEW_STATES.IDLE
  );

  const publicAccessUrl = React.useRef("");

  React.useEffect(() => {
    if (collection.isPublic) {
      setViewState(VIEW_STATES.LOADING_PUBLIC_URL);
      fetchPublicCollectionData();
    }
  }, []);

  React.useEffect(() => {
    if (
      fetchPublicCollectionDataResult.data &&
      fetchPublicCollectionDataResult.data.publicCollectionData
    ) {
      publicAccessUrl.current = getCollectionPublicAccessUrlFromAccessToken(
        fetchPublicCollectionDataResult.data.publicCollectionData.accessToken
      );
      setViewState(VIEW_STATES.PUBLIC_URL_RETRIEVED);
    }
  }, [fetchPublicCollectionDataResult.data]);

  const copyLinkToClipboard = React.useCallback(() => {
    navigator.clipboard.writeText(publicAccessUrl.current);
    setDisplayUrlCopiedTooltip(true);
    setTimeout(() => {
      setDisplayUrlCopiedTooltip(false);
    }, 2000);
  }, []);

  const handleSuspendPublicAccess = React.useCallback(() => {
    setViewState(VIEW_STATES.LOADING_SUSPEND_URL);
    suspendPublicAccess()
      .then((result) => {
        if (result.data && result.data.suspendPublicAccess) {
          if (result.data.suspendPublicAccess.result) {
            publicAccessUrl.current = "";
            setViewState(VIEW_STATES.IDLE);
            const newCollection = { ...collection, isPublic: false}
            updateCollectionInCache(
              client.cache,
              newCollection
            );
          } else {
            //setViewState(VIEW_STATES.ERROR_RETRIEVE);
          }
        }
      })
      .catch((e) => {});
  }, [suspendPublicAccess]);

  const handleGeneratePublicAccess = React.useCallback(() => {
    setViewState(VIEW_STATES.LOADING_GENERATE_URL);
    generatePublicAccess()
      .then((result) => {
        if (result.data && result.data.generatePublicAccess) {
          if (result.data.generatePublicAccess.accessToken) {
            publicAccessUrl.current = getCollectionPublicAccessUrlFromAccessToken(
              result.data.generatePublicAccess.accessToken
            );
            const newCollection = { ...collection, isPublic: true}
            updateCollectionInCache(
              client.cache,
              newCollection
            );
            setViewState(VIEW_STATES.PUBLIC_URL_RETRIEVED);
          } else {
            //setViewState(VIEW_STATES.ERROR_RETRIEVE);
          }
        }
      })
      .catch((e) => {});
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          marginTop: "16px",
          padding: "8px",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#faf6e9",
          borderRadius: "4px",
          alignItems: "center",
        }}
      >
        <PublicIcon
          sx={{
            marginLeft: "8px",
          }}
        />

        <Typography
          sx={{
            fontSize: "1.6rem",
            marginLeft: "16px",
          }}
        >
          {t("publish_collection_settings_description")}
        </Typography>
      </Box>
      {(viewState == VIEW_STATES.LOADING_GENERATE_URL ||
        viewState == VIEW_STATES.LOADING_PUBLIC_URL ||
        viewState == VIEW_STATES.LOADING_SUSPEND_URL) && (
        <Skeleton height={40} width={"100%"} />
      )}

      {viewState == VIEW_STATES.PUBLIC_URL_RETRIEVED && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "16px",
            paddingLeft: "16px",
            marginTop: "16px",
            marginBottom: "16px",
            alignItems: "center",
            border: "dashed red",
            borderColor: colorMemento,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              overflow: "auto",
              scrollbarWidth: "1px",
              "&::-webkit-scrollbar": {
                height: 0,
              },
            }}
          >
            <InsertLinkIcon></InsertLinkIcon>
            <Typography
              sx={{
                marginLeft: "16px",
                fontSize: "1.6rem",
                wordWrap: "break-word",
              }}
            >
              {publicAccessUrl.current}
            </Typography>
          </Box>
          <Tooltip
            open={displayUrlCopiedTooltip}
            title={styledTooltipTitle(
              t("link_copied_to_clipboard_confirmation_msg")
            )}
          >
            <Button
              sx={{
                color: "gray",
              }}
              onClick={copyLinkToClipboard}
            >
              {t("copy_link_action")}
            </Button>
          </Tooltip>
        </Box>
      )}

      {viewState == VIEW_STATES.IDLE && (
        <Button
          variant="outlined"
          fullWidth={false}
          sx={{
            marginTop: "16px",
            display: "inline-flex",
            color: "black",
          }}
          onClick={handleGeneratePublicAccess}
        >
          {t("generate_public_access_url")}
        </Button>
      )}
      {viewState == VIEW_STATES.PUBLIC_URL_RETRIEVED && (
        <Button
          variant="outlined"
          fullWidth={false}
          sx={{
            marginTop: "16px",
            display: "inline-flex",
            color: "black",
          }}
          onClick={handleSuspendPublicAccess}
        >
          {t("suspend_public_access_url")}
        </Button>
      )}
    </Box>
  );
};

interface GenerateShareLinkCardProps {
  collectionId: string;
}

const GenerateShareLinkCard = ({
  collectionId,
}: GenerateShareLinkCardProps) => {
  const { t } = useTranslation();
  const [selectedRight, setSelectedRight] = React.useState(
    UserAccessRight.MEMBER_ACCESS_RIGHT
  );
  const [displayUrlCopiedTooltip, setDisplayUrlCopiedTooltip] = React.useState(
    false
  );

  const [generateShareLink, generateShareLinkResult] = useMutation(
    generateCollectionShareLinkGql,
    {
      variables: {
        collectionId: collectionId,
      },
    }
  );
  const [
    suspendCollectionShareLink,
    suspendCollectionShareLinkResult,
  ] = useMutation(suspendCollectionShareLinkGql, {
    variables: {
      collectionId: collectionId,
    },
  });

  const [
    queryCollectionShareLink,
    queryCollectionShareLinkResult,
  ] = useMutation(queryCollectionShareLinkGql, {
    variables: {
      collectionId: collectionId,
    },
  });

  const [urlExists, setUrlExists] = React.useState(false); //Whether this collection has a invite url that exists  already or not
  const collectionUrl = React.useRef("");
  const [currentUrlAccessRight, setCurrentUrlAccessRight] = React.useState(-1);

  const handleQueryShareLink = React.useCallback(() => {
    queryCollectionShareLink()
      .then((data) => {
        if (data.data.queryCollectionShareLink) {
          setUrlExists(data.data.queryCollectionShareLink.exists);
          collectionUrl.current = data.data.queryCollectionShareLink.url;
          setCurrentUrlAccessRight(data.data.queryCollectionShareLink.right);
        }
      })
      .catch((e) => {});
  }, [collectionUrl.current]);

  React.useEffect(() => {
    handleQueryShareLink();
  }, [queryCollectionShareLink]);

  const handleSuspendUrl = React.useCallback(() => {
    suspendCollectionShareLink()
      .then((data) => {
        if (
          data.data.suspendCollectionShareLink &&
          data.data.suspendCollectionShareLink.result
        ) {
          setUrlExists(!data.data.suspendCollectionShareLink.result);
          collectionUrl.current = "";
        }
      })
      .catch((e) => {});
  }, [suspendCollectionShareLink]);

  const copyLinkToClipboard = React.useCallback(() => {
    navigator.clipboard.writeText(collectionUrl.current);
    setDisplayUrlCopiedTooltip(true);
    setTimeout(() => {
      setDisplayUrlCopiedTooltip(false);
    }, 2000);
  }, []);

  const handleGenerateLink = React.useCallback(() => {
    generateShareLink({
      variables: {
        collectionId: collectionId,
        right: selectedRight,
      },
    })
      .then((data) => {
        if (data.data.generateCollectionShareLink) {
          setUrlExists(true);
          collectionUrl.current = data.data.generateCollectionShareLink.url;
          copyLinkToClipboard();
          setCurrentUrlAccessRight(selectedRight);
        }
      })
      .catch((e) => {});
  }, [selectedRight, copyLinkToClipboard]);

  if (queryCollectionShareLinkResult.error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          backgroundColor: "gray",
          borderRadius: "4px",
          marginBottom: "8px",
          marginTop: "8px",
        }}
      >
        <ListItem
          key="link-generation"
          style={{ width: "100%" }}
          disableGutters
        >
          <InsertLinkIcon
            sx={{
              width: "42px",
              height: "42px",
              marginLeft: "4px",
              marginRight: "5%",
            }}
          ></InsertLinkIcon>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: "black", fontSize: "1.6rem" }}
            >
              {t("failed_to_fetch_share_link_msg")}
            </Typography>
            <TextLink
              onClick={handleQueryShareLink}
              sx={{
                fontSize: "1.6rem",
                cursor: "pointer",
              }}
            >
              {t("retry_action")}
            </TextLink>
          </div>
        </ListItem>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#faf6e9",
        borderRadius: "4px",
        marginBottom: "8px",
        marginTop: "8px",
      }}
    >
      <ListItem key="link-generation" style={{ width: "100%" }} disableGutters>
        <InsertLinkIcon
          sx={{
            width: "42px",
            height: "42px",
            marginLeft: "4px",
            marginRight: "5%",
          }}
        ></InsertLinkIcon>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {queryCollectionShareLinkResult.loading ? (
            <>
              <Typography variant="h5" sx={{ color: "black" }}>
                <Skeleton variant="text" height={20} width={200} />
              </Typography>

              <Typography variant="h5" sx={{ color: "black" }}>
                <Skeleton variant="text" height={20} width={150} />
              </Typography>
            </>
          ) : (
            <>
              {urlExists ? (
                <>
                  <Typography variant="h5" sx={{ color: "black" }}>
                    {t("shared_collection_with_link_title") +
                      getRightAsString(currentUrlAccessRight, t).toLowerCase()}
                  </Typography>
                  <Tooltip
                    open={displayUrlCopiedTooltip}
                    title={styledTooltipTitle(
                      t("link_copied_to_clipboard_confirmation_msg")
                    )}
                  >
                    <TextLink
                      onClick={copyLinkToClipboard}
                      sx={{
                        maxWidth: "50%",
                        fontSize: "1.6rem",
                        cursor: "pointer",
                      }}
                    >
                      {t("copy_link_action")}
                    </TextLink>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Typography variant="h5" sx={{ color: "black" }}>
                    {t("share_collection_with_link_action_title")}
                  </Typography>
                  <Tooltip
                    open={displayUrlCopiedTooltip}
                    title={styledTooltipTitle(
                      t("link_copied_to_clipboard_confirmation_msg")
                    )}
                  >
                    {!generateShareLinkResult.loading ? (
                      <TextLink
                        onClick={handleGenerateLink}
                        sx={{
                          fontSize: "1.6rem",
                          cursor: "pointer",
                        }}
                      >
                        {t("generate_link_action")}
                      </TextLink>
                    ) : (
                      <LinearProgress
                        sx={{
                          marginTop: "2px",
                        }}
                      />
                    )}
                  </Tooltip>
                </>
              )}
            </>
          )}
        </div>
      </ListItem>
      <div>
        <>
          {queryCollectionShareLinkResult.loading ? (
            <>
              <Skeleton
                sx={{ marginRight: "12px" }}
                variant="text"
                height={40}
                width={100}
              />
            </>
          ) : (
            <>
              {!urlExists ? (
                <RightsControl
                  userAccess={{
                    user: {
                      id: "skfjqss",
                      firstName: "bl",
                      lastName: "kjl",
                    },
                    right: selectedRight,
                    userRegistered: true,
                  }}
                  onUserRightsAccessChanged={(
                    userAccess: SharedUserAccess,
                    right: number
                  ) => {
                    setSelectedRight(right);
                  }}
                  currentUserAccessRight={3}
                ></RightsControl>
              ) : (
                <>
                  {suspendCollectionShareLinkResult.loading ? (
                    <CircularProgress
                      size="24px"
                      sx={{
                        marginRight: "24px",
                        color: "gray",
                        width: "8px",
                        height: "8px",
                      }}
                    />
                  ) : (
                    <Tooltip title={styledTooltipTitle(t("suspend_action"))}>
                      <IconButton
                        onClick={handleSuspendUrl}
                        sx={{
                          marginRight: "24px",
                        }}
                      >
                        <HighlightOffIcon
                          sx={{
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          )}
        </>
      </div>
    </Box>
  );
};

interface RightsControlProps {
  userAccess: SharedUserAccess;
  onUserRightsAccessChanged: (
    userAccess: SharedUserAccess,
    right: number
  ) => void;
  width?: string;
  currentUserAccessRight: number;
}

const RightsControl = ({
  userAccess,
  onUserRightsAccessChanged,
  width,
  currentUserAccessRight,
}: RightsControlProps) => {
  const { t } = useTranslation();
  const userObj: UserObj = useSelector((state: RootState) => state.auth.user); //current user

  return (
    <FormControl
      sx={{
        //minWidth: "20%",
        width: width ? width : "196px",
        height: "36px",
        marginRight: "8px",
        ".MuiSelect-select": {
          fontSize: "1.4rem",
          fontStyle: "bold",
          padding: "2px",
        },
      }}
    >
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={userAccess.right}
        onChange={(event: SelectChangeEvent) => {
          onUserRightsAccessChanged(userAccess, event.target.value);
        }}
        renderValue={(value: any) => {
          if (value == UserAccessRight.READER_ACCESS_RIGHT) {
            return (
              <Typography
                variant="h5"
                style={{
                  padding: "4px",
                }}
              >
                {t("shared_user_observer")}
              </Typography>
            );
          } else if (value == UserAccessRight.MEMBER_ACCESS_RIGHT) {
            return (
              <Typography
                variant="h5"
                style={{
                  padding: "4px",
                }}
              >
                {t("shared_user_member")}
              </Typography>
            );
          }
        }}
      >
        <MenuItem
          value={UserAccessRight.READER_ACCESS_RIGHT}
          disabled={
            isUserOnFreePlan(userObj) ||
            currentUserAccessRight == UserAccessRight.READER_ACCESS_RIGHT
          }
          sx={{
            width: "224px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              style={{
                padding: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {isUserOnFreePlan(userObj) && (
                <BoltIcon fontSize="large" sx={{ color: colorMemento }} />
              )}
              {t("shared_user_observer")}
            </Typography>
            {isUserOnFreePlan(userObj) ? (
              <Typography
                variant="h6"
                sx={{
                  width: "100%",
                  whiteSpace: "initial",
                }}
              >
                {t("shared_user_observer_description_free_user")}
              </Typography>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  width: "100%",
                  whiteSpace: "initial",
                }}
              >
                {t("shared_user_observer_description")}
              </Typography>
            )}
          </div>
        </MenuItem>
        <MenuItem
          value={UserAccessRight.MEMBER_ACCESS_RIGHT}
          disabled={
            currentUserAccessRight == UserAccessRight.READER_ACCESS_RIGHT
          }
          sx={{
            width: "224px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h5"
              style={{
                padding: "4px",
              }}
            >
              {t("shared_user_member")}
            </Typography>
            <Typography variant="h6" style={{}}>
              {t("shared_user_member_description")}
            </Typography>
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={() => props.handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <Title>
            <ManageAccountsOutlinedIcon
              fontSize="large"
              sx={{ marginRight: "4px" }}
            />{" "}
            Manage access to {props.collectionName}
          </Title>
          <Autocomplete
            multiple
            id="tags-standard"
            options={[]}
            sx={{
              marginTop: "48px",
              fontSize: "2rem",
              ".MuiInputLabel-root": {
                fontSize: "2rem",
              },
              input: {
                fontSize: "2rem",
              },
            }}
            freeSolo
            value={usersAccessList}
            getOptionLabel={(option) => option}
            defaultValue={usersAccessList}
            renderTags={(value: readonly SharedUserAccess[], getTagProps) =>
              value.map((userAccess: SharedUserAccess, index: number) => {
                if (!isEmailValid(userAccess.user.email)) {
                  return null;
                }
                return (
                  <Chip
                    variant="outlined"
                    label={userAccess.user.email}
                    sx={{
                      fontSize: "1.8rem",
                      padding: "4px",
                      ".MuiChip-label": {
                        padding: "4px",
                        marginRight: "4px",
                      },
                    }}
                    {...getTagProps({ index })}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ fontSize: "2rem" }}
                variant="standard"
                label={undefined}
                placeholder="Enter an email address and press enter"
              />
            )}
            onInputChange={() => {
              setErrorMessage(null);
            }}
            onChange={(
              event: React.SyntheticEvent,
              values: any,
              reason: string,
              details?: string
            ) => {
              console.log(
                "auto complete before filtering" + JSON.stringify(values)
              );
              //We first check if the email is well formatted
              var badlyFormattedEmail = null;
              const userEmails = values.filter((userEmail: string) => {
                const result = isEmailValid(userEmail);
                if (!result) {
                  badlyFormattedEmail = userEmail;
                }
                return result;
              });

              // //We check if it exists already in the list . No need, autocomplete won't allow twice the same value
              // if (userEmails.includes(values[values.length-1])){
              //   setErrorMessage("Email already in the list " + values[values.length-1])
              // }
              if (badlyFormattedEmail) {
                setErrorMessage(badlyFormattedEmail + "is not a valid email");
              }

              console.log("auto complete" + JSON.stringify(userEmails));
              setUsersAccessList(userEmails);
            }}
          />
          {errorMessage && (
            <FormHelperText
              id="component-error-text"
              style={{ color: "red", fontSize: "small" }}
            >
              {errorMessage}
            </FormHelperText>
          )}*/

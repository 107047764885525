import styled from "styled-components";

import AggDataLayout from "containers/AggData/AggDataLayout";
import { Input, TextArea } from "containers/InputField/InputElements";
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { clearEditableData } from "store/actions/aggdataActions";
import { shouldAggDataBeCreadted, AggDataObj, DataType } from "models/aggdata";
import { useMutation } from "@apollo/client";
import { addNewDataWithImageGql } from "graphql/mutations";
import { retrieveCollectionGql } from "graphql/queries";
import { addAggDataInCache } from "graphql/helpers";
import { CollectionObj } from "models/collection";
import { setMaxSortOrderScore } from "store/actions/aggdataActions";

type BlurEventType =
  | React.FocusEvent<HTMLInputElement>
  | React.FocusEvent<HTMLTextAreaElement>;

export const OpaqueOverlay = styled.div`
  position: fixed;
  display: absolute;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  z-index: 4;
`;

const EMPTY_NOTE: AggDataObj = { note: { noteTitle: "", noteContent: "" } };
//Deprecated, not in use anymore !!! Needs to be cleaned out, but i'm too lazy right now.
const CreateNewNotePage = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [aggData, setAggData] = useState(EMPTY_NOTE);
  const dispatch = useDispatch();
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );
  const [addAggData, { data, loading, error }] = useMutation(
    addNewDataWithImageGql,
    {
      update(cache, { data: { createAggdata } }) {
        addAggDataInCache(cache, createAggdata.aggData);
      },
      //We refetch the collection in order to update the changes applied by backend, notably items count and preview urls.
      refetchQueries: [
        {
          query: currentlyOpenedCollection ? retrieveCollectionGql : "", // if no collection, avoid trying a useless query
          variables: {
            id: currentlyOpenedCollection ? currentlyOpenedCollection.id : null,
          },
        },
      ],
    }
  );

  const handleBlur = useCallback(async (e: BlurEventType): Promise<void> => {
    const { name, value } = e.target;
    //if ()
    console.log("handle blur");
  }, []);

  const handleDelete = useCallback(async (): Promise<void> => {}, []);

  const handleClose = useCallback((): void => {
    dispatch(clearEditableData());
    if (shouldAggDataBeCreadted(aggData)) {
      console.log("should create new agg data");
      //addData(aggData)
      aggData.dataType = DataType.NOTE_TYPE;
      aggData.collection = currentlyOpenedCollection?.id;
      aggData.sortOrderScore = maxSortOrderScore + 1;
      addAggData({ variables: { input: aggData } }).then(() => {
        dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
      });
    } else {
      console.log("Nope, thing is empty");
    }
  }, [aggData]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      const event = e.target as HTMLElement;
    },
    []
  );

  const maxSortOrderScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxSortOrderScore
  );

  const handleAddToCollection = useCallback(() => {}, []);

  const noteLayoutProps = {
    aggData: undefined,
    isHovering,
    clicked: 1,
    setIsHovering,
    onAddToCollection: handleAddToCollection,
    onClick: handleClick,
    onDelete: handleDelete,
    onClose: handleClose,
  };

  return (
    <>
      <OpaqueOverlay onClick={handleClose} />
      <AggDataLayout {...noteLayoutProps}>
        <Input
          name="noteTitle"
          placeholder="Title"
          autoComplete="off"
          onChange={(e) =>
            setAggData({
              ...aggData,
              note: { ...aggData.note, noteTitle: e.target.value },
            })
          }
          isEditable
          onBlur={(e) => handleBlur(e)}
        />
        {/* {isChecked ? (
          <TodoList id={id} todoContent={todos} /> */}
        <TextArea
          name="noteContent"
          placeholder="Note"
          autoComplete="off"
          onChange={(e) =>
            setAggData({
              ...aggData,
              note: { ...aggData.note, noteContent: e.target.value },
            })
          }
          isEditable
          onBlur={(e) => handleBlur(e)}
        />
      </AggDataLayout>
    </>
  );
};

export default CreateNewNotePage;

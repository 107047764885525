import React from "react";
import styled, { css } from "styled-components";
import { noteLightColorsPalette } from "shared/colors";
import CheckIcon from "@mui/icons-material/Check";

const ColorPaletteContainer = styled("div")<{ isInputField?: boolean }>`
  max-width: 256px;
  padding: 0.5rem;
  position: absolute;
  display: block;
  bottom: 30px;
  left: 8px;
  z-index: 40000000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149);
  background-color: white;

  ${({ isInputField }) =>
    isInputField &&
    css`
      bottom: 80px;
    `}
`;

const ColorPaletteBtn = styled("button")<{ color: string }>`
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1.5px solid transparent;
  border-radius: 50%;
  margin: 0.2rem;
  background: ${(props) => props.color};

  &:hover {
    border: 1.5px solid #5f6367;
  }

  &:first-child {
    border: 1.5px solid #e7eaed;
    &:hover {
      border: 1.5px solid #5f6367;
    }
  }
`;

interface ColorPaletteProp {
  id?: string;
  isInputField?: boolean;
  selectedColor?: number;
  onHover: () => void;
  onUnHover: () => void;
  onColorSelected: ((colorIndex: number) => void) | undefined;
}

const ColorPalette = ({
  id,
  isInputField,
  selectedColor,
  onHover,
  onUnHover,
  onColorSelected,
}: ColorPaletteProp) => {
  return (
    <ColorPaletteContainer onMouseEnter={onHover} onMouseLeave={onUnHover}>
      {noteLightColorsPalette.map((noteColor) => (
        <ColorPaletteBtn
          key={noteColor.index}
          color={noteColor.color}
          onClick={(e) => onColorSelected(noteColor.index)}
        >
          <CheckIcon
            sx={{ opacity: noteColor.index == selectedColor ? 1 : 0 }}
          />
        </ColorPaletteBtn>
      ))}
    </ColorPaletteContainer>
  );
};

export default React.memo(ColorPalette);

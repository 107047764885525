import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { addNewDataWithImageGql } from "graphql/mutations";
import {
  retrieveCollectionGql,
  MAX_DATA_USAGE_REACHED_ERROR,
} from "graphql/queries";
import { useMutation } from "@apollo/client";
import { constructLinkData } from "models/aggdata";
import { useSelector, useDispatch } from "react-redux";
import {
  addAggDataInCache,
  addAggDataInCollectionSpecificCache,
} from "graphql/helpers";
import { RootState } from "store/reducers";
import { CollectionObj } from "models/collection";
import { setMaxSortOrderScore } from "store/actions/aggdataActions";
import { displayMaxDataUsageReachedDialog } from "store/actions/view";
import { useTranslation } from "react-i18next";
import { Link as TextLink } from "@mui/material";
import { colorMemento } from "shared/colors";
import { isAggdataShared } from "models/aggdata";

interface AddNewLinkModalProps {
  open: boolean;
  reminder?: string; //A link can be created with an initial reminder, if the note is created from the reminders view. This field should be json string equivalent to an array with a Reminder object
  handleModalClose: (open: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "60%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    form: {
      margin: theme.spacing(1),
      marginTop: "24px",
      fontSize: "8rem",
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "4px",
      "& .MuiInput-input": {
        fontSize: "large",
      },
    },
    addButton: {
      marginEnd: "12px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: "24px",
    },
    hintContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "8px",
    },
  })
);

function isValidHttpUrl(input: string): boolean {
  let url;
  try {
    url = new URL(input);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export default function AddNewLinkModal(props: AddNewLinkModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [linkInputValue, setLinkInputValue] = React.useState<string>("");
  const [isValidLink, setIsValidLink] = React.useState(true);
  const dispatch = useDispatch();
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );

  const maxSortOrderScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxSortOrderScore
  );
  const [addNewData, { loading, data }] = useMutation(addNewDataWithImageGql, {
    update(cache, { data: { createAggdata } }) {
      if (createAggdata && createAggdata.aggData) {
        addAggDataInCache(cache, createAggdata.aggData);
        if (isAggdataShared(createAggdata.aggData)) {
          addAggDataInCollectionSpecificCache(
            cache,
            createAggdata.aggData,
            createAggdata.aggData.collection.id
          );
        }
      }
    },
    //We refetch the collection in order to update the changes applied by backend, notably items count and preview urls.
    refetchQueries: [
      {
        query: currentlyOpenedCollection ? retrieveCollectionGql : "", // if no collection, avoid trying a useless query
        variables: {
          id: currentlyOpenedCollection ? currentlyOpenedCollection.id : null,
        },
      },
    ],
  });

  const theme = useTheme();

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const textInput = e.target.value;
    const isInputAValidLink = isValidHttpUrl(textInput);
    setIsValidLink(!isInputAValidLink);
    setLinkInputValue(textInput);
  };

  const handleClick = () => {
    const aggData = constructLinkData(linkInputValue);
    addNewData({
      variables: {
        input: {
          ...aggData,
          sortOrderScore: maxSortOrderScore + 1,
          collection: currentlyOpenedCollection?.id,
          reminders: props.reminder ? props.reminder : undefined,
        },
      },
    }).then((data: any) => {
      if (
        data.data &&
        data.data.createAggdata &&
        data.data.createAggdata.error &&
        data.data.createAggdata.error == MAX_DATA_USAGE_REACHED_ERROR
      ) {
        dispatch(displayMaxDataUsageReachedDialog(true));
        return;
      }
      dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
    });
    setLinkInputValue("");
    props.handleModalClose(false);
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={() => props.handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <FormControl fullWidth className={classes.form} variant="outlined">
              <InputLabel
                className={classes.inputLabel}
                htmlFor="standard-adornment-amount"
              ></InputLabel>
              <Input
                id="outlined-adornment-amount"
                placeholder="http://..."
                className={classes.inputLabel}
                value={linkInputValue}
                onChange={handleChange}
                inputProps={{
                  disableUnderline: true,
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <InsertLinkIcon style={{ width: "40px", height: "40px" }} />
                  </InputAdornment>
                }
              />
              <div className={classes.hintContainer}>
                <Typography
                  style={{
                    marginLeft: "12px",
                    color: "grey",
                    marginTop: "12px",
                  }}
                  variant="h5"
                >
                  {t("no_pending_items_hint")}{" "}
                  <TextLink
                    style={{ color: colorMemento, cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        "https://chrome.google.com/webstore/detail/memento-collect/pmmdajcdfdfnofmagfpaeikgockmcnpp",
                        "_blank"
                      );
                    }}
                  >
                    {t("browser_extension")}
                  </TextLink>
                </Typography>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disabled={isValidLink}
                  className={classes.addButton}
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  // className={buttonClassname}
                  //disabled={loading}
                  // onClick={handleButtonClick}
                >
                  <Typography variant="h6">{t("add_action")}</Typography>
                </Button>
              </div>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

import {
  AggDataState,
  AggDataActionTypes,
  SetMaxAllItemsPinScore,
  SetMaxSortOrderScore,
  SetMaxSortOrderScoreCurrentCollection,
  GetEditableData,
  SetEditableAggdataNote,
  InitData,
  AddData,
  ClearEditableData,
  SetAggDataWithReminders,
  SetMultiSelectedData,
  AppendMultiSelectedData
} from 'store/actions/aggdataActions';
import * as actionTypes from 'store/actions/aggdataActions';
import { updateObject } from 'shared/utility';
import { RemoveMultiSelectedData } from '../actions/aggdataActions';

const initialState: AggDataState = {
  aggData: [],
  aggDataWithRemindersState: { aggDataWithReminders: [], loading: true, error: false },
  editableData: null,
  editableAggdataNote: null,
  maxAllItemsPinScore: 0,
  maxSortOrderScore: 0,
  maxSortOrderScoreInCurrentCollection: 0,
  multiSelectedAggData: [],
};

const initData = (state: AggDataState, action: InitData): AggDataState => {
  const newState = {
    ...state,
    aggData: action.payload,
  };
  return updateObject(state, newState);
};

const addData = (state: AggDataState, action: AddData) => {
  console.log('add data payload ' + action.payload)
  const notes = {
    ...state,
    aggData: [...state.aggData, action.payload]
  };
  return notes;
};

const getEditableData = (state: AggDataState, action: GetEditableData) => {
  const updatedNotes = {
    ...state,
    editableNote: action.payload,
  };
  return updateObject(state, updatedNotes);
};

const setEditableAggdataNote = (state: AggDataState, action: SetEditableAggdataNote) => {
  const updatedNotes = {
    ...state,
    editableAggdataNote: action.payload,
  };
  return updateObject(state, updatedNotes);
};

const clearEditableData = (state: AggDataState, action: ClearEditableData) => {
  const updatedNotes = {
    ...state,
    editableNote: null,
  };
  return updateObject(state, updatedNotes);
};

const setMaxAllItemsPinScore = (state: AggDataState, action: SetMaxAllItemsPinScore): AggDataState => {
  const newState = {
    ...state,
    maxAllItemsPinScore: action.payload,
  };
  return newState;
};


const setMultiSelectedData = (state: AggDataState, action: SetMultiSelectedData): AggDataState => {
  const newState = {
    ...state,
    multiSelectedAggData: action.payload,
  };
  return updateObject(state, newState);
};



const appendMultiSelectedData = (state: AggDataState, action: AppendMultiSelectedData): AggDataState => {
  const newState = {
    ...state,
    multiSelectedAggData: [...state.multiSelectedAggData, action.payload] 
  };
  return updateObject(state, newState);
};

const removeMultiSelectedData = (state: AggDataState, action: RemoveMultiSelectedData): AggDataState => {
  const arrayCopy = [...state.multiSelectedAggData];
  const index =  arrayCopy.indexOf(action.payload);
  if (index > -1) { // only splice array when item is found
    arrayCopy.splice(index, 1); // 2nd parameter means remove one item only
  }
  const newState = {
    ...state,
    multiSelectedAggData: arrayCopy
  };
  return updateObject(state, newState);
};

const setAggDataWithReminders = (state: AggDataState, action: SetAggDataWithReminders): AggDataState => {
  const newState = {
    ...state,
    aggDataWithRemindersState: action.payload,
  };
  return updateObject(state, newState);
};


const setMaxSortOrderScore = (state: AggDataState, action: SetMaxSortOrderScore): AggDataState => {
  const newState = {
    ...state,
    maxSortOrderScore: action.payload,
  };
  return newState;
};

const setMaxSortOrderScoreInCurrentCollection = (state: AggDataState, action: SetMaxSortOrderScoreCurrentCollection): AggDataState => {
  const newState = {
    ...state,
    maxSortOrderScoreInCurrentCollection: action.payload,
  };
  return newState;
};

// REDUCER
const reducer = (state = initialState, action: AggDataActionTypes) => {
  switch (action.type) {
    case actionTypes.INIT_DATA:
      console.log("INIT_DATA")
      return initData(state, action);
    case actionTypes.ADD_DATA:
      console.log('ADD_DATA')
      return addData(state, action);
    case actionTypes.GET_EDITABLE_DATA:
      return getEditableData(state, action);
    case actionTypes.CLEAR_EDITABLE_DATA:
      return clearEditableData(state, action);
    case actionTypes.SET_MAX_ALL_ITEMS_PIN_SCORE:
      console.log("Max all items pin score" + action.payload)
      return setMaxAllItemsPinScore(state, action);
    case actionTypes.SET_MAX_SORT_ORDER_SCORE:
      console.log("Max sort order score" + action.payload)
      return setMaxSortOrderScore(state, action);
    case actionTypes.SET_MAX_SORT_ORDER_SCORE_CURRENT_COLLECTION:
      return setMaxSortOrderScoreInCurrentCollection(state, action);
    case actionTypes.SET_EDITABLE_DATA:
      return setEditableAggdataNote(state, action);
    case actionTypes.SET_AGG_DATA_WITH_REMINDERS:
      return setAggDataWithReminders(state, action);
    case actionTypes.SET_MULTI_SELECTED_DATA:
      return setMultiSelectedData(state, action);
    case actionTypes.APPEND_DATA_TO_MULTI_SELECTION:
      return appendMultiSelectedData(state, action);
    case actionTypes.REMOVE_MULTI_SELECTED_DATA:
      return removeMultiSelectedData(state, action);
    default:
      return state;
  }
};

export default reducer;

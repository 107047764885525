import React from "react";
import EmptyCollectionIllustration from "illustrations/undraw_empty_list_yellow_memento.svg";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items:center;
  overflow-x: hidden;
`;

const EmptyCollection = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <img
        style={{ marginTop: "60px", height: "300px"}}
        src={EmptyCollectionIllustration}
      />
      <Typography
        style={{ marginLeft: "12px", color: "black", marginTop: "36px" }}
        variant="h4"
      >
        {t("all_items_empty_list_msg")}
      </Typography>
    </MainContainer>
  );
};

export default React.memo(EmptyCollection);

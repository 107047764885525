import React, { useState, useCallback } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSelector, useDispatch } from "react-redux";
import { setSearchInput, showConfidentialityFilter } from "store/actions/view";
import {
  getAggdataSortType,
  getAggdataViewType,
  getAggdataFilterType,
  saveConfidentialityFilterConfiguration,
  saveAggdataFilterType,
  saveAggdataViewType,
  saveAggdataSortType,
} from "localstorage/localstorage";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import { useTranslation } from "react-i18next";
import { RootState } from "store/reducers";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import Box from "@mui/material/Box";
import {
  Button,
  CircularProgress,
  Grow,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import CheckIcon from "@mui/icons-material/Check";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { colorMemento } from "shared/colors";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { AggDataTagObj } from "models/aggdata";
import { DocumentNode, useQuery } from "@apollo/client";
import {
  retrieveAllAggDataTagsGql,
  retrieveTagsForCollectionGql,
  retrieveTagsForPublicCollectionGql,
} from "graphql/queries";

export enum VIEW_TYPE {
  GRID = "0",
  LIST = "1",
  FEED = "2",
}

export enum SORT_TYPE {
  PINNED_FIRST = "0",
  LAST_CREATED = "1",
  COMMENTS_COUNT = "2",
}

export enum FILTER_TYPE {
  FILTER_NONE = "0",
  FILTER_NOTES = "1",
  FILTER_IMAGES = "2",
  FILTER_LINK = "3",
  FILTER_FILES = "4",
  FILTER_AUDIO = "5",
  FILTER_VIDEO = "6",
}

export const MenuItemText = styled.div`
  font-size: 1.2rem;
`;

export interface AggdataDisplayControlProps {
  onViewTypeChanged: (viewType: VIEW_TYPE) => void;
  onSortTypeChanged: (viewType: SORT_TYPE) => void;
  onFilterTypeChanged: (viewType: FILTER_TYPE) => void;
  onCommentClicked?: () => void;
  disableVisibilityOption?: boolean;
  displaySortByComments?: boolean;
  displaySortByPin?: boolean;
  displaySearch?: boolean;
  displayComments?: boolean;
  collectionId?: string;
  collectionName?: string;
  isCollectionPublic?: boolean;
  tags?: AggDataTagObj[];
  selectedTags?: AggDataTagObj[];
  onSelectedTagsChanged?: (tags: AggDataTagObj[]) => void;
  excludedTag?: string;
}

const useStyles = makeStyles(() => ({
  searchField: {
    "& .MuiInputBase-input": {
      fontSize: "1.2rem",
      padding: "4px 4px",
    },

    "& .MuiInputBase-root": {},

    "&:hover .MuiInputBase-root": {
      background: "white",
    },
    "&:Mui-focused .MuiInputBase-root": {
      background: "white",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderWidth: "4px",
    },
  },
}));

enum TABS {
  DATATYPE_TAB = "type",
  TAG_TAB = "tag",
}

const AggdataDisplayControl = ({
  onViewTypeChanged,
  onSortTypeChanged,
  onFilterTypeChanged,
  onCommentClicked,
  disableVisibilityOption = false,
  displaySortByComments = true,
  displaySortByPin = true,
  displaySearch = false,
  displayComments = false,
  tags,
  selectedTags,
  onSelectedTagsChanged,
  collectionId,
  collectionName,
  isCollectionPublic,
  excludedTag,
}: AggdataDisplayControlProps) => {
  const showConfidentialityFilterState = useSelector(
    (state: RootState) => state.view.showConfidentialityFilter
  );
  /*   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl); */

  const classes = useStyles();
  const theme = useTheme();
  const [
    filterTypeAnchorEl,
    setFilterTypeAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const openFilterMenu = Boolean(filterTypeAnchorEl);

  const [
    viewTypeAnchoreEl,
    setViewTypeAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const openViewTypeMenu = Boolean(viewTypeAnchoreEl);

  const [
    sortTypeAnchoreEl,
    setSortTypeAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const openSortTypeMenu = Boolean(sortTypeAnchoreEl);

  const [confidentialitySelected, setConfidentialitySelected] = React.useState(
    showConfidentialityFilterState
  );

  const [tabValue, setTabValue] = React.useState(TABS.DATATYPE_TAB);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [selectedViewType, setSelectedViewType] = React.useState<VIEW_TYPE>(
    getAggdataViewType()
  );
  const [selectedSortType, setSelectedSortType] = React.useState<SORT_TYPE>(
    getAggdataSortType()
  );
  const [selectedFilterType, setSelectedFilterType] = React.useState<
    FILTER_TYPE
  >(getAggdataFilterType());

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleSearch = React.useCallback(() => {
    setOpenSearch(!openSearch);
  }, [openSearch, setOpenSearch]);

  const closeFilterTypePopupMenu = React.useCallback(() => {
    setFilterTypeAnchorEl(null);
  }, [setFilterTypeAnchorEl]);

  const closeViewTypePopupMenu = React.useCallback(() => {
    setViewTypeAnchorEl(null);
  }, [setViewTypeAnchorEl]);

  const closeSortTypePopupMenu = React.useCallback(() => {
    setSortTypeAnchorEl(null);
  }, [setSortTypeAnchorEl]);

  const iconStyles = {
    width: "18px",
    height: "18px",
    color: "gray",
  };

  React.useEffect(() => {
    onViewTypeChanged(selectedViewType);
    saveAggdataViewType(selectedViewType);
  }, [selectedViewType]);

  React.useEffect(() => {
    onSortTypeChanged(selectedSortType);
    saveAggdataSortType(selectedSortType);
  }, [selectedSortType]);

  React.useEffect(() => {
    onFilterTypeChanged(selectedFilterType);
    saveAggdataFilterType(selectedFilterType);
  }, [selectedFilterType]);

  React.useEffect(() => {
    if (
      !displaySortByComments &&
      selectedSortType == SORT_TYPE.COMMENTS_COUNT
    ) {
      setSelectedSortType(SORT_TYPE.LAST_CREATED);
    }
  }, [displaySortByComments]);

  React.useEffect(() => {
    if (!displaySortByPin && selectedSortType == SORT_TYPE.PINNED_FIRST) {
      setSelectedSortType(SORT_TYPE.PINNED_FIRST);
    }
  }, [displaySortByPin]);

  React.useEffect(() => {
    return () => {
      if (displaySearch) {
        dispatch(setSearchInput(""));
      }
    };
  }, []);

  const handleSearchInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const textInput = e.target.value;
    dispatch(setSearchInput(textInput));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: "gray",
          padding: "4px",
          fontWeight: 500,
          cursor: "pointer",
          borderRadius: "2px",
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        }}
        onClick={(event: any) => {
          setSortTypeAnchorEl(event.currentTarget);
        }}
      >
        {t("sort_title")}
      </Typography>

      <Menu
        id="basic-menu"
        anchorEl={sortTypeAnchoreEl}
        open={openSortTypeMenu}
        onClose={closeSortTypePopupMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Typography
          key={4}
          sx={{
            fontSize: "1.3rem",
            marginLeft: "8px",
            color: "gray",
            fontWeight: "bold",
            width: "150px",
          }}
        >
          {t("sort_title")}
        </Typography>
        {displaySortByPin && (
          <MenuItem
            key={2}
            onClick={() => {
              setSelectedSortType(SORT_TYPE.PINNED_FIRST);
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <MenuItemText>{t("sort_pin_order_title")} </MenuItemText>
              {selectedSortType == SORT_TYPE.PINNED_FIRST && <CheckIcon />}
            </Box>
          </MenuItem>
        )}
        <MenuItem
          key={1}
          onClick={() => {
            setSelectedSortType(SORT_TYPE.LAST_CREATED);
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <MenuItemText>{t("sort_last_created_title")} </MenuItemText>
            {selectedSortType == SORT_TYPE.LAST_CREATED && <CheckIcon />}
          </Box>
        </MenuItem>

        {displaySortByComments && (
          <MenuItem
            key={0}
            onClick={() => {
              setSelectedSortType(SORT_TYPE.COMMENTS_COUNT);
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <MenuItemText>{t("sort_comments_title")} </MenuItemText>
              {selectedSortType == SORT_TYPE.COMMENTS_COUNT && <CheckIcon />}
            </Box>
          </MenuItem>
        )}
      </Menu>
      <Typography
        sx={{
          padding: "4px",
          fontWeight: 500,
          cursor: "pointer",
          borderRadius: "2px",
          color:
            selectedFilterType != FILTER_TYPE.FILTER_NONE ||
            (selectedTags && selectedTags.length > 0)
              ? colorMemento
              : "gray",
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        }}
        onClick={(event: any) => {
          setFilterTypeAnchorEl(event.currentTarget);
        }}
      >
        {t("filter_action")}
      </Typography>

      <Menu
        id="basic-menu"
        anchorEl={filterTypeAnchorEl}
        open={openFilterMenu}
        onClose={closeFilterTypePopupMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiTabs-root": {
            height: "40px",
            minHeight: 0,
            width: "25vw",
            minWidth: "200px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "1.3rem",
            marginLeft: "8px",
            color: "gray",
            fontWeight: "bold",
            width: "150px",
          }}
        >
          {t("filter_action")}
        </Typography>

        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
          }}
          textColor="primary"
          indicatorColor="primary"
          sx={{
            marginRight: "16px",
            "& .MuiButtonBase-root": {
              padding: 0,
              width: "auto",
              paddingRight: "8px",
              paddingLeft: "8px",
              minWidth: "auto",
              fontSize: "1rem",
              textTransform: "capitalize",
            },
          }}
        >
          <Tab value={TABS.DATATYPE_TAB} label="Type" wrapped />
          <Tab value={TABS.TAG_TAB} label="Tags" />
        </Tabs>
        {tabValue == TABS.TAG_TAB && (
          <SelectTagControl
            collectionId={collectionId}
            externalSelectedTags={selectedTags}
            onSelectedTagsChanged={onSelectedTagsChanged}
            collectionName={collectionName}
            isCollectionPublic={isCollectionPublic}
            excludedTag={excludedTag}
          />
        )}
        {tabValue == TABS.DATATYPE_TAB && (
          <>
            <MenuItem
              key={"0"}
              onClick={() => {
                setSelectedFilterType(FILTER_TYPE.FILTER_NONE);
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MenuItemText>{t("filter_menu_option_none")} </MenuItemText>
                {selectedFilterType == FILTER_TYPE.FILTER_NONE && <CheckIcon />}
              </Box>
            </MenuItem>
            <MenuItem
              key={"1"}
              onClick={() => {
                setSelectedFilterType(FILTER_TYPE.FILTER_NOTES);
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MenuItemText>{t("filter_menu_option_note")} </MenuItemText>
                {selectedFilterType == FILTER_TYPE.FILTER_NOTES && (
                  <CheckIcon />
                )}
              </Box>
            </MenuItem>
            <MenuItem
              key={"2"}
              onClick={() => {
                setSelectedFilterType(FILTER_TYPE.FILTER_LINK);
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MenuItemText>{t("filter_menu_option_link")} </MenuItemText>
                {selectedFilterType == FILTER_TYPE.FILTER_LINK && <CheckIcon />}
              </Box>
            </MenuItem>

            <MenuItem
              key={"3"}
              onClick={() => {
                setSelectedFilterType(FILTER_TYPE.FILTER_VIDEO);
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MenuItemText>{t("filter_menu_option_video")}</MenuItemText>
                {selectedFilterType == FILTER_TYPE.FILTER_VIDEO && (
                  <CheckIcon />
                )}
              </Box>
            </MenuItem>
            <MenuItem
              key={"4"}
              onClick={() => {
                setSelectedFilterType(FILTER_TYPE.FILTER_AUDIO);
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MenuItemText>{t("filter_menu_option_audio")}</MenuItemText>
                {selectedFilterType == FILTER_TYPE.FILTER_AUDIO && (
                  <CheckIcon />
                )}
              </Box>
            </MenuItem>
            <MenuItem
              key={"5"}
              onClick={() => {
                setSelectedFilterType(FILTER_TYPE.FILTER_FILES);
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MenuItemText>{t("filter_menu_option_file")}</MenuItemText>
                {selectedFilterType == FILTER_TYPE.FILTER_FILES && (
                  <CheckIcon />
                )}
              </Box>
            </MenuItem>
            <MenuItem
              key={"6"}
              onClick={() => {
                setSelectedFilterType(FILTER_TYPE.FILTER_IMAGES);
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MenuItemText>{t("filter_menu_option_image")}</MenuItemText>
                {selectedFilterType == FILTER_TYPE.FILTER_IMAGES && (
                  <CheckIcon />
                )}
              </Box>
            </MenuItem>
          </>
        )}
      </Menu>

      {openSearch && displaySearch && (
        <TextField
          className={classes.searchField}
          id="outlined-basic"
          placeholder={t("search_action")}
          label={undefined}
          variant="filled"
          type="search"
          sx={{
            width: openSearch ? "256px" : "0",
          }}
          onChange={handleSearchInputChange}
          InputProps={{
            disableUnderline: true, // <== added this
          }}
        ></TextField>
      )}
      {displaySearch && (
        <Tooltip placement="top" title={styledTooltipTitle(t("search_action"))}>
          <IconButton onClick={toggleSearch}>
            <SearchIcon sx={iconStyles} />
          </IconButton>
        </Tooltip>
      )}

      {displayComments && (
        <Tooltip
          placement="top"
          title={styledTooltipTitle(t("comments_title"))}
        >
          <IconButton onClick={onCommentClicked}>
            <CommentOutlinedIcon sx={iconStyles} />
          </IconButton>
        </Tooltip>
      )}

      {!disableVisibilityOption && (
        <Tooltip
          placement="top"
          title={styledTooltipTitle(
            !confidentialitySelected
              ? t("confidentiality_control_hide_hint")
              : t("confidentiality_control_show_hint")
          )}
        >
          <IconButton
            onClick={() => {
              saveConfidentialityFilterConfiguration(!confidentialitySelected);
              setConfidentialitySelected(!confidentialitySelected);
              dispatch(showConfidentialityFilter(!confidentialitySelected));
            }}
          >
            {!confidentialitySelected ? (
              <VisibilityOffIcon sx={iconStyles} />
            ) : (
              <VisibilityIcon sx={iconStyles} />
            )}
          </IconButton>
        </Tooltip>
      )}
      <Tooltip placement="top" title={styledTooltipTitle(t("view_title"))}>
        <IconButton
          onClick={(event: any) => {
            setViewTypeAnchorEl(event.currentTarget);
          }}
        >
          {selectedViewType == VIEW_TYPE.GRID && (
            <GridViewRoundedIcon sx={iconStyles} />
          )}
          {selectedViewType == VIEW_TYPE.LIST && (
            <TableRowsRoundedIcon sx={iconStyles} />
          )}
          {selectedViewType == VIEW_TYPE.FEED && (
            <FeedOutlinedIcon sx={iconStyles} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu2"
        anchorEl={viewTypeAnchoreEl}
        open={openViewTypeMenu}
        onClose={closeViewTypePopupMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiMenuItem-root": {
            paddingLeft: "8px",
            paddingRight: "8px",
            width: "150px",
          },
        }}
      >
        <MenuItem
          key={0}
          onClick={() => {
            setSelectedViewType(VIEW_TYPE.GRID);
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <GridViewRoundedIcon sx={iconStyles} />
              <MenuItemText
                style={{
                  marginLeft: "8px",
                }}
              >
                {t("view_grid_title")}
              </MenuItemText>
            </Box>
            {selectedViewType == VIEW_TYPE.GRID && <CheckIcon />}
          </Box>
        </MenuItem>
        <MenuItem
          key={1}
          onClick={() => {
            setSelectedViewType(VIEW_TYPE.LIST);
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TableRowsRoundedIcon sx={iconStyles} />
              <MenuItemText
                style={{
                  marginLeft: "8px",
                }}
              >
                {t("view_list_title")}
              </MenuItemText>
            </Box>
            {selectedViewType == VIEW_TYPE.LIST && <CheckIcon />}
          </Box>
        </MenuItem>
        <MenuItem
          key={2}
          onClick={() => {
            setSelectedViewType(VIEW_TYPE.FEED);
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FeedOutlinedIcon sx={iconStyles} />
              <MenuItemText
                style={{
                  marginLeft: "8px",
                }}
              >
                {t("view_feed_title")}
              </MenuItemText>
            </Box>
            {selectedViewType == VIEW_TYPE.FEED && <CheckIcon />}
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

interface SelectTagControlInterface {
  //tags: AggDataTagObj[];
  externalSelectedTags?: AggDataTagObj[];
  onSelectedTagsChanged?: (tags: AggDataTagObj[]) => void;
  collectionId?: string;
  collectionName?: string;
  isCollectionPublic?: boolean;
  excludedTag?: string;
}

interface DisplayedTag {
  tag: AggDataTagObj;
  selected: boolean;
}

const SelectTagControl = ({
  externalSelectedTags,
  onSelectedTagsChanged,
  collectionId,
  collectionName,
  isCollectionPublic,
  excludedTag,
}: SelectTagControlInterface) => {
  const [displayedTags, setDisplayedTags] = React.useState<DisplayedTag[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<DisplayedTag[]>(() => {
    if (externalSelectedTags) {
      return [...externalSelectedTags].map((tag) => {
        return { tag: tag, selected: false };
      });
    } else return [];
  });

  const getTagsGqlQuery = React.useCallback(() => {
    if (isCollectionPublic) {
      return retrieveTagsForPublicCollectionGql;
    } else if (collectionId) {
      return retrieveTagsForCollectionGql;
    } else {
      return retrieveAllAggDataTagsGql;
    }
  }, []);

  const { loading, data, error } = useQuery(getTagsGqlQuery(), {
    variables: collectionId
      ? {
          collectionId: collectionId,
        }
      : undefined,
    context: {
      clientName: isCollectionPublic ? "public" : "",
    },
  });

  const { t } = useTranslation();

  /* console.log(
    "YIT Current external selected tags are " +
      JSON.stringify(externalSelectedTags)
  ); */
  React.useEffect(() => {
    // console.log("yi data is go into useeffect ");
    if (
      data &&
      (data.tagsForCollection ||
        data.allTags ||
        data.aggdataTagsForPublicCollection)
    ) {
      let tags = isCollectionPublic
        ? data.aggdataTagsForPublicCollection
        : collectionId
        ? data.tagsForCollection
        : data.allTags;

      let tagsArray = [...tags]
        .map((tag: AggDataTagObj) => {
          if (selectedTags.length > 0) {
            let tagAlreadyExists = selectedTags.some(
              (e: DisplayedTag) => e.tag.name == tag.name
            );
            if (tagAlreadyExists) {
              return { tag: tag, selected: true };
            }
          }
          return { tag: tag, selected: false };
        })
        .sort((displayedTag1: DisplayedTag, displayedTag2: DisplayedTag) =>
          displayedTag1.tag.name.localeCompare(displayedTag2.tag.name)
        );
      //console.log("YIT displayed tags " + JSON.stringify(tagsArray));

      setDisplayedTags(tagsArray);
    }
  }, [data]);

  //Order of useeffect here is important here
  React.useEffect(() => {
    if (externalSelectedTags && displayedTags.length > 0) {
      var newSelectedTagsArray: DisplayedTag[] = [];
      for (var i = 0; i < displayedTags.length; i++) {
        externalSelectedTags?.map((selectedTag) => {
          if (displayedTags[i].tag.name == selectedTag.name) {
            //console.log("Mx seleted tags are " + displayedTags[i].tag.name);
            displayedTags[i].selected = true;
            newSelectedTagsArray.push(displayedTags[i]);
          }
        });
      }
      setSelectedTags(newSelectedTagsArray);
      /*  console.log(
        "YIT displayed tags external selected " + JSON.stringify(displayedTags)
      ); */
      //setDisplayedTags(displayedTags);
    }
  }, [externalSelectedTags]);
  //console.log("YIT selected tags are selected " + JSON.stringify(selectedTags));
  const handleMenuItemClicked = React.useCallback(
    (displayedTag: DisplayedTag) => {
      displayedTag.selected = !displayedTag.selected;
      var newArrayOfSelectedTags = [];
      if (displayedTag.selected) {
        //  console.log("YIT new tag is now selected ");
        newArrayOfSelectedTags = [...selectedTags, displayedTag];
        /* console.log(
          "YIT new tag is now selected " + JSON.stringify(selectedTags)
        ); */

        setSelectedTags(newArrayOfSelectedTags);
      } else {
        newArrayOfSelectedTags = selectedTags.filter(
          (filterTag) => filterTag.tag.name != displayedTag.tag.name
        );
        setSelectedTags(newArrayOfSelectedTags);
      }
      /* console.log(
        "YIT new array of selected TAGS " +
          JSON.stringify(newArrayOfSelectedTags)
      ); */
      onSelectedTagsChanged &&
        onSelectedTagsChanged(
          newArrayOfSelectedTags.map((displayedTag) => displayedTag.tag)
        );
    },
    [selectedTags]
  );

  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "12px",
          }}
        >
          <CircularProgress
            sx={{
              width: "10px",
              height: "10px",
            }}
            size="32px"
          />
        </Box>
      )}
      {!loading && !error && displayedTags.length == 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "12px",
          }}
        >
          <Typography
            sx={{
              fontStyle: "italic",
              fontSize: "1.2rem",
            }}
          >
            {collectionName
              ? t("no_tags_in_collection_title", {
                  collection_name: collectionName,
                })
              : t("no_tags_title")}
          </Typography>
        </Box>
      )}
      {!loading &&
        displayedTags.map((displayedTag: DisplayedTag) => {
          if (displayedTag.tag.name == excludedTag) {
            return <></>;
          }
          return (
            <MenuItem
              key={displayedTag.tag.name}
              onClick={() => handleMenuItemClicked(displayedTag)}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MenuItemText>{"🏷️ " + displayedTag.tag.name} </MenuItemText>
                {displayedTag.selected && <CheckIcon />}
              </Box>
            </MenuItem>
          );
        })}
    </>
  );
};

export default React.memo(AggdataDisplayControl);

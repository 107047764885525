
import { UserObj } from "models/user";
import { SubscriptionInformation} from "models/user";

export const LOGIN_USER = 'LOGIN_USER';
export const INIT_USER = 'INIT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER_SUBSCRIPTION_INFO = 'SET_USER_SUBSCRIPTION_INFO';


// Types
export interface UserAuthState {
    user: UserObj | null;
    userSubscriptionInfo: SubscriptionInformation | null;
    isUserLoggedIn: boolean;
}

export interface LoginUser {
    type: typeof LOGIN_USER;
    payload: UserObj;
}

export interface SetUserSubscriptionInfo {
    type: typeof SET_USER_SUBSCRIPTION_INFO;
    payload: SubscriptionInformation;
}

export interface LogoutUser {
    type: typeof LOGOUT_USER;
}

export type UserAuthActionTypes =
  | LoginUser
  | SetUserSubscriptionInfo
  | LogoutUser;


export const loginUser = (userObj: UserObj): LoginUser => {
    return {
      type: LOGIN_USER,
      payload: userObj,
    };
  };


export const setUserSubscriptionInformation = (subscriptionInfo: SubscriptionInformation | null): SetUserSubscriptionInfo => {
    return {
      type: SET_USER_SUBSCRIPTION_INFO,
      payload: subscriptionInfo,
    };
  };
  

export const logoutUser = (): LogoutUser => {
    return {
      type: LOGOUT_USER
    };
  };
  

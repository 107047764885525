import React from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { UserObj } from "models/user";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getMonthFromMonthNumber } from "shared/dataUtils";
import { useTranslation } from "react-i18next";
import { SUBSCRIPTION_MEMENTEE_PLUS_PLAN } from "configuration";

interface DataUsageProgressBarProps {
    hideTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  progressbar: {
    width: "100%",
  },
}));

const DataUsageProgressBar = ({ hideTitle }: DataUsageProgressBarProps) => {
  const userObj: UserObj = useSelector((state: RootState) => state.auth.user);
  const classes = useStyles();
  const { t } = useTranslation();
  const usagePercentage = useCallback((): number => {
    const result = Math.round(
      (userObj.monthlyDataUsage / userObj.subscriptionPlan.maxDataUsage) * 100
    );
    return result > 100 ? 100 : result;
  }, [userObj]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
         <Typography sx={{ textAlign: "start", width: "50%" }} variant="h5">
          {hideTitle ? "" :  t('data_usage_for_month_title', { data_usage_month: getMonthFromMonthNumber(userObj.dataUsageMonth)})}
        </Typography>
        <Typography sx={{ textAlign: "end", width: "50%" }} variant="h6">
          {userObj.subscriptionPlan.id != SUBSCRIPTION_MEMENTEE_PLUS_PLAN.id ? userObj.subscriptionPlan.maxDataUsageAsString : t('unlimited_title')}
        </Typography>
      </Box>
      <LinearProgress
        className={classes.progressbar}
        variant="determinate"
        value={usagePercentage()}
      />
      <Typography
        sx={{ textAlign: "start", width: "100%", paddingTop: "4px" }}
        variant="h6"
      >{`${usagePercentage()}%`}</Typography>
    </Box>
  );
};

export default React.memo(DataUsageProgressBar);

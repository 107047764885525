import {
  ViewState,
  ViewTypes,
  ShowFlashMessage,
  ShowSnackbar,
} from "store/actions/view";
import * as actionTypes from "store/actions/view";
import { updateObject } from "shared/utility";
import {
  SetSearchInput,
  SetSearchContext,
  SetCurrentCollection,
  DisplayImageViewer,
  SetPathAfterLogin,
  SetDisplayTimepickerOnAddingNewItems,
  AddFilesFromDragNDrop,
  DisplayMaxDataUsageReachedDialog,
  ArchivesContainerIsDisplayed,
  HideMainSearchBar,
  SetCurrentUserRightForOpenedCollection,
  ShowConfidentialityFilter,
} from "../actions/view";
import { shouldShowConfidentialityFilter } from "localstorage/localstorage";
import { UserAccessRight } from "models/user";
import {
  HIDE_MAIN_SEARCH_BAR,
  DisplayCommentsView,
  DISPLAY_COMMENTS_VIEW,
} from "../actions/view";

//The state of this store gets reset after logout
const initialState: ViewState = {
  flashMessage: {
    showMessage: false,
    message: "",
  },
  pathAfterLogin: "",
  searchInput: "",
  searchContext: "",
  currentlyOpenedCollection: null,
  createNewNote: false,
  addLink: false,
  isCollectionUIDisplayed: false, // used to signal to the FAB button that the current UI is a collections UI, so that when it's clicked the user is prompted to add a new collection
  snackBarState: {
    message: "",
    action: "",
    onAction: null,
  },
  displayImageViewer: null,
  displayNoteImageViewer: null,
  displayAttachDataToCollectionDialog: {
    showAttachToCollectionDialog: false,
    aggdataToAttach: undefined,
  },
  displayReminderDatePicker: {
    displayPicker: false,
    aggDataArray: undefined,
  },
  displayTimepickerOnAddingNewItems: {
    displayTimePicker: false,
    selectedDayDate: new Date(),
  },
  addFilesFromDragAndDrop: null,
  displayMaxDataUsageReachedDialog: false,
  hideAddNewItemFabButton: false,
  showConfidentialityFilter: shouldShowConfidentialityFilter(),
  currentlyOpenedCollectionCurrentUserRight: UserAccessRight.OWNER_ACCESS_RIGHT,
  hideMainSearchBar: false,
  displayCommentsViewState: null,
  aggdataClicked: null,
  savePublicAggdataClicked: null,
  displayLoginModal: null,
  publicCollectionOpened: false,
};

const resetStore = (state: ViewState) => {
  //We only keep the pathafterlogin, which gets wiped if we reset the store, and redirection after login doesn't work anymore.
  return {
    ...initialState,
    pathAfterLogin: state.pathAfterLogin,
    publicCollectionOpened: state.publicCollectionOpened,
  };
};

const displayMaxDataUsageReachedDialog = (
  state: ViewState,
  action: SetCurrentUserRightForOpenedCollection
) => {
  const updateState = {
    ...state,
    displayMaxDataUsageReachedDialog: action.payload,
  };
  return updateObject(state, updateState);
};

const setCurrentlyOpenedCollectionCurrentUserRight = (
  state: ViewState,
  action: DisplayMaxDataUsageReachedDialog
) => {
  const updateState = {
    ...state,
    currentlyOpenedCollectionCurrentUserRight: action.payload,
  };
  return updateObject(state, updateState);
};

const setArchivesContainerIsDisplayed = (
  state: ViewState,
  action: ArchivesContainerIsDisplayed
) => {
  const updateState = {
    ...state,
    hideAddNewItemFabButton: action.payload,
  };
  return updateObject(state, updateState);
};

const setHideMainSearchBar = (state: ViewState, action: HideMainSearchBar) => {
  const updateState = {
    ...state,
    hideMainSearchBar: action.payload,
  };
  return updateObject(state, updateState);
};

const setPublicCollectionOpened = (
  state: ViewState,
  action: actionTypes.PublicCollectionOpened
) => {
  const updateState = {
    ...state,
    publicCollectionOpened: action.payload,
  };
  return updateObject(state, updateState);
};

const setDisplayCommentsView = (
  state: ViewState,
  action: DisplayCommentsView
) => {
  const updateState = {
    ...state,
    displayCommentsViewState: action.payload,
  };
  return updateObject(state, updateState);
};

const showConfidentialityFilter = (
  state: ViewState,
  action: ShowConfidentialityFilter
) => {
  const updateState = {
    ...state,
    showConfidentialityFilter: action.payload,
  };
  return updateObject(state, updateState);
};

const showSnackbar = (state: ViewState, action: ShowSnackbar) => {
  const updateState = {
    ...state,
    snackBarState: action.payload,
  };
  return updateObject(state, updateState);
};

const showFlashMessage = (state: ViewState, action: ShowFlashMessage) => {
  const updatedState = {
    ...state,
    flashMessage: {
      showMessage: true,
      message: action.payload,
    },
  };
  return updateObject(state, updatedState);
};

const hideFlashMessage = (state: ViewState) => {
  return updateObject(state, initialState);
};

const setSearchInput = (state: ViewState, action: SetSearchInput) => {
  const updatedState = {
    ...state,
    searchInput: action.payload,
  };
  return updateObject(state, updatedState);
};

const setSearchContext = (state: ViewState, action: SetSearchContext) => {
  const updatedState = {
    ...state,
    searchContext: action.payload,
  };
  return updateObject(state, updatedState);
};

const setCurrentCollection = (
  state: ViewState,
  action: SetCurrentCollection
) => {
  const updatedState = {
    ...state,
    currentlyOpenedCollection: action.payload,
  };
  return updateObject(state, updatedState);
};

const updateAddFilesState = (
  state: ViewState,
  action: AddFilesFromDragNDrop
) => {
  const updatedState = {
    ...state,
    addFilesFromDragAndDrop: action.payload,
  };
  return updateObject(state, updatedState);
};

const setPathAfterLogin = (state: ViewState, action: SetPathAfterLogin) => {
  console.log("pathafterlogin " + action.payload);
  const updatedState = {
    ...state,
    pathAfterLogin: action.payload,
  };
  return updateObject(state, updatedState);
};

const displayImageViewer = (state: ViewState, action: DisplayImageViewer) => {
  const updatedState = {
    ...state,
    displayImageViewer: action.payload,
  };
  return updateObject(state, updatedState);
};

const displayNoteImageViewer = (
  state: ViewState,
  action: actionTypes.DisplayNoteImagesViewer
) => {
  const updatedState = {
    ...state,
    displayNoteImageViewer: action.payload,
  };
  return updateObject(state, updatedState);
};

const closeImageViewer = (state: ViewState) => {
  const updatedState = {
    ...state,
    displayImageViewer: null,
  };
  return updateObject(state, updatedState);
};

const closeNoteImageViewer = (state: ViewState) => {
  const updatedState = {
    ...state,
    displayNoteImageViewer: null,
  };
  return updateObject(state, updatedState);
};

const displayAddNewLinkInput = (state: ViewState) => {
  const updatedState = {
    ...state,
    addLink: true,
  };
  return updateObject(state, updatedState);
};
const hideAddNewLinkInput = (state: ViewState) => {
  const updatedState = {
    ...state,
    addLink: false,
  };
  return updateObject(state, updatedState);
};

const setDisplayTimepickerOnAddingNewItems = (
  state: ViewState,
  action: SetDisplayTimepickerOnAddingNewItems
) => {
  const updatedState = {
    ...state,
    displayTimepickerOnAddingNewItems: action.payload,
  };
  return updateObject(state, updatedState);
};

const setAggdataCLicked = (
  state: ViewState,
  action: actionTypes.AggdataClickedInterface
) => {
  const updatedState = {
    ...state,
    aggdataClicked: action.payload,
  };
  return updateObject(state, updatedState);
};

const setSavePublicAggdataClicked = (
  state: ViewState,
  action: actionTypes.SavePublicAggdataClickedInterface
) => {
  const updatedState = {
    ...state,
    savePublicAggdataClicked: action.payload,
  };
  return updateObject(state, updatedState);
};

const setDisplayLoginModal = (
  state: ViewState,
  action: actionTypes.DisplayLoginModalInterface
) => {
  const updatedState = {
    ...state,
    displayLoginModal: action.payload,
  };
  return updateObject(state, updatedState);
};

const reducer = (state = initialState, action: ViewTypes) => {
  //console.log("reducer action is " + JSON.stringify(action));
  switch (action.type) {
    case actionTypes.PUBLIC_COLLECTION_OPENED:
      return setPublicCollectionOpened(state, action);
    case actionTypes.DISPLAY_LOGIN_MODAL:
      return setDisplayLoginModal(state, action);
    case actionTypes.SAVE_PUBLIC_AGGDATA_CLICKED:
      return setSavePublicAggdataClicked(state, action);
    case actionTypes.AGGDATA_CLICKED:
      return setAggdataCLicked(state, action);
    case actionTypes.RESET_STORE:
      return resetStore(state);
    case actionTypes.SHOW_SNACKBAR:
      return showSnackbar(state, action);
    case actionTypes.SHOW_FLASH_MESSAGE:
      return showFlashMessage(state, action);
    case actionTypes.HIDE_FLASH_MESSAGE:
      return hideFlashMessage(state);
    case actionTypes.SET_SEARCH_INPUT:
      return setSearchInput(state, action);
    case actionTypes.SET_SEARCH_CONTEXT:
      return setSearchContext(state, action);
    case actionTypes.SET_CURRENT_COLLECTION:
      return setCurrentCollection(state, action);
    case actionTypes.DISPLAY_IMAGE_VIEWER:
      return displayImageViewer(state, action);
    case actionTypes.DISPLAY_NOTE_IMAGE_VIEWER:
      return displayNoteImageViewer(state, action);
    case actionTypes.CLOSE_IMAGE_VIEWER:
      return closeImageViewer(state);
    case actionTypes.CLOSE_NOTE_IMAGE_VIEWER:
      return closeNoteImageViewer(state);
    case actionTypes.DISPLAY_ADD_NEW_LINK_INPUT:
      return displayAddNewLinkInput(state);
    case actionTypes.SET_PATH_AFTER_LOGIN:
      return setPathAfterLogin(state, action);
    case actionTypes.HIDE_ADD_NEW_LINK_INPUT:
      return hideAddNewLinkInput(state);
    case actionTypes.DISPLAY_TIME_PICKER_ON_ADDING_NEW_ITEMS:
      return setDisplayTimepickerOnAddingNewItems(state, action);
    case actionTypes.ADD_FILES_FROM_DRAG_AND_DROP:
      return updateAddFilesState(state, action);
    case actionTypes.DISPLAY_MAX_DATA_USAGE_DIALOG:
      return displayMaxDataUsageReachedDialog(state, action);
    case actionTypes.HIDE_ADD_NEW_ITEM_FAB_BUTTON:
      return setArchivesContainerIsDisplayed(state, action);
    case actionTypes.HIDE_MAIN_SEARCH_BAR:
      return setHideMainSearchBar(state, action);
    case actionTypes.DISPLAY_COMMENTS_VIEW:
      return setDisplayCommentsView(state, action);
    case actionTypes.HIDE_COMMENTS_VIEW:
      return setDisplayCommentsView(state, action);
    case actionTypes.SHOW_CONFIDENTIALITY_FILTER:
      return showConfidentialityFilter(state, action);
    case actionTypes.SET_CURRENT_USER_OPENED_COLLECTION_RIGHT:
      return setCurrentlyOpenedCollectionCurrentUserRight(state, action);
    case actionTypes.UPDATE_COLLECTION_CONTEXT:
      return { ...state, isCollectionUIDisplayed: action.payload };
    case actionTypes.CLOSE_ATTACH_DATA_TO_COLLECTION_DIALOG:
      return { ...state, displayAttachDataToCollectionDialog: action.payload };
    case actionTypes.OPEN_ATTACH_DATA_TO_COLLECTION_DIALOG:
      return { ...state, displayAttachDataToCollectionDialog: action.payload };
    case actionTypes.OPEN_REMINDER_DATE_PICKER:
      return { ...state, displayReminderDatePicker: action.payload };
    case actionTypes.CLOSE_REMINDER_DATE_PICKER:
      return { ...state, displayReminderDatePicker: action.payload };
    default:
      return state;
  }
};

export default reducer;

import {
  Box,
  Button,
  Fade,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import {
  AggDataObj,
  DataType,
  buildLinkPreviewInformation,
  getLinkPreviewInformation,
} from "models/aggdata";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "@apollo/client";
import { updateAggDataGql } from "graphql/mutations";
import AggdataCommentView from "containers/Comments/AggdataCommentView";

interface EditAggdataLinkPreviewModalProps {
  open: boolean;
  handleModalClose: (open: boolean) => void;
  aggdata: AggDataObj;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "60%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      height: "70%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "16px",
      flex: "1 1 0",
      /*       "& .MuiInput-root": {
        height: "70%",
      }, */
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
  })
);
const EditAggdataPreviewModal = ({
  open,
  handleModalClose,
  aggdata,
}: EditAggdataLinkPreviewModalProps) => {
  const classes = useStyles();
  const [updateAggData, updateAggDataResult] = useMutation(updateAggDataGql);

  const [aggData, setAggdata] = React.useState(aggdata);
  const [title, setTitle] = React.useState(() => {
    const previewInformation = getLinkPreviewInformation(aggdata);
    return previewInformation?.title;
  });
  const [description, setDescription] = React.useState(() => {
    const previewInformation = getLinkPreviewInformation(aggdata);
    return previewInformation?.description;
  });
  const [previewImageUrl, setPreviewImageUrl] = React.useState(() => {
    const previewInformation = getLinkPreviewInformation(aggdata);
    return previewInformation?.imgUrl;
  });

  React.useEffect(() => {
    const previewInformation = getLinkPreviewInformation(aggdata);
    let newDataContent = buildLinkPreviewInformation(
      title,
      description,
      previewImageUrl,
      previewInformation?.hostUrl
    );
    setAggdata({ ...aggData, dataContent: newDataContent });
  }, [title, description, previewImageUrl]);

  const { t } = useTranslation();

  const hanleUpdateContent = React.useCallback(() => {
    var aggDataUpdateForBackend = {
      id: aggdata.id,
      dataContent: aggData.dataContent,
    };
    updateAggData({
      variables: { input: aggDataUpdateForBackend },
      refetchQueries: undefined,
    })
      .then(() => {
        handleModalClose(false);
      })
      .catch((e) => {
        //If update with the backend fails, we rollback the changes
        /* updateAggDataInCache(
          updateAggDataResult.client.cache,
          beforeUpdateAggData
        ); */
      });
  }, [aggData, handleModalClose]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        onClick={(e) => {
          e.stopPropagation(); //useful because for some reason when you click outside to close the modal the click still propagates to components underneath
        }}
      >
        <Fade in={open}>
          <div
            className={classes.paper}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {/*             {(aggdata.dataType == DataType.IMAGE_TYPE ||
                aggdata.dataType == DataType.VIDEO_TYPE) && (
                <img
                  src={getImageForDataId(aggdata.id)}
                  style={{
                    width: "auto",
                    objectFit: "contain",
                    overflow: "hidden",
                    borderRadius: "16px",
                    maxHeight: "100%",
                    background: colorMementoBeige,
                    flex: "1 1 0px",
                  }}
                />
              )}
              {aggdata.dataType == DataType.AUDIO_TYPE && (
                <Box>
                  <AudioPlayer aggdataObj={aggdata} />
                </Box>
              )} */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                height: "100%",
                flex: "1 1 0px",
                justifyContent: "center",
              }}
            >
              <AggdataCommentView aggData={aggData}></AggdataCommentView>
            </Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                flex: "1 1 0px",
                padding: "16px",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  marginTop: "16px",
                }}
              ></Box>
              <TextField
                label={t("title")}
                variant="standard"
                className={classes.inputLabel}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                maxRows={10}
                defaultValue={aggdata.annotation}
                multiline
                color="primary"
                inputProps={{
                  style: {
                    fontSize: "large",
                    height: "100%",
                    lineHeight: "normal",
                  },
                }}
                InputLabelProps={{ style: { fontSize: "medium" } }}
                focused
              />
              <TextField
                label={t("preview_url_title")}
                variant="standard"
                className={classes.inputLabel}
                value={previewImageUrl}
                onChange={(e) => {
                  setPreviewImageUrl(e.target.value);
                }}
                maxRows={10}
                defaultValue={aggdata.annotation}
                multiline
                color="primary"
                inputProps={{
                  style: {
                    fontSize: "large",
                    height: "100%",
                    lineHeight: "normal",
                  },
                }}
                InputLabelProps={{ style: { fontSize: "medium" } }}
                focused
              />
              <TextField
                label={t("description")}
                variant="standard"
                className={classes.inputLabel}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                maxRows={10}
                defaultValue={aggdata.annotation}
                multiline
                color="primary"
                inputProps={{
                  style: {
                    fontSize: "large",
                    height: "100%",
                    lineHeight: "normal",
                  },
                }}
                InputLabelProps={{ style: { fontSize: "medium" } }}
                focused
              />
              <LoadingButton
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
                loading={updateAggDataResult.loading}
                variant="contained"
                onClick={hanleUpdateContent}
              >
                {t("save_action")}
              </LoadingButton>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(EditAggdataPreviewModal);

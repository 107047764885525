import React from "react";
import { Switch } from "react-router-dom";
import Route from "routes/Route";
import Login from "pages/Login/login";
import Register from "pages/Register/register";
import AccountActivationPage from "pages/AccountActivation/AccountActivationPage";
import RequestResetPassword from "pages/ResetPassword/requestresetpassword";
import ResetPassword from "pages/ResetPassword/resetpassword";
import MainBoard from "pages/MainBoard/MainBoard";
import LandingMain from "pages/Landing/LandingMain";
import {
  ARCHIVE,
  DEV_EXT,
  SAVE_DATA,
  ROOT,
  LOGIN,
  MAINBOARD,
  MAINBOARD_WITH_SUBPATH,
  MAINBOARD_WITH_SUBPATH_ID,
  MAINBOARD_WITH_INVITATION_ID,
  REGISTER,
  REGISTER_GOOGLE_MOBILE_PATH,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  PRICING,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  PAYMENT,
  SETTINGS,
  SETTINGS_WITH_SUBPATH,
  ACCOUNT_ACTIVATION,
  MAINBOARD_WITH_SUBPATH_ARCHIVE_ITEM_ID,
  NOTION_AUTH_PATH,
  PINTEREST_AUTH_PATH,
  PUBLIC_COLLECTION_ROUTE_SUBPATH_ID,
  PUBLIC_USER_PROFILE_SUBPATH_ID
} from "./Constants";
import SetupExtension from "pages/Extension/SetupExtension";
import ExtensionDev from "pages/Dev/ExtensionDev";
import Pricing from "components/Pricing/Pricing";
import Payment from "pages/Payments/Payment";
import UserSettings from "pages/Settings/UserSettings";
import PrivacyPolicy from "pages/Privacy/PrivacyPolicy";
import TermsConditions from "pages/Privacy/TermsConditions";
import NotionAuthRedirect from "integrationsauth/NotionAuthRedirect";
import PinterestAuthRedirect from "integrationsauth/PinterestAuthRedirect";
import PublicCollection from "components/CollectionItems/public/PublicCollection";

import { DEBUG } from "configuration";
import PublicUserProfile from "containers/User/PublicUserProfile";


function MainRouterConfig() {
  return (
    <>
      <Switch>
        <Route isPrivate path={MAINBOARD} exact component={MainBoard}/>
        <Route isPrivate path={MAINBOARD_WITH_SUBPATH} exact component={MainBoard}/>
        {/**Here order is important. Route MAINBOARD_WITH_SUBPATH_ARCHIVE_ITEM_ID needs to be declared before  MAINBOARD_WITH_SUBPATH_ID,
         * otherwise we won't be able to catch the id params from MAINBOARD_WITH_SUBPATH_ARCHIVE_ITEM_ID
        */}
        <Route isPrivate path={MAINBOARD_WITH_INVITATION_ID} component={MainBoard}/>
        <Route isPrivate path={NOTION_AUTH_PATH} component={NotionAuthRedirect}/>
        <Route isPrivate path={PINTEREST_AUTH_PATH} component={PinterestAuthRedirect}/>
        <Route isPrivate path={MAINBOARD_WITH_SUBPATH_ARCHIVE_ITEM_ID} component={MainBoard}/> 
        <Route isPrivate path={MAINBOARD_WITH_SUBPATH_ID} component={MainBoard}/>
        <Route isPrivate path={SAVE_DATA} exact component={SetupExtension} />
        <Route isPrivate path={PAYMENT} exact component={Payment} />
        <Route isPrivate path={SETTINGS_WITH_SUBPATH} exact component={UserSettings} />
        <Route isPrivate path={SETTINGS} exact component={UserSettings} />
        <Route path={PUBLIC_COLLECTION_ROUTE_SUBPATH_ID} component={PublicCollection}/>
        <Route path={PUBLIC_USER_PROFILE_SUBPATH_ID} component={PublicUserProfile}/>
        <Route path={ACCOUNT_ACTIVATION} exact component={AccountActivationPage} />
        <Route path={PRICING} exact component={Pricing} />
        <Route path={PRIVACY_POLICY} exact component={PrivacyPolicy} />
        <Route path={TERMS_CONDITIONS} exact component={TermsConditions} />
        {DEBUG && <Route path={DEV_EXT} exact component={ExtensionDev} />}
        <Route path={ROOT} exact component={LandingMain} />
        <Route path={LOGIN} exact component={Login} />
        <Route
          path={REQUEST_RESET_PASSWORD}
          exact
          component={RequestResetPassword}
        />
        <Route path={RESET_PASSWORD} exact component={ResetPassword} />
        <Route path={REGISTER} exact component={Register} />
        <Route path={REGISTER_GOOGLE_MOBILE_PATH} exact component={Register} />
      </Switch>
    </>
  );
}

export default React.memo(MainRouterConfig);

import styled from "styled-components";
import React from "react";
import CollectionPlaceholderImg from "icons/ic_memento_lists_placeholder.svg";

export interface ImageProps {
  previewUrls: string[] | null | undefined;
  height?: string;
  width?: string;
  imgSpacing?: string;
  hideBorder?: boolean;
}

const ImagesContainer = styled("div")<{
  height?: string;
  width?: string;
  hideBorder?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.hideBorder ? "none" : "1px dashed #FDC84B")};
  border-radius: 6px;
  height: ${(props) => (props.height ? props.height : "200px")};
  width: ${(props) => (props.width ? props.width : "100%")};
`;

const ImagesContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 50%;
  width: 100%;
`;

function ImagePreview({
  previewUrls,
  height,
  imgSpacing,
  width,
  hideBorder,
}: ImageProps) {
  //console.log("preview urls length" + previewUrls?.length);
  const [successfulPreviewUrls, setSuccessfullPreviewUrls] = React.useState(
    previewUrls
  );

  const handleOnLoadingError = React.useCallback(
    (urlError: string) => {
      setSuccessfullPreviewUrls(
        successfulPreviewUrls?.filter((url) => url != urlError)
      );
    },
    [successfulPreviewUrls, setSuccessfullPreviewUrls]
  );

  if (!successfulPreviewUrls || successfulPreviewUrls.length === 0) {
    return (
      <ImagesContainer height={height} width={width} hideBorder={hideBorder}>
        <img
          style={{
            width: "100%",
            height: "100%",
            padding: imgSpacing ? imgSpacing : "2px",
            borderRadius: imgSpacing == "0px" ? "0px" : "6px",
            objectFit: "cover",
          }}
          src={CollectionPlaceholderImg}
        />
      </ImagesContainer>
    );
  } else if (successfulPreviewUrls.length === 1) {
    return (
      <ImagesContainer height={height} width={width} hideBorder={hideBorder}>
        <ImagesContainerRow style={{ height: "100%" }}>
          <img
            key={successfulPreviewUrls[0]}
            style={{
              width: "100%",
              height: "100%",
              padding: imgSpacing ? imgSpacing : "2px",
              borderRadius: "4px",
              objectFit: "cover",
            }}
            src={successfulPreviewUrls[0]}
            loading="lazy"
            onError={(e) => {
              handleOnLoadingError(successfulPreviewUrls[0]);
            }}
          />
        </ImagesContainerRow>
      </ImagesContainer>
    );
  } else if (successfulPreviewUrls.length === 2) {
    return (
      <ImagesContainer height={height} width={width} hideBorder={hideBorder}>
        <ImagesContainerRow style={{ height: "100%" }}>
          {successfulPreviewUrls.map((item, index) => {
            return (
              <img
                key={index}
                style={{
                  width: "50%",
                  maxWidth: "50%",
                  maxHeight: "100%",
                  padding: imgSpacing ? imgSpacing : "2px",
                  borderRadius: imgSpacing == "0px" ? "0px" : "6px",
                  objectFit: "cover",

                  borderTopRightRadius: index == 0 ? "0px" : "4px",
                  borderTopLeftRadius: index == 0 ? "4px" : "0px",
                  borderBottomRightRadius: index == 0 ? "0px" : "4px",
                  borderBottomLeftRadius: index == 0 ? "4px" : "0px",
                }}
                src={item}
                loading="lazy"
                onError={(e) => {
                  handleOnLoadingError(item);
                }}
              />
            );
          })}
        </ImagesContainerRow>
      </ImagesContainer>
    );
  } else if (successfulPreviewUrls.length === 3) {
    return (
      <ImagesContainer height={height} width={width} hideBorder={hideBorder}>
        <ImagesContainerRow>
          {successfulPreviewUrls.slice(0, 2).map((item, index) => {
            {
              console.log("index here for slice " + index);
            }
            return (
              <img
                key={index}
                style={{
                  width: "50%",
                  maxWidth: "50%",
                  maxHeight: "100%",
                  padding: imgSpacing ? imgSpacing : "2px",
                  borderRadius: imgSpacing == "0px" ? "0px" : "6px",
                  objectFit: "cover",
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
                }}
                src={item}
                loading="lazy"
                onError={(e) => {
                  handleOnLoadingError(item);
                }}
              />
            );
          })}
        </ImagesContainerRow>
        <ImagesContainerRow>
          <img
            key={3}
            style={{
              width: "100%",
              height: "100%",
              padding: imgSpacing ? imgSpacing : "2px",
              borderRadius: imgSpacing == "0px" ? "0px" : "6px",
              objectFit: "cover",
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
            src={successfulPreviewUrls[2]}
            loading="lazy"
            onError={(e) => {
              handleOnLoadingError(successfulPreviewUrls[2]);
            }}
          />
          ;
        </ImagesContainerRow>
      </ImagesContainer>
    );
  } else {
    return (
      <ImagesContainer height={height} width={width} hideBorder={hideBorder}>
        <ImagesContainerRow>
          {successfulPreviewUrls.slice(0, 2).map((item, index) => {
            return (
              <img
                key={index}
                style={{
                  width: "50%",
                  maxWidth: "50%",
                  maxHeight: "100%",
                  padding: imgSpacing ? imgSpacing : "2px",
                  borderRadius: imgSpacing == "0px" ? "0px" : "6px",
                  objectFit: "cover",
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
                }}
                src={item}
                loading="lazy"
                onError={(e) => {
                  handleOnLoadingError(item);
                }}
              />
            );
          })}
        </ImagesContainerRow>
        <ImagesContainerRow>
          {successfulPreviewUrls.slice(2, 4).map((item, index) => {
            return (
              <img
                key={index + 2}
                style={{
                  width: "50%",
                  maxWidth: "50%",
                  maxHeight: "100%",
                  padding: imgSpacing ? imgSpacing : "2px",
                  borderRadius: imgSpacing == "0px" ? "0px" : "6px",
                  objectFit: "cover",
                  borderBottomRightRadius: "4px",
                  borderBottomLeftRadius: "4px",
                }}
                src={item}
                loading="lazy"
                onError={(e) => {
                  handleOnLoadingError(item);
                }}
              />
            );
          })}
        </ImagesContainerRow>
      </ImagesContainer>
    );
  }
}

export default React.memo(ImagePreview);

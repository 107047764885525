import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import Avatar from "@mui/material/Avatar";
import { colorMemento, stringToColour } from "shared/colors";
import { SharedUserAccess, UserObj } from "models/user";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getUserAvatarForEmail, getUserAvatarForId } from "configuration";

export enum Size {
  NORMAL = "normal",
  MEDIUM = "medium",
  SMALL = "small",
}
interface SharedUserAvatarProps {
  user: UserObj;
  applyLeftMargin?: boolean;
  applyLeftRight?: boolean;
  size?: Size; //can be small or normal
}

const useStyles = makeStyles((theme: Theme) => ({
  avatarHovered: {
    //transition: "transform 0.15s ease-in-out",
    transform: "scale(1.2)",
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: 200,
    }),
  },
  avatarNotHovered: {
    transform: "scale(1)",
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: 200,
    }),
  },
}));

const SharedUserAvatar = ({
  user,
  applyLeftMargin,
  size,
  applyLeftRight = true,
}: SharedUserAvatarProps) => {
  const classes = useStyles();
  const [avatarHovered, setAvatarHovered] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(false);
  const { t } = useTranslation();
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  const getToolTipContent = React.useCallback(
    (user: UserObj) => {
      if (currentUser && currentUser.id == user.id) {
        return t("me_myself");
      } else {
        var displayedContent = user.firstName + " " + user.lastName;
        if (user.username) {
          displayedContent += " (" + user.username + ")";
        }
        return displayedContent;
      }
    },
    [currentUser]
  );

  const getUserInitials = React.useCallback(() => {
    if (user.userInitials) {
      var userInitials = user.userInitials;
    } else if (user?.firstName && user?.lastName) {
      var userInitials = user?.firstName.charAt(0) + user?.lastName.charAt(0);
      if (userInitials.length == 0) {
        userInitials = user?.username.substring(0, 2);
      }
    } else if (user.username) {
      userInitials = user?.username.substring(0, 2);
    } else {
      userInitials = "..";
    }
    userInitials = userInitials.toUpperCase();
    return userInitials;
  }, [user]);

  const userFullName = user.firstName + user.lastName;
  const avatarColor = user.username
    ? stringToColour(user.username)
    : userFullName && userFullName.length > 0
    ? stringToColour(userFullName)
    : { textColor: "#000000", backgroundColor: colorMemento };
  return (
    <Tooltip
      key={user.id}
      open={avatarHovered}
      arrow
      title={styledTooltipTitle(getToolTipContent(user))}
    >
      <Avatar
        className={
          avatarHovered ? classes.avatarHovered : classes.avatarNotHovered
        }
        onMouseEnter={() => setAvatarHovered(true)}
        onMouseLeave={() => setAvatarHovered(false)}
        color="red"
        src={getUserAvatarForId(user.id)}
        style={{
          border: "thick double #fff",
          fontSize:
            size == Size.SMALL
              ? "1.1rem"
              : size == Size.MEDIUM
              ? "1.rem"
              : "1.7rem",
          width:
            size == Size.SMALL ? "30px" : size == Size.MEDIUM ? "37px" : "45px",
          height:
            size == Size.SMALL ? "30px" : size == Size.MEDIUM ? "37px" : "45px",
          cursor: "pointer",
          marginLeft: !applyLeftMargin
            ? "0"
            : size == Size.SMALL
            ? "-12px"
            : "-12px",
          //filter: avatarFilter,
          backgroundColor: avatarColor.backgroundColor,
          color: avatarColor.textColor,
          // backgroundColor: theme.palette.primary.main,
        }}
      >
        {getUserInitials()}
      </Avatar>
    </Tooltip>
  );
};

export default React.memo(SharedUserAvatar);

import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { APP_PLAYSTORE_LINK } from "configuration";
import RealTimeSyncIllustration from "illustrations/undraw_real_time_sync.svg";
import GetItOnPlayStore from "icons/google-play-badge.png";
import {TITLE_TEXT_SIZE, SUBTITLE_TEXT_SIZE} from "pages/Landing/Content";

const MainContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const RealTimeSyncIllustraitonContainer = styled.img`
  width: 35%;
  @media (max-width: 780px) {
    width: 70vw;
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const RealTimeSyncTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  @media (max-width: 780px) {
    width: 100%;
    margin-top: 10%;
  }
`;

const GetItOnPlaystoreBadge = styled.img`
  width: 30%;
  margin-top: 2%;
`;

const RealTimeSyncContainer = () => {
  const redirectToPlaystore = useCallback(() => {
    window.open(APP_PLAYSTORE_LINK, "_blank");
  }, []);
  
  const { t } = useTranslation();

  return (
    <MainContainer>
      <RealTimeSyncIllustraitonContainer src={RealTimeSyncIllustration} alt="Synchronize your notes, screenshots, links files with mobile and web" />
      <RealTimeSyncTextContainer>
        <Typography variant="h4" sx={{ fontSize: TITLE_TEXT_SIZE }}>
          {t('landing_page_sync_data_title')}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: SUBTITLE_TEXT_SIZE,
            fontFamily: "Trebuchet MS, sans-serif",
            fontWeight: 300,
          }}
        >
        {t('landing_page_sync_data_description')}
        </Typography>
        <GetItOnPlaystoreBadge
          onClick={redirectToPlaystore}
          src={GetItOnPlayStore}
        ></GetItOnPlaystoreBadge>
      </RealTimeSyncTextContainer>
    </MainContainer>
  );
};

export default RealTimeSyncContainer;

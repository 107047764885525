import React, { useState, useCallback, useRef } from "react";
import styled from "styled-components";
import { retrieveCommentsNotificationsForCollectionGql } from "graphql/queries";
import { CollectionObj } from "models/collection";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useLazyQuery } from "@apollo/client";
import { DEBUG } from "configuration";
import { deleteCommentsNotificationsCacheForCollection } from "graphql/helpers";
import { useApolloClient } from "@apollo/client";
import CommentIcon from "@mui/icons-material/Comment";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import CollectionBadge from "components/UI/CollectionBadge";
import CommentNotificationContainer from "containers/Comments/CommentNotificationContainer";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

export const MainContainer = styled("div")<{}>`
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  transition: width 0.2s ease-in, height 0.2s ease-in;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex: 1 1 auto;
  margintop: 8px;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
   
  }

  cursor: default;
`;

interface CommentsDrawerProps {
  collection: CollectionObj;
  open: boolean;
  onClose: () => void;
}
const PAGE_SIZE = 15;
const CommentsDrawer = ({ collection, open, onClose}: CommentsDrawerProps) => {
  const fetchMoreOngoing = React.useRef(false);
  const lastFetchedPage = React.useRef(1);
  const [allCommentsRetrieved, setAllCommentsRetrieved] = React.useState(false);
  const [commentsListEmpty, setCommentsListEmpty] = React.useState(false);
  const { t } = useTranslation();
  const client = useApolloClient();

  const ref = useRef<any>(null);

  const [fetchFirstPage, commentsQueryResult] = useLazyQuery(
    retrieveCommentsNotificationsForCollectionGql,
    {
      variables: {
        collectionId: collection.id,
        page: 1,
        pageSize: PAGE_SIZE,
      },
      context: {
        clientName: collection.isPublic ?  "public" : undefined,
      }
    }
  );

  React.useEffect(() => {
    document.addEventListener(
      "keydown",
      (event: any) => {
        if (event.key === "Escape") {
          onClose();
        }
      },
      false
    );
  }, []);
  
  const handleClickOutside = React.useCallback((e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      onClose()
    }
  }, [ref]);

  React.useEffect(() => {
    if (open){
      document.addEventListener('mousedown', handleClickOutside);
      fetchFirstPage()
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      deleteCommentsNotificationsCacheForCollection(client.cache, collection.id); 
      //We reset everything for the next render to be from scratch
      fetchMoreOngoing.current= false;
      lastFetchedPage.current = 1;
      setAllCommentsRetrieved(false);
      setCommentsListEmpty(false);
      var myDiv = document.getElementById("comments-container");
      myDiv.scrollTop = 0;
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      deleteCommentsNotificationsCacheForCollection(client.cache, collection.id);
    };
  }, [open]);

  React.useEffect(() => {
    if (
      commentsQueryResult.data &&
      !commentsQueryResult.loading &&
      !commentsQueryResult.error
    ) {
      if (commentsQueryResult.data.commentsForCollectionPaged.length == 0) {
        setCommentsListEmpty(true);
        setAllCommentsRetrieved(true);
      } else {
        setAllCommentsRetrieved(
          commentsQueryResult.data.commentsForCollectionPaged.length % PAGE_SIZE != 0
        );
      }
    }
  }, [commentsQueryResult]);
  

  const fetchMoreComments = React.useCallback(() => {
    if (fetchMoreOngoing.current) {
      if (DEBUG) {
        console.log("fetchMoreComments already ongoing");
      }
      return;
    }
    fetchMoreOngoing.current = true;

    if (allCommentsRetrieved) {
      if (DEBUG) {
        console.log("Already retrieved everything");
      }
      fetchMoreOngoing.current = false;
      return;
    }
    const nextPageToFetch = lastFetchedPage.current + 1;

    if (DEBUG) {
      console.log("Fetch more data for page " + nextPageToFetch);
    }
    commentsQueryResult
      .fetchMore({
        variables: {
          collectionId: collection.id,
          page: nextPageToFetch,
          pageSize: PAGE_SIZE,
        },
      })
      .then((result) => {
        if (DEBUG) {
          console.log("Fetch more result %o", result);
        }
        if (
          result.data &&
          result.data.commentsForCollectionPaged &&
          result.data.commentsForCollectionPaged.length == 0
        ) {
          setAllCommentsRetrieved(true);
        }
        lastFetchedPage.current = nextPageToFetch;
        fetchMoreOngoing.current = false;
      })
      .catch((e) => {
        //setFetchOngoing(false)
        fetchMoreOngoing.current = false;
      });
    //}
  }, [
    commentsQueryResult,
    commentsQueryResult.data,
    allCommentsRetrieved,
    fetchMoreOngoing.current,
  ]);

  const handleOnScroll = React.useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        //console.log("bottom reached momo");
        fetchMoreComments();
      }
    },
    [fetchMoreComments]
  );

  return (
    <>
      <Drawer
        ref={ref}
        anchor={"right"}
        open={open}
        hideBackdrop
        variant="persistent"
        onClick={() => {
          console.log("Drawer click")
        }}
        ModalProps={{
          keepMounted: false,
        }}
        onClose={onClose}
      >
        <Box
          sx={{
            width: "30vw",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overFlow: "hidden",
            "@media (max-width: 780px)": {
              width: "80vw",

            },
          }}
          role="presentation"

          //onClick={toggleDrawer(anchor, false)}
          //onKeyDown={toggleDrawer(anchor, false)}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "16px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconButton onClick={onClose}>
                <CloseIcon fontSize="large" />
              </IconButton>
              <Typography
                sx={{
                  fontWeight: 400,
                  marginLeft: "12px",
                  fontSize: "1.8rem",
                }}
                variant="h5"
              >
                {t('comments_title')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CollectionBadge collection={collection} readOnly />
            </Box>
          </Box>
          <Divider light />

          <MainContainer id={"comments-container"} onScroll={handleOnScroll}>
            {commentsQueryResult &&
              commentsQueryResult.data &&
              commentsQueryResult.data.commentsForCollectionPaged &&
              commentsQueryResult.data.commentsForCollectionPaged.map(
                (commentNotification: any, index: number) => {
                  return (
                    <CommentNotificationContainer
                      key={index}
                      commentNotification={{
                        user: commentNotification.user,
                        content: commentNotification.content,
                        created: commentNotification.created,
                        dataId: commentNotification.aggdata.id,
                        previewUrl: commentNotification.aggdata.previewUrl,
                      }}
                      //index={index}
                    />
                  );
                }
              )}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!allCommentsRetrieved && !commentsListEmpty && (
                <CircularProgress style={{ padding: "2px", marginTop: "16px" }} />
              )}
              {commentsListEmpty && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CommentIcon sx={{ width: "30%", height: "30%" }} />
                  <Typography variant="h5">
                    {t("no_comments_yet_title")}
                  </Typography>
                </Box>
              )}
            </Box>
          </MainContainer>
        </Box>
      </Drawer>
    </>
  );
};

export default React.memo(CommentsDrawer);

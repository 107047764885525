import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import SimpleHeader from "components/Header/SimpleHeader";
import { colorMemento } from "shared/colors";
import { Link as TextLink } from "@mui/material";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from "shared/utility";


const MainContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding-right: 5%;
  padding-left: 15%;
  margin-bottom: 5%;
  @media (max-width: 780px) {
    padding-left: 8%;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 780px) {
    width: 90%;
  }
`;

const Space = styled.section`
  height: 7%;
`;

const TermsConditions = () => {
  const { t } = useTranslation();
  //const history = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet>
        <title>{capitalizeFirstLetter(t('terms_and_conditions'))} | Memento</title>
      </Helmet>
      <SimpleHeader makeStatic={true} />

      <Typography
        variant="h2"
        sx={{
          fontSize: "5rem",
          fontFamily: "Trebuchet MS, sans-serif",
          textAlign: "center",
          marginTop: "5%",
          marginBottom: "10%",
        }}
      >
        Terms and conditions
      </Typography>
      <Space style={{ height: "20%" }} />
      <MainContainer>
        {/* {TEXT_CONTENT.map((content) => {
          return ( 
            <>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "3rem",
                  fontWeight: 530,
                  fontFamily: "Trebuchet MS, sans-serif",
                }}
              >
                {content.title}
              </Typography>
              {content.paragraphs.map((paragraph) => {
                return (
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Trebuchet MS, sans-serif",
                      marginTop: "2%",
                      fontSize: "2rem",
                    }}
                  >
                    {paragraph}
                  </Typography>
                );
              })}
            </>
          );
        })} */}

        <BodyContainer>
          <Typography
            variant="h3"
            sx={{
              fontSize: "1.6rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: colorMemento,
            }}
          >
            <span style={{ background: "white", color: "black" }}>
              Effective from
            </span>{" "}
              May 6th, 2023
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
              fontSize: "1.9rem",
            }}
          >
            By using Memento, the following terms of service will automatically
            apply to you – you should make sure therefore that you read them
            carefully before using the app. If you do not agree with the
            following terms of service as stated, you are therefore not allowed
            to use Memento. From the moment you start using Memento, you
            acknowledge, and accept all the terms and privacy policy we have in
            place, withouth any limitation. This includes using Memento software
            on all supported platforms, i.e. web application for laptops and
            mobile application.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
              fontSize: "1.9rem",
            }}
          >
            The following terms and conditions are subject to change through
            time due to various reasons, so strongly recommend you to check
            every once in a while this page. Whenever the changes are being
            considered as reasonably important, we will try to notify you.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
              fontSize: "1.9rem",
            }}
          >
            If at any point in time you no longer agree with how these terms
            evolve, then you must stop the using our service and contact us for
            more information. Continuing using Memento after any terms update
            will confirm your acceptance of the updated version of our terms.
          </Typography>
          <Space style={{ marginTop: "1%" }} />
          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: colorMemento,
            }}
          >
            Using Memento
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
              fontSize: "1.9rem",
            }}
          >
            Memento can be used in one of the following ways :
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              paddingLeft: "2%",
              fontSize: "1.9rem",
            }}
          >
            <b>• Connected mode</b>: This mode is enabled when you create a
            Memento account using your email address. It allows you to store
            data you input to Memento on our servers, and make it available on
            all your devices that are connected to the internet, either using
            Memento for web or for mobile devices.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              paddingLeft: "2%",
              fontSize: "1.9rem",
            }}
          >
            <b>• Offline mode</b>: This mode is enabled by default when you
            first install Memento app on your mobile device. You can input your
            data to Memento without having a Memento account. In this case, all
            the inputed data is stored solely on your local device. If at some
            point you switch to the connected mode, i.e. create a Memento
            account and login to it on your mobile device, all existing data on
            your local device will be synchronized with our servers. Note that
            this mode only exists on Memento for mobile devices, and is not
            relevant for the Memento web application.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            In the "Connected Mode", you are required to create an account using
            your "User information" including your first name, last name, email.
            Your account will be secured using a password of your choice, that
            we strongly encourage you to pick wisely (Guidelines for a robust
            password {"  "}
            <TextLink
              style={{ color: colorMemento, cursor: "pointer" }}
              onClick={() => {
                //history.push(PRIVACY_POLICY);
                window.open(
                  "https://www.bu.edu/tech/support/information-security/security-for-everyone/how-to-choose-a-strong-password/",
                  "_blank"
                );
              }}
            >
              here
            </TextLink>
            ) . You are fully responsible of keeping the secrecy of your account
            authentication information, i.e. your password, and we will not take
            any responsiblity for any illegitimate access to your account. We
            strongly encourage you to use only trusted and secure devices, on
            secure networks to access your account. If you suspect any
            unauthorized access to your account, we recommend you to change your
            password instantly and take action by disconnecting any unrecognized
            devices from the devices section in your account settings. If you
            ever loose or forget your password, we offer you the possibility to
            securely reset it and define a new one using your email address. In
            case your email address is compromised, we do not take
            responsibility for any data theft or loss that might occur as a
            result.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            Using Memento in connected Mode requires an internet connection. We
            are not responsible of any costs related to internet data usage that
            might result of using Memento.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            Using Memento in Offline Mode results into your data being stored on
            your local device. You are therefore responsible of your device
            security, and we will not take responsibility for any data loss or
            theft that might occur while using Memento in the "Offline Mode".
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            Note that your user information is personal and should only detained
            by yourself. Any account sharing is not authorized under the present
            terms.
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: colorMemento,
            }}
          >
            Prohibited activities
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            Memento is expected to be used as is, only for its intended
            purpose, without any excpetions unless formaly allowed by us. You
            therefore agree to, no limited to, the following : <br />
            <b>•</b> You are not allowed to attempt any operations that might
            compromise our services, or result in accessing unlawfully or
            illegitimately any data not owned by you. <br />
            <b>•</b> You are not allowed to make false claims about other users.
            <br />
            <b>•</b> You are not allowed to attempt to access others users'
            accounts, or any account not owned by you.
            <br />
            <b>•</b> You are not allowed to have any kind or form of automated
            interactions with our services, out of the scope of our service
            offerings, unless you have a formal authorization to do so provided
            by us. <br />
            <b>•</b> You are not allowed to upload any content prohibited by
            local regulations.
            <br />
            <b>•</b> You are not allowed to perform any activty that might cause
            harm or hurt us. <br />
            <b>•</b> You are not allowed to try to gain access to the Software
            used in our services, and modify it, redistribute it, or alter it
            for any purpose.
            <br />
            <b>•</b> You are not allowed to try to bypass any measure
            implemented by us regarding your user subscription plan. <br /><br />

            We monitor the misusage of some our features such as AI based features, and in some cases,
            reserve the right to suspend the availability to some users of those features, for a 
            limited or unlimited period of time even if they are entitled to those as part of a paid plan. 
            Some of these cases include and are not limited to : <br />
            <b>•</b> Abnormally high usage of our virtual assistant, the limit being defined at our own discretion<br /> 
            <b>•</b> Usage of our assistant to generate hateful content or engage in harmful or illegal activities<br />

            
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
              color: colorMemento,
            }}
          >
            Content ownership
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            The data you input to your account is owned by you. Any copyrights
            or others intellectual property rights you detain on the content you
            input to our service shall remain unchanged, and will not be
            affected by inputing it to your account. You are responsible of any
            modifications you make to your content through our service. If you
            decide to delete any of your content, we do not guarantee to recover
            it.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
              color: colorMemento,
            }}
          >
            Intellectual property rights
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            Unless otherwise indicated, Memento is our proprietary property and
            all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, and graphics on Memento
            (collectively, the “Content”) and the trademarks, service marks, and
            logos contained therein (the “Marks”) are owned or controlled by us
            or licensed to us, and are protected by copyright and trademark laws
            and various other intellectual property rights and unfair
            competition laws of the United States, international copyright laws,
            and international conventions. The Content and the Marks are
            provided on Memento “AS IS” for your information and personal use
            only. Except as expressly provided in these Terms of Use, no part of
            Memento and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or
            otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            Provided that you are eligible to use the Site, you are granted a
            limited license to access and use the Site and to download or print
            a copy of any portion of the Content to which you have properly
            gained access solely for your personal, non-commercial use. We
            reserve all rights not expressly granted to you in and to Memento,
            the Content and the Marks.
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
              color: colorMemento,
            }}
          >
            User data
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            We will retain certain data that you transmit to Memento for the
            purpose of providing our services as well as data relating to your
            use of the Site. Although we perform regular routine backups of
            data, you are solely responsible for all data that you transmit or
            that relates to any activity you have undertaken using the Site. You
            agree that we shall have no liability to you for any loss or
            corruption of any such data, and you hereby waive any right of
            action against us arising from any such loss or corruption of such
            data.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
              color: colorMemento,
            }}
          >
            Modifications and interruptions
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            We reserve the right to add, modify, update or remove the content or
            the services we provide, in our sole discretion, in order to update
            our offerings or for any other various reasons. We also reserve the
            right to discontinue our services if we were to close permanently
            for both predictable and unpredictable reasons. We do not guarantee
            that the service will be available at all times, as we might
            experience like all companies all kinds of technical problems which
            might result in a temporary suspension of our services. We will also
            not take any liability to you or any third party for any
            modification or discontinuance of our services. Nothing will
            obligate us to continue or provide any kind of support to maintain
            our services.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
              color: colorMemento,
            }}
          >
            DISCLAIMER
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR
            USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
            WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
            WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              marginTop: "4%",
              fontFamily: "Trebuchet MS, sans-serif",
              color: colorMemento,
            }}
          >
            Contact us
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
           For any questions, comments, or complaints about the present Privacy Policy, feel free to reach out to us 
           at support@mementocollect.com.
          </Typography>
          <Space />
          <Space />
          <Space />
        </BodyContainer>
      </MainContainer>
    </div>
  );
};

export default React.memo(TermsConditions);

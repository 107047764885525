import React, { useCallback, useState } from "react";
import AggDataItemsContainer, { RequestTypes } from "./AggDataItemsContainer";
import { useTheme } from "styled-components";
import {
  getAggdataFilterType,
  getAggdataSortType,
  getAggdataViewType,
} from "localstorage/localstorage";
import AggdataDisplayControl, {
  FILTER_TYPE,
  SORT_TYPE,
  VIEW_TYPE,
} from "components/UI/Controls/AggdataDisplayControl";
import { AggDataTagObj } from "models/aggdata";
import { Box, IconButton, Typography } from "@mui/material";
import { stringToColour } from "shared/colors";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import SimpleTagsFilter from "components/UI/Filters/SimpleTagsFilter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

interface TagsAggdataItemsContainerProps {
  tagName: string;
}

const TagsAggdataItemsContainer = ({
  tagName,
}: TagsAggdataItemsContainerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedFilterTags, setSelectedFilterTags] = useState<AggDataTagObj[]>(
    []
  );

  React.useEffect(() => {
    // setSelectedFilterTags([tagName]);
  }, [tagName]);

  const [displaySortType, setDisplaySortType] = React.useState<SORT_TYPE>(
    getAggdataSortType()
  );
  const [dataTypeFilter, setDataTypeFilter] = React.useState<FILTER_TYPE>(
    getAggdataFilterType()
  );

  const [displayViewType, setDispayViewType] = React.useState<VIEW_TYPE>(
    getAggdataViewType()
  );
  const handleBackPressed = useCallback(() => {
    history.goBack();
  }, [history]);

  let avatarColour = stringToColour(tagName);
  return (
    <>
      <Box>
        <Helmet>
          <title>{"🏷️  " + tagName}</title>
        </Helmet>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            marginTop: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <IconButton
              sx={{
                marginLeft: "64px",
              }}
              onClick={handleBackPressed}
            >
              <ArrowBackIcon
                style={{ width: "32px", height: "32px" }}
              ></ArrowBackIcon>
            </IconButton>
            <Typography
              variant="h5"
              onMouseEnter={() => {
                // setShowCollectionBadgeTooltip(true);
              }}
              onMouseLeave={() => {
                // setShowCollectionBadgeTooltip(false);
              }}
              onClick={(e) => {
                e.preventDefault();
                /*  if (aggdata.collection?.isArchived) {
              history.push(
                "/board/archives/collection/" + aggdata.collection.id
              );
            } else {
              history.push("/board/collection/" + aggdata.collection.id);
            } */
                e.stopPropagation();
              }}
              sx={{
                borderRadius: "4px",
                backgroundColor: avatarColour.backgroundColor,
                color: avatarColour.textColor,
                fontSize: "1.6rem",
                padding: "4px",
                cursor: "pointer",
                display: "inline-block",
                width: "auto",
                textAlign: "center",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                marginLeft: "8px",
                paddingBlock: "8px",
                paddingInline: "8px",
              }}
            >
              {"🏷️  " + tagName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              marginRight: "5%",
            }}
          >
            <AggdataDisplayControl
              onSortTypeChanged={(sortType: SORT_TYPE) => {
                setDisplaySortType(sortType);
              }}
              onViewTypeChanged={(viewType: VIEW_TYPE) => {
                setDispayViewType(viewType);
              }}
              onFilterTypeChanged={(filterType: FILTER_TYPE) => {
                setDataTypeFilter(filterType);
              }}
              displaySortByPin
              selectedTags={selectedFilterTags}
              onSelectedTagsChanged={(tags: AggDataTagObj[]) => {
                setSelectedFilterTags(tags);
              }}
              excludedTag={tagName}
            />
          </Box>
        </Box>

        {selectedFilterTags.length > 0 && (
          <Box sx={{
            marginLeft: "42px",
            marginTop: "16px",
            marginBottom: "16px",
          }}>
            <SimpleTagsFilter
              allSelectedText={t("filter_all_collections_selected_text")}
              inputHint={t("filter_by_tags_title")}
              onSelectionChanged={(values) => {
                // console.log("YIT simple filter tag selection changes " + JSON.stringify(values))
                setSelectedFilterTags(
                  values.map((value) => {
                    return { name: value };
                  })
                );
              }}
              selectedValues={selectedFilterTags.map(
                (selectedTag) => selectedTag.name
              )}
              excludedTag={tagName}
            />
          </Box>
        )}
        <AggDataItemsContainer
          dataTypeFilter={dataTypeFilter}
          displayViewType={displayViewType}
          displaySortType={displaySortType}
          requestType={RequestTypes.TAG}
          filterTags={selectedFilterTags}
          mainTag={{ name: tagName }}
        />
      </Box>
    </>
  );
};

export default React.memo(TagsAggdataItemsContainer);

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from "@apollo/client";

export const moveCollectionToCollectionGql = gql`
  mutation($collectionId: ID, $destinationCollectionId: ID) {
    moveCollectionToCollection(
      collectionId: $collectionId
      destinationCollectionId: $destinationCollectionId
    ) {
      result
    }
  }
`;

export const deleteUiElementGql = gql`
  mutation($elementId: String) {
    deleteUiElement(elementId: $elementId) {
      publicProfile {
        id
        description
        enabled
        socialMediaUrls
        presentationVideoUrl
        background
        uiItems
      }
    }
  }
`;

export const updateUiElementGql = gql`
  mutation($element: String) {
    updateUiElement(element: $element) {
      publicProfile {
        id
        description
        enabled
        socialMediaUrls
        presentationVideoUrl
        background
        uiItems
      }
    }
  }
`;

export const addUiElementGql = gql`
  mutation($element: String) {
    addUiElement(element: $element) {
      publicProfile {
        id
        description
        enabled
        socialMediaUrls
        presentationVideoUrl
        background
        uiItems
      }
    }
  }
`;

export const updateUserAvatarGql = gql`
  mutation($avatarFile: Upload!) {
    updateUserAvatar(avatarFile: $avatarFile) {
      result
    }
  }
`;

export const updatePublicProfileGql = gql`
  mutation($input: PublicProfileInput!) {
    updatePublicUserProfile(input: $input) {
      publicProfile {
        id
        description
        enabled
        socialMediaUrls
        presentationVideoUrl
        background
        uiItems
      }
    }
  }
`;

export const removeTagFromAggdataGql = gql`
  mutation($dataId: ID, $tagName: String) {
    removeTagFromData(dataId: $dataId, tagName: $tagName) {
      result
    }
  }
`;

export const addTagToAggdataGql = gql`
  mutation($dataId: ID, $tagName: String) {
    addTagToData(dataId: $dataId, tagName: $tagName) {
      result
    }
  }
`;

export const savePublicAggdataGql = gql`
  mutation($collectionId: String, $dataId: String) {
    savePublicAggdata(collectionId: $collectionId, dataId: $dataId) {
      result
    }
  }
`;

export const updateUserSettingsGql = gql`
  mutation($input: UserSettingsInput!) {
    updateUserSettings(input: $input) {
      userSettings {
        aiClassificationEnabled
      }
    }
  }
`;

export const uploadNoteImagesGql = gql`
  mutation($aggdataId: String, $imagesList: [NoteImageInputType]) {
    uploadNoteImages(aggdataId: $aggdataId, imagesList: $imagesList) {
      result
      aggData {
        id
        isArchived
        isResolved
        annotation
        url
        dataType
        dataContent
        sortOrderScore
        allItemsPinScore
        commentsCount
        listItemsPinScore
        tags {
          name
        }
        created
        image
        reminders
        collection {
          id
          name
          isArchived
          itemsCount
          pinScore
          lastActive
          isPublic
          lastUsedScore
          previewUrls
          created
          owner {
            id
            username
            email
          }
          sharedUsers {
            id
            right
            userRegistered
            created
            user {
              id
              username
              email
              firstName
              lastName
            }
          }
        }
        note {
          id
          noteColor
          noteTitle
          noteContent
          lastEdited
          noteTasks
          noteimageSet {
            id
          }
        }
        file {
          pagesCount
          fileName
          length
        }
        owner {
          id
          username
          email
          userInitials
        }
        sharedUsers {
          id
          right
          user {
            id
            username
            email
            userInitials
          }
        }
      }
    }
  }
`;

export const deleteNoteImagesGql = gql`
  mutation($ids: [String], $aggdataId: String) {
    deleteNoteImages(ids: $ids, aggdataId: $aggdataId) {
      result
    }
  }
`;
export const updateUsernameGql = gql`
  mutation($newUsername: String) {
    updateUsername(newUsername: $newUsername) {
      result
    }
  }
`;

export const generatePublicAccessGql = gql`
  mutation($collectionId: String) {
    generatePublicAccess(collectionId: $collectionId) {
      accessToken
    }
  }
`;

export const suspendPublicAccessGql = gql`
  mutation($collectionId: String) {
    suspendPublicAccess(collectionId: $collectionId) {
      result
    }
  }
`;

export const canSavePublicCollectionGql = gql`
  mutation($collectionId: String) {
    canSavePublicCollection(collectionId: $collectionId) {
      collectionNameExists
      result
      isOwner
    }
  }
`;

export const savePublicCollectionGql = gql`
  mutation($publicCollectionToken: String, $newCollectionName: String) {
    savePublicCollection(
      publicCollectionToken: $publicCollectionToken
      newCollectionName: $newCollectionName
    ) {
      result
    }
  }
`;

export const deleteCommentGql = gql`
  mutation($commentId: String, $page: Int, $pageSize: Int) {
    deleteCommentAndReturnPage(
      commentId: $commentId
      page: $page
      pageSize: $pageSize
    ) {
      result
      newPage {
        id
        content
        user {
          id
          username
          firstName
          lastName
          email
          userInitials
        }
        created
      }
    }
  }
`;

export const createCommentGql = gql`
  mutation($content: String!, $dataId: String!) {
    createComment(content: $content, dataId: $dataId) {
      comment {
        id
        content
        user {
          id
          username
          firstName
          lastName
          userInitials
          email
        }
        created
      }
      result
    }
  }
`;

export const queryCollectionInviteInformationGql = gql`
  mutation($collectionUuid: String, $accessToken: String) {
    querySharedCollectionInfo(
      collectionUuid: $collectionUuid
      accessToken: $accessToken
    ) {
      collectionName
      previewUrls
      inviterFirstName
      inviterEmail
      alreadyAccepted
    }
  }
`;

export const acceptCollectionInviteGql = gql`
  mutation($collectionUuid: String, $accessToken: String) {
    acceptCollectionInvite(
      collectionUuid: $collectionUuid
      accessToken: $accessToken
    ) {
      result
    }
  }
`;

export const generateCollectionShareLinkGql = gql`
  mutation($collectionId: String, $right: Int) {
    generateCollectionShareLink(collectionId: $collectionId, right: $right) {
      url
    }
  }
`;

export const addNewDataGql = gql`
  mutation($input: AggDataInput) {
    createAggdata(input: $input) {
      aggData {
        id
        isArchived
        isResolved
        annotation
        url
        dataType
        dataContent
        sortOrderScore
        allItemsPinScore
        commentsCount
        listItemsPinScore

        tags {
          name
        }
        created
        image
        reminders
        collection {
          id
          name
          isArchived
          itemsCount
          pinScore
          lastActive
          isPublic
          lastUsedScore
          previewUrls
          created
          owner {
            id
            username
            email
          }
          sharedUsers {
            id
            right
            userRegistered
            created
            user {
              id
              username
              email
              firstName
              lastName
            }
          }
        }
        note {
          id
          noteColor
          noteTitle
          noteContent
          lastEdited
          noteTasks
          noteimageSet {
            id
          }
        }
        file {
          pagesCount
          fileName
          length
        }
        owner {
          id
          username
          email
          userInitials
        }
        sharedUsers {
          id
          right
          user {
            id
            username
            email
            userInitials
          }
        }
      }
      error
    }
  }
`;

export const addNewCollectionGql = gql`
  mutation($input: CollectionInput) {
    createCollection(input: $input) {
      collection {
        id
        name
        lastActive
        isPublic
        isArchived
        itemsCount
        lastUsedScore
        pinScore
        previewUrls
        isNested
        created
        owner {
          id
          username
          email
        }
        sharedUsers {
          id
          right
          userRegistered
          user {
            id
            username
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const updateCollectionGql = gql`
  mutation($input: CollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
        name
        lastActive
        isPublic
        isArchived
        itemsCount
        pinScore
        lastUsedScore
        previewUrls
        created
      }
    }
  }
`;

export const shareCollectionWithUsersGql = gql`
  mutation(
    $collectionId: String
    $sharedUsers: [UserAccessRightInput]
    $removedUsers: [String]
  ) {
    shareCollectionWithUsers(
      collectionId: $collectionId
      sharedUsers: $sharedUsers
      removedUsers: $removedUsers
    ) {
      result
    }
  }
`;

export const suspendCollectionShareLinkGql = gql`
  mutation($collectionId: String) {
    suspendCollectionShareLink(collectionId: $collectionId) {
      result
    }
  }
`;

export const queryCollectionShareLinkGql = gql`
  mutation($collectionId: String) {
    queryCollectionShareLink(collectionId: $collectionId) {
      url
      exists
      right
    }
  }
`;

export const moveItemsFromCollectionToCollectionGql = gql`
  mutation($sourceCollectionId: String!, $destinationCollectionId: String!) {
    moveItemsFromCollectionToCollection(
      sourceCollectionId: $sourceCollectionId
      destinationCollectionId: $destinationCollectionId
    ) {
      result
    }
  }
`;

export const unshareMultipleAggdataFromSharedCollectionGql = gql`
  mutation($inputList: [UnshareMultipleAggDataInputType]) {
    unshareMultipleAggdata(inputList: $inputList) {
      aggdatas {
        id
        isArchived
        isResolved
        annotation
        url
        dataType
        dataContent
        sortOrderScore
        allItemsPinScore
        commentsCount
        listItemsPinScore

        tags {
          name
        }
        created
        image
        reminders
        collection {
          id
          name
          lastActive
          isPublic
          itemsCount
          previewUrls
        }
        note {
          id
          noteColor
          noteTitle
          noteContent
          lastEdited
          noteTasks
          noteimageSet {
            id
          }
        }
        file {
          pagesCount
          fileName
          length
        }
        owner {
          id
          username
          email
          userInitials
        }
        sharedUsers {
          id
          user {
            id
            username
            email
            userInitials
          }
        }
      }
    }
  }
`;

export const updatePinMutliAggDataGql = gql`
  mutation(
    $input: [String]
    $sortOrderScore: Int
    $allItemsPinScore: Int
    $listItemsPinScore: Int
  ) {
    updatePinMultiAggdata(
      input: $input
      allItemsPinSocre: $allItemsPinScore
      listItemsPinScore: $listItemsPinScore
      sortOrderScore: $sortOrderScore
    ) {
      aggdatas {
        id
        allItemsPinScore
        listItemsPinScore
        sortOrderScore
      }
    }
  }
`;

export const attachMultipleAggDataToCollectionGql = gql`
  mutation($input: [String], $collectionId: String, $sortOrderScore: Int) {
    attachMultipleAggdataToCollection(
      input: $input
      collectionId: $collectionId
      sortOrderScore: $sortOrderScore
    ) {
      error
      aggdatas {
        id
        sortOrderScore
        isResolved
        collection {
          id
          name
          lastActive
          isPublic
          isArchived
          itemsCount
          previewUrls
          created
        }
      }
    }
  }
`;

export const archiveMultipleAggDataGql = gql`
  mutation($input: [String], $archive: Boolean, $sortOrderScore: Int) {
    archiveMultipleAggdata(
      input: $input
      archive: $archive
      sortOrderScore: $sortOrderScore
    ) {
      aggdatas {
        id
        isArchived
        isResolved
        collection {
          id
        }
      }
    }
  }
`;

export const addNewDataWithImageGql = gql`
  mutation($input: AggDataInput) {
    createAggdata(input: $input) {
      aggData {
        id
        isArchived
        isResolved
        annotation
        url
        dataType
        dataContent
        sortOrderScore
        allItemsPinScore
        commentsCount
        listItemsPinScore

        tags {
          name
        }
        created
        image
        reminders
        collection {
          id
          name
          lastActive
          isPublic
          itemsCount
          previewUrls
          sharedUsers {
            id
            user {
              id
              username
              email
            }
          }
        }
        note {
          id
          noteColor
          noteTitle
          noteContent
          lastEdited
          noteTasks
          noteimageSet {
            id
          }
        }
        file {
          pagesCount
          fileName
          length
        }
        owner {
          id
          username
          email
          userInitials
        }
      }
      error
    }
  }
`;

export const addMultipleNewDataWithImageGql = gql`
  mutation($input: [AggDataInput]) {
    createMultipleAggdata(input: $input) {
      aggDatas {
        id
        isArchived
        isResolved
        annotation
        url
        dataType
        dataContent
        sortOrderScore
        allItemsPinScore
        listItemsPinScore

        tags {
          name
        }
        created
        image
        reminders
        collection {
          id
          name
          lastActive
          isPublic
          isArchived
          itemsCount
          pinScore
          lastUsedScore
          previewUrls
          created
          owner {
            id
            username
            email
          }
          sharedUsers {
            id
            right
            userRegistered
            created
            user {
              id
              username
              email
              firstName
              lastName
            }
          }
        }
        note {
          id
          noteColor
          noteTitle
          noteContent
          lastEdited
          noteTasks
          noteimageSet {
            id
          }
        }
        file {
          pagesCount
          fileName
          length
        }
        owner {
          id
          username
          firstName
          lastName
          email
        }
        sharedUsers {
          id
          user {
            id
            username
            email
            userInitials
          }
        }
      }
      error
    }
  }
`;

export const uploadFileGql = gql`
  mutation($file: Upload!) {
    uploadFileTest(file: $file) {
      success
    }
  }
`;

export const updateAggDataGql = gql`
  mutation($input: AggDataInput!) {
    updateAggdata(input: $input) {
      aggData {
        id
        isArchived
        isResolved
        annotation
        url
        dataType
        dataContent
        sortOrderScore
        allItemsPinScore
        commentsCount
        listItemsPinScore

        tags {
          name
        }
        created
        image
        reminders
        collection {
          id
          name
          lastActive
          isPublic
          isArchived
          itemsCount
          pinScore
          lastUsedScore
          previewUrls
          created
          owner {
            id
            username
            email
          }
          sharedUsers {
            id
            right
            userRegistered
            created
            user {
              id
              username
              email
              firstName
              lastName
            }
          }
        }
        note {
          id
          noteColor
          noteTitle
          noteContent
          lastEdited
          noteTasks
          noteimageSet {
            id
          }
        }
        file {
          pagesCount
          fileName
          length
        }
        owner {
          id
          username
          email
          userInitials
        }
        sharedUsers {
          id
          user {
            id
            username
            email
            userInitials
          }
        }
      }
    }
  }
`;

export const updateMultipleAggDataGql = gql`
  mutation($input: [AggDataInput]) {
    updateMultipleAggdata(input: $input) {
      aggdatas {
        id
        reminders
        updatedAt
      }
    }
  }
`;

export const deleteAggDataGql = gql`
  mutation($id: ID) {
    deleteAggdata(id: $id) {
      result
    }
  }
`;

export const deleteMultipleAggDataGql = gql`
  mutation($input: [ID]) {
    deleteMultipleAggdata(input: $input) {
      result
    }
  }
`;

export const deleteCollectionGql = gql`
  mutation($id: ID) {
    deleteCollection(id: $id) {
      result
    }
  }
`;

import axios, { AxiosResponse } from "axios";
import { SERVER_URL, DEBUG } from "configuration";
import { SubscriptionInformation } from "models/user"

const GET_CONNECTED_DEVICES_API_SUBPATH = "/devices/connected-devices";
const DISCONNECT_DEVICE_API_SUBPATH = "/devices/disconnect";


export interface ConnectedDevice {
    device_name: string;
    device_id: string;
    last_login: string;
}


export interface DisconnectDeviceResult {
    result: string, //when successful will be 0, otherwise > 0
}


export const getConnectedDevices = async (): Promise<ConnectedDevice[]> => {
    try {
        let res = await axios.get(SERVER_URL + GET_CONNECTED_DEVICES_API_SUBPATH,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("getConnectedDevices result " + JSON.stringify(res));
        }
        return JSON.parse(res.data);

    } catch (e) {
        throw (e)
    }
}

export const disconnectDevice = async (deviceId: string): Promise<DisconnectDeviceResult> => {
    try {
        let res = await axios.post(SERVER_URL + DISCONNECT_DEVICE_API_SUBPATH,
            {
                device_id: deviceId
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("getConnectedDevices result " + JSON.stringify(res));
        }
        return { result: res.data.result }

    } catch (e) {
        throw (e)
    }
}
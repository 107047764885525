import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ArchiveIllustration from "illustrations/undraw_archive_empty_box.svg";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

const ArchivesEmpty = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <img
        style={{ marginTop: "120px", height: "300px", width: "600px",  cursor:"initial" }}
        src={ArchiveIllustration}
      />
      <Typography
        style={{
          textAlign: "center",
          marginLeft: "12px",
          color: "black",
          marginTop: "12px",
        }}
        variant="h4"
      >
        {t("archives_empty_msg")}
      </Typography>
    </MainContainer>
  );
};

export default React.memo(ArchivesEmpty);

import { Box, Fade, ListItemButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  retrieveTopLevelCollectionsGql,
  retrieveTopLevelCollectionsOwnedByMeGql,
} from "graphql/queries";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UserObj } from "models/user";
import { RootState } from "store/reducers";
import { colorMemento } from "shared/colors";
import { useHistory } from "react-router-dom";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { retrieveChildCollectionsForParentGql } from "graphql/queries";
import { CollectionObj } from "models/collection";
import ImagePreview from "components/CollectionItems/ImagePreview";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { moveCollectionToCollectionGql } from "graphql/mutations";
import { showSnackbar } from "store/actions/view";
import {
  deleteCollectionFromTopLevelCollectionsCache,
  updateTopLevelCollectionsCache,
} from "graphql/helpers";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";

interface HierarchyCollectionItemProps {
  collection: CollectionObj;
  selectedCollection: CollectionObj;
  collectionToIgnore?: CollectionObj | null; //There is always a collection we don't want to display here and its the collection that we want to move. This collection canno't be moved inside itslef or inside one of its children, so we hide it from here
  onCollectionClicked: (collection: CollectionObj) => void;
}

const HierarchyCollectionItem = ({
  collection,
  onCollectionClicked,
  selectedCollection,
  collectionToIgnore = null,
}: HierarchyCollectionItemProps) => {
  const history = useHistory();

  const [openNesting, setOpenNesting] = React.useState(false);
  const [
    retrieveChildCollections,
    retrieveChildCollectionsResult,
  ] = useLazyQuery(retrieveChildCollectionsForParentGql, {
    variables: { parentId: collection.id },
  });

  const handleToggleNesting = React.useCallback(() => {
    if (!retrieveChildCollectionsResult.data) {
      retrieveChildCollections();
    }
    setOpenNesting(!openNesting);
  }, [openNesting, retrieveChildCollectionsResult]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ".MuiButtonBase-root": {
          padding: "4px",
        },
      }}
    >
      <ListItemButton
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        selected={selectedCollection == collection}
        onClick={(e) => {
          // e.stopPropagation();
          onCollectionClicked(collection);
        }}
      >
        {selectedCollection == collection && (
          <CheckIcon
            sx={{
              color: colorMemento,
              position: "absolute",
              right: "8px",
            }}
          />
        )}
        {/*  <IconButton
             
            > */}
        {openNesting ? (
          <KeyboardArrowDownIcon
            style={{
              width: "16px",
              height: "16px",
              visibility: collection.isNested ? "visible" : "hidden",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleToggleNesting();
            }}
          />
        ) : (
          <KeyboardArrowRightIcon
            style={{
              width: "16px",
              height: "16px",
              visibility: collection.isNested ? "visible" : "hidden",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleToggleNesting();
            }}
          />
        )}
        {/*         </IconButton>
         */}{" "}
        {/*  <ListItemIcon
            sx={{
              borderRadius: "4px",
            }}
          > */}
        {collection.previewUrls && collection.previewUrls.length > 0 && (
          <ImagePreview
            previewUrls={
              collection.previewUrls && collection.previewUrls.length > 0
                ? [collection.previewUrls[0]]
                : []
            }
            height="24px"
            width="24px"
            hideBorder
          />
        )}
        {/*       </ListItemIcon>
         */}{" "}
        <Typography
          sx={{
            marginLeft: "8px",
            fontSize: "1.4rem",
            fontWeight: 410,
          }}
        >
          {collection.name}
        </Typography>
        {/*       <CollectionItem collection={collection} onCollectionClicked={() => {}} />
         */}
        <Typography
          sx={{
            fontSize: "0.8rem",
            color: "grey",
            marginLeft: "4px",
          }}
        >
          ({collection.itemsCount ? collection.itemsCount : 0})
        </Typography>
      </ListItemButton>
      {openNesting && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "16px",
          }}
        >
          {retrieveChildCollectionsResult.data &&
            !retrieveChildCollectionsResult.loading &&
            !retrieveChildCollectionsResult.error &&
            retrieveChildCollectionsResult.data.childCollections.map(
              (collectionFromMap: CollectionObj) => {
                if (
                  collectionToIgnore &&
                  collectionFromMap.id == collectionToIgnore.id
                ) {
                  return <></>;
                }
                return (
                  <HierarchyCollectionItem
                    selectedCollection={selectedCollection}
                    collection={collectionFromMap}
                    collectionToIgnore={collectionToIgnore}
                    onCollectionClicked={(coll: CollectionObj) => {
                      onCollectionClicked(coll);
                    }}
                  />
                );
              }
            )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(HierarchyCollectionItem);


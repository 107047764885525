import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { APP_PLAYSTORE_LINK } from "configuration";
import SecureServerContainer from "illustrations/undraw_secure_server.svg";
import { Button } from "@mui/material";
import { colorMemento } from "shared/colors";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useHistory } from "react-router-dom";
import { PRIVACY_POLICY } from "navigation/Constants";
import { TITLE_TEXT_SIZE, SUBTITLE_TEXT_SIZE } from "pages/Landing/Content";

const MainContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 8%;
  padding-right: 8%;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const IllustraitonContainer = styled.img`
  width: 35%;
  @media (max-width: 780px) {
    width: 80vw;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 10%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  @media (max-width: 780px) {
    width: 100%;
    margin-top: 10%;
  }
`;

const SecurityContainer = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <MainContainer>
      <IllustraitonContainer
        src={SecureServerContainer}
        alt="Securely collect notes, links, screenshots and more"
      />

      <TextContainer>
        <Typography variant="h4" sx={{ fontSize: TITLE_TEXT_SIZE }}>
          {t("landing_page_privacy_title")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: SUBTITLE_TEXT_SIZE,
            fontFamily: "Trebuchet MS, sans-serif",
            fontWeight: 300,
          }}
        >
          {t("landing_page_privacy_description")}
        </Typography>

        <Typography
          variant="h5"
          sx={{
            fontSize: "1.6rem",
            fontFamily: "Trebuchet MS, sans-serif",
            fontWeight: 300,
            color: "grey",
            alignItems: "center",
            display: "flex",
            flexDirection: "center",
            cursor: "pointer",
            marginTop: "8px",
            "&:hover": {
              opacity: 0.6,
            },
          }}
          onClick={() => {
            history.push(PRIVACY_POLICY);
          }}
        >
          {t("learn_more_tilte")}{" "}
          <ArrowRightAltIcon
            sx={{ marginLeft: "8px", width: "3rem", height: "3rem" }}
          />
        </Typography>
      </TextContainer>
    </MainContainer>
  );
};

export default SecurityContainer;

export const colorMemento = "#FDC84B"
export const colorHyperlink = "#0000EE"
export const colorMementoBeige = "#faf6e9"
export const colorWhiteNuanced = "#fffdfa"
export const colorGreyMemento = "rgba(0, 0, 0, 0.54)"
export const colorMementoHovered = "#fcedc7"

export const landingBackgroundColor= "#ffffff"//"rgb(255, 241, 206)"


function hexToRgb(hex: string) : number[] | null{
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
       parseInt(result[3], 16)]
     : null;
}
  

//Will return a color hex code from a string.
//for the same input string the output will always be the same
export const stringToColour = (str:string) => {
    if (!str){
        return null
    }
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    const rgb = hexToRgb(colour)

    const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                      (parseInt(rgb[1]) * 587) +
                      (parseInt(rgb[2]) * 114)) / 1000);
  const textColour = (brightness > 125) ? 'black' : 'white';
  const backgroundColour = 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
    return {textColor: textColour, backgroundColor: backgroundColour};
}


const COLOR_BLACK_WHITE = 0;
const COLOR_RED = 1;
const COLOR_GREEN = 2;
const COLOR_BLUE = 3;
const COLOR_LIME = 4;
const COLOR_ORANGE = 5;
const COLOR_TEAL = 6;
const COLOR_PURPLE = 7;
const COLOR_YELLOW = 8;

interface NoteColor {
    index: number;
    color: string;
}

export const noteLightColorsPalette: NoteColor[] = [
    { index: COLOR_BLACK_WHITE, color: '#ffffff' },
    { index: COLOR_RED, color: '#e57373' },
    { index: COLOR_GREEN, color: '#81c784' },
    { index: COLOR_BLUE, color: '#64b5f6' },
    { index: COLOR_LIME, color: '#dce775' },
    { index: COLOR_ORANGE, color: '#ffb74d' },
    { index: COLOR_TEAL, color: '#4db6ac' },
    { index: COLOR_PURPLE, color: '#ba68c8' },
    { index: COLOR_YELLOW, color: '#fff176' }
];



export const getNoteColorFromColorIndex = (colorIndex: number | null | undefined, darkMode: boolean) => {
    if (colorIndex == null || colorIndex == undefined) {
        return darkMode ? "#121212" : "#ffffff"
    }
    switch (colorIndex) {
        case COLOR_BLACK_WHITE:
            return darkMode ? "#121212" : "#ffffff"
        case COLOR_RED:
            return darkMode ? "#651717" : "#e57373"
        case COLOR_GREEN:
            return darkMode ? "#17381A" : "#81c784"
        case COLOR_BLUE:
            return darkMode ? "#122C59" : "#64b5f6"
        case COLOR_LIME:
            return darkMode ? "#4A4515" : "#dce775"
        case COLOR_ORANGE:
            return darkMode ? "#7C3209" : "#ffb74d"
        case COLOR_TEAL:
            return darkMode ? "#093029" : "#4db6ac"
        case COLOR_PURPLE:
            return darkMode ? "#2E134F" : "#ba68c8"
        case COLOR_YELLOW:
            return darkMode ? "#898244" : "#fff176"
        default:
            return darkMode ? "#121212" : "#ffffff"
    }
}
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DevicesIcon from "@mui/icons-material/Devices";
import Typography from "@mui/material/Typography";
import {
  getConnectedDevices,
  disconnectDevice,
  ConnectedDevice,
} from "api/devicesApi";
import { getClientUniqueId } from "shared/dataUtils";
import { useTranslation } from "react-i18next";
import { getDateAndTimeFromIso8601 } from "shared/dataUtils";
import { colorMemento } from "shared/colors";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    marginTop: "36px",
    paddingLeft: "36px",
    flexDirection: "column",
    width: "50%",
    [theme.breakpoints.down('mobile')]: {
      width: "100%",
      paddingLeft: "8px",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      fontSize: "1.4rem",
    },
  },
  tableHeader: {
    "& .MuiTableCell-root": {
      fontSize: "1.4rem",
    },
  },
}));

enum ConnectedDeviceState {
  CONNECTED = 1,
  DISCONNECTED = 2,
  DICONNECTION_ONGOING = 3,
  FAILED = 4,
  CURRENT = 0,
}

interface ConnectedDeviceWrapper {
  deviceId: string;
  deviceName: string;
  lastConnectedAt: string;
  state: ConnectedDeviceState;
}

export default function Devices() {
  const classes = useStyles();

  const { t } = useTranslation();
  const [connectedDevices, setConnectedDevices] = React.useState<
    ConnectedDeviceWrapper[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    getConnectedDevices()
      .then((data: ConnectedDevice[]) => {
        const connectedDevices = data
          .sort((device1, device2) => {
            if (device1.device_id != getClientUniqueId()) {
              return 1;
            } else return -1;
          })
          .map((device) => {
            console.log("connected device " + JSON.stringify(device));
            return {
              deviceName: device.device_name,
              deviceId: device.device_id,
              lastConnectedAt: getDateAndTimeFromIso8601(device.last_login),
              state:
                getClientUniqueId() == device.device_id
                  ? ConnectedDeviceState.CURRENT
                  : ConnectedDeviceState.CONNECTED,
            };
          });
        setIsLoading(false);
        setError(false);
        setConnectedDevices(connectedDevices);
      
      })
      .catch((e) => {
        setIsLoading(false);
        setError(true);
      });
  }, [setConnectedDevices]);

  const handleLogout = React.useCallback(
    (device: ConnectedDeviceWrapper) => {
      const updateDevicesArray = (device: ConnectedDeviceWrapper) => {
        const index = connectedDevices.indexOf(device);
        const newArray = [...connectedDevices];
        newArray[index] = { ...device };
        setConnectedDevices(newArray);
      };

      device.state = ConnectedDeviceState.DICONNECTION_ONGOING;
      disconnectDevice(device.deviceId)
        .then((data) => {
          if (data.result == "0") {
            console.log("device disconnected");
            device.state = ConnectedDeviceState.DISCONNECTED;
          } else {
            device.state = ConnectedDeviceState.FAILED;
          }
          updateDevicesArray(device);
        })
        .catch((e) => {
          device.state = ConnectedDeviceState.FAILED;
          updateDevicesArray(device);
        });
      updateDevicesArray(device);
    },
    [connectedDevices]
  );

  const getConnectedDeviceDisplayableAction = React.useCallback(
    (device: ConnectedDeviceWrapper) => {
      const deviceState = device.state;
      if (deviceState == ConnectedDeviceState.CONNECTED) {
        return (
          <Link
            color="#054cff"
            style={{
              cursor: "pointer",
              fontWeight: "bold",
              color: "#123456",
              marginTop: "48px",
            }}
            onClick={() => handleLogout(device)}
          >
            {t("logout_action_title")}
          </Link>
        );
      } else if (deviceState == ConnectedDeviceState.FAILED) {
        return (
          <Link
            color="#054cff"
            style={{
              cursor: "pointer",
              fontWeight: "bold",
              color: "red",
              marginTop: "48px",
            }}
            onClick={() => handleLogout(device)}
          >
            {t("device_disconnect_failed_retry_title")}
          </Link>
        );
      } else if (deviceState == ConnectedDeviceState.DISCONNECTED) {
        return <i>{t("disconnected_title")}</i>;
      } else if (deviceState == ConnectedDeviceState.DICONNECTION_ONGOING) {
        return <CircularProgress size="2.5rem" />;
      } else if (deviceState == ConnectedDeviceState.CURRENT) {
        return (
          <Typography
            variant="h5"
            style={{ borderRadius: "4px", backgroundColor: colorMemento }}
          >
            {t("current_title")}
          </Typography>
        );
      }
    },
    [handleLogout]
  );

  if (isLoading) {
    return (
      <Box className={classes.root}>
        <Skeleton height="36px" width="60%" />
        <Skeleton height="36px" width="20%" />
        <Skeleton height="80%" width="80%" />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography style={{ marginBottom: "12px" }} variant="h3">
        {t("devices_settings_title")}
      </Typography>
      <Typography style={{ marginBottom: "12px" }} variant="h5">
        {t("devices_settings_list_devices_title")}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell align="center">
                {t("devices_table_device_title")}
              </TableCell>
              <TableCell align="center">
                {t("devices_table_last_connected_title")}
              </TableCell>
              <TableCell align="center">
                {t("devices_table_action_state_title")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {error && (
              <TableRow
                key="1"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center"></TableCell>
                <TableCell style={{ width: "100%" }} align="center">
                  ❌ Failed to fetch list of devices
                </TableCell>
              </TableRow>
            )}
            {!error &&
              connectedDevices.map(
                (connectedDevice: ConnectedDeviceWrapper) => (
                  <TableRow
                    key={connectedDevice.deviceId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      {connectedDevice.deviceName}
                    </TableCell>
                    <TableCell align="center">
                      {connectedDevice.lastConnectedAt}
                    </TableCell>
                    <TableCell align="center">
                      {getConnectedDeviceDisplayableAction(connectedDevice)}
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

import React, { useState, useCallback } from "react";
import AggData from "containers/AggData/AggData";
import { useSelector, useDispatch } from "react-redux";
import AggDataItemsLayout from "components/AggDataItems/AggDataItemsLayout/AggDataItemsLayout";
import { AggDataObj, AggDataTagObj, aggDataHasReminders } from "models/aggdata";
import styled from "styled-components";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { updateAggDataGql } from "graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import {
  retrieveAllDataGql,
  retrieveCollectionGql,
  retrieveAllArchivedDataGql,
  retrievePublicAggdataForCollection,
} from "graphql/queries";
import { CollectionObj } from "models/collection";
import { updateAggDataInCache } from "graphql/helpers";
import {
  showSnackbar,
  setSearchContext,
  displayImageViewer,
  openAttachDataToCollectionDialog,
} from "store/actions/view";
import { useTranslation } from "react-i18next";
import { DataType } from "models/aggdata";
import { RootState } from "store/reducers";
import LoadingItems from "components/layout/Loading/LoadingItems";
import PendingDataEmpty from "containers/IllustrationContainers/PendingDataEmpty";
import SearchResultsEmpty from "containers/IllustrationContainers/SearchResultsEmpty";
import ArchivesEmpty from "containers/IllustrationContainers/ArchivesEmpty";
import AllItemsEmpty from "containers/IllustrationContainers/AllItemsEmpty";
import ErrorLoading from "containers/IllustrationContainers/ErrorLoading";
import PinnedHeader from "components/UI/Headers/PinnedHeader";
import UnpinnedHeader from "components/UI/Headers/UnpinnedHeader";
import BackgroundWorker from "workers/NextReminderWorker.worker";
import {
  FILTER_AGGDATA_FOR_MAIN_CONTAINER_REPLY,
  FILTER_AGGDATA_FOR_MAIN_CONTAINER,
  FILTER_AGGDATA_FOR_TAG,
} from "workers/WorkersMessageRequests";
import { DEBUG } from "configuration";
import LazyLoad, { forceCheck } from "react-lazyload";
import DataTypeFilter from "components/UI/Filters/DataTypeFilter";
import EmptyCollection from "containers/IllustrationContainers/EmptyCollection";
import { setMultiSelectedData } from "store/actions/aggdataActions";
import {
  setAllItemsMaxPinScore,
  setMaxSortOrderScore,
  setMaxSortOrderScoreCurrentCollection,
} from "store/actions/aggdataActions";
import ConfidentialityFilterControl from "components/UI/Controls/ConfidentialityFilterControl";
import { useLazyQuery } from "@apollo/client";
import { retrieveAggdataForCollectionGql } from "graphql/queries";
import {
  FILTER_TYPE,
  SORT_TYPE,
  VIEW_TYPE,
} from "components/UI/Controls/AggdataDisplayControl";
import { Grid, useTheme } from "@mui/material";
import { getAggdataSortType } from "localstorage/localstorage";

export enum RequestTypes {
  PENDING_ITEMS = "pending",
  ALL_ITEMS = "all",
  ARCHIVED_ITEMS = "archives",
  COLLECTIONS = "collections",
  REMINDERS = "reminders",
  COLLECTION = "collection", //This request type is used to display only data in a given collection. Colletion Id as an optional arg.
  ARCHIVED_COLLECTION = "archived_collection", //This request type is used to display only data in a given  archived collection. Colletion Id as an optional arg.
  PUBLIC_COLLECTION = "public_collection",
  TAG = "tag",
}

interface AggItemsContainerProps {
  requestType: string; //
  collectionId?: string;
  isCollectionShared?: boolean;
  dataTypeFilter?: FILTER_TYPE;
  displayViewType?: VIEW_TYPE;
  displaySortType?: SORT_TYPE;
  filterTags?: AggDataTagObj[];
  mainTag?: AggDataTagObj | null;
}

const ControlsContainer = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: end;
  height: 40px;
  width: 100%;
  padding-right: 8%;
`;

const useStyles = makeStyles((theme: Theme) => ({
  itemsTypeStack: {
    position: "sticky",
    top: "10px",
    marginRight: "135px",
    "&.MuiToggleButtonGroup-root": {
      position: "sticky",
      top: "10px",
    },
  },
  tab: {
    "& .MuiButtonBase-root": {
      fontSize: "1.4rem",
    },
  },
  toggleTooltip: {
    fontSize: "1.6rem",
  },
}));

interface ImageViewerParams {
  show: boolean;
  currentImg: string;
  sources: string[];
}

const DataEmptyContainer = styled.div`
  display: flex;
  flex: 4;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: none;
`;

/**
 * Whatever the request type we always retrieve all data (either archived or non archived)
 * and then we do local filtering to display for exmaple for a collection, data with reminders etc
 * @param param0
 * @returns
 */
const AggDataItemsContainer = ({
  requestType,
  collectionId = null,
  isCollectionShared = false,
  dataTypeFilter = FILTER_TYPE.FILTER_NONE,
  displayViewType = VIEW_TYPE.GRID,
  displaySortType = SORT_TYPE.PINNED_FIRST,
  filterTags = [],
  mainTag = null,
}: AggItemsContainerProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const searchInput: string = useSelector(
    (state: RootState) => state.view.searchInput
  );
  const [retrieveAllAggdata, retrieveAllAggdataResult] = useLazyQuery(
    requestType == RequestTypes.ARCHIVED_ITEMS ||
      requestType == RequestTypes.ARCHIVED_COLLECTION
      ? retrieveAllArchivedDataGql
      : retrieveAllDataGql
  );

  const [
    loadPublicAggdataForCollection,
    loadPublicAggdataForCollectionResult,
  ] = useLazyQuery(retrievePublicAggdataForCollection, {
    variables: {
      collectionId: collectionId,
    },
    context: {
      clientName: "public",
    },
  });

  const [
    loadAggdataForSharedCollection,
    loadAggdataForSharedCollectionResult,
  ] = useLazyQuery(retrieveAggdataForCollectionGql, {
    variables: { collectionId: collectionId },
  });
  const [dataToDisplay, setDataToDisplay] = React.useState<AggDataObj[]>([]);
  //const [dataToDisplaySorted, setDataToDisplaySorted] = React.useState<AggDataObj[]>([]);
  const dataToDisplaySorted = React.useRef<AggDataObj[]>();
  const [
    dataToDisplayUnfilteredByType,
    setDataToDisplayUnfilteredByType,
  ] = React.useState<AggDataObj[]>([]);
  //const [renderedData, setRenderedData] = React.useState<any[]>([]);
  const aggDataToAddToCollection = React.useRef<AggDataObj | null>(null);
  const [imgViewersParams, setImgViewerParams] = React.useState<
    ImageViewerParams
  >({
    show: false,
    sources: [],
    currentImg: "",
  });
  const backgroundWorkerRef = React.useRef<Worker>();
  const backgroundWorkerRequestToken = React.useRef<Worker>(0);
  const [
    dataIsLoadingInBackground,
    setIsDataLoadingInBackground,
  ] = React.useState(false);
  const theme = useTheme();

  const dataToDisplayPinnedAndSorted = React.useRef<AggDataObj[]>([]); //sorted by the sortOrderScore
  const dataToDisplayUnPinnedAndSorted = React.useRef<AggDataObj[]>([]); //sorted by the sortOrderScore

  React.useEffect(() => {
    if (requestType == RequestTypes.PUBLIC_COLLECTION) {
      loadPublicAggdataForCollection();
    } else {
      retrieveAllAggdata();
    }
  }, []);

  //We compute the max sort order score here as well in addition to in the Home component, as sometimes
  //the user might go directly to collection page, or might just refresh it, and lose the value for the
  //order scores
  React.useEffect(() => {
    if (!retrieveAllAggdataResult.data) {
      return;
    }
    const allData = retrieveAllAggdataResult.data.allAggdata
      ? retrieveAllAggdataResult.data.allAggdata
      : retrieveAllAggdataResult.data.allArchivedAggdata;
    if (allData.length > 0) {
      const maxAllItemsPinScoreAggData = allData.reduce(
        (previous: AggDataObj, current: AggDataObj) =>
          Number(previous.allItemsPinScore) > Number(current.allItemsPinScore)
            ? previous
            : current
      );
      dispatch(
        setAllItemsMaxPinScore(
          Number(maxAllItemsPinScoreAggData.allItemsPinScore)
        )
      );
      const maxSortOrderScoreAggData = allData.reduce(
        (previous: AggDataObj, current: AggDataObj) =>
          Number(previous.sortOrderScore) > Number(current.sortOrderScore)
            ? previous
            : current
      );
      dispatch(
        setMaxSortOrderScore(Number(maxSortOrderScoreAggData.sortOrderScore))
      );
    }
  }, [retrieveAllAggdataResult.data]);

  //Pagniation trial. Let's keep it here for now, resume it later.
  // React.useEffect(() => {
  //   const scrollListener = function () {
  //     // console.log(
  //     //   "Scroll meas scrollY " +
  //     //     (window.scrollY + window.innerHeight) +
  //     //     "  /  " +
  //     //     window.document.scrollingElement?.scrollHeight!!
  //     // );
  //     if (
  //       window.innerHeight + window.scrollY >=
  //       window.document.scrollingElement?.scrollHeight!! - 400
  //     ) {
  //       console.log("Scroll meas bottom detected");
  //       // Show loading spinner and make fetch request to api
  //       if (data && data.allAggdata) {
  //         console.log("Scroll meas data length " + data.allAggdata.length);
  //         fetchMore({
  //           variables: {
  //             offset: data.allAggdata.length,
  //           },
  //         });
  //       }
  //     }
  //   };
  //   window.addEventListener("scroll", scrollListener);
  //   return () => {
  //     window.removeEventListener("scroll", scrollListener);
  //   };
  // }, [data, fetchMore]);

  React.useEffect(() => {
    if (
      loadAggdataForSharedCollectionResult.data &&
      loadAggdataForSharedCollectionResult.data &&
      loadAggdataForSharedCollectionResult.data.aggdataForCollection
    ) {
      backgroundWorkerRef.current.postMessage({
        request: FILTER_AGGDATA_FOR_MAIN_CONTAINER,
        payload: {
          filterToCollectionId: collectionId!!,
          filterToDataType: getDataTypeForAppliedFilter(),
          searchInput: searchInput,
          sortType: displaySortType,
          token: ++backgroundWorkerRequestToken.current,
          filterTags: filterTags,
          mainTag: mainTag,
          aggData:
            loadAggdataForSharedCollectionResult.data.aggdataForCollection,
        },
      });
    }
  }, [
    loadAggdataForSharedCollectionResult.data,
    displaySortType,
    dataTypeFilter,
    filterTags,
    mainTag,
    searchInput,
  ]);

  React.useEffect(() => {
    if (
      loadPublicAggdataForCollectionResult.data &&
      loadPublicAggdataForCollectionResult.data &&
      loadPublicAggdataForCollectionResult.data.aggdataForPublicCollection
    ) {
      backgroundWorkerRef.current.postMessage({
        request: FILTER_AGGDATA_FOR_MAIN_CONTAINER,
        payload: {
          filterToCollectionId: collectionId!!,
          filterToDataType: getDataTypeForAppliedFilter(),
          searchInput: searchInput,
          sortType: displaySortType,
          filterTags: filterTags,
          mainTag: mainTag,
          token: ++backgroundWorkerRequestToken.current,
          aggData:
            loadPublicAggdataForCollectionResult.data
              .aggdataForPublicCollection,
        },
      });
    }
  }, [
    loadPublicAggdataForCollectionResult.data,
    displaySortType,
    dataTypeFilter,
    filterTags,
    mainTag,
    searchInput,
  ]);

  const currentOpenedNoteAggdataId = React.useRef("");

  /*   const [sortType, setSortType] = React.useState<SORT_TYPE>(
    getAggdataSortType()
  ); */
  const [aggdataListContent, setAggdataListContent] = React.useState(<></>);

  const [updateAggData, updateAggDataResult] = useMutation(updateAggDataGql);

  React.useEffect(() => {
    forceCheck();
  }, [aggdataListContent]);

  React.useEffect(() => {
    if (backgroundWorkerRef && backgroundWorkerRef.current) {
      backgroundWorkerRef.current.terminate();
    }
    backgroundWorkerRef.current = new BackgroundWorker();
    backgroundWorkerRef.current.addEventListener("message", (event: any) => {
      if (DEBUG) {
        console.log("AggDataItemsContainer got back message %o", event.data);
      }
      if (event.data.reply == FILTER_AGGDATA_FOR_MAIN_CONTAINER_REPLY) {
        const requestToken = event.data.payload.token;
        if (backgroundWorkerRequestToken.current != requestToken) {
          if (DEBUG) {
            console.log(
              "drop this reply from worker, request token doesn't match"
            );
          }
          return;
        }
        const aggDataUnfilteredByType =
          event.data.payload.aggDataUnFilteredByTypeResult;
        const aggDataFilteredResult = event.data.payload.aggDataFilterResult;
        dataToDisplayPinnedAndSorted.current =
          event.data.payload.dataPinnedAndSorted;
        dataToDisplayUnPinnedAndSorted.current =
          event.data.payload.dataUnPinnedAndSorted;

        const maxSortOrderScoreForCollection =
          event.data.payload.maxSortOrderScoreForCollection;
        if (maxSortOrderScoreForCollection) {
          if (DEBUG)
            console.log(
              "max sort order score for current collection is " +
                JSON.stringify(maxSortOrderScoreForCollection)
            );
          dispatch(
            setMaxSortOrderScoreCurrentCollection(
              maxSortOrderScoreForCollection
            )
          );
        }

        setDataToDisplayUnfilteredByType(aggDataUnfilteredByType);
        setDataToDisplay(aggDataFilteredResult);
        setIsDataLoadingInBackground(false);
      }
    });
    return () => {
      //thread cleanup
      if (backgroundWorkerRef && backgroundWorkerRef.current) {
        backgroundWorkerRef.current.terminate();
      }
    };
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(setMultiSelectedData([]));
    };
  });

  const getDataTypeForAppliedFilter = useCallback(() => {
    switch (dataTypeFilter) {
      case FILTER_TYPE.FILTER_NONE:
        return undefined;
      case FILTER_TYPE.FILTER_LINK:
        return DataType.WEB_URL;
      case FILTER_TYPE.FILTER_NOTES:
        return DataType.NOTE_TYPE;
      case FILTER_TYPE.FILTER_IMAGES:
        return DataType.IMAGE_TYPE;
      case FILTER_TYPE.FILTER_FILES:
        return DataType.FILE_TYPE;
      case FILTER_TYPE.FILTER_AUDIO:
        return DataType.AUDIO_TYPE;
      case FILTER_TYPE.FILTER_VIDEO:
        return DataType.VIDEO_TYPE;
      default:
        console.log("Unsupported filter for data type" + dataTypeFilter);
    }
  }, [dataTypeFilter]);


  React.useEffect(() => {
   console.log("main tag value %o", mainTag )
    if (
      retrieveAllAggdataResult.data &&
      (retrieveAllAggdataResult.data.allAggdata ||
        retrieveAllAggdataResult.data.allArchivedAggdata)
    ) {
      const allData = retrieveAllAggdataResult.data.allAggdata
        ? retrieveAllAggdataResult.data.allAggdata
        : retrieveAllAggdataResult.data.allArchivedAggdata;
      switch (requestType) {
        case RequestTypes.PENDING_ITEMS:
          setIsDataLoadingInBackground(dataToDisplay.length == 0);
          backgroundWorkerRef.current.postMessage({
            request: FILTER_AGGDATA_FOR_MAIN_CONTAINER,
            payload: {
              filterToPending: true,
              filterToDataType: getDataTypeForAppliedFilter(),
              searchInput: searchInput,
              sortType: displaySortType,
              filterTags: filterTags,
              token: ++backgroundWorkerRequestToken.current,
              aggData: allData,
            },
          });

          break;
        case RequestTypes.TAG:
          setIsDataLoadingInBackground(dataToDisplay.length == 0);
          backgroundWorkerRef.current.postMessage({
            request: FILTER_AGGDATA_FOR_MAIN_CONTAINER,
            payload: {
              filterToDataType: getDataTypeForAppliedFilter(),
              searchInput: searchInput,
              sortType: displaySortType,
              filterTags: filterTags,
              mainTag: mainTag,
              token: ++backgroundWorkerRequestToken.current,
              aggData: allData,
            },
          });
          break;
        case RequestTypes.ALL_ITEMS:
          setIsDataLoadingInBackground(dataToDisplay.length == 0);
          backgroundWorkerRef.current.postMessage({
            request: FILTER_AGGDATA_FOR_MAIN_CONTAINER,
            payload: {
              filterToDataType: getDataTypeForAppliedFilter(),
              searchInput: searchInput,
              sortType: displaySortType,
              filterTags: filterTags,
              token: ++backgroundWorkerRequestToken.current,
              aggData: allData,
            },
          });
          break;
        case RequestTypes.COLLECTION:
        case RequestTypes.ARCHIVED_COLLECTION:
          if (isCollectionShared) {
            loadAggdataForSharedCollection();
          } else {
            setIsDataLoadingInBackground(dataToDisplay.length == 0);
            backgroundWorkerRef.current.postMessage({
              request: FILTER_AGGDATA_FOR_MAIN_CONTAINER,
              payload: {
                filterToCollectionId: collectionId!!,
                filterToDataType: getDataTypeForAppliedFilter(),
                searchInput: searchInput,
                sortType: displaySortType,
                filterTags: filterTags,
                token: ++backgroundWorkerRequestToken.current,
                aggData: allData,
              },
            });
          }
          break;
        case RequestTypes.ARCHIVED_ITEMS:
          setIsDataLoadingInBackground(dataToDisplay.length == 0);
          backgroundWorkerRef.current.postMessage({
            request: FILTER_AGGDATA_FOR_MAIN_CONTAINER,
            payload: {
              filterToDataType: getDataTypeForAppliedFilter(),
              searchInput: searchInput,
              sortType: displaySortType,
              filterTags: filterTags,
              token: ++backgroundWorkerRequestToken.current,
              aggData: allData,
            },
          });
          break;
      }
    }
  }, [
    retrieveAllAggdataResult.data,
    requestType,
    dataTypeFilter,
    displaySortType,
    searchInput,
    collectionId,
    mainTag,
    filterTags,
    getDataTypeForAppliedFilter,
  ]);

  React.useEffect(() => {
    setIsDataLoadingInBackground(true);
  }, [searchInput]);

  React.useEffect(() => {
    updateSerachContext();
    return () => {
      dispatch(setSearchContext(""));
    }; //cleanup search context
  }, [requestType, dataTypeFilter]);

  React.useEffect(() => {
    if (!dataToDisplay) {
      return;
    }
    if (displaySortType == SORT_TYPE.PINNED_FIRST) {
      const pinnedAggDataSorted = dataToDisplayPinnedAndSorted.current;
      const unpinnedAggDataSorted = dataToDisplayUnPinnedAndSorted.current;
      const result = [...pinnedAggDataSorted, ...unpinnedAggDataSorted];
      //console.log("resultik  %o" ,  result)
      //setDataToDisplaySorted(result)
      dataToDisplaySorted.current = result;
      if (requestType != RequestTypes.ARCHIVED_ITEMS) {
        var pinnedAggDataSortedContent = pinnedAggDataSorted.map((aggData) =>
          getAggDataListItem(aggData)
        );
        var unpinnedAggDataSortedContent = unpinnedAggDataSorted.map(
          (aggData) => getAggDataListItem(aggData)
        );

        const renderedDataList = (
          <>
            {pinnedAggDataSortedContent && pinnedAggDataSorted.length > 0 && (
              <PinnedHeader itemsCount={pinnedAggDataSortedContent.length} />
            )}
            <AggDataItemsLayout
              layoutType={displayViewType}
              notes={pinnedAggDataSortedContent}
            />
            {unpinnedAggDataSortedContent &&
              unpinnedAggDataSorted.length > 0 && (
                <UnpinnedHeader itemsCount={unpinnedAggDataSorted.length} />
              )}
            <AggDataItemsLayout
              layoutType={displayViewType}
              notes={unpinnedAggDataSortedContent}
            />
          </>
        );

        setAggdataListContent(renderedDataList);
      } else {
        const renderedDataList = (
          <>
            <AggDataItemsLayout
              layoutType={displayViewType}
              notes={result.map((aggData) => getAggDataListItem(aggData))}
            />
          </>
        );
        setAggdataListContent(renderedDataList);
      }
    } else if (
      displaySortType == SORT_TYPE.LAST_CREATED ||
      displaySortType == SORT_TYPE.COMMENTS_COUNT
    ) {
      dataToDisplaySorted.current = dataToDisplay;
      const renderedDataList = (
        <>
          <AggDataItemsLayout
            layoutType={displayViewType}
            notes={dataToDisplay.map((aggData) => getAggDataListItem(aggData))}
          />
        </>
      );
      setAggdataListContent(renderedDataList);
    } else {
      console.log("Sort type not supported!! =" + displaySortType);
    }
  }, [dataToDisplay, displayViewType]);

  const handleImageAggDataClicked = useCallback(
    (currentAggDataImg: AggDataObj) => {
      var title = "";

      if (
        requestType == RequestTypes.COLLECTION ||
        requestType == RequestTypes.ARCHIVED_COLLECTION
      ) {
        title = currentAggDataImg.collection?.name;
      } else if (requestType == RequestTypes.ALL_ITEMS) {
        title = t("all_images_title");
      } else if (requestType == RequestTypes.PENDING_ITEMS) {
        title = t("pending_images_title");
      } else if (requestType == RequestTypes.ARCHIVED_ITEMS) {
        title = t("archives_images_title");
      } else if (requestType == RequestTypes.REMINDERS) {
        title = t("reminder_images_title");
      }

      if (dataTypeFilter == FILTER_TYPE.FILTER_IMAGES) {
        dispatch(
          displayImageViewer(
            dataToDisplaySorted.current,
            currentAggDataImg,
            title
          )
        );
      } else {
        const imagesAggData = dataToDisplaySorted.current.filter(
          (aggData: AggDataObj) =>
            aggData.dataType == DataType.IMAGE_TYPE ||
            aggData.dataType == DataType.VIDEO_TYPE
        );
        //console.log("resultik images sent %o", imagesAggData)
        dispatch(displayImageViewer(imagesAggData, currentAggDataImg, title));
      }
    },
    [dataToDisplay, dataToDisplaySorted, dataToDisplayUnfilteredByType]
  );

  const getAggDataListItem = (aggData: AggDataObj) => {
    const onImageClicked =
      aggData.dataType == DataType.IMAGE_TYPE ||
      aggData.dataType == DataType.VIDEO_TYPE
        ? handleImageAggDataClicked
        : null;
    if (displayViewType == VIEW_TYPE.GRID) {
      return (
        //Huge performance improvement. We only render visible items
        <LazyLoad key={aggData.id} height={250} offset={400} once>
          <AggData
            key={aggData.id}
            aggDataObj={aggData}
            displayedFromCollection={Boolean(collectionId)}
            setBeingEditedNoteAggDataId={(aggdataId: string) => {
              currentOpenedNoteAggdataId.current = aggdataId;
            }}
            isEditingProp={currentOpenedNoteAggdataId.current == aggData.id}
            onAddToCollection={() => handleAddToCollection(aggData)}
            onImageAggDataClicked={onImageClicked}
          />
        </LazyLoad>
      );
    } else {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          key={aggData.id}
          style={{
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            padding: displayViewType == VIEW_TYPE.LIST ? 0 : 16,
            width: "100%",
          }}
        >
          <LazyLoad
            key={aggData.id}
            height={100}
            offset={displayViewType == VIEW_TYPE.LIST ? 1000 : 100}
            once
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AggData
              layoutType={displayViewType}
              key={aggData.id}
              aggDataObj={aggData}
              displayedFromCollection={Boolean(collectionId)}
              setBeingEditedNoteAggDataId={(aggdataId: string) => {
                currentOpenedNoteAggdataId.current = aggdataId;
              }}
              isEditingProp={currentOpenedNoteAggdataId.current == aggData.id}
              onAddToCollection={() => handleAddToCollection(aggData)}
              onImageAggDataClicked={onImageClicked}
            />
          </LazyLoad>
        </Grid>
      );
    }
  };

  const updateSerachContext = useCallback(() => {
    var serachContext: string | undefined = "";
    switch (requestType) {
      case RequestTypes.PENDING_ITEMS:
        switch (dataTypeFilter) {
          case FILTER_TYPE.FILTER_NONE:
            serachContext = t("search_context_pending_all");
            break;
          case FILTER_TYPE.FILTER_LINK:
            serachContext = t("search_context_pending_links");
            break;
          case FILTER_TYPE.FILTER_NOTES:
            serachContext = t("search_context_pending_notes");
            break;
          case FILTER_TYPE.FILTER_IMAGES:
            serachContext = t("search_context_pending_media");
            break;
          case FILTER_TYPE.FILTER_FILES:
            serachContext = t("search_context_pending_files");
            break;
          default:
            serachContext = "";
        }
        break;
      case RequestTypes.ALL_ITEMS:
        switch (dataTypeFilter) {
          case FILTER_TYPE.FILTER_NONE:
            serachContext = t("search_context_all");
            break;
          case FILTER_TYPE.FILTER_LINK:
            serachContext = t("search_context_all_links");
            break;
          case FILTER_TYPE.FILTER_NOTES:
            serachContext = t("search_context_all_notes");
            break;
          case FILTER_TYPE.FILTER_IMAGES:
            serachContext = t("search_context_all_media");
            break;
          case FILTER_TYPE.FILTER_FILES:
            serachContext = t("search_context_all_files");
            break;
          default:
            serachContext = "";
        }
        break;
      case RequestTypes.COLLECTION:
      case RequestTypes.ARCHIVED_COLLECTION:
        if (!dataToDisplay || dataToDisplay.length == 0) {
          return; // if aggdata is not ready yet don't do anything. We need it to figure out the collection name, to set the search context.
        }
        switch (dataTypeFilter) {
          case FILTER_TYPE.FILTER_NONE:
            serachContext = dataToDisplay[0].collection?.name;
            break;
          case FILTER_TYPE.FILTER_LINK:
            serachContext = t("search_context_links_from_collection", {
              collection_name: dataToDisplay[0].collection?.name,
            });
            break;
          case FILTER_TYPE.FILTER_NOTES:
            serachContext = t("search_context_notes_from_collection", {
              collection_name: dataToDisplay[0].collection?.name,
            });
            break;
          case FILTER_TYPE.FILTER_IMAGES:
            serachContext = t("search_context_media_from_collection", {
              collection_name: dataToDisplay[0].collection?.name,
            });
            break;
          case FILTER_TYPE.FILTER_FILES:
            serachContext = t("search_context_files_from_collection", {
              collection_name: dataToDisplay[0].collection?.name,
            });
            break;
        }
        break;
      case RequestTypes.ARCHIVED_ITEMS:
        serachContext = t("search_context_archived_items");
        switch (dataTypeFilter) {
          case FILTER_TYPE.FILTER_NONE:
            serachContext = t("search_context_archived_items");
            break;
          case FILTER_TYPE.FILTER_LINK:
            serachContext = t("search_context_archived_links");
            break;
          case FILTER_TYPE.FILTER_NOTES:
            serachContext = t("search_context_archived_notes");
            break;
          case FILTER_TYPE.FILTER_IMAGES:
            serachContext = t("search_context_archived_media");
            break;
          case FILTER_TYPE.FILTER_FILES:
            serachContext = t("search_context_archived_files");
            break;
        }
        break;
      case RequestTypes.REMINDERS:
        serachContext = t("search_context_reminder_items");
        switch (dataTypeFilter) {
          case FILTER_TYPE.FILTER_NONE:
            serachContext = t("search_context_reminder_items");
            break;
          case FILTER_TYPE.FILTER_LINK:
            serachContext = t("search_context_reminder_links");
            break;
          case FILTER_TYPE.FILTER_NOTES:
            serachContext = t("search_context_reminder_notes");
            break;
          case FILTER_TYPE.FILTER_IMAGES:
            serachContext = t("search_context_reminder_media");
            break;
          case FILTER_TYPE.FILTER_FILES:
            serachContext = t("search_context_reminder_files");
            break;
        }
        break;
      default:
        serachContext = "";
        break;
    }
    dispatch(setSearchContext(serachContext));
  }, [retrieveAllAggdataResult.data, dispatch, requestType, dataTypeFilter]);

  function handleAddToCollection(aggData: AggDataObj) {
    dispatch(openAttachDataToCollectionDialog(aggData));
  }

  if (
    retrieveAllAggdataResult.error ||
    loadPublicAggdataForCollectionResult.error
  ) {
    return <ErrorLoading></ErrorLoading>;
  }

  //console.log("loading " + loading + " dataIsLoadingInBackground " + dataIsLoadingInBackground + "  loadAggdataForSharedCollectionResult.loading " +  loadAggdataForSharedCollectionResult.loading)

  if (
    retrieveAllAggdataResult.loading ||
    dataIsLoadingInBackground ||
    loadAggdataForSharedCollectionResult.loading ||
    loadPublicAggdataForCollectionResult.loading
  ) {
    return (
      <DataEmptyContainer>
        {/* {!loading && (
          <ControlsContainer>
            <DataTypeFilter
              selectedValue={dataTypeFilter}
              handleValueChange={handleAlignment}
            />
            <ConfidentialityFilterControl />
          </ControlsContainer>
        )} */}
        <LoadingItems />
      </DataEmptyContainer>
    );
  }

  if (
    dataToDisplay &&
    dataToDisplay.length == 0 &&
    !retrieveAllAggdataResult.error &&
    !retrieveAllAggdataResult.loading
  ) {
    if (searchInput.length > 0) {
      return (
        <DataEmptyContainer>
          {/* <ControlsContainer>
            <DataTypeFilter
              selectedValue={dataTypeFilter}
              handleValueChange={handleAlignment}
            />
          </ControlsContainer> */}
          <SearchResultsEmpty />
        </DataEmptyContainer>
      );
    }
    switch (requestType) {
      case RequestTypes.ARCHIVED_ITEMS:
        return (
          <DataEmptyContainer>
            {/* <ControlsContainer>
              <DataTypeFilter
                selectedValue={dataTypeFilter}
                handleValueChange={handleAlignment}
              />
            </ControlsContainer> */}
            <ArchivesEmpty />
          </DataEmptyContainer>
        );
      case RequestTypes.PENDING_ITEMS:
        return (
          <DataEmptyContainer>
            {/* <ControlsContainer>
              <DataTypeFilter
                selectedValue={dataTypeFilter}
                handleValueChange={handleAlignment}
              />
            </ControlsContainer> */}
            <PendingDataEmpty />
          </DataEmptyContainer>
        );
      case RequestTypes.ALL_ITEMS:
      case RequestTypes.TAG:
        return (
          <DataEmptyContainer>
            {/*   <ControlsContainer>
              <DataTypeFilter
                selectedValue={dataTypeFilter}
                handleValueChange={handleAlignment}
              />
            </ControlsContainer> */}
            <AllItemsEmpty />
          </DataEmptyContainer>
        );
      case RequestTypes.COLLECTION:
      case RequestTypes.ARCHIVED_COLLECTION:
        return (
          <DataEmptyContainer>
            {/* <ControlsContainer>
              <DataTypeFilter
                selectedValue={dataTypeFilter}
                handleValueChange={handleAlignment}
              />
            </ControlsContainer> */}
            <EmptyCollection />
          </DataEmptyContainer>
        );
      default:
        break;
    }
  }

  return (
    <>
      {/*  <ControlsContainer>
        <DataTypeFilter
          selectedValue={dataTypeFilter}
          handleValueChange={handleAlignment}
        />
        <ConfidentialityFilterControl />
      </ControlsContainer> */}

      {aggdataListContent}
    </>
  );
};

export default React.memo(AggDataItemsContainer);

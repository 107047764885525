/*global chrome*/

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { AggDataObj, AggDataTagObj } from "models/aggdata";
import AggDataItemsContainer from "components/AggDataItems/AggDataItemsContainer";
import CollectionsContainer from "components/CollectionItems/CollectionsContainer";
import { retrieveAllDataGql } from "graphql/queries";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { RequestTypes } from "components/AggDataItems/AggDataItemsContainer";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  setAllItemsMaxPinScore,
  setMaxSortOrderScore,
} from "store/actions/aggdataActions";
import AggdataDisplayControl, {
  FILTER_TYPE,
  SORT_TYPE,
  VIEW_TYPE,
} from "components/UI/Controls/AggdataDisplayControl";
import { Box } from "@mui/material";
import {
  getAggdataFilterType,
  getAggdataSortType,
  getAggdataViewType,
} from "localstorage/localstorage";
import SimpleTagsFilter from "components/UI/Filters/SimpleTagsFilter";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabsContainer = styled.div`
  padding-left: 135px;

  display: flex;
  flex-directoin: row;
  justify-content: space-between;
  height: 40px;
  margin-top: 8px;
  margin-bottom: 12px;
  width: 100%;
`;

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    "& .MuiButtonBase-root": {
      fontSize: "1.4rem",
    },
  },
}));

interface HomeProps {
  tabName?: string;
}

function Home({ tabName = "all" }: HomeProps) {
  const classes = useStyles();
  const [dataTypeFilter, setDataTypeFilter] = React.useState<FILTER_TYPE>(
    getAggdataFilterType()
  );
  const [displayViewType, setDispayViewType] = React.useState<VIEW_TYPE>(
    getAggdataViewType()
  );
  const [displaySortType, setDisplaySortType] = React.useState<SORT_TYPE>(
    getAggdataSortType()
  );
  const [selectedFilterTags, setSelectedFilterTags] = React.useState<
    AggDataTagObj[]
  >([]);
  /**
   * Data list is first retrieved here. This is the initial fetch done by the react app,
   * which loads the cache and make it available to a ll views;
   */
  const { loading, error, data } = useQuery(retrieveAllDataGql, {
    // pollInterval: 500,
  });

  const [currentTab, setCurrentTab] = React.useState(tabName);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (currentTab === RequestTypes.COLLECTIONS) {
      history.replace("/board/collections");
    } else if (currentTab === RequestTypes.ALL_ITEMS) {
      history.replace("/board/all");
    } else if (currentTab === RequestTypes.PENDING_ITEMS) {
      history.replace("/board/pending");
    }
  }, [currentTab]);

  useEffect(() => {
    if (data && data.allAggdata && data.allAggdata.length > 0) {
      const maxAllItemsPinScoreAggData = data.allAggdata.reduce(
        (previous: AggDataObj, current: AggDataObj) =>
          Number(previous.allItemsPinScore) > Number(current.allItemsPinScore)
            ? previous
            : current
      );
      dispatch(
        setAllItemsMaxPinScore(
          Number(maxAllItemsPinScoreAggData.allItemsPinScore)
        )
      );
      const maxSortOrderScoreAggData = data.allAggdata.reduce(
        (previous: AggDataObj, current: AggDataObj) =>
          Number(previous.sortOrderScore) > Number(current.sortOrderScore)
            ? previous
            : current
      );
      dispatch(
        setMaxSortOrderScore(Number(maxSortOrderScoreAggData.sortOrderScore))
      );
    }
  }, [data]);

  const handleTabChange = (event: any, selectedTab: any) => {
    setCurrentTab(selectedTab);
  };

  var container;
  if (tabName === RequestTypes.COLLECTIONS) {
    container = (
      <Container>
        <CollectionsContainer></CollectionsContainer>
      </Container>
    );
  } else {
    container = (
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginRight: "5%",
          }}
        >
          <AggdataDisplayControl
            onSortTypeChanged={(sortType: SORT_TYPE) => {
              setDisplaySortType(sortType);
            }}
            onViewTypeChanged={(viewType: VIEW_TYPE) => {
              setDispayViewType(viewType);
            }}
            onFilterTypeChanged={(filterType: FILTER_TYPE) => {
              setDataTypeFilter(filterType);
            }}
            selectedTags={selectedFilterTags}
            onSelectedTagsChanged={(tags: AggDataTagObj[]) => {
              setSelectedFilterTags(tags);
            }}
            displaySortByComments
          />
        </Box>
        {selectedFilterTags.length > 0 && (
          <SimpleTagsFilter
            allSelectedText={t("filter_all_collections_selected_text")}
            inputHint={t("filter_by_tags_title")}
            onSelectionChanged={(values) => {
              // console.log("YIT simple filter tag selection changes " + JSON.stringify(values))
              setSelectedFilterTags(
                values.map((value) => {
                  return { name: value };
                })
              );
            }}
            selectedValues={selectedFilterTags.map(
              (selectedTag) => selectedTag.name
            )}
          />
        )}

        <AggDataItemsContainer
          displayViewType={displayViewType}
          dataTypeFilter={dataTypeFilter}
          displaySortType={displaySortType}
          requestType={tabName}
          filterTags={selectedFilterTags}
        />
      </Container>
    );
  }
  return (
    <>
      <TabsContainer>
        <Tabs
          className={classes.tab}
          textColor="primary"
          indicatorColor="primary"
          value={tabName}
          onChange={handleTabChange}
          aria-label="icon label tabs example"
        >
          <Tab
            value={RequestTypes.PENDING_ITEMS}
            label={t("tab_pending_title")}
          />
          <Tab value={RequestTypes.ALL_ITEMS} label={t("tab_all_title")} />
        </Tabs>
      </TabsContainer>

      {container}
    </>
  );
}

export default React.memo(Home);

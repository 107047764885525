import React from "react";
import CollectionsEmptyIllustration from "illustrations/ill_undraw_empty_list_list.svg";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow-x: hidden;
`;

const CollectionsEmpty = () => {
  const { t } = useTranslation();


  return (
    <MainContainer>
      <img
        style={{ marginTop: "60px", height: "300px",  cursor:"initial" }}
        src={CollectionsEmptyIllustration}
      />
      <Typography
        style={{
          textAlign: "center",
          width: "100%",
          marginLeft: "12px",
          color: "black",
          marginTop: "36px",
        }}
        variant="h4"
      >
        {t("collections_list_empty_title")}
      </Typography>
      <Typography
        style={{
          textAlign: "center",
          width: "100%",
          marginLeft: "12px",
          color: "black",
          marginTop: "12px",
        }}
        variant="h5"
      >
        {t("collections_list_empty_subtitle")}
      </Typography>
    </MainContainer>
  );
};

export default CollectionsEmpty;

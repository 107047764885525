import React from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import "./AggDataItemsLayout.css";
import { VIEW_TYPE } from "components/UI/Controls/AggdataDisplayControl";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

const ItemsContainer = styled.div`
  margin: 0px 0 0px 0;
  overflow-x: hidden;

  @media (max-width: 576px) {
    margin: 0px 0 0px 0;
  }

  &:last-child {
    margin: 0px 0 30px 0;

    @media (max-width: 576px) {
      margin: 0px 0 30px 0;
    }
  }
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #5f6368;
  margin-left: 135px;
  padding: 8px;

  @media (max-width: 576px) {
    margin-left: 75px;
  }
`;

interface BreakPoints {
  default: number;
  1200: number;
  1024: number;
  768: number;
  576: number;
}

interface AggDataItemsLayoutProps {
  notes: JSX.Element[];
  title?: string;
  itemsPerRow?: number;
  layoutType?: VIEW_TYPE;
}

const AggDataItemsLayout = ({
  notes,
  title,
  itemsPerRow = 6,
  layoutType = VIEW_TYPE.GRID,
}: AggDataItemsLayoutProps) => {
  const BREAK_POINTS: BreakPoints = {
    default: itemsPerRow,
    1200: 5,
    1024: 3,
    768: 2,
    576: 2,
  };

  if (notes.length > 0) {
    return (
      <>
        {layoutType == VIEW_TYPE.GRID && (
          <ItemsContainer>
            <Masonry
              breakpointCols={BREAK_POINTS}
              className="grid"
              columnClassName="col"
              style={{ width: "100%", marginTop: "16px" }}
            >
              {notes}
            </Masonry>
          </ItemsContainer>
        )}
        {layoutType == VIEW_TYPE.LIST && (
          <Box sx={{
            display: "flex",
            paddingLeft: "2.5%",
            paddingRight: "2.5%",
          }}>
            <Grid
              container
            >
              {notes}
            </Grid>
          </Box>
        )}
        {layoutType == VIEW_TYPE.FEED  && (
          <Box sx={{
            display: "flex",
            paddingLeft: "20%",
            paddingRight: "20%",
            "@media (max-width: 780px)": {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}>
            <Grid
              container
            >
              {notes}
            </Grid>
          </Box>
        )}
      </>
    );
  }
  return null;
};

export default React.memo(AggDataItemsLayout);

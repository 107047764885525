import React, { useCallback, useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "icons/favicon.png";
import Button from "@mui/material/Button";
import { activateAccount } from "api/authApi";

import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { LOGIN } from "navigation/Constants";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grow from "@mui/material/Grow";
import { colorMemento } from "shared/colors";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { colorMementoBeige } from "shared/colors";

import LogoIcon from "icons/ic_memento_m_letter.svg";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: ${colorMementoBeige};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  padding-top: 8%;
  overflow-y: hidden;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    goToBoardButton: {
      fontSize: "3rem",
    },
  })
);

const AccountActivationPage: React.FC = () => {
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [activationFailed, setActivationFailed] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const params: any = useParams();
  const classes = useStyles();

  useEffect(() => {
    activateAccount(params.uid, params.token)
      .then((statusCode) => {
        if (statusCode && statusCode == 200) {
          setActivationSuccess(true);
        } else {
          setActivationFailed(true);
        }
      })
      .catch((e) => {
        setActivationFailed(true);
      });
    return () => {
      console.log("Cleanup from login");
    };
  }, []);


  const handleGotoLogin = React.useCallback(() => {
    history.replace(LOGIN);
  }, [history]);

  return (
    <MainContainer>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "12px",
          background: "#f8fbf8",
          borderRadius: "16px",
          minWidth: "30%",
          "@media (max-width: 780px)": {
            width: "80%",
          },
        }}
        elevation={3}
      >
        <Link to="/">
          <img
            id="memento"
            src={LogoIcon}
            alt="Memento logo"
            width={activationSuccess ? "24px" : "82px"}
            height={activationSuccess ? "24px" : "82px"}
          />
        </Link>

        {activationSuccess && !activationFailed && (
          <Grow in={activationSuccess} style={{ transformOrigin: "0 0 0" }}>
            <CheckCircleIcon
              style={{
                height: "148px",
                width: "148px",
                color: colorMemento,
              }}
            />
          </Grow>
        )}
        {!activationSuccess && !activationFailed && (
          <Typography
            sx={{ marginTop: "24px", marginBottom: "24px", textAlign: "center" }}
            variant="h4"
            component="div"
            gutterBottom
          >
            {t("account_activation_ongoing")}
          </Typography>
        )}
        {activationSuccess && !activationFailed && (
          <Typography
            sx={{ marginTop: "24px", marginBottom: "24px", textAlign: "center" }}
            variant="h4"
            component="div"
            gutterBottom
          >
            {t("account_activation_success")}
          </Typography>
        )}
        {activationFailed && !activationSuccess && (
          <Typography
            sx={{ marginTop: "24px", marginBottom: "24px", textAlign: "center"}}
            variant="h4"
            component="div"
            gutterBottom
          >
            {t("account_activation_failed")}
          </Typography>
        )}
        {!activationSuccess && !activationFailed && (
          <CircularProgress size="64px" variant="indeterminate" />
        )}

        {activationSuccess && !activationFailed && (
          <Grow in={activationSuccess} style={{ transformOrigin: "0 0 0" }}>
            <Button
              className={classes.goToBoardButton}
              style={{
                fontSize: "1.8rem",
                marginTop: "32px",
                minWidth: "10%",
                width: "50%",
                backgroundColor: colorMemento,
                color: "white",
              }}
              size="large"
              onClick={handleGotoLogin}
            >
              {t("go_to_login_page")}
            </Button>
          </Grow>
        )}
      </Paper>
    </MainContainer>
  );
};

export default AccountActivationPage;

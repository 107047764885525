import React, { useState, useCallback } from "react";
import styled from "styled-components";

import Tool from "containers/Toolbar/Tool/Tool";
import ColorPalette from "components/ColorPalette/ColorPalette";
import { Dispatcher, ToggleTool } from "shared/types";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import PaletteIcon from "@mui/icons-material/Palette";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useTranslation } from "react-i18next";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { AggDataObj } from "models/aggdata";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const ToolbarContainer = styled("div")<{ isHover: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  line-height: 0;
  padding-left: 4px;
  opacity: ${(props) => (props.isHover ? 1 : 0)};
  background: rgba(255, 255, 255, 0.8);
  transition: opacity 0.3s ease-out;
  z-Index: 900;

  @media (max-width: 1024px) {
    opacity: 1;
  }

  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

const CloseBtn = styled.button`
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 1.5rem;
  padding: 8px 24px;
  border-radius: 4px;
  letter-spacing: 0.4px;

  &:hover {
    opacity: 0.87;
    background: rgba(95, 99, 104, 0.157);
  }

  @media (max-width: 320px) {
    margin-left: auto;
    padding: 0;
  }
`;

const ToolContaienr = styled.div`
  padding-top: 8px;
  padding-bottom: 4px;

  @media (max-width: 320px) {
    margin-left: auto;
  }
`;

interface ToolbarProp {
  id?: string;
  onHover: boolean;
  selectedColor?: number;
  onAddNote?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onToggle?: (noteProperty: ToggleTool) => void;
  onClick?: (color: string) => void;
  isInputField?: boolean;
  setShowLabel: Dispatcher<boolean>;
  onDelete: () => void;
  isChecked?: boolean;
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAddToCollection: () => void;
  isDataArchived: boolean;
  aggDataObj?: AggDataObj;
  onArchive: () => void;
  onPinUnpin: () => void;
  onAddCheckboxes: () => void;
  onAddPicture: () => void;
  onColorSelected: (colorIndex: number) => void;
}

interface Icon {
  id: string;
  icon: any;
  title: string;
}

const useStyles = makeStyles((theme) => ({
  toolIcon: {
    borderRadius: "50%",
    width: "2rem",
    height: "2rem",
    backgroundSize: "50%",
    marginRight: "10px",
    "&:hover": {
      opacity: "0.87",
      backgroundColor: theme.palette.action.hover,
    },
    cursor: "pointer",
  },
  addToCollectionIcon: {
    borderRadius: "50%",
    width: "1.7rem",
    height: "1.7rem",
    backgroundSize: "50%",
    marginRight: "10px",
    "&:hover": {
      opacity: "0.87",
      backgroundColor: theme.palette.action.hover,
    },
    cursor: "pointer",
  },
}));

const PALETTE_TOOL = "Color";
const ADD_TO_COLLECTION_TOOL = "Add to collection";
const ADD_CHECK_BOXES = "Add checkboxes";
const ARCHIVE_TOOL = "Archive";
const DELETE_TOOL = "Delete";
const PIN_UNPIN_TOOL = "Pin";
const ADD_PICTURE = "Add picture";


const NoteEditorToolbar = ({
  id,
  onHover,
  selectedColor,
  onAddNote,
  onToggle,
  onClick,
  isInputField,
  setShowLabel,
  onDelete,
  isChecked,
  onClose,
  onAddToCollection,
  isDataArchived,
  aggDataObj,
  onArchive,
  onPinUnpin,
  onAddCheckboxes,
  onAddPicture,
  onColorSelected,
}: ToolbarProp) => {
  const [isHoverColorPalette, setIsHoverColorPalette] = useState(false);
  // const editableNote = useSelector(
  //   (state: RootState) => state.notes.editableNote,
  // );

  const classes = useStyles();
  const { t } = useTranslation();

  const isArchived: boolean =
    window.location.pathname === "/archives" ? true : false;
  // const isEditable: boolean = editableNote ? true : false;
  const isEditable: boolean = true;

  const icons: Icon[] = [
    {
      id: PALETTE_TOOL,
      title: t("toolbar_palette_tool_title"),
      icon: (
        <PaletteIcon
          id={PALETTE_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
    },
    {
      id: ADD_CHECK_BOXES,
      icon: (
        <CheckBoxOutlinedIcon
          id={ADD_CHECK_BOXES}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
      title: t("toolbar_add_checkboxes_tool_title"),
    },
    {
      id: ADD_TO_COLLECTION_TOOL,
      icon: (
        <PlaylistAddIcon
          id={ADD_TO_COLLECTION_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.addToCollectionIcon}
        />
      ),
      title: t("toolbar_add_to_collection_tool_title"),
    },
    {
      id: ARCHIVE_TOOL,
      icon: isDataArchived ? (
        <UnarchiveIcon
          id={ARCHIVE_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ) : (
        <ArchiveIcon
          id={ARCHIVE_TOOL}
          onClick={(e) => handleClick(e)}
          className={classes.toolIcon}
        />
      ),
      title: isDataArchived
        ? t("toolbar_unarchive_tool_title")
        : t("toolbar_archive_tool_title"),
    },
    {
      id: PIN_UNPIN_TOOL,
      icon:
        Number(aggDataObj?.allItemsPinScore) > 0 ? (
          <PushPinOutlinedIcon
            id={PIN_UNPIN_TOOL}
            onClick={(e) => handleClick(e)}
            className={classes.toolIcon}
          />
        ) : (
          <PushPinIcon
            id={PIN_UNPIN_TOOL}
            onClick={(e) => handleClick(e)}
            className={classes.toolIcon}
          />
        ),
      title:
        Number(aggDataObj?.allItemsPinScore) > 0
          ? t("action_unpin")
          : t("action_pin"),
    },
    {
      id: ADD_PICTURE,
      icon: (
        <AddPhotoAlternateIcon
          id={ADD_PICTURE}
          onClick={(e) => handleClick(e)}
          className={classes.addToCollectionIcon}
        />
      ),
      title: t("toolbar_add_to_picture_tool_title"),
    },
    {
      id: DELETE_TOOL,
      icon: (
        <DeleteIcon
          onClick={(e) => handleClick(e)}
          id={DELETE_TOOL}
          className={classes.toolIcon}
        />
      ),
      title: t("toolbar_delete_tool_title"),
    },
  ];

  const handleShowColorPalette = useCallback((): void => {
    setIsHoverColorPalette(true);
  }, []);

  const handleHideColorPalette = useCallback((): void => {
    setIsHoverColorPalette(false);
  }, []);

  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const handleArchive = useCallback(() => {
    onArchive();
  }, [onArchive]);

  const handlePinUnpin = useCallback(() => {
    onPinUnpin();
  }, [onPinUnpin]);

  const handleClick = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.preventDefault();
      switch (e.currentTarget.id) {
        case DELETE_TOOL:
          handleDelete();
          break;
        case ADD_TO_COLLECTION_TOOL:
          onAddToCollection();
          break;
        case ARCHIVE_TOOL:
          handleArchive();
          break;
        case PIN_UNPIN_TOOL:
          handlePinUnpin();
          break;
        case ADD_CHECK_BOXES:
          onAddCheckboxes();
          break;
        case PALETTE_TOOL:
          handleShowColorPalette(true);
          break;
        case ADD_PICTURE:
          onAddPicture();
          break;
        default:
          console.log("not supported!");
      }
    },
    [onAddToCollection, handleDelete, handleArchive]
  );

  //const handleAddToCollection =

  return (
    <ToolbarContainer isHover={onHover}>
      <ToolContaienr>
        {icons.map((icon) => {
          if (isDataArchived && icon.title == ADD_TO_COLLECTION_TOOL) {
            return null;
          }
          return (
            <Tool
              key={icon.title}
              id={id}
              title={icon.title}
              showPalette={
                icon.title === "Change Color"
                  ? handleShowColorPalette
                  : undefined
              }
              hidePalette={
                icon.title === "Change Color"
                  ? handleHideColorPalette
                  : undefined
              }
              onToggle={onToggle}
              setShowLabel={setShowLabel}
              isInputField={isInputField}
              isChecked={isChecked}
            >
              {icon.icon}
            </Tool>
          );
        })}
        {/* {!isInputField && (
          <Tool
            id={id}
            title="Delete Note"
            bgImage={TranshCanIcon}
            onDelete={onDelete}
          />
        )} */}
      </ToolContaienr>
      {isInputField && <CloseBtn onClick={onAddNote}>Close</CloseBtn>}
      {/* {isEditable && <CloseBtn onClick={onClose}>Close</CloseBtn>} */}
      {isHoverColorPalette && (
        <ColorPalette
          id={id}
          selectedColor={selectedColor}
          isInputField={isInputField}
          onUnHover={handleHideColorPalette}
          onHover={handleShowColorPalette}
          onColorSelected={onColorSelected ? onColorSelected : undefined}
        />
      )}
    </ToolbarContainer>
  );
};

export default React.memo(NoteEditorToolbar);

import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { RootState } from "store/reducers";
import {
  retrieveAllCollectionsGql,
  retrieveTopLevelCollectionsGql,
  retrieveAllArchivedCollectionsGql,
  retrieveAllTopLevelArchivedCollectionsGql,
} from "graphql/queries";
import { useQuery } from "@apollo/client";
import { experimentalStyled as styledComponent } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { CollectionObj } from "models/collection";
import CollectionLayout from "components/CollectionItems/CollectionLayout";
import { updateCollectionContext, setSearchContext } from "store/actions/view";
import {
  setCollectionsMaxPinScore,
  setCollectionsMaxLastUsedScore,
} from "store/actions/collectionsActions";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CollectionsEmpty from "containers/IllustrationContainers/CollectionsEmpty";
import PinnedHeader from "components/UI/Headers/PinnedHeader";
import UnpinnedHeader from "components/UI/Headers/UnpinnedHeader";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import ArchivesEmpty from "containers/IllustrationContainers/ArchivesEmpty";
import LazyLoad, { forceCheck } from "react-lazyload";
import ConfidentialityFilterControl from "components/UI/Controls/ConfidentialityFilterControl";
import CollectionsFilterControl, {
  SORT_TYPE,
  VIEW_TYPE,
} from "components/UI/Controls/CollectionsFilterControl";
import BackgroundWorker from "workers/NextReminderWorker.worker";
import {
  BUILD_SHARED_USERS_LIST,
  BUILD_SHARED_USERS_LIST_REPLY,
  FILTER_COLLECTIONS,
  FILTER_COLLECTIONS_REPLY,
} from "workers/WorkersMessageRequests";
import { DEBUG } from "configuration";
import { setSharedUsersList } from "store/actions/collectionsActions";
import { Typography } from "@mui/material";
import CollectionsFilter, {
  CollectionsFilterOptions,
} from "components/UI/Filters/CollectionsFilter";
import LoadingCollections from "components/layout/Loading/LoadingCollections";
import {
  getCollectionFilterType,
  getCollectionsSortType,
  getCollectionsViewType,
  saveCollectionFilterOption,
} from "localstorage/localstorage";
import { UserObj } from "models/user";
import { getCollectionRoute } from "navigation/Constants";
import { Helmet } from "react-helmet";

const Item = styledComponent(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "column",
}));

const MainContainer = styled.div`
  display: flex;
  margin-top: 36px;
  flex-direction: column;

  margin-bottom: 12px;
  width: 100%;
  height: 100%;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-directoin: row;
  justify-content: end;
  height: 40px;
  margin-top: 36px;
  margin-bottom: 12px;
  width: 100%;
`;

const PreviewImageContainer = styled.img`
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

const CollectionItemFooter = styled.div`
  display: flex;
  justify-content: start;
  flex-directoin: column;
  margin-bottom: 8px;
  width: 100%;
`;

export const CollectionTitle = styled.div`
  display: flex;
  justify-content: start;
  font-size: 1.6rem;
  font-weight: 500;
  width: 100%;
  min-height: 24px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) => ({
  itemsTypeStack: {
    marginRight: "135px",
  },
  tab: {
    "& .MuiButtonBase-root": {
      fontSize: "1.4rem",
    },
  },

  headerText: {},
}));

const PIN_FILTER_TYPE = 1;

interface CollectionsContainerProps {
  displayArchivedCollections?: boolean;
}

//const DEFAULT_VIEW_TYPE = VIEW_TYPE.GRID;

const CollectionsContainer = ({
  displayArchivedCollections = false,
}: CollectionsContainerProps) => {
  const [filterType, setFilterType] = React.useState(getCollectionFilterType());

  const [renderedGridContent, setRenderedGridContent] = React.useState(<></>);
  const { loading, error, data } = useQuery(
    displayArchivedCollections
      ? retrieveAllTopLevelArchivedCollectionsGql
      : retrieveTopLevelCollectionsGql
  );

  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  ); //current user

  const [emptyResult, setEmptyResult] = React.useState(Boolean(data)); //if data is already there, consider the initial state to be empty

  const [dataLoadingWorker, setDataLoadingWorker] = React.useState(true);
  const backgroundWorkerRef = React.useRef<Worker>();
  const backgroundWorkerRequestToken = React.useRef<Worker>(0);
  const filterCollectionsWorkerRequestToken = React.useRef<Worker>(0);
  const { t } = useTranslation();
  const [collectionsToDisplay, setCollectionsToDisplay] = React.useState<
    CollectionObj[]
  >([]);
  const [personalCollectionsList, setPersonalCollectionsList] = React.useState<
    CollectionObj[]
  >([]);
  const [sharedCollectionsList, setSharedCollectionsList] = React.useState<
    CollectionObj[]
  >([]);
  const history = useHistory();
  const searchInput: string = useSelector(
    (state: RootState) => state.view.searchInput
  );

  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedViewType, setSelectedViewType] = React.useState<VIEW_TYPE>(
    getCollectionsViewType()
  );

  const [sortType, setSortType] = React.useState<SORT_TYPE>(
    getCollectionsSortType()
  );

  React.useEffect(() => {
    if (backgroundWorkerRef && backgroundWorkerRef.current) {
      backgroundWorkerRef.current.terminate();
    }
    backgroundWorkerRef.current = new BackgroundWorker();
    backgroundWorkerRef.current.addEventListener("message", (event: any) => {
      if (DEBUG) {
        console.log("CollectionsContainer got back message %o", event.data);
      }
      if (event.data.reply == BUILD_SHARED_USERS_LIST_REPLY) {
        const requestToken = event.data.payload.token;
        if (backgroundWorkerRequestToken.current != requestToken) {
          if (DEBUG) {
            console.log(
              "CollectionsContainer: drop this reply from worker, request token doesn't match"
            );
          }
          return;
        }
        const allSharedUsersList = event.data.payload.sharedUsersList;
        /*  console.log(
          "List of sharedUsers is " + JSON.stringify(allSharedUsersList)
        ); */
        dispatch(setSharedUsersList(allSharedUsersList));
      } else if (event.data.reply == FILTER_COLLECTIONS_REPLY) {
        const requestToken = event.data.payload.token;
        if (filterCollectionsWorkerRequestToken.current != requestToken) {
          if (DEBUG) {
            console.log(
              "CollectionsContainer: drop this reply from worker, request token doesn't match"
            );
          }
          return;
        }
        const personalCollectionsList =
          event.data.payload.personalCollectionsList;
        const sharedCollectionsList = event.data.payload.sharedCollectionsList;
        setPersonalCollectionsList(personalCollectionsList);
        setSharedCollectionsList(sharedCollectionsList);
        setDataLoadingWorker(false);
      }
    });
    return () => {
      //thread cleanup
      if (backgroundWorkerRef && backgroundWorkerRef.current) {
        backgroundWorkerRef.current.terminate();
      }
    };
  }, []);

  useEffect(() => {
    dispatch(updateCollectionContext(true));
    dispatch(
      setSearchContext(
        !displayArchivedCollections
          ? t("search_context_all_collections")
          : t("search_context_all_archived_collections")
      )
    );
    return () => {
      dispatch(updateCollectionContext(false));
      dispatch(setSearchContext(""));
    };
  }, []);

  const getCollectionGridItem = React.useCallback(
    (collection: CollectionObj) => {
      return (
        <Grid
          item
          xs={selectedViewType == VIEW_TYPE.LIST ? 12 : 2}
          sm={selectedViewType == VIEW_TYPE.LIST ? 12 : 3}
          md={selectedViewType == VIEW_TYPE.LIST ? 12 : 3}
          lg={selectedViewType == VIEW_TYPE.LIST ? 12 : 4}
          key={collection.id}
          style={{
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            padding: selectedViewType == VIEW_TYPE.LIST ? 0 : 16,
            width: "60%",
          }}
        >
          <LazyLoad key={collection.id} height={100} offset={600} once>
            <Box sx={{
              marginRight: "5%",
              marginLeft: "5%"
            }}>
              <CollectionLayout
                onClick={(e, collectionClicked) => handleCollectionClicked(e, collectionClicked)}
                key={collection.id!!}
                collection={collection}
                listLayout={selectedViewType == VIEW_TYPE.LIST}
              />
            </Box>
          </LazyLoad>
        </Grid>
      );
    },
    [selectedViewType]
  );

  useEffect(() => {
    if (data && (data.collections || data.topLevelCollections || data.archivedTopLevelCollections)) {
      const collectionsList = displayArchivedCollections
        ? data.archivedTopLevelCollections
        : data.topLevelCollections;
      if (!displayArchivedCollections) {
        backgroundWorkerRef.current.postMessage({
          request: BUILD_SHARED_USERS_LIST,
          payload: {
            token: ++backgroundWorkerRequestToken.current,
            collections: collectionsList,
          },
        });
      }
      setDataLoadingWorker(emptyResult); //if there is existing data, we don't want to have the screen flashing for update
      backgroundWorkerRef.current.postMessage({
        request: FILTER_COLLECTIONS,
        payload: {
          token: ++filterCollectionsWorkerRequestToken.current,
          searchInput: searchInput,
          sortType: sortType,
          collections: collectionsList,
        },
      });

      //var searchAppliedCollectionsArray = applySearchInput(collectionsList);
      //setCollectionsToDisplay(searchAppliedCollectionsArray);
    }
  }, [data, searchInput, sortType]);

  //In this useeffect we will construct the grid layout content.
  // The grid content will include various headers depending on the filterType chosen
  useEffect(() => {
    var renderedResult = <></>;
    if (filterType == CollectionsFilterOptions.PERSONAL) {
      if (personalCollectionsList.length > 0) {
        if (sortType == SORT_TYPE.PINNED_FIRST) {
          const personalPinnedCollectionsList: CollectionObj[] = [];
          const personalUnPinnedCollectionsList: CollectionObj[] = [];
          personalCollectionsList.map((collection: CollectionObj) => {
            if (collection.pinScore > 0) {
              personalPinnedCollectionsList.push(collection);
            } else {
              personalUnPinnedCollectionsList.push(collection);
            }
          });
          renderedResult = (
            <>
              {personalPinnedCollectionsList &&
                personalPinnedCollectionsList.length > 0 && (
                  <PinnedHeader
                    itemsCount={personalPinnedCollectionsList.length}
                  />
                )}
              {personalPinnedCollectionsList.map(
                (collection: CollectionObj, index: number) =>
                  getCollectionGridItem(collection)
              )}
              {personalUnPinnedCollectionsList &&
                personalPinnedCollectionsList &&
                personalPinnedCollectionsList.length > 0 &&
                personalUnPinnedCollectionsList.length > 0 && (
                  <UnpinnedHeader
                    itemsCount={personalUnPinnedCollectionsList.length}
                  />
                )}
              {personalUnPinnedCollectionsList &&
                personalUnPinnedCollectionsList.map(
                  (collection: CollectionObj, index: number) =>
                    getCollectionGridItem(collection)
                )}
            </>
          );
        } else {
          renderedResult = (
            <>
              {personalCollectionsList.map(
                (collection: CollectionObj, index: number) =>
                  getCollectionGridItem(collection)
              )}
            </>
          );
        }
      }
      setEmptyResult(personalCollectionsList.length == 0);
    } else if (filterType == CollectionsFilterOptions.SHARED) {
      renderedResult = (
        <>
          {sharedCollectionsList.map(
            (collection: CollectionObj, index: number) =>
              getCollectionGridItem(collection)
          )}
        </>
      );
      setEmptyResult(sharedCollectionsList.length == 0);
    }
    setRenderedGridContent(renderedResult);
  }, [
    filterType,
    personalCollectionsList,
    sharedCollectionsList,
    selectedViewType,
  ]);

  useEffect(() => {
    forceCheck();
  }, [renderedGridContent]);

  useEffect(() => {
    if (
      data &&
      ((data.collections && data.collections.length > 0) ||
        (data.archivedTopLevelCollections && data.archivedTopLevelCollections.length > 0))
    ) {
      const collectionsList = displayArchivedCollections
        ? data.archivedTopLevelCollections
        : data.collections;
      const maxPinScoreCollection = collectionsList.reduce(
        (previous: CollectionObj, current: CollectionObj) =>
          previous.pinScore > current.pinScore ? previous : current
      );
      dispatch(setCollectionsMaxPinScore(maxPinScoreCollection.pinScore));
      const maxLasUsedScoreCollection = collectionsList.reduce(
        (previous: CollectionObj, current: CollectionObj) =>
          previous.lastUsedScore > current.lastUsedScore ? previous : current
      );
      dispatch(
        setCollectionsMaxLastUsedScore(maxLasUsedScoreCollection.lastUsedScore)
      );
    }
  }, [data]);

  /*  const applySearchInput = useCallback(
    (collectionsArray: CollectionObj[]) => {
      if (searchInput.length > 0) {
        const rgxp = new RegExp(searchInput, "gi");
        const searchCollections = collectionsArray.filter(
          (collection: CollectionObj) => collection.name?.match(rgxp)
        );
        return searchCollections;
      } else {
        return collectionsArray;
      }
    },
    [searchInput]
  ); */

  const handleCollectionClicked = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      collection: CollectionObj
    ) => {
      const event = e.target as HTMLElement;
      console.log("tagname " + event.tagName);
      if (event.tagName == "svg" || event.tagName == "BUTTON") {
        //ignoe the click when it comes from the more button on the card
        return;
      }
      if (displayArchivedCollections) {
        history.push("/board/archives/collection/" + collection.id);
      } else {
        history.push(getCollectionRoute(collection.id));
      }
    },
    [displayArchivedCollections]
  );

  if (loading || dataLoadingWorker) {
    return (
      <Box
        sx={{
          marginLeft: theme.spacing(16),
          marginRight: theme.spacing(16),
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <LoadingCollections listView={selectedViewType == VIEW_TYPE.LIST} />
      </Box>
    );
  }

  if (emptyResult && !error && !loading) {
    if (displayArchivedCollections) {
      return (
        <>
          <Helmet>
            <title>
              {displayArchivedCollections
                ? t("archived_collections_title")
                : t("collections_title")}{" "}
              | Memento
            </title>
          </Helmet>
          <ArchivesEmpty />
        </>
      );
    } else {
      return (
        <MainContainer>
          <Helmet>
            <title>
              {displayArchivedCollections
                ? t("archived_collections_title")
                : t("collections_title")}{" "}
              | Memento
            </title>
          </Helmet>
          <Box
            sx={{
              marginLeft: theme.spacing(16),
              marginRight: theme.spacing(16),
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CollectionsFilter
              defaultValue={getCollectionFilterType()}
              personalCount={personalCollectionsList.length}
              sharedCount={sharedCollectionsList.length}
              handleOptionSelected={(option) => {
                saveCollectionFilterOption(option);
                setFilterType(option);
              }}
            />
            <CollectionsEmpty />
          </Box>
        </MainContainer>
      );
    }
  }

  return (
    <MainContainer>
      <Helmet>
        <title>
          {displayArchivedCollections
            ? t("archived_collections_title")
            : t("collections_title")}{" "}
          | Memento
        </title>
      </Helmet>
      <Box
        sx={{
          marginLeft: theme.spacing(16),
          marginRight: theme.spacing(16),
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* we don't display the filter for archived view */}
        {!displayArchivedCollections && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CollectionsFilter
              defaultValue={getCollectionFilterType()}
              personalCount={personalCollectionsList.length}
              sharedCount={sharedCollectionsList.length}
              handleOptionSelected={(option) => {
                saveCollectionFilterOption(option);
                setFilterType(option);
              }}
            />
          </div>
        )}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CollectionsFilterControl
              initialViewType={getCollectionsViewType()}
              onSortTypeChanged={(sortType: SORT_TYPE) => {
                setSortType(sortType);
              }}
              onViewTypeChanged={(viewType: VIEW_TYPE) => {
                setSelectedViewType(viewType);
              }}
              disablePinSortOption={
                filterType == CollectionsFilterOptions.SHARED
              }
            />
          </div>
          {data && renderedGridContent}
        </Grid>
      </Box>
    </MainContainer>
  );
};

export default React.memo(CollectionsContainer);

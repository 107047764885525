import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { RootState } from "store/reducers";
import { UserObj } from "models/user";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { CalendarPickerSkeleton } from "@mui/x-date-pickers/CalendarPickerSkeleton";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import {
  setSearchContext,
  displayReminderOnAddNewItems,
} from "store/actions/view";
import Badge from "@mui/material/Badge";
import { DEBUG } from "configuration";
import AggDataRemindersContainer from "pages/Reminders/AggDataRemindersContainer";
import CalendarRemindersWoker from "workers/NextReminderWorker.worker";
import {
  COMPUTE_DAYS_WITH_REMINDERS_REPLY,
  COMPUTE_DAYS_WITH_REMINDERS,
  COMPUTE_REMINDERS_FOR_DAY_REPLY,
  COMPUTE_REMINDERS_FOR_DAY,
  SEARCH_AGGDATA_WITH_KEYWORD,
  SEARCH_AGGDATA_WITH_KEYWORD_REPLY,
} from "workers/WorkersMessageRequests";
import { colorMementoBeige, colorMemento } from "shared/colors";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import {
  isDateFromYesterday,
  isDateFromToday,
  isDateFromTomorrow,
  formatToContextualDate,
  formatToContextualDateIntervalForWeek,
  getMonthFromMonthNumber,
} from "shared/dataUtils";
import LoadingItems from "components/layout/Loading/LoadingItems";
import RemindersEmpty from "containers/IllustrationContainers/RemindersEmpty";
import ErrorLoading from "containers/IllustrationContainers/ErrorLoading";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AggDataObj, DataType } from "models/aggdata";
import { displayImageViewer } from "store/actions/view";
import SearchResultsEmpty from "containers/IllustrationContainers/SearchResultsEmpty";
import SimpleFilter from "components/UI/Filters/SimpleFilter";
import { retrieveAllAggDataWithRermindersShared } from "graphql/queries";
import { useQuery } from "@apollo/client";
import SharedUsersFilterControl from "components/UI/Controls/SharedUsersFilterControl";

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BodyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AggDataContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AggDataMainContainer = styled.div`
  height: 100%;
  display: flex;
  margin-top: 2%;
  flex-direction: column;
  width: 75%;
`;

const TitleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  margin-top: 48px;
  width: 100%;
`;

const MenuContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 12px;
  margin-right: 12px;
`;

const CalendarContainer = styled.div`
  height: 10%;
  position: sticky;
  position: -webkit-sticky;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  top: 15%;
`;

const initialValue = new Date();

const SelectedOptionsValues = {
  FOR_SELECTED_DAY: "0",
  FOR_SELECTED_WEEK: "1",
  FOR_SELECTED_MONTH: "2",
};

interface SelectedWeek {
  startDate: Date;
  endDate: Date;
}

const selectedTextStyles = {
  fontSize: "2rem",
};

const RemindersItemsContainer = () => {
  const globalTheme = useTheme();
  const theme = createTheme({
    ...globalTheme,
    components: {
      MuiPickersDay: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "1.4rem",
          },
        },
      },
    },
  });

  const dispatch = useDispatch();
  // const aggDataWithRemindersState = useSelector(
  //   (state: RootState) => state.aggdataReducer.aggDataWithRemindersState
  // );
  const aggDataWithRemindersState = useQuery(
    retrieveAllAggDataWithRermindersShared,
    {
      // pollInterval: 500,
    }
  );
  const searchInput: string = useSelector(
    (state: RootState) => state.view.searchInput
  );

  const calendarRemindersWorker = React.useRef<Worker>();
  const selectedMonth = React.useRef(new Date().getMonth());
  const selectedDate = React.useRef<Date>(initialValue);
  const [isCalendarLoading, setIsCalendarLoading] = React.useState(true);
  const [isLoadingReminderItems, setIsLoadingReminderItems] =
    React.useState(true);
  const [displayNoItemsView, setDisplayNoItemsView] = React.useState(false);
  const [daysWithReminders, setDaysWithReminders] = React.useState([]);
  const [aggDataForSelectedDate, setAggDataForSelectedDate] = React.useState(
    []
  );

  const displayedAggDataPreSearch = React.useRef<AggDataObj[]>([]);
  const [displayedAggData, setDisplayedAggData] = React.useState<AggDataObj[]>(
    //displayed according to what is selected
    []
  );

  const aggDataForSelectedMonth = React.useRef([]);
  const [searchResultEmpty, setSearchResultEmpty] = React.useState(false);
  const aggDataForSelectedDay = React.useRef([]);
  const searchInputRef = React.useRef();
  const aggDataForSelectedDaysWeek = React.useRef([]);
  const lastSelectedDate = React.useRef(initialValue);
  const selectedWeekDates = React.useRef<SelectedWeek>();
  const [selectedDisplayOption, setSelectedDisplayOption] = React.useState(
    SelectedOptionsValues.FOR_SELECTED_DAY
  );
  //Ref used to be passeed to worker thread callback, if we pass directly state variable, the callback doesn't get the freshest value
  //Same is done for other state variables
  const selectedDisplayOptionRef = React.useRef<string>(
    SelectedOptionsValues.FOR_SELECTED_DAY
  );
  const [selectedMonthRemindersCount, setSelectedMonthRemindersCount] =
    React.useState(0);
  const [displayedItemsCollections, setDisplayedItemsCollections] =
    React.useState<string[]>([]);
  const [collectionsFilterSelection, setCollectionsFilterSelection] =
    React.useState<string[]>([]);
  const [value, setValue] = React.useState<Date | null>(initialValue);
  const [foundSharedAggdata, setFoundSharedAggdata] = React.useState(false);
  const [filterRemindersToCurrentUserData, setFilterRemindersToCurrentUserData] = React.useState(false);
  const [title, setTitle] = React.useState<String>("");
  const { t } = useTranslation();
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  const collectionsForMonth = React.useRef<string[]>([]);
  const collectionsForDay = React.useRef<string[]>([]);
  const collectionsForWeek = React.useRef<string[]>([]);
  const collectionsForSearchResult = React.useRef<string[]>([]);

  React.useEffect(() => {
    dispatch(displayReminderOnAddNewItems(true, selectedDate.current));
    dispatch(setSearchContext(t("reminders_title_lowcase")));
    return () => {
      dispatch(setSearchContext(""));
      dispatch(displayReminderOnAddNewItems(false, selectedDate.current));
    };
  }, []);


  React.useEffect(() => {
    if (calendarRemindersWorker && calendarRemindersWorker.current) {
      calendarRemindersWorker.current.terminate();
    }
    calendarRemindersWorker.current = new CalendarRemindersWoker();
    calendarRemindersWorker.current.addEventListener(
      "message",
      (event: any) => {
        if (DEBUG) {
          console.log(
            "RemindersItemsContainer got back message %o",
            event.data
          );
        }

        if (event.data.reply == COMPUTE_DAYS_WITH_REMINDERS_REPLY) {
          const dateMonth = event.data.payload.month;
          //console.log("received month " + dateMonth + " selected Month" + selectedMonth)
          if (dateMonth != selectedMonth.current) {
            //Porobably a reply from a previous request, we drop this. Can be optimized even further by telling the work to stop
            return;
          }
          const remindersDaysDuringMonth = event.data.payload.daysWithReminders;
          setDaysWithReminders(remindersDaysDuringMonth);
          setIsCalendarLoading(false);
          const date = event.data.payload.date;
          if (
            date.getMonth() != selectedDate.current.getMonth() ||
            date.getFullYear() != selectedDate.current.getFullYear()
          ) {
            if (DEBUG) {
              console.log(
                "Current selected date doesn't match date from reply, will return. Date from reply " +
                  date +
                  " selected date is " +
                  selectedDate.current
              );
            }
            return;
          }

          const aggDataWithRemindersInMonth =
            event.data.payload.aggDataWithRemindersInMonth;
          const remindersCountForTheMonth =
            event.data.payload.remindersCountForTheMonth;
          collectionsForMonth.current = event.data.payload.collections;
          setSelectedMonthRemindersCount(aggDataWithRemindersInMonth.length);
          aggDataForSelectedMonth.current = aggDataWithRemindersInMonth;
          setFoundSharedAggdata(event.data.payload.foundSharedAggdata);

          if (
            selectedDisplayOptionRef.current ==
            SelectedOptionsValues.FOR_SELECTED_MONTH
          ) {
            //updateDisplayedAggData(aggDataWithRemindersInMonth);
            displayedAggDataPreSearch.current = aggDataWithRemindersInMonth;
            setDisplayedAggData(aggDataWithRemindersInMonth);
            setDisplayedItemsCollections(collectionsForMonth.current);
            setIsLoadingReminderItems(false);
            setDisplayNoItemsView(aggDataWithRemindersInMonth.length == 0);
          }
        } else if (event.data.reply == COMPUTE_REMINDERS_FOR_DAY_REPLY) {
          const date: Date = event.data.payload.date;
          if (date.getTime() != selectedDate.current.getTime()) {
            //Porobably a reply from a previous request, we drop this. Can be optimized even further by telling the work to stop
            return;
          }
          const aggDataWithinSelectedDateDay =
            event.data.payload.aggDataWithRemindersInDay;
          aggDataForSelectedDay.current = aggDataWithinSelectedDateDay;
          aggDataForSelectedDaysWeek.current =
            event.data.payload.aggDataWithRemindersInDaysWeek;
          collectionsForWeek.current = event.data.payload.collectionsForWeek;
          collectionsForDay.current = event.data.payload.collectionsForDay;
          selectedWeekDates.current = {
            startDate: event.data.payload.weekStartDate,
            endDate: event.data.payload.weekEndDate,
          };

          if (
            selectedDisplayOptionRef.current ==
            SelectedOptionsValues.FOR_SELECTED_DAY
          ) {
            //updateDisplayedAggData(aggDataWithinSelectedDateDay);
            displayedAggDataPreSearch.current = aggDataWithinSelectedDateDay;
            setDisplayedAggData(aggDataForSelectedDay.current);
            setDisplayedItemsCollections(collectionsForDay.current);
            setIsLoadingReminderItems(false);
            setDisplayNoItemsView(aggDataWithinSelectedDateDay.length == 0);
          } else if (
            selectedDisplayOptionRef.current ==
            SelectedOptionsValues.FOR_SELECTED_WEEK
          ) {
            //updateDisplayedAggData(aggDataForSelectedDaysWeek.current);
            displayedAggDataPreSearch.current =
              aggDataForSelectedDaysWeek.current;
            setDisplayedAggData(aggDataForSelectedDaysWeek.current);
            setDisplayedItemsCollections(collectionsForWeek.current);
            setIsLoadingReminderItems(false);
            setDisplayNoItemsView(
              aggDataForSelectedDaysWeek.current.length == 0
            );
          }
        } else if (event.data.reply == SEARCH_AGGDATA_WITH_KEYWORD_REPLY) {
          const searchKeyword = event.data.payload.keyword;
          if (searchKeyword != searchInputRef.current) {
            //We ignore this result in this case, as there will be probably another one coming soon.
            if (DEBUG) {
              console.log(
                "Will skip result since keyword is " +
                  searchKeyword +
                  " while searchinput is " +
                  searchInput
              );
            }
            return;
          }
          const aggDataArraySearchResult =
            event.data.payload.aggDataArraySearchResult;

          collectionsForSearchResult.current = event.data.payload.collections;

          setDisplayedAggData(aggDataArraySearchResult);
          setDisplayedItemsCollections(collectionsForSearchResult.current);
          setIsLoadingReminderItems(false);
          setSearchResultEmpty(aggDataArraySearchResult.length == 0);
        }
      }
    );
    handleMonthChange(value);
    loadRemindersForDate(value);
    //console.log("initial state " + JSON.stringify(aggDataWithRemindersState))
    return () => {
      calendarRemindersWorker.current.terminate();
    };
  }, [aggDataWithRemindersState.data]);

  React.useEffect(() => {
    handleMonthChange(value);
    loadRemindersForDate(value);
  } , [filterRemindersToCurrentUserData])

  React.useEffect(() => {
    setSearchResultEmpty(false);
    if (calendarRemindersWorker && calendarRemindersWorker.current) {
      searchInputRef.current = searchInput;
      if (
        displayedAggDataPreSearch &&
        displayedAggDataPreSearch.current &&
        displayedAggDataPreSearch.current.length > 0
      ) {
        calendarRemindersWorker.current.postMessage({
          request: SEARCH_AGGDATA_WITH_KEYWORD,
          payload: {
            keyword: searchInput,
            collections: collectionsFilterSelection,
            aggDataArray: displayedAggDataPreSearch.current,

          },
        });
        setIsLoadingReminderItems(true);
      }
    }
  }, [searchInput, displayedAggDataPreSearch, setIsLoadingReminderItems]);

  const updateDisplayedAggData = React.useCallback(
    (aggDataArray: AggDataObj[]) => {
      displayedAggDataPreSearch.current = aggDataArray;
      setDisplayedAggData(aggDataArray);
    },
    [setDisplayedAggData, displayedAggDataPreSearch]
  );

  React.useEffect(() => {
    if (isLoadingReminderItems || aggDataWithRemindersState.loading) {
      return;
    }
    //console.log("tpm new selected displayed option " + selectedDisplayOption);
    selectedDisplayOptionRef.current = selectedDisplayOption;
    switch (selectedDisplayOption) {
      case SelectedOptionsValues.FOR_SELECTED_DAY:
        //console.log("tpm  set FOR_SELECTED_DAY");
        const aggDataForSelectedDayArray = aggDataForSelectedDay.current;
        updateDisplayedAggData(aggDataForSelectedDayArray);
        setDisplayedItemsCollections(collectionsForDay.current);
        setIsLoadingReminderItems(false);
        setDisplayNoItemsView(aggDataForSelectedDayArray.length == 0);
        break;
      case SelectedOptionsValues.FOR_SELECTED_WEEK:
        const aggDataForSelectedDaysWeekArray =
          aggDataForSelectedDaysWeek.current;
        updateDisplayedAggData(aggDataForSelectedDaysWeekArray);
        setDisplayedItemsCollections(collectionsForWeek.current);
        setIsLoadingReminderItems(false);
        setDisplayNoItemsView(aggDataForSelectedDaysWeekArray.length == 0);
        break;
      case SelectedOptionsValues.FOR_SELECTED_MONTH:
        //console.log("tpm  set FOR_SELECTED_MONTH");
        const aggDataForSelectedMonthArray = aggDataForSelectedMonth.current;
        updateDisplayedAggData(aggDataForSelectedMonthArray);
        setDisplayedItemsCollections(collectionsForMonth.current);
        setIsLoadingReminderItems(false);
        setDisplayNoItemsView(aggDataForSelectedMonthArray.length == 0);
        break;
      default:
        break;
    }
  }, [selectedDisplayOption]);

  const handleMonthChange = (date: Date) => {
    if (!aggDataWithRemindersState.data) {
      return;
    }
    selectedMonth.current = date.getMonth();
    calendarRemindersWorker.current.postMessage({
      request: COMPUTE_DAYS_WITH_REMINDERS,
      payload: {
        date: date,
        collections: collectionsFilterSelection,
        aggDataArray:
          aggDataWithRemindersState.data.aggdatasSharedWithReminders,
        filterToCurrentUser: filterRemindersToCurrentUserData,
        currentUserId: currentUser.id,
      },
    });
    //We only display the loading when the month changes
    setIsCalendarLoading(date.getMonth() != value?.getMonth());
  };

  const handleDateChanged = React.useCallback(
    (date: Date) => {
      if (!aggDataWithRemindersState.data) {
        return;
      }
      selectedDisplayOptionRef.current = SelectedOptionsValues.FOR_SELECTED_DAY;
      setSelectedDisplayOption(SelectedOptionsValues.FOR_SELECTED_DAY);
      setDisplayedItemsCollections(collectionsForDay.current);
      setDisplayNoItemsView(false);
      selectedDate.current = date;
      setIsLoadingReminderItems(true);
      dispatch(displayReminderOnAddNewItems(true, selectedDate.current));
      if (
        !aggDataWithRemindersState.loading &&
        !aggDataWithRemindersState.error
      ) {
        calendarRemindersWorker.current.postMessage({
          request: COMPUTE_REMINDERS_FOR_DAY,
          payload: {
            date: date,
            collections: collectionsFilterSelection,
            aggDataArray:
              aggDataWithRemindersState.data.aggdatasSharedWithReminders,
              filterToCurrentUser: filterRemindersToCurrentUserData,
              currentUserId: currentUser.id,
          },
        });
      }
    },
    [
      aggDataWithRemindersState,
      filterRemindersToCurrentUserData,
      calendarRemindersWorker,
      collectionsFilterSelection,
      selectedDisplayOptionRef,
      collectionsForDay,
    ]
  );

  const loadRemindersForDate = React.useCallback(
    (date: Date) => {
      if (!aggDataWithRemindersState.data) {
        return;
      }
      setDisplayNoItemsView(false);
      selectedDate.current = date;
      setIsLoadingReminderItems(
        (aggDataWithRemindersState.loading &&
          aggDataWithRemindersState.data.aggdatasSharedWithReminders.length ==
            0) ||
          isLoadingReminderItems
      );
      if (
        !aggDataWithRemindersState.loading &&
        !aggDataWithRemindersState.error
      ) {
        calendarRemindersWorker.current.postMessage({
          request: COMPUTE_REMINDERS_FOR_DAY,
          payload: {
            date: date,
            collections: collectionsFilterSelection,
            aggDataArray:
              aggDataWithRemindersState.data.aggdatasSharedWithReminders,
              filterToCurrentUser: filterRemindersToCurrentUserData,
              currentUserId: currentUser.id,
          },
        });
      }
    },
    [aggDataWithRemindersState, filterRemindersToCurrentUserData, calendarRemindersWorker]
  );

  const getContextualDate = React.useCallback((): string => {
    if (!value) {
      return "";
    }
    var result;
    if (isDateFromToday(value)) {
      result = t("reminders_container_title_today", {
        date: formatToContextualDate(value),
      });
    } else if (isDateFromTomorrow(value)) {
      result = t("reminders_container_title_tomorrow", {
        date: formatToContextualDate(value),
      });
    } else if (isDateFromYesterday(value)) {
      result = t("reminders_container_title_yesterday", {
        date: formatToContextualDate(value),
      });
    } else {
      result = t("reminders_container_title_generic", {
        date: formatToContextualDate(value),
      });
    }
    result = result;
    return result;
  }, [value, aggDataForSelectedDate]);

  React.useEffect(() => {
    if (!value) {
      return;
    }
    if (selectedDisplayOption == SelectedOptionsValues.FOR_SELECTED_DAY) {
      setTitle(getContextualDate());
    } else if (
      selectedDisplayOption == SelectedOptionsValues.FOR_SELECTED_WEEK
    ) {
      setTitle(
        t("reminders_container_title_week", {
          date: formatToContextualDateIntervalForWeek(
            selectedWeekDates.current?.startDate,
            selectedWeekDates.current?.endDate
          ),
        })
      );
    } else if (
      selectedDisplayOption == SelectedOptionsValues.FOR_SELECTED_MONTH
    ) {
      setTitle(
        t("reminders_container_title_month", {
          date: getMonthFromMonthNumber(value.getMonth() + 1),
        })
      );
    }
    //const currentDate = new Date();
  }, [
    selectedDisplayOption,
    value,
    aggDataForSelectedDate,
    selectedWeekDates,
    value,
  ]);

  React.useEffect(() => {
    if (lastSelectedDate.current.getMonth() != value?.getMonth()) {
      console.log("Month has changed to " + value);
      lastSelectedDate.current = value;
      handleMonthChange(value);
    }
    lastSelectedDate.current = value;
  }, [value]);

  const getRenderedSelectValue = React.useCallback(
    (value: string) => {
      switch (value) {
        case SelectedOptionsValues.FOR_SELECTED_DAY:
          return (
            <MenuContainer>
              <Typography
                style={selectedTextStyles}
                component="h4"
                variant="h4"
                color="text.primary"
              >
                ⏰ {title}
              </Typography>
              {!isLoadingReminderItems && (
                <Typography
                  style={{ marginLeft: "8px" }}
                  component="h4"
                  variant="h4"
                  color="text.secondary"
                >
                  ({aggDataForSelectedDay.current.length})
                </Typography>
              )}
            </MenuContainer>
          );
        case SelectedOptionsValues.FOR_SELECTED_MONTH:
          return (
            <MenuContainer>
              <Typography
                style={selectedTextStyles}
                component="h4"
                variant="h4"
                color="text.primary"
              >
                ⏰ {title}
              </Typography>
              {!isLoadingReminderItems && (
                <Typography
                  style={{ marginLeft: "8px" }}
                  component="h4"
                  variant="h4"
                  color="text.secondary"
                >
                  ({selectedMonthRemindersCount})
                </Typography>
              )}
            </MenuContainer>
          );
        case SelectedOptionsValues.FOR_SELECTED_WEEK:
          return (
            <MenuContainer>
              <Typography
                style={selectedTextStyles}
                component="h4"
                variant="h4"
                color="text.primary"
              >
                ⏰ {title}
              </Typography>
              {!isLoadingReminderItems && (
                <Typography
                  style={{ marginLeft: "8px" }}
                  component="h4"
                  variant="h4"
                  color="text.secondary"
                >
                  ({aggDataForSelectedDaysWeek.current.length})
                </Typography>
              )}
            </MenuContainer>
          );
      }
    },
    [
      isLoadingReminderItems,
      aggDataForSelectedDay,
      title,
      selectedMonthRemindersCount,
      aggDataForSelectedDaysWeek,
      aggDataForSelectedMonth,
    ]
  );

  const handleImageAggDataClicked = React.useCallback(
    (currentAggDataImg: AggDataObj) => {
      const imagesAggData = displayedAggData.filter(
        (aggData: AggDataObj) => aggData.dataType == DataType.IMAGE_TYPE
      );
      //console.log("resultik images sent %o", imagesAggData)
      dispatch(
        displayImageViewer(imagesAggData, currentAggDataImg, "⏰" + title)
      );
    },
    [displayedAggData, title]
  );

  const handleFilterSelectionChanged = React.useCallback(
    (selectedValues: string[]) => {
      if (!aggDataWithRemindersState.data) {
        return;
      }
      //apply the filter here
      console.log(
        "Filter selection changed to " + JSON.stringify(selectedValues)
      );
      setCollectionsFilterSelection(selectedValues);
      switch (selectedDisplayOption) {
        case SelectedOptionsValues.FOR_SELECTED_DAY:
        case SelectedOptionsValues.FOR_SELECTED_WEEK:
        case SelectedOptionsValues.FOR_SELECTED_MONTH:
          setIsLoadingReminderItems(true);
          calendarRemindersWorker.current.postMessage({
            request: COMPUTE_REMINDERS_FOR_DAY,
            payload: {
              date: selectedDate.current,
              collections: selectedValues, //filter
              aggDataArray:
                aggDataWithRemindersState.data.aggdatasSharedWithReminders,
                filterToCurrentUser: filterRemindersToCurrentUserData,
                currentUserId: currentUser.id,
              },
          });

          //setIsLoadingReminderItems(true);
          calendarRemindersWorker.current.postMessage({
            request: COMPUTE_DAYS_WITH_REMINDERS,
            payload: {
              date: selectedDate.current,
              collections: selectedValues, //filter
              aggDataArray:
                aggDataWithRemindersState.data.aggdatasSharedWithReminders,
              filterToCurrentUser: filterRemindersToCurrentUserData,
              currentUserId: currentUser.id,
            },
          });
          break;
        default:
          break;
      }
    },
    [
      setCollectionsFilterSelection,
      filterRemindersToCurrentUserData,
      selectedDisplayOption,
      calendarRemindersWorker,
      selectedDate,
      aggDataWithRemindersState.data,
    ]
  );

  if (aggDataWithRemindersState.error) {
    return <ErrorLoading></ErrorLoading>;
  }

  return (
    <MainContainer>
      {true && (
        <TitleContainer>
          <FormControl style={{ width: "40%" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedDisplayOption}
              onChange={(event: SelectChangeEvent) => {
                selectedDisplayOptionRef.current = event.target.value; //need to update asap, because on next render this should be set to the right value
                setSelectedDisplayOption(event.target.value);
              }}
              renderValue={(value: string) => {
                return getRenderedSelectValue(value);
              }}
              inputProps={{
                "aria-label": "Without label",
              }}
            >
              <MenuItem value={SelectedOptionsValues.FOR_SELECTED_DAY}>
                <MenuContainer>
                  <Typography
                    style={selectedTextStyles}
                    component="h4"
                    variant="h4"
                    color="text.primary"
                  >
                    {getContextualDate()}
                  </Typography>
                  {!isLoadingReminderItems && (
                    <Typography
                      style={{ marginLeft: "8px" }}
                      component="h4"
                      variant="h4"
                      color="text.secondary"
                    >
                      ({aggDataForSelectedDay.current.length})
                    </Typography>
                  )}
                </MenuContainer>
              </MenuItem>
              <MenuItem value={SelectedOptionsValues.FOR_SELECTED_WEEK}>
                <MenuContainer>
                  <Typography
                    style={selectedTextStyles}
                    component="h4"
                    variant="h4"
                    color="text.primary"
                  >
                    {t("reminders_container_title_week", {
                      date: formatToContextualDateIntervalForWeek(
                        selectedWeekDates.current?.startDate,
                        selectedWeekDates.current?.endDate
                      ),
                    })}
                  </Typography>
                  {!isLoadingReminderItems && (
                    <Typography
                      style={{ marginLeft: "8px" }}
                      component="h4"
                      variant="h4"
                      color="text.secondary"
                    >
                      ({aggDataForSelectedDaysWeek.current.length})
                    </Typography>
                  )}
                </MenuContainer>
              </MenuItem>
              <MenuItem value={SelectedOptionsValues.FOR_SELECTED_MONTH}>
                <MenuContainer>
                  <Typography
                    style={selectedTextStyles}
                    component="h4"
                    variant="h4"
                    color="text.primary"
                  >
                    {t("reminders_container_title_month", {
                      date: getMonthFromMonthNumber(value.getMonth() + 1),
                    })}
                  </Typography>
                  {!isLoadingReminderItems && (
                    <Typography
                      style={{ marginLeft: "8px" }}
                      component="h4"
                      variant="h4"
                      color="text.secondary"
                    >
                      ({selectedMonthRemindersCount})
                    </Typography>
                  )}
                </MenuContainer>
              </MenuItem>
            </Select>
          </FormControl>
        </TitleContainer>
      )}
      <BodyContainer>
        <AggDataMainContainer>
          <div style={{ marginLeft: "32px" }}>
            {(displayedItemsCollections.length > 0 ||
              collectionsFilterSelection.length > 0) && (
              <SimpleFilter
                values={displayedItemsCollections}
                inputHint={t("filter_collection_input_hint")}
                allSelectedText={t("filter_all_collections_selected_text")}
                onSelectionChanged={handleFilterSelectionChanged}
              />
            )}
          </div>
          <AggDataContainer>
            {isLoadingReminderItems && (
              <LoadingItems rowsCount={4}></LoadingItems>
            )}
            {!isLoadingReminderItems && (
              <AggDataRemindersContainer
                aggDataWithReminders={displayedAggData}
                date={new Date()}
                onImageAggDataClicked={handleImageAggDataClicked}
              ></AggDataRemindersContainer>
            )}
            {!aggDataWithRemindersState.loading && displayNoItemsView && (
              <RemindersEmpty date={value} />
            )}
            {searchResultEmpty && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SearchResultsEmpty />
              </div>
            )}
          </AggDataContainer>
        </AggDataMainContainer>

        <CalendarContainer>
          {foundSharedAggdata && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginRight: "8px",
                paddingRight: "8px",
                paddingTop: "8px",
              }}
            >
              <SharedUsersFilterControl
                handleOptionChanged={(displaySharedItems: boolean) => {
                  setFilterRemindersToCurrentUserData(displaySharedItems)
                }}
              ></SharedUsersFilterControl>
            </div>
          )}
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={value}
                loading={isCalendarLoading}
                onChange={(newValue) => {
                  if (!newValue) {
                    return;
                  }
                  setValue(newValue);
                  handleDateChanged(newValue);
                }}
                minDate={new Date(2018, 0, 0, 0, 0, 0)}
                onMonthChange={handleMonthChange}
                onYearChange={handleMonthChange}
                renderInput={(params) => <TextField {...params} />}
                renderLoading={() => <CalendarPickerSkeleton />}
                renderDay={(day, _value, DayComponentProps) => {
                  //console.log("Render day for " + day)
                  const isSelected = daysWithReminders.includes(day.getDate());

                  var backgroundColor = colorMementoBeige;
                  if (
                    selectedDisplayOption ==
                    SelectedOptionsValues.FOR_SELECTED_WEEK
                  ) {
                    const weekStartDate = selectedWeekDates.current.startDate;
                    const weekEndDate = selectedWeekDates.current.endDate;
                    if (
                      weekEndDate.getDate() < weekStartDate.getDate() &&
                      weekEndDate.getMonth() == weekStartDate.getMonth() + 1
                    ) {
                      if (
                        (weekStartDate.getDate() <= day.getDate() &&
                          weekStartDate.getMonth() == day.getMonth()) ||
                        (weekEndDate.getDate() >= day.getDate() &&
                          weekEndDate.getMonth() == day.getMonth())
                      ) {
                        backgroundColor = colorMemento;
                      }
                    } else if (
                      selectedWeekDates.current.startDate.getDate() <=
                        day.getDate() &&
                      selectedWeekDates.current?.endDate.getDate() >=
                        day.getDate()
                    ) {
                      backgroundColor = colorMemento;
                    } else {
                      backgroundColor = colorMementoBeige;
                    }
                  } else if (
                    selectedDisplayOption ==
                      SelectedOptionsValues.FOR_SELECTED_MONTH &&
                    value?.getMonth() == day.getMonth()
                  ) {
                    backgroundColor = colorMemento;
                  }
                  return (
                    <Badge
                      key={day.toString()}
                      overlap="circular"
                      badgeContent={isSelected ? undefined : undefined}
                    >
                      <PickersDay
                        {...DayComponentProps}
                        sx={{
                          backgroundColor: isSelected
                            ? backgroundColor
                            : "transparent",
                          borderWidth: isSelected ? "1px" : "0px",
                          borderRadius: "50%",
                          borderStyle: "solid",

                          borderColor: colorMemento,
                        }}
                      />
                    </Badge>
                  );
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </CalendarContainer>
      </BodyContainer>
    </MainContainer>
  );
};

export default React.memo(RemindersItemsContainer);

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getUserAvatarForUsername } from "configuration";
import {
  EMAIL_BUTTON,
  SOCIAL_MEDIA_URLS,
} from "pages/Settings/SettingsSections/PublicProfileSettings";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styledTooltipTitle } from "shared/styles";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useMutation } from "@apollo/client";
import { deleteUiElementGql, updatePublicProfileGql } from "graphql/mutations";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/actions/view";
import PublicCollectionsContainer from "components/CollectionItems/public/PublicCollectionsContainer";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { ButtonItem, PublicProfile } from "models/user";
import UploadProfileImage from "./UploadProfileImage";
import { stringToColour } from "shared/colors";
import { checkIfYoutubeVideoUrl } from "shared/dataUtils";
import ConfigureYoutubePresentationVideoModal from "components/UI/Modals/ConfigureYoutubePresentationVideoModal";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ColorPalette from "components/ColorPalette/ColorPalette";
import PublicProfileColorPalette from "components/ColorPalette/PublicProfileColorPalette";
import { useHistory } from "react-router-dom";
import { GENERAL_SETTINGS } from "navigation/Constants";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AddPublicProfileButtonModal from "components/UI/Modals/AddPublicProfileButtonModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";
import Draggable from "components/Draggable/Draggable";
import update from "immutability-helper";
import ImportExportIcon from "@mui/icons-material/ImportExport";

interface PublicUserProfilePreviewProps {
  onOpenConfigureSocialMediaLinks?: () => void | null;
  username: string;
  publicProfile: PublicProfile;
  readonly?: boolean;
}

const PublicUserProfilePreview = ({
  username,
  publicProfile,
  onOpenConfigureSocialMediaLinks,
  readonly = true,
}: PublicUserProfilePreviewProps) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const history = useHistory();
  const [
    colorPaletteAnchorEl,
    setColorPaletteAnchorEl,
  ] = React.useState<HTMLInputElement | null>(null);

  const [userAvatarUrl, setUserAvatarUrl] = React.useState(
    getUserAvatarForUsername(username)
  );
  const [backgroundColor, setBackgroundColor] = React.useState<string>(
    publicProfile.background
  );
  const [
    openEditYoutubePresentationUrl,
    setOpenEditYoutubePresentationUrl,
  ] = React.useState(false);
  const [openAddButtonModal, setOpenAddButtonModal] = React.useState(false);
  const [
    displayDeleteConfirmationModal,
    setDisplayDeleteConfirmationModal,
  ] = React.useState(false);
  const [editUiItem, setEditUiItem] = React.useState<ButtonItem | null>(null);
  const [editProfilePictureSrc, setEditProfilePicture] = React.useState<
    string | null
  >(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [
    editPublicProfileDescription,
    setEditPublicProfileDescription,
  ] = React.useState(false);
  const [
    publicProfileDescriptionInputValue,
    setPublicProfileDescriptionInputValue,
  ] = React.useState(publicProfile.description);
  const [
    publicProfileDescription,
    setPublicProfileDescription,
  ] = React.useState(publicProfile.description);
  const [uiItems, setUiItems] = React.useState<ButtonItem[] | null>(null);

  const [updatePublicProfile, updatePublicProfileResult] = useMutation(
    updatePublicProfileGql
  );

  const uiElementToDeleteId = React.useRef<string | null>(null);
  const [deleteUiElement, deleteUiElementResult] = useMutation(
    deleteUiElementGql
  );

  React.useEffect(() => {
    if (publicProfile.uiItems) {
      let uiItemsAsJson = JSON.parse(publicProfile.uiItems);
      setUiItems(uiItemsAsJson);
    }
  }, [publicProfile]);

  const avatarColor = stringToColour(username);

  const handleSavePublicProfileDescription = React.useCallback(() => {
    if (publicProfileDescriptionInputValue == publicProfileDescription) {
      setEditPublicProfileDescription(false);
      return; //no need to make an update
    }
    updatePublicProfile({
      variables: {
        input: {
          description: publicProfileDescriptionInputValue,
        },
      },
    })
      .then((result) => {
        if (
          result.data &&
          result.data.updatePublicUserProfile &&
          result.data.updatePublicUserProfile.publicProfile
        ) {
          setPublicProfileDescription(
            result.data.updatePublicUserProfile.publicProfile.description
          );
          setEditPublicProfileDescription(false);
        }
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("generic_failure_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [publicProfileDescriptionInputValue, publicProfileDescription]);

  function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setEditProfilePicture(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleDeletePresentationVideo = React.useCallback(() => {
    updatePublicProfile({
      variables: {
        input: {
          presentationVideoUrl: "",
        },
      },
    })
      .then((result) => {})
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("generic_failure_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, []);

  const handleColorChange = React.useCallback(
    (newColor: string, previousColor: string) => {
      updatePublicProfile({
        variables: {
          input: {
            background: newColor,
          },
        },
      })
        .then((result) => {})
        .catch((e) => {
          setBackgroundColor(previousColor); //We revert the change in case of failure to apply to backend
          dispatch(
            showSnackbar({
              message: t("generic_failure_msg"),
              action: "",
              onAction: null,
              loading: false,
            })
          );
        });
    },
    []
  );

  const handleDeleteUiElement = React.useCallback((uiElementId: string) => {
    deleteUiElement({
      variables: {
        elementId: uiElementId,
      },
    });
  }, []);

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number): void => {
      if (!uiItems) {
        return;
      }
      const dragUiItem = uiItems[dragIndex];
      /* if (dragNoteTask.done) {
        return;
      } */
      const updatedArray = update(uiItems, {
        $splice: [
          [dragIndex, 1], // Delete the item item at the drag start index
          [hoverIndex, 0, dragUiItem], // Add the item where it's being dragged by the user.
        ],
      });
      setUiItems(updatedArray);
      //onNoteTasksChange && onNoteTasksChange(updatedArray);
    },
    [uiItems, setUiItems]
  );

  const handleDrop = useCallback((): void => {
    updatePublicProfile({
      variables: {
        input: {
          uiItems: JSON.stringify(uiItems),
        },
      },
    })
      .then((result) => {})
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("generic_failure_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [uiItems]);

  return (
    <Box
      sx={{
        minHeight: "95vh",
        display: "flex",
        marginBottom: "1vh",
        flexDirection: "column",
        justifyContent: "start",
        width: readonly ? "40%" : "60%",
        alignItems: "center",
        padding: "8px",
        marginTop: "16px",
        /*         boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
         */ borderRadius: "8px",
        background: backgroundColor,
        "@media (max-width: 1280px)": {
          width: "60%",
          paddingTop: "32px",
        },
        "@media (max-width: 780px)": {
          width: "95%",
          paddingTop: "32px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          width: "100%",
          position: "relative",
        }}
      >
        {!readonly && (
          <>
            <Tooltip
              title={styledTooltipTitle(t("pick_public_profile_background"))}
            >
              <IconButton
                onClick={(e) => {
                  setColorPaletteAnchorEl(e.target);
                }}
              >
                <ColorLensIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </IconButton>
            </Tooltip>
            <PublicProfileColorPalette
              anchorEl={colorPaletteAnchorEl}
              open={Boolean(colorPaletteAnchorEl)}
              onClose={() => {
                setColorPaletteAnchorEl(null);
              }}
              onColorSelected={(color) => {
                handleColorChange(color, backgroundColor);

                setBackgroundColor(color);
              }}
              selectedColor={backgroundColor}
            />
          </>
        )}
      </Box>

      <Avatar
        //onClick={handleClick}
        //className={classes.avatarNotHovered}
        color="red"
        src={userAvatarUrl}
        style={{
          border: "thick double #fff",
          fontSize: "3rem",
          width: "96px",
          height: "96px",
          cursor: "pointer",
          //filter: avatarFilter,
          backgroundColor: avatarColor?.backgroundColor,
          color: avatarColor?.textColor,
          // backgroundColor: theme.palette.primary.main,
        }}
      >
        .
      </Avatar>
      {!readonly && (
        <Button
          onClick={() => {
            hiddenFileInput?.current?.click();
          }}
          sx={{
            color: "gray",
          }}
        >
          {t("edit_action")}
        </Button>
      )}

      <input
        ref={hiddenFileInput}
        accept="image/*"
        onChange={onFileInputChange}
        type="file"
        style={{ display: "none" }}
      />

      <UploadProfileImage
        open={Boolean(editProfilePictureSrc)}
        handleModalClose={(imgChanged) => {
          setEditProfilePicture(null);
          if (imgChanged) {
            setUserAvatarUrl(
              getUserAvatarForUsername(username) + "?" + Date.now()
            );
          }
        }}
        src={editProfilePictureSrc}
      />

      <Box
        sx={{
          marginTop: "16px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: "3.4rem",
          }}
        >
          {username}
        </Typography>
        {!readonly && (
          <Tooltip
            title={styledTooltipTitle(t("edit_username_public_profile_hint"))}
          >
            <IconButton
              sx={{
                width: "34px",
                height: "34px",
              }}
              onClick={() => {
                history.push(GENERAL_SETTINGS);
              }}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {SOCIAL_MEDIA_URLS.map((item, index) => {
          const json = JSON.parse(publicProfile.socialMediaUrls);
          var url = json[item.key];
          if (readonly) {
            if (!url) {
              return <></>;
            }
          } else {
            if (index > 4) {
              //we don't to display all the icons, only few of them
              return <></>;
            }
          }
          return (
            <IconButton
              onClick={() => {
                if (publicProfile) {
                  if (!url) {
                    return;
                  }
                  if (item.id == EMAIL_BUTTON) {
                    window.open("mailto:" + url, "_blank");
                  } else {
                    if (
                      !url.startsWith("https://") &&
                      !url.startsWith("http://")
                    ) {
                      url = "https://" + url;
                    }
                    window.open(url, "_blank");
                  }
                }
              }}
            >
              {item.icon}
            </IconButton>
          );
        })}
        {!readonly && (
          <Tooltip
            title={styledTooltipTitle(
              t("configure_your_social_media_links_description")
            )}
          >
            <IconButton
              sx={{
                width: "34px",
                height: "34px",
              }}
              onClick={onOpenConfigureSocialMediaLinks}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "90%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!editPublicProfileDescription && (
          <>
            {publicProfileDescription && (
              <Typography
                sx={{
                  fontSize: "1.8rem",
                  textAlign: "center",
                  width: "70%",
                  "@media (max-width: 780px)": {
                    width: "85%",
                  },
                }}
              >
                {publicProfileDescription}
              </Typography>
            )}
            {!publicProfileDescription && !readonly && (
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  textAlign: "center",
                  fontStyle: "italic",
                  "@media (max-width: 780px)": {
                    width: "85%",
                  },
                }}
              >
                {t("public_profile_hint_text")}
              </Typography>
            )}
            {!readonly && (
              <Tooltip
                title={styledTooltipTitle(
                  t("public_profile_edit_description_hint")
                )}
              >
                <IconButton
                  sx={{
                    width: "34px",
                    height: "34px",
                  }}
                  onClick={() => {
                    //setPublicProfileDescriptionInputValue(username);
                    setEditPublicProfileDescription(true);
                  }}
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}

        {editPublicProfileDescription && (
          <>
            <TextField
              //ref={publicProfileDescriptionRef}
              fullWidth
              multiline
              variant="standard"
              helperText={t("public_profile_hint_text")}
              value={publicProfileDescriptionInputValue}
              sx={{
                "& .MuiFormHelperText-root": {
                  fontSize: "1.2rem",
                },
              }}
              InputProps={{
                style: { fontSize: "1.8rem", width: "100%" },
                readOnly:
                  updatePublicProfileResult &&
                  updatePublicProfileResult.loading,
                /*                     disableUnderline: true,
                 */
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPublicProfileDescriptionInputValue(event.target.value);
              }}
            />

            {!updatePublicProfileResult.loading && (
              <Tooltip title={styledTooltipTitle(t("save_action"))}>
                <IconButton
                  onClick={() => {
                    handleSavePublicProfileDescription();
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            )}
            {updatePublicProfileResult.loading && (
              <CircularProgress
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            )}
            <Tooltip title={styledTooltipTitle(t("cancel").toUpperCase())}>
              <IconButton
                onClick={() => {
                  setEditPublicProfileDescription(false);
                }}
              >
                <HighlightOffOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>

      {!readonly && !publicProfile.presentationVideoUrl && (
        <>
          <Button
            variant="outlined"
            sx={{
              border: "1px dashed grey",
              color: "grey",
              marginTop: "16px",
              fontSize: "1.2rem",
            }}
            onClick={() => {
              setOpenEditYoutubePresentationUrl(true);
            }}
          >
            <YouTubeIcon sx={{ color: "#FF0000", marginRight: "8px" }} />
            {t("add_youtube_presentation_video_button")}
          </Button>
        </>
      )}

      {publicProfile.presentationVideoUrl && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          <iframe
            style={{
              maxWidth: "100%",
            }}
            width="470"
            height="345"
            src={checkIfYoutubeVideoUrl(publicProfile.presentationVideoUrl)}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          {!readonly && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  setOpenEditYoutubePresentationUrl(true);
                }}
                sx={{
                  color: "gray",
                }}
              >
                {t("edit_action")}
              </Button>
              <Button
                onClick={handleDeletePresentationVideo}
                sx={{
                  color: "gray",
                }}
              >
                {t("action_delete")}
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          alignItems: "center",
          "@media (max-width: 780px)": {
            padding: "0px",
          },
        }}
      >
        {!readonly && uiItems && uiItems.length > 1 && (
          <Typography
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {t("public_profile_buttons_drag_n_drop_hint")} <ImportExportIcon />
          </Typography>
        )}

        {uiItems &&
          uiItems.length > 0 &&
          uiItems.map((uiItem: ButtonItem, index) => {
            if (uiItem.type == "button") {
              return (
                <Draggable
                  handleMove={handleMove}
                  handleDrop={handleDrop}
                  index={index}
                  id={uiItem.id}
                  key={uiItem.id}
                >
                  <Box
                    key={uiItem.id}
                    sx={{
                      display: "flex",
                      position: "relative",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "80px",
                      border: "1px solid black",
                      borderRadius: "8px",
                      cursor: "pointer",
                      background: uiItem.background,
                      overflow: "none",
                      padding: "4px",
                      marginTop: "8px",
                      ":hover": {
                        boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5);",
                        background: "transparent",
                        "-webkit-transition": "background 1s" /* For Safari */,
                        transition: "background 1s",
                      },
                    }}
                    onClick={() => {
                      window.open(uiItem.url, "_blank");
                    }}
                  >
                    {uiItem.imgUrl && (
                      <Avatar
                        //onClick={handleClick}
                        //className={classes.avatarNotHovered}
                        color="red"
                        src={uiItem.imgUrl}
                        style={{
                          border: "thick double #fff",
                          fontSize: "3rem",
                          width: "60px",
                          height: "60px",
                          cursor: "pointer",
                          marginLeft: "8px",
                          // backgroundColor: theme.palette.primary.main,
                        }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontSize: "1.6rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        flex: "1 1 auto",
                        textAlign: "center",
                        alignContent: "center",
                        alignItems: "center",
                        height: "100%",
                        overflow: "hidden",
                      }}
                    >
                      {uiItem.title}
                    </Typography>

                    {!readonly && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Tooltip title={styledTooltipTitle(t("edit_action"))}>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditUiItem(uiItem);
                            }}
                          >
                            <ModeEditOutlineOutlinedIcon
                              sx={{
                                width: "16px",
                                height: "16px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={styledTooltipTitle(t("action_delete"))}>
                          <IconButton
                            sx={{
                              width: "34px",
                              height: "34px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              uiElementToDeleteId.current = uiItem.id;
                              setDisplayDeleteConfirmationModal(true);
                            }}
                          >
                            <DeleteOutlineIcon
                              sx={{
                                width: "16px",
                                height: "16px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Draggable>
              );
            }
          })}
      </Box>
      {!readonly && (
        <>
          <Button
            variant="outlined"
            sx={{
              border: "1px dashed grey",
              color: "grey",
              marginTop: "16px",
              fontSize: "1.2rem",
            }}
            onClick={() => {
              setOpenAddButtonModal(true);
            }}
          >
            {t("add_button_action")}
          </Button>
        </>
      )}

      <ConfigureYoutubePresentationVideoModal
        open={openEditYoutubePresentationUrl}
        handleModalClose={() => {
          setOpenEditYoutubePresentationUrl(false);
        }}
        presentationUrl={publicProfile.presentationVideoUrl}
      />
      {openAddButtonModal && (
        <AddPublicProfileButtonModal
          open={openAddButtonModal}
          handleModalClose={() => {
            setOpenAddButtonModal(false);
          }}
        />
      )}
      {editUiItem && (
        <AddPublicProfileButtonModal
          open={Boolean(editUiItem)}
          handleModalClose={() => {
            setEditUiItem(null);
          }}
          buttonItem={editUiItem}
        />
      )}

      {displayDeleteConfirmationModal && (
        <SimpleConfirmationDialog
          open={displayDeleteConfirmationModal}
          title={t("delete_button_confirmation_title")}
          description={t("delete_button_confirmation_description")}
          handleOk={() => {
            handleDeleteUiElement(uiElementToDeleteId.current);
            setDisplayDeleteConfirmationModal(false);
            uiElementToDeleteId.current = null;
          }}
          handleModalClose={() => {
            setDisplayDeleteConfirmationModal(false);
          }}
        />
      )}

      <Box
        sx={{
          display: "flex",
          overflowX: "none",
          width: "100%",
        }}
      >
        <PublicCollectionsContainer username={username} readonly={readonly} />
      </Box>
    </Box>
  );
};

export default React.memo(PublicUserProfilePreview);

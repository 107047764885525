import React, { useCallback, useRef } from "react";

import Modal from "@mui/material/Modal";
import { Box, Fade, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { isUrlValid } from "shared/dataUtils";
import { updatePublicProfileGql } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/actions/view";
import {
  EMAIL_BUTTON,
  SOCIAL_MEDIA_URLS,
  SocialMediaItem,
} from "pages/Settings/SettingsSections/PublicProfileSettings";
import { isEmailValid } from "shared/utility";

interface ConfigureSocialMediaLinksProps {
  open: boolean;
  handleModalClose: (open: boolean) => void;
  socialMediaUrlsJsonString: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      height: "80%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("mobile")]: {
        width: "90%",
      },
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "16px",
      flex: "1 1 0",
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
  })
);

const ConfigureSocialMediaLinks = ({
  open,
  handleModalClose,
  socialMediaUrlsJsonString,
}: ConfigureSocialMediaLinksProps) => {
  const classes = useStyles();
  const updatedUrls = React.useRef<any>();
  const [updatePublicProfile, updatePublicProfileResult] = useMutation(
    updatePublicProfileGql
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const socialMediaUrlsJson = JSON.parse(socialMediaUrlsJsonString);
  const handleSave = React.useCallback(() => {
    if (updatedUrls.current) {
      updatePublicProfile({
        variables: {
          input: {
            socialMediaUrls: JSON.stringify(updatedUrls.current),
          },
        },
      })
        .then((result) => {
          handleModalClose(false);
        })
        .catch((e) => {
          dispatch(
            showSnackbar({
              message: t("generic_failure_msg"),
              action: "",
              onAction: null,
              loading: false,
            })
          );
        });
    } else {
      handleModalClose(false);
    }
  }, [updatedUrls, updatedUrls.current, updatePublicProfileResult]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        onClick={(e) => {
          e.stopPropagation(); //useful because for some reason when you click outside to close the modal the click still propagates to components underneath
        }}
      >
        <Fade in={open}>
          <div
            className={classes.paper}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                  paddingRight: "4px",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: 5,
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#C2C2C2",
                    borderRadius: "8px",
                  },
                }}
              >
                {SOCIAL_MEDIA_URLS.map((item) => {
                  return (
                    <UrlItem
                      key={item.key}
                      item={item}
                      defaultValue={socialMediaUrlsJson[item.key]}
                      onInputChanged={(input: string) => {
                        updatedUrls.current = {
                          ...updatedUrls.current,
                          [item.key]: input,
                        };
                      }}
                    />
                  );
                })}
              </Box>
              <LoadingButton
                sx={{
                  marginRight: "16px",
                  marginTop: "8px",
                }}
                loading={updatePublicProfileResult.loading}
                variant="contained"
                onClick={handleSave}
              >
                {t("save_action")}
              </LoadingButton>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

interface UrlItemProps {
  item: SocialMediaItem;
  onInputChanged: (input: string) => void;
  defaultValue: string;
}

const UrlItem = ({ item, defaultValue, onInputChanged }: UrlItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "8px",
        marginBottom: "8px",
      }}
    >
      <Typography
        sx={{
          color: "black",
          fontSize: "1.6rem",
        }}
      >
        {item.title}
      </Typography>

      <TextField
        variant="outlined"
        fullWidth
        className={classes.inputLabel}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        defaultValue={defaultValue}
        onChange={(e) => {
          // setPreviewImageUrl(e.target.value);
          let value = e.target.value.trim();

          if (item.id == EMAIL_BUTTON) {
            if (!isEmailValid(value) && value.length > 0) {
              setErrorMessage(t("email_not_valid_error"));
            } else {
              setErrorMessage(null);
            }
          } else {
            if (!isUrlValid(value) && value.length > 0) {
              setErrorMessage(t("url_not_valid_error"));
            } else {
              setErrorMessage(null);
            }
          }
          onInputChanged(e.target.value);
        }}
        //defaultValue={aggdata.annotation}
        color="primary"
        inputProps={{
          style: {
            fontSize: "large",
            height: "100%",
            lineHeight: "normal",
          },
        }}
        InputProps={{
          startAdornment: <Box sx={{ marginRight: "8px" }}>{item.icon}</Box>,
        }}
        InputLabelProps={{
          style: { fontSize: "medium" },
        }}
      />
    </Box>
  );
};

export default React.memo(ConfigureSocialMediaLinks);

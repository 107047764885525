import React, { useEffect, useState } from "react";
import Logo from "icons/favicon.png";
import { addNewDataGql } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { useApolloClient, ApolloLink } from "@apollo/client";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Theme, createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { colorWhiteNuanced } from "shared/colors";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";

// function test3(){
//   chrome.tabs.update({active: true, url: 'http://localhost:3000/'});

// }

const AUTH_TOKEN_STORAGE_KEY = "authToken";
const CSRF_TOKEN_STORAGE_KEY = "token";
/**
 * Notes :
 *  - To visualize the printed logs, right click on the pop that displays when clicking on extension, and inspect.
 *
 */

function handleClickAway() {
  console.log("HAndle click away");
}

export const AggDataTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  width: 100%;
  padding: 12px 12px 0 12px;
`;

export const AggDataContent = styled.div`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 0 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
`;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: "8px",
    padding: "4px",
    borderRadius: "5px",
    //backgroundColor: theme.palette.background.paper,
  },

  dataContainerPaper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  container2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "4px",
    marginRight: "4px",
  },
  modal: {
    top: "10px",
    right: "10px",
  },
  logo: {
    width: "30px",
    height: "30px",
    padding: "12px",
  },

  circularProgress: {
    padding: "4px",
    position: "absolute",
    right: "4px",
  },
}));

const prefersDarkMode = false;

const theme = createTheme({
  palette: {
    secondary: {
      main: "#E33E7F",
    },
    primary: {
      main: "#FDC84B",
    },
    type: prefersDarkMode ? "dark" : "light",
  },
});

const ExtensionDev = () => {
  const [url, setUrl] = useState<string>("");
  const [displayCollectionsContainer, setDisplayCollectionsContainer] =
    React.useState(false);
  const [addNewData, { loading, data }] = useMutation(addNewDataGql);
  const client = useApolloClient();
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <div
      style={{
        width: "300px",
        position: "fixed",
        top: "5px",
        right: "5px",
        zIndex: 23432,
        display: "block !important",
      }}
    >
      <Paper
        elevation={4}
        style={{ backgroundColor: colorWhiteNuanced }}
        className={classes.paper}
      >
        {/* <div className="App">
      <header className="App-header">
        <button onClick={test}> TEST </button>
        <button onClick={sendMessage}> SEND MESSAGE to the background </button>

        <img src={logo} className="App-logo" alt="logo" />
        <p>URL:</p>
        <p>{url}</p>
      </header> s
    </div> */}
        <div className={classes.container}>
          <img className={classes.logo} src={Logo} alt="logo" />

          <Typography
            style={{ marginLeft: "6px" }}
            variant="h6"
            component="div"
          >
            Adding item
          </Typography>
          <CircularProgress className={classes.circularProgress} />
        </div>
      </Paper>

      <Paper className={classes.dataContainerPaper}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={
              "https://media.istockphoto.com/photos/colored-powder-explosion-on-black-background-picture-id1057506940?k=20&m=1057506940&s=612x612&w=0&h=3j5EA6YFVg3q-laNqTGtLxfCKVR3_o6gcVZZseNaWGk="
            }
            style={{
              marginTop: "8px",
              marginBottom: "8px",
              width: "30%",
              height: "auto",
              borderRadius: "10px",
              maxHeight: "150px",
              objectFit: "cover",
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <AggDataTitle> TOYOYO</AggDataTitle>
            <AggDataContent> TOYOYO</AggDataContent>
          </div>
        </div>

        <div className={classes.container2}>
          <IconButton
            onClick={() => {
              setDisplayCollectionsContainer(true);
            }}
            aria-label="add-to-collection"
            component="span"
          >
            <PlaylistAddIcon></PlaylistAddIcon>
          </IconButton>
          <IconButton onClick={() => {}} aria-label="Delete" component="span">
            <DeleteIcon></DeleteIcon>
          </IconButton>
        </div>
      </Paper>

      {displayCollectionsContainer && (
        <Fade in={displayCollectionsContainer} mountOnEnter unmountOnExit>
          <Paper
            style={{ backgroundColor: colorWhiteNuanced }}
            className={classes.dataContainerPaper}
          >
            <div
              style={{
                width: "100%",
                height: "100px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            ></div>

          </Paper>
        </Fade>
      )}
    </div>

    // <Modal
    // aria-labelledby="transition-modal-title"
    // aria-describedby="transition-modal-description"
    // className={classes.modal}
    // open={true}>
    //     <Fade in={true}>
    //       <div className={classes.root}></div>
    //     </Fade>
    // </Modal>
  );
};

export default ExtensionDev;

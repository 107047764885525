import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AggDataTitle,
  AggDataContent,
  AggDataImageContent,
  AggDataFileImgPreviewContent,
  AggDataFilePagesInfoContainer,
  AggDataSubTitle,
} from "containers/AggData/AggDataElements";
import AggDataLayout from "containers/AggData/AggDataLayout";
import { highlightText } from "shared/utility";

import { AggDataObj } from "models/aggdata";
import { CollectionObj } from "models/collection";
import { RootState } from "store/reducers";
import { DataType, sanitizeForApiCall } from "models/aggdata";
import { useMutation } from "@apollo/client";
import { clearEditableData } from "../../store/actions/aggdataActions";
import {
  openReminderDatePicker,
  displayCommentsViewAction,
  setAggdataClicked,
  setSavePublicAggdataClicked,
  setDisplayLoginModal,
} from "store/actions/view";
import { retrieveCollectionGql } from "graphql/queries";
import { showSnackbar } from "store/actions/view";
import { useTranslation } from "react-i18next";
import {
  addNewDataGql,
  updateAggDataGql,
  deleteAggDataGql,
  unshareMultipleAggdataFromSharedCollectionGql,
} from "graphql/mutations";
import { getLinkPreviewInformation } from "models/aggdata";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  getImageForDataId,
  getFilePathForDataId,
  getNoteImageForId,
} from "configuration";
import {
  deleteAggDataFromCache,
  deleteAggdataFromCollectionSpecificCache,
  deleteAggDataFromAllDataCache,
  addAggDataInArchivedDataCache,
  addAggDataInCache,
  deleteAggDataFromArchivedDataCache,
} from "graphql/helpers";
import {
  colorMementoBeige,
  colorGreyMemento,
  colorMemento,
} from "shared/colors";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { updateAggDataInCache } from "graphql/helpers";
import {
  setAllItemsMaxPinScore,
  setMaxSortOrderScore,
  setEditableAggdataNote,
} from "store/actions/aggdataActions";
import parse from "html-react-parser";
import NoteTasksList from "containers/AggData/NoteEditor/NoteTasksList";
import { NoteTask } from "shared/types";
import {
  parseNoteTasksAsSortedList,
  checkIfYoutubeVideoUrl,
  formatIso8601date,
  formatIso8601,
  getInChronometerFormat,
} from "shared/dataUtils";
import ChipsContainer from "components/UI/ChipsContainer/ChipsContainer";
import { Reminder } from "models/aggdata";
import Skeleton from "@mui/material/Skeleton";
import LazyLoad from "react-lazyload";
import {
  Divider,
  IconButton,
  ListItemButton,
  Menu,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { getNoteColorFromColorIndex } from "shared/colors";
import { DEBUG } from "configuration";
import { useApolloClient } from "@apollo/client";
import SharedUserAvatar from "components/UI/Controls/SharedUserAvatar";
import { stringToColour } from "shared/colors";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { UserAccessRight, UserObj, SharedUserAccess } from "models/user";
import { isAggdataShared } from "models/aggdata";
import YoutubePlayerModal from "components/UI/Modals/YoutubePlayerModal";
import { VIEW_TYPE } from "components/UI/Controls/AggdataDisplayControl";
import Box from "@mui/material/Box";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import SharedUsersAvatars from "components/UI/Controls/SharedUsersAvatars";
import CommentIcon from "@mui/icons-material/Comment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isMobile } from "react-device-detect";
import NoteImagePreview, { PreviewType } from "./NoteEditor/NoteImagePreview";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PlayCircleFilledWhiteRoundedIcon from "@mui/icons-material/PlayCircleFilledWhiteRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AnnotateAggdataModal from "components/UI/Modals/AnnotateAggdataModal";
import AggDataFeedItemHeader from "./AggDataFeedItemHeader";
import AggdataPopupMenu from "./AggdataPopupMenu";
import ExpandableText from "components/Display/ExpandableText";
import NotesIcon from "@mui/icons-material/Notes";
import EditAggdataPreviewModal from "components/UI/Modals/EditAggdataPreviewModal";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import AggDataTagsContainer from "components/UI/ChipsContainer/AggDataTagsContainer";
import { FaFileZipper } from "react-icons/fa6";

interface AggDataProp {
  aggDataObj: AggDataObj;
  isEditingProp: boolean;
  displayedFromCollection?: boolean; //Whether this object is displayed in the context of a collection being opened or not.
  onAddToCollection: () => void;
  setBeingEditedNoteAggDataId: (id: string) => void;
  onImageAggDataClicked?: (currentImg: AggDataObj) => void; //current img source.
  layoutType?: VIEW_TYPE;
}

type BlurEventType =
  | React.FocusEvent<HTMLInputElement>
  | React.FocusEvent<HTMLTextAreaElement>;

const AggData = ({
  aggDataObj,
  isEditingProp,
  displayedFromCollection = false,
  onAddToCollection,
  onImageAggDataClicked,
  setBeingEditedNoteAggDataId,
  layoutType = VIEW_TYPE.GRID,
}: AggDataProp) => {
  const [isHovering, setIsHovering] = useState(false);
  const [editedAggData, setEditedAggData] = useState<AggDataObj>(aggDataObj);
  const [youtubeVideoUrlToOpen, setYoutubeVideoUrlToOpen] = useState<
    string | null
  >(null);

  const [openAnnotateModal, setOpenAnnotateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const history = useHistory();
  const [isEditing, setIsEditing] = useState(isEditingProp);
  const [deleteAggData, deleteAggDataResult] = useMutation(
    deleteAggDataGql,
    {}
  );
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  //We compute the current user right for this aggdata
  const [currentUserRight, _] = React.useState(() => {
    // console.log("moms aggdata is %o", aggDataObj)
    // console.log("moms current user  is %o", currentUser)
    //If aggdata is not shared and is not public
    if (
      (!isAggdataShared(aggDataObj) &&
        currentUser &&
        aggDataObj.owner.id == currentUser.id) ||
      (currentUser && aggDataObj.owner.id == currentUser.id)
    ) {
      return UserAccessRight.OWNER_ACCESS_RIGHT;
    } else if (!currentUser) {
      return UserAccessRight.READER_ACCESS_RIGHT;
    } else {
      const currentUserAccess = aggDataObj?.collection.sharedUsers.find(
        (sharedUserAccess: SharedUserAccess) =>
          sharedUserAccess.user.id == currentUser.id
      );
      // console.log("current user right for aggdata " + JSON.stringify(aggDataObj.annotation) + JSON.stringify(currentUserAccess));
      return currentUserAccess
        ? currentUserAccess?.right
        : UserAccessRight.READER_ACCESS_RIGHT;
    }
  });

  const [noteColorIndex, setNoteColorIndex] = React.useState(-1);

  const [updateAggData, updateAggDataResult] = useMutation(updateAggDataGql);

  const [unshareFromCollection, unshareFromCollectionResult] = useMutation(
    unshareMultipleAggdataFromSharedCollectionGql
  );

  const [addAggData, { data, loading, error }] = useMutation(addNewDataGql, {
    // refetchQueries: [{ query: retrieveAllDataGql }],
  });
  const [isImgLoading, setIsImgLoading] = useState(false);

  const [noteTasks, setNoteTasks] = useState<NoteTask[]>(
    parseNoteTasksAsSortedList(editedAggData?.note?.noteTasks)
  );

  const client = useApolloClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const editableNote = useSelector(
    (state: RootState) => state.aggdataReducer.opened
  );
  const openedNoteAggdataId = useSelector(
    (state: RootState) => state.aggdataReducer.openedNoteAggDataId
  );
  const editableNoteID: string = editableNote && editableNote.id;
  //const todos: Todo[] | undefined = convertNoteToTodo(aggData.note.noteContent!!);

  const maxAllItemsPinScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxAllItemsPinScore
  );

  const maxSortOrderScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxSortOrderScore
  );

  const editableAggdataNote = useSelector(
    (state: RootState) => state.aggdataReducer.editableAggdataNote
  );

  useEffect(() => {
    //console.log("aggdata changed " + JSON.stringify(aggDataObj))
    setEditedAggData(aggDataObj);
    setNoteTasks(parseNoteTasksAsSortedList(aggDataObj.note?.noteTasks));
  }, [aggDataObj]);
  // useEffect(() => {
  //   console.log("momox useffect editableNote   " + JSON.stringify(editableNote))
  //   if (editableNote && editableNote.id === id) {

  //     setIsEditing(true);
  //   } else {
  //     setIsEditing(false);
  //   }
  // }, [dispatch, editableNote, id]);

  useEffect(() => {
    if (deleteAggDataResult.data) {
      dispatch(
        showSnackbar({
          message: t("snackbar_agg_data_deleted_msg"),
          action: t("snackbar_action_undo_msg"),
          onAction: handleUndoDelete,
        })
      );
    }
  }, [deleteAggDataResult]);

  const handleUndoDelete = useCallback(() => {
    //console.log("undo delete " + JSON.stringify(editedAggData));
    const sanitizedAggData = sanitizeForApiCall(editedAggData);
    addAggData({ variables: { input: sanitizedAggData } });
  }, [editedAggData]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      const event = e.target as HTMLElement;
      console.log(
        "handleClick " + event.tagName + "the type" + event.getAttribute("type")
      );
      if (editedAggData.dataType == DataType.WEB_URL) {
        const url = editedAggData.url;
        if (url) {
          const youtubeVideoUrl = checkIfYoutubeVideoUrl(url);
          if (youtubeVideoUrl && !isMobile) {
            setYoutubeVideoUrlToOpen(youtubeVideoUrl);
          } else {
            window.open(url, "_blank");
          }
        }
      } else if (
        editedAggData.dataType == DataType.IMAGE_TYPE ||
        editedAggData.dataType == DataType.VIDEO_TYPE
      ) {
        if (onImageAggDataClicked) {
          //important to pass the same instance than provided as input, and not the editedAggData. It will be used later to search for the index of this aggdata when opend in imageviewer
          onImageAggDataClicked(aggDataObj);
        }
      } else if (editedAggData.dataType == DataType.AUDIO_TYPE) {
        dispatch(setAggdataClicked(editedAggData));
      } else if (editedAggData.dataType == DataType.FILE_TYPE) {
        window.open(getFilePathForDataId(editedAggData.id), "_blank");
      } else if (
        event.tagName !== "BUTTON" &&
        event.tagName !== "path" &&
        event.tagName !== "svg" &&
        event.tagName !== "SPAN" &&
        event.getAttribute("type") !== "checkbox" &&
        event.id !== "label" &&
        !editableNoteID
      ) {
        //dispatch(getEditableData(aggData));
        //setBeingEditedNoteAggDataId && setBeingEditedNoteAggDataId(editedAggData.id)
        //setIsEditing(true);
        dispatch(setEditableAggdataNote(aggDataObj));
      }
    },
    [dispatch, editableNoteID, onImageAggDataClicked, youtubeVideoUrlToOpen]
  );

  const handleDelete = useCallback(async (): Promise<void> => {
    if (DEBUG)
      console.log(
        "delete agg data with collection " + editedAggData.collection
      );

    deleteAggDataFromCache(deleteAggDataResult.client.cache, editedAggData);
    deleteAggData({
      variables: { id: editedAggData.id },
      refetchQueries: editedAggData.collection
        ? [
            {
              query: retrieveCollectionGql,
              variables: { id: editedAggData.collection.id },
            },
          ]
        : undefined,
      update(cache, { data }) {
        //deleteAggDataFromCache(cache, editedAggData);
      },
    }).catch((e) => {
      addAggDataInCache(deleteAggDataResult.client.cache, editedAggData);
      dispatch(
        showSnackbar({
          message: t("snackbar_failed_apply_changes_backend"),
          action: "",
          onAction: null,
          loading: false,
        })
      );
    });
  }, [dispatch, editedAggData]);

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      dispatch(clearEditableData());
      if (!compareAggDataContent(editedAggData, editedAggData)) {
        if (DEBUG) console.log("Need to update data");
        deleteAggData({ variables: { input: editedAggData } });
      } else {
        if (DEBUG) console.log("No need to update aggdata");
      }
    },
    [dispatch]
  );

  const handleUndoArchive = useCallback(
    (aggData: AggDataObj) => {
      var refetchQueries: any = [];
      const aggDataToUpdate: any = {
        id: aggData.id,
        isArchived: false,
        collection: null,
      };
      if (DEBUG)
        console.log(
          "Will unarchive agg data " + JSON.stringify(aggDataToUpdate)
        );
      if (aggData.collection) {
        aggDataToUpdate.collection = aggData.collection.id;
        refetchQueries = [
          {
            query: aggData.collection?.id ? retrieveCollectionGql : "", // if no collection, avoid trying a useless query
            variables: {
              id: aggData.collection?.id,
            },
          },
        ];
      }
      updateAggData({
        variables: { input: aggDataToUpdate },
        refetchQueries: refetchQueries,
        update(cache, { data: { updateAggdata } }) {
          if (updateAggdata) {
            addAggDataInCache(cache, updateAggdata.aggData);
            deleteAggDataFromArchivedDataCache(cache, updateAggdata.aggData);
          }
        },
      });
    },
    [updateAggData]
  );

  const handleArchive = useCallback(() => {
    const aggDataToUpdate: any = {
      id: editedAggData.id,
      isArchived: true,
      sortOrderScore: maxSortOrderScore + 1,
      collection: null,
    }; //We dot this because propos are read only and cannot be modified directly.

    var refetchQueries: any = [];
    if (editedAggData.collection) {
      aggDataToUpdate.collection = "-1"; // means we want to detach the data from the collection
      refetchQueries = [
        {
          query: editedAggData.collection?.id ? retrieveCollectionGql : "", // if no collection, avoid trying a useless query
          variables: {
            id: editedAggData.collection?.id,
          },
        },
      ];
    }

    updateAggData({
      variables: { input: aggDataToUpdate },
      refetchQueries: refetchQueries,
      update(cache, { data: { updateAggdata } }) {
        if (updateAggdata) {
          //console.log("Dwight " + JSON.stringify(updateAggdata));
          deleteAggDataFromAllDataCache(cache, updateAggdata.aggData);
          addAggDataInArchivedDataCache(cache, updateAggdata.aggData);
        }
      },
    })
      .then((data) => {
        dispatch(
          showSnackbar({
            message: t("item_archived_confirmation_msg"),
            action: t("snackbar_action_undo_msg"),
            onAction: () => handleUndoArchive(editedAggData),
            loading: false,
          })
        );
        dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("item_archived_failed_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [editedAggData, maxSortOrderScore, handleUndoArchive, updateAggData]);

  const handleUndoUnarchive = useCallback(
    (aggData: AggDataObj) => {
      const aggDataToUpdate: any = {
        id: aggData.id,
        isArchived: true,
      };
      updateAggData({
        variables: { input: aggDataToUpdate },
        update(cache, { data: { updateAggdata } }) {
          if (updateAggdata) {
            addAggDataInArchivedDataCache(cache, updateAggdata.aggData);
            deleteAggDataFromAllDataCache(cache, updateAggdata.aggData);
          }
        },
      });
    },
    [updateAggData]
  );

  const handleUnarchive = useCallback(async (): Promise<void> => {
    const aggDataToUpdate: any = {
      id: editedAggData.id,
      isArchived: false,
      sortOrderScore: maxSortOrderScore + 1,
    }; //We dot this because propos are read only and cannot be modified directly.

    updateAggData({
      variables: { input: aggDataToUpdate },
      update(cache, { data: { updateAggdata } }) {
        if (updateAggdata) {
          addAggDataInCache(cache, updateAggdata.aggData);
          deleteAggDataFromArchivedDataCache(cache, updateAggdata.aggData);
        }
      },
    })
      .then((data) => {
        dispatch(
          showSnackbar({
            message: t("item_unarchived_confirmation_msg"),
            action: t("snackbar_action_undo_msg"),
            onAction: () => handleUndoUnarchive(editedAggData),
            loading: false,
          })
        );
        dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("item_unarchived_failed_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [editedAggData, handleUndoUnarchive, updateAggData]);

  const handleArchiveAction = useCallback((): void => {
    if (editedAggData.isArchived) {
      handleUnarchive();
    } else {
      handleArchive();
    }
  }, [editedAggData, handleArchive, handleUnarchive]);

  React.useEffect(() => {
    if (noteColorIndex < 0) {
      return;
    }
    var aggDataUpdateForCache;
    var aggDataUpdateForBackend;
    const beforeUpdateAggData = { ...editedAggData };
    aggDataUpdateForCache = {
      ...editedAggData,
      id: editedAggData.id,
      note: {
        ...editedAggData.note,
        noteColor: noteColorIndex,
      },
    };
    setEditedAggData(aggDataUpdateForCache);

    aggDataUpdateForBackend = {
      id: editedAggData.id,
      note: {
        noteColor: noteColorIndex,
      },
    };

    //  updateAggDataInCache(
    //   updateAggDataResult.client.cache,
    //   aggDataUpdateForCache
    // );

    updateAggData({
      variables: { input: aggDataUpdateForBackend },
      refetchQueries: undefined,
      update(cache, { data: { updateAggdata } }) {
        if (updateAggdata) {
          updateAggDataInCache(cache, updateAggdata.aggData);
        }
      },
    }).catch((e) => {
      //If update with the backend fails, we rollback the changes
      dispatch(
        showSnackbar({
          message: t("snackbar_failed_apply_changes_backend"),
          action: "",
          onAction: null,
          loading: false,
        })
      );
    });
  }, [noteColorIndex]);

  const handleColorSelected = useCallback(
    (colorIndex: number): void => {
      const aggDataUpdateForCache = {
        ...editedAggData,
        id: editedAggData.id,
        note: {
          ...editedAggData.note,
          noteColor: colorIndex,
        },
      };
      setEditedAggData(aggDataUpdateForCache);
      setNoteColorIndex(colorIndex);
    },
    [editedAggData, updateAggData]
  );

  const handlePinUnpinAction = useCallback((): void => {
    var aggDataUpdateForCache;
    var aggDataUpdateForBackend;
    const beforeUpdateAggData = { ...editedAggData };
    const pinScoreName = displayedFromCollection
      ? "listItemsPinScore"
      : "allItemsPinScore";
    if (Number(editedAggData[pinScoreName]) > 0) {
      // in this case item is pinned, we want to unpin it
      aggDataUpdateForCache = {
        ...editedAggData,
        id: editedAggData.id,
        //allItemsPinScore: 0,
        sortOrderScore: maxSortOrderScore + 1,
      };
      aggDataUpdateForCache[pinScoreName] = 0;
      aggDataUpdateForBackend = {
        id: editedAggData.id,
        //allItemsPinScore: 0,
        sortOrderScore: maxSortOrderScore + 1,
      };
      aggDataUpdateForBackend[pinScoreName] = 0;
      dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
    } else {
      // in this case item is unpinned, we want to pin it
      aggDataUpdateForCache = {
        ...editedAggData,
        id: editedAggData.id,
        //allItemsPinScore: maxAllItemsPinScore + 1,
      };
      aggDataUpdateForCache[pinScoreName] = maxAllItemsPinScore + 1;

      aggDataUpdateForBackend = {
        id: editedAggData.id,
        //allItemsPinScore: maxAllItemsPinScore + 1,
      };
      aggDataUpdateForBackend[pinScoreName] = maxAllItemsPinScore + 1;
      dispatch(setAllItemsMaxPinScore(maxAllItemsPinScore + 1));
    }

    updateAggDataInCache(
      updateAggDataResult.client.cache,
      aggDataUpdateForCache
    );
    updateAggData({
      variables: { input: aggDataUpdateForBackend },
      refetchQueries: undefined,
    }).catch((e) => {
      //If update with the backend fails, we rollback the changes
      updateAggDataInCache(
        updateAggDataResult.client.cache,
        beforeUpdateAggData
      );
      dispatch(
        showSnackbar({
          message: t("snackbar_failed_apply_changes_backend"),
          action: "",
          onAction: null,
          loading: false,
        })
      );
    });
  }, [editedAggData, updateAggData]);

  function compareAggDataContent(
    aggData1: AggDataObj,
    aggData2: AggDataObj
  ): boolean {
    return (
      aggData1.dataType === aggData2.dataType &&
      aggData1?.note?.noteTitle === aggData2?.note?.noteTitle &&
      aggData1?.note?.noteContent === aggData2?.note?.noteContent &&
      aggData1?.note?.noteColor === aggData2?.note?.noteColor
    );
  }

  const handleAddReminder = useCallback(() => {
    dispatch(openReminderDatePicker(editedAggData));
  }, [dispatch, editedAggData]);

  const handleAnnotateClicked = useCallback(() => {
    //dispatch(openReminderDatePicker(editedAggData));
    setOpenAnnotateModal(true);
  }, [setOpenAnnotateModal]);

  const handleEditClicked = useCallback(() => {
    //dispatch(openReminderDatePicker(editedAggData));
    //setOpenEditModal(true);
    setOpenAnnotateModal(true);
  }, [setOpenEditModal]);

  //Called from public collections, user can pick a content to cherry pick
  const handleSave = useCallback(() => {
    if (!currentUser) {
      dispatch(setDisplayLoginModal(t("save_public_aggdata_login_message")));
      return;
    }
    //  console.log("dispatching ");
    dispatch(setSavePublicAggdataClicked(editedAggData.id));
  }, [editedAggData]);

  const handleUnshareFromCollection = useCallback(() => {
    unshareFromCollection({
      variables: {
        inputList: [
          {
            aggdataId: editedAggData.id,
            collectionId: editedAggData.collection.id,
          },
        ],
      },
      refetchQueries: [
        {
          query: retrieveCollectionGql,
          variables: { id: editedAggData.collection.id },
        },
      ],
    })
      .then((data) => {
        if (DEBUG)
          console.log("Unshare from collection result " + JSON.stringify(data));
        deleteAggdataFromCollectionSpecificCache(
          client.cache,
          [editedAggData.id],
          editedAggData.collection.id
        );
      })
      .catch((e) => {
        if (DEBUG) console.log("Unshare from collection exception " + e);
        dispatch(
          showSnackbar({
            message: t("snackbar_failed_apply_changes_backend"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [unshareFromCollection, editedAggData, currentUser]);

  const handleComment = useCallback(() => {
    dispatch(displayCommentsViewAction(editedAggData));
  }, [editedAggData]);

  const noteLayoutProps = {
    aggData: editedAggData,
    isHovering,
    currentUserRight: currentUserRight,
    clicked: isEditing ? 1 : 0,
    displayFromCollection: displayedFromCollection,
    bgColor:
      editedAggData.dataType == DataType.NOTE_TYPE
        ? getNoteColorFromColorIndex(editedAggData.note?.noteColor, false)
        : undefined,
    setIsHovering,

    onClick: handleClick,
    onDelete: handleDelete,
    onEdit: handleEditClicked,
    onAnnotate: handleAnnotateClicked,
    onAddToCollection: onAddToCollection,
    onClose: handleClose,
    onArchive: handleArchiveAction,
    onRemoveFromSharedCollection: handleUnshareFromCollection,
    onPinUnpin: handlePinUnpinAction,
    onColorSelected: handleColorSelected,
    onAddReminder: handleAddReminder,
    onComment: handleComment,
    onSave: handleSave,
  };

  const handleNoteTasksChanged = useCallback(
    (noteTasks: NoteTask[]) => {
      const noteTasksWithoutId = noteTasks.map((noteTask) => {
        return { ...noteTask, id: undefined };
      });
      const noteTasksAsJsonString = JSON.stringify(noteTasksWithoutId);
      const updateData = {
        id: editedAggData.id,
        note: { noteTasks: noteTasksAsJsonString },
      };
      //setNoteTasks(noteTasks);
      const updatedAggdata = {
        ...editedAggData,
        note: { ...editedAggData.note, noteTasks: noteTasksAsJsonString },
      };
      setEditedAggData(updatedAggdata);
      setNoteTasks(noteTasks);
      updateAggData({
        variables: { input: updateData },
        update(cache, { data: { updateAggdata } }) {
          if (updateAggdata) {
            updateAggDataInCache(cache, updateAggdata.aggData);
          }
        },
      });
    },
    [editedAggData]
  );

  const handleReminderClicked = useCallback((reminder: Reminder) => {
    // console.log("Reminder clicked " + JSON.stringify(reminder));
  }, []);

  const handleDeleteReminder = useCallback(
    (reminder: Reminder) => {
      if (!editedAggData.reminders) {
        return; //nothing to delete
      }
      const remindersArray: Reminder[] = JSON.parse(editedAggData.reminders);
      const remindersArrayWithDeletedItem = remindersArray.filter(
        (reminderFilter: Reminder) => {
          return !(
            reminder.triggerTime === reminderFilter.triggerTime &&
            reminder.cycle === reminderFilter.cycle
          );
          //return reminder.triggerTime !== reminderFilter.triggerTime;
        }
      );
      var aggDataUpdateForCache;
      var aggDataUpdateForBackend;
      const beforeUpdateAggData = { ...editedAggData };

      const remindersAsJsonString = JSON.stringify(
        remindersArrayWithDeletedItem
      );

      aggDataUpdateForCache = {
        ...editedAggData,
        reminders: remindersAsJsonString,
      };
      setEditedAggData(aggDataUpdateForCache);
      aggDataUpdateForBackend = {
        id: editedAggData.id,
        reminders: remindersAsJsonString,
      };
      /*   console.log(
        "will update for cache " + JSON.stringify(aggDataUpdateForCache)
      );
      console.log(
        "will update for backend " + JSON.stringify(aggDataUpdateForBackend)
      ); */

      updateAggDataInCache(
        updateAggDataResult.client.cache,
        aggDataUpdateForCache
      );
      updateAggData({
        variables: { input: aggDataUpdateForBackend },
        refetchQueries: undefined,
      }).catch((e) => {
        //If update with the backend fails, we rollback the changes
        updateAggDataInCache(
          updateAggDataResult.client.cache,
          beforeUpdateAggData
        );
        dispatch(
          showSnackbar({
            message: t("snackbar_failed_apply_changes_backend"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
    },
    [editedAggData, updateAggData, dispatch, showSnackbar]
  );

  const getRemindersView = useCallback(() => {
    if (editedAggData && editedAggData.reminders) {
      const remindersArray = JSON.parse(editedAggData.reminders);
      if (remindersArray.length > 0) {
        return (
          <>
            <div style={{ height: "4px" }}></div>
            <ChipsContainer
              readOnly={currentUserRight == 0}
              onDeleteReminder={handleDeleteReminder}
              onReminderItemClicked={handleReminderClicked}
              reminders={JSON.parse(editedAggData.reminders)}
            />
            <div style={{ height: "24px" }}></div>
          </>
        );
      }
    }
    return <></>;
  }, [editedAggData, handleDeleteReminder, handleReminderClicked]);

  const getOwnerAvatar = useCallback(() => {
    return <TopBadge editedAggData={editedAggData} />;
  }, [editedAggData]);

  if (layoutType == VIEW_TYPE.GRID) {
    if (
      editableAggdataNote &&
      editableAggdataNote.id == editedAggData.id &&
      editedAggData.dataType == DataType.NOTE_TYPE
    ) {
      return (
        //we return an empty div, as in this case NoteEditor should be opened
        <></>
      );
    } else {
      if (editedAggData.dataType == DataType.WEB_URL) {
        const previewInformation = getLinkPreviewInformation(editedAggData);
        var image = (
          <InsertLinkIcon
            style={{
              width: "100%",
              height: "70px",
              background: "#faf6e9",
              borderRadius: "12px",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          />
        );
        var title = "";
        var description = "";
        var hostUrl = "";
        if (previewInformation) {
          title = previewInformation.title!!;
          description = previewInformation.description!!;
          hostUrl = previewInformation.hostUrl;
          if (previewInformation.imgUrl === "None") {
            image = (
              <InsertLinkIcon
                style={{
                  width: "100%",
                  height: "70px",
                  background: "#faf6e9",
                  borderRadius: "12px",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              />
            );
          } else {
            image = (
              <img
                src={previewInformation.imgUrl}
                style={{
                  paddingTop: "12px",
                  paddingBottom: "8px",
                  width: "100%",
                  padding: "12px",
                  height: "auto",
                  borderRadius: "24px",
                  maxHeight: "200px",
                  objectFit: "cover",
                }}
                loading="lazy"
              />
            );
          }
        } else {
          description = editedAggData.url;
        }
        return (
          <AggDataLayout {...noteLayoutProps}>
            <Box
              sx={{
                paddingTop: "8px",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              <ExpandableText text={editedAggData.annotation} />
            </Box>
            <AggDataTitle>{highlightText(title, "")}</AggDataTitle>
            {/* {isChecked ? (
             <TodoList todoContent={todos} />
           ) : (
             <AggDataContent>{highlightText(content, searchQuery)}</AggDataContent>
           )} */}
            {image}
            <AggDataContent>
              {highlightText(description === "None" ? "" : description, "")}
            </AggDataContent>

            <Typography
              variant="h6"
              color="gray"
              sx={{ fontWeight: "400", padding: "0px 12px 8px 12px" }}
            >
              {hostUrl}
            </Typography>
            {getRemindersView()}
            <AggDataTagsContainer aggdata={editedAggData} readOnly />

            {isHovering && getOwnerAvatar()}
            {youtubeVideoUrlToOpen && (
              <YoutubePlayerModal
                url={youtubeVideoUrlToOpen}
                onClose={() => {
                  setYoutubeVideoUrlToOpen(null);
                }}
              />
            )}
            <AnnotateAggdataModal
              open={openAnnotateModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenAnnotateModal(false);
              }}
            />
            <EditAggdataPreviewModal
              open={openEditModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenEditModal(false);
              }}
            />
          </AggDataLayout>
        );
      } else if (editedAggData.dataType === DataType.IMAGE_TYPE) {
        return (
          <AggDataLayout {...noteLayoutProps}>
            {editedAggData.annotation && (
              <Box
                sx={{
                  paddingTop: "8px",
                  paddingBottom: "4px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <ExpandableText text={editedAggData.annotation} />
              </Box>
            )}
            <AggDataImageContent>
              <LazyLoad height={250} offset={400} once>
                <img
                  src={getImageForDataId(editedAggData.id)}
                  style={{
                    width: "100%",
                    height: "auto",
                    display: isImgLoading ? "none" : "block",
                  }}
                  onLoadStart={() => {
                    setIsImgLoading(true);
                  }}
                  onLoad={() => {
                    setIsImgLoading(false);
                  }}
                />
              </LazyLoad>

              {isHovering && getOwnerAvatar()}
            </AggDataImageContent>
            {getRemindersView()}

            <AggDataTagsContainer aggdata={editedAggData} readOnly />

            <AnnotateAggdataModal
              open={openAnnotateModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenAnnotateModal(false);
              }}
            />
            <EditAggdataPreviewModal
              open={openEditModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenEditModal(false);
              }}
            />
          </AggDataLayout>
        );
      } else if (editedAggData.dataType === DataType.VIDEO_TYPE) {
        return (
          <AggDataLayout {...noteLayoutProps}>
            {editedAggData.annotation && (
              <Box
                sx={{
                  paddingTop: "8px",
                  paddingBottom: "4px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <ExpandableText text={editedAggData.annotation} />
              </Box>
            )}
            <AggDataImageContent>
              <LazyLoad height={250} offset={400} once>
                <img
                  src={getImageForDataId(editedAggData.id)}
                  style={{
                    width: "100%",
                    height: "auto",
                    display: isImgLoading ? "none" : "block",
                  }}
                  onLoadStart={() => {
                    setIsImgLoading(true);
                  }}
                  onLoad={() => {
                    setIsImgLoading(false);
                  }}
                />
              </LazyLoad>

              {isHovering && getOwnerAvatar()}
              <IconButton
                sx={{
                  position: "absolute",
                }}
              >
                <PlayCircleFilledWhiteRoundedIcon
                  sx={{
                    color: "white",
                    width: "48px",
                    height: "48px",
                  }}
                ></PlayCircleFilledWhiteRoundedIcon>
              </IconButton>
            </AggDataImageContent>
            {getRemindersView()}
            <AggDataTagsContainer aggdata={editedAggData} readOnly />
            <AnnotateAggdataModal
              open={openAnnotateModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenAnnotateModal(false);
              }}
            />
            <EditAggdataPreviewModal
              open={openEditModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenEditModal(false);
              }}
            />
          </AggDataLayout>
        );
      } else if (editedAggData.dataType === DataType.AUDIO_TYPE) {
        return (
          <AggDataLayout {...noteLayoutProps}>
            {editedAggData.annotation && (
              <Box
                sx={{
                  paddingTop: "8px",
                  paddingBottom: "4px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <ExpandableText text={editedAggData.annotation} />
              </Box>
            )}
            <AggDataTitle>{editedAggData.file?.fileName}</AggDataTitle>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "end",
                padding: "8px",
              }}
            >
              <KeyboardVoiceIcon
                sx={{
                  color: colorMemento,
                  alignContent: "center",
                }}
              />
              <Typography
                style={{
                  fontSize: "1.3rem",
                  textAlign: "end",
                  alignItems: "center",
                }}
              >
                {" "}
                {getInChronometerFormat(editedAggData.file?.length)}
              </Typography>
            </Box>
            <AggDataTagsContainer aggdata={editedAggData} readOnly />
            <AnnotateAggdataModal
              open={openAnnotateModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenAnnotateModal(false);
              }}
            />
            <EditAggdataPreviewModal
              open={openEditModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenEditModal(false);
              }}
            />
          </AggDataLayout>
        );
      } else if (editedAggData.dataType === DataType.FILE_TYPE) {
        return (
          <AggDataLayout {...noteLayoutProps}>
            {editedAggData.annotation && (
              <Box
                sx={{
                  paddingTop: "8px",
                  paddingBottom: "4px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <ExpandableText text={editedAggData.annotation} />
              </Box>
            )}
            <AggDataFileImgPreviewContent>
              <div
                style={{
                  backgroundColor: colorMementoBeige,
                  width: "100%",
                  height: "12px",
                }}
              ></div>
              {editedAggData.file?.fileName?.endsWith(".pdf") && (
                <img
                  src={getImageForDataId(editedAggData.id)}
                  style={{
                    width: "70%",
                  }}
                  loading="lazy"
                />
              )}
              {editedAggData.file?.fileName?.endsWith(".zip") && (
                <Box sx={{ padding: "8px" }}>
                  <FaFileZipper
                    style={{ width: "64px", height: "64px", color: "orange" }}
                  />
                </Box>
              )}
            </AggDataFileImgPreviewContent>
            <AggDataTitle>
              {highlightText(editedAggData.file?.fileName!!, "")}
            </AggDataTitle>
            {}
            <AggDataFilePagesInfoContainer>
              {editedAggData?.file?.pagesCount && (
                <>
                  <AggDataSubTitle>
                    {" "}
                    {editedAggData?.file?.pagesCount}
                  </AggDataSubTitle>
                  <MenuBookIcon style={{ color: colorGreyMemento }} />
                </>
              )}
            </AggDataFilePagesInfoContainer>
            {getRemindersView()}
            <AggDataTagsContainer aggdata={editedAggData} readOnly />
            {isHovering && getOwnerAvatar()}
            <AnnotateAggdataModal
              open={openAnnotateModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenAnnotateModal(false);
              }}
            />
            <EditAggdataPreviewModal
              open={openEditModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenEditModal(false);
              }}
            />
          </AggDataLayout>
        );
      } else {
        return (
          <AggDataLayout {...noteLayoutProps}>
            {editedAggData.annotation && editedAggData.annotation.length > 0 && (
              <Box
                sx={{
                  paddingTop: "8px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <ExpandableText text={editedAggData.annotation} />
              </Box>
            )}
            <AggDataTitle>
              {highlightText(editedAggData?.note?.noteTitle!!, "")}
            </AggDataTitle>
            {editedAggData?.note?.noteimageSet &&
              editedAggData?.note?.noteimageSet.length > 0 && (
                <NoteImagePreview
                  noteImageIds={editedAggData?.note?.noteimageSet}
                  aggData={editedAggData}
                />
              )}
            {/* {isChecked ? (
             <TodoList todoContent={todos} />
           ) : (
             <AggDataContent>{highlightText(content, searchQuery)}</AggDataContent>
           )} */}
            <AggDataContent>
              {parse(
                editedAggData?.note?.noteContent
                  ? editedAggData?.note?.noteContent
                  : "<br/><br/>"
              )}

              {/* {highlightText(aggData?.note?.noteContent!!, "")} */}
            </AggDataContent>

            {noteTasks.length > 0 && (
              <>
                <NoteTasksList
                  readOnly={true}
                  noteTasksArray={noteTasks}
                  onNoteTasksChange={handleNoteTasksChanged}
                />
                <div style={{ height: "8px" }}></div>
              </>
            )}
            {getRemindersView()}
            <AggDataTagsContainer aggdata={editedAggData} readOnly />

            {isHovering && getOwnerAvatar()}
            <AnnotateAggdataModal
              open={openAnnotateModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenAnnotateModal(false);
              }}
            />
            <EditAggdataPreviewModal
              open={openEditModal}
              aggdata={editedAggData}
              handleModalClose={() => {
                setOpenEditModal(false);
              }}
            />
          </AggDataLayout>
        );
      }
    }
  } else if (layoutType == VIEW_TYPE.LIST) {
    return (
      <>
        <AggDataListItem
          aggdata={editedAggData}
          handleClick={handleClick}
          currentUserRight={currentUserRight}
          displayedFromCollection={displayedFromCollection}
          onPinUnpin={handlePinUnpinAction}
          onAddReminder={handleAddReminder}
          onAddToCollection={onAddToCollection}
          onDelete={handleDelete}
          onEdit={handleEditClicked}
          onSave={handleSave}
          onAnnotate={handleAnnotateClicked}
          onArchiveUnarchive={handleArchiveAction}
          onRemoveFromSharedCollection={handleUnshareFromCollection}
          onComment={handleComment}
        />
        {youtubeVideoUrlToOpen && (
          <YoutubePlayerModal
            url={youtubeVideoUrlToOpen}
            onClose={() => {
              setYoutubeVideoUrlToOpen(null);
            }}
          />
        )}
        <AnnotateAggdataModal
          open={openAnnotateModal}
          aggdata={editedAggData}
          handleModalClose={() => {
            setOpenAnnotateModal(false);
          }}
        />
        <EditAggdataPreviewModal
          open={openEditModal}
          aggdata={editedAggData}
          handleModalClose={() => {
            setOpenEditModal(false);
          }}
        />
      </>
    );
  } else if (layoutType == VIEW_TYPE.FEED) {
    return (
      <Box
        sx={{
          width: "80%",
        }}
      >
        <AggDataFeedItem
          aggdata={editedAggData}
          handleClick={handleClick}
          currentUserRight={currentUserRight}
          displayedFromCollection={displayedFromCollection}
          noteLayoutProps={noteLayoutProps}
          noteTasks={noteTasks}
          handleNoteTasksChanged={handleNoteTasksChanged}
          remindersView={getRemindersView}
          onComment={handleComment}
          onAddReminder={handleAddReminder}
          onAddToCollection={onAddToCollection}
          onArchiveUnarchive={handleArchive}
          onDelete={handleDelete}
          onPinUnpin={handlePinUnpinAction}
          onEdit={handleEditClicked}
          onSave={handleSave}
          onAnnotate={handleAnnotateClicked}
        />
        {youtubeVideoUrlToOpen && (
          <YoutubePlayerModal
            url={youtubeVideoUrlToOpen}
            onClose={() => {
              setYoutubeVideoUrlToOpen(null);
            }}
          />
        )}
        <AnnotateAggdataModal
          open={openAnnotateModal}
          aggdata={editedAggData}
          handleModalClose={() => {
            setOpenAnnotateModal(false);
          }}
        />
        <EditAggdataPreviewModal
          open={openEditModal}
          aggdata={editedAggData}
          handleModalClose={() => {
            setOpenEditModal(false);
          }}
        />
      </Box>
    );
  }
};

export default React.memo(AggData);

interface AggDataFeedItemBottomContainerProps {
  aggdata: AggDataObj;
  currentUserRight: UserAccessRight;
  onComment: () => void;
  onSave: () => void;
}

const AggDataFeedItemBottomContainer = ({
  aggdata,
  currentUserRight,
  onComment,
  onSave,
}: AggDataFeedItemBottomContainerProps) => {
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );
  const publicCollectionOpened: number = useSelector(
    (state: RootState) => state.view.publicCollectionOpened
  );
  const currentlyOpenedCollectionCurrentUserRight: number = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollectionCurrentUserRight
  );
  const { t } = useTranslation();

  return (
    <>
      {((isAggdataShared(aggdata) &&
        Boolean(aggdata.commentsCount) &&
        aggdata.commentsCount > 0) ||
        (currentlyOpenedCollection && currentlyOpenedCollection.isPublic)) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            flexDirection: "row",
            alignItems: "center",
            paddingRight: "4px",
          }}
        >
          {publicCollectionOpened &&
            (!currentUser ||
              currentUserRight == UserAccessRight.READER_ACCESS_RIGHT) && (
              <IconButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  onSave();
                }}
              >
                <Tooltip
                  key="edit"
                  arrow
                  title={styledTooltipTitle(t("save_to_my_memento_title"))}
                >
                  <BookmarkIcon
                    sx={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </Tooltip>
              </IconButton>
            )}
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              onComment();
            }}
          >
            <CommentIcon
              sx={{
                width: "18px",
                height: "18px",
              }}
            ></CommentIcon>
            {Boolean(aggdata.commentsCount) && aggdata.commentsCount > 0 && (
              <Typography
                sx={{
                  fontWeight: 450,
                  marginLeft: "2px",
                  fontSize: "1.1rem",
                }}
              >
                {"" + aggdata.commentsCount + ""}
              </Typography>
            )}
          </IconButton>
        </Box>
      )}
    </>
  );
};

interface AggDataFeedItemProps {
  aggdata: AggDataObj;
  displayedFromCollection: boolean;
  currentUserRight: number;
  handleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  noteTasks: NoteTask[];
  handleNoteTasksChanged: (noteTasks: NoteTask[]) => void;
  remindersView: () => HTMLDivElement;
  noteLayoutProps: any;
  onAddToCollection: () => void;
  onPinUnpin: () => void;
  onAddReminder: () => void;
  onDelete: () => void;
  onArchiveUnarchive: () => void;
  onRemoveFromSharedCollection: () => void;
  onComment: () => void;
  onEdit: () => void;
  onAnnotate: () => void;
  onSave: () => void;
}

const AggDataFeedItem = ({
  aggdata,
  displayedFromCollection,
  currentUserRight,
  handleClick,
  onEdit,
  onAnnotate,
  noteTasks,
  handleNoteTasksChanged,
  remindersView,
  onAddToCollection,
  onPinUnpin,
  onAddReminder,
  onDelete,
  onArchiveUnarchive,
  onRemoveFromSharedCollection,
  onComment,
  onSave,
  noteLayoutProps,
}: AggDataFeedItemProps) => {
  const [isImgLoading, setIsImgLoading] = useState(false);

  console.log(
    "Aggdata feed item " +
      aggdata.annotation +
      "  " +
      JSON.stringify(aggdata.tags)
  );
  const editableAggdataNote = useSelector(
    (state: RootState) => state.aggdataReducer.editableAggdataNote
  );

  const [popupMenuAnchorEl, setPopupMenuAnchorEl] = useState(null);

  const openPopupMenu = React.useCallback(
    (e: any) => {
      setPopupMenuAnchorEl(e.currentTarget);
    },
    [setPopupMenuAnchorEl]
  );

  const getMainItemLayout = () => {
    if (
      editableAggdataNote &&
      editableAggdataNote.id == aggdata.id &&
      aggdata.dataType == DataType.NOTE_TYPE
    ) {
      return (
        //we return an empty div, as in this case NoteEditor should be opened
        <></>
      );
    } else {
      if (aggdata.dataType == DataType.WEB_URL) {
        const previewInformation = getLinkPreviewInformation(aggdata);
        var image = (
          <InsertLinkIcon
            style={{
              width: "100%",
              height: "70px",
              background: "#faf6e9",
              borderRadius: "12px",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          />
        );
        var title = "";
        var description = "";
        var hostUrl = "";
        if (previewInformation) {
          title = previewInformation.title!!;
          description = previewInformation.description!!;
          hostUrl = previewInformation.hostUrl;
          if (previewInformation.imgUrl === "None") {
            image = (
              <InsertLinkIcon
                style={{
                  width: "100%",
                  height: "70px",
                  background: "#faf6e9",
                  borderRadius: "12px",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              />
            );
          } else {
            image = (
              <img
                src={previewInformation.imgUrl}
                style={{
                  paddingTop: "12px",
                  paddingBottom: "8px",
                  width: "100%",
                  padding: "12px",
                  height: "auto",
                  borderRadius: "24px",
                  maxWidth: "512px",
                  maxHeight: "512px",
                  objectFit: "cover",
                }}
                loading="lazy"
              />
            );
          }
        } else {
          description = aggdata.url;
        }
        return (
          <AggDataLayout {...noteLayoutProps} disableToolbar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "8px",
                }}
              >
                <AggDataFeedItemHeader aggdata={aggdata} />
              </Box>
              {(noteLayoutProps.isHovering || Boolean(popupMenuAnchorEl)) && (
                <IconButton
                  sx={{
                    marginRight: "16px",
                    width: "28px",
                    height: "28px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openPopupMenu(e);
                    // setOpenAnnotateModal(true);
                  }}
                >
                  <MoreHorizIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconButton>
              )}
            </Box>

            <Box
              sx={{
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            >
              <AggDataTagsContainer aggdata={aggdata} readOnly />
            </Box>

            <ExpandableText text={aggdata.annotation} />

            <Typography
              sx={{
                display: "-webkit-box",
                fontWeight: 500,
                marginLeft: "12px",
                fontSize: "1.4rem",
                overflow: "hidden",
              }}
            >
              {" "}
              {highlightText(title, "")}{" "}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {image}
            </div>
            <AggDataContent>
              {highlightText(description === "None" ? "" : description, "")}
            </AggDataContent>
            <Typography
              variant="h6"
              color="gray"
              sx={{ fontWeight: "400", padding: "0px 12px 8px 12px" }}
            >
              {hostUrl}
            </Typography>
            {remindersView()}
            <AggDataFeedItemBottomContainer
              aggdata={aggdata}
              currentUserRight={currentUserRight}
              onComment={onComment}
              onSave={onSave}
            />
          </AggDataLayout>
        );
      } else if (aggdata.dataType === DataType.AUDIO_TYPE) {
        return (
          <AggDataLayout {...noteLayoutProps} disableToolbar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                padding: "8px",
              }}
            >
              <AggDataFeedItemHeader aggdata={aggdata} />
              {(noteLayoutProps.isHovering || Boolean(popupMenuAnchorEl)) && (
                <IconButton
                  sx={{
                    marginRight: "16px",
                    width: "28px",
                    height: "28px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openPopupMenu(e);
                    // setOpenAnnotateModal(true);
                  }}
                >
                  <MoreHorizIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconButton>
              )}
            </Box>

            <Box
              sx={{
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            >
              <AggDataTagsContainer aggdata={aggdata} readOnly />
            </Box>

            <ExpandableText text={aggdata.annotation} />

            <AggDataTitle>{aggdata.file?.fileName}</AggDataTitle>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "end",
                padding: "8px",
              }}
            >
              <KeyboardVoiceIcon
                sx={{
                  color: colorMemento,
                  alignContent: "center",
                }}
              />
              <Typography
                style={{
                  fontSize: "1.3rem",
                  textAlign: "end",
                  alignItems: "center",
                }}
              >
                {" "}
                {getInChronometerFormat(aggdata.file?.length)}
              </Typography>
            </Box>
            <AggDataFeedItemBottomContainer
              aggdata={aggdata}
              currentUserRight={currentUserRight}
              onComment={onComment}
              onSave={onSave}
            />
          </AggDataLayout>
        );
      } else if (aggdata.dataType === DataType.IMAGE_TYPE) {
        return (
          <AggDataLayout {...noteLayoutProps} disableToolbar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "8px",
                }}
              >
                <AggDataFeedItemHeader aggdata={aggdata} />
              </Box>
              {(noteLayoutProps.isHovering || Boolean(popupMenuAnchorEl)) && (
                <IconButton
                  sx={{
                    marginRight: "16px",
                    width: "28px",
                    height: "28px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openPopupMenu(e);
                    // setOpenAnnotateModal(true);
                  }}
                >
                  <MoreHorizIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            >
              <AggDataTagsContainer aggdata={aggdata} readOnly />
            </Box>

            <ExpandableText text={aggdata.annotation} />

            <AggDataImageContent>
              <LazyLoad height={250} offset={400} once>
                <img
                  src={getImageForDataId(aggdata.id)}
                  style={{
                    width: "auto",
                    maxWidth: "100%",
                    maxHeight: "512px",
                    borderRadius: "8px",
                    display: isImgLoading ? "none" : "block",
                  }}
                  onLoadStart={() => {
                    setIsImgLoading(true);
                  }}
                  onLoad={() => {
                    setIsImgLoading(false);
                  }}
                />
              </LazyLoad>
              {/*             {isHovering && getOwnerAvatar()}
               */}{" "}
            </AggDataImageContent>
            {remindersView()}
            <AggDataFeedItemBottomContainer
              aggdata={aggdata}
              currentUserRight={currentUserRight}
              onComment={onComment}
              onSave={onSave}
            />
          </AggDataLayout>
        );
      } else if (aggdata.dataType === DataType.VIDEO_TYPE) {
        return (
          <AggDataLayout {...noteLayoutProps} disableToolbar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                padding: "8px",
              }}
            >
              <AggDataFeedItemHeader aggdata={aggdata} />
              {(noteLayoutProps.isHovering || Boolean(popupMenuAnchorEl)) && (
                <IconButton
                  sx={{
                    marginRight: "16px",
                    width: "28px",
                    height: "28px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openPopupMenu(e);
                    // setOpenAnnotateModal(true);
                  }}
                >
                  <MoreHorizIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconButton>
              )}
            </Box>

            <Box
              sx={{
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            >
              <AggDataTagsContainer aggdata={aggdata} readOnly />
            </Box>

            <ExpandableText text={aggdata.annotation} />

            {/* {aggdata.annotation && (
              <Typography
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  fontSize: "1.4rem",
                  padding: "16px"
                }}
              >
              </Typography>
            )} */}
            {/*             <AggDataContent expandText>{aggdata.annotation}</AggDataContent>
             */}
            <AggDataImageContent>
              <LazyLoad height={250} offset={400} once>
                <img
                  src={getImageForDataId(aggdata.id)}
                  style={{
                    width: "auto",
                    maxWidth: "100%",
                    maxHeight: "512px",
                    borderRadius: "8px",
                    display: isImgLoading ? "none" : "block",
                  }}
                  onLoadStart={() => {
                    setIsImgLoading(true);
                  }}
                  onLoad={() => {
                    setIsImgLoading(false);
                  }}
                />
              </LazyLoad>
              {/*             {isHovering && getOwnerAvatar()}
               */}{" "}
              <IconButton
                sx={{
                  position: "absolute",
                }}
              >
                <PlayCircleFilledWhiteRoundedIcon
                  sx={{
                    color: "white",
                    width: "48px",
                    height: "48px",
                  }}
                ></PlayCircleFilledWhiteRoundedIcon>
              </IconButton>
            </AggDataImageContent>
            {remindersView()}
            <AggDataFeedItemBottomContainer
              aggdata={aggdata}
              currentUserRight={currentUserRight}
              onComment={onComment}
              onSave={onSave}
            />
          </AggDataLayout>
        );
      } else if (aggdata.dataType === DataType.FILE_TYPE) {
        return (
          <AggDataLayout {...noteLayoutProps} disableToolbar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                padding: "8px",
              }}
            >
              <AggDataFeedItemHeader aggdata={aggdata} />
              {(noteLayoutProps.isHovering || Boolean(popupMenuAnchorEl)) && (
                <IconButton
                  sx={{
                    marginRight: "16px",
                    width: "28px",
                    height: "28px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openPopupMenu(e);
                    // setOpenAnnotateModal(true);
                  }}
                >
                  <MoreHorizIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconButton>
              )}
            </Box>

            <Box
              sx={{
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            >
              <AggDataTagsContainer aggdata={aggdata} readOnly />
            </Box>

            <ExpandableText text={aggdata.annotation} />

            <AggDataFileImgPreviewContent>
              <div
                style={{
                  backgroundColor: colorMementoBeige,
                  width: "100%",
                  height: "12px",
                }}
              ></div>
              <img
                src={getImageForDataId(aggdata.id)}
                style={{
                  width: "70%",
                }}
                loading="lazy"
              />
            </AggDataFileImgPreviewContent>
            <AggDataTitle>
              {highlightText(aggdata.file?.fileName!!, "")}
            </AggDataTitle>
            <AggDataFilePagesInfoContainer>
              <AggDataSubTitle> {aggdata?.file?.pagesCount}</AggDataSubTitle>
              <MenuBookIcon style={{ color: colorGreyMemento }} />
            </AggDataFilePagesInfoContainer>
            {remindersView()}
            <AggDataFeedItemBottomContainer
              aggdata={aggdata}
              currentUserRight={currentUserRight}
              onComment={onComment}
              onSave={onSave}
            />
          </AggDataLayout>
        );
      } else {
        return (
          <AggDataLayout {...noteLayoutProps} disableToolbar>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                padding: "8px",
              }}
            >
              <AggDataFeedItemHeader aggdata={aggdata} />
              {(noteLayoutProps.isHovering || Boolean(popupMenuAnchorEl)) && (
                <IconButton
                  sx={{
                    marginRight: "16px",
                    width: "28px",
                    height: "28px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openPopupMenu(e);
                    // setOpenAnnotateModal(true);
                  }}
                >
                  <MoreHorizIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconButton>
              )}
            </Box>

            <Box
              sx={{
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            >
              <AggDataTagsContainer aggdata={aggdata} readOnly />
            </Box>

            <ExpandableText text={aggdata.annotation} />

            {aggdata?.note?.noteimageSet &&
              aggdata?.note?.noteimageSet.length > 0 && (
                <NoteImagePreview
                  noteImageIds={aggdata?.note?.noteimageSet}
                  previewType={PreviewType.FEED}
                  aggData={aggdata}
                />
              )}
            {aggdata?.note?.noteTitle &&
              aggdata?.note?.noteTitle.length > 0 && (
                <AggDataTitle>
                  {highlightText(aggdata?.note?.noteTitle!!, "")}
                </AggDataTitle>
              )}
            {aggdata?.note?.noteContent &&
              aggdata?.note?.noteContent.length > 0 && (
                <AggDataContent
                  style={{
                    marginTop: "8px",
                  }}
                >
                  {parse(
                    aggdata?.note?.noteContent
                      ? aggdata?.note?.noteContent
                      : "<br/><br/>"
                  )}
                </AggDataContent>
              )}

            {noteTasks.length > 0 && (
              <>
                <NoteTasksList
                  readOnly={true}
                  noteTasksArray={noteTasks}
                  onNoteTasksChange={handleNoteTasksChanged}
                />
                <div style={{ height: "8px" }}></div>
              </>
            )}

            {remindersView()}
            <AggDataFeedItemBottomContainer
              aggdata={aggdata}
              currentUserRight={currentUserRight}
              onComment={onComment}
              onSave={onSave}
            />
          </AggDataLayout>
        );
      }
    }
  };

  return (
    <>
      {getMainItemLayout()}
      {popupMenuAnchorEl && (
        <AggdataPopupMenu
          handlePopupClose={() => setPopupMenuAnchorEl(null)}
          displayedFromCollection={displayedFromCollection}
          aggdata={aggdata}
          currentUserRight={currentUserRight}
          anchorEl={popupMenuAnchorEl}
          onAddToCollection={onAddToCollection}
          onArchive={onArchiveUnarchive}
          onDelete={onDelete}
          onPinUnpin={onPinUnpin}
          onAddReminder={onAddReminder}
          onEdit={onEdit}
          onAnnotate={onAnnotate}
          onComment={onComment}
        />
      )}
    </>
  );
};

interface AggDataListItemProps {
  aggdata: AggDataObj;
  handleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  currentUserRight: number;
  displayedFromCollection: boolean;
  onAddToCollection: () => void;
  onPinUnpin: () => void;
  onAddReminder: () => void;
  onDelete: () => void;
  onEdit: () => void;
  onSave: () => void;
  onAnnotate: () => void;
  onArchiveUnarchive: () => void;
  onRemoveFromSharedCollection: () => void;
  onComment: () => void;
}

const AggDataListItem = ({
  aggdata,
  handleClick,
  currentUserRight,
  displayedFromCollection,
  onAddToCollection,
  onPinUnpin,
  onEdit,
  onAnnotate,
  onAddReminder,
  onDelete,
  onArchiveUnarchive,
  onRemoveFromSharedCollection,
  onComment,
  onSave,
}: AggDataListItemProps) => {
  const [displayToolbar, setDisplayToolbar] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  const currentlyOpenedCollectionCurrentUserRight: number = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollectionCurrentUserRight
  );
  const isDisplayedFromRemindersContainer = useSelector(
    (state: RootState) => state.view.displayTimepickerOnAddingNewItems
  ).displayTimePicker;
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );

  const publicCollectionOpened: number = useSelector(
    (state: RootState) => state.view.publicCollectionOpened
  );

  const showConfidentialityFilterGlobal = useSelector(
    (state: RootState) => state.view.showConfidentialityFilter
  );
  const [
    shoConfidentialityFilter,
    setShowConfidentialityFilter,
  ] = React.useState(showConfidentialityFilterGlobal);
  const [displayVisibilityHint, setDisplayVisibilityHint] = React.useState(
    false
  );

  const getItemEmoji = React.useCallback(() => {
    if (aggdata.dataType == DataType.WEB_URL) {
      return "🔗";
    } else if (aggdata.dataType == DataType.IMAGE_TYPE) {
      return "🖼️";
    } else if (aggdata.dataType == DataType.FILE_TYPE) {
      return "📃";
    } else if (aggdata.dataType == DataType.NOTE_TYPE) {
      return "📝";
    } else if (aggdata.dataType == DataType.AUDIO_TYPE) {
      return "🔈";
    } else if (aggdata.dataType == DataType.VIDEO_TYPE) {
      return "📹";
    }
  }, [aggdata]);

  const getTitle = React.useCallback(() => {
    if (aggdata.dataType == DataType.WEB_URL) {
      const previewInformation = getLinkPreviewInformation(aggdata);
      return previewInformation?.title;
    } else if (aggdata.dataType == DataType.IMAGE_TYPE) {
      return "Image";
    } else if (
      aggdata.dataType == DataType.AUDIO_TYPE ||
      aggdata.dataType == DataType.VIDEO_TYPE
    ) {
      return aggdata.file?.fileName;
    } else if (aggdata.dataType == DataType.FILE_TYPE) {
      return aggdata.file?.fileName;
    } else if (aggdata.dataType == DataType.NOTE_TYPE) {
      return aggdata.note?.noteTitle;
    }
  }, [aggdata]);

  const getDescription = React.useCallback(() => {
    var description = "";
    if (aggdata.dataType == DataType.WEB_URL) {
      if (aggdata.annotation) {
        description = aggdata.annotation;
      }
      const previewInformation = getLinkPreviewInformation(aggdata);
      if (previewInformation?.description) {
        description += " | " + previewInformation?.description;
      }
      return description;
    } else if (aggdata.dataType == DataType.IMAGE_TYPE) {
      if (aggdata.annotation) {
        description = aggdata.annotation;
      }
      if (aggdata.dataContent) {
        description += " | " + aggdata.dataContent;
      }
      return description;
    } else if (aggdata.dataType == DataType.AUDIO_TYPE) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            padding: "8px",
          }}
        >
          {aggdata.annotation && <>{aggdata.annotation + " "}</>}

          <KeyboardVoiceIcon
            sx={{
              color: colorMemento,
              alignContent: "center",
            }}
          />
          {getInChronometerFormat(Number(aggdata.file?.length))}
        </Box>
      );
    } else if (aggdata.dataType == DataType.VIDEO_TYPE) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            padding: "8px",
          }}
        >
          {aggdata.annotation && <>{aggdata.annotation + " "}</>}

          <PlayArrowRoundedIcon
            sx={{
              color: colorMemento,
              alignContent: "center",
            }}
          />
          {getInChronometerFormat(Number(aggdata.file?.length))}
        </Box>
      );
    } else if (aggdata.dataType == DataType.FILE_TYPE) {
      return aggdata.annotation;
    } else if (aggdata.dataType == DataType.NOTE_TYPE) {
      if (aggdata.note?.noteContent) {
        return parse(aggdata.note?.noteContent!!);
      } else {
        return "<br/><br/>";
      }
    }
  }, [aggdata]);

  const getImagePreview = React.useCallback(() => {
    if (aggdata.dataType == DataType.WEB_URL) {
      const previewInformation = getLinkPreviewInformation(aggdata);
      return previewInformation?.imgUrl;
    } else if (
      aggdata.dataType == DataType.IMAGE_TYPE ||
      aggdata.dataType == DataType.FILE_TYPE ||
      aggdata.dataType == DataType.VIDEO_TYPE
    ) {
      return getImageForDataId(aggdata.id);
    } else if (
      aggdata.dataType == DataType.NOTE_TYPE &&
      aggdata.note?.noteimageSet &&
      aggdata.note?.noteimageSet.length > 0
    ) {
      return getNoteImageForId(aggdata.note?.noteimageSet[0].id);
    } else return null;
  }, [aggdata]);

  const handleAggdataClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleClick(e);
    },
    [handleClick, aggdata]
  );

  const pinScore = !displayedFromCollection
    ? "allItemsPinScore"
    : "listItemsPinScore";

  const isAggdataSharedAndDisplayedFromCollection =
    currentlyOpenedCollection &&
    currentlyOpenedCollection.sharedUsers &&
    currentlyOpenedCollection.sharedUsers.length > 0 &&
    aggdata?.collection &&
    aggdata?.sharedUsers &&
    aggdata?.sharedUsers.length > 0;

  React.useEffect(() => {
    setShowConfidentialityFilter(showConfidentialityFilterGlobal);
  }, [showConfidentialityFilterGlobal]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {displayToolbar &&
        (!currentlyOpenedCollection ||
          currentlyOpenedCollectionCurrentUserRight > 0) &&
        currentUserRight > 0 && (
          <Paper
            onMouseEnter={(event: any) => {
              setDisplayToolbar(true);
            }}
            onMouseLeave={() => {
              setDisplayToolbar(false);
            }}
            sx={{
              height: "36px",
              position: "sticky",
              display: "flex",
              flexDirection: "row",
              background: colorMementoBeige,
              marginTop: "-36px",
              alignItems: "center",
              padding: "4px",
            }}
          >
            {/* MERZOUGA */}

            {
              <>
                <SharedUserAvatar user={aggdata.owner} size="small" />
                <Typography
                  sx={{
                    fontWeight: 450,
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                >
                  {" "}
                  {formatIso8601date(aggdata.created)}{" "}
                </Typography>
                <Divider orientation="vertical" />
              </>
            }

            <Tooltip
              key="edit"
              arrow
              title={styledTooltipTitle(t("toolbar_edit_tool_title"))}
            >
              <IconButton onClick={onEdit}>
                <NotesIcon />
              </IconButton>
            </Tooltip>

            {/*     {currentUserRight == UserAccessRight.OWNER_ACCESS_RIGHT && (
              <Tooltip
                key="annotate"
                arrow
                title={styledTooltipTitle(t("toolbar_annotate_tool_title"))}
              >
                <IconButton onClick={onAnnotate}>
                  <NotesIcon />
                </IconButton>
              </Tooltip>
            )} */}

            {isAggdataShared(aggdata) && (
              <Tooltip
                key="comment"
                arrow
                title={styledTooltipTitle(t("toolbar_comment_tool_title"))}
              >
                <IconButton onClick={onComment}>
                  <CommentIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip
              key="add-reminder"
              arrow
              title={styledTooltipTitle(t("toolbar_add_reminder_tool_title"))}
            >
              <IconButton onClick={onAddReminder}>
                <AlarmAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              key="add-to-collection"
              arrow
              title={styledTooltipTitle(
                t("toolbar_add_to_collection_tool_title")
              )}
            >
              <IconButton onClick={onAddToCollection}>
                <PlaylistAddIcon />
              </IconButton>
            </Tooltip>
            {!isDisplayedFromRemindersContainer && (
              <>
                {Number(aggdata[pinScore]) > 0 ? (
                  <Tooltip
                    key="pin-unpin"
                    arrow
                    title={styledTooltipTitle(t("action_unpin"))}
                  >
                    <IconButton onClick={onPinUnpin}>
                      <PushPinOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    key="pin-unpin"
                    arrow
                    title={styledTooltipTitle(t("action_pin"))}
                  >
                    <IconButton onClick={onPinUnpin}>
                      <PushPinIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {(!isDisplayedFromRemindersContainer ||
              currentUser.id == aggdata?.owner.id) &&
              !isAggdataSharedAndDisplayedFromCollection && (
                <>
                  {aggdata.isArchived ? (
                    <Tooltip
                      key="archive"
                      arrow
                      title={styledTooltipTitle(
                        t("toolbar_unarchive_tool_title")
                      )}
                    >
                      <IconButton onClick={onArchiveUnarchive}>
                        <UnarchiveIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      key="archive"
                      arrow
                      title={styledTooltipTitle(
                        t("toolbar_archive_tool_title")
                      )}
                    >
                      <IconButton onClick={onArchiveUnarchive}>
                        <ArchiveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}

            {isAggdataSharedAndDisplayedFromCollection && (
              <Tooltip
                key="remove-from-collection"
                arrow
                title={styledTooltipTitle(
                  t("remove_from_shared_collection_action")
                )}
              >
                <IconButton onClick={onRemoveFromSharedCollection}>
                  <RemoveCircleRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
            {(!isDisplayedFromRemindersContainer ||
              currentUser.id == aggdata?.owner.id) &&
              !isAggdataSharedAndDisplayedFromCollection && (
                <Tooltip
                  key="delete"
                  arrow
                  title={styledTooltipTitle(t("toolbar_delete_tool_title"))}
                >
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
          </Paper>
        )}

      <ListItemButton
        onClick={handleAggdataClick}
        onMouseEnter={(event: any) => {
          setDisplayToolbar(true);
        }}
        onMouseLeave={() => {
          setDisplayToolbar(false);
        }}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {shoConfidentialityFilter && (
            <div
              onMouseEnter={() => {
                setDisplayVisibilityHint(true);
              }}
              onMouseLeave={() => {
                setDisplayVisibilityHint(false);
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                setShowConfidentialityFilter(false);
                setDisplayVisibilityHint(false);
              }}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backdropFilter: "blur(20px)",
                zIndex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
              }}
            >
              {displayVisibilityHint && (
                <VisibilityIcon sx={{ width: "48px", height: "48px" }} />
              )}
            </div>
          )}

          <Typography>{getItemEmoji()}</Typography>
          {getImagePreview() && (
            <img
              src={getImagePreview()}
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "4px",
                marginLeft: "12px",
                objectFit: "cover",
              }}
              loading="lazy"
            />
          )}
          {/*    <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "12px",
        }}
      > */}
          <Typography
            sx={{
              display: "-webkit-box",
              fontWeight: 500,
              marginLeft: "12px",
              fontSize: "1.4rem",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {" "}
            {getTitle()}
          </Typography>
          <Typography
            sx={{
              display: "-webkit-box",
              marginLeft: "12px",
              fontSize: "1.4rem",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {getDescription()}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {((isAggdataShared(aggdata) &&
            Boolean(aggdata.commentsCount) &&
            aggdata.commentsCount > 0) ||
            (currentlyOpenedCollection &&
              currentlyOpenedCollection.isPublic)) && (
            <Box>
              {publicCollectionOpened &&
                (!currentUser ||
                  currentUserRight == UserAccessRight.READER_ACCESS_RIGHT) && (
                  <IconButton
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onSave();
                    }}
                  >
                    <Tooltip
                      key="edit"
                      arrow
                      title={styledTooltipTitle(t("save_to_my_memento_title"))}
                    >
                      <BookmarkIcon
                        sx={{
                          width: "18px",
                          height: "18px",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                )}
              <IconButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  onComment();
                }}
              >
                <CommentIcon />

                <Typography
                  sx={{
                    fontWeight: 450,
                    marginLeft: "2px",
                  }}
                >
                  {aggdata.commentsCount > 0 ? aggdata.commentsCount : ""}
                </Typography>
              </IconButton>
            </Box>
          )}

          {aggdata &&
            aggdata.sharedUsers &&
            aggdata.sharedUsers.length > 0 &&
            !aggdata.collection?.isPublic && (
              <SharedUsersAvatars
                hideConfigureAccess
                sharedUsersList={aggdata?.collection.sharedUsers}
                size="small"
              />
            )}
          <CollectionBadge aggdata={aggdata} />
        </Box>
      </ListItemButton>
    </Box>
  );
};

interface TopBadgeProps {
  editedAggData: AggDataObj;
}

interface CollectionBadgeProps {
  aggdata: AggDataObj;
}

const CollectionBadge = ({ aggdata }: CollectionBadgeProps) => {
  const [
    showCollectionBadgeToolTip,
    setShowCollectionBadgeTooltip,
  ] = React.useState(false);
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );
  const { t } = useTranslation();
  const history = useHistory();
  var avatarColour = null;
  if (aggdata.collection) {
    avatarColour = stringToColour(aggdata.collection?.name);
  }
  return (
    <Box sx={{}}>
      {aggdata.collection && !currentlyOpenedCollection && (
        <Tooltip
          key="manage-access"
          open={showCollectionBadgeToolTip}
          arrow
          title={styledTooltipTitle(t("action_open"))}
        >
          <Typography
            variant="h5"
            onMouseEnter={() => {
              setShowCollectionBadgeTooltip(true);
            }}
            onMouseLeave={() => {
              setShowCollectionBadgeTooltip(false);
            }}
            onClick={(e) => {
              e.preventDefault();
              if (aggdata.collection?.isArchived) {
                history.push(
                  "/board/archives/collection/" + aggdata.collection.id
                );
              } else {
                history.push("/board/collection/" + aggdata.collection.id);
              }
              e.stopPropagation();
            }}
            sx={{
              borderRadius: "4px",
              backgroundColor: avatarColour.backgroundColor,
              color: avatarColour.textColor,
              fontSize: "1rem",
              padding: "4px",
              cursor: "pointer",
              display: "inline-block",
              width: "auto",
              textAlign: "center",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {aggdata.collection.name}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

const TopBadge = ({ editedAggData }: TopBadgeProps) => {
  const history = useHistory();
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );
  const { t } = useTranslation();
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );
  const [
    showCollectionBadgeToolTip,
    setShowCollectionBadgeTooltip,
  ] = React.useState(false);
  var avatarColour = null;
  if (editedAggData.collection) {
    avatarColour = stringToColour(editedAggData.collection?.name);
  }
  return (
    <>
      <div
        style={{
          marginTop: "4px",
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "center",
          top: "4px",
          right: "4px",
          marginBottom: "4px",
          marginLeft: "4px",
        }}
      >
        {/* We display the avatar when aggdata is shared and id displayed from its collection, or when the data is not owner by the user for example from reminders UI */}
        {isAggdataShared(editedAggData) &&
          currentUser &&
          (currentlyOpenedCollection ||
            currentUser.id != editedAggData.owner.id) && (
            <SharedUserAvatar
              user={editedAggData.owner}
              size="small"
            ></SharedUserAvatar>
          )}

        {/* display the collection badge when the item is not displayed from a collection */}
        {editedAggData.collection && !currentlyOpenedCollection && (
          <Tooltip
            key="manage-access"
            open={showCollectionBadgeToolTip}
            arrow
            title={styledTooltipTitle(t("action_open"))}
          >
            <Typography
              variant="h5"
              onMouseEnter={() => {
                setShowCollectionBadgeTooltip(true);
              }}
              onMouseLeave={() => {
                setShowCollectionBadgeTooltip(false);
              }}
              onClick={(e) => {
                e.preventDefault();
                if (editedAggData.collection?.isArchived) {
                  history.push(
                    "/board/archives/collection/" + editedAggData.collection.id
                  );
                } else {
                  history.push(
                    "/board/collection/" + editedAggData.collection.id
                  );
                }
                e.stopPropagation();
              }}
              style={{
                borderRadius: "4px",
                backgroundColor: avatarColour.backgroundColor,
                color: avatarColour.textColor,
                fontSize: "1.2rem",
                padding: "6px",
                marginTop: "6px",
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {isAggdataShared(editedAggData) && (
                <PeopleOutlineIcon
                  sx={{
                    marginRight: "6px",
                  }}
                />
              )}
              {editedAggData.collection.name}
            </Typography>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export const COMPUTE_NEXT_REMINDER = "compute_next_reminder";
export const COMPUTE_NEXT_REMINDER_REPLY = "compute_next_reminder_reply";

//Request to compute the days of the month that have reminders, and filters to only data that have reminders within the provide month
export const COMPUTE_DAYS_WITH_REMINDERS = "compute_days_with_reminders";
export const COMPUTE_DAYS_WITH_REMINDERS_REPLY =
  "compute_days_with_reminders_REPLY";

export const COMPUTE_REMINDERS_FOR_DAY = "compute_reminders_for_day";
export const COMPUTE_REMINDERS_FOR_DAY_REPLY =
  "compute_reminders_for_day_reply";

export const SEARCH_AGGDATA_WITH_KEYWORD = "search_aggdata_with_keyword";
export const SEARCH_AGGDATA_WITH_KEYWORD_REPLY =
  "search_aggdata_with_keyword_reply";

export const FILTER_AGGDATA_FOR_MAIN_CONTAINER =
  "filter_aggdata_for_main_container";
export const FILTER_AGGDATA_FOR_MAIN_CONTAINER_REPLY =
  "filter_aggdata_for_main_container_reply";
export const FILTER_AGGDATA_FOR_TAG_REPLY=
"filter_aggdata_for_tag_container";
export const FILTER_AGGDATA_FOR_TAG=
  "filter_aggdata_for_tag_container_reply";
export const SORT_COLLECTIONS = "sort_collections";
export const SORT_COLLECTIONS_REPLY = "sort_collections_reply";

export const BUILD_SHARED_USERS_LIST = "build_shared_users_list";
export const BUILD_SHARED_USERS_LIST_REPLY = "build_shared_users_list_reply";

export const FILTER_COLLECTIONS = "filter_collections";
export const FILTER_COLLECTIONS_REPLY = "filter_collections_reply";
import React, { useCallback } from "react";
import styled from "styled-components";
import DataCaptureIllustration from "icons/mem_data_capture_withbg.svg";
import { useTheme } from "@mui/material/styles";
import GetItOnPlayStore from "icons/google-play-badge.png";
import { APP_PLAYSTORE_LINK } from "configuration";
import { useTranslation } from "react-i18next";
import CarouselContainer from "pages/Landing/Containers/CarouselContainer";
import RealTimeSyncContainer from "pages/Landing/Containers/RealTimeSyncContainer";
import SecurityContainer from "pages/Landing/Containers/SecurityContainer";
import CollaborateContainer from "pages/Landing/Containers/CollaborateContainer";
import IntegrationsContainer from "pages/Landing/Containers/IntegrationsContainer";
import Divider from "@mui/material/Divider";
import { Box, Typography } from "@mui/material";
import {
  colorMemento,
  colorMementoBeige,
  colorMementoHovered,
} from "shared/colors";
import PricingCards from "components/Pricing/PricingCards";
//import DevicesMockup from "illustrations/devices_mockup_no_bgd.png";
//import DevicesMockup from "illustrations/landing_illustration.png";
import DevicesMockup from "illustrations/device_mockup.png";
import { useHistory } from "react-router-dom";
import { PRICING } from "navigation/Constants";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button } from "@mui/material";
import { REGISTER } from "navigation/Constants";
import { isMobile } from "react-device-detect";
import ContentOrganizationAiGif from "pages/Landing/illustrationgifs/auto_content_organization.gif";
import MementoMagnetIllustration from "illustrations/memento_magnet.png";
import MementoZenIllustration from "illustrations/zen_illustration.png";
import PlantIllustration from "illustrations/plan_illustration.png";
import Plant2Illustration from "illustrations/plant_2_illustration.png";
import ProblematicStepsComponent from "./Containers/ProblematicStepsComponent";

export const MAIN_TITLE_TEXT_SIZE = "4rem";
export const TITLE_TEXT_SIZE = "3rem";
export const SUBTITLE_TEXT_SIZE = "2rem";

export const MAIN_TITLE_TEXT_SIZE_780PX = "3rem";
export const TITLE_TEXT_SIZE_780PX = "2rem";
export const SUBTITLE_TEXT_SIZE_780PX = "1rem";

const CustomButton = styled.button`
  border: 1px solid black;
  color: back;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.8rem;
  background: ${colorMemento};
  color: black;
  padding: 8px;
  text-transform: uppercase;
  font-family: Trebuchet MS, sans-serif;
  padding-right: 88px;
  padding-left: 88px;
  :hover {
    background: #e3b443;
  }
`;

const Section = styled.section`
  display: flex;
  margin-top: 48px;
  flex-direction: column;
  justify-content: center;
  padding-right: 8%;
  padding-left: 8%;
  width: 100%;
  align-items: center;
  @media (max-width: 780px) {
    flex-direction: column;
    margin-top: 12px;
  }
`;

const DevicesMockupContainer = styled.img`
  width: 90%;
  align-items: center;
  margin-top: 2%;
  @media (max-width: 780px) {
    width: 100%;
    margin-top: 5%;
  }
`;

const ProblematicContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255, 253, 250, 1);
  @media (max-width: 780px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const ProblematicPointsContainer = styled.div`
  display: flex;
  flex-direction: rown;
  width: 100%;
  padding-right: 8%;
  padding-left: 8%;
  margin-bottom: 32px;
  margin-top: 48px;
  @media (max-width: 780px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const ProblematicStepsContainer = styled.div`
  display: flex;
  flex-direction: rown;
  justify-content: start;
  align-items: start;
  width: 100%;
  margin-bottom: 32px;
  margin-top: 120px;
  padding-right: 8%;
  padding-left: 8%;
  @media (max-width: 780px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const FirstSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 128px;
  padding-right: 8%;
  padding-left: 8%;
  @media (max-width: 780px) {
    flex-direction: column;
    margin-top: 10%;
    margin-bottom: 10%;
  }
`;

const FirstSectionTxtContainer = styled.text`
  width: 100%;
  font-size: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-family: Trebuchet MS, sans-serif;
  @media (max-width: 780px) {
    width: 100%;
    font-size: ${MAIN_TITLE_TEXT_SIZE_780PX};
  }
`;

const FirstSectionTxtSubContainer = styled.text`
  width: 90%;
  font-size: ${TITLE_TEXT_SIZE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media (max-width: 780px) {
    width: 100%;
    font-size: ${MAIN_TITLE_TEXT_SIZE_780PX};
  }
`;

const FirstSectionIllustrationContainer = styled.img`
  width: 800px;
  height: auto;
  @media (max-width: 780px) {
    width: 100%;
    margin-top: 48px;
    height: auto;
  }
`;

const AiOrganizationGifContainer = styled.img`
  max-height: 512px;
  width: auto;
  @media (max-width: 780px) {
    max-height: 1000px;
    width: auto;
    margin-top: 5%;
  }
`;

const FirstSectionSubtitle = styled.text`
  width: 100%;
  font-size: ${SUBTITLE_TEXT_SIZE};
  @media (max-width: 780px) {
    width: 100%;
    font-size: ${TITLE_TEXT_SIZE_780PX};
  }
`;

const TopLeftPlantContainer = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 250px;
  width: auto;
  @media (max-width: 780px) {
    max-width: 80px;
  }
`;

const GetItOnPlaystoreBadge = styled.img`
  width: 30%;
  margin-top: 2%;
`;

const SecondSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 90vh;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  @media (max-width: 780px) {
    flex-direction: column;
    margin-top: 10%;
    margin-bottom: 10%;
  }
`;

const ThirdSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PrivacyContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
`;

const RealTimeSyncIllustraitonContainer = styled.img`
  width: 35%;
  @media (max-width: 780px) {
    width: 100vw;
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const RealTimeSyncTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
`;

const titleStyle = {
  width: "70%",
  fontSize: "2rem",
  display: "inline",
  textAlign: "start",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Trebuchet MS, sans-serif",
  "@media (max-width: 780px)": {
    width: "100%",
  },
};

const subtitleStyle = {
  width: "70%",
  fontSize: "1.6rem",
  fontFamily: "Trebuchet MS, sans-serif",
  textAlign: "start",
  "@media (max-width: 780px)": {
    width: "100%",
  },
};

const stepsSubtitleStyle = {
  fontSize: "1.6rem",
  fontFamily: "Trebuchet MS, sans-serif",
  textAlign: "start",
  "@media (max-width: 780px)": {
    width: "100%",
  },
};

function Content() {
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const { t } = useTranslation();
  const history = useHistory();
  const redirectToPlaystore = useCallback(() => {
    window.open(APP_PLAYSTORE_LINK, "_blank");
  }, []);

  return (
    <>
      <Section>
        <Typography
          sx={{
            width: "70%",
            fontSize: MAIN_TITLE_TEXT_SIZE,
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Trebuchet MS, sans-serif",
            "@media (max-width: 780px)": {
              width: "95%",
              fontSize: "4rem",
              fontSize: MAIN_TITLE_TEXT_SIZE_780PX,
            },
          }}
        >
          {t("landing_page_main_title")}
        </Typography>

        <Typography
          variant="h5"
          sx={{
            width: "70%",
            fontSize: "2.8rem",
            fontFamily: "Trebuchet MS, sans-serif",
            textAlign: "center",
            fontWeight: 300,
            "@media (max-width: 780px)": {
              width: "95%",
              fontSize: TITLE_TEXT_SIZE_780PX,
            },
          }}
        >
          {t("landing_page_main_subtitle")}{" "}
          <span style={{ background: "black", color: primary }}>
            {t("app_name")}
          </span>
        </Typography>

        {/*  <Button
          variant="contained"
          sx={{
            marginTop: "24px",
            width: "30%",
            fontSize: "1.6rem",
            "@media (max-width: 780px)": {
              width: "80%",
            },
            background: { colorMemento },
          }}
          onClick={() => {
            if (isMobile) {
              redirectToPlaystore();
            } else {
              history.push(REGISTER);
            }
          }}
        >
          {t("try_for_free_title")}
        </Button> */}

        <CustomButton
          style={{
            marginTop: "24px",
          }}
          onClick={() => {
            if (isMobile) {
              redirectToPlaystore();
            } else {
              history.push(REGISTER);
            }
          }}
        >
          {" "}
          {t("try_for_free_title")}
        </CustomButton>
        <DevicesMockupContainer src={DevicesMockup} />
      </Section>

      <FirstSection>
        <FirstSectionTxtContainer>
          <FirstSectionTxtSubContainer>
            {t("landing_magnet_problematic_title")}
            <FirstSectionSubtitle>
              {t("landing_magnet_problematic_description_part1")}{" "}
              <span style={{ background: "black", color: primary }}>
                {t("app_name")}
              </span>{" "}
              {t("landing_magnet_problematic_description_part2")}
            </FirstSectionSubtitle>
          </FirstSectionTxtSubContainer>
          {/* <GetItOnPlaystoreBadge
            onClick={redirectToPlaystore}
            src={GetItOnPlayStore}
          ></GetItOnPlaystoreBadge> */}
        </FirstSectionTxtContainer>
        <FirstSectionIllustrationContainer
          style={{}}
          src={MementoMagnetIllustration}
          alt="Collect notes, links, screenshots, messages, emails, pdf files"
          title="Data capture and aggregation"
        ></FirstSectionIllustrationContainer>
      </FirstSection>

      <div
        style={{
          marginTop: "96px",
          height: "2px",
          width: "100%",
          background: "black",
        }}
      ></div>

      <ProblematicContentContainer>
        <TopLeftPlantContainer style={{}} src={PlantIllustration} />
        <img
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            maxWidth: "150px",
            width: "auto",
          }}
          src={Plant2Illustration}
        ></img>

        <Typography
          sx={{
            width: "100%",
            fontSize: TITLE_TEXT_SIZE,
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "48px",
            fontFamily: "Trebuchet MS, sans-serif",
          }}
        >
          {t("pick_digital_wellbeing_title")}
        </Typography>

        <img
          style={{
            marginTop: "48px",
            maxHeight: "300px",
            width: "auto",
          }}
          src={MementoZenIllustration}
        ></img>

        <ProblematicPointsContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "48px",
              alignItems: "center",
            }}
          >
            <Typography sx={titleStyle}>
              <span
                style={{
                  color: colorMemento,
                  background: "black",
                }}
              >
                {t("landing_problematic_2_title")}
              </span>
            </Typography>

            <Typography variant="h5" sx={subtitleStyle}>
              {t("landing_problematic_2_description")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "48px",
              alignItems: "center",
            }}
          >
            <Typography sx={titleStyle}>
              <span
                style={{
                  color: colorMemento,
                  background: "black",
                }}
              >
                {t("landing_problematic_1_title")}
              </span>
            </Typography>

            <Typography variant="h5" sx={subtitleStyle}>
              {t("landing_problematic_1_description")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "48px",
              alignItems: "center",
            }}
          >
            <Typography sx={titleStyle}>
              <span
                style={{
                  color: colorMemento,
                  background: "black",
                }}
              >
                {t("landing_problematic_3_title")}
              </span>
            </Typography>

            <Typography variant="h5" sx={subtitleStyle}>
              {t("landing_problematic_3_description")}
            </Typography>
          </div>
        </ProblematicPointsContainer>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "32px",
          }}
        >
          <CustomButton
            style={{
              marginTop: "24px",
            }}
            onClick={() => {
              window.open(
                "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10322198/"
              );
            }}
          >
            {" "}
            {/*  <Typography
            variant="h4"
            sx={{
              fontWeight: 400,
            }}
          > */}
            {t("learn_more_tilte")}
            {/*  </Typography> */}
          </CustomButton>
        </Box>
      </ProblematicContentContainer>
      <div
        style={{
          height: "2px",
          width: "100%",
          background: "black",
        }}
      ></div>

      <ProblematicStepsComponent />

      <FirstSection>
        <FirstSectionTxtContainer>
          <FirstSectionTxtSubContainer>
            {t("landing_section_1_title")}
            <FirstSectionSubtitle>
              {" "}
              {/*  <span style={{ background: "black", color: primary }}>
                {t("app_name")}
              </span> */}
              {t("landing_section_1_subtitle")}
              <span style={{ background: "black", color: primary }}>
                {t("landing_section_1_subtitle_2")}
              </span>
            </FirstSectionSubtitle>
          </FirstSectionTxtSubContainer>
          {/* <GetItOnPlaystoreBadge
            onClick={redirectToPlaystore}
            src={GetItOnPlayStore}
          ></GetItOnPlaystoreBadge> */}
        </FirstSectionTxtContainer>
        <AiOrganizationGifContainer
          style={{}}
          src={ContentOrganizationAiGif}
          alt="Collect notes, links, screenshots, messages, emails, pdf files"
          title="Data capture and aggregation"
        ></AiOrganizationGifContainer>
      </FirstSection>

      <SecondSection>
        <Divider light variant="middle" sx={{ opacity: 0 }} />
        {/*  <Typography
          variant="h4"
          sx={{
            fontSize: TITLE_TEXT_SIZE,
            width: "100%",
            textAlign: "center",
            fontFamily: "Trebuchet MS, sans-serif",
            marginBottom: "12px",
          }}
        >
          {t("landing_in_a_nutshell")}
        </Typography>
        <CarouselContainer /> */}
        <Divider
          light
          variant="middle"
          sx={{
            marginBottom: "3%",
            marginTop: "3%",
            opacity: 0,
            "@media (max-width: 780px)": {
              marginBottom: "10%",
              marginTop: "10%",
            },
          }}
        />
        <CollaborateContainer />
        {/*  <Divider
          light
          variant="middle"
          sx={{
            marginBottom: "5%",
            marginTop: "5%",
            opacity: 0,
            "@media (max-width: 780px)": {
              marginBottom: "10%",
              marginTop: "10%",
            },
          }}
        /> */}
        {/*         <RealTimeSyncContainer />
         */}
        <Divider
          light
          variant="middle"
          sx={{
            marginBottom: "5%",
            marginTop: "5%",
            opacity: 0,
            "@media (max-width: 780px)": {
              marginBottom: "10%",
              marginTop: "10%",
            },
          }}
        />
        <IntegrationsContainer />
        <Divider
          light
          variant="middle"
          sx={{
            marginBottom: "5%",
            marginTop: "5%",
            opacity: 0,
            "@media (max-width: 780px)": {
              marginBottom: "10%",
              marginTop: "10%",
            },
          }}
        />
        <SecurityContainer />
      </SecondSection>
      <ThirdSection>
        <Typography
          variant="h4"
          sx={{
            fontSize: TITLE_TEXT_SIZE,
            width: "100%",
            textAlign: "center",
            marginBottom: "12px",
          }}
        >
          {t("landing_pricing_title")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: SUBTITLE_TEXT_SIZE,
            fontFamily: "Trebuchet MS, sans-serif",
            textAlign: "center",
            fontWeight: 300,
          }}
        >
          {t("landing_pricing_subtitle")}
        </Typography>
        <Divider
          light
          variant="middle"
          sx={{ opacity: 0, paddingTop: "72px" }}
        />

        <PricingCards />

        <Typography
          variant="h5"
          sx={{
            width: "100%",
            fontSize: "2.4rem",
            fontFamily: "Trebuchet MS, sans-serif",
            fontWeight: 300,
            color: colorMemento,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            marginTop: "24px",
            "&:hover": {
              opacity: 0.6,
            },
          }}
          onClick={() => {
            window.open(PRICING, "_blank");
          }}
        >
          {t("pricing_compare_plans_title")}{" "}
          <ArrowRightAltIcon
            sx={{ marginLeft: "8px", width: "3rem", height: "3rem" }}
          />
        </Typography>
      </ThirdSection>
    </>
  );
}

export default Content;

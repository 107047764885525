import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FaceIcon from "@mui/icons-material/Face";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { colorMemento } from "shared/colors";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ChipItems from "components/UI/ChipsContainer/ChipItems";
import { Reminder } from "models/aggdata";
import useCallback from 'react';

interface ChipsContainerProps {
  reminders: Reminder[];
  readOnly?: boolean
  onReminderItemClicked: (reminder: Reminder) => void;
  onDeleteReminder: (reminder: Reminder) => void;
}

const ChipsContainer = ({
  reminders,
  readOnly = false,
  onReminderItemClicked,
  onDeleteReminder,
}: ChipsContainerProps) => {

  const [remindersArray, setRemindersArray] = React.useState(reminders);

  const handleDeleteReminder= React.useCallback((reminder: Reminder) => {
    const remindersArrayWithoutDeletedItem = remindersArray.filter((reminderFilter: Reminder) => {
      return !(reminder.triggerTime === reminderFilter.triggerTime && reminder.cycle === reminderFilter.cycle)
    })
    setRemindersArray(remindersArrayWithoutDeletedItem)
    onDeleteReminder(reminder)
  }, [onDeleteReminder, remindersArray, setRemindersArray]);

  React.useEffect(() => {
    setRemindersArray(reminders)
  }, [reminders]);

  return (
    <Autocomplete
      disabled
      multiple={true}
      id="tags-filled"
      options={reminders.map((option) => option.triggerTime)}
      getOptionLabel={(option) => option.reminders}
      defaultValue={reminders.map((option) => option.triggerTime)}
      freeSolo
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            InputProps={{ ...params.InputProps, disableUnderline: true }}
          />
        );
      }}
      renderTags={(value: readonly string[], getTagProps) =>
        remindersArray.map((reminder, index) => (
          <ChipItems
            key={reminder.triggerTime}
            readOnly={readOnly}
            onDeleteReminder={handleDeleteReminder}
            onClick={onReminderItemClicked}
            reminder={reminder}
          />
        ))
      }
    ></Autocomplete>
  );
};
export default React.memo(ChipsContainer);

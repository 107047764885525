import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link as TextLink } from "@mui/material";
import { colorMemento } from "shared/colors";
import { useHistory } from "react-router-dom";
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "navigation/Constants";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

interface CreateAccountConfirmationDialogProps {
  open: boolean;
  userFirstName: string;
  userEmail: string;
  googleAccessToken: string;
  handleCreateNewAccount: (googleAccessToken: string) => void;
  handleModalClose: (close: boolean) => void;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("mobile")]: {
        width: "80%",
      },
    },
    addButton: {
      marginEnd: "12px",
      marginStart: "12px",
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingTop: "24px",
    },
  })
);

export default function CreateAccountConfirmationDialog({
  open,
  userFirstName,
  userEmail,
  googleAccessToken,
  handleCreateNewAccount,
  handleModalClose,
}: CreateAccountConfirmationDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Title>Hi {userFirstName}</Title>
            <Typography variant="h5">
              {t("create_account_dialog_description", {
                user_email: userEmail
              })}
            </Typography>
            <Typography
              style={{
                marginBottom: "24px",
                fontSize: "1.2rem",
                marginTop: "16px",
              }}
              variant="subtitle1"
              component="div"
              gutterBottom
            >
              {t("privacy_and_terms_acknowledgement")}
              <TextLink
                style={{ color: colorMemento, cursor: "pointer" }}
                onClick={() => {
                  window.open(PRIVACY_POLICY, "_blank");
                }}
              >
                {" "}
                {t("privacy_policy")}
              </TextLink>{" "}
              {t("and")}
              <TextLink
                style={{ color: colorMemento, cursor: "pointer" }}
                onClick={() => {
                  window.open(TERMS_CONDITIONS, "_blank");
                }}
              >
                {" "}
                {t("terms_and_conditions")}
              </TextLink>

            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.addButton}
                color="primary"
                onClick={() => handleModalClose(false)}
                sx={{ marginRight: "12px" }}
              >
                <Typography variant="h6">{t("cancel")}</Typography>
              </Button>

              <Button
                className={classes.addButton}
                color="primary"
                variant="contained"
                onClick={() => {
                  handleCreateNewAccount(googleAccessToken);
                }}
              >
                <PersonOutlineOutlinedIcon
                  sx={{
                    color: "white",
                    height: "100%",
                    width: "24px",
                    paddingRight: "8px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ color: "white", paddingLeft: "4px", paddingTop: "4px" }}
                >
                  {t("create_account_action")}
                </Typography>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

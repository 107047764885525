import { SharedUser } from "models/user";

export const SET_MAX_PIN_SCORE = 'SET_MAX_PIN_SCORE';
export const SET_MAX_LAST_USED_SCORE = 'SET_MAX_LAST_USED_SCORE';
export const SET_SHARED_USERS_LIST = 'SET_SHARED_USERS_LIST';


export interface CollectionsState {
    maxPinScore: number;
    maxLastUsedScore: number;
    sharedUsers: SharedUser[];
}

export interface SetSharedUsersList {
    type: typeof SET_SHARED_USERS_LIST;
    payload: SharedUser[];
}

export interface SetMaxPinScore {
    type: typeof SET_MAX_PIN_SCORE;
    payload: number;
}

export interface SetMaxLastUsedScore {
    type: typeof SET_MAX_LAST_USED_SCORE;
    payload: number;
}

export type CollectionActionTypes =
    | SetMaxPinScore
    | SetMaxLastUsedScore
    | SetSharedUsersList;

export const setSharedUsersList = (sharedUsers: SharedUser[]): SetSharedUsersList => {
    return {
        type: SET_SHARED_USERS_LIST,
        payload: sharedUsers,
    };
};

// Action Creators
export const setCollectionsMaxPinScore = (maxPinScore: number): SetMaxPinScore => {
    return {
        type: SET_MAX_PIN_SCORE,
        payload: maxPinScore,
    };
};

// Action Creators
export const setCollectionsMaxLastUsedScore = (maxLastUsedScore: number): SetMaxLastUsedScore => {
    return {
        type: SET_MAX_LAST_USED_SCORE,
        payload: maxLastUsedScore,
    };
};

import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import useScrollBlock from "hooks/scrollHook";
import { closeImageViewer } from "store/actions/view";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AggDataObj, DataType } from "models/aggdata";
import {
  getImageForDataId,
  getDownloadLinkForDataId,
  getFilePathForDataId,
} from "configuration";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { deleteAggDataGql, updateAggDataGql } from "graphql/mutations";
import { retrieveCollectionGql } from "graphql/queries";
import { useMutation } from "@apollo/client";
import {
  deleteAggDataFromCache,
  addAggDataInArchivedDataCache,
  addAggDataInCache,
  deleteAggDataFromArchivedDataCache,
} from "graphql/helpers";
import { showSnackbar } from "store/actions/view";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import { openAttachDataToCollectionDialog } from "store/actions/view";
import { CollectionObj } from "models/collection";
import { RootState } from "store/reducers/index";
import { Box } from "@mui/material";
import UserAvatarWithTooltip from "components/UI/User/UserAvatarWithTooltip";
import { formatIso8601 } from "shared/dataUtils";
import ClearIcon from "@mui/icons-material/Clear";

interface ImageViewerProps {
  aggDataArray: AggDataObj[];
  startAggData: AggDataObj;
  title: string;
  inPublicMode?: boolean; //by default set to false. Set to true when this is opened from a public collection view
}

const ZOOM_MAX = 300;
const ZOOM_MIN = 100;
const ZOOM_STEP = 25;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    zIndex: 802,
    display: "flex",
    flexDirection: "column",
    flexFlow: "column",
    overflow: "hidden",
  },
  topToolbarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "70px",
    width: "100%",
    alignItems: "center",
  },
  backButton: {
    color: "white",
    width: "48px",
    height: "48px",

    "&.MuiIconButton-root": {
      marginTop: "12px",
      marginLeft: "8px",
    },

    "&:hover.MuiIconButton-root": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },
  arrowButtons: {
    color: "white",
    width: "48px",
    height: "48px",

    "&.MuiIconButton-root": {
      margin: "16px",
    },

    "&:hover.MuiIconButton-root": {
      backgroundColor: "rgba(253, 200, 75, 0.3)",
    },
  },
  toolButton: {
    color: "white",
    width: "32px",
    height: "32px",

    "&.MuiIconButton-root": {
      marginTop: "12px",
      marginLeft: "8px",
      marginRight: "18px",
    },

    "&:hover.MuiIconButton-root": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },

  mainContainer: {
    width: "100%",
    flex: 1.5,
    overflow: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainImage: {
    transition: theme.transitions.create(["height"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // "-ms-transform": "scale(1.5)", /* IE 9 */
    // "-webkit-transform": "scale(1.5)", /* Safari 3-8 */
    // transform: "scale(1.5)"
  },
}));

export const OpaqueOverlay = styled.div`
  position: fixed;
  display: absolute;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 801;
`;

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const ImageViewer = ({
  aggDataArray,
  startAggData,
  title,
  inPublicMode = false,
}: ImageViewerProps) => {
  const classes = useStyles();
  const [blockScroll, allowScroll] = useScrollBlock();
  const forceUpdate = useForceUpdate();
  const [navIndex, setNavIndex] = useState(
    aggDataArray.indexOf(startAggData) > 0
      ? aggDataArray.indexOf(startAggData)
      : 0
  );
  const { t } = useTranslation();
  const videoRef = React.useRef<any>();
  const [imageZoom, setImageZoom] = useState(100);
  const [displayedTitle, setDisplayedTitle] = useState("");
  const [updateAggData, updateAggDataResult] = useMutation(updateAggDataGql);
  const [deleteAggData, deleteAggDataResult] = useMutation(
    deleteAggDataGql,
    {}
  );
  const [
    displayAnnotationContainer,
    setDisplayAnnotationContainer,
  ] = React.useState(true);

  const [aggData, setCurrentAggdata] = React.useState(aggDataArray[navIndex]);
  const currentlyOpenedCollectionCurrentUserRight: number = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollectionCurrentUserRight
  );
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );

  const dispatch = useDispatch();

  const handleBackPressed = useCallback(() => {
    dispatch(closeImageViewer());
  }, []);

  const handleNextClicked = useCallback(() => {
    if (navIndex < aggDataArray.length - 1) {
      setNavIndex(navIndex + 1);
    }
  }, [navIndex]);

  const handlePreviousClicked = useCallback(() => {
    if (navIndex > 0) {
      setNavIndex(navIndex - 1);
    }
  }, [navIndex]);

  const updateTitle = useCallback(() => {
    setDisplayedTitle(" (" + (navIndex + 1) + "/" + aggDataArray.length + ")");
  }, [aggDataArray, navIndex, forceUpdate]);

  React.useEffect(() => {
    updateTitle();
  }, [updateTitle, forceUpdate]);

  React.useEffect(() => {
    if (
      aggDataArray[navIndex].dataType == DataType.VIDEO_TYPE &&
      videoRef &&
      videoRef.current
    ) {
      videoRef.current.src = getFilePathForDataId(aggDataArray[navIndex].id);
      videoRef.current.load();
    }
    setCurrentAggdata(aggDataArray[navIndex]);
  }, [navIndex]);

  const handleKeyboardEvent = useCallback(
    (e: any) => {
      e = e || window.event;
      switch (e.key) {
        case "ArrowLeft":
          handlePreviousClicked();
          break;
        case "ArrowRight":
          handleNextClicked();
          break;
        case "Escape":
          handleBackPressed();
          break;
      }
    },
    [handlePreviousClicked, handleNextClicked, handleBackPressed]
  );

  React.useEffect(() => {
    const scroll = window.scrollY;
    blockScroll();
    document.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      allowScroll();
      document.removeEventListener("keydown", handleKeyboardEvent);
      window.scroll(0, scroll); //we restore the scroll position.
    };
  }, [handleKeyboardEvent]);

  const handleClickOutside = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const event = e.target as HTMLElement;
      if (event.tagName == "DIV") {
        handleBackPressed();
      }
    },
    [handleBackPressed]
  );

  const zoomIn = useCallback(() => {
    if (imageZoom < ZOOM_MAX) {
      setImageZoom(imageZoom + ZOOM_STEP);
    }
  }, [imageZoom, setImageZoom]);

  const zoomOut = useCallback(() => {
    if (ZOOM_MIN < imageZoom) {
      setImageZoom(imageZoom - ZOOM_STEP);
    }
  }, [imageZoom, setImageZoom]);

  const handleDownloadClicked = useCallback(() => {
    window.open(getDownloadLinkForDataId(aggDataArray[navIndex].id), "_blank");
  }, [aggDataArray, navIndex]);

  const handleDelete = useCallback(async (): Promise<void> => {
    //Following a delete we update the navIndex to force a new render
    function updateNavIndex() {
      if (navIndex - 1 > 0) {
        setNavIndex(navIndex - 1);
      } else {
        if (aggDataArray.length > 0) {
          setNavIndex(0);
          forceUpdate();
        } else {
          handleBackPressed();
        }
      }
    }
    const aggData = aggDataArray[navIndex];
    if (aggData.collection) {
      deleteAggData({
        variables: { id: aggData.id },
        refetchQueries: [
          {
            query: retrieveCollectionGql,
            variables: { id: aggData.collection.id },
          },
        ],
        update(cache, { data }) {
          deleteAggDataFromCache(cache, aggData);
        },
      }).then((data) => {
        aggDataArray.splice(navIndex, 1);
        updateNavIndex();
      });
    } else {
      deleteAggData({
        variables: { id: aggData.id },
        update(cache, { data }) {
          deleteAggDataFromCache(cache, aggData);
        },
      }).then((data) => {
        aggDataArray.splice(navIndex, 1);
        updateNavIndex();
      });
    }
  }, [navIndex, aggDataArray, deleteAggData, handleBackPressed]);

  const handleUndoArchive = useCallback((aggData: AggDataObj) => {
    var refetchQueries: any = [];
    const aggDataToUpdate: any = {
      id: aggData.id,
      isArchived: false,
      collection: null,
    };
    if (aggData.collection) {
      aggDataToUpdate.collection = aggData.collection.id;
      refetchQueries = [
        {
          query: aggData.collection?.id ? retrieveCollectionGql : "", // if no collection, avoid trying a useless query
          variables: {
            id: aggData.collection?.id,
          },
        },
      ];
    }
    updateAggData({
      variables: { input: aggDataToUpdate },
      refetchQueries: refetchQueries,
      update(cache, { data: { updateAggdata } }) {
        if (updateAggdata) {
          addAggDataInCache(cache, updateAggdata.aggData);
          deleteAggDataFromArchivedDataCache(cache, updateAggdata.aggData);
          aggDataArray.splice(
            navIndex - 1 > -1 ? navIndex - 1 : 0,
            0,
            updateAggdata.aggData
          );
        }
      },
    });
  }, []);

  const handleUndoUnarchive = useCallback((aggData: AggDataObj) => {
    const aggDataToUpdate: any = {
      id: aggData.id,
      isArchived: true,
    };
    updateAggData({
      variables: { input: aggDataToUpdate },
      update(cache, { data: { updateAggdata } }) {
        if (updateAggdata) {
          addAggDataInArchivedDataCache(cache, updateAggdata.aggData);
          deleteAggDataFromCache(cache, updateAggdata.aggData);
          aggDataArray.splice(
            navIndex - 1 > -1 ? navIndex - 1 : 0,
            0,
            updateAggdata.aggData
          );
        }
      },
    });
  }, []);

  const handleArchive = useCallback(async (): Promise<void> => {
    //Following a delete we update the navIndex to force a new render
    function updateNavIndex() {
      if (navIndex - 1 > 0) {
        setNavIndex(navIndex - 1);
      } else {
        if (aggDataArray.length > 0) {
          setNavIndex(0);
          forceUpdate();
        } else {
          handleBackPressed();
        }
      }
    }
    const aggData = aggDataArray[navIndex];
    const aggDataToUpdate: any = {
      id: aggData.id,
      isArchived: true,
      collection: null,
    }; //We dot this because propos are read only and cannot be modified directly.

    var refetchQueries: any = [];
    if (aggData.collection) {
      aggDataToUpdate.collection = "-1"; // means we want to detach the data from the collection
      refetchQueries = [
        {
          query: aggData.collection?.id ? retrieveCollectionGql : "", // if no collection, avoid trying a useless query
          variables: {
            id: aggData.collection?.id,
          },
        },
      ];
    }

    updateAggData({
      variables: { input: aggDataToUpdate },
      refetchQueries: refetchQueries,
      update(cache, { data: { updateAggdata } }) {
        if (updateAggdata) {
          addAggDataInArchivedDataCache(cache, updateAggdata.aggData);
          deleteAggDataFromCache(cache, updateAggdata.aggData);
        }
      },
    })
      .then((data) => {
        aggDataArray.splice(navIndex, 1);
        updateNavIndex();
        dispatch(
          showSnackbar({
            message: t("item_archived_confirmation_msg"),
            action: t("snackbar_action_undo_msg"),
            onAction: () => handleUndoArchive(aggData),
            loading: false,
          })
        );
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("item_archived_failed_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [navIndex, aggDataArray, deleteAggData, handleBackPressed]);

  const handleUnarchive = useCallback(async (): Promise<void> => {
    //Following a delete we update the navIndex to force a new render
    function updateNavIndex() {
      if (navIndex - 1 > 0) {
        setNavIndex(navIndex - 1);
      } else {
        if (aggDataArray.length > 0) {
          setNavIndex(0);
          forceUpdate();
        } else {
          handleBackPressed();
        }
      }
    }
    const aggData = aggDataArray[navIndex];
    const aggDataToUpdate: any = {
      id: aggData.id,
      isArchived: false,
    }; //We dot this because propos are read only and cannot be modified directly.

    updateAggData({
      variables: { input: aggDataToUpdate },
      update(cache, { data: { updateAggdata } }) {
        if (updateAggdata) {
          addAggDataInCache(cache, updateAggdata.aggData);
          deleteAggDataFromArchivedDataCache(cache, updateAggdata.aggData);
        }
      },
    })
      .then((data) => {
        aggDataArray.splice(navIndex, 1);
        updateNavIndex();
        dispatch(
          showSnackbar({
            message: t("item_unarchived_confirmation_msg"),
            action: t("snackbar_action_undo_msg"),
            onAction: () => handleUndoUnarchive(aggData),
            loading: false,
          })
        );
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("item_unarchived_failed_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [navIndex, aggDataArray, deleteAggData, handleBackPressed]);

  const handleAddToCollection = useCallback(() => {
    const aggData = aggDataArray[navIndex];
    dispatch(openAttachDataToCollectionDialog(aggData));
  }, [navIndex, aggDataArray]);

  React.useEffect(() => {
    if (deleteAggDataResult.data) {
      dispatch(
        showSnackbar({
          message: t("snackbar_agg_data_deleted_msg"),
          action: t("snackbar_action_undo_msg"),
          onAction: null,
        })
      );
    }
  }, [deleteAggDataResult.data]);

  return (
    <>
      <OpaqueOverlay />
      <div className={classes.root}>
        <div className={classes.topToolbarContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              className={classes.backButton}
              onClick={handleBackPressed}
            >
              <ArrowBackIcon
                style={{ width: "36px", height: "36px", color: "white" }}
              ></ArrowBackIcon>
            </IconButton>

            <Typography
              style={{ color: "white", marginLeft: "12px" }}
              variant="h4"
            >
              {title}
            </Typography>
            <Typography
              style={{ color: "white", marginLeft: "12px" }}
              variant="h5"
            >
              {displayedTitle}
            </Typography>
          </div>

          {/**  =========== ZOOM CONTAINER  ============ */}
          <div>
            <IconButton className={classes.toolButton} onClick={zoomOut}>
              <ZoomOutIcon
                style={{ width: "28px", height: "28px", color: "white" }}
              ></ZoomOutIcon>
            </IconButton>
            <IconButton className={classes.toolButton} onClick={zoomIn}>
              <ZoomInIcon
                style={{ width: "28px", height: "28px", color: "white" }}
              ></ZoomInIcon>
            </IconButton>
          </div>

          {/** =========== TOOLS ======== */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {!startAggData.isArchived &&
              (!currentlyOpenedCollection ||
                currentlyOpenedCollectionCurrentUserRight > 0) &&
              !inPublicMode && (
                <Tooltip
                  placement="bottom"
                  style={{ zIndex: 40000000 }}
                  title={styledTooltipTitle(
                    t("toolbar_add_to_collection_tool_title")
                  )}
                >
                  <IconButton
                    className={classes.toolButton}
                    onClick={handleAddToCollection}
                  >
                    <PlaylistAddIcon
                      style={{ width: "28px", height: "28px", color: "white" }}
                    ></PlaylistAddIcon>
                  </IconButton>
                </Tooltip>
              )}
            <Tooltip
              placement="bottom"
              title={styledTooltipTitle(t("action_download"))}
            >
              <IconButton
                className={classes.toolButton}
                onClick={handleDownloadClicked}
              >
                <DownloadIcon
                  style={{ width: "28px", height: "28px", color: "white" }}
                ></DownloadIcon>
              </IconButton>
            </Tooltip>
            {(!currentlyOpenedCollection ||
              currentlyOpenedCollectionCurrentUserRight > 0) &&
              !inPublicMode && (
                <Tooltip
                  placement="bottom"
                  title={styledTooltipTitle(
                    startAggData.isArchived
                      ? t("action_unarchive")
                      : t("action_archive")
                  )}
                >
                  <IconButton
                    className={classes.toolButton}
                    onClick={
                      startAggData.isArchived ? handleUnarchive : handleArchive
                    }
                  >
                    {startAggData.isArchived ? (
                      <UnarchiveOutlinedIcon
                        style={{
                          width: "28px",
                          height: "28px",
                          color: "white",
                        }}
                      ></UnarchiveOutlinedIcon>
                    ) : (
                      <ArchiveOutlinedIcon
                        style={{
                          width: "28px",
                          height: "28px",
                          color: "white",
                        }}
                      ></ArchiveOutlinedIcon>
                    )}
                  </IconButton>
                </Tooltip>
              )}
            {(!currentlyOpenedCollection ||
              currentlyOpenedCollectionCurrentUserRight > 0) &&
              !inPublicMode && (
                <Tooltip
                  placement="bottom"
                  title={styledTooltipTitle(t("action_delete"))}
                >
                  <IconButton
                    className={classes.toolButton}
                    onClick={handleDelete}
                  >
                    <DeleteOutlinedIcon
                      style={{ width: "28px", height: "28px", color: "white" }}
                    ></DeleteOutlinedIcon>
                  </IconButton>
                </Tooltip>
              )}
          </div>
        </div>
        <div className={classes.mainContainer} onClick={handleClickOutside}>
          <IconButton
            className={classes.arrowButtons}
            onClick={handlePreviousClicked}
          >
            {navIndex > 0 && (
              <ArrowBackIosNewIcon
                style={{ width: "42px", height: "42px", color: "white" }}
              ></ArrowBackIosNewIcon>
            )}
          </IconButton>

          <div
            style={{
              height: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {aggDataArray[navIndex].dataType == DataType.IMAGE_TYPE && (
              <img
                src={getImageForDataId(aggDataArray[navIndex].id)}
                className={classes.mainImage}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: imageZoom == ZOOM_MIN ? "auto" : imageZoom + "%",
                  width: imageZoom == ZOOM_MIN ? "100%" : "auto",
                  maxHeight: imageZoom == ZOOM_MIN ? "100%" : undefined,
                }}
                loading="lazy"
              />
            )}
            {aggDataArray[navIndex].dataType == DataType.VIDEO_TYPE && (
              <video
                ref={videoRef}
                poster={getImageForDataId(aggDataArray[navIndex].id)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: imageZoom == ZOOM_MIN ? "auto" : imageZoom + "%",
                  width: imageZoom == ZOOM_MIN ? "100%" : "auto",
                  maxHeight: imageZoom == ZOOM_MIN ? "100%" : undefined,
                }}
                controls
                autoPlay
              >
                <source src={getFilePathForDataId(aggDataArray[navIndex].id)} />
              </video>
            )}
          </div>

          <IconButton
            className={classes.arrowButtons}
            onClick={handleNextClicked}
          >
            {navIndex < aggDataArray.length - 1 && (
              <ArrowForwardIosIcon
                style={{ width: "42px", height: "42px", color: "white" }}
              ></ArrowForwardIosIcon>
            )}
          </IconButton>
        </div>
        {aggData.annotation && displayAnnotationContainer && (
          <Box
            sx={{
              "*::-webkit-scrollbar": {
                width: 2,
              },
              "*::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 0px rgba(0, 0, 0, 0.3)`,
                borderRadius: "8px",
              },
              "*::-webkit-scrollbar-thumb": {
                backgroundColor: "#bdbdbd",
                borderRadius: "8px",
              },
            }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%, rgba(0,0,0,0.8) 100%) ",
                opacity: 0.9,
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                paddingLeft: "16px",
                paddingRight: "8px",
                paddingBottom: "32px",
                width: "100%",
                maxHeight: "15%",
                overflowY: "scroll",
                left: 0,
                bottom: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <Box //name and created date container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <UserAvatarWithTooltip
                    aggData={aggData}
                    topRight={false}
                    size={"normal"}
                  />
                </Box>
                <Box //name and created date container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "12px",
                    marginLeft: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: 500,
                      aligntText: "start",
                      color: "white",
                    }}
                  >
                    {aggData?.owner?.firstName + " " + aggData?.owner?.lastName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      aligntText: "start",
                      color: "white",
                    }}
                  >
                    {formatIso8601(aggData?.created)}
                  </Typography>
                </Box>
                <Tooltip title={styledTooltipTitle(t("title_close"))}>
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: 0,
                    }}
                    onClick={() => {
                      setDisplayAnnotationContainer(false);
                    }}
                  >
                    <ClearIcon
                      style={{ width: "24px", height: "24px", color: "white" }}
                    ></ClearIcon>
                  </IconButton>
                </Tooltip>
              </Box>
              <Typography
                sx={{
                  color: "white",
                  background: "#03000000",
                  fontSize: "1.6rem",
                }}
              >
                {aggData.annotation}
              </Typography>
            </Box>
          </Box>
        )}
      </div>
    </>
  );
};

export default ImageViewer;

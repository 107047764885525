import { Box, Fade, ListItemButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  retrieveAllCollectionsGql,
  retrieveTopLevelCollectionsOwnedByMeGql,
} from "graphql/queries";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UserObj } from "models/user";
import { RootState } from "store/reducers";
import { colorMemento } from "shared/colors";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { CollectionObj } from "models/collection";
import CheckIcon from "@mui/icons-material/Check";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import HierarchyCollectionItem from "./HierarchyCollectionItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "relative",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      height: "100%",
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "16px",
      overflow: "hidden",
      "& .MuiInput-root": {
        height: "70%",
      },
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
    annotationTextField: {
      fontSize: "1.9rem",
    },

    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingTop: "24px",
      marginRight: "24px",
    },
    applyButton: {
      marginEnd: "12px",
      marginStart: "12px",
      alignItems: "center",
    },
  })
);

interface SelectCollectionFromHierarchyViewProps {
  collection?: CollectionObj | null;
  searchInput?: string;
  handleClose: () => void;
  handleCollectionPicked?: (collection: CollectionObj) => void;
  onCollectionSelected: (collection: CollectionObj | null) => void;
  onTopLevelOptionSelected: (selected: boolean) => void;
}

/**
 * When a collection is provided, it will be excluded from the list of displayed collections, as for now this view is usede to pick a parent collection for a collection that the user would want to move, and in this case it doesn't make sense to display the collection itself
 * @param param0
 * @returns
 */
const SelectCollectionFromHierarchyView = ({
  collection = null,
  onCollectionSelected,
  onTopLevelOptionSelected,
  searchInput,
}: SelectCollectionFromHierarchyViewProps) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(
    retrieveTopLevelCollectionsOwnedByMeGql
  );

  const [fetchAllCollections, fetchAllCollectionsResult] = useLazyQuery(
    retrieveAllCollectionsGql
  );

  const [collectionsToDisplay, setCollectionsToDisplay] = React.useState<
    CollectionObj[]
  >([]);

  const client = useApolloClient();

  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  React.useEffect(() => {
    if (data && data.topLevelCollectionsOwnedByMe) {
      setCollectionsToDisplay(data.topLevelCollectionsOwnedByMe);
    }
  }, [data]);

  React.useEffect(() => {
    if (
      searchInput &&
      searchInput.length > 0 &&
      fetchAllCollectionsResult.data &&
      fetchAllCollectionsResult.data.collections
    ) {
      const rgxp = new RegExp(searchInput, "gi");
      setCollectionsToDisplay(
        fetchAllCollectionsResult.data.collections.filter(
          (collection: CollectionObj) => {
            return collection?.name?.match(rgxp);
          }
        )
      );
    } else if (
      (!searchInput || searchInput == "" || searchInput.trim().length == 0) &&
      data &&
      data.topLevelCollectionsOwnedByMe
    ) {
      setCollectionsToDisplay(data.topLevelCollectionsOwnedByMe);
    } else if (
      searchInput &&
      searchInput.length > 0 &&
      !fetchAllCollectionsResult.called
    ) {
      fetchAllCollections();
    }
  }, [searchInput]);

  React.useEffect(() => {
    if (
      searchInput &&
      searchInput.length > 0 &&
      fetchAllCollectionsResult.data &&
      fetchAllCollectionsResult.data.collections
    ) {
      const rgxp = new RegExp(searchInput, "gi");
      setCollectionsToDisplay(
        fetchAllCollectionsResult.data.collections.filter(
          (collection: CollectionObj) => {
            return collection?.name?.match(rgxp);
          }
        )
      );
    }
  }, [fetchAllCollectionsResult.data, searchInput]);

  const [moveTopLevelSelected, setMoveTopLevelSelected] = React.useState(false);
  const [
    selectedCollection,
    setSelectedCollection,
  ] = React.useState<CollectionObj | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <Box
        className={classes.paper}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "spread-between",
          "&::-webkit-scrollbar": {
            width: 3,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px #faf6e9`,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#bdbdbd",
            borderRadius: "4px",
          },
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {collection && collection.owner.id == currentUser.id && (
              <ListItemButton
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                selected={moveTopLevelSelected && !selectedCollection}
                onClick={(e) => {
                  setMoveTopLevelSelected(true);
                  setSelectedCollection(null);
                  onTopLevelOptionSelected(true);
                  onCollectionSelected(null);
                }}
              >
                {moveTopLevelSelected && (
                  <CheckIcon
                    sx={{
                      color: colorMemento,
                      position: "absolute",
                      right: "8px",
                    }}
                  />
                )}
                {/*  <IconButton
         
                > */}

                <VerticalAlignTopIcon />

                <Typography
                  sx={{
                    marginLeft: "8px",
                    fontSize: "1.4rem",
                    fontWeight: 410,
                  }}
                >
                  {t("move_collection_to_top_level_title")}
                </Typography>
              </ListItemButton>
            )}
            {collectionsToDisplay.map((collectionFromMap: CollectionObj) => {
              if (collection && collection.id == collectionFromMap.id) {
                return <></>; //We don't want to display the collection itself here that we are trying to move
              }
              return (
                <HierarchyCollectionItem
                  collection={collectionFromMap}
                  selectedCollection={selectedCollection}
                  collectionToIgnore={collection}
                  onCollectionClicked={(coll: CollectionObj) => {
                    setSelectedCollection(coll);
                    setMoveTopLevelSelected(false);
                    onCollectionSelected(coll);
                    onTopLevelOptionSelected(false);
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(SelectCollectionFromHierarchyView);

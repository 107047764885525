import styled from "styled-components";
import React, { useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { experimentalStyled as styledComponent } from "@mui/material/styles";
import ImagePreview from "components/CollectionItems/ImagePreview";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CollectionObj, isCollectionShared } from "models/collection";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { Theme, useTheme } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import { colorGreyMemento } from "shared/colors";
import DeleteCollectionConfirmationDialog from "components/UI/Modals/DeleteCollectionConfirmationDialog";
import { useTranslation } from "react-i18next";
import {
  deleteCollectionGql,
  updateCollectionGql,
  moveItemsFromCollectionToCollectionGql,
  moveCollectionToCollectionGql,
} from "graphql/mutations";
import { useMutation, useApolloClient, useLazyQuery } from "@apollo/client";
import {
  retrieveCollectionGql,
  retrieveAggdataForCollectionIds,
  retrieveAggdataForCollectionGql,
  retrieveChildCollectionsForParentGql,
  retrievePublicChildCollectionsForParentGql,
  retrieveTopLevelCollectionsGql,
} from "graphql/queries";
import { showSnackbar } from "store/actions/view";
import AddNewCollectionModal from "components/UI/Modals/AddNewCollectionModal";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { RootState } from "store/reducers";
import {
  updateCollectionInCache,
  deleteCollectionFromCache,
  deleteCollectionFromArchivedCache,
  moveAggDataToArchivedForCollectionId,
  moveAggDataToUnArchivedForCollectionId,
  addCollectionInCache,
  addCollectionInArchivedCache,
  deleteSharedCollectionSpecificCache,
  deleteCollectionFromTopLevelCollectionsCache,
  addCollectionInTopLevelArchivedCache,
  updateTopLevelCollectionsCache,
  deleteCollectionFromTopLevelArchivedCache,
} from "graphql/helpers";
import {
  setCollectionsMaxPinScore,
  setCollectionsMaxLastUsedScore,
} from "store/actions/collectionsActions";
import WrapTextIcon from "@mui/icons-material/WrapText";
import SelectCollectionContainer from "containers/SelectCollectionContainer/SelectCollectionContainer";
import ManageUserAccessModal from "components/UI/Modals/ManageUserAccessModal";
import { AggDataObj } from "../../models/aggdata";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { colorMemento } from "shared/colors";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SharedUsersAvatars from "components/UI/Controls/SharedUsersAvatars";
import { UserObj } from "models/user";
import { DEBUG } from "configuration";
import { Box, ListItem, ListItemButton, Tooltip } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import { styledTooltipTitle } from "shared/styles";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsDrawer from "containers/Comments/CommentsDrawer";
import InputIcon from "@mui/icons-material/Input";
import MoveCollectionModal from "./MoveCollectionModal";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Item = styledComponent(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "column",
}));

const useStyles = makeStyles((theme: Theme) => ({
  moreIconButton: {
    width: "48px",
    height: "48px",
  },
  menuItemIcon: {
    width: "18px",
    height: "18px",
  },
  menuItem: {
    height: "30px",
  },
}));

export interface CollectionLayoutProps {
  collection: CollectionObj;
  onClick: (
    e: React.MouseEvent<HTMLElement>,
    collection: CollectionObj
  ) => void;
  listLayout: boolean;
  displayPublicCollection?: boolean; //Whether this component is displayed from the context of public collections or private collections
}

const MainContainer = styled.div`
  display: flex;
  position: relative;

  flex-direction: column;
`;

const CollectionItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-directoin: column;
  align-items: center;
  margin-bottom: 8px;
  min-height: 64px;
  width: 100%;
`;

export const CollectionTitle = styled.div`
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

export const CollectionTitleVisibilityHint = styled(CollectionTitle)`
  text-align: center;
  margin-top: 8px;
`;

export const CollectionSubTitle = styled.div`
  display: flex;
  justify-content: start;
  font-size: 1.3rem;
  font-weight: 500;
  margin-right: 8px;
`;
export const MenuItemText = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 12px;
`;

const OPEN_SELECT_COLLECTION_MOVE_COLLECTION = "move_collection";
const OPEN_SELECT_COLLECTION_MOVE_AGGDATA = "move_aggdata";

function CollectionLayout({
  collection,
  onClick,
  listLayout,
  displayPublicCollection = false,
}: CollectionLayoutProps) {
  const [showMore, setShowMore] = React.useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [
    displaySelectCollectionModal,
    setDisplaySelectCollectionModal,
  ] = React.useState(false);
  const displaySelectCollectionToken = React.useRef<string>(
    OPEN_SELECT_COLLECTION_MOVE_AGGDATA
  );
  const [
    openMoveCollectionModal,
    setOpenMoveCollectionModal,
  ] = React.useState<CollectionObj | null>(null);
  const [
    displayManageUsersAccessModal,
    setDisplayManageUsersAccessModal,
  ] = React.useState(false);

  const [
    displayDeleteConfirmation,
    setDisplayDeleteConfirmation,
  ] = React.useState(false);
  const [
    openRenameCollectionModal,
    setOpenRenameCollectionModal,
  ] = React.useState(false);

  const [displayVisibilityHint, setDisplayVisibilityHint] = React.useState(
    false
  );
  const showConfidentialityFilterGlobal = useSelector(
    (state: RootState) => state.view.showConfidentialityFilter
  );

  const client = useApolloClient();

  const [
    shoConfidentialityFilter,
    setShowConfidentialityFilter,
  ] = React.useState(showConfidentialityFilterGlobal);

  //Monitor global state and apply it if it changes
  React.useEffect(() => {
    setShowConfidentialityFilter(showConfidentialityFilterGlobal);
  }, [showConfidentialityFilterGlobal]);

  //const [getAllData, {loading, error, data}] = useLazyQuery(retrieveAllDataGql);
  const [deleteCollection, deleteCollectionResult] = useMutation(
    deleteCollectionGql
  );
  const [
    moveCollectionToCollection,
    moveCollectionToCollectionResult,
  ] = useMutation(moveCollectionToCollectionGql);
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );

  const [openNesting, setOpenNesting] = React.useState(false);

  const [
    retrieveChildCollections,
    retrieveChildCollectionsResult,
  ] = useLazyQuery(retrieveChildCollectionsForParentGql, {
    variables: { parentId: collection.id },
  });

  const [
    retrievePublicChildCollections,
    retrievePublicChildCollectionsResult,
  ] = useLazyQuery(retrievePublicChildCollectionsForParentGql, {
    variables: { parentId: collection.id },
    context: displayPublicCollection
      ? {
          clientName: "public",
        }
      : undefined,
  });

  const handleToggleNesting = React.useCallback(() => {
    if (displayPublicCollection) {
      if (!retrievePublicChildCollectionsResult.called) {
        retrievePublicChildCollections();
      }
    } else {
      if (!retrieveChildCollectionsResult.called) {
        retrieveChildCollections();
      }
    }

    setOpenNesting(!openNesting);
  }, [displayPublicCollection, openNesting, retrieveChildCollectionsResult]);

  const [openCommentsDrawer, setOpenCommentsDrawer] = React.useState(false);

  const [
    moveItemsFromCollectionToCollection,
    moveItemsFromCollectionToCollectionResult,
  ] = useMutation(moveItemsFromCollectionToCollectionGql);

  const [updateCollection, updateCollectionResult] = useMutation(
    updateCollectionGql,
    {
      update(cache, { data: { updateCollection } }) {
        if (updateCollection) {
          updateCollectionInCache(cache, updateCollection.collection);
        }
      },
    }
  );

  const maxPinScore: number = useSelector(
    (state: RootState) => state.collectionsReducer.maxPinScore
  );

  const maxLastUsedScore: number = useSelector(
    (state: RootState) => state.collectionsReducer.maxLastUsedScore
  );

  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  const isCurrentUserOwnerOfCollection =
    currentUser && currentUser.id == collection.owner.id;

  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopupMenu = () => {
    setAnchorEl(null);
  };

  //console.log(" maxpinscore=" + maxPinScore);

  function handleDeleteCollection(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    setDisplayDeleteConfirmation(true);
    closePopupMenu();
  }

  /**
   * Depending on whether the collection is pinned or unpinned, this function
   * will do the opposite
   * @param e
   */
  function handlePinUnPinCollection(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (collection.pinScore) {
      //collection is pinned, let's unpin it
      updateCollection({
        variables: {
          input: {
            id: collection.id,
            pinScore: 0,
            lastUsedScore: maxLastUsedScore + 1,
          },
        },
      });
      dispatch(setCollectionsMaxLastUsedScore(maxLastUsedScore + 1));
    } else {
      updateCollection({
        variables: {
          input: { id: collection.id, pinScore: maxPinScore + 1 },
        },
      });
      dispatch(setCollectionsMaxPinScore(maxPinScore + 1));
    }
    closePopupMenu();
  }

  function handleRenameCollection() {
    setOpenRenameCollectionModal(true);
    closePopupMenu();
  }

  const handleCommentsClicked = React.useCallback(() => {
    setOpenCommentsDrawer(true);
    closePopupMenu();
  }, []);

  const handleManageUserAccess = React.useCallback(() => {
    closePopupMenu();
    setDisplayManageUsersAccessModal(true);
  }, [closePopupMenu]);

  useEffect(() => {
    if (deleteCollectionResult.loading) {
      dispatch(
        showSnackbar({
          message: t("delete_collection_ongoing"),
          action: "",
          onAction: null,
          loading: true,
        })
      );
    }
  }, [deleteCollectionResult.loading]);

  function handleOk() {
    setDisplayDeleteConfirmation(false);
    doDeleteCollection();
  }

  const handleMoveItemsFromCollectionToCollection = useCallback(
    (destinationCollection: CollectionObj) => {
      //In this case, we check if the destination collection is shared or not, and if yes we see if there is an existing cache.
      // If there is an existing cache, we remove it to force refetching data if ever that collection is opened, to make sure
      //data displayed is fresh and relevant
      if (
        destinationCollection.sharedUsers &&
        destinationCollection.sharedUsers.length > 0
      ) {
        const cachedData = client.readQuery({
          query: retrieveAggdataForCollectionGql,
          variables: { collectionId: destinationCollection.id },
        });
        if (cachedData) {
          if (DEBUG)
            console.log(
              "DEBUG cache found for shared collection %o",
              cachedData
            );
          if (DEBUG)
            console.log(
              "DEBUG will evict the cache found to force the data to be refetched from server if ever the user opens the shared collection"
            );
          deleteSharedCollectionSpecificCache(
            client.cache,
            destinationCollection.id
          );
        }
      }

      moveItemsFromCollectionToCollection({
        variables: {
          sourceCollectionId: collection.id,
          destinationCollectionId: destinationCollection.id,
        },
        refetchQueries: [
          {
            query: retrieveCollectionGql,
            variables: {
              id: destinationCollection.id,
            },
          },
          {
            query: retrieveAggdataForCollectionIds,
            variables: {
              collectionId: destinationCollection.id,
            },
          },
        ],
        update(cache, { data: { moveItemsFromCollectionToCollection } }) {
          if (DEBUG) {
            console.log(
              "handleMoveItemsFromCollectionToCollection: result " +
                moveItemsFromCollectionToCollection
            );
          }
          if (
            moveItemsFromCollectionToCollection &&
            moveItemsFromCollectionToCollection.result == 0
          ) {
            updateCollectionInCache(cache, {
              ...collection,
              itemsCount: 0,
              previewUrls: [],
            });
          }
        },
      });
    },
    [collection]
  );

  function doDeleteCollection() {
    deleteCollection({
      variables: { id: collection.id },
      refetchQueries: currentlyOpenedCollection
        ? [
            {
              query: retrieveChildCollectionsForParentGql,
              variables: {
                parentId: currentlyOpenedCollection.id,
              },
            },
          ]
        : undefined,
      update(cache, { data }) {
        if (data.deleteCollection.result) {
          if (!currentlyOpenedCollection) {
            deleteCollectionFromTopLevelCollectionsCache(cache, collection.id);
          }
        }
      },
    })
      .then((data) => {
        dispatch(
          showSnackbar({
            message: t("delete_collection_done"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      })
      .catch((e) => {
        console.log("Exception is %o", e);
        dispatch(
          showSnackbar({
            message: t("delete_collection_failed"),
            action: "",
            onAction: null,
          })
        );
      });
  }

  const handleArchive = useCallback(() => {
    const collectionToUpdate: any = {
      id: collection.id,
      isArchived: !collection.isArchived,
      lastUsedScore: maxLastUsedScore + 1,
    };
    updateCollection({
      variables: { input: collectionToUpdate },
      refetchQueries: undefined,
      update(cache, { data: { updateCollection } }) {
        //Might impact performance ...
        if (updateCollection) {
          if (!collection.isArchived) {
            moveAggDataToArchivedForCollectionId(cache, collection.id);
            deleteCollectionFromTopLevelCollectionsCache(cache, updateCollection.collection.id);
            addCollectionInTopLevelArchivedCache(cache, updateCollection.collection);
          } else {
            moveAggDataToUnArchivedForCollectionId(cache, collection.id);
            deleteCollectionFromTopLevelArchivedCache(
              cache,
              updateCollection.collection
            );
            updateTopLevelCollectionsCache(cache, retrieveTopLevelCollectionsGql,  updateCollection.collection);
          }
        }
      },
    })
      .then((data) => {
        var snackbarMessage = "";
        if (!collection.isArchived) {
          snackbarMessage = t("collection_archived_confirmation_msg", {
            collection_name: collection.name,
          });
        } else {
          snackbarMessage = t("collection_unarchived_confirmation_msg", {
            collection_name: collection.name,
          });
        }
        dispatch(
          showSnackbar({
            message: snackbarMessage,
          })
        );
        dispatch(setCollectionsMaxLastUsedScore(maxLastUsedScore + 1));
      })
      .catch((e) => {
        console.log("exception %o", e);
        dispatch(
          showSnackbar({
            message: t("item_archived_failed_msg"),
          })
        );
      });
    closePopupMenu();
  }, [collection, updateCollection, closePopupMenu]);

  const getCollectionLayout = () => {
    if (!listLayout) {
      return (
        <>
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: "4px",
              right: "16px",
              flexDirection: "row",
            }}
          >
            {collection &&
              collection.sharedUsers &&
              collection.sharedUsers.length > 0 && (
                <SharedUsersAvatars
                  hideConfigureAccess
                  sharedUsersList={collection.sharedUsers}
                  size="small"
                />
              )}
          </div>
          <div
            onClick={(e) => {
              onClick(e, collection);
            }}
          >
            <ImagePreview
              previewUrls={collection.previewUrls}
              hideBorder={shoConfidentialityFilter}
            />
          </div>
          <CollectionItemFooter>
            <div
              onClick={onClick}
              style={{
                width: "100%",
                maxWidth: "80%",
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                flexGrow: 1,
              }}
            >
              <CollectionTitle>{collection.name}</CollectionTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CollectionSubTitle>
                  {"(" +
                    (collection.itemsCount ? collection.itemsCount : "0") +
                    ")"}
                </CollectionSubTitle>
                {collection.isPublic && (
                  <Tooltip
                    title={styledTooltipTitle(
                      t("collection_is_public_tooltip_msg")
                    )}
                  >
                    <PublicIcon />
                  </Tooltip>
                )}
              </div>
            </div>

            <IconButton
              className={classes.moreIconButton}
              onClick={handleClick}
              aria-label="more"
            >
              <MoreHorizIcon style={{ height: "24px", width: "24px" }} />
            </IconButton>
          </CollectionItemFooter>
        </>
      );
    } else {
      return (
        <Box>
          <ListItemButton
            onClick={(e) => {
              onClick(e, collection);
            }}
            sx={{
              borderRadius: "16px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              padding: "8px",
            }}
          >
            {openNesting ? (
              <IconButton
                sx={{
                  width: "16px",
                  height: "16px",
                  marginRight: "8px",
                  marginLeft: "8px",
                  visibility: collection.isNested ? "visible" : "hidden",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleNesting();
                }}
              >
                <KeyboardArrowDownIcon style={{}} />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  width: "16px",
                  height: "16px",
                  marginRight: "8px",
                  marginLeft: "8px",
                  visibility: collection.isNested ? "visible" : "hidden",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleNesting();
                }}
              >
                <KeyboardArrowRightIcon style={{}} />
              </IconButton>
            )}
            <div onClick={onClick}>
              <ImagePreview
                previewUrls={collection.previewUrls}
                hideBorder={shoConfidentialityFilter}
                height="48px"
                width="48px"
                imgSpacing="0px"
              />
            </div>
            <div
              onClick={(e) => {
                onClick(e, collection);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                flexGrow: 1,
                marginLeft: "2%",
                alignItems: "center",
              }}
            >
              <CollectionTitle
                style={{
                  width: "auto",
                }}
              >
                {collection.name}
              </CollectionTitle>
              <CollectionSubTitle
                style={{
                  marginLeft: "8px",
                }}
              >
                {"(" +
                  (collection.itemsCount ? collection.itemsCount : "0") +
                  ")"}
              </CollectionSubTitle>
              {collection.isPublic && (
                <Tooltip
                  title={styledTooltipTitle(
                    t("collection_is_public_tooltip_msg")
                  )}
                >
                  <PublicIcon />
                </Tooltip>
              )}
            </div>

            {
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {collection &&
                  collection.sharedUsers &&
                  collection.sharedUsers.length > 0 && (
                    <SharedUsersAvatars
                      hideConfigureAccess
                      sharedUsersList={collection.sharedUsers}
                      size="small"
                    />
                  )}
                <IconButton
                  className={classes.moreIconButton}
                  onClick={handleClick}
                  aria-label="more"
                >
                  <MoreHorizIcon style={{ height: "24px", width: "24px" }} />
                </IconButton>
              </div>
            }
          </ListItemButton>
          {openNesting && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "16px",
              }}
            >
              {retrieveChildCollectionsResult.data &&
                retrieveChildCollectionsResult.data.childCollections.map(
                  (collectionFromMap: CollectionObj) => {
                    return (
                      <CollectionLayout
                        collection={collectionFromMap}
                        onClick={onClick}
                        listLayout={listLayout}
                        displayPublicCollection={displayPublicCollection}
                      />
                    );
                  }
                )}
              {retrievePublicChildCollectionsResult.data &&
                retrievePublicChildCollectionsResult.data.publicChildCollections.map(
                  (collectionFromMap: CollectionObj) => {
                    return (
                      <CollectionLayout
                        collection={collectionFromMap}
                        onClick={onClick}
                        listLayout={listLayout}
                        displayPublicCollection={displayPublicCollection}
                      />
                    );
                  }
                )}
            </Box>
          )}
        </Box>
      );
    }
  };

  return (
    <MainContainer
      onMouseEnter={() => setShowMore(true)}
      onMouseLeave={() => {
        setShowMore(false);
        closePopupMenu();
      }}
    >
      {shoConfidentialityFilter && (
        <div
          onMouseEnter={() => {
            setDisplayVisibilityHint(true);
          }}
          onMouseLeave={() => {
            setDisplayVisibilityHint(false);
          }}
          onClick={() => {
            setShowConfidentialityFilter(false);
            setDisplayVisibilityHint(false);
          }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backdropFilter: "blur(15px)",
            zIndex: 1,
            display: "flex",
            flexDirection: listLayout ? "row" : "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "8px",
            border: "1px dashed #FDC84B",
          }}
        >
          {displayVisibilityHint && (
            <>
              <VisibilityIcon sx={{ width: "48px", height: "48px" }} />
              <CollectionTitleVisibilityHint
                style={{
                  width: "auto",
                  marginLeft: listLayout ? "12px" : "0px",
                }}
              >
                {collection.name}
              </CollectionTitleVisibilityHint>
            </>
          )}
        </div>
      )}

      {getCollectionLayout()}
      {true && (
        <>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closePopupMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {!collection.isArchived && !isCollectionShared(collection) && (
              // Menu item needs a child with a key
              <div key="unpin">
                {collection.pinScore ? (
                  <MenuItem
                    key="unpin"
                    className={classes.menuItem}
                    onClick={handlePinUnPinCollection}
                  >
                    <PushPinOutlinedIcon
                      htmlColor={colorGreyMemento}
                      className={classes.menuItemIcon}
                    />
                    <MenuItemText>{t("action_unpin")}</MenuItemText>
                  </MenuItem>
                ) : (
                  <MenuItem
                    key="unpin"
                    className={classes.menuItem}
                    onClick={handlePinUnPinCollection}
                  >
                    <PushPinIcon
                      htmlColor={colorGreyMemento}
                      className={classes.menuItemIcon}
                    />
                    <MenuItemText>{t("action_pin")}</MenuItemText>
                  </MenuItem>
                )}
              </div>
            )}
            {/* only display it for non archived collections */}
            {!collection.isArchived && (
              <MenuItem
                key="manage-access"
                className={classes.menuItem}
                onClick={handleManageUserAccess}
              >
                <ManageAccountsOutlinedIcon
                  htmlColor={colorGreyMemento}
                  className={classes.menuItemIcon}
                />
                <MenuItemText>{t("manage_user_access_title")}</MenuItemText>
              </MenuItem>
            )}
            {(collection.isPublic || isCollectionShared(collection)) && (
              <MenuItem
                key="manage-access"
                className={classes.menuItem}
                onClick={handleCommentsClicked}
              >
                <CommentIcon
                  htmlColor={colorGreyMemento}
                  className={classes.menuItemIcon}
                />
                <MenuItemText>{t("comments_title")}</MenuItemText>
              </MenuItem>
            )}
            {isCurrentUserOwnerOfCollection && (
              <MenuItem
                key="edit-name"
                className={classes.menuItem}
                onClick={handleRenameCollection}
              >
                <ModeEditOutlinedIcon
                  htmlColor={colorGreyMemento}
                  className={classes.menuItemIcon}
                />
                <MenuItemText>{t("rename_title")}</MenuItemText>
              </MenuItem>
            )}
            {collection &&
            !isCollectionShared(collection) &&
            !collection.isArchived &&
            collection.itemsCount > 0 && ( //display only when collection is not empty, otherwise useless
                <MenuItem
                  key="move-to-collection"
                  className={classes.menuItem}
                  onClick={() => {
                    displaySelectCollectionToken.current = OPEN_SELECT_COLLECTION_MOVE_AGGDATA;
                    setDisplaySelectCollectionModal(true);
                    closePopupMenu();
                  }}
                >
                  <WrapTextIcon
                    htmlColor={colorGreyMemento}
                    className={classes.menuItemIcon}
                  />
                  <MenuItemText>
                    {t("action_move_items_to_collection")}
                  </MenuItemText>
                </MenuItem>
              )}
            {
              <MenuItem
                key="move-collection"
                className={classes.menuItem}
                onClick={() => {
                  displaySelectCollectionToken.current = OPEN_SELECT_COLLECTION_MOVE_COLLECTION;
                  setOpenMoveCollectionModal(collection);
                  //setDisplaySelectCollectionModal(true);
                  closePopupMenu();
                }}
              >
                <>
                  <InputIcon
                    htmlColor={colorGreyMemento}
                    className={classes.menuItemIcon}
                  />
                  <MenuItemText>{t("action_move")}</MenuItemText>
                </>
              </MenuItem>
            }
            {!isCollectionShared(collection) && (
              <MenuItem
                key="archive"
                className={classes.menuItem}
                onClick={handleArchive}
              >
                {collection.isArchived ? (
                  <>
                    <UnarchiveIcon
                      htmlColor={colorGreyMemento}
                      className={classes.menuItemIcon}
                    />
                    <MenuItemText>{t("action_unarchive")}</MenuItemText>
                  </>
                ) : (
                  <>
                    <ArchiveIcon
                      htmlColor={colorGreyMemento}
                      className={classes.menuItemIcon}
                    />
                    <MenuItemText>{t("action_archive")}</MenuItemText>
                  </>
                )}
              </MenuItem>
            )}
            {isCurrentUserOwnerOfCollection && (
              <MenuItem
                key="delete"
                className={classes.menuItem}
                onClick={handleDeleteCollection}
              >
                <DeleteIcon
                  htmlColor={colorGreyMemento}
                  className={classes.menuItemIcon}
                />
                <MenuItemText>{t("action_delete")}</MenuItemText>
              </MenuItem>
            )}
          </Menu>
        </>
      )}

      <DeleteCollectionConfirmationDialog
        open={displayDeleteConfirmation}
        collectionObj={collection}
        handleModalClose={setDisplayDeleteConfirmation}
        handleOk={handleOk}
      />

      <AddNewCollectionModal
        open={openRenameCollectionModal}
        handleModalClose={setOpenRenameCollectionModal}
        isOpenedInRenameMode
        collectionIdToRename={collection.id!!}
        collectionNameToRename={collection.name!!}
      />

      {displaySelectCollectionModal && (
        <SelectCollectionContainer
          open={displaySelectCollectionModal}
          title={t("select_collection_container_title")}
          token={displaySelectCollectionToken.current}
          handleCollectionSelected={(
            collectionObj: CollectionObj,
            aggdataObj: AggDataObj,
            token
          ) => {
            if (token == OPEN_SELECT_COLLECTION_MOVE_AGGDATA) {
              handleMoveItemsFromCollectionToCollection(collectionObj);
            } else if (token == OPEN_SELECT_COLLECTION_MOVE_COLLECTION) {
              handleMoveCollectionToCollection(collection, collectionObj);
              //console.log("need to move to collection");
            }
            setDisplaySelectCollectionModal(false);
          }}
          handleModalClose={() => {
            setDisplaySelectCollectionModal(false);
          }}
        ></SelectCollectionContainer>
      )}

      {openMoveCollectionModal && (
        <MoveCollectionModal
          open={Boolean(openMoveCollectionModal)}
          collection={openMoveCollectionModal}
          handleModalClose={() => {
            setOpenMoveCollectionModal(null);
          }}
        />
      )}
      {displayManageUsersAccessModal && (
        <ManageUserAccessModal
          open={displayManageUsersAccessModal}
          handleModalClose={() => {
            setDisplayManageUsersAccessModal(false);
          }}
          collection={collection}
          sharedUserAccessList={
            collection.sharedUsers ? collection.sharedUsers : []
          }
        ></ManageUserAccessModal>
      )}

      {collection && (
        <CommentsDrawer
          collection={collection}
          open={openCommentsDrawer}
          onClose={() => {
            setOpenCommentsDrawer(false);
          }}
        />
      )}
    </MainContainer>
  );
}

export default React.memo(CollectionLayout);

import React from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import ReactDom from "react-dom";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers";
import axios from "axios";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";
// const firebaseConfig = {
//   apiKey: "AIzaSyD9t8tD0RXZuWTNmPuxdzdzul8aCF2TYLE",
//   authDomain: "memento-92a16.firebaseapp.com",
//   databaseURL: "https://memento-92a16.firebaseio.com",
//   projectId: "memento-92a16",
//   storageBucket: "memento-92a16.appspot.com",
//   messagingSenderId: "1029225018169",
//   appId: "1:1029225018169:web:2c08d546f51f0057c2aee8",
//   measurementId: "G-VE1BRBZ22W",
// };

// const app = initializeApp(firebaseConfig);

// const messaging = getMessaging();
// getToken(messaging, { vapidKey: "BGHw-h0D5SIBTnQ-pFT2xdRB5fs0furNYEnWlzt8JGISUKT7eUUHPnyxuOAgI2OGAtaAQHGKtBPL6YrIzyi_f1o" })
//     .then((currentToken) => {
//       if (currentToken) {
//         // Send the token to your server and update the UI if necessary
//         // ...
//         console.log("Momox got the token here ! " + currentToken)
//       } else {
//         // Show permission request UI
//         console.log(
//           "Momox No registration token available. Request permission to generate one."
//         );
//         // ...
//       }
//     })
//     .catch((err) => {
//       console.log("Momox An error occurred while retrieving token. ", err);
//       // ...
//     });

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

//axios.defaults.headers.common['X-CSRF-TOKEN'] =  Cookies.get('csrftoken');
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";


ReactDom.render(
  <I18nextProvider i18n={i18n}>
    {/* <ApolloProvider client={client}> */}
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <Router>
            <App />
          </Router>
        </DndProvider>
      </Provider>
    {/* </ApolloProvider> */}
  </I18nextProvider>,
  document.querySelector("#root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styled from "styled-components";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useCallback, useEffect } from "react";
import { RootState } from "store/reducers/index";
import { useSelector, useDispatch } from "react-redux";
import { UserObj } from "models/user";
import { fetchUserData } from "api/authApi";
import { loginUser } from "store/actions/authActions";
import { useHistory } from "react-router-dom";
import {
  DEVICES_SETTINGS,
  GENERAL_SETTINGS,
  MAINBOARD,
  PREFERENCES_SETTINGS,
  PUBLIC_PROFILE_SETTINGS,
  SUBSCRIPTION_SETTINGS,
} from "navigation/Constants";
import { colorMemento } from "shared/colors";
import General from "pages/Settings/SettingsSections/General";
import Subscription from "pages/Settings/SettingsSections/Subscription";
import Devices from "pages/Settings/SettingsSections/Devices";
import Integrations from "pages/Settings/SettingsSections/Integrations";
import PinterestIntegration from "pages/Settings/SettingsSections/PinterestIntegration";
import Preferences from "pages/Settings/SettingsSections/Preferences";
import { useTranslation } from "react-i18next";
import { logoutUser } from "store/actions/authActions";
import { useParams } from "react-router-dom";
import { Button, CircularProgress, Slide, Snackbar } from "@mui/material";
import { showSnackbar } from "store/actions/view";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import PublicProfileSettings from "./SettingsSections/PublicProfileSettings";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1%;
  flex-direction: row;
  justify-content: start;
  padding-left: 96px;
  @media (max-width: 780px) {
    padding-left: 8px;
    flex-direction: column;
  }
  *::-webkit-scrollbar {
    display: none;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;

  flex-direction: column;
  padding-left: 48px;
  padding-right: 48px;
`;

const useStyles = makeStyles((theme: Theme) => ({
  settingsList: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("mobile")]: {
      flexDirection: "row",
      overflow: "auto",
      width: "100%",
    },
    "& .MuiListItemText-primary": {
      fontSize: "2rem",
      marginRight: "36px",
      width: "30%",
      [theme.breakpoints.down("mobile")]: {
        width: "auto",
        fontSize: "1.6rem",
        marginRight: "0px",
        padding: 0,
      },
    },
  },
}));

interface SettingsMenus {
  menuId: number;
  title: string;
}

const MENU_GENERAL = 0;
const MENU_PREFERENCES = 1;
const MENU_SUBSCRIPTION = 2;
const MENU_NOTION = 3;
const MENU_PINTEREST = 4;
const MENU_DEVICES = 5;
const MENU_PUBLIC_PROFILE = 6;

const UserSettings = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const user: UserObj = useSelector((state: RootState) => state.auth.user);
  // console.log("Got user " + JSON.stringify(user));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const snackBar = useSelector((state: RootState) => state.view.snackBarState);

  const classes = useStyles();
  const history = useHistory();
  const params: any = useParams();
  console.log("params %o", params);
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    if (index == MENU_PUBLIC_PROFILE) {
      history.push(PUBLIC_PROFILE_SETTINGS);
    }
    if (index == MENU_GENERAL) {
      history.push(GENERAL_SETTINGS);
    }
    if (index == MENU_PREFERENCES) {
      history.push(PREFERENCES_SETTINGS);
    }
    if (index == MENU_SUBSCRIPTION) {
      history.push(SUBSCRIPTION_SETTINGS);
    }
    if (index == MENU_DEVICES) {
      history.push(DEVICES_SETTINGS);
    } else {
      setSelectedIndex(index);
    }
  };

  React.useEffect(() => {
    if (params && params.subpath) {
      switch (params.subpath) {
        case "general":
          setSelectedIndex(MENU_GENERAL);
          break;
        case "subscription":
          setSelectedIndex(MENU_SUBSCRIPTION);
          break;
        case "integrations":
          setSelectedIndex(MENU_NOTION);
          break;
        case "pinterest":
          setSelectedIndex(MENU_PINTEREST);
          break;
        case "devices":
          setSelectedIndex(MENU_DEVICES);
          break;
        case "preferences":
          setSelectedIndex(MENU_PREFERENCES);
          break;
        case "public-profile":
          setSelectedIndex(MENU_PUBLIC_PROFILE);
          break;
      }
    }
  }, [params]);

  React.useEffect(() => {
    if (!user && isUserLoggedIn) {
      fetchUserData()
        .then((user: UserObj) => {
          console.log("Dispatch user " + user);
          dispatch(loginUser(user));
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    }
  }, [user]);

  const getMenu = useCallback(
    (selectedItem: number) => {
      if (selectedItem == MENU_GENERAL) {
        return <General />;
      } else if (selectedItem == MENU_SUBSCRIPTION) {
        return <Subscription />;
      } else if (selectedItem == MENU_NOTION) {
        return <Integrations />;
      } else if (selectedItem == MENU_PINTEREST) {
        return <PinterestIntegration />;
      } else if (selectedItem == MENU_DEVICES) {
        return <Devices />;
      } else if (selectedItem == MENU_PREFERENCES) {
        return <Preferences />;
      } else if (selectedItem == MENU_PUBLIC_PROFILE) {
        return <PublicProfileSettings />;
      }
    },
    [user]
  );

  const handleSnackbarClose = React.useCallback(
    (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      console.log("handle close for reason =" + reason);

      if (reason === "clickaway") {
        return;
      }
      dispatch(
        showSnackbar({
          message: "",
          action: "",
          onAction: null,
          loading: false,
        })
      );
    },
    []
  );

  const handleSnackBarAction = useCallback(
    (event: React.SyntheticEvent | React.MouseEvent) => {
      if (snackBar.onAction) {
        snackBar.onAction();
      }
      handleSnackbarClose(event);
    },
    [snackBar, handleSnackbarClose]
  );

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }

  const handleBack = useCallback(() => {
    history.push(MAINBOARD);
  }, [history]);

  const settingsMenus: SettingsMenus[] = [
    {
      menuId: MENU_GENERAL,
      title: t("user_settings_general_title"),
    },
    {
      menuId: MENU_PUBLIC_PROFILE,
      title: t("public_profile_user_settings"),
    },
    {
      menuId: MENU_PREFERENCES,
      title: t("user_settings_preferences_title"),
    },
    {
      menuId: MENU_SUBSCRIPTION,
      title: t("user_settings_subscription_title"),
    },
    {
      menuId: MENU_DEVICES,
      title: t("user_settings_devices_title"),
    },
    {
      menuId: MENU_NOTION,
      title: t("user_settings_integrations_title"),
    },
    {
      menuId: MENU_PINTEREST,
      title: t("user_settings_pinterest_integration_title"),
    },
  ];

  return (
    <MainContainer>
      <Header>
        <IconButton
          sx={{
            marginTop: "16px",
            marginLeft: "16px",
          }}
          onClick={handleBack}
        >
          <ArrowBackIcon
            sx={{
              width: "4rem",
              height: "4rem",
            }}
          />
        </IconButton>
      </Header>
      <BodyContainer>
        <List
          className={classes.settingsList}
          component="nav"
          aria-label="main mailbox folders"
        >
          {settingsMenus.map((item) => {
            return (
              <ListItemButton
                sx={{
                  borderLeft: selectedIndex === item.menuId ? "solid" : "none",
                  borderColor: colorMemento,
                  "@media (max-width: 780px)": {
                    borderLeft: "none",
                    width: "auto",
                    flex: "1 0 auto",
                    borderBottom:
                      selectedIndex === item.menuId ? "solid" : "none",
                    borderColor: colorMemento,
                  },
                }}
                selected={selectedIndex === item.menuId}
                onClick={(event) => handleListItemClick(event, item.menuId)}
              >
                <ListItemText primary={item.title} />
              </ListItemButton>
            );
          })}
        </List>
        <Divider orientation="vertical" flexItem />

        {getMenu(selectedIndex)}
        {/* <Typography>BLABLABLABLA</Typography> */}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          style={{ width: "800px", maxWidth: "800px" }}
          open={snackBar.message.length > 0}
          autoHideDuration={snackBar.loading ? 600000 : 6000} //when it's a loading snackbar, we leave it there until the action confirmation arrives to update it.
          onClose={handleSnackbarClose}
          TransitionComponent={TransitionUp}
          message={
            <h1 style={{ width: "100%", fontWeight: 50, fontSize: "1.7rem" }}>
              {snackBar.message}
            </h1>
          }
          action={
            <React.Fragment>
              {snackBar.loading ? (
                <CircularProgress
                  style={{ margin: "8px 0px 8px 130px", padding: "2px" }}
                />
              ) : (
                <Button
                  style={{
                    margin: "8px 0px 8px 130px",
                    fontSize: "1.4rem",
                  }}
                  color="primary"
                  size="large"
                  onClick={handleSnackBarAction}
                >
                  {snackBar.action}
                </Button>
              )}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </BodyContainer>
    </MainContainer>
  );
};

export default UserSettings;

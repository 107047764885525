import React from "react";
import ErrorLoadingIllustration from "illustrations/undraw_towing_-6-yy4.svg";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import styled from "styled-components";


const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow-x: hidden;
`;

const ErrorLoading = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <img
        style={{ marginTop: "60px", height: "300px"}}
        src={ErrorLoadingIllustration}
      />
      <Typography
        style={{ textAlign: "center", marginLeft: "12px", color: "black", marginTop: "36px" }}
        variant="h4"
      >
        {t("loading_data_error_message")}
      </Typography>
    </MainContainer>
  );
};

export default ErrorLoading;

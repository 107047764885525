import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { APP_PLAYSTORE_LINK } from "configuration";
import SecureServerContainer from "illustrations/undraw_secure_server.svg";
import { Button } from "@mui/material";
import { colorMemento } from "shared/colors";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useHistory } from "react-router-dom";
import { PRIVACY_POLICY } from "navigation/Constants";
import { TITLE_TEXT_SIZE, SUBTITLE_TEXT_SIZE } from "pages/Landing/Content";
import ExportNotionGif from "pages/Landing/illustrationgifs/notion_export_gif.gif";
import NotionIcon from "icons/notion_icon.svg";

const MainContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8%;
  padding-left: 8%;
  align-items: center;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const IllustraitonContainer = styled.img`
  width: 50%;
  @media (max-width: 780px) {
    width: 100vw;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 10%;
  }
`;

const TextContainer = styled.div`
  position:: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 3%;
  justify-content: center;
  @media (max-width: 780px) {
    width: 100%;
    margin-top: 10%;
  }
`;

const IntegrationsContainer = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <MainContainer>
      <TextContainer>
        <img src={NotionIcon} width={"28px"} height="28px" />

        <Typography
          variant="h4"
          sx={{
            fontSize: TITLE_TEXT_SIZE,
            alignItems: "top",
            alignText: "top",
            display: "flex",
            marginTop: "4px",
            marginBottom: "4px",
          }}
        >
          {t("landing_sync_notion_title")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: SUBTITLE_TEXT_SIZE,
            fontFamily: "Trebuchet MS, sans-serif",
            fontWeight: 300,
          }}
        >
          {t("landing_sync_notion_description")}
        </Typography>
      </TextContainer>

      <IllustraitonContainer
        src={ExportNotionGif}
        alt="Export your content to others apps like Notion"
      />
    </MainContainer>
  );
};

export default IntegrationsContainer;

import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { RootState } from "store/reducers/index";
import { UserObj, isUserEntitledToAiAutoAggdataClassification } from "models/user";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { colorMemento, stringToColour } from "shared/colors";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { updateUserSettingsGql, updateUsernameGql } from "graphql/mutations";
import { retrieveUserSettingsGql } from "graphql/queries";
import Switch from "@mui/material/Switch";
import BoltIcon from "@mui/icons-material/Bolt";
import { showSnackbar } from "store/actions/view";
import MaxDataUsageLimitReachedDialog from "components/UI/Modals/MaxDataUsageLimitReachedDialog";

const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;

  flex-direction: column;
  padding-left: 48px;
  padding-right: 48px;
  @media (max-width: 780px) {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  subscriptionPlanContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  userFullNameContainer: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  userFullNameWithAvatarContainerContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  dataUsageContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("mobile")]: {
      width: "100%",
    },
  },
  avatarNotHovered: {
    transform: "scale(1)",
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: 200,
    }),
    [theme.breakpoints.down("mobile")]: {
      width: "10px",
      height: "10px",
    },
  },
  boxSectionContiner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "start",
  },

  deleteAccountContainer: {
    padding: 0,
    display: "flex",
    marginTop: "36px",
    fontSize: "1.6rem",
    flexDirection: "row",
    width: "60%",
    cursor: "pointer",
    "& .MuiLink-root": {
      color: "#123456",
    },
  },
}));

const label = { inputProps: { "aria-label": "Switch demo" } };

const Preferences = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user: UserObj = useSelector((state: RootState) => state.auth.user);

  const [displayUpgradePlanDialog, setDisplayUpgradePlanDialog] =
  React.useState(false);

  const [updateUserSettings, updateUserSettingsResult] = useMutation(
    updateUserSettingsGql
  );

  const dispatch = useDispatch();
  const [aiClassificationChecked, setAiClassificationChecked] = React.useState(
    false
  );
  const [aiTagsClassificationChecked, setAiTagsClassificationChecked] = React.useState(
    false
  );

  const { loading, error, data } = useQuery(retrieveUserSettingsGql);

  React.useEffect(() => {
    if (data && data.userSettings) {
      setAiClassificationChecked(data.userSettings.aiClassificationEnabled);
      setAiTagsClassificationChecked(data.userSettings.aiTagsClassificationEnabled);
    }
  }, [data]);


  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      //setChecked(event.target.checked);
      if (!isUserEntitledToAiAutoAggdataClassification(user)) {
        setDisplayUpgradePlanDialog(true);
        return;
      }

      const newValue = !aiClassificationChecked;
      setAiClassificationChecked(newValue);

      updateUserSettings({
        variables: {
          input: {
            aiClassificationEnabled: newValue,
          },
        },
      }).catch((e) => {
        dispatch(
          showSnackbar({
            message: t("failed_to_update_user_setting_error"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        setAiClassificationChecked(!newValue); //we restore the UI value back
      });
    },
    [aiClassificationChecked, user, setDisplayUpgradePlanDialog]
  );

  const handleTagsClassificationChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      //setChecked(event.target.checked);
      if (!isUserEntitledToAiAutoAggdataClassification(user)) {
        setDisplayUpgradePlanDialog(true);
        return;
      }

      const newValue = !aiTagsClassificationChecked;
      setAiTagsClassificationChecked(newValue);

      updateUserSettings({
        variables: {
          input: {
            aiTagsClassificationEnabled: newValue,
          },
        },
      }).catch((e) => {
        dispatch(
          showSnackbar({
            message: t("failed_to_update_user_setting_error"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        setAiTagsClassificationChecked(!newValue); //we restore the UI value back
      });
    },
    [aiTagsClassificationChecked, user, setDisplayUpgradePlanDialog]
  );

  if (loading || !user) {
    return (
      <>
        <MenuContainer>
          <Box className={classes.subscriptionPlanContainer}>
            <Box
              className={classes.boxSectionContiner}
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: "1 1 auto",
              }}
            >
              <Skeleton height="36px" width="100%" />
              <Skeleton height="36px" width="80%" />
            </Box>
            <Box
              className={classes.boxSectionContiner}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent:"end"
              }}
            >
              <Skeleton height="36px" width="10%" />
            </Box>
          </Box>
        </MenuContainer>
      </>
    );
  } else {
    return (
      <MenuContainer>
        <Box className={classes.subscriptionPlanContainer}>
          <Box className={classes.boxSectionContiner}>
            <Typography
              style={{
                marginTop: "2px",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
              }}
              variant="h4"
              color="text.secondary"
            >
              {t("user_settings_enable_tag_ai_classification_title")}
              <BoltIcon
                sx={{
                  height: "24px",
                  width: "24px",
                  color: colorMemento,
                }}
              />
            </Typography>
            <Typography
              style={{ marginTop: "4px" }}
              variant="h6"
              color="text.secondary"
            >
              {t("user_settings_enable_tag_ai_classification_description")}
            </Typography>
          </Box>
          <Box className={classes.boxSectionContiner}>
            <Switch
              {...label}
              checked={aiTagsClassificationChecked}
              onChange={handleTagsClassificationChange}
            />
          </Box>
        </Box>
        <Box className={classes.subscriptionPlanContainer}>
          <Box className={classes.boxSectionContiner}>
            <Typography
              style={{
                marginTop: "2px",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
              }}
              variant="h4"
              color="text.secondary"
            >
              {t("user_settings_enable_ai_classification_title")}
              <BoltIcon
                sx={{
                  height: "24px",
                  width: "24px",
                  color: colorMemento,
                }}
              />
            </Typography>
            <Typography
              style={{ marginTop: "4px" }}
              variant="h6"
              color="text.secondary"
            >
              {t("user_settings_enable_ai_classification_description")}
            </Typography>
          </Box>
          <Box className={classes.boxSectionContiner}>
            <Switch
              {...label}
              checked={aiClassificationChecked}
              onChange={handleChange}
            />
          </Box>
        </Box>
        {displayUpgradePlanDialog && (
          <MaxDataUsageLimitReachedDialog
            open={displayUpgradePlanDialog}
            description={t("upgrade_plan_to_auto_ai_classification")}
            handleModalClose={() => {
              setDisplayUpgradePlanDialog(false);
            }}
          />
        )}
      </MenuContainer>
    );
  }
};

export default React.memo(Preferences);

import { Box, Fade, Modal, Theme, Typography } from "@mui/material";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import styled from "styled-components";
import { CollectionObj } from "models/collection";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { generatePublicAccessGql } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { retrievePublicCollectionsForUsernameGql } from "graphql/queries";
import PublicIcon from "@mui/icons-material/Public";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UserObj } from "models/user";
import { RootState } from "store/reducers";
import { showSnackbar } from "store/actions/view";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("mobile")]: {
        width: "80%",
      },
    },
    addButton: {
      marginEnd: "12px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: "24px",
    },
  })
);

interface PublishCollectionModalProps {
  open: boolean;
  collection: CollectionObj;
  handleModalClose: () => void;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
  text-align: center;
`;

const PublishCollectionModal = ({
  open,
  collection,
  handleModalClose,
}: PublishCollectionModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );

  const [generatePublicAccess, generatePublicAccessResult] = useMutation(
    generatePublicAccessGql,
    {
      variables: {
        collectionId: collection.id,
      },
      refetchQueries: [
        {
          query: retrievePublicCollectionsForUsernameGql,
          variables: {
            username: currentUser.username,
          },
          context: {
            clientName: "public",
          },
        },
      ],
    }
  );

  const handleMakeCollectionPublic = React.useCallback(() => {
    generatePublicAccess()
      .then(() => {
        handleModalClose();
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("generic_failure_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [handleModalClose]);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose()}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Title>
              {t("publish_collection_modal_title", {
                collection_name: collection.name,
              })}
            </Title>
            <Box
              sx={{
                marginTop: "16px",
                padding: "8px",
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#faf6e9",
                borderRadius: "4px",
                alignItems: "center",
              }}
            >
              <PublicIcon
                sx={{
                  marginLeft: "8px",
                }}
              />

              <Typography
                sx={{
                  fontSize: "1.6rem",
                  marginLeft: "16px",
                }}
              >
                {t("publish_collection_description")}
              </Typography>
            </Box>
            <LoadingButton
              variant="outlined"
              fullWidth={false}
              sx={{
                marginTop: "16px",
                display: "inline-flex",
                color: "black",
              }}
              onClick={handleMakeCollectionPublic}
              loading={generatePublicAccessResult.loading}
            >
              {t("publish_action")}
            </LoadingButton>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(PublishCollectionModal);

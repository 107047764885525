import React, { useCallback } from "react";
import styled from "styled-components";
import Carousel from "react-material-ui-carousel";
import CreateReminderGif from "pages/Landing/illustrationgifs/create_reminder_onfly.gif";
import PickAndCollect from "pages/Landing/illustrationgifs/pick_and_collect_as_you_go.gif";
import EasySearch from "pages/Landing/illustrationgifs/easy_search.gif";
import CaptureIdeas from "pages/Landing/illustrationgifs/capture_ideas.gif";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const CarouselItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 780px) {
    flex-direction: column;
  }
  img {
    height: 500px;
    @media (max-width: 780px) {
      height: 650px;
    }
  }
`;

const CarouselItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  @media (max-width: 780px) {
    width: 100%;
    margin-bottom: 10%;
  }
`;

const CarouselContainer = () => {
  const [carouselInterval, setCarouselInterval] = React.useState(30000);
  const [currentItem, setCurrentItem] = React.useState(0);
  const { t } = useTranslation();
  var items = [
    {
      gif: PickAndCollect,
      title: t("carousel_landing_item1_title"),
      description: t("carousel_landing_item1_description"),
      gifDuration: 13000,
    },
    {
      gif: CreateReminderGif,
      title: t("carousel_landing_item2_title"),
      description: t("carousel_landing_item2_description"),
      gifDuration: 30000,
    },

    {
      gif: EasySearch,
      title: t("carousel_landing_item3_title"),
      description: t("carousel_landing_item3_description"),
      gifDuration: 13000,
    },
    {
      gif: CaptureIdeas,
      title: t("carousel_landing_item4_title"),
      description: t("carousel_landing_item4_description"),
      gifDuration: 26000,
    },
  ];

  return (
    <Carousel
      interval={carouselInterval}
      onChange={(now: number, previous) => {
        setCarouselInterval(items[now].gifDuration);
        setCurrentItem(now);
      }}
      stopAutoPlayOnHover
      NextIcon={<ArrowForwardIosIcon />}
      PrevIcon={<ArrowBackIosNewIcon />}
    >
      {items.map((item, i) => (
        <CarouselItemContainer>
          <CarouselItemTextContainer>
            <Typography
              variant="h4"
              sx={{
                fontSize: "3rem",
                lineHeight: "4.6rem",
                fontFamily: "Trebuchet MS, sans-serif",
                fontWeight: 500,
              }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "2rem",
                fontFamily: "Trebuchet MS, sans-serif",
                fontWeight: 300,
              }}
            >
              {item.description}
            </Typography>
          </CarouselItemTextContainer>
          <div style={{
            display: "flex",
            alignItems: "end",
            flex: "0 1 0",
            marginRight: "48px"
          }}>
          {/* height set in main container selector to apply media query on img */}
          {currentItem == i && <img src={item.gif}></img>}
          {currentItem != i && ( //placeholder used to avoid weird jump of text when the carousel goes to next view
            <div style={{ height: "500px", width: "24px" }}></div>
          )}
          </div>
        </CarouselItemContainer>
      ))}
    </Carousel>
  );
};

export default CarouselContainer;

import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { ToolbarBtn, ToolSpan } from "containers/Toolbar/Tool/ToolElements";


import { ToggleTool, LabelObj } from "shared/types";

interface ToolProp {
  id?: string;
  title: string;
  bgImage?: string;
  onToggle?: (noteProperty: ToggleTool) => void;
  showPalette?: (() => void) | undefined;
  hidePalette?: (() => void) | undefined;
  deleteTodo?: () => void | undefined;
  setShowLabel?: (val: boolean) => void;
  onRemove?: (label: string) => void;
  isInputField?: boolean;
  isPinned?: boolean;
  editLabel?: boolean;
  notePin?: boolean;
  inputPin?: boolean;
  isLabel?: boolean;
  isChecked?: boolean;
  label?: string | LabelObj;
  newLabel?: string;
  clearInput?: () => void;
  onDelete?: (id: string) => void;
  onRemoveNoteLabel?: (id: string, label: string) => void;
  children?: any;
}

interface StyleProps {
  bgImage?: string | undefined;
  notePin?: boolean | undefined;
  inputPin?: boolean | undefined;
  isPinned?: boolean | undefined;
  isLabel?: boolean | undefined;
  editLabel?: boolean | undefined;
}

const Tool = ({
  id,
  title,
  children,
}: ToolProp) => {
 // const dispatch = useDispatch(); 

  return (
    <>
      <Tooltip title={<ToolSpan>{title}</ToolSpan>}>
        {/* <ToolbarBtn
          onMouseEnter={e => console.log("mouse enter") }
          onMouseLeave={e => console.log("mouse leave") }
          onClick={(e) => handleClick(e, title)}
          {...styleProps}
        /> */}
        {children}
         
      </Tooltip>
    </>
  );
};

export default React.memo(Tool);

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { savePayment, createSubscriptionForCustomer } from "api/stripeApi";
import { DEBUG } from "configuration";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/index";
import { UserObj } from "models/user";
import { fetchUserData } from "api/authApi";
import styled from "styled-components";
import { colorMemento, colorGreyMemento } from "../../shared/colors";
import Button from "components/Button";
import { useDispatch } from "react-redux";
import { loginUser } from "store/actions/authActions";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";
import { getUserCurrency } from "localstorage/localstorage";
import Alert from "@mui/material/Alert";
import { logoutUser } from "store/actions/authActions";

const CardInputWrapper = styled.div`
  border: 2px solid ${colorMemento};
  border-radius: 8px;
  padding: 20px 4px;
  height: 64px;
  margintop: 16px;
`;

const Container = styled.div`
  width: 50%;
`;

const inputStyle = {
  iconColor: colorGreyMemento,
  color: "black",
  fontWeight: "500",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "1.5rem",
  fontSmoothing: "antialiased",
  "::placeholder": {
    color: colorGreyMemento,
  },
};

const useStyles = makeStyles((theme) => ({
  nameInputField: {
    marginTop: "16px",
    marginBottom: "16px",

    "& .MuiInputBase-root": {
      border: "2px solid #FDC848",
      borderRadius: "8px",
    },
    "& .MuiInputBase-input": {
      fontSize: "2rem",
      padding: "12px 12px",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    width: "45%",

    input: {
      "::placeholder": {
        color: "blue",
      },
    },
  },
  payButton: {
    fontSize: "3rem",
    fontWeight: "normal",
    "& .MuiButtonBase-root": {
      fontSize: "1.5rem",
      fontWeight: "normal",
    },
  },
  editPaymentButtonsBox: {
    display: "flex",
    marginBottom: "36px",
    flexDirection: "row",
  },
}));

interface CheckoutFormProps {
  onSubscriptionSuccess: () => void;
  onSubscriptionFailed: () => void;
}

const CheckoutForm = ({
  onSubscriptionSuccess,
  onSubscriptionFailed,
}: CheckoutFormProps) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [displayPaymentError, setDisplayPaymentError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(t("payment_error_invalid_card"));
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [fullNameFieldContent, setFullNameFieldContent] = useState("");
  const [fullNameErrorText, setFullNameErrorText] = useState("");
  const classes = useStyles();
  const user: UserObj = useSelector((state: RootState) => state.auth.user);

  console.log(" stripe user " + JSON.stringify(user));
  // Handle real-time validation errors from the CardElement.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisplayPaymentError(false);
    if (fullNameFieldContent.length == 0) {
      setFullNameErrorText("Full name is required");
      return;
    } else {
      setFullNameErrorText("");
    }
    const card = elements.getElement(CardElement);
    setIsPaymentLoading(true);
    const processPayment = async (email: string) => {

      const userCurrencyFromLocal = getUserCurrency();

      createSubscriptionForCustomer(userCurrencyFromLocal).then((data)=> {
        if (DEBUG){
          console.log("createSubscriptionForCustomer: result is %o", data)
        }
        if (data.result == "0") {
              //Success
              setIsPaymentLoading(false);
              onSubscriptionSuccess();
        } else if (data.result == "1"){
          const clientSecret = data.clientSecret
          //const confirmPaymentResult = await
           stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: card,
              billing_details: {
                name: fullNameFieldContent,
                email: user.email,
              },
            }
          }).then((result) => {
            console.log("payment confirmed result %o", result)
            setIsPaymentLoading(false);
            if(result.error) {
              //alert(result.error.message);
              setErrorMsg(t("payment_error_failed_to_confirm_payment"));
              setDisplayPaymentError(true);
            } else {
              // Successful subscription payment
              if (result.paymentIntent.status == "succeeded"){
                onSubscriptionSuccess();
              }
            }            
          });
        } else if (data.result == "2") {
              //User is already subscribed and subscription is ok
              setIsPaymentLoading(false);
              setErrorMsg(t("payment_error_already_subscribed"));
              setDisplayPaymentError(true);
        } else if (data.result == "3") {
              //Payment passed but failed to provision the product
              setIsPaymentLoading(false);
              setErrorMsg(t("payment_error_failed_to_provision"));
              setDisplayPaymentError(true);
        }
      }).catch(e => {
        console.log("caught an error %o", e);
            setErrorMsg(t("payment_error_failed_toreach_backend"));
            setDisplayPaymentError(true);
            setIsPaymentLoading(false);
      })
    };
    if (!user) {
      //If user goes directly to payment page, user info state might not be there.
      //So we make sure to fetch user information
      fetchUserData()
        .then((user: UserObj) => {
          //dispatchLogin(user);
          //history.push(MAINBOARD);
          console.log("User data is  " + JSON.stringify(user));
          dispatch(loginUser(user));
          processPayment(user.email);
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    } else {
      processPayment(user.email);
    }
  };
  return (
    <Paper
      elevation={0}
      sx={{
        padding: "16px",
      }}
    >
      <Typography
        sx={{ width: "100%", marginBottom: "36px" }}
        variant="h3"
        component="div"
        gutterBottom
      >
        {t("payment_details_title")}
      </Typography>
      {displayPaymentError && (
        <Alert
          severity="error"
          style={{ fontSize: "1.8rem", marginBottom: "18px" }}
        >
          {errorMsg}
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="stripe-form">
        <TextField
          error={fullNameErrorText.length > 0}
          helperText={fullNameErrorText}
          className={classes.nameInputField}
          fullWidth={true}
          placeholder={t("full_name_title")}
          sx={{ fontSize: "3rem" }}
          variant="standard"
          onChange={(e) => {
            setFullNameFieldContent(e.target.value);
          }}
          FormHelperTextProps={{
            style: {
              fontSize: "1.2rem",
              color: "black",
            },
          }}
          InputProps={{
            style: {
              color: "black",
              fontWeight: "500",
            },
            startAdornment:
              fullNameErrorText.length == 0 ? (
                <InputAdornment position="start">
                  <PersonSharpIcon
                    sx={{
                      opacity: "0.5",
                      color: colorGreyMemento,
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <ErrorOutlineIcon
                    sx={{
                      opacity: "0.5",
                      color: "red",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </InputAdornment>
              ),
            disableUnderline: true,
          }}
        ></TextField>
        <div className="form-row" style={{ marginTop: "32px" }}>
          <CardInputWrapper>
            <CardElement
              options={{
                style: {
                  base: inputStyle,
                },
              }}
              onChange={handleChange}
            />
          </CardInputWrapper>
          <div className="card-errors" role="alert">
            {error}
          </div>
        </div>

        <Button
          //className={classes.payButton}
          //fullWidth={true}

          // sx={{
          //   color: "white",
          //   height: "48px",
          //   marginTop: "16px",
          //   fontSize: "1.5rem",
          // }}
          // variant="contained"
          type="submit"
          loading={isPaymentLoading}
        >
          {t("payment_submit_payment")}
        </Button>
      </form>
    </Paper>
  );
};
export default CheckoutForm;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import NoMatching from "components/UI/NoMatching/NoMatching";
import AggDataItemsContainer from "components/AggDataItems/AggDataItemsContainer";
import { RequestTypes } from "components/AggDataItems/AggDataItemsContainer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CollectionsContainer from "components/CollectionItems/CollectionsContainer";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { setHideAddNewItemFabButton } from "store/actions/view";
import AggdataDisplayControl, {
  FILTER_TYPE,
  SORT_TYPE,
  VIEW_TYPE,
} from "components/UI/Controls/AggdataDisplayControl";
import { Box } from "@mui/material";
import {
  getAggdataFilterType,
  getAggdataSortType,
  getAggdataViewType,
} from "localstorage/localstorage";
import SimpleTagsFilter from "components/UI/Filters/SimpleTagsFilter";
import { useTranslation } from "react-i18next";
import { AggDataTagObj } from "models/aggdata";

const Container = styled.div`
  height: 100%;
  overflow-x: hidden;
  width: 100%;
`;

const TabsContainer = styled.div`
  padding-left: 135px;
  display: flex;
  flex-directoin: row;
  justify-content: space-between;
  height: 40px;
  margin-top: 8px;
  margin-bottom: 12px;
  width: 100%;
`;

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    "& .MuiButtonBase-root": {
      fontSize: "1.4rem",
    },
  },
}));

interface ArchivedItemsContainerProps {
  tabName?: string;
}

const ArchivedItemsContainer = ({
  tabName = RequestTypes.ARCHIVED_ITEMS,
}: ArchivedItemsContainerProps) => {
  const classes = useStyles();
  const params: any = useParams();
  const [displaySortType, setDisplaySortType] = React.useState<SORT_TYPE>(
    getAggdataSortType()
  );
  const [dataTypeFilter, setDataTypeFilter] = React.useState<FILTER_TYPE>(
    getAggdataFilterType()
  );
  const [displayViewType, setDispayViewType] = React.useState<VIEW_TYPE>(
    getAggdataViewType()
  );
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = React.useState(
    params.subpathbis && params.subpathbis == "collections"
      ? RequestTypes.COLLECTIONS
      : RequestTypes.ALL_ITEMS
  );
  const history = useHistory();

  const [selectedFilterTags, setSelectedFilterTags] = useState<AggDataTagObj[]>(
    []
  );

  const { t } = useTranslation();

  // useEffect(() => {
  //   if (params.subpathbis == "collections") {
  //     setCurrentTab(RequestTypes.COLLECTIONS);
  //   } else {
  //     setCurrentTab(RequestTypes.ALL_ITEMS);
  //   }
  // }, [params]);

  useEffect(() => {
    if (currentTab === RequestTypes.COLLECTIONS) {
      history.replace("/board/archives/collections");
    } else if (currentTab === RequestTypes.ALL_ITEMS) {
      history.replace("/board/archives/all");
    }
  }, [currentTab]);

  React.useEffect(() => {
    dispatch(setHideAddNewItemFabButton(true));
    return () => {
      dispatch(setHideAddNewItemFabButton(false));
    };
  }, []);

  //console.log("Berkok Params " + JSON.stringify(params));

  var container;
  if (currentTab === RequestTypes.COLLECTIONS) {
    container = (
      <Container>
        <CollectionsContainer displayArchivedCollections></CollectionsContainer>
      </Container>
    );
  } else {
    container = (
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginRight: "5%",
          }}
        >
          <AggdataDisplayControl
            onSortTypeChanged={(sortType: SORT_TYPE) => {
              setDisplaySortType(sortType);
            }}
            onViewTypeChanged={(viewType: VIEW_TYPE) => {
              setDispayViewType(viewType);
            }}
            onFilterTypeChanged={(filterType: FILTER_TYPE) => {
              setDataTypeFilter(filterType);
            }}
            selectedTags={selectedFilterTags}
            onSelectedTagsChanged={(tags: AggDataTagObj[]) => {
              setSelectedFilterTags(tags);
            }}
          />
        </Box>

        {selectedFilterTags.length > 0 && (
          <SimpleTagsFilter
            allSelectedText={t("filter_all_collections_selected_text")}
            inputHint={t("filter_by_tags_title")}
            onSelectionChanged={(values) => {
              // console.log("YIT simple filter tag selection changes " + JSON.stringify(values))
              setSelectedFilterTags(
                values.map((value) => {
                  return { name: value };
                })
              );
            }}
            selectedValues={selectedFilterTags.map(
              (selectedTag) => selectedTag.name
            )}
          />
        )}
        <AggDataItemsContainer
          dataTypeFilter={dataTypeFilter}
          displayViewType={displayViewType}
          displaySortType={displaySortType}
          requestType={tabName}
          filterTags={selectedFilterTags}
        />
      </Container>
    );
  }

  const handleTabChange = (event: any, selectedTab: any) => {
    setCurrentTab(selectedTab);
  };

  return (
    <>
      <TabsContainer>
        <Tabs
          className={classes.tab}
          textColor="primary"
          indicatorColor="primary"
          value={currentTab}
          onChange={handleTabChange}
          aria-label="icon label tabs example"
        >
          <Tab value={RequestTypes.ALL_ITEMS} label="All" />
          <Tab value={RequestTypes.COLLECTIONS} label="Collections" />
        </Tabs>
      </TabsContainer>
      {container}
    </>
  );
};

export default React.memo(ArchivedItemsContainer);

export const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: 7,
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#bdbdbd",
    borderRadius: "8px",
  },
};

export const editorScrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: 3,
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.9)`,
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#bdbdbd",
    borderRadius: "4px",
  },
};

export const styledTooltipTitle = (text) => {
  return (
    <h1 style={{ fontWeight: 100, fontSize: "1.4rem", padding: "0.6rem", textAlign : "center" }}>
      {text}
    </h1>
  );
};

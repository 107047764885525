import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Fade,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import {
  AggDataObj,
  AggDataTagObj,
  DataType,
  buildLinkPreviewInformation,
  getLinkPreviewInformation,
} from "models/aggdata";
import React, { useCallback, useRef } from "react";
import { DEBUG, getImageForDataId } from "configuration";
import AggDataFeedItemHeader from "containers/AggData/AggDataFeedItemHeader";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLazyQuery, useMutation } from "@apollo/client";
import { updateAggDataGql } from "graphql/mutations";
import AggdataCommentView from "containers/Comments/AggdataCommentView";
import AggDataTagsContainer from "../ChipsContainer/AggDataTagsContainer";
import { scrollBarStyle } from "shared/styles";

interface AnnotateAggdataModal {
  open: boolean;
  handleModalClose: (open: boolean) => void;
  aggdata: AggDataObj;
}

const DUMMY_INITIAL_EMPTY_OPTIONS = [{ name: "", isLoading: true }];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "60%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "row",
      height: "80%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),

      paddingRight: "0px",
      paddingLeft: "0px",
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "16px",
      overflow: "hidden",
      "& .MuiInput-root": {
        height: "70%",
      },
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
    annotationTextField: {
      fontSize: "1.9rem",
    },
  })
);
const AnnotateAggdataModal = ({
  open,
  handleModalClose,
  aggdata,
}: AnnotateAggdataModal) => {
  const classes = useStyles();

  const [updateAggData, updateAggDataResult] = useMutation(updateAggDataGql);

  const { t } = useTranslation();
  const [displayEmojiPicker, setDisplayEmojiPicker] = React.useState(false);
  const [annotateTextFocused, setAnnotateTextFocused] = React.useState(false);
  const [titleTextFocused, setTitleextFocused] = React.useState(false);
  const [descriptionTextFocused, setDescriptionTextFocused] = React.useState(
    false
  );
  const [linkTextFocused, setLinkTextFocused] = React.useState(false);
  const textFieldValueRef = useRef(""); //creating a refernce for TextField Component

  const [aggData, setAggdata] = React.useState(aggdata);

  const [annotation, setAnnotation] = React.useState(aggdata.annotation);

  const [title, setTitle] = React.useState(() => {
    const previewInformation = getLinkPreviewInformation(aggdata);
    return previewInformation?.title;
  });
  const [description, setDescription] = React.useState(() => {
    const previewInformation = getLinkPreviewInformation(aggdata);
    return previewInformation?.description;
  });
  const [previewImageUrl, setPreviewImageUrl] = React.useState(() => {
    const previewInformation = getLinkPreviewInformation(aggdata);
    return previewInformation?.imgUrl;
  });

  React.useEffect(() => {
    const previewInformation = getLinkPreviewInformation(aggdata);
    let newDataContent = buildLinkPreviewInformation(
      title,
      description,
      previewImageUrl,
      previewInformation?.hostUrl
    );
    setAggdata({ ...aggData, dataContent: newDataContent });
  }, [title, description, previewImageUrl]);

  const toggleEmojiPicker = React.useCallback(() => {
    setDisplayEmojiPicker(!displayEmojiPicker);
  }, [displayEmojiPicker, setDisplayEmojiPicker]);

  const hanleUpdateAnnotation = React.useCallback(() => {
    var aggdataChanged = false;
    var aggDataUpdateForBackend: any = {
      id: aggdata.id,
    };
    if (aggdata.annotation != textFieldValueRef.current.value) {
      aggdataChanged = true;
      aggDataUpdateForBackend = {
        ...aggDataUpdateForBackend,
        annotation: textFieldValueRef.current.value,
      };
    }
    if (aggdata.dataContent != aggData.dataContent) {
      aggdataChanged = true;
      aggDataUpdateForBackend = {
        ...aggDataUpdateForBackend,
        dataContent: aggData.dataContent,
      };
    }
    if (aggdataChanged) {
      updateAggData({
        variables: { input: aggDataUpdateForBackend },
        refetchQueries: undefined,
      })
        .then(() => {
          handleModalClose(false);
        })
        .catch((e) => {});
    } else {
      handleModalClose(false);
    }
  }, [textFieldValueRef, aggdata, aggData, handleModalClose]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        onClick={(e) => {
          e.stopPropagation(); //useful because for some reason when you click outside to close the modal the click still propagates to components underneath
        }}
      >
        <Fade in={open}>
          <Box
            className={classes.paper}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  width: "100px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  height: "100%",
                  flex: "1 1 0px",
                  justifyContent: "center",
                }}
              >
                <AggdataCommentView aggData={aggData}></AggdataCommentView>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 0px",
                  paddingTop: "16px",
                  height: "100%",
                }}
              >
                <AggDataFeedItemHeader aggdata={aggdata} />
                <Box
                  sx={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: "16px",
                    ...scrollBarStyle,
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "8px",
                    }}
                  ></Box>
                  <AggDataTagsContainer aggdata={aggdata} />
                  <Box
                    sx={{
                      marginTop: "8px",
                    }}
                  ></Box>
                  <TextField
                    label={
                      !annotateTextFocused &&
                      (!annotation || annotation.length == 0)
                        ? t("annotate_textfield_label")
                        : undefined
                    }
                    variant="outlined"
                    className={classes.annotationTextField}
                    maxRows={10}
                    //defaultValue={aggdata.annotation}
                    value={annotation}
                    onChange={(e) => {
                      setAnnotation(e.target.value);
                    }}
                    inputRef={textFieldValueRef}
                    multiline
                    color="primary"
                    InputLabelProps={{
                      style: {
                        fontSize: "medium",
                      },
                    }}
                    onFocus={(e) => {
                      setAnnotateTextFocused(true);
                    }}
                    onBlur={(e) => {
                      setAnnotateTextFocused(false);
                    }}
                    InputProps={{
                      style: {
                        height: "200px",
                      },
                      endAdornment: (
                        <InputAdornment position="end" sx={{ padding: "0px" }}>
                          <IconButton
                            id="sentiment-icon"
                            onClick={toggleEmojiPicker}
                            aria-label="comment"
                            sx={{
                              padding: "0px",
                            }}
                            size="large"
                            color="primary"
                          >
                            <SentimentSatisfiedAltIcon
                              id="sentiment-icon"
                              sx={{ width: "24px", height: "24px" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: {
                        fontSize: "large",
                        height: "100%",
                        lineHeight: "normal",
                        overflow: "auto",
                      },
                    }}
                  />

                  {displayEmojiPicker && (
                    <Box
                      sx={{
                        width: "100%",
                        position: "sticky",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        bottom: 0,
                        right: 0,
                        zIndex: 10,
                      }}
                    >
                      <Picker
                        data={data}
                        theme="light"
                        onEmojiSelect={(e: any) => {
                          textFieldValueRef.current.value =
                            textFieldValueRef.current.value + e.native;
                        }}
                        onClickOutside={(e) => {
                          console.log(
                            "Onclickoutside displayed?" +
                              displayEmojiPicker +
                              " target " +
                              e.srcElement.id
                          );
                          //We do this because for some strange reason onClickoutside gets called the first time we try to open the picker...
                          /* if (e.srcElement.id == "sentiment-icon") {
                        return;
                      } */
                          if (displayEmojiPicker) {
                            setDisplayEmojiPicker(false);
                          }
                        }}
                      />
                    </Box>
                  )}
                  {aggdata.dataType == DataType.WEB_URL && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "600px",
                        marginTop: "16px",
                      }}
                    >
                      <TextField
                        label={
                          !titleTextFocused && title?.length == 0
                            ? t("title")
                            : undefined
                        }
                        variant="outlined"
                        className={classes.inputLabel}
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        onFocus={(e) => {
                          setTitleextFocused(true);
                        }}
                        onBlur={(e) => {
                          setTitleextFocused(false);
                        }}
                        maxRows={10}
                        defaultValue={aggdata.annotation}
                        multiline
                        color="primary"
                        sx={{
                          marginTop: "16px",
                        }}
                        inputProps={{
                          style: {
                            fontSize: "large",
                            height: "100%",
                            lineHeight: "normal",
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: "medium" } }}
                      />
                      <TextField
                        sx={{
                          marginTop: "16px",
                        }}
                        label={
                          !linkTextFocused && previewImageUrl?.length == 0
                            ? t("preview_url_title")
                            : undefined
                        }
                        variant="outlined"
                        className={classes.inputLabel}
                        value={previewImageUrl}
                        onChange={(e) => {
                          setPreviewImageUrl(e.target.value);
                        }}
                        onFocus={(e) => {
                          setLinkTextFocused(true);
                        }}
                        onBlur={(e) => {
                          setLinkTextFocused(false);
                        }}
                        maxRows={10}
                        defaultValue={aggdata.annotation}
                        multiline
                        color="primary"
                        inputProps={{
                          style: {
                            fontSize: "large",
                            height: "100%",
                            lineHeight: "normal",
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: "medium" } }}
                      />
                      <TextField
                        sx={{
                          marginTop: "16px",
                        }}
                        label={
                          !descriptionTextFocused && description?.length == 0
                            ? t("description")
                            : undefined
                        }
                        variant="outlined"
                        className={classes.inputLabel}
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        onFocus={(e) => {
                          setDescriptionTextFocused(true);
                        }}
                        onBlur={(e) => {
                          setDescriptionTextFocused(false);
                        }}
                        maxRows={10}
                        defaultValue={aggdata.annotation}
                        multiline
                        color="primary"
                        inputProps={{
                          style: {
                            fontSize: "large",
                            height: "100%",
                            lineHeight: "normal",
                          },
                        }}
                        InputLabelProps={{ style: { fontSize: "medium" } }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </div>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                paddingTop: "16px",
                paddingRight: "16px",
              }}
            >
              <LoadingButton
                sx={{}}
                loading={updateAggDataResult.loading}
                variant="contained"
                onClick={hanleUpdateAnnotation}
              >
                {t("save_action")}
              </LoadingButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(AnnotateAggdataModal);

import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutForm from "pages/Payments/CheckoutForm";
import SimpleHeader from "components/Header/SimpleHeader";
import styled from "styled-components";
import { fetchUserData } from "api/authApi";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/index";
import { UserObj } from "models/user";
import { useDispatch } from "react-redux";
import { loginUser } from "store/actions/authActions";
//import PricingCard from "components/Pricing/PricingCard";
import CheckoutRecapCard from "pages/Payments/CheckoutRecapCard";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { colorMemento } from "shared/colors";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getDateInOneMonth } from "shared/dataUtils";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { MAINBOARD } from "navigation/Constants";
import { useTranslation } from "react-i18next";
import { setUserCurrency, getUserCurrency } from "localstorage/localstorage";
import { getPriceInLocalCurrency, getCurrencySymbol } from "shared/currencies";
import { fetchUserCurrency } from "api/ipapi";
import { DEFAULT_CURRENCY, STRIPE_API_KEY } from "configuration";
import { logoutUser } from "store/actions/authActions";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from "shared/utility";
import { SUBSCRIPTION_MEMENTEE_PLAN } from "configuration";

const stripePromise = loadStripe(STRIPE_API_KEY);

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  overflow-x: hidden;
`;

const FormContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  vertical-align: top;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const CardInputContainer = styled.div`
  width: 50%;
  padding-left: 32px;
  @media (max-width: 780px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const RecapCardContainer = styled.div`
  width: 30%;
  padding-left: 32px;
  @media (max-width: 780px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentRecapLine: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    goToBoardButton: {
      fontSize: "3rem",
    },
  })
);

const Payment = () => {
  const dispatch = useDispatch();
  const user: UserObj = useSelector((state: RootState) => state.auth.user);
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [localCurrency, setLocalCurrency] = React.useState(
    getUserCurrency() ? getUserCurrency() : DEFAULT_CURRENCY
  );

  React.useEffect(() => {
    const userCurrencyFromLocal = getUserCurrency();
    if (!userCurrencyFromLocal) {
      fetchUserCurrency().then((data) => {
        setUserCurrency(data.result);
        setLocalCurrency(data.result);
      });
    }
  }, []);

  const TIER_MEMENTEE = {
    id: 1,
    title: t("tier_mementee_title"),
    price: getPriceInLocalCurrency(localCurrency),
    description: [
      t("tier_mementee_description_1"),
      t("tier_mementee_description_5"),
      t("tier_mementee_description_2"),
      t("tier_mementee_description_6"),
      t("tier_mementee_description_3"),
      t("tier_mementee_description_4"),
    ],
  };
  //const [isPaymentSuccess, setPaymentSuccess] = useState(false);
  const [displayCheckoutForm, setDisplayCheckoutForm] = useState(true);
  const [hideCheckoutView, setHideCheckoutView] = useState(false);
  const [displayCheckIcon, setDisplayCheckIcon] = useState(false);
  // const [displayCheckoutForm, setDisplayCheckoutForm] = useState(false);
  // const [hideCheckoutView, setHideCheckoutView] = useState(true);
  // const [displayCheckIcon, setDisplayCheckIcon] = useState(true);

  React.useEffect(() => {
    if (!user && isUserLoggedIn) {
      fetchUserData()
        .then((user: UserObj) => {
          dispatch(loginUser(user));
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    }
  }, [user, displayCheckoutForm]);

  const handleGotoMainBoardClick = React.useCallback(() => {
    history.replace(MAINBOARD);
  }, [history]);

  return (
    <Container>
      <Helmet>
        <title>
          {capitalizeFirstLetter(t("payment_title"))} | Memento
        </title>
      </Helmet>
      <SimpleHeader />
      {!hideCheckoutView && (
        <Fade
          in={displayCheckoutForm}
          addEndListener={(node, done) => {
            node.addEventListener(
              "transitionend",
              (e) => {
                if (!displayCheckoutForm) {
                  setHideCheckoutView(true);
                }
                done();
              },
              false
            );
          }}
        >
          <FormContainer>
            <CardInputContainer>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  onSubscriptionSuccess={() => {
                    //dispatch(loginUser(user));
                    //We update the local state with the new subscription id,
                    //We don't refetch right away the user data from the server, 
                    //as the subscription might not be updated yet, since subscription status update
                    //relies on stripe webhook, that is called when the subscription is activated by stripe
                    dispatch(loginUser({...user, subscriptionPlan: SUBSCRIPTION_MEMENTEE_PLAN}));
                    //setPaymentSuccess(true)
                    // fetchUserData()
                    //   .then((user: UserObj) => {
                    //     dispatch(loginUser(user));
                    //   })
                    //   .catch((err) => {
                    //     console.log(
                    //       "Caught an error while fetch user data" + err
                    //     );
                    //   });
                    setDisplayCheckoutForm(false);
                  }}
                  onSubscriptionFailed={() => {
                    //setPaymentSuccess(false)
                    //setDisplayCheckoutForm(false);
                  }}
                />
              </Elements>
            </CardInputContainer>
            <RecapCardContainer>
              <CheckoutRecapCard
                {...TIER_MEMENTEE}
                price={
                  getPriceInLocalCurrency(localCurrency) +
                  getCurrencySymbol(localCurrency)
                }
                buttonText="Subscribe"
              />
            </RecapCardContainer>
          </FormContainer>
        </Fade>
      )}
      <Slide
        addEndListener={(node, done) => {
          node.addEventListener(
            "transitionend",
            (e) => {
              if (!displayCheckoutForm) {
                setDisplayCheckIcon(true);
              }
              done();
            },
            false
          );
        }}
        direction="down"
        in={hideCheckoutView}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "36px",
              border: "2px solid #f7f7f7",
            }}
          >
            <Grow in={displayCheckIcon}>
              <CheckCircleIcon
                sx={{ width: "128px", height: "128px", color: colorMemento }}
              />
            </Grow>
            <Typography
              style={{ marginBottom: "32px" }}
              component="h2"
              variant="h3"
              color={colorMemento}
            >
              {t("payment_successful_msg")}
            </Typography>

            <Box className={classes.paymentRecapLine}>
              <Typography
                style={{ marginTop: "16px" }}
                variant="h4"
                color="text.secondary"
              >
                {t("payment_successful_amount_paid_title")}
              </Typography>

              <Typography
                style={{ marginTop: "16px" }}
                component="h4"
                variant="h4"
                color="text.secondary"
              >
                {getPriceInLocalCurrency(localCurrency)}
                {getCurrencySymbol(localCurrency)}
              </Typography>
            </Box>
            <Box className={classes.paymentRecapLine}>
              <Typography
                style={{ marginTop: "16px" }}
                variant="h4"
                color="text.secondary"
              >
                {t("payment_successful_next_debit_title")}
              </Typography>

              <Typography
                style={{ marginTop: "16px" }}
                component="h4"
                variant="h4"
                color="text.secondary"
              >
                {getDateInOneMonth()}
              </Typography>
            </Box>
            <Typography
              style={{ marginTop: "48px", textAlign: "center" }}
              component="h4"
              variant="h4"
              color="text.primary"
            >
              {t("payment_subscription_confirmed_msg")}
            </Typography>
            <Typography
              style={{ marginTop: "8px", textAlign: "center" }}
              component="h4"
              variant="h4"
              color="text.primary"
            >
              {t("payment_enjoy_msg")}
            </Typography>
            <Button
              className={classes.goToBoardButton}
              style={{
                fontSize: "1.8rem",
                marginTop: "32px",
                width: "50%",
                height: "20%",
                backgroundColor: colorMemento,
                color: "white",
              }}
              size="large"
              variant="outlined"
              onClick={handleGotoMainBoardClick}
            >
              {t("payment_go_board_btn_msg")}
            </Button>
          </Paper>
        </div>
      </Slide>
    </Container>
  );
};

export default Payment;

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components";
import { colorMemento } from "shared/colors";
import { useTheme } from "@mui/material/styles";
import { RootState } from "store/reducers";
import { UserObj } from "models/user";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddNewItemMenu from "components/UI/Add/AddNewItemMenu";
import { setSearchInput } from "store/actions/view";
import { CollectionObj } from "models/collection";
import CurrentUserAvatarMenu from "./CurrentUserAvatarMenu";

const HeaderContainer = styled.header`
  width: 100%;
  height: 80px;
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;

  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;

  // &:hover {
  //   background-color: ${colorMemento}};
  // }
`;

const LogoContainer = styled.img`
  width: 10%;
  margin-left: 10%;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 5,
  },
  searchField: {
    "& .MuiInputBase-input": {
      fontSize: "1.6rem",
      padding: "12px 12px",
    },

    "& .MuiInputBase-root": {
      borderRadius: "8px",
      background: "#e8e8e8",
    },

    "&:hover .MuiInputBase-root": {
      background: "white",
    },
    "&:Mui-focused .MuiInputBase-root": {
      background: "white",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      // boxShadow: "0 1px 4px 0 rgba(60, 64, 67, 0.602)",
      // borderWidth: "0px",
      // "& fieldset": {
      //   borderRadius: "8px",
      //   boxShadow: "0 1px 4px 0 rgba(60, 64, 67, 0.602)",
      //   borderWidth: "0px",
      // },
      // "&:hover fieldset": {
      //   borderRadius: "8px",
      //   boxShadow: "0 1px 4px 0 rgba(60, 64, 67, 0.602)",
      //   borderWidth: "0px",
      // },
      // "&.Mui-focused fieldset": {
      //   borderRadius: "8px",
      //   boxShadow: "0 1px 4px 0 rgba(60, 64, 67, 0.602)",
      //   borderWidth: "0px",
      // },
    },
    width: "45%",
    fontSize: "5rem",
  },
}));

const Header = (/*{ userObj }: HeaderProp*/) => {
  const theme = useTheme();
  const userObj: UserObj = useSelector((state: RootState) => state.auth.user);
  const { t } = useTranslation();
  const [searchHint, setSearchHint] = React.useState(
    t("search_searchfield_hint")
  );
  const [searchHasFocus, setSearchHasFocus] = React.useState(false);
  const searchContext = useSelector((state) => state.view.searchContext);
  const hideAddNewItemFabButton = useSelector(
    (state) => state.view.hideAddNewItemFabButton
  );
  const hideMainSearchBar = useSelector(
    (state) => state.view.hideMainSearchBar
  );
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );
  const dispatch = useDispatch();
  const userInitials =
    userObj?.firstName.charAt(0) + userObj?.lastName.charAt(0);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showToolTip, setShowTooltip] = React.useState(false);

  const handleClick = (event: any) => {
    setShowTooltip(false);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const currentlyOpenedCollectionCurrentUserRight: number = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollectionCurrentUserRight
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const tootltipText: string = t("header_avatar_tooltip");

  const avatarFilter = showToolTip ? "brightness(.9)" : "";

  const handleSearchInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const textInput = e.target.value;
    dispatch(setSearchInput(textInput));
  };
  return (
    <>
      <HeaderContainer>
        {/* <LogoContainer src={LogoIcon} alt="Logo" /> */}

        <TextField
          className={classes.searchField}
          id="outlined-basic"
          placeholder={searchHint}
          label={undefined}
          variant="outlined"
          sx={{
            opacity: hideMainSearchBar ? 0 : 1,
            marginTop: "8px",
            marginRight: "10%",
            borderRadius: "30px",
          }}
          onChange={handleSearchInputChange}
          onPointerEnter={() => {
            setSearchHint(
              searchContext.length > 0
                ? t("search_searchfield_hint_with_context", {
                    search_context: searchContext,
                  })
                : t("search_searchfield_hint")
            );
          }}
          onPointerLeave={() => {
            if (!searchHasFocus) setSearchHint(t("search_searchfield_hint"));
          }}
          onBlur={() => {
            setSearchHint(t("search_searchfield_hint"));
            setSearchHasFocus(false);
          }}
          onFocus={() => {
            setSearchHasFocus(true);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ width: "24px", height: "24px" }} />
              </InputAdornment>
            ),
          }}
        ></TextField>

        {!hideAddNewItemFabButton &&
          !currentlyOpenedCollection?.isArchived &&
          currentlyOpenedCollectionCurrentUserRight > 0 && <AddNewItemMenu />}

        <CurrentUserAvatarMenu />
      </HeaderContainer>
    </>
  );
};


export default React.memo(Header);

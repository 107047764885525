import React from "react";
import { CollectionObj } from "models/collection";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { styledTooltipTitle } from "shared/styles";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { isCollectionShared } from "models/collection";
import { stringToColour } from "shared/colors";

interface CollectionBadgeProps {
  collection: CollectionObj;
  readOnly?: boolean; //whether it's clickable or not to open the collection
}

const CollectionBadge = ({
  collection,
  readOnly = false,
}: CollectionBadgeProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [
    showCollectionBadgeToolTip,
    setShowCollectionBadgeTooltip,
  ] = React.useState(false);
  var avatarColour = null;
  if (collection) {
    avatarColour = stringToColour(collection?.name);
  }
  return (
    <Box
      sx={{
        aligntItems: "center",
        display: "flex",
      }}
    >
      <Tooltip
        key="manage-access"
        open={showCollectionBadgeToolTip && !readOnly}
        arrow
        title={styledTooltipTitle(t("action_open"))}
      >
        <Typography
          variant="h5"
          onMouseEnter={() => {
            setShowCollectionBadgeTooltip(true);
          }}
          onMouseLeave={() => {
            setShowCollectionBadgeTooltip(false);
          }}
          onClick={(e) => {
            if (readOnly) {
              return;
            }
            e.preventDefault();
            if (collection?.isArchived) {
              history.push("/board/archives/collection/" + collection.id);
            } else {
              history.push("/board/collection/" + collection.id);
            }
            e.stopPropagation();
          }}
          style={{
            borderRadius: "4px",
            backgroundColor: avatarColour.backgroundColor,
            color: avatarColour.textColor,
            fontSize: "1.2rem",
            padding: "6px",
            cursor: readOnly ? undefined : "pointer",
            alignItems: "center",
            display: "flex",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          {isCollectionShared(collection) && (
            <PeopleOutlineIcon
              sx={{
                marginRight: "6px",
              }}
            />
          )}
          {collection.name}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default React.memo(CollectionBadge);

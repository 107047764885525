import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled, { keyframes, css } from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import { colorMemento } from "shared/colors";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useTranslation } from "react-i18next";
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";
import Box from "@mui/material/Box";
import { styledTooltipTitle } from "shared/styles";
import { setMultiSelectedData } from "store/actions/aggdataActions";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { AggDataObj } from "../../../models/aggdata";
import {
  archiveMultipleAggDataGql,
  deleteMultipleAggDataGql,
  updatePinMutliAggDataGql,
  unshareMultipleAggdataFromSharedCollectionGql,
} from "graphql/mutations";
import { retrieveCollectionsForIds, retrieveCollectionGql } from "graphql/queries";
import { showSnackbar } from "store/actions/view";
import { useMutation } from "@apollo/client";
import { openAttachDataToCollectionDialogForAggDataArray } from "store/actions/view";
import { RootState } from "store/reducers/index";
import {
  deleteAggDataFromCache,
  updateAggDataArrayInCache,
  addAggDataArrayInCache,
  addAggDataArrayInArchivedDataCache,
  deleteAggDataFromArchivedDataCache,
  deleteAggDataFromAllDataCache,
  deleteAggdataFromCollectionSpecificCache
} from "graphql/helpers";
import { openReminderDatePickerForAggdataArray } from "store/actions/view";

import {
  setAllItemsMaxPinScore,
  setMaxSortOrderScore,
} from "store/actions/aggdataActions";
import { CollectionObj } from "models/collection";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { DEBUG } from "configuration";
//import AggData from 'containers/AggData/AggData';
import { UserObj } from "models/user";
import { useApolloClient } from "@apollo/client";


const popupScale = keyframes`
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(0.2);
    }
    40% {
      transform: scale(0.4);
    }
    60% {
      transform: scale(0.6);
    }
    80% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1) ;
    }
`;

const MainContainer = styled.header`
  width: 100%;
  height: 80px;
  background: white;
  display: flex;
  animation: ${popupScale} 0.1s ease-in-out;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
`;

const LeftContainer = styled.header`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const RightContainer = styled.header`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

interface Icon {
  id: string;
  icon: any;
  title: string;
}

const ARCHIVE_ACTION = "ARCHIVE_ACTION";
const PIN_UNPIN_ACTION = "PIN_UNPIN_ACTION";
const DELETE_ICON_ACTION = "DELETE_ICON_ACTION";
const ADD_TO_COLLECTION_ACTION = "ADD_TO_COLLECTION_ACTION";
const ADD_REMINDER_ACTION = "ADD_REMINDER_ACTION";
const REMOVE_FROM_SHARE_COLLECTION = "remove from shared collection";

const useStyles = makeStyles((theme) => ({
  toolIcon: {
    borderRadius: "50%",
    width: "4rem",
    height: "4rem",
    backgroundSize: "50%",
  },
}));

const MultiSelectionToolbar = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [deleteMultipleAggData, deleteMultipleAggDataResult] = useMutation(
    deleteMultipleAggDataGql,
    {}
  );
  const client = useApolloClient();

  const [archiveMultipleAggData, archiveMultipleAggDataResult] = useMutation(
    archiveMultipleAggDataGql,
    {}
  );
  const [updatePinMutliAggData, updatePinMutliAggDataResult] = useMutation(
    updatePinMutliAggDataGql,
    {}
  );
  const currentUser: UserObj = useSelector(
    (state: RootState) => state.auth.user
  );
  //Allows to figure out if the multi selection toolbar is displayed from aggdata collection container UI.
  const currentlyOpenedCollection: CollectionObj = useSelector(
    (state: RootState) => state.view.currentlyOpenedCollection
  );
  const maxAllItemsPinScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxAllItemsPinScore
  );

  const maxSortOrderScore: number = useSelector(
    (state: RootState) => state.aggdataReducer.maxSortOrderScore
  );
  const dispatch = useDispatch();
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [attacheToCollectionClicked, setAttacheToCollectionClicked] =
    useState(false);
  const [archiveClicked, setArchiveClicked] = useState(false);
  const [pinClicked, setPinClicked] = useState(false);
  const [unshareFromCollectionClicked, setUnshareFromCollectionClicked] = useState(false);

  const actionClicked =
    deleteClicked || attacheToCollectionClicked || archiveClicked || pinClicked;
  const [displayDeleteConfirmationDialog, setDisplayConfirmationDialog] =
    React.useState(false);
  const multiSelectedAggData: AggDataObj[] = useSelector(
    (state: RootState) => state.aggdataReducer.multiSelectedAggData
  );
  const [unshareFromCollection, unshareFromCollectionResult] = useMutation(
    unshareMultipleAggdataFromSharedCollectionGql
  );
  const iconStyles = {
    width: "32px",
    height: "32px",
  };

  const icons: Icon[] = [
    {
      id: ADD_REMINDER_ACTION,
      icon: (
        <AlarmAddIcon
          id={ADD_REMINDER_ACTION}
          sx={iconStyles}
          // onClick={(e) => handleActionClicked(e)}
          className={classes.toolIcon}
        />
      ),
      title: t("toolbar_add_reminder_tool_title"),
    },
    {
      id: ADD_TO_COLLECTION_ACTION,
      icon: (
        <PlaylistAddIcon
          id={ADD_TO_COLLECTION_ACTION}
          sx={iconStyles}
          // onClick={(e) => handleActionClicked(e)}
          className={classes.toolIcon}
        />
      ),
      title: t("toolbar_add_to_collection_tool_title"),
    },
    {
      id: PIN_UNPIN_ACTION,
      icon:
        multiSelectedAggData &&
        multiSelectedAggData.length > 0 &&
        multiSelectedAggData[0][
          currentlyOpenedCollection ? "listItemsPinScore" : "allItemsPinScore"
        ] > 0 ? (
          <PushPinOutlinedIcon
            id={PIN_UNPIN_ACTION}
            sx={iconStyles}
            // onClick={(e) => handleActionClicked(e)}
            className={classes.toolIcon}
          />
        ) : (
          <PushPinIcon
            id={PIN_UNPIN_ACTION}
            sx={iconStyles}
            // onClick={(e) => handleActionClicked(e)}
            className={classes.toolIcon}
          />
        ),
      title:
        multiSelectedAggData &&
        multiSelectedAggData.length > 0 &&
        multiSelectedAggData[0][
          currentlyOpenedCollection ? "listItemsPinScore" : "allItemsPinScore"
        ] > 0
          ? t("action_unpin")
          : t("action_pin"),
    },
    {
      id: ARCHIVE_ACTION,
      icon:
        multiSelectedAggData &&
        multiSelectedAggData.length > 0 &&
        multiSelectedAggData[0].isArchived ? (
          <UnarchiveIcon
            id={ARCHIVE_ACTION}
            sx={iconStyles}
            // onClick={(e) => handleActionClicked(e)}
            className={classes.toolIcon}
          />
        ) : (
          <ArchiveIcon
            id={ARCHIVE_ACTION}
            sx={iconStyles}
            // onClick={(e) => handleActionClicked(e)}
            className={classes.toolIcon}
          />
        ),
      title:
        multiSelectedAggData &&
        multiSelectedAggData.length > 0 &&
        multiSelectedAggData[0].isArchived
          ? t("toolbar_unarchive_tool_title")
          : t("toolbar_archive_tool_title"),
    },
    {
      id: DELETE_ICON_ACTION,
      icon: (
        <DeleteIcon
          sx={iconStyles}
          // onClick={(e) => handleActionClicked(e)}
          id={DELETE_ICON_ACTION}
          className={classes.toolIcon}
        />
      ),
      title: t("toolbar_delete_tool_title"),
    },
    {
      id: REMOVE_FROM_SHARE_COLLECTION,
      icon: (
        <RemoveCircleIcon
          sx={iconStyles}
          // onClick={(e) => handleActionClicked(e)}
          id={REMOVE_FROM_SHARE_COLLECTION}
          className={classes.toolIcon}
        />
      ),
      title: t("remove_from_shared_collection_action"),
    },
  ];

  React.useEffect(() => {
    const handleKeyEvent = (event: any) => {
      if (event.key == "Escape") {
        handleClose();
      }
    };
    document.addEventListener("keydown", handleKeyEvent);

    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, []);

  const handleClose = React.useCallback(() => {
    dispatch(setMultiSelectedData([]));
  }, [dispatch]);

  const handleActionClicked = React.useCallback(
    (actionId: string) => {
      //e.preventDefault();
      if (actionClicked) {
        //If one of the actions is clicked, then we don't do anything upon clicking on others while one is ongoing.
        return;
      }
      switch (actionId) {
        case DELETE_ICON_ACTION:
          // setDeleteClicked(true);
          // handleDelete();
          setDisplayConfirmationDialog(true);
          break;
        case ADD_TO_COLLECTION_ACTION:
          setAttacheToCollectionClicked(true);
          dispatch(
            openAttachDataToCollectionDialogForAggDataArray(
              multiSelectedAggData
            )
          );
          break;
        case ARCHIVE_ACTION:
          setArchiveClicked(true);
          handleArchive();
          break;
        case PIN_UNPIN_ACTION:
          setPinClicked(true);
          handlePinAction();
          break;
        case REMOVE_FROM_SHARE_COLLECTION:
          setUnshareFromCollectionClicked(true);
          handleUnshareAggDataFromCollection();
          break;
        case ADD_REMINDER_ACTION:
          console.log("Add reminder");
          dispatch(openReminderDatePickerForAggdataArray(multiSelectedAggData));
          handleClose();
          //onAddReminder && onAddReminder();
          break;
        default:
          console.log("not supported!");
      }
    },
    [multiSelectedAggData, handleClose, actionClicked]
  );

  const handleUnshareAggDataFromCollection = React.useCallback(() => {
    unshareFromCollection({
      variables: {
        inputList: multiSelectedAggData.map((aggData: AggDataObj) => {
          return {
            aggdataId : aggData.id,
            collectionId: aggData?.collection?.id
          }
        }),
      },
      refetchQueries:[
        {
          query: retrieveCollectionGql,
          variables: { id: currentlyOpenedCollection.id },
        },
      ]
    }).then((data) => {
        if (DEBUG)
          console.log("Unshare from collection result " + JSON.stringify(data));
          //remove from local cache aggdata not owned by used, as it has been unshared thus not accessible anymore to the non owner
          const dataToRemoveFromLocalCache = multiSelectedAggData.map((aggData: AggDataObj) => {
                return aggData.id
          })
          deleteAggdataFromCollectionSpecificCache(
            client.cache,
            dataToRemoveFromLocalCache,
            currentlyOpenedCollection.id
          );
      })
      .catch((e) => {
        if (DEBUG)
        console.log("Unshare from collection exception " + e);
        dispatch(
          showSnackbar({
            message: t("snackbar_failed_apply_changes_backend"),
            action: "",
            onAction: null,
            loading: false,
          })
        );

      });
  }, [multiSelectedAggData, currentUser, currentlyOpenedCollection, unshareFromCollection])



  const handleArchive = React.useCallback(() => {
    const archiveData = !multiSelectedAggData[0].isArchived;

    const collectionsIds: string[] = [];
    const dataIdsArray = multiSelectedAggData.map((aggData: AggDataObj) => {
      if (aggData.collection) {
        collectionsIds.push(aggData.collection.id);
      }
      return aggData.id;
    });

    archiveMultipleAggData({
      variables: {
        input: dataIdsArray,
        archive: archiveData,
        sortOrderScore: maxSortOrderScore + 1,
      },
      refetchQueries:
        collectionsIds.length > 0
          ? [
              {
                query: retrieveCollectionsForIds,
                variables: { input: collectionsIds },
              },
            ]
          : undefined,
      update(cache: any, { data: { archiveMultipleAggdata } }) {
        console.log(
          "archive multiple result " + JSON.stringify(archiveMultipleAggdata)
        );
        if (archiveMultipleAggdata) {
          const dataToPutInArchivedCache: AggDataObj[] = [];
          if (archiveData) {
            multiSelectedAggData.map((aggData: AggDataObj) => {
              deleteAggDataFromAllDataCache(cache, aggData);
              dataToPutInArchivedCache.push({
                ...aggData,
                isArchived: archiveData,
                collection: undefined,
                sortOrderScore: maxSortOrderScore + 1,
                isResolved: false,
              });
            });
            addAggDataArrayInArchivedDataCache(cache, dataToPutInArchivedCache);
          } else {
            multiSelectedAggData.map((aggData: AggDataObj) => {
              deleteAggDataFromArchivedDataCache(cache, aggData);
              dataToPutInArchivedCache.push({
                ...aggData,
                isArchived: archiveData,
                collection: undefined,
                sortOrderScore: maxSortOrderScore + 1,
                isResolved: false,
              });
            });
            addAggDataArrayInCache(cache, dataToPutInArchivedCache);
          }
        }

        dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));

        handleClose();
      },
    })
      .then((data) => {
        dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
        var snackbarMessage;
        if (archiveData) {
          snackbarMessage =
            multiSelectedAggData.length > 1
              ? t("multiple_aggdata_archived_msg_plural", {
                  items_count: multiSelectedAggData.length,
                })
              : t("item_archived_confirmation_msg");
        } else {
          snackbarMessage =
            multiSelectedAggData.length > 1
              ? t("multiple_aggdata_unarchived_msg_plural", {
                  items_count: multiSelectedAggData.length,
                })
              : t("item_unarchived_confirmation_msg");
        }
        dispatch(
          showSnackbar({
            message: snackbarMessage,
            action: "",
            onAction: null,
            loading: false,
          })
        );
        handleClose();
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: archiveData
              ? t("item_archived_failed_msg")
              : t("item_unarchived_failed_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        handleClose();
      });
  }, [multiSelectedAggData]);

  const handlePinAction = React.useCallback(() => {
    const pinScoreName = currentlyOpenedCollection
      ? "listItemsPinScore"
      : "allItemsPinScore";
    const pinScoreValue =
      multiSelectedAggData[0][pinScoreName] > 0 ? 0 : maxAllItemsPinScore + 1;

    const dataIdsArray = multiSelectedAggData.map((aggData: AggDataObj) => {
      return aggData.id;
    });

    updatePinMutliAggData({
      variables: {
        input: dataIdsArray,
        [pinScoreName]: pinScoreValue,
        sortOrderScore: maxSortOrderScore + 1,
      },
      update(cache: any, { data: { updatePinMultiAggdata } }) {
        console.log(
          "updatePinMultiAggdata " + JSON.stringify(updatePinMultiAggdata)
        );
        if (updatePinMultiAggdata) {
          updateAggDataArrayInCache(cache, updatePinMultiAggdata.aggdatas);
        }

        handleClose();
      },
    })
      .then((data) => {
        //todo dispatch new scores
        dispatch(setMaxSortOrderScore(maxSortOrderScore + 1));
        dispatch(setAllItemsMaxPinScore(maxAllItemsPinScore + 1));
      })
      .catch((e) => {
        //show snackbar
        dispatch(
          showSnackbar({
            message: t("snackbar_failed_apply_changes_backend"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        handleClose();
      });
  }, [
    currentlyOpenedCollection,
    multiSelectedAggData,
    currentlyOpenedCollection,
  ]);

  const handleDeleteSelectedItems = React.useCallback(() => {
    setDeleteClicked(true);
    setDisplayConfirmationDialog(false);

    const collectionsIds: string[] = [];
    const dataIdsArray = multiSelectedAggData.map((aggData: AggDataObj) => {
      if (aggData.collection) {
        collectionsIds.push(aggData.collection.id);
      }
      return aggData.id;
    });

    //deleteAggDataFromCache(deleteAggDataResult.client.cache, editedAggData);
    deleteMultipleAggData({
      variables: { input: dataIdsArray },
      refetchQueries:
        collectionsIds.length > 0
          ? [
              {
                query: retrieveCollectionsForIds,
                variables: { input: collectionsIds },
              },
            ]
          : undefined,
      update(cache: any, { data }: any) {
        console.log("deletion result " + JSON.stringify(data));
        if (data.deleteMultipleAggdata.result) {
          multiSelectedAggData.map((aggData: AggDataObj) => {
            deleteAggDataFromCache(cache, aggData);
          });
        }

        handleClose();
      },
    }).catch((e: any) => {
      //addAggDataInCache(deleteAggDataResult.client.cache, editedAggData);
      dispatch(
        showSnackbar({
          message: t("snackbar_failed_apply_changes_backend"),
          action: "",
          onAction: null,
          loading: false,
        })
      );
    });
  }, [handleClose, multiSelectedAggData]);

  return (
    <MainContainer>
      <LeftContainer>
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ width: "36px", height: "36px" }} />
        </IconButton>
        <Typography variant="h4">
          {multiSelectedAggData.length +
            (multiSelectedAggData.length > 1
              ? t("multi_selection_toolbar_title_plural")
              : t("multi_selection_toolbar_title_singular"))}
        </Typography>
      </LeftContainer>
      <RightContainer>
        {icons.map((icon) => {
          if (
            multiSelectedAggData &&
            multiSelectedAggData.length > 0 &&
            multiSelectedAggData[0].isArchived &&
            (icon.id == ADD_TO_COLLECTION_ACTION ||
              icon.id == PIN_UNPIN_ACTION ||
              icon.id == ADD_REMINDER_ACTION)
          ) {
            //when data is archived it can't be pinned/unpinned or added to a collection
            return null;
          }

          if (
            multiSelectedAggData &&
            multiSelectedAggData.length > 0 &&
            multiSelectedAggData[0].sharedUsers &&
            multiSelectedAggData[0].sharedUsers.length > 0 &&
            multiSelectedAggData[0].collection  &&
            (icon.id == ADD_TO_COLLECTION_ACTION ||
              icon.id == DELETE_ICON_ACTION ||
              icon.id == ARCHIVE_ACTION ||
              icon.id == ADD_REMINDER_ACTION)
          ) {
            //when data is shared, we only display limited number of actions
            return null;
          }

          if (!(multiSelectedAggData &&
            multiSelectedAggData.length > 0 &&
            multiSelectedAggData[0].sharedUsers &&
            multiSelectedAggData[0].sharedUsers.length > 0 &&
            multiSelectedAggData[0].collection) && icon.id== REMOVE_FROM_SHARE_COLLECTION){
              return null;
          }          

          if (
            (deleteClicked && icon.id == DELETE_ICON_ACTION) ||
            //For now this is disabled. If selecting a collection is cancelled, the spinner still rotates..
            // (attacheToCollectionClicked &&
            //   icon.id == ADD_TO_COLLECTION_ACTION) ||
            (archiveClicked && icon.id == ARCHIVE_ACTION) ||
            (unshareFromCollectionClicked && icon.id == REMOVE_FROM_SHARE_COLLECTION) ||
            (pinClicked && icon.id == PIN_UNPIN_ACTION)
          ) {
            return (
              <CircularProgress
                key={icon.id}
                variant="indeterminate"
                disableShrink
                size="2rem"
                sx={{
                  marginRight: "10px",
                  marginLeft: "10px",
                  color: "gray",
                }}
              ></CircularProgress>
            );
          }
          return (
            <IconButton
              sx={{ width: "64px", height: "64px" }}
              onClick={() => handleActionClicked(icon.id)}
            >
              <Tooltip key={icon.id} title={styledTooltipTitle(icon.title)}>
                {icon.icon}
              </Tooltip>
            </IconButton>
          );
        })}
      </RightContainer>
      {/* </MainContainer> */}
      <SimpleConfirmationDialog
        open={displayDeleteConfirmationDialog}
        title={t("delete_multiple_items_confirmation_title")}
        description={t(
          multiSelectedAggData.length > 1
            ? "delete_multiple_items_confirmation_plural"
            : "delete_multiple_items_confirmation_singular",
          { items_count: multiSelectedAggData.length }
        )}
        handleOk={handleDeleteSelectedItems}
        handleModalClose={() => {
          setDisplayConfirmationDialog(false);
        }}
      ></SimpleConfirmationDialog>
    </MainContainer>
  );
});

export default React.memo(MultiSelectionToolbar);

import { CollectionObj } from "models/collection"
import { AggDataObj, NoteImage, Reminder } from "models/aggdata"

// Action types
export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_FLASH_MESSAGE = 'HIDE_FLASH_MESSAGE';
export const SET_SEARCH_INPUT = 'SET_SEARCH_INPUT';
export const SET_SEARCH_CONTEXT = 'SET_SEARCH_CONTEXT';
export const SET_CURRENT_COLLECTION = 'SET_CURRENT_COLLECTION';
export const CREATE_NEW_NOTE = 'CREATE_NEW_NOTE';
export const DISPLAY_ADD_NEW_LINK_INPUT = 'DISPLAY_ADD_NEW_LINK_INPUT';
export const HIDE_ADD_NEW_LINK_INPUT = 'HIDE_ADD_NEW_LINK_INPUT';
export const UPDATE_COLLECTION_CONTEXT = 'UPDATE_COLLECTION_CONTEXT';
export const DISPLAY_IMAGE_VIEWER = 'DISPLAY_IMAGE_VIEWER';
export const CLOSE_IMAGE_VIEWER = 'CLOSE_IMAGE_VIEWER';
export const OPEN_ATTACH_DATA_TO_COLLECTION_DIALOG = 'OPEN_ATTACH_DATA_TO_COLLECTION';
export const CLOSE_ATTACH_DATA_TO_COLLECTION_DIALOG = 'CLOSE_ATTACH_DATA_TO_COLLECTION_DIALOG';
export const OPEN_REMINDER_DATE_PICKER = 'OPEN_REMINDER_DATE_PICKER';
export const CLOSE_REMINDER_DATE_PICKER = 'CLOSE_REMINDER_DATE_PICKER';
export const SET_PATH_AFTER_LOGIN = 'SET_PATH_AFTER_LOGIN';
export const DISPLAY_TIME_PICKER_ON_ADDING_NEW_ITEMS = 'DISPLAY_TIME_PICKER_ON_ADDING_NEW_ITEMS';
export const ADD_FILES_FROM_DRAG_AND_DROP = 'ADD_FILES_FROM_DRAG_AND_DROP';
export const RESET_STORE = 'RESET_STORE';
export const DISPLAY_MAX_DATA_USAGE_DIALOG = 'DISPLAY_MAX_DATA_USAGE_DIALOG';
export const HIDE_ADD_NEW_ITEM_FAB_BUTTON = 'HIDE_ADD_NEW_ITEM_FAB_BUTTON';
export const SHOW_CONFIDENTIALITY_FILTER = 'SHOW_CONFIDENTIALITY_FILTER';
export const SET_CURRENT_USER_OPENED_COLLECTION_RIGHT = 'SET_CURRENT_USER_OPENED_COLLECTION_RIGHT';
export const HIDE_MAIN_SEARCH_BAR = 'HIDE_MAIN_SEARCH_BAR';
export const DISPLAY_COMMENTS_VIEW = 'DISPLAY_COMMENTS_VIEW';
export const HIDE_COMMENTS_VIEW = 'HIDE_COMMENTS_VIEW';
export const DISPLAY_NOTE_IMAGE_VIEWER = 'DISPLAY_NOTE_IMAGE_VIEWER';
export const CLOSE_NOTE_IMAGE_VIEWER = 'CLOSE_NOTE_IMAGE_VIEWER';
export const AGGDATA_CLICKED = 'AGGDATA_CLICKED';
export const SAVE_PUBLIC_AGGDATA_CLICKED = 'SAVE_PUBLIC_AGGDATA_CLICKED';
export const DISPLAY_LOGIN_MODAL = 'DISPLAY_LOGIN_MODAL';
export const PUBLIC_COLLECTION_OPENED = 'PUBLIC_COLLECTION_OPENED';

// Types
interface Message {
  showMessage: boolean;
  message: string;
}


interface DisplayReminderDatePicker {
  displayPicker: boolean;
  aggDataArray: AggDataObj[] | undefined;
  reminderToEdit?: Reminder | undefined;
}

interface DisplayAttachDataToCollection {
  showAttachToCollectionDialog: boolean;
  aggdataToAttach: AggDataObj[] | undefined;
}

interface AggdataClickedPayload {
  aggData: AggDataObj | null;
}

interface SavePublicAggdataClickedPayload {
  aggDataId: string;
}

interface DisplayLoginModalPayload {
  message: string;
}

interface SnackbarState {
  message: string,
  action?: string,
  onAction?: (() => void) | null,
  loading?: boolean | null,
}

interface DisplayImageViewerRequest {
  aggDataArray: AggDataObj[];//the full list of data to display
  currentAggData: AggDataObj;// the start data
  title: string;
}

interface DisplayNoteImagesViewerRequest {
  noteImagesArray: NoteImage[];//the full list of data to display
  currentNoteImage: NoteImage;// the start data
  aggData: AggDataObj;// the start data
  title: string;
}

interface DisplayTimepickerOnAddingNewItemsType {
  displayTimePicker: boolean;
  selectedDayDate: Date;
}

interface DisplayCommentsViewState {
  aggData: AggDataObj;
}

export interface ViewState {
  flashMessage: Message;
  currentlyOpenedCollection: CollectionObj | null; // will be used to give context to adding new Items. When a collection is opened, all newly added items will go directly to that collection.
  searchInput: string;
  pathAfterLogin: string | null;
  searchContext: string; //Will be displayed in the searchfield as a hint, to give information about search context. For ex: "Search in Pending Notes"
  createNewNote: boolean;
  isCollectionUIDisplayed: boolean;
  addLink: boolean;
  snackBarState: SnackbarState;
  displayImageViewer: DisplayImageViewerRequest | null;
  displayNoteImageViewer: DisplayNoteImagesViewerRequest | null;
  displayAttachDataToCollectionDialog: DisplayAttachDataToCollection;
  displayReminderDatePicker: DisplayReminderDatePicker;
  displayTimepickerOnAddingNewItems: DisplayTimepickerOnAddingNewItemsType;
  addFilesFromDragAndDrop: FileList | null; //When files are being drag and dropped, the file list will be sent to the AddNewItemMenu component that will add the filelist
  displayMaxDataUsageReachedDialog: boolean;
  hideAddNewItemFabButton: boolean;
  showConfidentialityFilter: boolean;
  currentlyOpenedCollectionCurrentUserRight: number;//Used to signal the current user right for the currently opened collection. This will be used to hide some edit options.
  hideMainSearchBar: boolean;
  displayCommentsViewState: DisplayCommentsViewState | null;
  aggdataClicked: AggdataClickedPayload | null;
  savePublicAggdataClicked: SavePublicAggdataClickedPayload | null;
  displayLoginModal: DisplayLoginModalPayload | null;
  publicCollectionOpened: boolean;
}

export interface AggdataClickedInterface {
  type: typeof AGGDATA_CLICKED;
  payload: AggdataClickedPayload;
}

export interface SavePublicAggdataClickedInterface {
  type: typeof SAVE_PUBLIC_AGGDATA_CLICKED;
  payload: SavePublicAggdataClickedPayload;
}

export interface DisplayLoginModalInterface {
  type: typeof DISPLAY_LOGIN_MODAL;
  payload: DisplayLoginModalPayload;
}

export interface ShowSnackbar {
  type: typeof SHOW_SNACKBAR;
  payload: SnackbarState;
}

export interface SetCurrentUserRightForOpenedCollection {
  type: typeof SET_CURRENT_USER_OPENED_COLLECTION_RIGHT;
  payload: number;
}

export interface ShowFlashMessage {
  type: typeof SHOW_FLASH_MESSAGE;
  payload: string;
}

export interface UpdateCollectionContext {
  type: typeof UPDATE_COLLECTION_CONTEXT;
  payload: boolean;
}

export interface HideFlashMessage {
  type: typeof HIDE_FLASH_MESSAGE;
}

export interface CreateNewNote {
  type: typeof CREATE_NEW_NOTE;
}

export interface displayAddNewLinkInputInterface {
  type: typeof DISPLAY_ADD_NEW_LINK_INPUT;
}

export interface DisplayMaxDataUsageReachedDialog {
  type: typeof DISPLAY_MAX_DATA_USAGE_DIALOG;
  payload: boolean;
}

export interface ArchivesContainerIsDisplayed {
  type: typeof HIDE_ADD_NEW_ITEM_FAB_BUTTON;
  payload: boolean;
}

export interface HideMainSearchBar {
  type: typeof HIDE_MAIN_SEARCH_BAR;
  payload: boolean;
}

export interface PublicCollectionOpened {
  type: typeof PUBLIC_COLLECTION_OPENED;
  payload: boolean;
}

export interface DisplayCommentsView {
  type: typeof DISPLAY_COMMENTS_VIEW;
  payload: DisplayCommentsViewState | null;
}

export interface HideCommentsView {
  type: typeof HIDE_COMMENTS_VIEW;
  payload: null;
}

export interface ShowConfidentialityFilter {
  type: typeof SHOW_CONFIDENTIALITY_FILTER;
  payload: boolean;
}

export interface hideAddNewLinkInputInterface {
  type: typeof HIDE_ADD_NEW_LINK_INPUT;
}

export interface SetSearchInput {
  type: typeof SET_SEARCH_INPUT;
  payload: string;
}

export interface SetSearchContext {
  type: typeof SET_SEARCH_CONTEXT;
  payload: string;
}

export interface SetPathAfterLogin {
  type: typeof SET_PATH_AFTER_LOGIN;
  payload: string;
}

export interface SetDisplayTimepickerOnAddingNewItems {
  type: typeof DISPLAY_TIME_PICKER_ON_ADDING_NEW_ITEMS;
  payload: DisplayTimepickerOnAddingNewItemsType;
}

export interface SetCurrentCollection {
  type: typeof SET_CURRENT_COLLECTION;
  payload: CollectionObj | null;
}

export interface DisplayImageViewer {
  type: typeof DISPLAY_IMAGE_VIEWER;
  payload: DisplayImageViewerRequest;
}

export interface DisplayNoteImagesViewer {
  type: typeof DISPLAY_NOTE_IMAGE_VIEWER;
  payload: DisplayNoteImagesViewerRequest;
}

export interface AddFilesFromDragNDrop {
  type: typeof ADD_FILES_FROM_DRAG_AND_DROP;
  payload: FileList;
}

export interface CloseImageViewer {
  type: typeof CLOSE_IMAGE_VIEWER;
}

export interface CloseNoteImageViewer {
  type: typeof CLOSE_NOTE_IMAGE_VIEWER;
}

export interface OpenAttachDataToCollectionDialog {
  type: typeof OPEN_ATTACH_DATA_TO_COLLECTION_DIALOG;
}

export interface CloseAttachDataToCollectionDialog {
  type: typeof OPEN_ATTACH_DATA_TO_COLLECTION_DIALOG;
}

export interface ResetStoreType {
  type: typeof RESET_STORE;
}

export type ViewTypes = ShowFlashMessage | ResetStoreType | HideFlashMessage | CreateNewNote | UpdateCollectionContext | DisplayImageViewer | CloseImageViewer
  | displayAddNewLinkInputInterface | hideAddNewLinkInputInterface | ShowSnackbar | SetSearchInput | SetSearchContext | SetCurrentCollection 
  | OpenAttachDataToCollectionDialog | CloseAttachDataToCollectionDialog | DisplayReminderDatePicker | SetPathAfterLogin | SetDisplayTimepickerOnAddingNewItems | AddFilesFromDragNDrop | ArchivesContainerIsDisplayed | DisplayMaxDataUsageReachedDialog 
  | SetCurrentUserRightForOpenedCollection | HideMainSearchBar | DisplayCommentsView | HideCommentsView | DisplayNoteImagesViewer | CloseNoteImageViewer | AggdataClickedInterface | SavePublicAggdataClickedInterface| DisplayLoginModalInterface| PublicCollectionOpened;

export const resetStore = (): ResetStoreType => {
  return {
    type: RESET_STORE,
  };
};


export const showFlashMessage = (text: string): ShowFlashMessage => {
  return {
    type: SHOW_FLASH_MESSAGE,
    payload: text,
  };
};

export const showSnackbar = ({ message, action, onAction, loading = false }: SnackbarState): ShowSnackbar => {
  return {
    type: SHOW_SNACKBAR,
    payload: { message, action, onAction, loading }
  }
}

export const updateCollectionContext = (isCollectionUIDisplayed: boolean): UpdateCollectionContext => {
  return {
    type: UPDATE_COLLECTION_CONTEXT,
    payload: isCollectionUIDisplayed
  }
}

export const addFiles = (filesList: FileList): AddFilesFromDragNDrop => {
  return {
    type: ADD_FILES_FROM_DRAG_AND_DROP,
    payload: filesList
  }
}

export const createNewNote = (): CreateNewNote => {
  return {
    type: CREATE_NEW_NOTE,
  };
};

export const displayAddNewLinkInputField = (): displayAddNewLinkInputInterface => {
  return {
    type: DISPLAY_ADD_NEW_LINK_INPUT,
  };
};


export const displayMaxDataUsageReachedDialog = (show :boolean): DisplayMaxDataUsageReachedDialog => {
  return {
    type: DISPLAY_MAX_DATA_USAGE_DIALOG,
    payload: show,
  };
};


export const setHideAddNewItemFabButton = (isDisplayed :boolean): ArchivesContainerIsDisplayed => {
  return {
    type: HIDE_ADD_NEW_ITEM_FAB_BUTTON,
    payload: isDisplayed,
  };
};

export const setHideMainSearchBar = (hide :boolean): HideMainSearchBar => {
  return {
    type: HIDE_MAIN_SEARCH_BAR,
    payload: hide,
  };
};

export const displayCommentsViewAction = (aggData : AggDataObj): DisplayCommentsView => {
  return {
    type: DISPLAY_COMMENTS_VIEW,
    payload: { aggData : aggData},
  };
};

export const hideCommentsViewAction = (): HideCommentsView => {
  return {
    type: HIDE_COMMENTS_VIEW,
    payload: null,
  };
};


export const showConfidentialityFilter = (show :boolean): ShowConfidentialityFilter => {
  return {
    type: SHOW_CONFIDENTIALITY_FILTER,
    payload: show,
  };
};

export const hideAddNewLinkInputField = (): hideAddNewLinkInputInterface => {
  return {
    type: HIDE_ADD_NEW_LINK_INPUT,
  };
};

export const hideFlashMessage = (): HideFlashMessage => {
  return {
    type: HIDE_FLASH_MESSAGE,
  };
};

export const setSearchInput = (query: string) => {
  return {
    type: SET_SEARCH_INPUT,
    payload: query,
  };
};

export const setSearchContext = (query: string) => {
  return {
    type: SET_SEARCH_CONTEXT,
    payload: query,
  };
};

export const setCurrentCollection = (collection: CollectionObj | null): SetCurrentCollection => {
  return {
    type: SET_CURRENT_COLLECTION,
    payload: collection,
  };
};


export const setPathAfterLogin = (pathAfterLogin: string | null): SetPathAfterLogin => {
  return {
    type: SET_PATH_AFTER_LOGIN,
    payload: pathAfterLogin,
  };
};

/**
 * @param aggDataArray : The arraay of data that the image viewer will browse through
 * @param currentAggData : The first array displayed by the image viewer when opened.

 */
export const displayImageViewer = (aggDataArray: AggDataObj[], currentAggData: AggDataObj, title: string): DisplayImageViewer => {
  return {
    type: DISPLAY_IMAGE_VIEWER,
    payload: { aggDataArray: aggDataArray, currentAggData: currentAggData, title: title },
  };
};

export const displayNoteImagesViewer = (noteImagesArray: NoteImage[], currentNoteImage: NoteImage, aggData: AggDataObj, title: string): DisplayNoteImagesViewer => {
  return {
    type: DISPLAY_NOTE_IMAGE_VIEWER,
    payload: { noteImagesArray: noteImagesArray, currentNoteImage: currentNoteImage, aggData: aggData, title: title },
  };
};

export const displayReminderOnAddNewItems = (displayTimepicker: boolean, selectedDayDate: Date): SetDisplayTimepickerOnAddingNewItems => {
  return {
    type: DISPLAY_TIME_PICKER_ON_ADDING_NEW_ITEMS,
    payload: { displayTimePicker: displayTimepicker, selectedDayDate: selectedDayDate, },
  };
};


export const closeImageViewer = () => {
  return {
    type: CLOSE_IMAGE_VIEWER,
  };
};

export const closeNoteImageViewer = () => {
  return {
    type: CLOSE_NOTE_IMAGE_VIEWER,
  };
};


export const openAttachDataToCollectionDialog = (aggDataObj: AggDataObj) => {
  return {
    type: OPEN_ATTACH_DATA_TO_COLLECTION_DIALOG,
    payload: { showAttachToCollectionDialog: true, aggdataToAttach: [aggDataObj] }

  };
};

export const openAttachDataToCollectionDialogForAggDataArray = (aggDataObjArray: AggDataObj[]) => {
  return {
    type: OPEN_ATTACH_DATA_TO_COLLECTION_DIALOG,
    payload: { showAttachToCollectionDialog: true, aggdataToAttach: aggDataObjArray }

  };
};

export const closedAttachDataToCollectionDialog = () => {
  return {
    type: CLOSE_ATTACH_DATA_TO_COLLECTION_DIALOG,
    payload: { showAttachToCollectionDialog: false, aggdataToAttach: undefined }
  };
};


export const openReminderDatePicker = (aggDataObj: AggDataObj, reminderToEditObj?: Reminder | undefined) => {
  return {
    type: OPEN_REMINDER_DATE_PICKER,
    payload: { displayPicker: true, aggDataArray: [aggDataObj], reminderToEditObj: reminderToEditObj }

  };
};

export const openReminderDatePickerForAggdataArray = (aggDataObjArray: AggDataObj[], reminderToEditObj?: Reminder | undefined) => {
  return {
    type: OPEN_REMINDER_DATE_PICKER,
    payload: { displayPicker: true, aggDataArray: aggDataObjArray, reminderToEditObj: reminderToEditObj }

  };
};


export const closeReminderDatePicker = () => {
  return {
    type: CLOSE_REMINDER_DATE_PICKER,
    payload: { displayPicker: false }

  };
};



export const setCurrentUserOpenedCollectionRight = (currentUserOpenedCollectionRight : number) => {
  return {
    type: SET_CURRENT_USER_OPENED_COLLECTION_RIGHT,
    payload: currentUserOpenedCollectionRight
  };
};


export const setAggdataClicked = (aggdataObjPayload : AggDataObj | null) => {
  return {
    type: AGGDATA_CLICKED,
    payload: { aggData : aggdataObjPayload}
  };
};

export const setSavePublicAggdataClicked = (dataId : string) => {
  return {
    type: SAVE_PUBLIC_AGGDATA_CLICKED,
    payload: { aggDataId : dataId}
  };
};


export const setDisplayLoginModal= (messageValue : string) => {
  return {
    type: DISPLAY_LOGIN_MODAL,
    payload: { message : messageValue}
  };
};

export const hideLoginModal= () => {
  return {
    type: DISPLAY_LOGIN_MODAL,
    payload: null
  };
};


export const setPublicCollectionOpened= (opened: boolean) => {
  return {
    type: PUBLIC_COLLECTION_OPENED,
    payload:opened
  };
};


import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import CollaborateIllustrationContainer from "illustrations/undraw_collaborators.svg";
import ShareItemsScreenshots from "illustrations/share_items_memento.png";
import { colorMemento } from "shared/colors";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useHistory } from "react-router-dom";
import { PRIVACY_POLICY } from "navigation/Constants";
import { TITLE_TEXT_SIZE, SUBTITLE_TEXT_SIZE } from "pages/Landing/Content";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

const MainContainer = styled.section`
  display: flex;
  flex-direction: row;
  padding-right: 8%;
  padding-left: 8%;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const IllustraitonContainer = styled.img`
  width: 50%;
  padding-left: 3%;
  @media (max-width: 780px) {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 10%;
  }
`;

const TextContainer = styled.div`
  position:: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  @media (max-width: 780px) {
    width: 100%;
    margin-top: 10%;
  }
`;

const PUBLIC_COLLECTIONS_LIST = [
  {
    name: "Cooking 🍳🍴",
    url:
      "https://www.mementocollect.com/pcollection/Cooking-fLSxq26eDaXTgDB8GHbHJlEb",
  },
  {
    name: "Life Changing Books 📚",
    url:
      "https://www.mementocollect.com/pcollection/Life-Changing-Books-MJpNgQ0A1IwEZyVESb3PkzGA",
  },
  {
    name: "Wellness and Health 🧘‍♀️🥗",
    url:
      "https://www.mementocollect.com/pcollection/Wellness-and-Health-xuyXCHO4fYFOZ4odCOIOJaVe", //https://www.mementocollect.com/pcollection/LKHRImU8SUpSA1dKsWfnB3UcVwRdL9OGHYm4
  },
  {
    name: "Career and Personal Development 📚",
    url:
      "https://www.mementocollect.com/pcollection/Career-and-Personal-Development-MKxroAhwZPYQqbFyqzQnzsq0", //https://www.mementocollect.com/pcollection/ESpGBNY6Xds535s7xs8Ir9IdZyi5L9ZoFV2P
  },
  {
    name: "Travel and adventures 🌍",
    url:
      "https://www.mementocollect.com/pcollection/Travel-and-adventures-KbDQLPWc9wAJfZMsus5wOTa7", //https://www.mementocollect.com/pcollection/sJaBA7yqaBg0UfurSdrs9CH8hsfXMnKQLkdG
  },
];

const CollaborateContainer = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleClick = React.useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);
  return (
    <MainContainer>
      <TextContainer>
        <Typography variant="h4" sx={{ fontSize: TITLE_TEXT_SIZE }}>
          {t("landing_page_collaborate_title")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: SUBTITLE_TEXT_SIZE,
            fontFamily: "Trebuchet MS, sans-serif",
            fontWeight: 300,
            marginTop: "8px",
          }}
        >
          {t("landing_page_collaborate_description")}
          <span
            style={{
              color: colorMemento,
              background: "black",
            }}
          >
            {t("landing_page_collaborate_description_1")}
          </span>
        </Typography>

        <Typography
          variant="h5"
          sx={{
            fontSize: "1.2rem",
            fontFamily: "Trebuchet MS, sans-serif",
            fontWeight: 300,
            marginTop: "16px",
          }}
        >
          {t("explore_public_collections_title")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            overflow: "none",
          }}
        >
          {PUBLIC_COLLECTIONS_LIST.map((publicCollection) => {
            return (
              <Chip
                key={publicCollection.name}
                label={publicCollection.name}
                sx={{
                  marginLeft: "8px",
                  marginTop: "4px",
                  fontSize: "1.2rem",
                }}
                variant="outlined"
                onClick={() => {
                  handleClick(publicCollection.url);
                }}
              />
            );
          })}
        </Box>
      </TextContainer>

      <IllustraitonContainer
        src={ShareItemsScreenshots}
        alt="Collaborate with colleagues, friends, and family. Discuss over comments"
      />
    </MainContainer>
  );
};

export default CollaborateContainer;

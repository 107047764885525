import * as React from "react";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { PINTEREST_INTEGRATION_SETTINGS_WITH_SUBPATH } from "navigation/Constants";

//On mobile the redirection is done on a tab and not a popup window.
//We need to ge the auth code and redirect to the notion integration settings view
const PinterestAuthRedirect = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");
  const history = useHistory();

  React.useEffect(() => {
    if (code) {
      history.replace(
        PINTEREST_INTEGRATION_SETTINGS_WITH_SUBPATH + "?code=" + code
      );
    }
  }, [code]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size="3rem"></CircularProgress>
    </div>
  );
};
export default PinterestAuthRedirect;

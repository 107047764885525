import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { colorMemento } from "shared/colors";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

export enum CollectionsFilterType {
  COLLECTION_FILTER_TYPE_ALL = "all",
  COLLECTION_FILTER_TYPE_SHARED = "shared",
  COLLECTION_FILTER_TYPE_PERSONAL = "personal",
}

const useStyles = makeStyles((theme: Theme) => ({
  itemsTypeStack: {
    position: "sticky",
    top: "10px",
    marginRight: "24px",
    "&.MuiToggleButtonGroup-root": {
      position: "sticky",
      top: "10px",
    },
  },
  tab: {
    "& .MuiButtonBase-root": {
      fontSize: "1.4rem",
    },
  },
  toggleTooltip: {
    fontSize: "1.6rem",
  },
}));

export const enum CollectionsFilterOptions {
  PERSONAL = "personal",
  SHARED = "shared",
}

export interface CollectionsFilterProps {
  defaultValue: CollectionsFilterOptions;
  personalCount: number;
  sharedCount: number;
  handleOptionSelected: (option: CollectionsFilterOptions) => void;
}

const CollectionsFilter = ({
  defaultValue,
  personalCount,
  sharedCount,
  handleOptionSelected,
}: CollectionsFilterProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = React.useState(
    defaultValue
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          onClick={() => {
            setSelectedOption(CollectionsFilterOptions.PERSONAL);
            handleOptionSelected(CollectionsFilterOptions.PERSONAL);
          }}
          sx={{
            borderRadius: "8px",
            fontSize: "1.6rem",
            padding: "16px",
            marginLeft: "24px",
            cursor: "pointer",
            alignItems: "center",
            display: "flex",
            color:
              selectedOption == CollectionsFilterOptions.PERSONAL
                ? "#323332"
                : "gray",
            backgroundColor:
              selectedOption == CollectionsFilterOptions.PERSONAL
                ? "rgba(253, 199, 73, 0.05)"
                : "transparent",
          }}
        >
          <PersonOutlineIcon
            fontSize="large"
            sx={{
              marginRight: "6px",
            }}
          />
          {t('personal_collections_title') +" (" + personalCount + ")"}
        </Typography>
        <Typography
          variant="h4"
          onClick={() => {
            setSelectedOption(CollectionsFilterOptions.SHARED);
            handleOptionSelected(CollectionsFilterOptions.SHARED)
          }}
          sx={{
            borderRadius: "8px",
            fontSize: "1.6rem",
            padding: "16px",
            marginLeft: "24px",
            cursor: "pointer",
            alignItems: "center",
            display: "flex",
            color:
              selectedOption == CollectionsFilterOptions.SHARED
                ? "#323332"
                : "gray",
            backgroundColor:
              selectedOption == CollectionsFilterOptions.SHARED
                ? "rgba(253, 199, 73, 0.05)"
                : "transparent",
          }}
        >
          <PeopleOutlineIcon
            fontSize="large"
            sx={{
              marginRight: "6px",
            }}
          />{" "}
          {t('shared_collections_title') +" (" + sharedCount + ")"}
        </Typography>
      </div>
    </>
  );
};

export default React.memo(CollectionsFilter);


/**
 * for a given object, filters the provided field key.
 * User mainly to filter '__typename' from objects from apollo cache, as the server request fail if they get an unexpected field
 * in addition to the fields expected.
 * @param {} obj 
 * @param {*} key 
 * @returns 
 */
export function filterObject(obj, key) {
    var result = {}
    for (var i in obj) {
        if (typeof obj[i] == 'object'){
          if (obj[i] == null){
            result[i] = null
          } else {
            result[i] = filterObject(obj[i], key)
          }
          continue
        }
        if (i == key){
          continue;
        }
        result[i] = obj[i]
    }
    return result;
}
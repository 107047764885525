import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import CircularProgress from '@mui/material/CircularProgress';

import { Container } from "./styles";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};


const Button: React.FC<ButtonProps> = ({
  children,
  loading = true,
  ...rest
}) => (
  <Container type="button" {...rest}>
    {loading ?   <CircularProgress color='inherit' size="24px" /> : children}
  </Container>
);

export default Button;

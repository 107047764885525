import styled, { keyframes } from "styled-components";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideCommentsViewAction } from "store/actions/view";
import { AggDataObj, CommentObj } from "models/aggdata";
import AggdataCommentView from "containers/Comments/AggdataCommentView";
import UserAvatarWithTooltip from "components/UI/User/UserAvatarWithTooltip";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { formatIso8601, getTimeElapsedsince } from "shared/dataUtils";
import { styledTooltipTitle } from "shared/styles";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isAggdataShared } from "models/aggdata";
import { stringToColour } from "shared/colors";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import {
  retrieveAggdataForIdFromCacheGql,
  retrieveCommentsForAggdataIdGql,
} from "graphql/queries";
import { createCommentGql, deleteCommentGql } from "graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { UserObj } from "models/user";
import { DEBUG } from "configuration";
import SharedUserAvatar, {
  Size,
} from "components/UI/Controls/SharedUserAvatar";
import CircularProgress from "@mui/material/CircularProgress";
import { useApolloClient } from "@apollo/client";
import {
  deleteCommentsCacheForAggdata,
  addAggdataCommentInCache,
  updateCommentCacheUponDelete,
  updateAggDataInCache,
} from "graphql/helpers";
import CommentIcon from "@mui/icons-material/Comment";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import { Link as TextLink } from "@mui/material";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import InputAdornment from "@mui/material/InputAdornment";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { RootState } from "store/reducers";
import LoginModal from "components/UI/Modals/LoginModal";
import ExpandableText from "components/Display/ExpandableText";

export const MainContainer = styled("div")<{}>`
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  transition: width 0.2s ease-in, height 0.2s ease-in;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
  }

  cursor: default;
`;

export const OpaqueOverlay = styled.div`
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 4;
`;

interface AggdataCommentsContainerProps {
  aggData: AggDataObj;
  inPublicMode?: boolean;
}

const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) => ({
  searchField: {
    "& .MuiInputBase-input": {
      fontSize: "1.6rem",
    },

    "& .MuiInputBase-root": {
      borderRadius: "8px",
      background: "#e8e8e8",
    },

    "&:hover .MuiInputBase-root": {
      background: "white",
    },
    "&:Mui-focused .MuiInputBase-root": {
      background: "white",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      padding: "12px",

      "& .MuiOutlinedInput-input": {
        padding: "4px",
      },
    },
  },
}));

const AggdataCommentsContainer = ({
  aggData,
  inPublicMode = false,
}: AggdataCommentsContainerProps) => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const commentsQueryResult = useQuery(retrieveCommentsForAggdataIdGql, {
    variables: {
      id: aggData.id,
      page: 1,
      pageSize: PAGE_SIZE,
    },
    context: {
      clientName: inPublicMode ? "public" : undefined,
    },
  });

  const [
    showCollectionBadgeToolTip,
    setShowCollectionBadgeTooltip,
  ] = React.useState(false);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const currentCommentsCount = useRef<number>(Number(aggData.commentsCount));
  const { t } = useTranslation();
  const history = useHistory();
  const fetchMoreOngoing = React.useRef(false);
  const lastFetchedPage = React.useRef(1);
  const [allCommentsRetrieved, setAllCommentsRetrieved] = React.useState(false);
  const [commentsListEmpty, setCommentsListEmpty] = React.useState(false);
  const [displayEmojiPicker, setDisplayEmojiPicker] = React.useState(false);
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const [commentCreationOngoing, setCommentCreationOngoing] = React.useState(
    false
  );
  const classes = useStyles();
  const textFieldValueRef = useRef(""); //creating a refernce for TextField Component
  const [createComment, createCommentResult] = useMutation(createCommentGql);

  const emojiButtonRef = React.useRef();
  //Understand handle click outside of comments container.
  const handleClickOutside = React.useCallback(() => {
    dispatch(hideCommentsViewAction());
  }, [hideCommentsViewAction]);
  var avatarColour = null;
  if (aggData.collection) {
    avatarColour = stringToColour(aggData.collection?.name);
  }

  console.log("comments list empty " + commentsListEmpty);

  React.useEffect(() => {
    document.addEventListener(
      "keydown",
      (event: any) => {
        if (event.key === "Escape") {
          handleClickOutside();
        }
      },
      false
    );
  }, []);

  React.useEffect(() => {
    if (
      commentsQueryResult.data &&
      !commentsQueryResult.loading &&
      !commentsQueryResult.error
    ) {
      if (commentsQueryResult.data.aggdataCommentsPaged.length == 0) {
        setCommentsListEmpty(true);
        setAllCommentsRetrieved(true);
      } else {
        setAllCommentsRetrieved(
          commentsQueryResult.data.aggdataCommentsPaged.length % PAGE_SIZE != 0
        );
      }
    }
  }, [commentsQueryResult]);

  //When we close the view we remove the cache to force fetching from server at next reopen
  React.useEffect(() => {
    return () => {
      deleteCommentsCacheForAggdata(client.cache, aggData?.id);
    };
  }, []);

  const fetchMoreComments = React.useCallback(() => {
    if (fetchMoreOngoing.current) {
      if (DEBUG) {
        console.log("fetchMoreComments already ongoing");
      }
      return;
    }
    fetchMoreOngoing.current = true;

    if (allCommentsRetrieved) {
      if (DEBUG) {
        console.log("Already retrieved everything");
      }
      fetchMoreOngoing.current = false;
      return;
    }
    const nextPageToFetch = lastFetchedPage.current + 1;

    if (DEBUG) {
      console.log("Fetch more data for page " + nextPageToFetch);
    }
    commentsQueryResult
      .fetchMore({
        variables: {
          id: aggData.id,
          page: nextPageToFetch,
          pageSize: PAGE_SIZE,
        },
      })
      .then((result) => {
        if (DEBUG) {
          console.log("Fetch more result %o", result);
        }
        if (
          result.data &&
          result.data.aggdataCommentsPaged &&
          result.data.aggdataCommentsPaged.length == 0
        ) {
          setAllCommentsRetrieved(true);
        }
        lastFetchedPage.current = nextPageToFetch;
        fetchMoreOngoing.current = false;
      })
      .catch((e) => {
        //setFetchOngoing(false)
        fetchMoreOngoing.current = false;
      });
    //}
  }, [
    commentsQueryResult,
    commentsQueryResult.data,
    allCommentsRetrieved,
    fetchMoreOngoing.current,
  ]);

  const handleOnScroll = React.useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        //console.log("bottom reached momo");
        fetchMoreComments();
      }
    },
    [fetchMoreComments]
  );

  const handleSendComment = React.useCallback(() => {
    const commentContent = textFieldValueRef.current.value;
    if (DEBUG) {
      console.log("Send comment " + commentContent);
    }
    if (commentContent.replace(/\s/g, "") == "") {
      if (DEBUG) {
        console.log("Empty comment, will not send");
      }
      return;
    }
    setCommentCreationOngoing(true);
    createComment({
      variables: {
        content: commentContent,
        dataId: aggData.id,
      },
    })
      .then((result) => {
        textFieldValueRef.current.value = "";
        if (DEBUG) {
          console.log("Comment created result is  %o", result);
        }
        addAggdataCommentInCache(
          client.cache,
          result.data.createComment.comment,
          aggData.id,
          PAGE_SIZE
        );
        if (DEBUG) {
          console.log("Aggdata from cache is %o", data);
        }
        currentCommentsCount.current++;
        const aggDataWtihCommentCountUpdated = {
          ...aggData,
          commentsCount: currentCommentsCount.current,
        };
        updateAggDataInCache(client.cache, aggDataWtihCommentCountUpdated);

        setCommentCreationOngoing(false);
        setCommentsListEmpty(false);
      })
      .catch((e) => {
        textFieldValueRef.current.value = "";

        setCommentCreationOngoing(false);
      });
  }, [textFieldValueRef, aggData, data, setCommentCreationOngoing]);

  //When a comment is deleted, we drop its page and all pages that come after it in the cache,
  //We replace its page by the new page returned from the backend
  //We need from here to restart fetching comments from this page
  const handleCommentDeleted = (deletedCommentPage: number) => {
    if (DEBUG) {
      console.log(
        "handleCommentDeleted: will start fetching from page " +
          (deletedCommentPage + 1)
      );
    }
    lastFetchedPage.current = deletedCommentPage;
    currentCommentsCount.current--;
    const aggDataWtihCommentCountUpdated = {
      ...aggData,
      commentsCount: currentCommentsCount.current,
    };
    updateAggDataInCache(client.cache, aggDataWtihCommentCountUpdated);
  };

  const toggleEmojiPicker = React.useCallback(() => {
    setDisplayEmojiPicker(!displayEmojiPicker);
  }, [displayEmojiPicker, setDisplayEmojiPicker]);
  //console.log("retrieved comments %o", commentsQueryResult);
  return (
    <>
      <OpaqueOverlay onClick={handleClickOutside} />
      <Box
        sx={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 400,
          position: "fixed",
          width: "70%",
          height: "80vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "8px",
          background: "#ffffff",
          border: "1px solid rgba(66, 66, 66, 0.2)",
          boxShadow:
            "0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149)",
          "@media (max-width: 1024px)": {
            width: "55%",
          },
          "@media (max-width: 768px)": {
            width: "75%",
            height: "80vh",
          },
          "@media (max-width: 576px)": {
            width: "100%",
          },
        }}
      >
        <MainContainer
          style={{
            flex: "1 1 0px",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "90%",
          }}
        >
          <AggdataCommentView aggData={aggData}></AggdataCommentView>
        </MainContainer>

        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            overflow: "hidden",
            flex: "1 1 0px",
          }}
        >
          {/********************START OF USER HEADER *********/}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <Box //name and created date container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  marginTop: "12px",
                }}
              >
                <UserAvatarWithTooltip
                  aggData={aggData}
                  topRight={false}
                  size={"normal"}
                />
              </Box>
              <Box //name and created date container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  marginTop: "12px",
                  marginLeft: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: 500,
                    aligntText: "start",
                  }}
                >
                  {aggData?.owner?.firstName + " " + aggData?.owner?.lastName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    aligntText: "start",
                  }}
                >
                  {formatIso8601(aggData?.created)}
                </Typography>
              </Box>
            </Box>

            {aggData.collection && (
              <Box
                sx={{
                  marginRight: "24px",
                }}
              >
                <Tooltip
                  key="manage-access"
                  open={showCollectionBadgeToolTip}
                  arrow
                  title={styledTooltipTitle(t("action_open"))}
                >
                  <Typography
                    variant="h5"
                    onMouseEnter={() => {
                      setShowCollectionBadgeTooltip(true);
                    }}
                    onMouseLeave={() => {
                      setShowCollectionBadgeTooltip(false);
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (aggData.collection?.isArchived) {
                        history.push(
                          "/board/archives/collection/" + aggData.collection.id
                        );
                      } else {
                        history.push(
                          "/board/collection/" + aggData.collection.id
                        );
                      }
                      e.stopPropagation();
                    }}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: avatarColour.backgroundColor,
                      color: avatarColour.textColor,
                      fontSize: "1.2rem",
                      padding: "6px",
                      marginTop: "6px",
                      cursor: "pointer",
                      alignItems: "center",
                      display: "flex",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    {isAggdataShared(aggData) && (
                      <PeopleOutlineIcon
                        sx={{
                          marginRight: "6px",
                        }}
                      />
                    )}
                    {aggData.collection.name}
                  </Typography>
                </Tooltip>
              </Box>
            )}
          </Box>

          {/*************** END OF HEADER  **********************/}

          {/*************** START OF COMMENTS LIST **********************/}

          <Box
            onScroll={handleOnScroll}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "0.7em",
                height: "0.7em",
              },
            }}
          >
            <Box
              sx={{
              }}
            >
              <ExpandableText text={aggData.annotation} />
            </Box>
            <Box>
              {commentsQueryResult &&
                commentsQueryResult.data &&
                commentsQueryResult.data.aggdataCommentsPaged &&
                commentsQueryResult.data.aggdataCommentsPaged.map(
                  (comment: CommentObj, index: number) => {
                    return (
                      <CommentContainer
                        key={index}
                        comment={comment}
                        index={index}
                        dataId={aggData.id}
                        onCommentDeleted={handleCommentDeleted}
                      />
                    );
                  }
                )}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!allCommentsRetrieved && !commentsListEmpty && (
                <CircularProgress style={{ padding: "2px" }} />
              )}
              {commentsListEmpty && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CommentIcon sx={{ width: "30%", height: "30%" }} />
                  <Typography variant="h5">
                    {t("comments_empty_message")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {displayEmojiPicker && (
            <Box
              sx={{
                width: "100%",
                position: "sticky",
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                bottom: 0,
                right: 0,
              }}
            >
              <Picker
                data={data}
                theme="light"
                onEmojiSelect={(e: any) => {
                  textFieldValueRef.current.value =
                    textFieldValueRef.current.value + e.native;
                }}
                onClickOutside={(e) => {
                  //  console.log("Onclickoutside displayed?" + displayEmojiPicker + " target "+ e.srcElement.id);
                  //We do this because for some strange reason onClickoutside gets called the first time we try to open the picker...
                  if (e.srcElement.id == "sentiment-icon") {
                    return;
                  }
                  if (displayEmojiPicker) {
                    setDisplayEmojiPicker(false);
                  }
                }}
              />
            </Box>
          )}
          {/*****************  START OF COMMENTS INPUT *********************************** */}
          {!commentsQueryResult.loading && (
            <Box
              sx={{
                width: "100%",
                borderRadius: "8px",
                background: "#ffffff",
                padding: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {currentUser && (
                <SharedUserAvatar
                  user={currentUser}
                  size={"small"}
                  applyLeftRight={false}
                />
              )}

              <TextField
                className={classes.searchField}
                id="outlined-basic"
                placeholder={"Enter comment"}
                multiline={true}
                maxRows={4}
                label={undefined}
                variant="outlined"
                inputRef={textFieldValueRef}
                sx={{
                  width: "100%",
                  marginLeft: "8px",
                }}
                onFocusCapture={(e: any) => {
                  console.log("Focus captured");
                  if (!currentUser) {
                    setOpenLoginModal(true);
                    e.target.blur(); //we remove the focus
                  }
                }}
                /* blurOnSelect
              onFocus={(e:any) => {
                console.log("Focus captured");
                if (!currentUser) {
                  setOpenLoginModal(true)
                }
              }} */
                //onChange={handleSearchInputChange}
                onPointerEnter={() => {}}
                onPointerLeave={() => {}}
                onBlur={() => {}}
                onFocus={() => {}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ padding: "0px" }}>
                      <IconButton
                        id="sentiment-icon"
                        onClick={toggleEmojiPicker}
                        aria-label="comment"
                        sx={{
                          padding: "0px",
                        }}
                        size="large"
                        color="primary"
                      >
                        <SentimentSatisfiedAltIcon
                          id="sentiment-icon"
                          sx={{ width: "24px", height: "24px" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              {commentCreationOngoing && (
                <CircularProgress
                  sx={{
                    width: "32px",
                    height: "32px",
                    padding: "4px",
                  }}
                ></CircularProgress>
              )}
              {!commentCreationOngoing && (
                <IconButton
                  onClick={handleSendComment}
                  aria-label="comment"
                  size="large"
                  color="primary"
                >
                  <SendIcon
                    fontSize="inherit"
                    sx={{
                      width: "32px",
                      height: "32px",
                    }}
                  />
                </IconButton>
              )}
            </Box>
          )}
          <LoginModal
            description={t("login_prompt_description_comment_login")}
            handleModalClose={() => {
              setOpenLoginModal(false);
            }}
            open={openLoginModal}
          />
        </Box>
      </Box>
    </>
  );
};

export default React.memo(AggdataCommentsContainer);

interface CommentContainerProps {
  comment: CommentObj;
  index: number;
  dataId: string;
  onCommentDeleted: (deleteCommentPage: number) => void;
}

const CommentContainer = ({
  comment,
  index,
  dataId,
  onCommentDeleted,
}: CommentContainerProps) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const [deleteComment, _] = useMutation(deleteCommentGql);
  const client = useApolloClient();

  const handleDeleteComment = React.useCallback(() => {
    const commentPage = index == 0 ? 1 : Math.ceil(index / PAGE_SIZE);
    if (DEBUG) {
      console.log(
        "handleDeleteComment: comment " +
          JSON.stringify(comment) +
          " page is " +
          commentPage
      );
    }

    deleteComment({
      variables: {
        commentId: comment.id,
        page: commentPage,
        pageSize: PAGE_SIZE,
      },
    })
      .then((result) => {
        if (DEBUG) {
          console.log(
            "handleDeleteComment: delete comment result is  %o",
            result
          );
        }
        const newPage = result?.data?.deleteCommentAndReturnPage.newPage;
        /*if (newPage.length == 0) {
          if (DEBUG)
            console.log(
              "delete comment was the only one in a last page, nothing to do"
            );
        } else { */
        updateCommentCacheUponDelete(
          client.cache,
          commentPage,
          PAGE_SIZE,
          dataId,
          newPage
        );
        onCommentDeleted(commentPage);
        // }
      })
      .catch((e) => {
        console.log("Got an exception %o", e);
      });
  }, [deleteComment, comment]);

  return (
    <Box
      key={comment.id}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        width: "100%",
        paddLeft: "12px",
        paddRight: "12px",
        alignItems: "center",
        marginBottom: "12px",
        overflow: "hidden",
      }}
    >
      <Box //name and created date container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginTop: "12px",
          marginLeft: "12px",
        }}
      >
        <SharedUserAvatar user={comment.user} size={"small"} />
      </Box>
      <Box //name and created date container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginTop: "4px",
          marginLeft: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.3rem",
              fontWeight: 500,
              aligntText: "start",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {comment?.user?.firstName + " " + comment?.user?.lastName}{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 200,
              aligntText: "start",
              marginLeft: "8px",
            }}
          >
            {getTimeElapsedsince(comment.created)}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "1.3rem",
            aligntText: "start",
            wordWrap: "break-word",
          }}
        >
          {comment.content}
        </Typography>
        {currentUser && currentUser.id == comment.user.id && (
          <TextLink
            style={{ color: "grey", cursor: "pointer" }}
            onClick={handleDeleteComment}
          >
            {t("action_delete")}
          </TextLink>
        )}
      </Box>
    </Box>
  );
};

import { ClickAwayListener, Paper, Popper } from "@mui/material";
import React from "react";

interface PublishCollectionFloatingMenuProps {
  anchorEl: any;
  onClose: () => void;
}

const PublishCollectionFloatingMenu = ({
  anchorEl,
  onClose,
}: PublishCollectionFloatingMenuProps) => {
  const open = Boolean(anchorEl);
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper id={"3"} open={open} anchorEl={anchorEl}>
        <Paper
          sx={{
            width: "200px",
            height: "200px",
            background: "red",
          }}
        ></Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default React.memo(PublishCollectionFloatingMenu);

import React, { useState, useCallback } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSelector, useDispatch } from "react-redux";
import { showConfidentialityFilter } from "store/actions/view";
import { saveConfidentialityFilterConfiguration } from "localstorage/localstorage";
import Tooltip from "@mui/material/Tooltip";
import { styledTooltipTitle } from "shared/styles";
import { useTranslation } from "react-i18next";
import { RootState } from "store/reducers";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import {
  saveCollectionsViewType,
  saveCollectionsSortType,
  getCollectionsSortType,
} from "localstorage/localstorage";
import SortIcon from "@mui/icons-material/Sort";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import CheckIcon from "@mui/icons-material/Check";

export enum VIEW_TYPE {
  GRID = "0",
  LIST = "1",
}

export enum SORT_TYPE {
  PINNED_FIRST = "0",
  ALPHABETICAL = "1",
  LAST_CREATED = "2",
  LAST_ACTIVE = "3",
}

export const MenuItemText = styled.div`
  font-size: 1.2rem;
`;
export interface CollectionsFilterControlProps {
  initialViewType: VIEW_TYPE;
  onViewTypeChanged: (viewType: VIEW_TYPE) => void;
  onSortTypeChanged: (viewType: SORT_TYPE) => void;
  disablePinSortOption?: boolean;
  disableVisibilityOption?: boolean;
  disableSortOptions?: boolean;
}

const CollectionsFilterControl = ({
  initialViewType,
  onViewTypeChanged,
  onSortTypeChanged,
  disablePinSortOption = false,
  disableVisibilityOption = false,
  disableSortOptions = false,
}: CollectionsFilterControlProps) => {
  const showConfidentialityFilterState = useSelector(
    (state: RootState) => state.view.showConfidentialityFilter
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [confidentialitySelected, setConfidentialitySelected] = React.useState(
    showConfidentialityFilterState
  );
  const [selectedViewType, setSelectedViewType] = React.useState<VIEW_TYPE>(
    initialViewType
  );
  const [selectedSortType, setSelectedType] = React.useState<SORT_TYPE>(
    getCollectionsSortType()
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = React.useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closePopupMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const iconStyles = {
    width: "18px",
    height: "18px",
  };

  React.useEffect(() => {
    onViewTypeChanged(selectedViewType);
    saveCollectionsViewType(selectedViewType);
  }, [selectedViewType]);

  React.useEffect(() => {
    onSortTypeChanged(selectedSortType);
    saveCollectionsSortType(selectedSortType);
  }, [selectedSortType]);

  return (
    <Box>
      {!disableSortOptions && (
        <Tooltip placement="top" title={styledTooltipTitle(t("sort_title"))}>
          <IconButton
            onClick={(e) => {
              handleClick(e);
            }}
          >
            {!confidentialitySelected ? (
              <SortIcon sx={iconStyles} />
            ) : (
              <SortIcon sx={iconStyles} />
            )}
          </IconButton>
        </Tooltip>
      )}
      {!disableVisibilityOption && (
        <Tooltip
          placement="top"
          title={styledTooltipTitle(
            !confidentialitySelected
              ? t("confidentiality_control_hide_hint")
              : t("confidentiality_control_show_hint")
          )}
        >
          <IconButton
            onClick={() => {
              saveConfidentialityFilterConfiguration(!confidentialitySelected);
              setConfidentialitySelected(!confidentialitySelected);
              dispatch(showConfidentialityFilter(!confidentialitySelected));
            }}
          >
            {!confidentialitySelected ? (
              <VisibilityOffIcon sx={iconStyles} />
            ) : (
              <VisibilityIcon sx={iconStyles} />
            )}
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        placement="top"
        title={styledTooltipTitle(
          selectedViewType == VIEW_TYPE.GRID
            ? t("view_list_title")
            : t("view_grid_title")
        )}
      >
        <IconButton
          onClick={() => {
            setSelectedViewType(
              selectedViewType == VIEW_TYPE.GRID
                ? VIEW_TYPE.LIST
                : VIEW_TYPE.GRID
            );
          }}
        >
          {selectedViewType == VIEW_TYPE.GRID ? (
            <TableRowsRoundedIcon sx={iconStyles} />
          ) : (
            <GridViewRoundedIcon sx={iconStyles} />
          )}
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closePopupMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.3rem",
            marginLeft: "8px",
            color: "gray",
            fontWeight: "bold",
            width: "150px",
          }}
        >
          {t("sort_title")}
        </Typography>
        {!disablePinSortOption && (
          <MenuItem
            key={0}
            onClick={() => {
              setSelectedType(SORT_TYPE.PINNED_FIRST);
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <MenuItemText>{t("sort_pin_order_title")} </MenuItemText>
              {selectedSortType == SORT_TYPE.PINNED_FIRST && <CheckIcon />}
            </Box>
          </MenuItem>
        )}
        <MenuItem
          key={1}
          onClick={() => {
            setSelectedType(SORT_TYPE.ALPHABETICAL);
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <MenuItemText>{t("sort_alphabetical_order_title")} </MenuItemText>
            {selectedSortType == SORT_TYPE.ALPHABETICAL && <CheckIcon />}
          </Box>
        </MenuItem>

        <MenuItem
          key={2}
          onClick={() => {
            setSelectedType(SORT_TYPE.LAST_CREATED);
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <MenuItemText>{t("sort_last_created_title")}</MenuItemText>
            {selectedSortType == SORT_TYPE.LAST_CREATED && <CheckIcon />}
          </Box>
        </MenuItem>
        <MenuItem
          key={3}
          onClick={() => {
            setSelectedType(SORT_TYPE.LAST_ACTIVE);
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <MenuItemText>{t("sort_last_active_title")}</MenuItemText>
            {selectedSortType == SORT_TYPE.LAST_ACTIVE && <CheckIcon />}
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default React.memo(CollectionsFilterControl);

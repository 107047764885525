import axios, { AxiosResponse } from "axios";
import { SERVER_URL, DEBUG } from "configuration";

const AUTH_URL_API_SUBPATH = "/integrations/get-auth-url"
const AUTH_USER_API_SUBPATH = "/integrations/auth-notion"
const FETCH_NOTION_INFORMATION_API_SUBPATH = "/integrations/notion-info"
const DISCONNECT_NOTION_API_SUBPATH = "/integrations/notion-disconnect"
const CONNECT_COLLECTION_TO_NOTION_API_SUBPATH = "/integrations/notion-connect-collection"
const DISCONNECT_COLLECTION_TO_NOTION_API_SUBPATH = "/integrations/notion-disconnect-collection"
const FETCH_NOTION_COLLECTIONS_API_SUBPATH = "/integrations/notion-collections"

export interface NotionIntegrationInformation {
    isConnected: boolean;
    workspaceName: string | null;
    workspaceIconUrl: string | null;
    rootPageName: string | null;
    rootPageUrl: string | null;
}

export interface NotionCollectionsInfo {
    id: string;
    name: string;
    previewUrls: string;
    notionDatabaseId: string;
    lastSync: string;
    isArchived: boolean;
}

export interface ConnectCollectionResult {
    collectionInfo: NotionCollectionsInfo | null;
    result: boolean;
}

export interface AuthenticateUserResponse {
    resultCode: number;
    integrationInfo: NotionIntegrationInformation;
}

export const fetchAuthUrl = async (): Promise<string> => {
    try {
        let res = await axios.post(SERVER_URL + AUTH_URL_API_SUBPATH,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("fetchAuthUrl: result" + JSON.stringify(res));
        }
        return res.data.url

    } catch (e) {
        throw (e)
    }
}

export const authenticateUser = async (code: string): Promise<AuthenticateUserResponse> => {
    try {
        let res = await axios.post(SERVER_URL + AUTH_USER_API_SUBPATH,
            {
                code: code
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("authenticateUser: result" + JSON.stringify(res));
        }
        return {
            resultCode: res.data.result,
            integrationInfo: {
                isConnected: res.data.connected,
                workspaceName: res.data.information?.workspace_name,
                workspaceIconUrl: res.data.information?.workspace_icon,
                rootPageName: res.data.information?.root_page_name,
                rootPageUrl: res.data.information?.root_page_url,
            }
        };
    } catch (e) {
        throw (e)
    }
}

export const disConnectCollection = async (collectionId: string): Promise<boolean> => {
    try {
        let res = await axios.post(SERVER_URL + DISCONNECT_COLLECTION_TO_NOTION_API_SUBPATH,
            {
                id: collectionId
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true   
            })
        if (DEBUG) {
            console.log("disConnectCollection: result" + JSON.stringify(res));
        }
        return res.status == 200
    } catch (e) {
        throw (e)
    }
}

export const connectCollection = async (collectionId: string): Promise<ConnectCollectionResult> => {
    try {
        let res = await axios.post(SERVER_URL + CONNECT_COLLECTION_TO_NOTION_API_SUBPATH,
            {
                id: collectionId
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("connectCollection: result" + JSON.stringify(res));
        }
        if (res.status == 200){
            return {
                result: true,
                collectionInfo: {
                    id: res.data.collection_info.collection_id,
                    name:  res.data.collection_info.collection_name,
                    previewUrls:  res.data.collection_info.preview_urls,
                    notionDatabaseId:  res.data.collection_info.notion_database_id,
                    lastSync:  res.data.collection_info.last_sync,
                }
            }
        } else {
            return {
                result: true,
                collectionInfo: null,
            }
        }
    } catch (e) {
        throw (e)
    }
}

export const fetchIntegrationInformation = async (): Promise<NotionIntegrationInformation> => {
    try {
        let res = await axios.get(SERVER_URL + FETCH_NOTION_INFORMATION_API_SUBPATH,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("fetchIntegrationInformation: result" + JSON.stringify(res));
        }

        return {
            isConnected: res.data.connected,
            workspaceName: res.data.information?.workspace_name,
            workspaceIconUrl: res.data.information?.workspace_icon,
            rootPageName: res.data.information?.root_page_name,
            rootPageUrl: res.data.information?.root_page_url,
        }
    } catch (e) {
        throw (e)
    }
}


export const fetchNotionCollections = async (): Promise<NotionCollectionsInfo[] | null> => {
    try {
        let res = await axios.get(SERVER_URL + FETCH_NOTION_COLLECTIONS_API_SUBPATH,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("fetchNotionCollections: result" + JSON.stringify(res));
        }
        if (res.data.result){
            let resultArray =  res.data.result.map((info : any) => {
                return {
                    id: info.collection_id,
                    name: info.collection_name,
                    previewUrls: info.preview_urls,
                    notionDatabaseId: info.notion_database_id,
                    lastSync: info.last_sync,
                    isArchived: info.is_archived,
                }
            })
            return resultArray
        } else {
            return null
        }
    } catch (e) {
        throw (e)
    }
}

export const disconnectCurrentIntegration = async (): Promise<boolean> => {
    try {
        let res = await axios.get(SERVER_URL + DISCONNECT_NOTION_API_SUBPATH,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
        if (DEBUG) {
            console.log("disconnectCurrentIntegration: result" + JSON.stringify(res));
        }

        return res.status == 200
    } catch (e) {
        throw (e)
    }
}
import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import moment from "moment";
import { Reminder, AggDataObj } from 'models/aggdata';
import ReminderCyclePicker from "components/UI/ReminderDatePicker/ReminderCyclePicker";
import { DEBUG } from 'configuration';


interface ReminderDatePickerProps {
  open: boolean;
  aggDataArray: AggDataObj[];
  onClose: () => void;
  onReminderPicked: (reminder: Reminder, aggdataObjArray : AggDataObj[]) => void;
}

const ReminderDatePicker = ({
  aggDataArray,
  open,
  onClose,
  onReminderPicked,
}: ReminderDatePickerProps) => {
  const globalTheme = useTheme();

  const [currentReminderValue, setCurrentReminderValue] = useState<Reminder>({
    triggerTime: 0,
    cycle: 0,
  });
  const [displayCyclePicker, setDisplayCyclePicker] = useState(false);

  const theme = createTheme({
    ...globalTheme,
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "1.8rem",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "1.4rem",
          },
        },
      },
      ClockPicker: {
        styleOverrides: {
          fontSize: "2rem",
        },
      },
    },
  });

  const handleCyclePicked = (cycle: number) => {
    onReminderPicked &&
      onReminderPicked({ ...currentReminderValue, cycle: cycle }, aggDataArray);
  };

  return (
    <>
      {!displayCyclePicker && (
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              open={open}
              renderInput={(props) => {
                return <></>;
              }}
              DialogProps={{
                onClose: (e, reason) => {
                  //console.log("close e reason is " + reason,e)
                  onClose();
                },
               
              }}
              minDate={new Date()}
              //When we go back from cycle picker, we want to display the date that has been selected just before
              value={currentReminderValue.triggerTime > 0  ? new Date(currentReminderValue.triggerTime*1000): new Date()}
              onClose={() => {
                //console.log("close e reason is ")

                //onClose();
              }}
              
              onChange={(newValue) => {
                const date = moment(newValue);
                setCurrentReminderValue({ triggerTime: date.unix(), cycle: 0 });
              }}
              onAccept={(val) => {
                if (DEBUG)
                console.log("on onAccept " + val + " type of " + typeof val);
                const date = moment(val);
                setCurrentReminderValue({ triggerTime: date.unix(), cycle: 0 });
                setDisplayCyclePicker(true);
              }}
              
            />
          </LocalizationProvider>
        </ThemeProvider>
      )}

      {displayCyclePicker && (
        <ReminderCyclePicker
          pickedTimestamp={currentReminderValue.triggerTime}
          onBackToDateTimePicker={() => {
            setDisplayCyclePicker(false);
          }}
          onClose={onClose}
          onCyclePicked={handleCyclePicked}
        />
      )}
    </>
  );
};

export default React.memo(ReminderDatePicker);

import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback, useEffect } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { retrieveAllCollectionsGql } from "graphql/queries";
import Grid from "@mui/material/Grid";
import { CollectionObj } from "models/collection";
import Box from "@mui/material/Box";
import SelectCollectionItem from "containers/SelectCollectionContainer/SelectCollectionItem";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import IconButton from "@mui/material/IconButton";
import { colorGreyMemento } from "shared/colors";
import AddNewCollectionModal from "components/UI/Modals/AddNewCollectionModal";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Grow from "@mui/material/Grow";
import CloseIcon from "@mui/icons-material/Close";
import { scrollBarStyle } from "shared/styles";
import { AggDataObj } from "models/aggdata";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      width: "100%",
      borderRadius: "25px",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
  })
);

interface YoutubePlayerModalProps {
  url: string;
  onClose: () => void;
}

const YoutubePlayerModal = ({ url, onClose }: YoutubePlayerModalProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(Boolean(url));
  console.log("modal opened")

  const handleModalClose = (e: any) => {
    e.stopPropagation();
    onClose();
    setOpen(false); 
  };


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={(e) => handleModalClose(e)}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <iframe
          width="853"
          height="480"
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Fade>
    </Modal>
  );
};

export default React.memo(YoutubePlayerModal);

import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { colorMementoBeige, colorMemento } from "shared/colors";
import CancelIcon from "@mui/icons-material/Cancel";
const options = [
  "Collection1",
  "Collection2",
  "Collection3",
  "Collection4",
  "Collection5",
];

interface SimpleFilterProps {
  values: string[];
  allSelectedText: string; //placeholder text displayed when all items are selected
  inputHint: string; //placeholder text displayed when all items are selected
  onSelectionChanged: (selectedValues: string[]) => void;
}

const SimpleFilter = ({
  values,
  allSelectedText,
  inputHint,
  onSelectionChanged,
}: SimpleFilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const [unSelectedItems, setUnSelectedItems] = React.useState(values);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * When the values provided as an input changes, we need to make sure that the selected items are still within the range of values provided
   * If not, then we simply remove the selected items, if the values doesn't exist anymore in values input, and notify about selection change.
   */
  React.useEffect(() => {
    setUnSelectedItems(
      values.filter((value) => !selectedItems.includes(value))
    );
    const selectedItemCountPreFilter = selectedItems.length;
    const newSelectedItemsArray = selectedItems.filter((selectedItem) =>
      values.includes(selectedItem)
    );
    setSelectedItems(newSelectedItemsArray);
    console.log("length before filter " + selectedItemCountPreFilter + " and after filter " + selectedItems.length)
    if (selectedItemCountPreFilter != newSelectedItemsArray.length) {
       onSelectionChanged(newSelectedItemsArray)
    }
  }, [values, onSelectionChanged]);

  const handleDeleteSelectedItem = React.useCallback(
    (itemToDelete: string) => {
      const mutableArray = [...selectedItems];
      const indexOfItemToDelete = mutableArray.indexOf(itemToDelete);
      if (indexOfItemToDelete > -1) {
        mutableArray.splice(indexOfItemToDelete, 1);
        onSelectionChanged(mutableArray);
        setSelectedItems(mutableArray);
      }
      setUnSelectedItems([...unSelectedItems, itemToDelete]);
      setAnchorEl(null);
    },
    [selectedItems, unSelectedItems, setUnSelectedItems, setAnchorEl]
  );

  const handleMenuItemClicked = React.useCallback(
    (clickedItem: string) => {
      const mutableArray = [...unSelectedItems];
      const indexOfItemToDelete = mutableArray.indexOf(clickedItem);
      if (indexOfItemToDelete > -1) {
        mutableArray.splice(indexOfItemToDelete, 1);
        setUnSelectedItems(mutableArray);
      }
      const selectedItemsResult = [...selectedItems, clickedItem];
      setSelectedItems(selectedItemsResult);
      onSelectionChanged(selectedItemsResult);
      setAnchorEl(null);
    },
    [selectedItems, unSelectedItems, setUnSelectedItems, setAnchorEl]
  );

  return (
    <div>
      <Chip
        style={{
          borderStyle: "dashed",
        }}
        label={inputHint}
        variant="outlined"
        onClick={handleClick}
      />
      <Menu
        style={{
          width: "100%",
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {unSelectedItems.map((option, index) => (
          <MenuItem
            sx={{
              fontSize: "1.2rem"
            }}
            key={option + index}
            onClick={(event) => {
              handleMenuItemClicked(option);
            }}
          >
            {option}
          </MenuItem>
        ))}
        {unSelectedItems.length == 0 && (
          <Typography
            sx={{
              paddingRight: "16px",
              paddingLeft: "16px",
            }}
          >
            <i>{allSelectedText}</i>
          </Typography>
        )}
      </Menu>
      {selectedItems.map((item, index) => {
        return (
          <Chip
            key={item + index}
            style={{
              borderColor: colorMemento,
              marginRight: "4px",
              marginLeft: "4px",
            }}
            label={item}
            color="primary"
            deleteIcon={<CancelIcon color="white"></CancelIcon>}
            onDelete={() => handleDeleteSelectedItem(item)}
            onClick={() => {}}
          />
        );
      })}
    </div>
  );
};

export default SimpleFilter;

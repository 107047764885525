import React from "react";
import styled from "styled-components";
import { colorMemento } from "shared/colors";
import DownloadIcon from '@mui/icons-material/Download';
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(105,105,105, 0.7);
  align-items: center;
  justify-content: center;
  z-index: 4000000;
`;

const SubContainer = styled.div`
  display: flex;
  height: 70%;
  width: 70%;
  background: transparent;
  border-style: dashed;
  border-width: 4px;
  border-color: ${colorMemento};
  font-size: 20rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: - 1;
`;

const FilesDropArea = () => {
  const { t } = useTranslation();

  return (
    <>
      <MainContainer>
        <SubContainer>
        <DownloadIcon sx={{width : "45%", height: "40%", color: colorMemento}}/>
        <Typography sx={{ color: "white"}} variant="h2">{t('drop_files_here_hint')}</Typography>
        </SubContainer>
      </MainContainer>
    </>
  );
};

export default FilesDropArea;

import React, { useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { useTranslation } from "react-i18next";
import { colorGreyMemento } from "shared/colors";


interface UnpinnedHeaderProps{
  itemsCount?: number;
}

const UnpinnedHeader = ({ itemsCount }: UnpinnedHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        marginTop: "16px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        paddingLeft: "32px",
      }}
    >
      <PushPinOutlinedIcon
        htmlColor={colorGreyMemento}
        style={{ height: "16px", width: "16px" }}
      />
      <Typography
        sx={{
          fontWeight: "550",
          color: colorGreyMemento,
          marginLeft: "4px",
          fontSize: "1.6rem"
        }}
        variant="h4"
      > 
        {t("header_unpinned_title")}
      </Typography>
      <Typography
        sx={{
          fontWeight: "550",
          color: colorGreyMemento,
          marginLeft: "4px",
          fontSize: "1.2rem"
        }}
        variant="h5"
      >
        {"(" + itemsCount + ")"}
      </Typography>
    </Box>
  );
};

export default React.memo(UnpinnedHeader);

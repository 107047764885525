import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import RemindersIllustration from "illustrations/undraw_no_reminders.svg";
import styled from "styled-components";
import {
  isDateFromYesterday,
  isDateFromToday,
  isDateFromTomorrow,
  formatToContextualDate,
} from "shared/dataUtils";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface RemindersEmptyProps {
  date : Date;
}



const RemindersEmpty = ({ date }: RemindersEmptyProps) => {
  const { t } = useTranslation();
  
  const getContextualDate = React.useCallback(() : string => {
    if (!date) {
      return "";
    }
    if (isDateFromToday(date)) {
      return t("reminders_empty_title_today", {
          date: formatToContextualDate(date),
        })
     
    } else if (isDateFromTomorrow(date)) {
      return t("reminders_empty_title_tomorrow", {
          date: formatToContextualDate(date),
        })
      
    } else if (isDateFromYesterday(date)) {
      return t("reminders_empty_title_yesterday", {
          date: formatToContextualDate(date),
        })
      
    } else {
      return t("reminders_empty_title_generic", {
          date: formatToContextualDate(date),
        })
     
    }
  }, [date])

  const [displayedMessage, setDisplayedMessage] = React.useState<string>(getContextualDate(date));


  React.useEffect(() => {
    setDisplayedMessage(getContextualDate())
  }, [date])

  
 
  return (
    <MainContainer>
      <img
        style={{ marginTop: "120px", height: "300px", width: "600px", cursor:"initial" }}
        src={RemindersIllustration}
      />
      <Typography
        style={{ textAlign: "center", marginLeft: "12px", color: "black", marginTop: "24px" }}
        variant="h4"
      >
        {displayedMessage}
      </Typography>
    </MainContainer>
  );
};

export default React.memo(RemindersEmpty);

import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { retrieveAuthToken } from "api/authApi";
import { CHROME_EXTENSION_ID } from "configuration";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Logo from "icons/favicon.png";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";


const sendTokenToExtension = (token: string) => {
  chrome.runtime.sendMessage(CHROME_EXTENSION_ID, {
    type: 0,
    payload: token,
  });
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: theme.palette.background.paper,
  },
  logo: {
    width: "80px",
    height: "80px", 
    marginBottom: "16px",
  },
  paper: {
    padding: "36px",
    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function SetupExtension() {
  const classes = useStyles();

  const { t } = useTranslation();

  useEffect(() => {
    retrieveAuthToken().then((result) => {
      if (result) {
        sendTokenToExtension(result);
      }
    });
  }, []);
  return (
    <>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <img
            id="logo"
            className={classes.logo}
            src={Logo}
            alt="Memento logo"
          />

          <Typography variant="h4" component="div" gutterBottom>
            {t('extension_setup_loading_message')}
          </Typography>
          <CircularProgress style={{marginTop: "36px"}} />
        </Paper>
      </Box>
    </>
  );
}

export default SetupExtension;

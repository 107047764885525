import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { APP_PLAYSTORE_LINK } from "configuration";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import OrganizeIllustration from "illustrations/organize_illustration.png";
import CaptureIllustration from "illustrations/capture_illustration.png";
import FindBackIllustration from "illustrations/find_back_illustration.png";
import ArrowIllustration from "illustrations/arrow_illustration.png";

const ProblematicStepsContainer = styled.div`
  display: flex;
  flex-direction: rown;
  justify-content: start;
  align-items: start;
  width: 100%;
  margin-bottom: 32px;
  margin-top: 120px;
  padding-right: 8%;
  padding-left: 8%;
  @media (max-width: 780px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const stepsSubtitleStyle = {
  fontSize: "1.6rem",
  fontFamily: "Trebuchet MS, sans-serif",
  textAlign: "start",
  "@media (max-width: 780px)": {
    width: "100%",
  },
};

const ArrowContainer = styled.img`
  max-height: 512px;
  width: auto;
  @media (max-width: 780px) {
    transform: rotate(90deg);
  }
`;

const ProblematicStepsComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <ProblematicStepsContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            flex: "1 1 0px",
          }}
        >
          <img
            style={{
              width: "auto",
              height: "90px",
            }}
            src={CaptureIllustration}
          />

          <Typography
            variant="h4"
            sx={{
              width: "100%",
              marginTop: "12px",
              textAlign: "start",
            }}
          >
            {t("landing_probletmatic_capture_title")}
          </Typography>
          <Typography variant="h5" sx={stepsSubtitleStyle}>
            {t("landing_probletmatic_capture_description")}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              width: "100%",
              fontSize: "1.4rem",
              fontFamily: "Trebuchet MS, sans-serif",
              fontWeight: 300,
              alignItems: "center",
              marginTop: "12px",
              color: "grey",
              display: "flex",
              justifyContent: "start",
              cursor: "pointer",
              "&:hover": {
                opacity: 0.6,
              },
            }}
            onClick={() => {
              window.open(
                "https://blog.mementocollect.com/how-memento-supercharges-your-content-capture",
                "_blank"
              );
            }}
          >
            {t("learn_more_tilte")}{" "}
            <ArrowRightAltIcon
              sx={{ marginLeft: "8px", width: "1.4rem", height: "1.4rem" }}
            />
          </Typography>
        </div>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: "48px",
            marginLeft: "48px",
            alignItems: "center",
            "@media (max-width: 780px)": {
              width: "100%",
              height: "100%",
              marginRight: "0px",
              marginLeft: "0px",
            },
          }}
        >
          <ArrowContainer
            style={{
              width: "auto",
              maxHeight: "100px",
            }}
            src={ArrowIllustration}
          />
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            flex: "1 1 0px",
          }}
        >
          <img
            style={{
              width: "auto",
              height: "75px",
              marginTop: "15px",
            }}
            src={OrganizeIllustration}
          />

          <Typography
            variant="h4"
            sx={{
              width: "70%",
              marginTop: "12px",
              textAlign: "start",
            }}
          >
            {t("landing_probletmatic_organize_title")}
          </Typography>
          <Typography variant="h5" sx={stepsSubtitleStyle}>
            {t("landing_probletmatic_organize_description")}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              width: "100%",
              fontSize: "1.4rem",
              fontFamily: "Trebuchet MS, sans-serif",
              fontWeight: 300,
              alignItems: "center",
              marginTop: "12px",
              color: "grey",
              display: "flex",
              justifyContent: "start",
              cursor: "pointer",
              "&:hover": {
                opacity: 0.6,
              },
            }}
            onClick={() => {
              window.open("https://blog.mementocollect.com", "_blank");
            }}
          >
            {t("learn_more_tilte")}{" "}
            <ArrowRightAltIcon
              sx={{ marginLeft: "8px", width: "1.4rem", height: "1.4rem" }}
            />
          </Typography>
        </div>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: "48px",
            marginLeft: "48px",
            alignItems: "center",
            "@media (max-width: 780px)": {
              width: "100%",
              height: "100%",
              marginRight: "0px",
              marginLeft: "0px",
            },
          }}
        >
          <ArrowContainer
            style={{
              width: "auto",
              maxHeight: "100px",
            }}
            src={ArrowIllustration}
          />
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            flex: "1 1 0px",
          }}
        >
          <img
            style={{
              width: "auto",
              maxHeight: "90px",
            }}
            src={FindBackIllustration}
          />

          <Typography
            variant="h4"
            sx={{
              width: "70%",
              marginTop: "12px",
              textAlign: "start",
            }}
          >
            {t("landing_probletmatic_search_title")}
          </Typography>

          <Typography variant="h5" sx={stepsSubtitleStyle}>
            {t("landing_probletmatic_search_description")}
          </Typography>
        </div>
      </ProblematicStepsContainer>
    </>
  );
};

export default ProblematicStepsComponent;

import {
    CollectionsState,
    CollectionActionTypes
} from 'store/actions/collectionsActions';
import * as actionTypes from 'store/actions/collectionsActions';
import { SetMaxPinScore, SetMaxLastUsedScore, SetSharedUsersList} from 'store/actions/collectionsActions';
import { SharedUser } from "models/user";
import { updateObject } from 'shared/utility';

const initialState: CollectionsState = {
    maxPinScore: 0,
    maxLastUsedScore: 0,
    sharedUsers: [],   
};

const setSharedUsersList = (state: CollectionsState, action: SetSharedUsersList): CollectionsState => {
    const newState = {
        ...state,
        sharedUsers: action.payload,
    };
    return updateObject(state, newState);
};

const setMaxPinScore = (state: CollectionsState, action: SetMaxPinScore): CollectionsState => {
    const newState = {
        ...state,
        maxPinScore: action.payload,
    };
    return newState;
};

const setMaxLastUsedScore = (state: CollectionsState, action: SetMaxLastUsedScore): CollectionsState => {
    const newState = {
        ...state,
        maxLastUsedScore: action.payload,
    };
    return newState;
};

const reducer = (state = initialState, action: CollectionActionTypes) => {
    switch (action.type) {
        case actionTypes.SET_MAX_PIN_SCORE:
            return setMaxPinScore(state, action);
        case actionTypes.SET_MAX_LAST_USED_SCORE:
            return setMaxLastUsedScore(state, action);
        case actionTypes.SET_SHARED_USERS_LIST:
            return setSharedUsersList(state, action);
        default:
            return state;
    }
};

export default reducer;
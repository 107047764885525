import React from "react";
import { PRIVACY_POLICY } from "navigation/Constants";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import SimpleHeader from "components/Header/SimpleHeader";
import { colorMemento } from "shared/colors";
import { Link as TextLink } from "@mui/material";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from "shared/utility";

const MainContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding-right: 5%;
  padding-left: 15%;
  margin-bottom: 5%;
  @media (max-width: 780px) {
    padding-left: 8%;
  }
`;
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 780px) {
    width: 90%;
  }
`;

const Space = styled.section`
  height: 7%;
`;

const TEXT_CONTENT = [
  {
    title: "",
    paragraphs: [
      "This page is used to inform visitors about our policies regarding the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. If you don't agree with the current policy as presently stated, please do not use any of our services.",
      " The present policy might be subject to changes in the future for various reasons. When the change is significant, we will make sure that you aware of it and eventually reach out for your consent when necessary.",
    ],
  },
  {
    title: "The information we collect about you",
    paragraphs: ["", "", ""],
  },
];

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  //const history = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet>
        <title>{capitalizeFirstLetter(t("privacy_policy"))} | Memento</title>
      </Helmet>
      <SimpleHeader makeStatic={true} />

      <Typography
        variant="h2"
        sx={{
          fontSize: "5rem",
          fontFamily: "Trebuchet MS, sans-serif",
          textAlign: "center",
          marginTop: "5%",
          marginBottom: "10%",
        }}
      >
        Our privacy policy
      </Typography>
      <Space style={{ height: "20%" }} />
      <MainContainer>
        {/* {TEXT_CONTENT.map((content) => {
          return ( 
            <>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "3rem",
                  fontWeight: 530,
                  fontFamily: "Trebuchet MS, sans-serif",
                }}
              >
                {content.title}
              </Typography>
              {content.paragraphs.map((paragraph) => {
                return (
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Trebuchet MS, sans-serif",
                      marginTop: "2%",
                      fontSize: "2rem",
                    }}
                  >
                    {paragraph}
                  </Typography>
                );
              })}
            </>
          );
        })} */}

        <BodyContainer>
          <Typography
            variant="h3"
            sx={{
              fontSize: "1.6rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: colorMemento,
            }}
          >
            <span style={{ background: "white", color: "black" }}>
              Effective from
            </span>{" "}
            May 6th, 2023
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
              fontSize: "1.9rem",
            }}
          >
            This page is used to inform visitors about our policies regarding
            the collection, use, and disclosure of Personal Information if
            anyone decided to use our Service. If you choose to use our Service,
            then you agree to the collection and use of information in relation
            to this policy. If you don't agree with the current policy as
            presently stated, please do not use any of our services.
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontFamily: "Trebuchet MS, sans-serif", fontSize: "1.9rem" }}
          >
            The present policy might be subject to changes in the future for
            various reasons. When the change is significant, we will make sure
            that you aware of it and eventually reach out for your consent when
            necessary.
          </Typography>

          <Space style={{ marginTop: "1%" }} />
          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: colorMemento,
            }}
          >
            The information we collect about you
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
              fontSize: "1.9rem",
            }}
          >
            Memento can operate in two different modes, which impacts how we
            deal with your personal data:
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              paddingLeft: "2%",
              fontSize: "1.9rem",
            }}
          >
            <b>• Connected mode</b>: This mode is enabled when you create a
            Memento account using your email address. It allows you to store
            data you input to Memento on our servers, and make it available on
            all your devices that are connected to the internet, either using
            Memento for web or for mobile devices.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              paddingLeft: "2%",
              fontSize: "1.9rem",
            }}
          >
            <b>• Offline mode</b>: This mode is enabled by default when you
            first install Memento app on your mobile device. You can input your
            data to Memento without having a Memento account. In this case, all
            the inputed data is stored solely on your local device. If at some
            point you switch to the connected mode, i.e. create a Memento
            account and login to it on your mobile device, all existing data on
            your local device will be synchronized with our servers. Note that
            this mode only exists on Memento for mobile devices, and is not
            relevant for the Memento web application.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            Helping you collect your data and organizing it is the core of our
            service. Memento retains the data you choose to input to it, and
            eventually some other data that allows to improve our service :
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• User information</b> : To use Memento on your web browser, or
            to synchronize data from your mobile device you are required to
            create a Memento account using your email address, first name, last
            name and a password. This information is stored on our servers and
            will allow to identify you each time you use our service. If you
            decide to register or login to our service using third party
            authentication (Google sign in for instance), Memento will have
            access to your personal information required for the account
            creation, according to the permissions granted by the third party
            authentication provider. Note that this information is only
            collected when you use Memento in Connected Mode. Furthermore, data
            such as your email address, first name and last name will also be
            shared with other Memento users that you might have shared
            collections with.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• Inputed data</b>: Memento collects images, files, notes and
            links you choose to input to it. To help you collect data easily,
            Memento by default automatically captures your screenshots and WhatsApp images,
            and acts as a receiver
            for some data that is shared to it. If you choose to, you can give
            access to some of your friends to the data that you choose to share
            with them through shared collections. When you do so, in all cases
            you remain owner of the data that you add to shared collections, and
            you can at any time revoke access to it. <br />
            Some of the data you input to Memento might be transferred to some
            third parties that provide to us some of the services we offer you,
            such as our AI based features. We pay very good attention to our
            providers privacy policy for processing and retaining your data, and
            in this case if you are entitled to such features with respect to
            our different subscription plans, our provider commits to to retain
            submitted data for no more than 30 days, and not to use it to
            further train their AI models. Their full policy is available .
            <TextLink
              style={{ color: colorMemento, cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://openai.com/policies/api-data-usage-policies",
                  "_blank"
                );
              }}
            >
              here
            </TextLink>
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• Subscription information</b> : Payment information such as
            credit card number, zip code and CVC that you may use to upgrade
            your Memento plan is securely processed by a third party provider
            according their{" "}
            <TextLink
              style={{ color: colorMemento, cursor: "pointer" }}
              onClick={() => {
                //history.push(PRIVACY_POLICY);
                window.open("https://stripe.com/fr/privacy", "_blank");
              }}
            >
              policy
            </TextLink>
            . None of this information is retained by Memento. We however keep
            track of your subscription status, and monitor your data usage
            according to the plans quotas.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• Device activity</b> : Memento collects information about the
            devices you use to connect, such as your device model, operating
            system, and web browser, and also keeps track of your activity such
            as your last login date and account creation date that you can all
            access from your settings section. This information is collected in
            order to give you better control and visibilty over how your account
            is being accessed.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• Usage data</b>: In order to continuously provide a curated
            experience to our users, we collect some of the data about the usage
            you make of Memento using Google Analytics according to this{" "}
            <TextLink
              style={{ color: colorMemento, cursor: "pointer" }}
              onClick={() => {
                //history.push(PRIVACY_POLICY);
                window.open(
                  " https://policies.google.com/technologies/partner-sites?hl=en-GB&gl=uk",
                  "_blank"
                );
              }}
            >
              policy
            </TextLink>
            . For example, this helps us to figure out what features are being
            the most useful to our users, and prioritize our efforts to match
            our users expectations.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
              color: colorMemento,
            }}
          >
            How we use the information we collect :
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            At Memento we value the trust you put in us by using our service,
            and that's why we are committed to protect your privacy and treat
            your data responsibly. Data we collect is used to operate our
            service to match our security, efficiency and user experience
            requirements.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• Improve our service</b>: In order to respond to various
            technical challenges in managing our infrastructues and implementing
            new requirements, we may need to analyze in an aggregated/individual
            fashion our data sets and extract various information that will
            allow us to achieve our improvements. During this process, please
            note that we will always make sure that your information is strictly
            protected, and is only accessed by our teams when absolutely
            necessary.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• Support you</b>: To be able to support you in case you need our
            help, our support team may need to access some of your personal
            information temporarily.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• Communication with you</b>: Memento uses your contact
            information to communicate with you.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• Satisfy legal requests</b>: Memento might disclose some
            information about you if required by law in compliance with
            applicable local regulations.
          </Typography>
          <Space style={{ marginTop: "1%" }} />
          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              marginTop: "4%",
              fontFamily: "Trebuchet MS, sans-serif",
              color: colorMemento,
            }}
          >
            Data management and lifecycle :
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
              fontSize: "1.9rem",
            }}
          >
            At Memento, our revenues are not based on trading your personal
            information. There are limited cases where we might have to disclose
            some of your information, only the minimum required for each of the
            following cases :
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
              fontSize: "1.9rem",
              paddingLeft: "2%",
            }}
          >
            <b>• Payment information:</b> When you pay for a Memento product,
            your payment information along with your contact information are
            shared with a third party that securely processes the payment
            transaction on our behalf.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
              fontSize: "1.9rem",
            }}
          >
            Your personal information can be managed from your personal account.
            For any requests that can't be directly handled through your
            personal settings space, you can contact us directly.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            The data you input to our service is still owned by you, and will be
            stored and retained by us until you decide to delete it. When your
            data is deleted, it might be kept around for a maximum of 30 days as
            a backup in case it has been wrongfully deleted by you.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            If you wish to, you can at any time delete your account and all your
            personal data with it from your account settings. When you do so,
            you will be notified about this action on your connected mobile
            devices (if you have any), and we will give you the choice to either
            keep a copy of your data on your local mobile device, which will
            bring the Memento mobile application into operating in "Offline
            Mode", or not keeping any data, which means all your data will be
            irreversibly deleted.
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              marginTop: "4%",
              fontFamily: "Trebuchet MS, sans-serif",
              color: colorMemento,
            }}
          >
            Keeping your information safe
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            At Memento, one of our areas of focus is to always implement the
            most secure and safe mechanisms and measures to protect your data
            globally. In spite of all our efforts, we are still subject to
            security breaches and vulnerabilities inherent to technology, like
            all others companies. Indeed, no computer technology can be
            guaranteed to be fully secure, so in turn we can't guarantee to be
            an exception to it. We will do our best to protect all kind of
            information you provide to us, however the transmission of it to our
            services remains at your own risk. You are strongly recommended to
            interact with our services using only trustable and secure devices,
            from secure environments.
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              marginTop: "4%",
              fontFamily: "Trebuchet MS, sans-serif",
              color: colorMemento,
            }}
          >
            Contact us
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              fontSize: "1.9rem",
            }}
          >
            For any questions, comments, or complaints about the present Privacy
            Policy, feel free to reach out to us at support@mementocollect.com.
          </Typography>

          <Space />
          <Space />
          <Space />
        </BodyContainer>
      </MainContainer>
    </div>
  );
};

export default React.memo(PrivacyPolicy);

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { getFilePathForDataId } from "configuration";
import { AggDataObj, DataType } from "models/aggdata";
import React, { useState, useCallback, useEffect } from "react";
import AudioProgressBar from "./AudioProgressBar";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import { colorMemento } from "shared/colors";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useDispatch } from "react-redux";
import { setAggdataClicked } from "store/actions/view";
import { useTranslation } from "react-i18next";
import { styledTooltipTitle } from "shared/styles";
interface AudioPlayerProps {
  aggdataObj: AggDataObj;
  autoPlay?: boolean;
  floatingMode?: boolean;
}
const AudioPlayer = ({ aggdataObj, autoPlay = true, floatingMode = true}: AudioPlayerProps) => {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClose = React.useCallback(() => {
    dispatch(setAggdataClicked(null));
  }, []);

  return (
    <>
      <Box
        sx={{
          background: colorMemento,
          dispay: "flex",
          width: floatingMode ? "30vw": "auto",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "24px",
          padding: "16px",
          bottom: 0,
          right: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              overflowX: "hidden",
            }}
          >
            {aggdataObj?.file?.fileName}
          </Typography>
          <Tooltip title={styledTooltipTitle(t("title_close"))}>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon></CloseRoundedIcon>
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <audio
            ref={audioRef}
            onCanPlay={(e) => {
              if (autoPlay) {
                audioRef.current?.play();
              }
            }}
            style={{
              width: "100%",
            }}
            src={getFilePathForDataId(aggdataObj.id)}
            controls
          ></audio>
        </Box>
        {/* {aggdataObj.dataType == DataType.AUDIO_TYPE && (
          <audio
            ref={audioRef}
            preload="metadata"
            onDurationChange={(e) => setDuration(e.currentTarget.duration)}
            onPlaying={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onEnded={handleNext}
            onCanPlay={(e) => {
              e.currentTarget.volume = volume;
              setIsReady(true);
            }}
            onTimeUpdate={(e) => {
              setCurrrentProgress(e.currentTarget.currentTime);
              handleBufferProgress(e);
            }}
            onProgress={handleBufferProgress}
            onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
            //  src={getFilePathForDataId(aggdataObj.id)}
          >
            <source src={getFilePathForDataId(aggdataObj.id)} />
          </audio>
        )}
        <AudioProgressBar
          style={{ width: "100%" }}
          duration={duration}
          currentProgress={currrentProgress}
          buffered={buffered}
          onChange={(e) => {
            if (!audioRef.current) return;
            audioRef.current.currentTime = e.currentTarget.valueAsNumber;

            setCurrrentProgress(e.currentTarget.valueAsNumber);
          }}
        />
        <IconButton onClick={togglePlayPause}>
          {isPlaying ? (
            <PauseRoundedIcon></PauseRoundedIcon>
          ) : (
            <PlayArrowRoundedIcon></PlayArrowRoundedIcon>
          )}
        </IconButton> */}
      </Box>
    </>
  );
};

export default React.memo(AudioPlayer);

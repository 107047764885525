import Chip from "@mui/material/Chip";
import React, { useState } from "react";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { colorMemento } from "shared/colors";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Reminder } from "models/aggdata";
import { formatTimeStamp, isFromPast } from "shared/dataUtils";
import { colorGreyMemento } from '../../../shared/colors';

interface ChipItemProps {
  reminder: Reminder;
  readOnly?: boolean;//will disable clear icon button 
  onClick: (reminder: Reminder) => void;
  onDeleteReminder: (reminder: Reminder) => void;
}

const ChipItems = ({ reminder, readOnly = false, onClick, onDeleteReminder }: ChipItemProps) => {
  const [isHover, setHover] = useState(false);
  
  return (
    <Chip
      onMouseEnter={(e) => setHover(true)}
      onMouseLeave={(e) => setHover(false)}
      size="small"
      icon={
        reminder.cycle > 0 ? (
          <RepeatIcon style={{ color: isFromPast(reminder.triggerTime*1000) ? colorGreyMemento :  colorMemento }} />
        ) : (
          <AccessAlarmIcon style={{ color: isFromPast(reminder.triggerTime*1000) ? colorGreyMemento :  colorMemento  }} />
        )
      }
      variant="outlined"
      label={formatTimeStamp(reminder.triggerTime)}
      onDelete={//this is what allows to display the clear icon on the chip. If no function is provided, then the chip is not displayed.
        (!readOnly && isHover)
          ? () => {
              onDeleteReminder(reminder);
            }
          : undefined
      }
      onClick={(e) => {
        e.preventDefault();
        onClick(reminder);
      }}
      sx={{
        fontSize: "1.3rem",
        cursor: "pointer",
        margin: "2px",
      }}
      // {...getTagProps({ index })}
    />
  );
};

export default React.memo(ChipItems);

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { getTodaysDate } from "shared/dataUtils";
import { colorMemento } from "shared/colors";
import { useTranslation } from "react-i18next";


interface PricingCardProps {
  title: string;
  price: string;
  subheader: string;
  description: string[];
  handleButtonClick: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chooseButton: {
      fontSize: "1.5rem",
      fontWeight: "normal",
      "& .MuiButtonBase-root": {
        fontSize: "1.5rem",
        fontWeight: "normal",
      },
    },
  })
);

const CheckoutRecapCard = ({
  title,
  price,
  subheader,
  description,
  handleButtonClick,
}: PricingCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{
          fontSize: "3rem",
          align: "center",
          color: "grey",
        }}
        action={title === "Pro" ? <StarIcon /> : null}
        subheaderTypographyProps={{
          align: "center",
          fontSize: "1.7rem",
        }}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? "#faf6e9"
              : theme.palette.primary.main,
        }}
      />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            mb: 2,
          }}
        >
          <Typography sx={{ color: colorMemento, fontSize: "6rem"}} component="h1" variant="h2" color="text.primary">
            {price}
          </Typography>
          <Typography sx={{ color: colorMemento }} variant="h6" color="text.secondary">
            /{t('month_title')}
          </Typography>
        </Box>
          
      <Typography sx={{marginBottom : "32px"}} variant="h4" color="text.primary">{t('recap_card_text')}</Typography>
        <ul>
          {description.map((line) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "16px",
              }}
            >
              <CheckIcon
                style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  color: "green",
                }}
              />
              <Typography
                variant="subtitle1"
                align="center"
                variant="h5"
                sx={{ marginLeft: "6px", fontSize: "2rem" }}
                key={line}
              >
                {line}
              </Typography>
            </div>
          ))}
        </ul>

        
      <Typography variant="h6" color="text.secondary">
           {t('recap_card_subscription_charge_hint', {todays_date: getTodaysDate()})}
          </Typography>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default CheckoutRecapCard;

import React, { useCallback, useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import Logo from "icons/favicon.png";
import LogoIcon from "icons/ic_memento_m_letter.svg";

import Input from "../../components/Input";
import Button from "../../components/Button";
import { signUp } from "api/authApi";
import { loginUser as loginReducerAction } from "store/actions/authActions";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import { UserObj } from "../../models/user";
import { useTranslation } from "react-i18next";
import { PASSWORD_MIN_LENGTH, GOOGLE_LOGIN_KEY } from "configuration";
import { SignupApiResult, SignupStatusCodes } from "api/authApi";
import styled from "styled-components";
import { colorMemento } from "shared/colors";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import EmailSentIllustration from "illustrations/undraw_mail_sent.svg";
import { Link as TextLink } from "@mui/material";
import { PRIVACY_POLICY, TERMS_CONDITIONS, LOGIN, REGISTER_GOOGLE_MOBILE_PATH } from "navigation/Constants";
import Divider from "@mui/material/Divider";
import GoogleLogin from "react-google-login";
import GoogleIcon from "icons/google_icon.svg";
import { capitalizeFirstLetter } from "shared/utility";
import { Helmet } from "react-helmet";
import { handleGoogleLoginResponse } from "api/authApi";
import { createUploadLink } from "apollo-upload-client";
import { fetchUserData, LoginApiResult } from "api/authApi";
import { useApolloClient } from "@apollo/client";
import { DEBUG, SERVER_URL } from "configuration";
import Cookies from "js-cookie";
import { MAINBOARD } from "navigation/Constants";
import { isMobile } from "react-device-detect";
import SimpleConfirmationDialog from "components/UI/Modals/SimpleConfirmationDialog";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(
    90deg,
    rgba(255, 241, 206, 1) 0%,
    rgba(255, 253, 250, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  padding-top: 3%;
  overflow-y: hidden;
`;

interface SignUpFormData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

interface SignUpResult {
  signupFailed: boolean;
  errorMsg: string;
}

const INITIAL_SIGNUP_STATUS = {
  signupFailed: false,
  errorMsg: "",
};

const Register: React.FC = () => {
  const [signupsuccess, setSignupSuccess] = useState(false);
  const [signupStatus, setSignupStatus] = useState<SignUpResult>(
    INITIAL_SIGNUP_STATUS
  );
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const client = useApolloClient();
  const [
    displayConfirmationDialogForMobile,
    setDisplayConfirmationDialogForMobile,
  ] = useState(false);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setIsLoading(true);

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          first_name: Yup.string().required(t("first_name_register_required")),
          last_name: Yup.string().required(t("last_name_register_required")),
          email: Yup.string()
            .email(t("enter_valid_email_error"))
            .required(t("email_required_error")),
          password: Yup.string()
            .min(
              PASSWORD_MIN_LENGTH,
              t("password_too_short_error", {
                password_min_length: PASSWORD_MIN_LENGTH,
              })
            )
            .matches(/^(?=.*[0-9])/, t("reset_password_strength_error"))
            .required(t("password_required_error")),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref("password"), null],
            t("password_match_confirmation_error")
          ),
        });

        await schema.validate(data, { abortEarly: false });
        signUp(data).then((result: SignupApiResult) => {
          switch (result.statusCode) {
            case SignupStatusCodes.USER_EXISTS_ALREADY.valueOf():
              setSignupFailed(t("sinup_user_already_exists_error"));
              break;
            case SignupStatusCodes.UNKOWN.valueOf():
              setSignupFailed(t("sinup_user_unknown_error"));
              break;
            case SignupStatusCodes.SUCCESS.valueOf():
              setSignupSuccess(true);
              break;
          }
          console.log(
            "Signup result from register " + result.statusCode.toString()
          );
        });
      } catch (err) {
        setIsLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        console.log("Error occured during login ! ");
      }
    },
    [history]
  );

  const setSignupFailed = useCallback(
    (errorMsgArg: string) => {
      setSignupStatus({ signupFailed: true, errorMsg: errorMsgArg });
      setIsLoading(false);
    },
    [signupStatus]
  );

  const dispatchLogin = (user: UserObj) => {
    dispatch(loginReducerAction(user));
  };

  const onGoogleLoginSuccess = (accessToken: any) => {
    setSignupStatus(INITIAL_SIGNUP_STATUS);
    setIsLoading(true);
    setTimeout(() => {
      //this is just a hack.. to avoid error on backend token used too early
      const response = handleGoogleLoginResponse(accessToken, true);
      response
        .then((result: any) => {
          if (result.status === 200 && result.data.status_code == 0) {
            setLoginSucceeded();
          } else {
            setSignupStatus({
              signupFailed: true,
              errorMsg: t("google_signup_failed_msg"),
            });
          }
          if (DEBUG) {
            console.log("Got a result :" + JSON.stringify(result));
          }
          setIsLoading(false);
        })
        .catch((e: any) => {
          setIsLoading(false);
        });
    }, 5000);
  };

  const setLoginSucceeded = () => {
    fetchUserData()
      .then((user: UserObj) => {
        dispatchLogin(user);
        const uri = SERVER_URL + "/graphql";
        const uploadLink = createUploadLink({
          uri: uri,
          credentials: "include",
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
            // Authorization:
            //   "Token 3d9e16e9bd49539aefb2648535e3529ce9920293b372c7c6d7d8a4d2e0a67dbc",
          },
        });
        client.setLink(uploadLink);
        if (DEBUG) {
          console.log("User data is  " + JSON.stringify(user));
        }
        

        if (isMobile) {
          setDisplayConfirmationDialogForMobile(true)
        } else {
          history.push(MAINBOARD);
        }
      })
      .catch((err) => {
        console.log("Caught an error while fetch user data" + err);
      });
  };

  if (signupsuccess) {
    return (
      <MainContainer>
        <Helmet>
          <title>{capitalizeFirstLetter(t("signup_action"))} | Memento</title>
        </Helmet>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            background: "#f8fbf8",
            borderRadius: "16px",
            marginTop: "24px",
            minWidth: "30%",
            "@media (max-width: 780px)": {
              minWidth: "80%",
            },
          }}
          elevation={3}
        >
          <Link to="/">
            <img
              id="logo"
              width="24px"
              height="24px"
              src={LogoIcon}
              alt="Memento logo"
            />
          </Link>
          <img
            id="logo"
            width="196px"
            height="196px"
            style={{ marginTop: "36px", marginBottom: "36px" }}
            src={EmailSentIllustration}
            alt="Memento logo"
          />
          <Typography
            sx={{
              marginBottom: "24px",
              fontWeight: "300",
              textAlign: "center",
            }}
            variant="h4"
            gutterBottom
          >
            {t("confirmation_email_sent_msg")}
          </Typography>
        </Paper>
      </MainContainer>
    );
  } else
    return (
      <>
        <Helmet>
          <title>{capitalizeFirstLetter(t("signup_action"))} | Memento</title>
        </Helmet>
        <MainContainer>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "24px",
              background: "#f8fbf8",
              borderRadius: "16px",
              minWidth: "30%",
              "@media (max-width: 780px)": {
                minWidth: "80%",
              },
            }}
            elevation={3}
          >
            {signupStatus.signupFailed && (
              <Alert
                style={{ width: "80%", fontSize: "1.4rem" }}
                id="error-alert"
                severity="error"
              >
                {signupStatus.errorMsg}
              </Alert>
            )}
            <Link to="/">
              <img
                id="logo"
                width="82px"
                height="82px"
                src={LogoIcon}
                alt="Memento logo"
              />
            </Link>

            <Typography
              style={{
                marginBottom: "24px",
              }}
              variant="h4"
              component="div"
              gutterBottom
            >
              {t("register")}
            </Typography>
            <Form
              ref={formRef}
              style={{ width: "80%", paddingTop: "32px" }}
              onSubmit={handleSubmit}
            >
              <Input
                autoFocus
                name="first_name"
                type="text"
                placeholder="First name"
              />
              <Input name="last_name" type="text" placeholder="Last name" />
              <Input name="email" type="text" placeholder="E-mail" />
              <Input
                name="password"
                type="password"
                placeholder={t("password")}
              />
              <Input
                name="password_confirmation"
                type="password"
                placeholder={t("password_confirmation")}
              />
              <Button type="submit" loading={isLoading}>
                {" "}
                {t("signup_action")}
              </Button>
              <Typography
                sx={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              >
                {t("or_text")}
              </Typography>
              <GoogleLogin
                render={(renderProps) => (
                  <button
                    onClick={() => {
                      if (isMobile){
                        history.push(REGISTER_GOOGLE_MOBILE_PATH)
                      } 
                      renderProps.onClick()  
                    }}
                    disabled={renderProps.disabled}
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(255, 255, 255)",
                      display: "inline-flex",
                      alignItems: "center",
                      color: "rgba(0, 0, 0, 0.54)",
                      // boxShadow:
                      //   "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
                      padding: "0px",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      fontSize: "14px",
                      fontWeight: 500,
                      borderWidth: "1px",
                      borderColor: "gray",
                      fontFamily: "Roboto, sans-serif",
                    }}
                    type="button"
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        background: "rgb(255, 255, 255)",
                        padding: "10px",
                        borderRadius: " 2px",
                      }}
                    >
                      <img src={GoogleIcon} />
                    </div>
                    <span
                      style={{
                        width: "100%",
                        padding: "10px 10px 10px 0px",
                        fontWeight: 500,
                      }}
                    >
                      {t("register_with_google_title")}
                    </span>
                  </button>
                )}
                clientId={GOOGLE_LOGIN_KEY}
                buttonText={t("login_with_google_btn_txt")}
                onSuccess={onGoogleLoginSuccess}
              />
            </Form>
            <Typography
              style={{
                marginBottom: "24px",
                fontSize: "1.2rem",
                marginTop: "16px",
                width: "80%",
                textAlign: "center",
              }}
              variant="subtitle1"
              component="div"
              gutterBottom
            >
              {" "}
              By creating an account you agree to our
              <TextLink
                style={{ color: colorMemento, cursor: "pointer" }}
                onClick={() => {
                  history.push(PRIVACY_POLICY);
                }}
              >
                {" "}
                privacy policy
              </TextLink>{" "}
              and
              <TextLink
                style={{ color: colorMemento, cursor: "pointer" }}
                onClick={() => {
                  history.push(TERMS_CONDITIONS);
                }}
              >
                {" "}
                terms and conditions
              </TextLink>
            </Typography>

            <div
              style={{
                width: "90%",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <Divider light variant="middle" />
            </div>
            <TextLink
              style={{
                color: "gray",
                fontWeight: "400",
                fontSize: "1.4rem",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push(LOGIN);
              }}
            >
              {t("login_action")}
            </TextLink>
            {/* <Typography
              style={{
                fontSize: "1.8rem",
                marginTop: "16px",
                width: "100%",
                textAlign: "center",
              }}
              variant="subtitle1"
              component="div"
            >
              Already have an account ?
            </Typography>

            <Typography
              style={{
                color: colorMemento,
                cursor: "pointer",
                fontSize: "1.8rem",
              }}
              onClick={() => {
                history.push(LOGIN);
              }}
            >
              Login
            </Typography> */}
          </Paper>
          {displayConfirmationDialogForMobile && (
            <SimpleConfirmationDialog
              open={displayConfirmationDialogForMobile}
              description={t("signup_with_google_dialog_mobile_description")}
              title={t("signup_with_google_mobile_dialog_title")}
              handleOk={() => {
                setDisplayConfirmationDialogForMobile(false);
                history.push(MAINBOARD);
              }}
              hideCancel
            ></SimpleConfirmationDialog>
          )}
        </MainContainer>
      </>
    );
};

export default Register;

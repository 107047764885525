import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import {
  retrievePublicCollectionsForUsernameGql,
  retrievePublicUserProfileGql,
} from "graphql/queries";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import PublicUserProfilePreview from "./PublicUserProfilePreview";
import NotFoundIllustration from "illustrations/undraw_page_not_found.svg";
import { useTranslation } from "react-i18next";
import { MAINBOARD } from "navigation/Constants";


const PublicUserProfile = () => {
  const params: any = useParams();
  const username = params.id;
  const { t } = useTranslation();
  const history = useHistory();

  /* const [fetchPublicCollections, fetchPublicCollectionsResult] = useLazyQuery(
    retrievePublicCollectionsForUsernameGql,
    {
      variables: {
        username: id,
      },
      context: {
        clientName: "public",
      },
    }
  ); */

  const [publicUserProfile, publicUserProfileResult] = useLazyQuery(
    retrievePublicUserProfileGql,
    {
      variables: {
        username: username,
      },
      context: {
        clientName: "public",
      },
    }
  );

  const [fetchPublicCollections, fetchPublicCollectionsResult] = useLazyQuery(
    retrievePublicCollectionsForUsernameGql,
    {
      variables: {
        username: username,
      },
      context: {
        clientName: "public",
      },
    }
  );

  React.useEffect(() => {
    fetchPublicCollections().then((result) => {
      console.log("Got result " + JSON.stringify(result.data));
    });
    publicUserProfile()
      .then((result) => {})
      .catch((e) => {});
  }, []);

  if (publicUserProfileResult.error && !publicUserProfileResult.loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          style={{ marginTop: "60px", height: "300px", cursor: "initial" }}
          src={NotFoundIllustration}
        />
        <Typography
          style={{
            marginLeft: "12px",
            color: "black",
            marginTop: "36px",
            textAlign: "center",
          }}
          variant="h4"
        >
          {t("page_not_found_error")}
        </Typography>
        <Button
          variant="contained"
          sx={{
            fontSize: "1.4rem",
            width: "fit-content",
            marginTop: "12px",
          }}
          onClick={() => {
            history.push(MAINBOARD);
          }}
        >
          {t("back_to_home_action")}
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        alignItems: "center",
        background:  publicUserProfileResult.data && publicUserProfileResult.data.publicProfile.background ?  publicUserProfileResult.data.publicProfile.background: "#ffffff",
        "@media (max-width: 780px)": {
          padding: "8px",
        },
      }}
    >
      <Helmet>
        <title>{username}| Memento</title>
      </Helmet>
      {publicUserProfileResult.data &&
        publicUserProfileResult.data.publicProfile && (
          <PublicUserProfilePreview
            username={username}
            publicProfile={publicUserProfileResult.data.publicProfile}
          />
        )}
    </Box>
  );
};

export default React.memo(PublicUserProfile);

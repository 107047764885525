export const ROOT = "/";
export const ARCHIVE = "archive";
export const LOGIN = "/login";
export const MAINBOARD_WITH_SUBPATH = "/board/:subpath";
export const MAINBOARD_WITH_SUBPATH_ID = "/board/:subpath/:subpathbis";
export const MAINBOARD_WITH_INVITATION_ID =
  "/board/:subpath/:subpathbis/:id/:token";
export const MAINBOARD_WITH_SUBPATH_ARCHIVE_ITEM_ID =
  "/board/:subpath/:subpathbis/:id";
export const MAINBOARD = "/board";
export const HOME = MAINBOARD + "/home";
export const REGISTER = "/register";
export const COLLECTION_ROUTE_SUBPATH = "collection";
export const PUBLIC_COLLECTION_ROUTE_SUBPATH_ID = "/pcollection/:id";
export const PUBLIC_COLLECTION_BASE_PATH = "/pcollection";
export const COLLECTIONS_ROUTE_SUBPATH = "collections";
export const ARCHIVES_ROUTE_SUBPATH = "archives";
export const REMINDERS_ROUTE_SUBPATH = "reminders";
export const ARCHIVES_FULL_ROUTE = MAINBOARD + "/" + ARCHIVES_ROUTE_SUBPATH;
export const REMINDERS_FULL_ROUTE = MAINBOARD + "/" + REMINDERS_ROUTE_SUBPATH;
export const ALL_ITEMS_ROUTE_SUBPATH = "all";
export const ALL_PENDING_ITEMS_ROUTE_SUBPATH = "pending";
export const TAG_ITEMS_ROUTE_SUBPATH = "tag";
export const COLLECTIONS_FULL_ROUTE = MAINBOARD + "/collections";
export const ALL_ITEMS_FULL_ROUTE = MAINBOARD + "/all";
export const ALL_PENDING_ITEMS_FULL_ROUTE = MAINBOARD + "/pending";
export const RESET_PASSWORD = "/reset-password/:uid/:token";
export const RESET_PASSWORD_BASE_PATH = "/reset-password";
export const REQUEST_RESET_PASSWORD = "/request-reset-password";
export const PRICING = "/pricing";
export const PRIVACY_POLICY = "/privacy";
export const TERMS_CONDITIONS = "/terms-conditions";
export const PAYMENT = "/payment";
export const SETTINGS = "/settings";
export const PUBLIC_PROFILE_SETTINGS = "/settings/public-profile";
export const GENERAL_SETTINGS = "/settings/general";
export const PREFERENCES_SETTINGS = "/settings/preferences";
export const SUBSCRIPTION_SETTINGS = "/settings/subscription";
export const DEVICES_SETTINGS = "/settings/devices";
export const SETTINGS_WITH_SUBPATH = SETTINGS + "/:subpath";
export const NOTION_INTEGRATION_SETTINGS_WITH_SUBPATH =
  SETTINGS + "/integrations";
export const PINTEREST_INTEGRATION_SETTINGS_WITH_SUBPATH =
  SETTINGS + "/pinterest";
export const ACCOUNT_ACTIVATION = "/activate/:uid/:token";
export const ACCOUNT_ACTIVATION_BASE_PATH = "/activate";
//Used to expose to chrome extension a component to save data.
export const SAVE_DATA = "/setup-extension";
export const DEV_EXT = "/dev-ext"; // this is dev path used to accelerate UI dev from chrome extension.
export const MAINBOARD_COLLECTION_INVITE_BASE_PATH = "/board/invite/c/";

export const ARCHIVED_COLLECTION_BASE_PATH = "/board/archives/collection/";
export const COLLECTION_BASE_PATH = "/board/collection/";
export const REGISTER_GOOGLE_MOBILE_PATH = "/register/google";
export const NOTION_AUTH_PATH = "/notion-auth";
export const PINTEREST_AUTH_PATH = "/pinterest-auth";
export const BLOG_URL = "https://blog.mementocollect.com";



export const PUBLIC_USER_PROFILE_SUBPATH_ID = "/u/:id";
export const PUBLIC_USER_PROFILE_BASE_PATH = "/u";

export const ALLOWED_PATHS_ON_MOBILE = [
  ROOT,
  LOGIN,
  REGISTER,
  SETTINGS,
  PRICING,
  PAYMENT,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD_BASE_PATH,
  ACCOUNT_ACTIVATION,
  ACCOUNT_ACTIVATION_BASE_PATH,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  NOTION_AUTH_PATH,
  PINTEREST_AUTH_PATH,
  PUBLIC_COLLECTION_BASE_PATH,
  PUBLIC_USER_PROFILE_BASE_PATH
];

export const getTagRoute = (tagName: string) => {
  return "/board/tag/" + tagName;
};

export const getCollectionRoute = (collectionId: string) => {
  return "/board/collection/" + collectionId;
};

/**
 * Methd that returns whether for a path it is accepted to render it on mobile device or not.
 * @param path
 * @returns
 */
export const isPathAllowedForMobileDevice = (path: string): boolean => {
  if (!path) {
    return false;
  }
  return (
    ALLOWED_PATHS_ON_MOBILE.indexOf(path) > -1 ||
    path.startsWith(RESET_PASSWORD_BASE_PATH) ||
    path.startsWith(SETTINGS) ||
    path.startsWith(ACCOUNT_ACTIVATION_BASE_PATH) ||
    path.startsWith(PUBLIC_COLLECTION_BASE_PATH) ||
    path.startsWith(PUBLIC_USER_PROFILE_BASE_PATH)
  );
};

export const shouldRedirectToPlaystore = (path: string): boolean => {
  return (
    path.startsWith(MAINBOARD_COLLECTION_INVITE_BASE_PATH) ||
    path.startsWith(COLLECTION_BASE_PATH)
  );
};

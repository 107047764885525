import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store/reducers/index";
import { UserObj } from "models/user";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { getPublicProfileUrl } from "configuration";
import { colorMemento } from "shared/colors";
import { Button, Skeleton, Switch, Tooltip } from "@mui/material";
import { styledTooltipTitle } from "shared/styles";
import { useMutation, useQuery } from "@apollo/client";
import { updatePublicProfileGql } from "graphql/mutations";
import { retrieveMyPublicProfileGql } from "graphql/queries";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { showSnackbar } from "store/actions/view";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ConfigureSocialMediaLinksModal from "components/UI/Modals/ConfigureSocialMediaLinksModal";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PublicUserProfilePreview from "containers/User/PublicUserProfilePreview";
import { FaTiktok } from "react-icons/fa";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { BsThreads } from "react-icons/bs";
import EmailIcon from '@mui/icons-material/Email';
import { FaAmazon } from "react-icons/fa";
import { FaTwitch } from "react-icons/fa";
import { FaStrava } from "react-icons/fa";


const socialIconStyle = {
  width: "24px",
  height: "24px",
  color: "black",
};

const FACEBOOK_BUTTON = 0;
const PINTEREST_BUTTON = 1;
const YOUTUBE_BUTTON = 2;
const INSTAGRAM_BUTTON = 3;
const TWITTER_BUTTON = 4;
const LINKEDIN_BUTTON = 5;
const TIKTOK_BUTTON = 6;
const THREADS_BUTTON = 7;
export const EMAIL_BUTTON = 8;
const AMAZON_BUTTON = 9;
const TWITCH_BUTTON = 10;
const STRAVA_BUTTON = 11;


export interface SocialMediaItem {
  icon: any;
  title: string;
  key: string;
  id: number;
}

export const SOCIAL_MEDIA_URLS: SocialMediaItem[] = [
  {
    icon: (
      <EmailIcon sx={{ ...socialIconStyle /* , color : "#1877f2" */ }} />
    ),
    title: "Email",
    key: "email",
    id: EMAIL_BUTTON,
  },

  {
    icon: <FaTiktok style={socialIconStyle} />,
    title: "Tiktok",
    key: "tiktokUrl",
    id: TIKTOK_BUTTON,
  },
  {
    icon: (
      <FacebookIcon sx={{ ...socialIconStyle /* , color : "#1877f2" */ }} />
    ),
    title: "Facebook",
    key: "facebookUrl",
    id: FACEBOOK_BUTTON,
  },
  {
    icon: (
      <PinterestIcon sx={{ ...socialIconStyle /* , color : "#bd081c" */ }} />
    ),
    title: "Pinterest",
    key: "pinterestUrl",
    id: PINTEREST_BUTTON,
  },
  {
    icon: <YouTubeIcon sx={{ ...socialIconStyle /* , color : "#ff0000" */ }} />,
    title: "Youtube",
    key: "youtubeUrl",
    id: YOUTUBE_BUTTON,
  },
  {
    icon: (
      <LinkedInIcon sx={{ ...socialIconStyle /* , color : "#0a66c2" */ }} />
    ),
    title: "Linkedin",
    key: "linkedinUrl",
    id: LINKEDIN_BUTTON,
  },
  {
    icon: (
      <InstagramIcon sx={{ ...socialIconStyle /* , color : "#c32aa3" */ }} />
    ),
    title: "Instagram",
    key: "instagramUrl",
    id: INSTAGRAM_BUTTON,
  },
  {
    icon: (
      <BsThreads style={{ ...socialIconStyle /* , color : "#c32aa3" */ }} />
    ),
    title: "Threads",
    key: "threadsUrl",
    id: THREADS_BUTTON,
  },
  {
    icon: <XIcon sx={socialIconStyle} />,
    title: "X",
    key: "xUrl",
    id: TWITTER_BUTTON,
  },
  {
    icon: <FaTwitch style={socialIconStyle} />,
    title: "Twitch",
    key: "twitchUrl",
    id: TWITCH_BUTTON,
  },
  {
    icon: (
      <FaStrava style={{ ...socialIconStyle /* , color : "#1877f2" */ }} />
    ),
    title: "Strava",
    key: "stravaUrl",
    id: STRAVA_BUTTON,
  },
  {
    icon: <FaAmazon style={socialIconStyle} />,
    title: "Amazon",
    key: "amazonUrl",
    id: AMAZON_BUTTON,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  avatarNotHovered: {
    transform: "scale(1)",
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: 200,
    }),
    [theme.breakpoints.down("mobile")]: {
      width: "10px",
      height: "10px",
    },
  },
  boxSectionContiner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "start",
  },
}));

const PublicProfileSettings = () => {
  const user: UserObj = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  const [
    editPublicProfileDescription,
    setEditPublicProfileDescription,
  ] = React.useState(false);
  const [
    publicProfileDescriptionInputValue,
    setPublicProfileDescriptionInputValue,
  ] = React.useState("blablabla default text");
  const [
    publicProfileDescription,
    setPublicProfileDescription,
  ] = React.useState("");
  const classes = useStyles();

  const [enablePublicProfile, setEnablePublicProfile] = React.useState(false);
  const [
    openConfigureSocialMediaLinks,
    setOpenConfigureSocialMediaLinks,
  ] = React.useState(false);

  const [updatePublicProfile, updatePublicProfileResult] = useMutation(
    updatePublicProfileGql
  );

  const myPublicProfileQuery = useQuery(retrieveMyPublicProfileGql);

  const { t } = useTranslation();

  const handleEnablePublicProfileToggle = React.useCallback(() => {
    const newValue = !enablePublicProfile;
    setEnablePublicProfile(newValue);

    updatePublicProfile({
      variables: {
        input: {
          enabled: newValue,
        },
      },
    }).catch((e) => {
      dispatch(
        showSnackbar({
          message: t("generic_failure_msg"),
          action: "",
          onAction: null,
          loading: false,
        })
      );
      setEnablePublicProfile(!newValue); //we restore the UI value back
    });
  }, [enablePublicProfile]);

  /* React.useEffect(() => {
    if (editPublicProfileDescription) {
      publicProfileDescriptionRef.current.focus();
    }
  }, [editPublicProfileDescription]); */

  React.useEffect(() => {
    if (myPublicProfileQuery.data && myPublicProfileQuery.data.publicProfile) {
      let description = myPublicProfileQuery.data.publicProfile.description;
      setPublicProfileDescription(description);
      setEnablePublicProfile(myPublicProfileQuery.data.publicProfile.enabled);
      if (!editPublicProfileDescription) {
        setPublicProfileDescriptionInputValue(description);
      }
    }
  }, [myPublicProfileQuery.data]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          padding: "16px",
          paddingTop: 0,
          alignItems: "center",
          "@media (max-width: 780px)": {
            padding: "8px",
            paddingTop: 0,
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            marginTop: "16px",
            padding: "8px",
            display: "flex",
            flexDirection: "row",
            paddingTop: "16px",
            paddingBottom: "16px",
            backgroundColor: colorMemento, //"#faf6e9"
            borderRadius: "4px",
            alignItems: "top",
            "@media (max-width: 780px)": {
              marginTop: "0px",
            },
          }}
        >
          <TipsAndUpdatesIcon
            sx={{
              marginLeft: "8px",
              marginTop: "4px",
              "@media (max-width: 780px)": {
                marginLeft: 0,
              },
            }}
          />

          <Typography
            sx={{
              fontSize: "1.2rem",
              marginLeft: "16px",
              whiteSpace: "pre-line",
              "@media (max-width: 780px)": {
                marginLeft: "8px",
              },
            }}
            variant="h6"
            color="text.secondary"
          >
            {t("public_profile_settings_presentation")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "16px",
            width: "100%",
            marginTop: "16px",
            "@media (max-width: 780px)": {
              padding: 0,
              marginBottom: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "start",

            }}
          >
            <Typography
              style={{
                marginTop: "2px",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                color: "black",
              }}
              variant="h4"
              color="text.secondary"
            >
              {t("enable_public_profile_action_title")}
            </Typography>
            <Typography
              style={{ marginTop: "4px" }}
              variant="h6"
              color="text.secondary"
            >
              {t("enable_public_profile_action_description")}
            </Typography>
          </Box>
          <Box className={classes.boxSectionContiner}>
            <Switch
              checked={enablePublicProfile}
              onChange={handleEnablePublicProfileToggle}
            />
          </Box>
        </Box>

        <Typography
          sx={{
            width: "100%",
            paddingLeft: "16px",
            marginTop: "8px",
            alignItems: "start",
            display: "flex",
            flexDirection: "row",
            color: "black",
            "@media (max-width: 780px)": {
              paddingLeft: 0,
            },
          }}
          variant="h4"
          color="text.secondary"
        >
          {t("link_to_public_profile_title")}
        </Typography>
        <PublicProfileLinkContainer />
        <Typography
          sx={{
            width: "100%",
            paddingLeft: "16px",
            marginTop: "8px",
            alignItems: "start",
            display: "flex",
            flexDirection: "row",
            color: "black",
            "@media (max-width: 780px)": {
              paddingLeft: 0,
            },
          }}
          variant="h4"
          color="text.secondary"
        >
          {t("public_profile_preview_title")}
        </Typography>
        {myPublicProfileQuery.data && myPublicProfileQuery.data.publicProfile && (
          <PublicUserProfilePreview
            username={user.username}
            publicProfile={myPublicProfileQuery.data.publicProfile}
            onOpenConfigureSocialMediaLinks={() => {
              setOpenConfigureSocialMediaLinks(true);
            }}
            readonly={false}
          />
        )}

        {myPublicProfileQuery.data && myPublicProfileQuery.data.publicProfile && (
          <ConfigureSocialMediaLinksModal
            open={openConfigureSocialMediaLinks}
            socialMediaUrlsJsonString={
              myPublicProfileQuery.data.publicProfile.socialMediaUrls
            }
            handleModalClose={() => {
              setOpenConfigureSocialMediaLinks(false);
            }}
          />
        )}
      </Box>
    </>
  );
};

const PublicProfileLinkContainer = () => {
  const [displayUrlCopiedTooltip, setDisplayUrlCopiedTooltip] = React.useState(
    false
  );
  const { t } = useTranslation();

  const user: UserObj = useSelector((state: RootState) => state.auth.user);
  const copyLinkToClipboard = React.useCallback(() => {
    navigator.clipboard.writeText(getPublicProfileUrl(user.username));
    setDisplayUrlCopiedTooltip(true);
    setTimeout(() => {
      setDisplayUrlCopiedTooltip(false);
    }, 2000);
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingLeft: "16px",
        "@media (max-width: 780px)": {
          paddingLeft: 0,
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingRight: "16px",
          paddingLeft: "16px",
          marginTop: "8px",
          marginBottom: "16px",
          alignItems: "center",
          border: "dashed red",
          borderColor: colorMemento,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflow: "auto",
            scrollbarWidth: "1px",
            "&::-webkit-scrollbar": {
              height: 0,
            },
          }}
        >
          <InsertLinkIcon></InsertLinkIcon>
          {user && (
            <Typography
              sx={{
                marginLeft: "16px",
                fontSize: "1.6rem",
                wordWrap: "break-word",
                "@media (max-width: 780px)": {
                  padding: 0,
                },
              }}
            >
              {getPublicProfileUrl(user.username)}
            </Typography>
          )}
          {!user && (
            <Skeleton
              variant="text"
              sx={{
                marginRight: "8px",
                marginLeft: "8px",
              }}
              height={40}
              width={"100%"}
            />
          )}
        </Box>
        <Tooltip
          open={displayUrlCopiedTooltip}
          title={styledTooltipTitle(
            t("link_copied_to_clipboard_confirmation_msg")
          )}
        >
          <Button
            sx={{
              color: "gray",
            }}
            onClick={copyLinkToClipboard}
          >
            {t("copy_link_action")}
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default React.memo(PublicProfileSettings);
